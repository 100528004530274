import gql from 'graphql-tag';

import BaseDataSource from '../BaseDataSource';
import AuthenticationHelper from '~/services/Auth';

export default class SubjectDataSource extends BaseDataSource {
  constructor() {
    super();
    this.defaultGetSubjectsFields = `
      totalCount
      items {
        id
        contact {
          name
          surname
          email
          phone
        }
        address {
          country
          street
          city
          zip
        }
      }
    `;
  }

  async selectById(id) {
    const query = gql`
      query subjectById($id: ID!) {
        subjectById(id: $id) {
          contact {
            name
            surname
            companyName
            email
            phone
          },
          address {
            country
            street
            city
            zip
          }
        }
      }
    `;
    const variables = { id };

    return this.client.query({
      query,
      variables,
      context: { headers: { AccessToken: AuthenticationHelper.accessToken } },
    });
  }

  async getSubjects(
    searchKey,
    orderBy,
    orderByDirection,
    offset,
    limit,
    cache = true,
    fields = this.defaultGetSubjectsFields,
  ) {
    const query = gql`
      query subjects($searchKey: String, $orderBy: EnumSubjectOrderByFields, $orderByDirection: EnumOrderByDirection ,$offset: Int, $limit: Int!) {
        subjects(searchKey: $searchKey, orderBy: $orderBy, orderByDirection: $orderByDirection, offset: $offset, limit: $limit) {
          ${fields}
        }
      }
    `;
    const variables = { searchKey, orderBy, orderByDirection, offset, limit };

    const options = {
      query,
      variables,
      context: { headers: { AccessToken: AuthenticationHelper.accessToken } },
    };

    if (!cache) {
      options.fetchPolicy = 'no-cache';
    }

    return this.client.query(options);
  }

  async createSubject(contact, address) {
    const mutation = gql`
      mutation createSubject($contact: InputContactCreate!, $address: InputAddressCreate!) {
        createSubject(contact: $contact, address: $address) {
          id
        }
      }
    `;

    const variables = { contact, address };

    return this.client.mutate({
      mutation,
      variables,
      context: { headers: { AccessToken: AuthenticationHelper.accessToken } },
    });
  }

  async updateSubject(id, contact, address) {
    const mutation = gql`
      mutation updateSubject($id: ID!, $contact: InputContactCreate!, $address: InputAddressCreate!) {
        updateSubject(id: $id, contact: $contact, address: $address) {
          id
          contact {
            name
            surname
            email
            phone
          }
          address {
            street
            city
            zip
            country
          }
        }
      }
    `;

    const variables = { id, contact, address };

    return this.client.mutate({
      mutation,
      variables,
      context: { headers: { AccessToken: AuthenticationHelper.accessToken } },
    });
  }

  async deleteSubject(id) {
    const mutation = gql`
      mutation deleteSubject($id: ID!) {
        deleteSubject(id: $id) {
          id
        }
      }
    `;

    const variables = { id };

    return this.client.mutate({
      mutation,
      variables,
      context: { headers: { AccessToken: AuthenticationHelper.accessToken } },
    });
  }
}
