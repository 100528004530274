import { useQuery } from 'react-apollo';
import { getGraphQLAuthenticationContext } from 'Shared/utilities';

function useTokenQuery(query, options) {
  return useQuery(
    query, {
      ...options,
      context: getGraphQLAuthenticationContext(),
    },
  );
}

export {
  // eslint-disable-next-line import/prefer-default-export
  useTokenQuery,
};
