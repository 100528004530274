import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Navbar, Nav, NavItem, Container } from 'reactstrap';

import ActionMenu from './Components/Container/ActionMenu/ActionMenu';

const ActionBar = ({
  checkedDeliveries,
  refetchDeliveries,
  cancelDelivery,
  hideActionBar,
}) => {
  const { t } = useTranslation('deliveries');

  return (
    <Navbar
      className="action-bar"
      color="dark"
      dark
      expand="md"
      fixed="bottom"
      style={{ display: (checkedDeliveries.length > 0) ? 'block' : 'none' }}
    >
      <Container className="align-items-end">
        <Nav navbar>
          <NavItem className="text-center">
            <div className="fa-2x">
              <span className="fa-layers fa-fw">
                <FontAwesomeIcon icon={['far', 'circle']} />
                <span className="fa-layers-text fa-inverse" style={{ fontSize: '14px', paddingBottom: 0 }}>
                  {checkedDeliveries.length}
                </span>
              </span>
            </div>
            {t('ActionBar.checkedDeliveries')}
          </NavItem>
        </Nav>

        <Nav navbar className="mx-auto">
          <ActionMenu
            cancelDelivery={cancelDelivery}
            hideActionBar={hideActionBar}
            checkedDeliveries={checkedDeliveries}
            refetchDeliveries={refetchDeliveries}
          />
        </Nav>

        <Nav navbar>
          <NavItem className="text-center" onClick={hideActionBar}>
            <div className="fa-2x">
              <FontAwesomeIcon icon={['fas', 'times']} style={{ cursor: 'pointer' }} />
            </div>
            {t('ActionBar.escapeButton')}
          </NavItem>
        </Nav>
      </Container>
    </Navbar>
  );
};

ActionBar.propTypes = {
  checkedDeliveries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  refetchDeliveries: PropTypes.func.isRequired,
  cancelDelivery: PropTypes.func.isRequired,
  hideActionBar: PropTypes.func.isRequired,
};

export default ActionBar;
