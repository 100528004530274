import React from 'react';
import {
  Card, CardBody, CardHeader, Container,
} from 'reactstrap';

type Props = {
  readonly error?: Nullable<string>,
  readonly errorDescription?: Nullable<string>,
}

const AuthorizeError: React.FC<Props> = ({
  error,
  errorDescription,
}) => {
  return (
    <Container>
      <Card>
        <CardHeader>
          Neplatný požadavek od vzdálené aplikace.
          <hr />
        </CardHeader>
        <CardBody>
          <dl>
            <dt>error</dt>
            <dd>{error || 'unknown_error'}</dd>
            <dt>error_description</dt>
            <dd>{errorDescription || 'Unknown error occured'}</dd>
          </dl>
        </CardBody>
      </Card>
    </Container>
  );
};

export default AuthorizeError;
