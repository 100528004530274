import React from 'react';

import basePaths from 'Routes/paths/base.path';
import LocalDeliveryTypes from '../../LocalDeliveryTypes/LocalDeliveryTypes';
import DuplicateDeliveryUI from '../../Presentational/DuplicateDelivery/DuplicateDelivery';

export default class DuplicateDelivery extends React.Component {
  duplicateDelivery = async () => {
    const { push, deliveryId } = this.props;
    push(`${basePaths.deliveries}/new/${deliveryId}`);
  };

  render() {
    return (
      <DuplicateDeliveryUI onDuplicateClick={() => this.duplicateDelivery()} />
    );
  }
}

DuplicateDelivery.propTypes = {
  push: LocalDeliveryTypes.every().push.isRequired,
  deliveryId: LocalDeliveryTypes.every().deliveryId.isRequired,
};
