import React, { useMemo } from 'react';
import { Button } from 'reactstrap';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Alert from '~/components/Alert/Alert';
import Icon from '~/components/Icon/Icon';
import { remainingDays } from '~/services/utils';
import configurator from '~/services/Configurator';
import useAuthQuery from '~/hooks/useAuthQuery';
import SUBSCRIPTIONS from './subscriptionCurrentUserList.gql';

type Props = {
  validTo: string;
}

const TrialAlert: React.FC<Props> = ({ validTo }) => {
  const { t } = useTranslation(['common']);
  const user = useSelector((state: any) => state.userReducer.data);
  const businessClass = user?.businessClass;

  const remaining = remainingDays(new Date(), new Date(validTo));
  const { data, loading } = useAuthQuery(SUBSCRIPTIONS, undefined, {
    fetchPolicy: 'cache-first',
  });
  const subscriptions = data?.subscriptionCurrentUserList;

  // TODO: Move Subscription types from IAM to shared package & use here
  const hasFutureSubscription = useMemo(() => {
    if (!subscriptions) {
      return true;
    }

    return subscriptions.some((subscription: any) => {
      return subscription.isFuture;
    });
  }, [subscriptions]);

  return (
    <Alert
      type={hasFutureSubscription ? 'primary' : 'info'}
      className="mb-3"
    >
      <Icon
        name={hasFutureSubscription ? 'info' : 'alert-triangle'}
        className="mr-2"
      />
      <p className="mb-0">
        <Trans
          i18nKey="common:TrialBanner.message"
          values={{ count: remaining }}
        />
      </p>
      {!loading && !hasFutureSubscription && (businessClass !== 'enterprise') && (
        <Button
          type="button"
          color="info"
          outline
          className="banner-btn"
          href={`${configurator.config.iamUrl}/upgrade-licence`}
        >
          {t('common:TrialBanner.upgradeButton')}
        </Button>
      )}
    </Alert>
  );
};

export default TrialAlert;
