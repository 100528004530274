import React from 'react';
import { clearTimeoutById, toast } from 'Shared/utilities';
import PropTypes from 'prop-types';
import configurator from '~/services/Configurator';

let sessionTimeoutAttached = false;

export default class UserActivityChecker extends React.Component {
  constructor(props) {
    super(props);

    this.sessionTimeoutId = null;

    this.initApolloRequestListener();
  }

  async componentDidMount() {
    this.startSessionExpirationTimeout();
  }

  componentWillUnmount() {
    clearTimeoutById(this.sessionTimeoutId);
  }

    restartSessionExpirationTimeout = () => {
      clearTimeoutById(this.sessionTimeoutId);
      this.startSessionExpirationTimeout();
    };

    startSessionExpirationTimeout = () => {
      const { setAuthenticated } = this.props;

      this.sessionTimeoutId = setTimeout(async () => {
        setAuthenticated(false);
        toast.info('Byl(a) jste odhlášen(a) z důvodu neaktivity.');
      }, configurator.config.sessionTimeout);
    };

    initApolloRequestListener() {
      if (!sessionTimeoutAttached) {
        document.addEventListener('apolloRequestFinished', this.restartSessionExpirationTimeout);
        sessionTimeoutAttached = true;
      }
    }

    render() {
      const { children } = this.props;

      return (
        <>
          {children}
        </>
      );
    }
}

UserActivityChecker.propTypes = {
  setAuthenticated: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
};
