import React from 'react';

type Props = {
  label: string;
  value?: JSX.Element | string;
  valueClassName?: string;
}

const InfoRow: React.FC<Props> = ({
  label,
  value = '-',
  valueClassName,
  children,
}) => (
  <>
    <dt className="col-6 text-dark font-weight-normal">
      {label}
    </dt>

    <dd className={`col-6 ${valueClassName || ''}`.trim()}>
      {children || value}
    </dd>
  </>
);

export default InfoRow;
