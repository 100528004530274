import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

import CloseButton from '~/components/CloseButton/CloseButton';
import css from './Banner.module.scss';

type Props = {
  title?: string;
  imgSrc?: string;
  imgAlt?: string;
  buttonText?: string;
  buttonLink?: string;
  buttonLinkHash?: string;
  onButtonClick?: () => void;
  actions?: Nullable<JSX.Element | string>;
  canDismiss?: boolean;
  onDismiss?: () => void;
};

const Banner: React.FC<Props> = ({
  title,
  imgSrc,
  imgAlt = 'Banner illustration',
  buttonText,
  buttonLink,
  buttonLinkHash,
  onButtonClick,
  actions,
  children,
  canDismiss = false,
  onDismiss,
}) => {
  const [isDismissed, setIsDismissed] = useState(false);

  const handleDismiss = () => {
    setIsDismissed(true);

    if (onDismiss) {
      onDismiss();
    }
  };

  if (isDismissed) {
    return null;
  }

  return (
    <div className={css.banner}>
      {canDismiss && (
        <CloseButton
          className={css.closeButton}
          onClick={handleDismiss}
        />
      )}

      {imgSrc && (
        <img
          src={imgSrc}
          alt={imgAlt}
          className={css.image}
        />
      )}

      <div className={css.contentContainer}>
        <div className={css.content}>
          {title && <h2 className={css.title}>{title}</h2>}
          {children}
        </div>
      </div>

      {actions}

      {buttonLink && (
        <Link
          to={{
            pathname: buttonLink,
            hash: buttonLinkHash,
          }}
          className="btn btn-primary"
        >
          {buttonText}
        </Link>
      )}
      {onButtonClick && (
        <Button variant="primary" onClick={onButtonClick}>
          {buttonText}
        </Button>
      )}
    </div>
  );
};

export default Banner;
