import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Spinner } from 'reactstrap';

import EshopLocalizationForm from './EshopLocalizationForm/EshopLocalizationForm';
import EshopForm, { EshopFormValues } from './EshopForm/EshopForm';
import useAuthMutation from '~/hooks/useAuthMutation';
import useAuthQuery from '~/hooks/useAuthQuery';
import { toast } from '#/shared/utilities';
import Base64Helper from '#/core/tools/Base64Helper';
import {
  EshopLocalizationConfig,
  EshopQuery,
  UpdateLocalizationConfigMutation,
  UpdateLocalizationConfigMutationVariables,
  UpdateEshopMutation,
  UpdateEshopMutationVariables,
  CreateLocalizationConfigMutation,
  CreateLocalizationConfigMutationVariables,
} from './types';

import ESHOP from './eshop.gql';
import UPDATE_ESHOP from './updateEshop.gql';
import UPDATE_LOCALIZATION_CONFIG from './updateLocalizationConfig.gql';
import CREATE_LOCALIZATION_CONFIG from './createLocalizationConfig.gql';

type Props = {
  updateSidebar: (eshopName: string) => void;
  hasActiveBranding: boolean;
};

const EshopPage: React.FC<Props> = ({ updateSidebar, hasActiveBranding }) => {
  const { t } = useTranslation(['settings', 'validation']);
  const [updateEshop] = useAuthMutation<UpdateEshopMutation, UpdateEshopMutationVariables>(UPDATE_ESHOP);
  const [updateLocalizationConfig] = useAuthMutation<UpdateLocalizationConfigMutation, UpdateLocalizationConfigMutationVariables>(UPDATE_LOCALIZATION_CONFIG);
  const [createLocalizationConfig] = useAuthMutation<CreateLocalizationConfigMutation, CreateLocalizationConfigMutationVariables>(CREATE_LOCALIZATION_CONFIG);
  const { data, loading, refetch } = useAuthQuery<{ eshop: EshopQuery }, never>(ESHOP);
  const eshop = data?.eshop;

  /**
   * Default localization config has the `country` field set to null
   */
  const defaultLocalizationConfig = useMemo<Nullable<EshopLocalizationConfig>>(() => {
    const config = eshop?.localizationConfigs.find((c: EshopLocalizationConfig) => c.country === null);
    return config ?? null;
  }, [eshop]);

  /**
   * List of existing localization configs (without default config)
   */
  const localizationConfigs = useMemo<EshopLocalizationConfig[]>(() => {
    return eshop?.localizationConfigs.filter((c: EshopLocalizationConfig) => c.country !== null) ?? [];
  }, [eshop]);

  const handleEshopFormSubmit = async (values: EshopFormValues) => {
    let logo = null;

    // TODO: Typeof value is File?
    // TODO: Replace base64 with file upload
    if (values.logo && typeof values.logo !== 'string') {
      const b64helper = new Base64Helper(values.logo);
      const encodedImage = await b64helper.getBase64String();

      logo = {
        name: values.logo.name,
        rawSource: encodedImage,
      };
    }

    const localizationConfigVariables: CreateLocalizationConfigMutationVariables = {
      config: {
        country: null,
        termsAndConditionsUrl: values.defaultLocalizationConfig.termsAndConditionsUrl ?? null,
        privacyPolicyUrl: values.defaultLocalizationConfig.privacyPolicyUrl ?? null,
        contactUrl: values.defaultLocalizationConfig.contactUrl ?? null,
        supportPhone: values.defaultLocalizationConfig.supportPhone ?? null,
        supportEmail: values.defaultLocalizationConfig.supportEmail ?? null,
        homepageUrl: values.defaultLocalizationConfig.homepageUrl ?? null,
      },
    };

    try {
      await updateEshop({
        variables: {
          eshop: {
            name: values.name || null,
            webUrl: values.webUrl || null,
            facebookUrl: values.facebookUrl || null,
            instagramUrl: values.instagramUrl || null,
            twitterUrl: values.twitterUrl || null,
            linkedInUrl: values.linkedInUrl || null,
            youTubeUrl: values.youtubeUrl || null,
            logo,
          },
        },
      });

      if (values.defaultLocalizationConfig.id) {
        await updateLocalizationConfig({
          variables: {
            ...localizationConfigVariables,
            id: values.defaultLocalizationConfig.id,
          },
        });
      } else {
        await createLocalizationConfig({
          variables: localizationConfigVariables,
        });
      }

      await refetch();
      updateSidebar(values.name);
      toast.success(t('settings:Eshop.Message.eshopFormSaved'));
    } catch (e) {
      // Handled in global error interceptor
    }
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center mt-5">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <EshopForm
        onSubmit={handleEshopFormSubmit}
        eshop={eshop}
        defaultLocalizationConfig={defaultLocalizationConfig}
      />
      { hasActiveBranding && (
        <EshopLocalizationForm
          localizationConfigs={localizationConfigs}
          onSubmit={() => refetch()}
        />
      ) }
    </>
  );
};

const mapStateToProps = (state: any) => ({ hasActiveBranding: state.userReducer.data.hasActiveBranding });
export default connect(mapStateToProps)(EshopPage);
// export default EshopPage;
