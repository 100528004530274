import React from 'react';
import { connect } from 'react-redux';
import { generate } from 'shortid';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import configurator from '~/services/Configurator';
import nav, { Navigation } from '#/core/routes/nav/web.nav';
import basePaths from '#/core/routes/paths/base.path';
import SidebarNavItem from './SidebarNavItem/SidebarNavItem';
import LanguageSwitcher from '~/components/LanguageSwitcher/LanguageSwitcher';

import css from './Sidebar.module.scss';

type Props = {
  sidebarVisible: boolean;
  hasActiveBranding: boolean;
};

const Sidebar: React.FC<Props> = ({ sidebarVisible, hasActiveBranding }) => {
  const { t } = useTranslation();
  const navigation = nav(t, hasActiveBranding);

  const prepareItems = (items: Navigation) => (
    items.map((item) => (
      (item.type === 'link') ? (
        <SidebarNavItem
          key={generate()}
          item={item}
        />
      ) : null
    )));

  const topNavItems = prepareItems(navigation.sidebar.top);
  const bottomNavItems = prepareItems(navigation.sidebar.bottom);

  return (
    <nav className={`${css.sidebar} d-flex flex-column ${css.sidebarNarrow} ${sidebarVisible ? 'navbar-light' : 'active navbar-light'}`}>
      <div className={css.header}>
        <figure className={css.logo}>
          <Link to={basePaths.deliveries}>
            <img
              alt="stiteknabalik.cz logo"
              src="/assets/img/snb-logo-symbol.svg"
            />
          </Link>
        </figure>
      </div>

      <ul className={`${css.navbarNav} ${css.topNavbar}`}>
        {topNavItems}
      </ul>

      <hr className={css.hr} />
      <ul className={`${css.navbarNav} ${css.bottomNavbar}`}>
        {bottomNavItems}
      </ul>

      <div className="mt-auto">
        <LanguageSwitcher hideCaret className={`${css.languageSwitcher} mb-3`} />

        <div className={`${css.version}`}>
          v
          {configurator.config.uiVersion}
        </div>
      </div>
    </nav>
  );
};

const mapStateToProps = (state: any) => ({ hasActiveBranding: state.userReducer.data.hasActiveBranding });
export default connect(mapStateToProps)(Sidebar);
