import React from 'react';
import produce from 'immer';
import PropTypes from 'prop-types';
import { ApolloConsumer } from 'react-apollo';

import CountryManager from 'Containers/CountryManager/CountryManager';
import CurrencyManager from 'Containers/CurrencyManager/CurrencyManager';
import dexieDriver from 'Services/DexieDriver';

export default class IndexedDBDataManager extends React.Component {
  constructor(props) {
    super(props);

    this.db = dexieDriver.getDb();
    this.state = { tables: {} };
  }

  getTable = async (tableName) => {
    if (!this.db) return [];

    const tableArray = await this.db[tableName].toArray();

    if (tableArray.length === 0) return tableArray;

    const state = produce(this.state, (draft) => {
      draft.tables[tableName] = tableArray;
    });
    this.setState(state);

    return tableArray;
  };

  setTable = async (tableName, tableData) => {
    if (!this.db) return;

    await this.db[tableName].bulkAdd(tableData);

    const state = produce(this.state, (draft) => {
      draft.tables[tableName] = tableData;
    });

    this.setState(state);
  };

  render() {
    const { children, useCountries, useCurrencies } = this.props;
    const { tables } = this.state;

    return (
      <ApolloConsumer>
        {(client) => (
          <CountryManager
            indexedDBClient={this.db}
            apolloClient={client}
            getTable={this.getTable}
            setTable={this.setTable}
            tables={tables}
            disabled={!useCountries}
          >
            <CurrencyManager
              indexedDBClient={this.db}
              apolloClient={client}
              getTable={this.getTable}
              setTable={this.setTable}
              tables={tables}
              disabled={!useCurrencies}
            >
              {children}
            </CurrencyManager>
          </CountryManager>
        )}
      </ApolloConsumer>
    );
  }
}

IndexedDBDataManager.defaultProps = {
  useCountries: true,
  useCurrencies: true,
};

IndexedDBDataManager.propTypes = {
  children: PropTypes.element.isRequired,
  useCountries: PropTypes.bool,
  useCurrencies: PropTypes.bool,
};
