import React from 'react';
import { Button, Form, FormGroup, Label, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { produce } from 'immer';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { InputWithValidation } from 'Components/Presentational/FormExtensions/HoC/withValidation/withValidation';
import { generateReactKey } from 'Shared/utilities';
import StartNumberAccordionUI from '../../Presentational/StartNumberAccordionUI/StartNumberAccordionUI';

class StartNumberAccordion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inactiveStartNumbers: [],
      isModalVisible: false,
    };
  }

  setUsedNumber(number) {
    const { services, mergeAgentFormValues, service: currentService } = this.props;
    const storedService = services.find((service) => service.deliveryType.id === currentService.deliveryType.id);

    const updatedInactiveStartNumbers = produce(this.state, (draft) => {
      if (!storedService.startNumber) storedService.startNumber = {};

      draft.inactiveStartNumbers.push({
        key: generateReactKey(),
        number: storedService.startNumber.number,
      });
    });

    this.setState(updatedInactiveStartNumbers);
    storedService.startNumber.number = number;
    mergeAgentFormValues({ services: { ...services, updatedService: storedService } });
  }

    handleAddStartNumber = ({ startNumber }, { resetForm }) => {
      this.toggleModal();
      this.setUsedNumber(startNumber);
      resetForm();
    };

    handleAddStartNumberClick = (e) => {
      e.stopPropagation();
      this.toggleModal();
    };

    toggleModal = () => {
      const { isModalVisible } = this.state;
      this.setState({ isModalVisible: !isModalVisible });
    };

    render() {
      const { t, service } = this.props;
      const { inactiveStartNumbers, isModalVisible } = this.state;

      return (
        <>
          <StartNumberAccordionUI
            disableAddBtn={service.startNumber !== null}
            key={service.id}
            service={service}
            startNumber={service.startNumber}
            inactiveStartNumbers={inactiveStartNumbers}
            onAddStartNumberClick={this.handleAddStartNumberClick}
            title={(
              <div key={service.deliveryType.id}>
                <strong>{service.deliveryType.name}</strong>
                <span>{service.deliveryType.abbr}</span>
                <Button onClick={() => null} className="secondary">
                  {t('settings:Agents.DeliveryServices.addStartNumberButton')}
                </Button>
              </div>
            )}
          >
            {service.deliveryType.name}
          </StartNumberAccordionUI>

          <Formik
            onSubmit={this.handleAddStartNumber}
            initialValues={{ startNumber: '' }}
            validationSchema={
              Yup.object().shape({
                startNumber: Yup
                  .number()
                  .typeError(t('settings:Agents.DeliveryServices.StartNumberModal.typeError'))
                  .required(t('settings:Agents.DeliveryServices.StartNumberModal.required')),
              })
            }
          >
            {({ handleSubmit }) => (
              <Modal centered isOpen={isModalVisible} toggle={this.toggleModal}>
                <Form
                  onSubmit={(e) => {
                    e.stopPropagation();
                    handleSubmit(e);
                  }}
                >
                  <ModalHeader>
                    {t('settings:Agents.DeliveryServices.StartNumberModal.title')}
                  </ModalHeader>
                  <ModalBody className="pt-0">
                    <FormGroup>
                      <Label>{t('settings:Agents.DeliveryServices.StartNumberModal.label')}</Label>
                      <InputWithValidation name="startNumber" />
                    </FormGroup>
                  </ModalBody>
                  <ModalFooter>
                    <Button type="submit" color="primary">
                      {t('settings:Agents.DeliveryServices.StartNumberModal.button')}
                    </Button>
                  </ModalFooter>
                </Form>
              </Modal>
            )}
          </Formik>
        </>
      );
    }
}

StartNumberAccordion.propTypes = {
  services: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  mergeAgentFormValues: PropTypes.func.isRequired,
  service: PropTypes.shape({}).isRequired,
};

export default (withTranslation(['settings', 'validation'])(StartNumberAccordion));
