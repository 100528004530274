const fileToPathReader = (file: File, setFileCallback: (callbackFile: string) => void): void => {
  const reader = new FileReader();

  reader.onloadend = () => {
    if (reader.result && typeof reader.result === 'string') {
      setFileCallback(reader.result);
    }
  };

  reader.readAsDataURL(file);
};

export default fileToPathReader;
