import gql from 'graphql-tag';

import BaseDataSource from '../BaseDataSource';
import AuthenticationHelper from '~/services/Auth';

export default class PackageDataSource extends BaseDataSource {
  createPackageTemplate(name, length, width, height) {
    const mutation = gql`
      mutation createPackageTemplate($packageTemplate: InputPackageTemplate!) {
        createPackageTemplate(packageTemplate: $packageTemplate) {
          id
          name
          length
          width
          height
        }
      }
    `;

    const variables = {
      packageTemplate: {
        name,
        length,
        width,
        height,
      },
    };

    return this.client.mutate({
      mutation,
      variables,
      context: { headers: { AccessToken: AuthenticationHelper.accessToken } },
    });
  }

  updatePackageTemplate(id, name, length, width, height) {
    const mutation = gql`
      mutation updatePackageTemplate($id: ID!, $packageTemplate: InputPackageTemplate!) {
        updatePackageTemplate(id: $id, packageTemplate: $packageTemplate) {
          id
          name
          length
          width
          height
        }
      }
    `;

    const variables = {
      id,
      packageTemplate: {
        name,
        length,
        width,
        height,
      },
    };

    return this.client.mutate({
      mutation,
      variables,
      context: { headers: { AccessToken: AuthenticationHelper.accessToken } },
    });
  }

  deletePackageTemplate(id) {
    const mutation = gql`
      mutation deletePackageTemplate($id: ID!) {
        deletePackageTemplate(id: $id) {
          id
        }
      }
    `;

    const variables = { id };

    return this.client.mutate({
      mutation,
      variables,
      context: { headers: { AccessToken: AuthenticationHelper.accessToken } },
    });
  }

  packageTemplateById(id) {
    const query = gql`
      query packageTemplateById($id: ID!) {
        packageTemplateById(id: $id) {
          id
          name
          length
          width
          height
        }
      }
    `;

    const variables = { id };

    return this.client.query({
      query,
      variables,
      context: { headers: { AccessToken: AuthenticationHelper.accessToken } },
    });
  }

  packageTemplates(cache = true) {
    const query = gql`
      query packageTemplates {
        packageTemplates {
          id
          name
          length
          width
          height
        }
      }
    `;

    const options = {
      query,
      fetchPolicy: 'no-cache',
      context: { headers: { AccessToken: AuthenticationHelper.accessToken } },
    };

    if (!cache) {
      options.fetchPolicy = 'no-cache';
    }

    return this.client.query(options);
  }
}
