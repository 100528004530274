import { Machine } from 'xstate';

function confirmModalMachine() {
  const modalInputStates = {
    initial: 'waitingForUserInput',
    states: {
      waitingForUserInput: { on: { CONFIRM_MODAL: 'confirmed', CANCEL_MODAL: 'canceled' } },
      confirmed: {},
      canceled: {},
    },
  };

  return Machine({
    id: 'confirmModal',
    type: 'parallel',
    states: {
      closeDelivery: {
        initial: 'hidden',
        states: {
          hidden: { on: { CLOSE_DELIVERY_MODAL_SHOWN: 'shown' } },
          shown: { on: { CLOSE_DELIVERY_MODAL_HIDDEN: 'hidden' }, ...modalInputStates },
        },
      },
      cancelDelivery: {
        initial: 'hidden',
        states: {
          hidden: { on: { CANCEL_DELIVERY_MODAL_SHOWN: 'shown' } },
          shown: { on: { CANCEL_DELIVERY_MODAL_HIDDEN: 'hidden' }, ...modalInputStates },
        },
      },
      stopDeliveryProcess: {
        initial: 'hidden',
        states: {
          hidden: { on: { STOP_DELIVERY_PROCESS_MODAL_SHOWN: 'shown' } },
          shown: { on: { STOP_DELIVERY_PROCESS_MODAL_HIDDEN: 'hidden' } },
        },
      },
    },
  });
}

function mutationMachine() {
  return Machine({
    id: 'mutation',
    type: 'parallel',
    states: {
      actionDropdownMutation: {
        initial: 'waiting',
        states: {
          waiting: { on: { ACTION_MUTATION_DISPATCHED: 'inProgress' } },
          inProgress: { on: { ACTION_MUTATION_RESPONSE_RECEIVED: 'waiting' } },
        },
      },
      refreshMutation: {
        initial: 'waiting',
        states: {
          waiting: { on: { REFRESH_MUTATION_DISPATCHED: 'inProgress' } },
          inProgress: { on: { REFRESH_MUTATION_RESPONSE_RECEIVED: 'waiting' } },
        },
      },
    },
  });
}

export { confirmModalMachine, mutationMachine };
