import React from 'react';
import PropTypes from 'prop-types';

import DataTableUi from 'Components/Presentational/DataTable/DataTable';

export default class DataTable extends React.Component {
  constructor(props) {
    super(props);
    this.minLoaderTimeout = 200;
    this.mounted = true;
    this.state = {
      shouldDisplayLoader: false,
      selectedRow: null,
    };
  }

  componentDidMount() {
    const { isLoaderVisible } = this.props;

    if (isLoaderVisible && !this.loaderTimeoutId) {
      this.loaderTimeoutId = setTimeout(() => {
        this.setState({ shouldDisplayLoader: true });
      }, this.minLoaderTimeout);
    } else {
      clearTimeout(this.loaderTimeoutId);
      this.loaderTimeoutId = undefined;
      this.setState({ shouldDisplayLoader: false });
    }
  }

  componentDidUpdate() {
    const { shouldDisplayLoader } = this.state;
    const { isLoaderVisible } = this.props;

    if (!this.mounted || isLoaderVisible === shouldDisplayLoader) return;

    if (isLoaderVisible && !this.loaderTimeoutId) {
      this.loaderTimeoutId = setTimeout(() => {
        this.setState({ shouldDisplayLoader: true });
      }, this.minLoaderTimeout);
    } else {
      clearTimeout(this.loaderTimeoutId);
      this.loaderTimeoutId = undefined;
      this.hideLoader();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  onRowSelect = (id) => {
    this.setState({ selectedRow: id });
  };

  hideLoader() {
    this.setState({ shouldDisplayLoader: false });
  }

  render() {
    const { shouldDisplayLoader, selectedRow } = this.state;

    return (
      <>
        <DataTableUi
          {...this.props}
          isLoaderVisible={shouldDisplayLoader || false}
          onRowSelect={this.onRowSelect}
          selectedRow={selectedRow}
        />
      </>
    );
  }
}

DataTable.propTypes = {
  config: PropTypes.shape({
    showId: PropTypes.bool,
    hideActionButtons: PropTypes.bool,
    borderless: PropTypes.bool,
    striped: PropTypes.bool,
    className: PropTypes.string,
  }),
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    index: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    className: PropTypes.string,
    rowClickDisable: PropTypes.bool,
  })).isRequired,
  actions: PropTypes.shape({
    /**
     * @deprecated Since task #1677.
     * Use item["actions"] = <DataTableActionLink | DataTableActionFunc ... /> instead.
     */
    custom: PropTypes.objectOf(PropTypes.shape({
      type: PropTypes.oneOf(['link', 'function']).isRequired,
      ref: (props, propName) => {
        const { type } = props;
        const { hasOwnProperty } = Object.prototype;

        if (type === 'link' && !hasOwnProperty.call(props, propName)) {
          return new Error(
            'Please provide ref for type "link"',
          );
        }

        return null;
      },
      func: (props, propName) => {
        const { type } = props;
        const { hasOwnProperty } = Object.prototype;

        if (type === 'function' && !hasOwnProperty.call(props, propName)) {
          return new Error(
            'Please provide func for type "function"',
          );
        }

        return null;
      },
      params: (props, propName) => {
        const { type } = props;
        const { hasOwnProperty } = Object.prototype;

        if (type === 'function' && !hasOwnProperty.call(props, propName)) {
          return new Error(
            'Please provide params for type "function"',
          );
        }

        return null;
      },
      icon: PropTypes.shape({
        class: PropTypes.array.isRequired,
        size: PropTypes.string,
      }),
      text: PropTypes.string.isRequired,
    })),
    rowClick: PropTypes.shape({
      type: PropTypes.oneOf(['link', 'function']).isRequired,
      ref: (props, propName) => {
        const { type } = props;
        const { hasOwnProperty } = Object.prototype;

        if (type === 'link' && !hasOwnProperty.call(props, propName)) {
          return new Error(
            'Please provide ref for type "link"',
          );
        }

        return null;
      },
      func: (props, propName) => {
        const { type } = props;
        const { hasOwnProperty } = Object.prototype;

        if (type === 'function' && !hasOwnProperty.call(props, propName)) {
          return new Error(
            'Please provide func for type "function"',
          );
        }

        return null;
      },
      params: (props, propName) => {
        const { type } = props;
        const { hasOwnProperty } = Object.prototype;

        if (type === 'function' && !hasOwnProperty.call(props, propName)) {
          return new Error(
            'Please provide params for type "function"',
          );
        }

        return null;
      },
    }),
  }),
  isLoaderVisible: PropTypes.bool.isRequired,
};

DataTable.defaultProps = {
  config: {},
  actions: {},
};
