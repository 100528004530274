import configurator from '../../../src/2020/services/Configurator';

export default class Hotjar {
  static init() {
    if (!configurator.config.hotjarId) return;

    /* eslint-disable */
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:configurator.config.hotjarId,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    /* eslint-enable */
  }
}
