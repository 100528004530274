import React from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DataTable from 'Containers/DataTable/DataTable';

const CollectionPlacesPreviewUI = ({ data, loading, dataTableStructure }) => {
  const { t } = useTranslation('settings');

  return (
    <Container className="collectionPlaces">
      <Row className="align-items-center right-col-spacing">
        <Col md={7}>
          <h1>{t('CollectionPlaces.title')}</h1>
        </Col>
        <Col md={5} className="text-right">
          <Link to="/settings/collection-places/new">
            <Button color="primary" size="sm" className="align-middle">
              <FontAwesomeIcon icon={['fas', 'plus']} fixedWidth />
              {t('CollectionPlaces.newPlaceButton')}
            </Button>
          </Link>
        </Col>
      </Row>
      <DataTable
        data={(!loading && data) ? data : []}
        columns={dataTableStructure.columns}
        actions={dataTableStructure.actions}
        config={dataTableStructure.config}
        isLoaderVisible={loading}
      />
    </Container>
  );
};

CollectionPlacesPreviewUI.defaultProps = { data: null };

CollectionPlacesPreviewUI.propTypes = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  dataTableStructure: PropTypes.shape({}).isRequired,
};

export default CollectionPlacesPreviewUI;
