import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Container,
  Form,
  FormGroup,
  Label,
} from 'reactstrap';

import EshopContactForm from './EshopContactForm';
import FormikScroll from '~/components/FormikScroll/FormikScroll';
import InputField from '~/components/InputField/InputField';
import InlineFormRow from '~/components/InlineFormRow/InlineFormRow';
import SubmitButton from '~/components/SubmitButton/SubmitButton';
import SimpleFileInput from '~/components/SimpleFileInput/SimpleFileInput';
import { formatBackendAssetUrl } from '#/shared/utilities';
import { EshopLocalizationConfig, EshopQuery } from '../types';
import { schema } from './validationSchema';

export type LocalizationConfigValues = {
  id?: string;
  country: Nullable<string>;
  termsAndConditionsUrl: string;
  privacyPolicyUrl: string;
  contactUrl: string;
  supportPhone: string;
  supportEmail: string;
  homepageUrl: string;
}

export type EshopFormValues = {
  name: string;
  webUrl: string;
  logo: File | string;
  facebookUrl: string;
  instagramUrl: string;
  twitterUrl: string;
  linkedInUrl: string;
  youtubeUrl: string;
  defaultLocalizationConfig: LocalizationConfigValues;
}

type Props = {
  eshop?: EshopQuery;
  defaultLocalizationConfig: Nullable<EshopLocalizationConfig>;
  onSubmit: (values: EshopFormValues) => void;
};

const EshopForm: React.FC<Props> = ({ eshop, onSubmit, defaultLocalizationConfig }) => {
  const { t } = useTranslation(['settings', 'validation']);
  const history = useHistory();

  const initialValues: EshopFormValues = {
    name: eshop?.name ?? '',
    webUrl: eshop?.webUrl ?? '',
    logo: eshop?.logo?.url ?? '',
    facebookUrl: eshop?.facebookUrl ?? '',
    instagramUrl: eshop?.instagramUrl ?? '',
    twitterUrl: eshop?.twitterUrl ?? '',
    linkedInUrl: eshop?.linkedInUrl ?? '',
    youtubeUrl: eshop?.youTubeUrl ?? '',
    defaultLocalizationConfig: {
      country: null,
      id: defaultLocalizationConfig?.id,
      termsAndConditionsUrl: defaultLocalizationConfig?.termsAndConditionsUrl ?? '',
      privacyPolicyUrl: defaultLocalizationConfig?.privacyPolicyUrl ?? '',
      contactUrl: defaultLocalizationConfig?.contactUrl ?? '',
      supportPhone: defaultLocalizationConfig?.supportPhone ?? '',
      supportEmail: defaultLocalizationConfig?.supportEmail ?? '',
      homepageUrl: defaultLocalizationConfig?.homepageUrl ?? '',
    },
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={schema(t)}
      enableReinitialize
    >
      {({ handleSubmit, isSubmitting, dirty, resetForm }) => (
        <Form
          onSubmit={handleSubmit}
          noValidate
        >
          <FormikScroll />
          <Container>
            <h1 className="pb-3">{t('settings:Eshop.title')}</h1>

            <InlineFormRow
              sectionTitle={t('settings:Eshop.General.title')}
              description={t('settings:Eshop.General.description')}
            >
              <FormGroup>
                <Label>{t('settings:Eshop.General.Name.label')}</Label>
                <InputField
                  name="name"
                  type="text"
                  placeholder={t('settings:Eshop.General.Name.placeholder')}
                />
              </FormGroup>
              <FormGroup>
                <Label>{t('settings:Eshop.General.WebUrl.label')}</Label>
                <InputField
                  name="webUrl"
                  type="url"
                  placeholder={t('settings:Eshop.General.WebUrl.placeholder')}
                />
              </FormGroup>
              <FormGroup>
                <SimpleFileInput
                  initialPreviewUrl={initialValues.logo ? formatBackendAssetUrl(initialValues.logo) : null}
                  label={t('settings:Eshop.General.Logo.label')}
                  name="logo"
                  accept="image/png, image/jpeg"
                />
              </FormGroup>
            </InlineFormRow>

            <hr className="mb-5 mt-4" />
            <InlineFormRow
              sectionTitle={t('settings:Eshop.Socials.title')}
              description={t('settings:Eshop.Socials.description')}
            >
              <FormGroup>
                <Label>{t('settings:Eshop.Socials.FacebookUrl.label')}</Label>
                <InputField
                  name="facebookUrl"
                  type="url"
                  placeholder={t('settings:Eshop.Socials.FacebookUrl.placeholder')}
                />
              </FormGroup>
              <FormGroup>
                <Label>{t('settings:Eshop.Socials.InstagramUrl.label')}</Label>
                <InputField
                  name="instagramUrl"
                  type="url"
                  placeholder={t('settings:Eshop.Socials.InstagramUrl.placeholder')}
                />
              </FormGroup>
              <FormGroup>
                <Label>{t('settings:Eshop.Socials.TwitterUrl.label')}</Label>
                <InputField
                  name="twitterUrl"
                  type="url"
                  placeholder={t('settings:Eshop.Socials.TwitterUrl.placeholder')}
                />
              </FormGroup>
              <FormGroup>
                <Label>{t('settings:Eshop.Socials.LinkedInUrl.label')}</Label>
                <InputField
                  name="linkedInUrl"
                  type="url"
                  placeholder={t('settings:Eshop.Socials.LinkedInUrl.placeholder')}
                />
              </FormGroup>
              <FormGroup>
                <Label>{t('settings:Eshop.Socials.YoutubeUrl.label')}</Label>
                <InputField
                  name="youtubeUrl"
                  type="url"
                  placeholder={t('settings:Eshop.Socials.YoutubeUrl.placeholder')}
                />
              </FormGroup>
            </InlineFormRow>

            <hr className="mb-5 mt-4" />
            <InlineFormRow
              sectionTitle={t('settings:Eshop.Contact.title')}
              description={t('settings:Eshop.Contact.description')}
            >
              <EshopContactForm inputNamePrefix="defaultLocalizationConfig." />
            </InlineFormRow>

            <hr className="mb-3" />
            <div className="d-flex justify-content-start align-content-center">
              <SubmitButton
                color="primary"
                type="submit"
                isLoading={isSubmitting}
              >
                {t('settings:Eshop.submitButton')}
              </SubmitButton>
              <Button
                className="ml-2 mr-5"
                color="primary"
                outline
                onClick={() => history.push('/settings')}
              >
                {t('settings:Eshop.cancelButton')}
              </Button>
              <Button
                color="dark"
                size="xs"
                className="align-middle ml-md-auto"
                disabled={!dirty}
                onClick={() => resetForm()}
              >
                <FontAwesomeIcon icon={['fas', 'trash']} fixedWidth />
                {t('settings:Eshop.discardChangesButton')}
              </Button>
            </div>
          </Container>
        </Form>
      )}
    </Formik>
  );
};

export default EshopForm;
