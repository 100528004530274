import React, { useState } from 'react';
import {
  Button,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { ValueType } from 'react-select';
import { useTranslation } from 'react-i18next';

import CountrySelect, { Option } from 'Containers/Deliveries/New/CountrySelect/CountrySelect';
import { ModalProps } from '~/hooks/useModal';
import DeliveryActionCreators from '#/store/actions/DeliveryActionCreators';

import css from '../DeliveryFilter.module.scss';

type Props = ModalProps;

const DeliveryFilterModal: React.FC<Props> = ({
  isOpen,
  onToggle,
}) => {
  const { t } = useTranslation('deliveries');
  const dispatch = useDispatch();
  const filter = useSelector((state: any) => state.deliveryReducer.filter);
  const [country, setCountry] = useState<string>(filter.recipientCountry || '');

  const handleChange = (option: ValueType<Option>) => {
    const selectedCountry = Array.isArray(option) ? option[0] : option;

    setCountry(selectedCountry.value);
  };

  const handleConfirm = (): void => {
    dispatch(DeliveryActionCreators.setRecipientCountry(country));
    onToggle();
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => onToggle()}
      centered
      modalTransition={{ timeout: 150 }}
    >
      <ModalHeader toggle={() => onToggle()}>
        {t('FilterModal.title')}
      </ModalHeader>

      <ModalBody className="pt-0">
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label>
                {t('FilterModal.countrySelectLabel')}
              </Label>
              <CountrySelect
                name="country"
                // `value` must be of type `string` due to `CustomSelect` implementation
                // (see CustomSelect.tsx:81)
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                value={country}
                onChange={handleChange}
                className={css.input}
                selectAll
              />
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>

      <ModalFooter className="border-0">
        <Button
          onClick={handleConfirm}
          color="primary"
        >
          {t('FilterModal.submitButton')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DeliveryFilterModal;
