import React from 'react';
import produce from 'immer';
import { Link } from 'react-router-dom';
import { Spinner, Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import CompanyQueryFragments from 'Models/company/Query/Fragments/CompanyQueryFragments';
import SettingsLayout from 'Components/Settings/SettingsLayout';
import SettingsBase from 'Containers/Settings/_base/SettingsBase';
import { generateReactKey } from 'Shared/utilities';
import Tooltip from 'Components/Presentational/Tooltip/Tooltip';
import CompanyQueryFactory from 'Models/company/Query/Factories/CompanyQueryFactory';
import DataTableActionLink from 'Components/Presentational/DataTable/DataTableActions/DataTableActionLink';
import CustomQuery from 'Models/Components/CustomQuery';
import AgentsUI from '../../Presentational/AgentsUI/AgentsUI';
import { getCarrierLogoUrl } from '~/services/utils';

class AgentsPreview extends SettingsBase {
  static getDeliveryTypes(services) {
    const deliveryTypes = services.map((ser) => ser.deliveryType);

    const hiddenDeliveryTypeCount = deliveryTypes.length - 3;
    const hiddenDeliveryTypeNames = deliveryTypes.slice(3).map((i) => i.name);
    const shownDeliveryTypeNames = deliveryTypes.slice(0, 3).map((i) => i.name);

    return {
      names: deliveryTypes.map((i) => i.name),
      shown: { names: shownDeliveryTypeNames },
      hidden: {
        count: hiddenDeliveryTypeCount,
        names: hiddenDeliveryTypeNames,
      },
    };
  }

  formatDataForTable(credentials) {
    const { t } = this.props;

    return credentials.map((credential) => {
      const tableRow = {};
      const { agent, services } = credential;

      tableRow.id = generateReactKey();
      tableRow.logo = (
        <img
          src={getCarrierLogoUrl(agent.logoSlug)}
          alt={agent.originalName}
          title={agent.originalName}
          className="agent-logo"
        />
      );
      tableRow.name = <strong>{agent.name}</strong>;

      tableRow.deliveryTypes = (() => {
        const deliveryTypes = AgentsPreview.getDeliveryTypes(services);
        return (
          <>
            <span>{deliveryTypes.shown.names.join(', ')}</span>
            {deliveryTypes.hidden.count > 0 && (
              <Tooltip
                id={generateReactKey()}
                linkText={`+${deliveryTypes.hidden.count}`}
                text={deliveryTypes.hidden.names.join(', ')}
                linkIconHide
                linkIcon={[]}
                linkIconPosition="after"
                placement="top"
              />
            )}
          </>
        );
      })();

      tableRow.actions = (
        <>
          {credential.status === 'incomplete' ? (
            <Link to={`/settings/agents/${credential.id}/edit`}>
              <Button
                color="primary"
                size="sm"
              >
                {t('settings:Agents.connectAgentButton')}
              </Button>
            </Link>
          ) : (
            <DataTableActionLink
              to={`/settings/agents/${credential.id}/edit`}
              icon={{ class: ['far', 'edit'] }}
              tooltip={t('settings:Agents.tooltip')}
            />
          )}
        </>
      );

      return tableRow;
    });
  }

  constructor(props) {
    super(props, 'comm');

    this.table = {
      columns: [
        {
          title: 'Logo',
          index: 'logo',
          key: '1',
        },
        {
          title: 'Název',
          index: 'name',
          key: '2',
        },
        {
          title: 'Typy doručení',
          index: 'deliveryTypes',
          key: '3',
        },
      ],
      config: {
        striped: false,
        borderless: false,
        hideActionButtons: false,
        className: 'basic pickup-places',
      },
      isLoaderVisible: false,
    };
  }

  componentDidMount() {
    this.displayPage();
  }

  displayPage() {
    const state = produce(this.state, (draft) => {
      draft.isPageDisplayed = true;
    });

    this.setState(state);
  }

  render() {
    return (
      <SettingsLayout
        goBack="/settings"
        leftBlock={this.state.leftBlock}
        rightBlock={this.state.rightBlock}
        checkedItems={this.state.checkedItems}
        isOpened={this.state.isPageDisplayed}
      >
        <CustomQuery
          query={CompanyQueryFactory.company('...CredentialsPreview', CompanyQueryFragments.credentialsPreview())}
        >
          {({ data, error, loading }) => {
            if (loading) return <Spinner />;
            if (error) return null;

            const { company } = data;
            const { credentials } = company;

            return (
              <AgentsUI
                table={this.table}
                data={this.formatDataForTable(credentials)}
              />
            );
          }}
        </CustomQuery>
      </SettingsLayout>
    );
  }
}

export default withTranslation(['settings'])(AgentsPreview);
