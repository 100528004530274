import { TFunction } from 'i18next';
import { mixed, object, string } from 'yup';

export const validationSchema = (t: TFunction) => {
  return object().shape({
    title: string()
      .required(t('validation:TemplateName.required'))
      .max(255, t('validation:TemplateName.maxLength', { count: 255 })),
    logoImg: mixed(),
    faviconImg: mixed(),
    desktopBackground: mixed(),
    mobileBackground: mixed(),
    primaryColor: string().required(t('settings:Branding.TrackAndTrace.Form.Design.Validation.primaryColor')),
    linkColor: string().required(t('settings:Branding.TrackAndTrace.Form.Design.Validation.linkColor')),
    headerBackgroundColor: string().required(t('settings:Branding.TrackAndTrace.Form.Design.Validation.headerBackgroundColor')),
    footerBackgroundColor: string().required(t('settings:Branding.TrackAndTrace.Form.Design.Validation.footerBackgroundColor')),
    tagColor: mixed().when('enabledRating', {
      is: true,
      then: mixed().required(t('settings:Branding.TrackAndTrace.Form.Design.Validation.tagColor')),
    }),
    tagBackgroundColor: mixed().when('enabledRating', {
      is: true,
      then: mixed().required(t('settings:Branding.TrackAndTrace.Form.Design.Validation.tagBackgroundColor')),
    }),
    selectedTagColor: mixed().when('enabledRating', {
      is: true,
      then: mixed().required(t('settings:Branding.TrackAndTrace.Form.Design.Validation.selectedTagColor')),
    }),
    selectedTagBackgroundColor: mixed().when('enabledRating', {
      is: true,
      then: mixed().required(t('settings:Branding.TrackAndTrace.Form.Design.Validation.selectedTagBackgroundColor')),
    }),
  });
};
