import { Button } from 'reactstrap';
import React from 'react';

import { toast } from '#/shared/utilities';
import useAuthMutation from '~/hooks/useAuthMutation';
import RESEND_VERIFICATION_EMAIL from './resendCompanyVerificationEmail.gql';

const EmailVerificationButton: React.FC = ({ children }) => {
  const [resendVerificationEmail, { loading }] = useAuthMutation(RESEND_VERIFICATION_EMAIL, {
    onCompleted: () => {
      toast.success('Hotovo! Zkontrolujte, prosím, Vaši e-mailovou schránku.');
    },
  });

  const handleResendEmail = async () => {
    if (loading) {
      return;
    }

    try {
      await resendVerificationEmail();
    } catch (e) {
      toast.error('Odeslání e-mailu se nezdařilo. Kontaktuje, prosím, naši zákaznickou podporu.');
    }
  };

  return (
    <Button
      type="button"
      color="primary"
      outline
      className="banner-btn"
      disabled={loading}
      onClick={handleResendEmail}
    >
      {children}
    </Button>
  );
};

export default EmailVerificationButton;
