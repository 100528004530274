import React from 'react';

import { useTranslation } from 'react-i18next';
import TimelineIconAccordion from '../_TimelineIconAccordion/TimelineIconAccordion';

const ActionAccordion = ({ events }) => {
  const { t } = useTranslation('deliveries');

  return (
    <TimelineIconAccordion
      theme="dark"
      icon={{
        type: 'foxdeli',
        icon: 'warning_bigger',
        className: 'warning',
        size: '1_2',
      }}
      className="note"
      title={(
        <>
          <h4>{events[0].title}</h4>
          <div className="subtitle">
            {events[0].note && (
              <h5>
                {t('DeliveryDetail.Timeline.Notes.noteLabel')}
                {': '}
                {events[0].note}
              </h5>
            )}
            <span>{events[0].on}</span>
            <span>&nbsp;|&nbsp;</span>
            <span>{events[0].author.name}</span>
          </div>
        </>
      )}
    />
  );
};

export default ActionAccordion;
