import React from 'react';
import { useTranslation } from 'react-i18next';

import { generateReactKey } from 'Shared/utilities';
import TimelineIconAccordion from '../_TimelineIconAccordion/TimelineIconAccordion';

const NoteWithCategory = ({ events, onEditClick, canUpdate }) => {
  const { t } = useTranslation('deliveries');

  return (
    <>
      <TimelineIconAccordion
        theme="dark"
        icon={{ type: 'fw', icon: 'comment' }}
        key={generateReactKey()}
        title={(
          <>
            <h4>
              {t('DeliveryDetail.Timeline.Notes.noteLabel')}{': '}{events[0].title}
            </h4>
            <div className="subtitle">
              {(events[0].author) && (
                <>
                  <span>&nbsp;|&nbsp;</span>
                  <span>
                    {t('DeliveryDetail.Timeline.Notes.userLabel')}{': '}{events[0].author.name}
                  </span>
                </>
              )}
            </div>
          </>
        )}
      >
        {events.map((event) => (
          <div className="event">
            <span>{events.on}</span>
            <span>{event.on}</span>

            {canUpdate && (
              <>
                <span>&nbsp;|&nbsp;</span>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <span style={{ cursor: 'pointer' }} onClick={() => onEditClick(event.relatedObjectId)}>
                  {t('DeliveryDetail.Timeline.Notes.editButton')}
                </span>
              </>
            )}
          </div>
        ))}
      </TimelineIconAccordion>
    </>
  );
};

export default NoteWithCategory;
