import gql from 'graphql-tag';

export default class FilesFragments {
  static get fileBase64() {
    return gql`
            fragment FileBase64 on FileBase64 {
                name
                rawSource
            }
        `;
  }
}
