import React from 'react';
import produce from 'immer';
import PropTypes from 'prop-types';

import SettingsLayout from 'Components/Settings/SettingsLayout';
import basePaths from 'Routes/paths/base.path';
import CompanyDataSource from 'Models/company/CompanyDataSource';

class SettingsBase extends React.Component {
  constructor(props) {
    super(props);

    const { t } = this.props;

    this.company = new CompanyDataSource();
    this.state = {
      children: null,
      checkedItems: [],
      isOpened: false,
      leftBlock: {
        header: {
          title: '',
          subTitle: '',
          button: {
            text: t('settings:Profile.Navigation.newCompanyButton'),
            path: `${basePaths.settings}/company/new`,
            class: {
              color: 'secondary',
              size: 'xs',
              outline: false,
            },
          },
        },
        main: [
          {
            name: t('settings:Profile.Navigation.title'),
            items: [
              {
                name: t('settings:Profile.Navigation.profileItem'),
                id: 'com_gen_information',
                icon: {
                  class: [],
                  type: 'svg',
                  icon: 'users',
                },
                path: `${basePaths.settings}/my-profile`,
              },
              {
                name: t('settings:Profile.Navigation.logoutItem'),
                id: 'com_gen_license',
                icon: {
                  class: ['fas', 'sign-out-alt'],
                  size: '1x',
                  type: 'font',
                  icon: 'logout',
                },
                path: `${basePaths.auth}/logout`,
              },
            ],
          },
        ],
      },
      rightBlock: {
        header: {
          title: '',
          subTitle: '',
          button: {
            text: '',
            path: '',
            class: {
              color: 'secondary',
              size: 'xs',
              outline: false,
            },
          },
          percentDone: 20,
        },
        customHeader: null,
        main: {
          left: [
            {
              name: '',
              items: [],
            },
          ],
          right: [
            {
              name: '',
              items: [],
            },
          ],
        },
      },
    };

    const { user } = this.props;
    const { leftBlock, rightBlock } = this.state;

    leftBlock.header.defaultTitle = user.data.login;
    rightBlock.header.defaultTitle = '';
  }

  async componentDidMount() {
    const { t } = this.props;
    const { data: { company: { name: companyName } } } = await this.company.selectById(null, 'name');

    const state = produce(this.state, (draft) => {
      draft.leftBlock.header.title = companyName || t('settings:Profile.Navigation.notSpecifiedCompanyName');
    });

    this.setState(state);
  }

  render() {
    const { leftBlock, rightBlock, checkedItems, isOpened, children } = this.state;

    return (
      <SettingsLayout
        leftBlock={leftBlock}
        rightBlock={rightBlock}
        checkedItems={checkedItems}
        isOpened={isOpened}
      >
        {children}
      </SettingsLayout>
    );
  }
}

SettingsBase.propTypes = { user: PropTypes.shape({}).isRequired };

export default SettingsBase;
