import * as types from './actionTypes';

export default class DeliveryActionCreators {
  static setOrderBy(orderBy) {
    return {
      type: types.DELIVERY_ORDER_BY_CHANGED,
      payload: orderBy,
    };
  }

  static setOrderByDirection(orderByDirection) {
    return {
      type: types.DELIVERY_ORDER_BY_DIRECTION_CHANGED,
      payload: orderByDirection,
    };
  }

  static setRecipientCountry(country) {
    return {
      type: types.DELIVERY_RECIPIENT_COUNTRY_CHANGED,
      payload: country,
    };
  }

  static setFilterAndFlags(filter) {
    return {
      type: types.DELIVERY_FILTER_AND_FLAGS_CHANGED,
      payload: filter,
    };
  }

  static setCSVFile(csvFile) {
    return {
      type: types.DELIVERY_UPLOAD_CSV_FILE_CHANGED,
      payload: csvFile,
    };
  }

  static setPage(page) {
    return {
      type: types.DELIVERY_PAGE_CHANGED,
      payload: page,
    };
  }

  static setFilters(filters) {
    return {
      type: types.DELIVERY_FILTERS_CHANGED,
      payload: filters,
    };
  }
}
