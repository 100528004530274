import React from 'react';
import PropTypes from 'prop-types';

import Logout from 'Containers/Auth/Logout/Logout';
import IndexedDBDataManager from 'Containers/IndexedDBDataManager/IndexedDBDataManager';
import TokenChecker from './TokenChecker/TokenChecker';
import UserActivityChecker from './UserActivityChecker/UserActivityChecker';

const protectedRoute = (Component) => {
  class ProtectedRoute extends React.Component {
    constructor(props) {
      super(props);
      this.state = { isAuthenticated: null };
    }

    setAuthenticated = (isAuthenticated) => {
      this.setState({ isAuthenticated });
    };

    render() {
      const { isAuthenticated } = this.state;

      let content = (
        <TokenChecker setAuthenticated={this.setAuthenticated}>
          <div />
        </TokenChecker>
      );

      if (isAuthenticated === true) {
        content = (
          <IndexedDBDataManager>
            <TokenChecker setAuthenticated={this.setAuthenticated}>
              <UserActivityChecker setAuthenticated={this.setAuthenticated}>
                <Component {...this.props} />
              </UserActivityChecker>
            </TokenChecker>
          </IndexedDBDataManager>
        );
      } else if (isAuthenticated === false) {
        sessionStorage.setItem('homepage', window.location.pathname + window.location.search);
        content = <Logout />;
      }

      return content;
    }
  }

  ProtectedRoute.propTypes = {
    location: PropTypes.shape({}).isRequired,
    history: PropTypes.shape({}).isRequired,
  };

  return ProtectedRoute;
};

export default protectedRoute;
