import React from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

import DeliveryQueryFactory from 'Models/delivery/Queries/Factories/DeliveryQueryFactory';
import DeliveryFragments from 'Models/delivery/Queries/Fragments/DeliveryFragments';
import { customManualMutation } from 'Models/Components/CustomMutation';
import ApolloQueryTypes from 'Types/apollo/ApolloQueryTypes';
import { toast } from 'Shared/utilities';
import CloseDeliveryToastBody from 'Components/Presentational/CloseDeliveryToastBody/CloseDeliveryToastBody';
import LocalDeliveryTypes from '../../LocalDeliveryTypes/LocalDeliveryTypes';
import CloseDeliveryUI from '../../Presentational/CloseDelivery/CloseDelivery';

/* eslint-disable jsx-a11y/click-events-have-key-events */
class CloseDelivery extends React.Component {
  async componentDidUpdate(prevProps) {
    const { modalState: prevModalState } = prevProps;
    const { modalState, modalService } = this.props;

    if (prevModalState.shown && modalState.shown) {
      if (prevModalState.shown === 'waitingForUserInput' && modalState.shown === 'confirmed') {
        await this.closeDelivery();
      }

      modalService.send('CLOSE_DELIVERY_MODAL_HIDDEN');
    }
  }

  showModal = () => {
    const { modalService } = this.props;
    modalService.send('CLOSE_DELIVERY_MODAL_SHOWN');
  };

  closeDelivery = async () => {
    const { deliveryId, refetchDeliveryDetail, mutationService, client } = this.props;

    mutationService.send('ACTION_MUTATION_DISPATCHED');

    const closeDeliveryResponse = await customManualMutation({
      client,
      mutation: DeliveryQueryFactory.closeDeliveriesFromList(
        '...closedDeliveries',
        DeliveryFragments.closedDeliveries(),
      ),
      variables: { deliveriesIds: [deliveryId] },
    });

    mutationService.send('ACTION_MUTATION_RESPONSE_RECEIVED');

    const { closeDeliveriesFromList } = closeDeliveryResponse.data;

    if (closeDeliveriesFromList.success) {
      refetchDeliveryDetail();
    }

    this.showDeliveryCloseToasts(closeDeliveriesFromList);
  };

  showDeliveryCloseToasts(response) {
    const toastFunction = response.success ? 'success' : 'info';

    toast[toastFunction](
      <CloseDeliveryToastBody
        failedDeliveries={response.failedDeliveries ?? []}
        messages={response.messages}
        success={response.success}
      />,
      response.success ? undefined : { closeOnClick: false, draggable: false },
    );
  }

  render() {
    const { canClose } = this.props;

    return <CloseDeliveryUI canClose={canClose} onCloseClick={this.showModal} />;
  }
}

CloseDelivery.propTypes = {
  deliveryId: LocalDeliveryTypes.every().deliveryId.isRequired,
  refetchDeliveryDetail: LocalDeliveryTypes.every().refetchDeliveryDetail.isRequired,
  mutationService: LocalDeliveryTypes.every().mutationService.isRequired,
  modalService: LocalDeliveryTypes.every().modalService.isRequired,
  modalState: LocalDeliveryTypes.every().modalState.isRequired,
  canClose: LocalDeliveryTypes.every().canClose.isRequired,
  client: ApolloQueryTypes.client().isRequired,
  history: PropTypes.shape({}).isRequired,
};

export default withRouter(CloseDelivery);
