import React from 'react';
import PropTypes from 'prop-types';

const DeliveryTableAgent = (props) => {
  const { imgAlt, img, deliveryType } = props;

  return (
    <div className="agent">
      <img
        alt={imgAlt}
        src={img}
        className="on-table-hover agent-logo"
        title={imgAlt}
      />
      {deliveryType && deliveryType !== '' && <span className="d-block">{deliveryType}</span>}
    </div>
  );
};

DeliveryTableAgent.defaultProps = { deliveryType: '' };

DeliveryTableAgent.propTypes = {
  img: PropTypes.string.isRequired,
  imgAlt: PropTypes.string.isRequired,
  deliveryType: PropTypes.string,
};

export default DeliveryTableAgent;
