import gql from 'graphql-tag';

import BaseDataSource from 'Models/BaseDataSource';
import AuthenticationHelper from '~/services/Auth';

export default class GlobalDataSource extends BaseDataSource {
  search(keyWord, limit, lastDaysCount, fields) {
    const query = gql`
      query search($keyWord: String!, $limit: Int!, $lastDaysCount: Int) {
        search(keyWord: $keyWord, limit: $limit, lastDaysCount: $lastDaysCount) {
          ${fields}
        }
      }
    `;

    const variables = { keyWord, limit, lastDaysCount };

    return this.client.query({
      query,
      variables,
      context: { headers: { AccessToken: AuthenticationHelper.accessToken } },
    });
  }
}
