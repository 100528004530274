import * as actionTypes from 'StoreActions/actionTypes';
import { updateObject } from 'Shared/utilities';

const initialState = {
  isLoading: false,
  error: {
    inError: false,
    data: {
      code: null,
      msg: null,
      msgDesc: null,
    },
  },
};

const loginStart = (state, action) => updateObject(state, {
  isLoading: true,
  error: { inError: false },
});

const loginFail = (state, action) => updateObject(state, {
  isLoading: false,
  error: {
    inError: true,
    data: action.error,
  },
});

const loginSuccess = (state, action) => updateObject(state, { isLoading: false });

const logoutSuccess = (state, action) => updateObject(state, initialState);

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.USER_LOGGING_IN:
      return loginStart(state, action);
    case actionTypes.USER_LOGGED_IN:
      return loginSuccess(state, action);
    case actionTypes.USER_LOGGING_IN_FAILED:
      return loginFail(state, action);
    case actionTypes.USER_LOGGED_OUT:
      return logoutSuccess(state, action);
    default:
      return state;
  }
};

export default reducer;
