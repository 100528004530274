import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { Input, Label } from 'reactstrap';
import { SketchPicker } from 'react-color';

type Props = {
  name?: string,
  label?: string | JSX.Element,
  color: string,
  onChange: (event: any) => any,
  onBlur: (event: any) => any,
  valid?: boolean,
  invalid?: boolean,
}

type ColorPickerChangeValue = {
  hex: string;
}

const ColorPicker: React.FC<Props> = ({
  color,
  name,
  label = 'Primární barva',
  onChange,
  onBlur,
  valid,
  invalid,
}: Props) => {
  const [isDisplayed, setDisplayed] = useState(false);
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    if (name && color) {
      setFieldValue(name, color);
    }
  }, [color, name]);

  const handleColorChange = (changedColor: ColorPickerChangeValue) => {
    const { hex } = changedColor;
    const fakeEvent = {
      target: {
        name,
        value: hex,
      },
    };

    onChange(fakeEvent);
    onBlur(fakeEvent);
  };

  return (
    <>
      <Label htmlFor={name} style={{ display: 'block' }} className="mt-3">
        {label}
      </Label>
      <div className="custom-input-color">
        <div className="picker" style={{ display: isDisplayed ? 'block' : 'none' }}>
          {/* TODO: Use a button instead?... */}
          {/* eslint-disable-next-line max-len */}
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
          <div
            className="cover"
            role="button"
            onClick={() => setDisplayed(false)}
          />
          <SketchPicker
            color={color ?? ''}
            onChange={handleColorChange}
          />
        </div>
        <div
          aria-label="color picker"
          tabIndex={0}
          role="button"
          className="rounded preview"
          onClick={() => setDisplayed(!isDisplayed)}
          onKeyDown={({ key }) => {
            if (key === 'Enter') setDisplayed(!isDisplayed);
          }}
          style={{ backgroundColor: color, cursor: 'pointer' }}
        />
        <Input
          name={name}
          data-test={`color-input-${name}`}
          type="text"
          value={color}
          onChange={(e) => handleColorChange({ hex: e.target.value })}
          placeholder="#000"
          valid={valid}
          invalid={invalid}
        />
      </div>
    </>
  );
};

export default ColorPicker;
