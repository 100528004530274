import React from 'react';
import { Spinner } from 'reactstrap';

import CustomQuery from 'Models/Components/CustomQuery';
import CollectionPlaceQueryFactory
  from 'Models/collectionPlace/Query/Factories/CollectionPlaceQueryFactory';
import CollectionPlaceFragments
  from 'Models/collectionPlace/Query/Fragments/CollectionPlaceFragments';

const withAllCollectionPlaces = (Component) => (props) => (
  <CustomQuery
    query={CollectionPlaceQueryFactory.collectionPlaces('...AgentFormCollectionPlace', CollectionPlaceFragments.agentFormCollectionPlace())}
    notifyOnNetworkStatusChange
  >
    {({
      data,
      error,
      loading,
      refetch,
    }) => {
      if (loading) return <Spinner />;
      if (error) return null;

      return (
        <Component
          {...props}
          collectionPlaces={data.collectionPlaces}
          refetchCollectionPlaces={refetch}
        />
      );
    }}
  </CustomQuery>
);

export default withAllCollectionPlaces;
