import ApolloClient from 'apollo-client';
import { BatchHttpLink } from 'apollo-link-batch-http';
import { ApolloLink } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';

import { toast } from '#/shared/utilities';
import configurator from '~/services/Configurator';

export const APOLLO_ENDPOINT_CONTEXT = 'apollo';

class GraphqlStorage {
  afterwareEvents: ApolloLink;

  apolloClient: any;

  static errorNotifier({ networkError, graphQLErrors }: any) {
    const graphQLErrorCategoryBlacklist = ['graphql'];

    if (networkError) {
      return toast.error('Server nekomunikuje. Zkuste to prosím později.');
    }

    if (graphQLErrors) {
      for (let i = 0; i < graphQLErrors.length; i += 1) {
        const error = graphQLErrors[i];

        if (!graphQLErrorCategoryBlacklist.includes(error.category)) {
          if (configurator.env === 'development') {
            toast.error(error.debugMessage || error.message);
          } else {
            toast.error(error.message);
          }
        }
      }
    }

    return null;
  }

  constructor() {
    this.afterwareEvents = new ApolloLink((operation, forward) => forward(operation)
      .map((response) => {
        const event = document.createEvent('CustomEvent');
        event.initEvent('apolloRequestFinished', false, false);
        document.dispatchEvent(event);

        return response;
      }));

    this.initApolloClient();
  }

  initApolloClient() {
    const apolloEndpointLink = new HttpLink({
      uri: `${configurator.config.apolloGqlUrl}/dashboard`, // TODO: pass endpoint URL path in context
    });

    const phpEndpointLink = onError(({ networkError, graphQLErrors }) => {
      GraphqlStorage.errorNotifier({ networkError, graphQLErrors });
    })
      .concat(this.afterwareEvents)
      .concat(new BatchHttpLink({
        uri: configurator.config.graphqlUrl,
        batchInterval: 20,
      }));

    this.apolloClient = new ApolloClient({
      link: ApolloLink.split(
        (operation) => operation.getContext().endpoint === APOLLO_ENDPOINT_CONTEXT,
        apolloEndpointLink,
        phpEndpointLink,
      ),
      cache: new InMemoryCache(),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'no-cache',
        },
        query: {
          fetchPolicy: 'no-cache',
        },
        mutate: {
          fetchPolicy: 'no-cache',
        },
      },
    });
  }

  get client() {
    return this.apolloClient;
  }
}

export default new GraphqlStorage();
