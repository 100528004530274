import React from 'react';
import { toast as toastify } from 'react-toastify';
import { generate } from 'shortid';

import configurator from '~/services/Configurator';
import AuthenticationHelper from '~/services/Auth';

export const updateObject = (oldObject, updatedProperties) => ({
  ...oldObject,
  ...updatedProperties,
});

export const hasProperty = (object, key) => {
  const has = Object.prototype.hasOwnProperty;

  return has.call(object, key);
};

export const formatBackendAssetUrl = (url) => `${configurator.config.adminUrl}${url}`;

export const withLayout = ((Component, Layout, routeData = null) => (props) => (
  <Layout {...props}>
    <Component {...props} routeData={routeData} />
  </Layout>
));

export const clearTimeoutById = (id) => {
  if (typeof id === 'number') {
    clearTimeout(id);
  }
};

export const getUserHomepage = (urlPath) => {
  if (urlPath && /^\/.*/.test(urlPath)) {
    sessionStorage.removeItem('homepage');
    return urlPath;
  }

  const storedHomepage = sessionStorage.getItem('homepage');

  if (storedHomepage) {
    sessionStorage.removeItem('homepage');
    return storedHomepage;
  }

  return '/deliveries';
};

export const stringifyNull = (value, placeholder = '') => ((value === null || value === undefined) ? placeholder : value);

export const nullifyString = (value) => ((value === '') ? null : value);

// TODO: possibly replace with shortid.generate()
export const generateReactKey = (index = Math.random()) => (index + Math.random())
  .toString(36)
  .substring(7);

export const getGraphQLAuthenticationContext = () => ({ headers: { AccessToken: AuthenticationHelper.accessToken } });

export const nl2p = (str) => {
  if (!str) return null;

  const pList = str.split('\\n').map((x) => (
    <p key={generate()}>
      {x}
    </p>
  ));

  return <>{pList}</>;
};

export const toast = {
  info(msg, options = {}) {
    const formattedMsg = typeof msg === 'string' ? nl2p(msg) : msg;

    return toastify.info(formattedMsg, {
      autoClose: false,
      ...options,
    });
  },
  success(msg, options = {}) {
    const formattedMsg = typeof msg === 'string' ? nl2p(msg) : msg;

    return toastify.success(formattedMsg, {
      autoClose: 5000,
      ...options,
    });
  },
  error(msg, options = {}) {
    const formattedMsg = typeof msg === 'string' ? nl2p(msg) : msg;

    return toastify.error(formattedMsg, {
      autoClose: false,
      ...options,
    });
  },
};

export const getReactSelectValue = (value) => {
  if (typeof value === 'object' && value !== null) {
    return value.value;
  }

  return value;
};

export const scrollToElement = (selector) => {
  const element = document.querySelector(selector);

  if (!element) return;

  element.scrollIntoView();
};

export const getDeliveryInflectionText = (numberOfDeliveries, t) => t('common:SummaryListItemLabels.delivery', { count: numberOfDeliveries });

export const nullifyEmptyString = (value) => (value === '' ? null : value);

export const normalizeString = (input) => input.normalize('NFD')
  .replace(/[\u0300-\u036f]/g, '')
  .toLowerCase();

export const stringToIdentifier = (string) => {
  let identifier = string;

  identifier = identifier.replace(/\s/g, '-');
  identifier = identifier.toLowerCase();
  identifier = normalizeString(identifier);

  return identifier;
};

export const addSelectDefaultOption = (optionArray, defaultOptionLabel) => {
  optionArray.unshift({ value: '', label: defaultOptionLabel });

  return optionArray;
};
