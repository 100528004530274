import React from 'react';

import Icon from '~/components/Icon/Icon';
import { cssMerge } from '~/services/utils';

import css from './CloseButton.module.scss';

type Props = {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  className?: string;
};

const CloseButton: React.FC<Props> = ({ onClick, className }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={cssMerge(
        css.closeButton,
        className,
      )}
    >
      <Icon name="close" />
    </button>
  );
};

export default CloseButton;
