/* eslint-disable react/prop-types */
import React from 'react';
import formStore from '../../Stores/Form/FormStore';

const withFormStore = (Component) => (props) => {
  const { formik, name } = props;

  formStore.store.forms[name] = formik;

  return (
    <Component {...props} />
  );
};

export default withFormStore;
