// Select box default value 'SELECT'
export const hasSelectDefaultValue = function hasSelectDefaultValue(t, msg) {
  return this.test('select-has-default-value', msg || t('validation:Default.Select.required'), (value) => value !== 'SELECT');
};

// Valid phone number
export const phoneNumber = function phoneNumber(t, msg) {
  return this.matches(/^(?:\+\d{2})?\d{10}(?:,(?:\+\d{2})?\d{10})*$/, { message: msg || t('validation:Default.Phone.pattern') });
};

// Valid zip code
export const zipCode = function zipCode(t, msg) {
  return this.matches(/^\d{5}(?:[-\s]\d{4})?$/, { message: msg || t('validation:Default.ZipCode.pattern') });
};
