import React from 'react';
import { Progress } from 'reactstrap';
import { formatNumber } from '~/services/utils';

import css from './ProgressList.module.scss';

export type ProgressItem = {
  label: string,
  value: number,
  key: string,
  formattedValue?: string;
}

type Props = {
  items: ProgressItem[],
  compact?: boolean,
  className?: string,
  showProgress?: boolean,
}

const ProgressList: React.FC<Props> = ({
  items,
  compact = false,
  showProgress = true,
  className,
}) => {
  return (
    <div className={className}>
      {items.map((item) => {
        const { label, value, key, formattedValue } = item;
        return (
          <div
            className={
              `${css.progressRow} ${compact ? css.compact : ''} ${value ? '' : css.disabled} d-flex flex-nowrap align-items-center text-left`
            }
            key={key}
          >
            <span className="progress-list-label font-weight-medium">
              {label}
            </span>

            {showProgress && (
              <div className={css.progress}>
                <Progress value={value} />
              </div>
            )}

            <span className="progress-list-value font-weight-medium text-right">
              {formattedValue || formatNumber(value / 100, { style: 'percent', maximumFractionDigits: 0 })}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default ProgressList;
