// :: React
import { withTranslation } from 'react-i18next';

// :: Components
import SettingsBase from 'Containers/Settings/_base/SettingsBase';

class Settings extends SettingsBase {
  constructor(props) {
    super(props, '', false);
  }
}

export default withTranslation(['settings'])(Settings);
