import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withApollo } from 'react-apollo';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { getDeliveryInflectionText } from 'Shared/utilities';
import MainBody from 'Components/Presentational/Layout/MainBody/MainBody';
import DataTable from 'Containers/DataTable/DataTable';
import TicketPrintModal from 'Containers/TicketPrintModal/TicketPrintModal';
import DeliveryTableAgent from 'Components/Deliveries/DeliveryTableAgent';
import DeliveryTableStatus from 'Components/Presentational/DataTable/DeliveryTableStatus';
import DeliveryTableRecipient from 'Components/Deliveries/DeliveryTableRecipient';
import DeliveryTableCashOnDelivery from 'Components/Deliveries/DeliveryTableCashOnDelivery';
import { graphQLHeaders } from 'Constants/app.const';
import Base64Helper from 'Tools/Base64Helper';
import { CustomQuery } from 'Models/Components/CustomQuery';
import BatchQueryFactory from 'Models/delivery/Batch/BatchQueryFactory';
import FilesFragments from 'Models/Files/Query/Fragments/FilesFragments';
import ApolloQueryTypes from 'Types/apollo/ApolloQueryTypes';
import Loader from '../../Loader/Loader';
import { format } from '~/services/date';
import { getCarrierLogoUrl } from '~/services/utils';

const DELIVERY_BATCH_BY_ID = BatchQueryFactory.deliveryBatchById(`
    id
    created
    deliveriesNumber
    agent {
        originalName
        logoSlug
    }
    deliveries {
        id
        deliveryNumber
        type {
            name
        }
        recipient {
            contact { name surname }
        }
        stateKey
        cashOnDelivery
        cashOnDeliveryCurrency
    }
`);

const DELIVERY_BATCH_CSV_BY_ID = BatchQueryFactory.deliveryBatchCsvExportById(
  '...FileBase64',
  FilesFragments.fileBase64,
);

class BatchDetail extends Component {
  constructor(props) {
    super(props);

    const { t } = this.props;

    this.state = {
      csv: {
        name: '',
        url: '',
      },
      showTicketPrintModal: false,
    };

    this.table = {
      columns: [
        {
          title: '',
          index: 'status',
          key: '1',
          width: 200,
          className: 'middle',
        },
        {
          title: '',
          index: 'agent',
          key: '2',
          width: 200,
        },
        {
          title: '',
          index: 'recipient',
          key: '3',
          width: 200,
        },
        {
          title: '',
          index: 'cashOnDelivery',
          key: '4',
          width: 200,
        },
      ],
      actions: {
        custom: {
          detail: {
            type: 'link',
            ref: '/deliveries/:id',
            icon: { class: ['far', 'eye'] },
            text: t('batches:BatchDetail.ListItemTooltip.deliveryDetail'),
          },
        },
        rowClick: {
          type: 'link',
          ref: '/deliveries/:id',
        },
      },
      config: {
        hideActionButtons: true,
        striped: false,
        borderless: false,
        className: 'basic deliveries table',
      },
    };

    this.csvDownloader = React.createRef();
    this.totalPages = null;
  }

  formatTableData(deliveries) {
    const { t } = this.props;
    if (deliveries.length === 0) return [];

    return deliveries.deliveries.map((delivery) => {
      const currentItem = {};

      currentItem.status = (
        <DeliveryTableStatus
          number={delivery.deliveryNumber}
          status={t(`status:${delivery.stateKey}`)}
          className="status-pl"
        />
      );
      currentItem.agent = (
        <DeliveryTableAgent
          deliveryType={delivery.type.name}
          img={getCarrierLogoUrl(deliveries.agent.logoSlug)}
          imgAlt={deliveries.agent.originalName}
        />
      );
      currentItem.recipient = (
        <DeliveryTableRecipient
          name={delivery.recipient.contact.name}
          surname={delivery.recipient.contact.surname}
        />
      );
      currentItem.cashOnDelivery = (
        <DeliveryTableCashOnDelivery
          value={delivery.cashOnDelivery}
          currency={delivery.cashOnDeliveryCurrency}
        />
      );
      currentItem.id = delivery.id;

      return currentItem;
    });
  }

  async downloadCSV() {
    const {
      client,
      match: { params: { id } },
    } = this.props;

    let csv = await client.query({
      query: DELIVERY_BATCH_CSV_BY_ID,
      context: graphQLHeaders,
      variables: { id },
    });
    csv = csv.data.deliveryBatchCsvExportById;

    const blobURL = Base64Helper.createLinkForEncryptedFile(csv.rawSource, 'application/csv');

    this.setState({ csv: { url: blobURL, name: csv.name } }, () => {
      this.csvDownloader.current.click();
    });
  }

  toggleTicketPrint() {
    this.setState((state) => ({ showTicketPrintModal: !state.showTicketPrintModal }));
  }

  render() {
    const { t } = this.props;
    const { match: { params: { id: batchId } } } = this.props;
    const { showTicketPrintModal, csv } = this.state;

    return (
      <CustomQuery
        query={DELIVERY_BATCH_BY_ID}
        onCompleted={(data) => this.formatTableData(data.deliveryBatchById)}
        variables={{ id: batchId }}
      >
        {({ data, error, loading }) => {
          if (error) return null;
          if (loading) return <Loader />;

          const extraButtons = (
            <div className="text-right action-group">
              <ButtonGroup>
                <Button color="primary" onClick={() => this.toggleTicketPrint()}>
                  {t('batches:BatchDetail.printTicketLabelButton')}
                </Button>
              </ButtonGroup>
              <ButtonGroup>
                <Button color="secondary" onClick={() => this.downloadCSV()}>
                  <FontAwesomeIcon icon={['fas', 'download']} fixedWidth />
                  {t('batches:BatchDetail.downloadCSVButton')}
                </Button>
              </ButtonGroup>
            </div>
          );

          const title = (
            <>
              <Link to="/batches">
                <div className="page-back-btn-overlay">
                  <FontAwesomeIcon icon={['fas', 'arrow-alt-circle-left']} className="text-muted page-back-btn" />
                </div>
              </Link>
              {t('batches:BatchDetail.title')}
            </>
          );

          const batchInfoHeader = data && Object.keys(data).length > 0 ? (
            <div className="info-header">
              {`
                ${data.deliveryBatchById.agent.originalName},
                ${data.deliveryBatchById.deliveries.length}
                ${getDeliveryInflectionText(data.deliveryBatchById.deliveries.length, t)},
                ${t('batches:BatchDetail.batchCreatedDateLabel')} ${format(new Date(data.deliveryBatchById.created), 'PPp')}
              `}
            </div>
          ) : '';

          return (
            <MainBody wrapperHeight="150px" title={title} extraButtons={extraButtons}>
              {batchInfoHeader}
              <div>
                <DataTable
                  data={(data && Object.keys(data).length > 0) ? this.formatTableData(data.deliveryBatchById) : []}
                  columns={this.table.columns}
                  actions={this.table.actions}
                  config={this.table.config}
                  isLoaderVisible={loading}
                />
              </div>
              <TicketPrintModal
                deliveryIds={(data && Object.keys(data).length > 0) ? data.deliveryBatchById.deliveries.map((val) => val.id) : []}
                onToggle={() => this.toggleTicketPrint()}
                isOpen={showTicketPrintModal}
              />
              <a
                id="csvDownloader"
                ref={this.csvDownloader}
                href={csv.url}
                download={csv.name}
                style={{ display: 'none' }}
              >
                {t('batches:downloadCSVLink')}
              </a>
            </MainBody>
          );
        }}
      </CustomQuery>
    );
  }
}

BatchDetail.propTypes = {
  match: PropTypes.shape({ params: PropTypes.shape({ id: PropTypes.string }) }).isRequired,
  client: ApolloQueryTypes.client().isRequired,
};

export default withApollo(withTranslation(['batches', 'status'])(BatchDetail));
