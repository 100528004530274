import { useState } from 'react';

import { customManualMutation } from 'Models/Components/CustomMutation';
import DeliveryQueryFactory from 'Models/delivery/Queries/Factories/DeliveryQueryFactory';
import { toast } from 'Shared/utilities';

// eslint-disable-next-line import/prefer-default-export
export const useNoteInput = ({ refetchTimelineEvents, refetchNotes, noteId, deliveryId }) => {
  const [note, setNote] = useState('');

  function handleNoteChange(e) {
    e.preventDefault();
    setNote(e.target.value);
  }

  function handleNoteUpdate() {
    customManualMutation({
      mutation: DeliveryQueryFactory.updateDeliveryNote('id', ''),
      variables: {
        note: {
          id: noteId,
          note,
        },
      },
    })
      .then(() => toast.success('Poznámka upravena'))
      .then(() => refetchTimelineEvents())
      .catch(console.error);
  }

  function handleNoteCreate() {
    customManualMutation({
      mutation: DeliveryQueryFactory.createDeliveryNote('id', ''),
      variables: {
        note: {
          deliveryId,
          note,
        },
      },
    })
      .then(() => toast.success('Poznámka uložena'))
      .then(() => refetchTimelineEvents())
      .then(() => refetchNotes())
      .then(() => setNote(''))
      .catch(console.error);
  }

  return {
    note,
    onNoteChange: handleNoteChange,
    onNoteUpdate: handleNoteUpdate,
    onNoteCreate: handleNoteCreate,
  };
};
