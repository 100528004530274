import React from 'react';

import { cssMerge } from '~/services/utils';
import css from './StatusBanner.module.scss';

type Props = {
  status: boolean;
  title: {
    active: string;
    inactive: string;
  }
  description?: {
    active: string;
    inactive: string;
  };
  stateSwitch?: boolean;
  className?: string;
}

const StatusBanner: React.FC<Props> = ({ status, title, description, className }) => {
  return (
    <div
      className={cssMerge(
        status ? css.active : css.inactive,
        description ? '' : 'pl-2',
        css.banner,
        'd-flex justify-content-between align-items-center',
        className,
      )}
    >
      <div className="d-flex align-items-center">
        <img
          className={cssMerge(
            css.image,
            description ? css.imgSizeLg : css.imgSizeSm,
          )}
          src={status ? '/assets/img/pin-active.png' : '/assets/img/pin-inactive.png'}
          alt="Pin"
        />
        <div className="ml-1">
          <p
            className={cssMerge(
              css.title,
              'mb-0',
              description ? 'font-weight-bold' : 'font-weight-normal',
            )}
          >
            {status ? title.active : title.inactive}
          </p>
          {description && (
            <p className={`${css.description} mb-0 mt-3`}>
              {status ? description.active : description.inactive}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
export default StatusBanner;
