import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import { useTranslation } from 'react-i18next';

const customSelectStyles = {
  control: (style, {
    isFocused,
    isSelected,
  }) => ({
    ...style,
    backgroundColor: '#fff',
    boxShadow: (isFocused || isSelected) ? '#28a745' : null,
    borderColor: (isFocused || isSelected) ? 'rgb(153, 153, 153)' : '#ced4da',
    '&:hover, &:focus': {
      borderColor: 'rgb(153, 153, 153)',
      boxShadow: 'none',
    },
  }),
  groupHeading: (style) => ({
    ...style,
    color: '#000000',
  }),
  option: (style, {
    data,
    isDisabled,
    isFocused,
    isSelected,
  }) => {
    let bgColor = null;

    if (!isDisabled && isSelected) {
      bgColor = '#E71D73';
    } else if (!isDisabled && isFocused) {
      bgColor = '#f3f3f7';
    }

    return {
      ...style,
      backgroundColor: bgColor,
      color: (isSelected) ? '#fff' : data.color,
      '&:active': { backgroundColor: '#ebebef' },
    };
  },
  placeholder: () => ({ margin: 0 }),
  menu: (style) => ({ ...style, zIndex: 2 }),
};

const Select = (props) => {
  const { t } = useTranslation(['common']);
  const { value, options } = props;
  let newValue = value;

  if (newValue !== '' && typeof (newValue) !== 'object') {
    let option = null;

    options.forEach((item) => {
      if (option) return;

      if (item.value !== undefined && item.value === newValue) {
        option = item;
      } else if (item.options !== undefined) {
        option = item.options.find((so) => (so.value === newValue));
      }
    });

    if (option) {
      newValue = option;
    }
  }

  return (
    <ReactSelect
      {...props}
      styles={customSelectStyles}
      value={newValue}
      noOptionsMessage={() => t('common:Select.noOptions')}
    />
  );
};

Select.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      label: PropTypes.string,
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
        label: PropTypes.string,
      }),
    ),
  ]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      label: PropTypes.string,
    }),
  ).isRequired,
};

Select.defaultProps = { value: '' };

export default Select;
