import React, { useMemo } from 'react';
import { Row, Col, CustomInput } from 'reactstrap';
import { connect } from 'formik';
import { useTranslation } from 'react-i18next';

import Tooltip from '#/Components/Presentational/Tooltip/Tooltip';
import SVGIcon from '#/Components/Presentational/SVGIcon/SVGIcon';
import { RecipientPickupType } from '../types';

import css from './ExtraServices.module.scss';

interface Values {
  [key: string]: boolean;
}

interface ServiceArgument {
  name: string;
  type: string;
  exampleValue: string;
}

interface Service {
  id: string;
  code: string;
  name: string;
  description: string;
  selectedByDefault: boolean;
  arguments: ServiceArgument[];
  allowedForApi: boolean;
  allowedForApp: boolean;
  allowedForCsv: boolean;
}

type Props = {
  services: Service[];
  collectionPlaces: any[];
  recipientCollectionPlace: any;
  recipientPickupType: RecipientPickupType;
  agentAbbr: string;
};

const ExtraServices = connect<Props, Values>(({
  services,
  collectionPlaces,
  recipientCollectionPlace,
  recipientPickupType,
  agentAbbr,
  formik,
}) => {
  const { t } = useTranslation(['services']);
  const { values, handleChange } = formik;
  const availableExtraServices = useMemo(() => {
    return services.filter((es) => es.allowedForApp);
  }, [services]);

  const selectedCollectionPlace = collectionPlaces.find(
    (collectionPlace: any) => collectionPlace.id === recipientCollectionPlace,
  );

  const getHintValue = (type: 'phone' | 'email'): string => {
    if (type === 'phone') {
      return recipientPickupType === 'collectionPlace' ? selectedCollectionPlace.contact.phone : values.recipientPhone;
    }

    if (type === 'email') {
      return recipientPickupType === 'collectionPlace' ? selectedCollectionPlace.contact.email : values.recipientEmail;
    }

    return '';
  };

  const getHintFromService = (service: Service, type: 'phone' | 'email'): string => (
    service.arguments.some((argument) => argument.type === type)
      ? getHintValue(type)
      : ''
  );

  const rows = availableExtraServices.map((service, index) => {
    const hint = (
      getHintFromService(service, 'phone') || getHintFromService(service, 'email')
    );

    return (
      <Row
        className={css.extraService}
        key={service.id}
      >
        <Col md="7">
          <CustomInput
            type="checkbox"
            id={service.code}
            label={t([`services:${agentAbbr}.${service.code}`, `services:${service.code}.name`], service.name)}
            className={service.description && css.hasDescription}
            onChange={handleChange}
            checked={values[service.code]}
          />

          {service.description && (
            <Tooltip
              id={`tooltip-email-${index}`}
              linkText={(
                <SVGIcon
                  icon="help"
                  style={{
                    maxHeight: '24px',
                    maxWidth: '24px',
                  }}
                />
              )}
              text={t(`services:${service.code}.description`)}
              linkIconHide
              linkIcon={[]}
              linkIconPosition="after"
              placement="right"
            />
          )}
        </Col>

        <Col md="5">
          {hint}
        </Col>
      </Row>
    );
  });

  return (
    <>
      {rows}
    </>
  );
});

export default ExtraServices;
