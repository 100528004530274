import React from 'react';

type Props = {
  logoUrl: string
}

const CompanyLogoCard: React.FC<Props> = ({ logoUrl }) => {
  return (
    <div className="card">
      <div className="card-body">
        {logoUrl ? (
          <img alt="Logo společnosti" src={logoUrl} />
        ) : 'Logo společnosti není k dispozici'}
      </div>
    </div>
  );
};

export default CompanyLogoCard;
