import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';

const OnUserLeavePageDialog: React.FC = () => {
  const { t } = useTranslation(['common']);

  const alertUser = (e: Event) => {
    e.preventDefault();
    // eslint-disable-next-line no-param-reassign
    e.returnValue = false;
  };

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, []);

  return (<Prompt message={t('common:userWantToLeaveUnsavedForm')} />);
};

export default OnUserLeavePageDialog;
