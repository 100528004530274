import React, { useState } from 'react';
import { Button, Popover, PopoverBody } from 'reactstrap';
import { endOfYesterday, isSameYear, subYears } from 'date-fns';
import { useTranslation } from 'react-i18next';

import useDidUpdateEffect from '~/hooks/useDidUpdateEffect';
import { format } from '~/services/date';
import DatePicker from '~/components/DatePicker/DatePicker';
import Icon from '~/components/Icon/Icon';

import css from './DatePickerButton.module.scss';

export interface DateRange {
  start: Date;
  end: Date;
}

type Props = {
  dateRange: DateRange,
  onApply: (startDate: Date, endDate: Date) => void,
}

const DatePickerButton: React.FC<Props> = ({
  dateRange,
  onApply,
}) => {
  const { t } = useTranslation('dashboard');

  const [isOpen, setIsOpen] = useState(false);

  const [draftDateRange, setDraftDateRange] = useState({
    start: dateRange.start,
    end: dateRange.end,
  });

  // TODO: code smell as we are handling uncontrolled state inside effect
  // https://reactjs.org/docs/hooks-faq.html#how-do-i-implement-getderivedstatefromprops
  useDidUpdateEffect(() => {
    setDraftDateRange({ start: dateRange.start, end: dateRange.end });
  }, [dateRange.start, dateRange.end]);

  const handleCalendarChange = ([start, end]: [Date, Date]) => {
    setDraftDateRange({ start, end });
  };

  const toggle = () => setIsOpen(!isOpen);

  const endDateFormat = 'MMM d, yyyy';
  const startDateFormat = isSameYear(dateRange.start, dateRange.end) ? 'MMM d' : endDateFormat;

  return (
    <>
      <Button
        color="light-alt"
        id="popoverDashboardRange"
        className={`${css.datePickerButton} btn-icon align-self-center ${isOpen ? css.isOpen : ''}`.trim()}
      >
        <Icon
          name="calendar"
          className={`${css.icon} mr-2`}
        />
        {`${format(dateRange.start, startDateFormat)} - ${format(dateRange.end, endDateFormat)}`}
        <Icon
          name="chevron-down"
          className={css.iconDropdown}
        />
      </Button>

      <Popover
        className="_ds"
        placement="bottom"
        isOpen={isOpen}
        target="popoverDashboardRange"
        toggle={toggle}
        hideArrow
      >
        <PopoverBody className="p-0">
          <DatePicker
            onChange={handleCalendarChange}
            startDate={draftDateRange.start}
            endDate={draftDateRange.end}
            minDate={subYears(endOfYesterday(), 1)}
            maxDate={endOfYesterday()}
            inline
            selectsRange
          />

          <div className="d-flex p-2 justify-content-between">
            <Button
              size="sm"
              color="link-secondary"
              onClick={() => {
                setDraftDateRange({ start: dateRange.start, end: dateRange.end });
                toggle();
              }}
            >
              {t('DatePicker.cancelButton')}
            </Button>

            <Button
              size="sm"
              color="link-primary"
              onClick={() => {
                /**
                 * Handles unspecified dateRange end. Allows & handles selecting only the start date
                 * and clicking apply (without selecting the end date)
                 */
                const end = draftDateRange.end === null ? draftDateRange.start : draftDateRange.end;

                onApply(draftDateRange.start, end);
                toggle();
              }}
            >
              {t('DatePicker.submitButton')}
            </Button>
          </div>
        </PopoverBody>
      </Popover>
    </>
  );
};

export default DatePickerButton;
