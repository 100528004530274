import gql from 'graphql-tag';

export default class AuthenticationQueryFactory {
  static refreshAccessToken(fields, fragments = '') {
    return gql`
      mutation refreshAccessToken($refreshToken: String!) {
        refreshAccessToken(refreshToken: $refreshToken) {
          ${fields}
        }
      }
    ${fragments}`;
  }

  static loggedUserInfo(fields, fragments = '') {
    return gql`
      query loggedUserInfo {
        loggedUserInfo {
          ${fields}
        }
      }
      ${fragments}
    `;
  }

  static requestPasswordReset() {
    return gql`
      mutation requestPasswordReset($email: ScalarEmail) {
        requestPasswordReset(email: $email)
      }
    `;
  }

  static resetPassword() {
    return gql`
      mutation resetPassword($token: String!, $password: String!) {
        resetPassword(token: $token, password: $password)
      }
    `;
  }

  static oAuthClientByIdentifier(fields, fragments = '') {
    return gql`
      query oAuthClientByIdentifier($identifier: String!) {
        oAuthClientByIdentifier(identifier: $identifier) {
          ${fields}
        }
      }
      ${fragments}
    `;
  }

  static oAuthScopes(fields, fragments = '') {
    return gql`
      query oAuthScopes {
        oAuthScopes {
          ${fields}
        }
      }
      ${fragments}
    `;
  }

  static authorizeOAuthClient(fields, fragments = '') {
    return gql`
      mutation authorizeOAuthClient($request: InputOAuthClientAuthorizationRequest!) {
        authorizeOAuthClient(request: $request) {
          ${fields}
        }
      }
      ${fragments}
    `;
  }
}
