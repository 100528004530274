import React from 'react';
import { useParams } from 'react-router';
import { Redirect } from 'react-router-dom';
import useAuthQuery from '~/hooks/useAuthQuery';

import { AccountByIdQuery, AccountByIdQueryVariables } from '../types';
import CreateUserForm from '../CreateUser/CreateUserForm';

import ACCOUNT from '../accountById.gql';

const EditUserForm: React.FC = () => {
  const { id } = useParams<{ id?: string; }>();

  const { data, loading } = useAuthQuery<AccountByIdQuery, AccountByIdQueryVariables>(
    ACCOUNT,
    { id: id ?? '' },
    { skip: id === undefined },
  );
  const account = data?.accountById;

  if (id === undefined) {
    return <Redirect to="/settings/users" />;
  }

  return (
    <CreateUserForm
      edit
      account={account}
      loading={loading}
    />
  );
};

export default EditUserForm;
