import brandingRouteNames from 'Containers/Settings/Branding/helpers/brandingRouteNames';

import EditMailing from 'Containers/Settings/Branding/Mailing/EditMailing';
import Mailing from 'Containers/Settings/Branding/Mailing/Mailing';
import NewMailing from 'Containers/Settings/Branding/Mailing/NewMailing';
import EditMarketingCampaign from 'Containers/Settings/Branding/MarketingCampaigns/EditMarketingCampaign';
import MarketingCampaigns from 'Containers/Settings/Branding/MarketingCampaigns/MarketingCampaigns';
import NewMarketingCampaign from 'Containers/Settings/Branding/MarketingCampaigns/NewMarketingCampaign';
import EditTrackAndTrace from 'Containers/Settings/Branding/TrackAndTrace/EditTrackAndTrace';
import NewTrackAndTrace from 'Containers/Settings/Branding/TrackAndTrace/NewTrackAndTrace';
import TrackAndTrace from 'Containers/Settings/Branding/TrackAndTrace/TrackAndTrace';

import React from 'react';

export type BrandingComponentContent = {
  component: React.FC;
  className: string;
  goBackRoute: string;
  upsellDescription?: string;
};

type BrandingComponentsHolder = {
  [key: string]: BrandingComponentContent
}

const brandingComponentsDataByRoute: BrandingComponentsHolder = {
  [brandingRouteNames.mailing]: {
    component: Mailing,
    className: 'mailing',
    goBackRoute: '/settings',
    upsellDescription: 'emails',
  },
  [brandingRouteNames.newMailing]: {
    component: NewMailing,
    className: 'mailing',
    goBackRoute: '/settings/branding/mailing',
    upsellDescription: 'emails',
  },
  [brandingRouteNames.editMailing]: {
    component: EditMailing,
    className: 'mailing',
    goBackRoute: '/settings/branding/mailing',
  },
  [brandingRouteNames.campaign]: {
    component: MarketingCampaigns,
    className: 'marketing-campaigns',
    goBackRoute: '/settings',
    upsellDescription: 'campaigns',
  },
  [brandingRouteNames.newCampaign]: {
    component: NewMarketingCampaign,
    className: 'new-marketing-campaign',
    goBackRoute: '/settings/branding/marketing-campaigns',
  },
  [brandingRouteNames.editCampaign]: {
    component: EditMarketingCampaign,
    className: 'edit-marketing-campaign',
    goBackRoute: '/settings/branding/marketing-campaigns',
  },
  [brandingRouteNames.trackAndTrace]: {
    component: TrackAndTrace,
    className: 'track-and-trace',
    goBackRoute: '/settings',
    upsellDescription: 'trackAndTrace',
  },
  [brandingRouteNames.newTrackAndTrace]: {
    component: NewTrackAndTrace,
    className: 'track-and-trace',
    goBackRoute: '/settings/branding/track-and-trace',
  },
  [brandingRouteNames.editTrackAndTrace]: {
    component: EditTrackAndTrace,
    className: 'track-and-trace',
    goBackRoute: '/settings/branding/track-and-trace',
  },
};

export default brandingComponentsDataByRoute;
