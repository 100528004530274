import gql from 'graphql-tag';
import BaseDataSource from '../BaseDataSource';
import AuthenticationHelper from '~/services/Auth';

class AuthenticationDataSource extends BaseDataSource {
  constructor() {
    super();
    this.loggedUserInfoDefaultFields = `
      roles
      account {
        id
        login
        businessClass
        primaryAccess
        protocolAccess
        deliveryAccessType
        contact {
          name
          surname
          email
          phone
        }
        company {
          id
          name
          email
          emailConfirmed
          website
          subscription {
            subscriptionType {
              systemName
            }
            validTo
            dashboard
            branding
            importantDeliveries
            technicalSupport
          } 
          credentials { 
            status 
          }
          collectionPlaces {
            id
          }
        }
      }
  `;
  }

  login(login, password) {
    const mutation = gql`
      mutation login($credentials: InputSecurityCredentials!) {
        login(credentials: $credentials) {
          accessToken
          refreshToken
          accessTokenExpiration
          refreshTokenExpiration
          account {
            id
            name
          }
        }
      }
    `;

    const variables = { credentials: { login, password } };

    return this.client.mutate({
      mutation,
      variables,
    });
  }

  logout() {
    const mutation = gql`
      mutation logout {
        logout
      }
    `;

    return this.client.mutate({
      mutation,
      context: { headers: { AccessToken: AuthenticationHelper.accessToken } },
    });
  }

  eshopMeta() {
    const query = gql`
      query eshop {
        eshop {
          platform
          activeTrackAndTraceConfig {
            id
          }
          activeEmailConfig {
            id
          }
        }
      }
    `;

    return this.client.query({
      query,
      context: { headers: { AccessToken: AuthenticationHelper.accessToken } },
    });
  }

  loggedUserInfo(fields = this.loggedUserInfoDefaultFields, cache = true) {
    const query = gql`
      query loggedUserInfo {
        loggedUserInfo {
          ${fields}
        }
      }
    `;

    const options = {
      query,
      context: { headers: { AccessToken: AuthenticationHelper.accessToken } },
    };

    if (!cache) {
      options.fetchPolicy = 'network-only';
    }

    return this.client.query(options);
  }

  refreshAccessToken(refreshToken) {
    const mutation = gql`
      mutation refreshAccessToken($refreshToken: String!) {
        refreshAccessToken(refreshToken: $refreshToken) {
          accessToken
          refreshToken
          accessTokenExpiration
          refreshTokenExpiration
        }
      }
    `;

    const variables = { refreshToken };

    return this.client.mutate({
      mutation,
      variables,
    });
  }

  updateAccount(id, account) {
    const mutation = gql`
      mutation updateAccount($id: ID, $account: InputAccount!) {
        updateAccount(id: $id, account: $account) {
          id
          contact {
            name
            surname
            email
            phone
          }
        }
      }
    `;

    const variables = { id, account };

    return this.client.mutate({
      mutation,
      variables,
      context: { headers: { AccessToken: AuthenticationHelper.accessToken } },
    });
  }

  updatePassword(password) {
    const mutation = gql`
      mutation updatePassword($password: InputSecurityPassword!) {
        updatePassword(password: $password)
      }
    `;

    const variables = { password };

    return this.client.mutate({
      mutation,
      variables,
      context: { headers: { AccessToken: AuthenticationHelper.accessToken } },
    });
  }
}

export default new AuthenticationDataSource();
