import { ExecutionResult } from 'apollo-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FieldArray, Formik } from 'formik';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, Container, Form } from 'reactstrap';

import EshopLocalizationItem from './EshopLocalizationItem';
import { LocalizationConfigValues } from '../EshopForm/EshopForm';
import FormikScroll from '~/components/FormikScroll/FormikScroll';
import SubmitButton from '~/components/SubmitButton/SubmitButton';
import useAuthMutation from '~/hooks/useAuthMutation';
import { toast } from '#/shared/utilities';
import {
  CreateLocalizationConfigMutation,
  CreateLocalizationConfigMutationVariables,
  EshopLocalizationConfig,
  UpdateLocalizationConfigMutation,
  UpdateLocalizationConfigMutationVariables,
} from '../types';
import { schema } from './validationSchema';

import UPDATE_LOCALIZATION_CONFIG from '../updateLocalizationConfig.gql';
import CREATE_LOCALIZATION_CONFIG from '../createLocalizationConfig.gql';

export type EshopLocalizationFormValues = {
  localizationConfigs: LocalizationConfigValues[];
};

type Props = {
  localizationConfigs: EshopLocalizationConfig[];
  onSubmit?: () => void;
};

const EshopLocalizationForm: React.FC<Props> = ({ localizationConfigs, onSubmit }) => {
  const { t } = useTranslation(['settings', 'validation']);
  const [updateLocalizationConfig] = useAuthMutation<UpdateLocalizationConfigMutation, UpdateLocalizationConfigMutationVariables>(UPDATE_LOCALIZATION_CONFIG);
  const [createLocalizationConfig] = useAuthMutation<CreateLocalizationConfigMutation, CreateLocalizationConfigMutationVariables>(CREATE_LOCALIZATION_CONFIG);
  const history = useHistory();

  const initialValues = useMemo<EshopLocalizationFormValues>(() => {
    return {
      localizationConfigs: localizationConfigs.map((config) => ({
        id: config.id,
        country: config.country ?? '',
        termsAndConditionsUrl: config.termsAndConditionsUrl ?? '',
        privacyPolicyUrl: config.privacyPolicyUrl ?? '',
        contactUrl: config.contactUrl ?? '',
        supportPhone: config.supportPhone ?? '',
        supportEmail: config.supportEmail ?? '',
        homepageUrl: config.homepageUrl ?? '',
      })),
    };
  }, [localizationConfigs]);

  const getNewItem = (): LocalizationConfigValues => ({
    country: '',
    termsAndConditionsUrl: '',
    privacyPolicyUrl: '',
    contactUrl: '',
    supportPhone: '',
    supportEmail: '',
    homepageUrl: '',
  });

  const handleFormSubmit = async (values: EshopLocalizationFormValues) => {
    const filteredConfigs = values.localizationConfigs.filter((config) => config.country !== null && config.country !== '');

    if (!filteredConfigs.length) {
      return;
    }

    try {
      await Promise.all<ExecutionResult<CreateLocalizationConfigMutation> | ExecutionResult<UpdateLocalizationConfigMutation>>(
        filteredConfigs.map((config) => {
          const mutationVariables: CreateLocalizationConfigMutationVariables = {
            config: {
              country: config.country,
              termsAndConditionsUrl: config.termsAndConditionsUrl,
              privacyPolicyUrl: config.privacyPolicyUrl,
              contactUrl: config.contactUrl,
              supportPhone: config.supportPhone,
              supportEmail: config.supportEmail,
              homepageUrl: config.homepageUrl,
            },
          };

          if (config.id) {
            return updateLocalizationConfig({
              variables: {
                id: config.id,
                config: mutationVariables.config,
              },
            });
          }

          return createLocalizationConfig({
            variables: {
              config: mutationVariables.config,
            },
          });
        }),
      );

      // TODO: Patch ID value of configs instead of onSubmit() + refetch() + enableReinitialize?
      if (onSubmit) {
        onSubmit();
      }

      toast.success(t('settings:Eshop.Message.localizationConfigSaved'));
    } catch (e) {
      // Handled in global error interceptor
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      enableReinitialize
      validationSchema={schema(t)}
    >
      {({
        isSubmitting,
        dirty,
        handleSubmit,
        resetForm,
        values,
      }) => (
        <Form className="mt-5 pt-2 branding" onSubmit={handleSubmit} noValidate>
          <FormikScroll />
          <Container className="mt-5">
            <h3>{t('settings:Eshop.Localization.title')}</h3>
            <p>{t('settings:Eshop.Localization.description')}</p>
            <FieldArray name="localizationConfigs">
              {({ push, remove }) => (
                <>
                  {values.localizationConfigs.map((config, index) => (
                    <EshopLocalizationItem
                      key={`localization-config-${index}`}
                      index={index}
                      config={config}
                      onDelete={() => remove(index)}
                    />
                  ))}

                  <Button
                    color="flat-blue"
                    type="button"
                    className="mb-3"
                    onClick={() => push(getNewItem())}
                  >
                    <FontAwesomeIcon icon={['fas', 'plus']} />
                    {t('settings:Eshop.Localization.addVariantButton')}
                  </Button>
                </>
              )}
            </FieldArray>

            {values.localizationConfigs.length > 0 && (
              <>
                <hr className="mb-3" />
                <div className="d-flex justify-content-start align-content-center">
                  <SubmitButton
                    color="primary"
                    type="submit"
                    isLoading={isSubmitting}
                  >
                    {t('settings:Eshop.submitButton')}
                  </SubmitButton>
                  <Button
                    className="ml-2 mr-5"
                    color="primary"
                    outline
                    onClick={() => history.push('/settings')}
                  >
                    {t('settings:Eshop.cancelButton')}
                  </Button>
                  <Button
                    color="dark"
                    size="xs"
                    className="align-middle ml-md-auto"
                    disabled={!dirty}
                    onClick={() => resetForm()}
                  >
                    <FontAwesomeIcon icon={['fas', 'trash']} fixedWidth />
                    {t('settings:Eshop.discardChangesButton')}
                  </Button>
                </div>
              </>
            )}
          </Container>
        </Form>
      )}
    </Formik>
  );
};

export default EshopLocalizationForm;
