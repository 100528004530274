import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';

const dataTableActionFunc = ({ onClick, icon, tooltip }) => (
  <Button
    onClick={() => onClick()}
    color="none"
    className="btn-link-action"
    tooltip={tooltip}
  >
    <FontAwesomeIcon
      icon={icon.class}
      size={icon.size}
      className="mr-2"
    />
  </Button>
);

dataTableActionFunc.propTypes = {
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.shape({
    class: PropTypes.array.isRequired,
    size: PropTypes.string,
  }).isRequired,
  tooltip: PropTypes.string,
};

dataTableActionFunc.defaultProps = { tooltip: null };

export default dataTableActionFunc;
