// :: Default / Reset
export const RESET = 'STATE_RESET';

// :: Auth
export const USER_LOGGING_IN = 'USER_LOGGING_IN';
export const USER_LOGGING_IN_FAILED = 'USER_LOGGING_IN_FAILED';
export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';

// :: User
export const FETCH_USER_START = 'FETCH_USER_START';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAIL = 'FETCH_USER_FAIL';

export const SET_USER_META = 'SET_USER_META';

// :: Contacts
export const CONTACTS_PAGE_CHANGED = 'CONTACTS_PAGE_CHANGED';
export const NUMBER_OF_ROWS_CHANGED = 'NUMBER_OF_ROWS_CHANGED';
export const SEARCH_KEY_CHANGED = 'SEARCH_KEY_CHANGED';
export const ORDERBY_CHANGED = 'ORDERBY_CHANGED';
export const ORDERBY_DIRECTION_CHANGED = 'ORDERBY_DIRECTION_CHANGED';
export const CONTACT_TOTAL_COUNT_CHANGED = 'CONTACT_TOTAL_COUNT_CHANGED';

// :: Delivery
export const DELIVERY_FILTER_AND_FLAGS_CHANGED = 'DELIVERY_FILTER_AND_FLAGS_CHANGED';
export const DELIVERY_ORDER_BY_DIRECTION_CHANGED = 'DELIVERY_ORDER_BY_DIRECTION_CHANGED';
export const DELIVERY_ORDER_BY_CHANGED = 'DELIVERY_ORDER_BY_CHANGED';
export const DELIVERY_UPLOAD_CSV_FILE_CHANGED = 'DELIVERY_UPLOAD_CSV_FILE_CHANGED';
export const DELIVERY_PAGE_CHANGED = 'DELIVERY_PAGE_CHANGED';
export const DELIVERY_FILTERS_CHANGED = 'DELIVERY_FILTERS_CHANGED';
export const DELIVERY_RECIPIENT_COUNTRY_CHANGED = 'DELIVERY_RECIPIENT_COUNTRY_CHANGED';
