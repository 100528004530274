import graphqlStorage from '../services/GraphqlStorage';

export default class BaseDataSource {
  constructor() {
    this.graphQLClient = graphqlStorage.client;
  }

  get client() {
    return this.graphQLClient;
  }
}
