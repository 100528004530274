import React from 'react';
import { connect } from 'formik';
import { FormGroup, Label, InputGroup, InputGroupAddon, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import transformDecimals from '~/services/transformDecimals';
import SelectField from '../../../SelectField/SelectField';
import InputField from '~/components/InputField/InputField';
import { PackageItem } from '../../../types';
import { PackageTemplateDraft } from '../types';

import css from './PackageRow.module.scss';

export type Props = {
  index: number;
  metrics: PackageItem;
  onSaveTemplateClick?: (dimensions: PackageTemplateDraft) => void;
  onDelete(index: number): void;
  hideDeleteButton: boolean;
  containerOptions?: any[];
}

const PackageRow = connect<Props>(({
  index,
  metrics,
  onSaveTemplateClick,
  onDelete,
  hideDeleteButton,
  containerOptions,
  formik,
}) => {
  const { t } = useTranslation(['deliveries', 'validation']);

  const { setFieldValue } = formik;

  return (
    <>
      <Row
        className={css.package}
        form
      >
        <div className={`${css.packageInfo} text-center font-weight-bold text-dark`}>
          {t('deliveries:NewDelivery.Packages.packageLabel')}
          {' '}
          {index + 1}
        </div>

        <FormGroup className="col-xl-3 col-6">
          <Label htmlFor={`packages[${index}].weight`}>
            {t('validation:PackageWeight.label')}
          </Label>

          <InputGroup className="input-appended">
            <InputField
              name={`packages[${index}].weight`}
              inputMode="numeric"
              onBlur={(): void => {
                setFieldValue(`packages[${index}].weight`, transformDecimals(metrics.weight), false);
              }}
              placeholder="0.00"
            />
            <InputGroupAddon addonType="append">kg</InputGroupAddon>
          </InputGroup>
        </FormGroup>

        <FormGroup className="col-xl-3 col-6">
          <Label htmlFor={`packages[${index}].length`}>
            {t('validation:PackageLength.label')}
          </Label>

          <InputGroup className="input-appended">
            <InputField
              name={`packages[${index}].length`}
              inputMode="numeric"
              placeholder="0"
            />
            <InputGroupAddon addonType="append">cm</InputGroupAddon>
          </InputGroup>
        </FormGroup>

        <FormGroup className="col-xl-3 col-6">
          <Label htmlFor={`packages[${index}].width`}>
            {t('validation:PackageWidth.label')}
          </Label>

          <InputGroup className="input-appended">
            <InputField
              name={`packages[${index}].width`}
              inputMode="numeric"
              placeholder="0"
            />
            <InputGroupAddon addonType="append">cm</InputGroupAddon>
          </InputGroup>
        </FormGroup>

        <FormGroup className="col-xl-3 col-6">
          <Label htmlFor={`packages[${index}].height`}>
            {t('validation:PackageHeight.label')}
          </Label>

          <InputGroup className="input-appended">
            <InputField
              name={`packages[${index}].height`}
              inputMode="numeric"
              placeholder="0"
            />
            <InputGroupAddon addonType="append">cm</InputGroupAddon>
          </InputGroup>
        </FormGroup>

        <div className={`${css.packageButtons} text-center`}>
          {
            onSaveTemplateClick && (
              <button
                type="button"
                className="btn-link-action"
                onClick={(): void => onSaveTemplateClick({
                  length: parseFloat(metrics.length) || 0,
                  width: parseFloat(metrics.width) || 0,
                  height: parseFloat(metrics.height) || 0,
                })}
              >
                <FontAwesomeIcon
                  icon={['far', 'save']}
                  size="2x"
                />
              </button>
            )
          }

          <button
            type="button"
            className="btn-link-action ml-4"
            style={hideDeleteButton ? ({ display: 'none' }) : undefined}
            onClick={(): void => onDelete(index)}
          >
            <FontAwesomeIcon
              icon={['fas', 'times']}
              size="2x"
            />
          </button>
        </div>
      </Row>

      {!!containerOptions?.length && (
        <Row form>
          <Col md={6}>
            <FormGroup>
              <SelectField
                name={`packages[${index}].containerCode`}
                label="Jednotka"
                placeholder="Vyberte cargo jednotku"
                options={containerOptions}
              />
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <InputField
                name={`packages[${index}].containerItems`}
                label="Počet"
                inputMode="numeric"
                placeholder="0"
              />
            </FormGroup>
          </Col>
        </Row>
      )}
    </>
  );
});

export default PackageRow;
