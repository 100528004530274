import React from 'react';
import PropTypes from 'prop-types';

const Loader = ({ fullscreen }) => {
  const wrapperProps = fullscreen ? { id: 'app-page-loader', className: 'page-loader-c' } : {};

  return (
    <div {...wrapperProps}>
      <div className="loader-circle">
        <img
          className="loader-image"
          src="/assets/img/snb-logo-symbol.svg"
          alt="Načítání stránky"
          height={39}
          width={30}
        />
      </div>
      <div className="loader-line-mask">
        <div className="loader-line" />
      </div>
    </div>
  );
};

Loader.defaultProps = { fullscreen: true };
Loader.propTypes = { fullscreen: PropTypes.bool };

export default Loader;
