import React from 'react';

import StateWithCategory from './StateWithCategory';
import StateWithoutCategory from './StateWithoutCategory';

const StateAccordion = ({ events }) => (
  <>
    {events[0].category ? <StateWithCategory events={events} /> : <StateWithoutCategory events={events} />}
  </>
);

export default StateAccordion;
