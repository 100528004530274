import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import SettingsBase from 'Containers/Settings/_base/SettingsBase';
import SettingsLayout from 'Components/Settings/SettingsLayout';
import { updateObject } from 'Shared/utilities';
import SubjectDataSource from 'Models/subject/SubjectDataSource';
import CollectionPlaceForm from './Components/Container/CollectionPlaceCreateForm/CollectionPlaceCreateForm';

class CollectionPlacesNew extends SettingsBase {
  constructor(props) {
    super(props, 'general');
    this.subject = new SubjectDataSource();
  }

  async componentDidMount() {
    this.initState();
  }

  initState() {
    const state = updateObject(this.state, { isOpened: true });

    this.setState(state);
  }

  render() {
    const { history } = this.props;

    const content = (
      <Container>
        <CollectionPlaceForm history={history} />
      </Container>
    );

    return (
      <SettingsLayout
        goBack="/settings"
        leftBlock={this.state.leftBlock}
        rightBlock={this.state.rightBlock}
        checkedItems={this.state.checkedItems}
        isOpened={this.state.isOpened}
      >
        {content}
      </SettingsLayout>
    );
  }
}

export default withTranslation(['settings'])(CollectionPlacesNew);
