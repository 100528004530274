import { BannerItem, CampaignBannerInputValues, MarketingCampaignFormValues } from 'Containers/Settings/Branding/MarketingCampaigns/typesAndDefaults';
import { uploadImage } from '~/helpers/imageHelper';

export const uploadCampaignDefaultImages = async (campaignId: string, marketingCampaignValues: MarketingCampaignFormValues): Promise<any> => {
  const defaultDesktopImageUploadData = uploadImage(marketingCampaignValues.defaultDesktopBannerImage);
  const defaultMobileImageUploadData = uploadImage(marketingCampaignValues.defaultMobileBannerImage);
  const defaultEmailImageUploadData = uploadImage(marketingCampaignValues.defaultEmailBannerImage);
  return Promise.all([defaultDesktopImageUploadData, defaultMobileImageUploadData, defaultEmailImageUploadData]);
};

export const uploadBannerImages = async (bannerData: CampaignBannerInputValues, campaignBanner: BannerItem): Promise<any> => {
  const desktopImageUploadData = uploadImage(campaignBanner.trackAndTraceDesktopBannerImage);
  const mobileImageUploadData = uploadImage(campaignBanner.trackAndTraceMobileBannerImage);
  const emailImageUploadData = uploadImage(campaignBanner.emailCommunicationBannerImage);
  return Promise.all([desktopImageUploadData, mobileImageUploadData, emailImageUploadData]);
};
