import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import Select from 'Components/Presentational/Select/Select';
import { CurrencyManagerContext } from 'Containers/CurrencyManager/CurrencyManager';

class CurrencySelect extends React.Component {
  handleChange = (option, select) => {
    const { onChange } = this.props;
    onChange(select.name, option);
  };

  formatCurrencies(currencies, t) {
    const { defaultLabel } = this.props;
    const recent = ['CZK', 'EUR', 'USD'];
    const optionsRecent = [];
    const optionsOthers = [];
    const options = [
      {
        value: '',
        label: defaultLabel || t('validation:CurrencySelect.label'),
      },
      {
        label: t('validation:CurrencySelect.oftenSelected'),
        options: optionsRecent,
      },
      {
        label: t('validation:CurrencySelect.allCurrencies'),
        options: optionsOthers,
      },
    ];

    currencies.forEach((item) => {
      const option = {
        value: item.name,
        label: item.name,
      };

      if (recent.includes(item.name)) {
        optionsRecent.push(option);
      } else {
        optionsOthers.push(option);
      }
    });

    return options;
  }

  render() {
    const { placeholder, t } = this.props;

    return (
      <CurrencyManagerContext.Consumer>
        {(currencyManager) => (
          <Select
            {...this.props}
            placeholder={placeholder}
            options={this.formatCurrencies(currencyManager.currencies, t)}
            onChange={this.handleChange}
            classNamePrefix="select"
          />
        )}
      </CurrencyManagerContext.Consumer>
    );
  }
}

CurrencySelect.defaultProps = {
  placeholder: null,
  defaultLabel: null,
};

/* eslint-disable react/require-default-props */
CurrencySelect.propTypes = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  invalid: PropTypes.bool,
  valid: PropTypes.bool,
  defaultLabel: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ]),
  name: PropTypes.string.isRequired,
};

export default withTranslation(['validation'])(CurrencySelect);
