import React, { useState, useEffect, useContext } from 'react';
import { ObservableQuery } from 'apollo-client';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row, Button, Badge } from 'reactstrap';
import produce from 'immer';
import { useTranslation } from 'react-i18next';

import useAuthQuery from '~/hooks/useAuthQuery';
import Icon from '#/Components/Presentational/Icon/Icon';
import MainBody from '#/Components/Presentational/Layout/MainBody/MainBody';
import Loader from '#/Components/Containers/Loader/Loader';
import TicketPrintModal from '#/Components/Containers/TicketPrintModal/TicketPrintModal';
import { stringifyNull } from '#/shared/utilities';
import { ServiceLocatorContext } from '#/Components/Containers/ServiceLocator/ServiceLocator';
import AuthenticationHelper from '~/services/Auth';
import DeliveryInteractionMenu from './Components/Container/DeliveryInteractionMenu/DeliveryInteractionMenu';
import SnoozeButton from './Components/Container/SnoozeButton/SnoozeButton';
import DeliveryUserEditViewer from '../../DeliveryUserEditViewer/DeliveryUserEditViewer';
import TrackStatus from './TrackStatus/TrackStatus';
import TrackInfo from './TrackInfo/TrackInfo';
import DeliveryTimeline from './DeliveryTimeline/DeliveryTimeline';
import DeliveryInfo from './DeliveryInfo/DeliveryInfo';
import ExtraServices from './ExtraServices/ExtraServices';
import PickupPoint from './PickupPoint/PickupPoint';
import Rating from './Rating/Rating';

import deliveryByIdQuery from './deliveryById.gql';
import css from './DeliveryDetail.module.scss';
import { getCarrierLogoUrl } from '~/services/utils';

type Props = {
  match: any;
  history: any;
}

const DeliveryDetail: React.FC<Props> = ({
  match,
  history: { push },
}) => {
  const { t } = useTranslation(['deliveries', 'status']);

  const { params: { deliveryId } } = match;

  const [showTicketPrintModal, setShowTicketPrintModal] = useState<boolean>(false);

  // TODO: antipattern
  let refetchTimelineEvents: ObservableQuery['refetch'] | null = null;

  const setRefetchTimelineEvents = (fn: ObservableQuery['refetch']) => {
    refetchTimelineEvents = fn;
  };

  const context = useContext(ServiceLocatorContext);

  // TODO: useCallback?
  const startTrackingDeliveryEdit = () => {
    if (AuthenticationHelper.isAdmin) return;

    const { userDeliveryTracking: { trackUserDeliveryEdit } } = context;

    trackUserDeliveryEdit(deliveryId);
  };

  const stopTrackingDeliveryEdit = () => {
    if (AuthenticationHelper.isAdmin) return;

    const { userDeliveryTracking: { untrackUserDeliveryEdit } } = context;

    untrackUserDeliveryEdit(deliveryId);
  };

  useEffect(() => {
    startTrackingDeliveryEdit();

    document.addEventListener('focus', startTrackingDeliveryEdit);

    return function cleanup() {
      stopTrackingDeliveryEdit();

      document.removeEventListener('focus', startTrackingDeliveryEdit);
    };
  });

  const { loading, error, data, refetch } = useAuthQuery<any>(
    deliveryByIdQuery,
    { id: deliveryId },
    { errorPolicy: 'all' },
  );

  if (loading) {
    return <Loader />;
  }

  if (error && !data?.deliverybyId) {
    return null;
  }

  // TODO: useCallback?
  const toggleTicketPrint = () => {
    setShowTicketPrintModal(!showTicketPrintModal);
  };

  const handleSnoozeSuccess = () => {
    refetch();

    if (refetchTimelineEvents) {
      refetchTimelineEvents();
    }
  };

  const delivery: any = produce(data.deliveryById, (draft: any) => {
    const deliveryData = draft;

    deliveryData.type.agent.logo.url = getCarrierLogoUrl(
      deliveryData.type.agent.logoSlug,
    );

    deliveryData.currentAgent.logo.url = getCarrierLogoUrl(
      deliveryData.currentAgent.logoSlug,
    );
  });

  const extraButtons = (
    <Button
      color="primary"
      onClick={toggleTicketPrint}
      disabled={!delivery.canGenerateTicket}
    >
      <FontAwesomeIcon icon={['fas', 'print']} />
      {t('deliveries:DeliveryDetail.printTicketLabelButton')}
    </Button>
  );

  const title = (
    <>
      <Link to="/deliveries">
        <div className="page-back-btn-overlay">
          <FontAwesomeIcon
            icon={['fas', 'arrow-alt-circle-left']}
            className="text-muted page-back-btn"
          />
        </div>
      </Link>

      <div className="d-inline-block">
        {t('deliveries:DeliveryDetail.title')}
        {' '}
        {stringifyNull(delivery.deliveryNumber)}
      </div>

      {delivery.isMonitored
      && (
        <Badge className={css.isMonitoredBadge}>
          {t('deliveries:DeliveryDetail.monitoredDeliveryTag')}
        </Badge>
      )}

      <div className="d-inline-block ml-3">
        {delivery.important && (
          <Icon
            icon="warning_bigger"
            type="foxdeli"
            className="warning"
            size="1"
          />
        )}

        <span className="ml-3">
          <SnoozeButton
            deliveryId={deliveryId}
            hidden={!delivery.important}
            onSuccess={handleSnoozeSuccess}
          />
        </span>
      </div>
    </>
  );

  function renderSubcategory() {
    // Show reason of delay for delayed deliveries
    if (delivery.delayed) {
      return t(`status:DelayCode.${delivery.reasonOfDelayCode}`);
    }

    // These subcategories contain multiple states (like 3.3.1, 3.3.2, 3.3.3, ...)
    const isMultiStateSubcategory = delivery.stateSubcategory === '3.1'
      || delivery.stateSubcategory === '3.2'
      || delivery.stateSubcategory === '3.3';

    /**
     * Subcategories with multiple states should be shown to give the full context,
     * for example "Recipient objection: Rejected" ("<subcategory>: <state>").
     *
     * Except for state with trailing '0', which is the same as its subcategory.
     * (state 3.0.0 is the same as subcategory 3.0)
     */
    if (isMultiStateSubcategory && !delivery.state.endsWith('0')) {
      return `${t(`status:StatusSubcategory.${delivery.stateSubcategory}`)}: ${t(`status:${delivery.stateKey}`)}`;
    }

    return t(`status:${delivery.stateKey}`);
  }

  return (
    <MainBody
      title={title}
      wrapperHeight="150px"
      extraButtons={!delivery.isMonitored && extraButtons}
    >
      <Row className="mb-2">
        <Col xs="6">
          <DeliveryUserEditViewer deliveryId={deliveryId} />

          <p className="text-dark">
            {renderSubcategory()}
          </p>
        </Col>

        <Col xs="6" className="text-right">
          <DeliveryInteractionMenu
            canUpdate={delivery.canUpdate}
            canClose={delivery.isMonitored ? false : delivery.canClose}
            canCancel={delivery.canCancel}
            canStopDeliveryProcess={delivery.canStopDelivering}
            deliveryId={deliveryId}
            refetchDeliveryDetail={refetch}
            push={push}
            isMonitored={delivery.isMonitored}
          />
        </Col>
      </Row>

      <Row className="mb-4 align-item-stretch">
        <Col md={8}>
          <TrackStatus delivery={delivery} />
        </Col>

        <Col md={4}>
          <TrackInfo delivery={delivery} />
        </Col>
      </Row>

      <Row>
        <Col md="8">
          <DeliveryTimeline
            setRefetchTimelineEvents={setRefetchTimelineEvents}
            deliveryId={deliveryId}
          />
        </Col>
        <Col md="4">
          {delivery.rating && (
            <Rating
              rating={delivery.rating}
              note={delivery.ratingNote}
              tags={delivery.ratingTags}
            />
          )}

          <DeliveryInfo
            delivery={delivery}
            onChange={() => { refetch(); }}
          />

          {delivery.recipient.pickUpPlace
            && <PickupPoint delivery={delivery} /> }

          <ExtraServices
            extraServices={delivery.extraServices}
            agentAbbr={delivery.type.agentType}
          />
        </Col>
      </Row>

      <TicketPrintModal
        deliveryIds={[parseInt(deliveryId, 10)]}
        onToggle={toggleTicketPrint}
        isOpen={showTicketPrintModal}
      />
    </MainBody>
  );
};

export default DeliveryDetail;
