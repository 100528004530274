import { ApolloError, isApolloError } from 'apollo-client';
import { Formik, FormikHelpers } from 'formik';
import React from 'react';
import { Button, Spinner, Container, Row, Col, Form, FormGroup, Label, Alert } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import {
  CreateCollectionPlaceMutation,
  CreateCollectionPlaceMutationVariables,
} from './types';

import { formatPhoneNumber } from '~/services/utils';
import useAuthMutation from '~/hooks/useAuthMutation';
import FormikScroll from '~/components/FormikScroll/FormikScroll';
import { toast } from '#/shared/utilities';
import InputField from '~/components/InputField/InputField';
import SenderForm from './SenderForm';
import CodForm from './CodForm';
import ContactForm from './ContactForm';
import { schema } from './validationSchema';

import CREATE_COLLECTION_PLACE from './createCollectionPlace.gql';

export interface CollectionPlaceFormValues {
  locationName: string;
  locationNameAddOn: string;
  senderAddressCompanyName: string;
  senderAddressStreet: string;
  senderAddressHouseNumber: string;
  senderAddressSequenceNumber: string;
  senderAddressPartCity: string;
  senderAddressCity: string;
  senderAddressZipCode: string;
  senderAddressCountryId: string;
  locationPickupPostalCode: string;
  codAddressCompanyName: string;
  codAddressStreet: string;
  codAddressHouseNumber: string;
  codAddressSequenceNumber: string;
  codAddressPartCity: string;
  codAddressCity: string;
  codAddressZipCode: string;
  codAddressCountryId: string;
  codBankPrefixAccount: string;
  codBankAccount: string;
  codBankCode: string;
  senderContactsMobile: string;
  senderContactsPhone: string;
  senderContactsEmail: string;
}

type Props = {
  refreshCollectionPlaces: () => Promise<void>;
}

const VALIDATION_ERROR_CATEGORY = 'InvalidPropertyData';

const CreateCollectionPlaceForm: React.FC<Props> = ({ refreshCollectionPlaces }) => {
  const { t } = useTranslation(['settings']);
  const [createCollectionPlace] = useAuthMutation<
    CreateCollectionPlaceMutation,
    CreateCollectionPlaceMutationVariables
  >(CREATE_COLLECTION_PLACE);

  const initialValues: CollectionPlaceFormValues = {
    locationName: '',
    locationNameAddOn: '',
    senderAddressCompanyName: '',
    senderAddressStreet: '',
    senderAddressHouseNumber: '',
    senderAddressSequenceNumber: '',
    senderAddressPartCity: '',
    senderAddressCity: '',
    senderAddressZipCode: '',
    senderAddressCountryId: '',
    locationPickupPostalCode: '',
    codAddressCompanyName: '',
    codAddressStreet: '',
    codAddressHouseNumber: '',
    codAddressSequenceNumber: '',
    codAddressPartCity: '',
    codAddressCity: '',
    codAddressZipCode: '',
    codAddressCountryId: '',
    codBankPrefixAccount: '',
    codBankAccount: '',
    codBankCode: '',
    senderContactsMobile: '',
    senderContactsPhone: '',
    senderContactsEmail: '',
  };

  const handleApolloError = (
    e: ApolloError,
    { setFieldError }: FormikHelpers<CollectionPlaceFormValues>,
  ): void => {
    e.graphQLErrors.forEach((error) => {
      const category = error.extensions?.category;
      const field = error.extensions?.property;
      const { message } = error;

      if (category === VALIDATION_ERROR_CATEGORY && field) {
        setFieldError(field, message);
      }
    });
  };

  const handleFormSubmit = async (
    values: CollectionPlaceFormValues,
    helpers: FormikHelpers<CollectionPlaceFormValues>,
  ) => {
    try {
      await createCollectionPlace({
        variables: {
          locationData: {
            ...values,
            senderContactsMobile: formatPhoneNumber(values.senderContactsMobile) || null,
            senderContactsPhone: formatPhoneNumber(values.senderContactsPhone) || null,
            senderContactsEmail: values.senderContactsEmail || null,
          },
        },
      });

      await refreshCollectionPlaces();

      toast.success(t('settings:Agents.CreateCollectionPlace.Banner.success'));
    } catch (e) {
      if (isApolloError(e)) {
        handleApolloError(e, helpers);
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={schema(t)}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Form
          onSubmit={handleSubmit}
          noValidate
        >
          <FormikScroll />
          <Container>
            <Row>
              <Col>
                <Alert
                  color="info"
                  className="mb-4"
                >
                  {t('settings:Agents.CreateCollectionPlace.description')}
                </Alert>
              </Col>
            </Row>

            <Row>
              <Col
                xs={12}
                lg={{ size: 10, offset: 1 }}
                className="pt-3 pt-md-0"
              >
                <h4 className="body font-weight-bold">
                  {t('settings:Agents.CreateCollectionPlace.generalTitle')}
                </h4>
                <FormGroup>
                  <Label>
                    {t('settings:Agents.CreateCollectionPlace.LocationName.label')}
                    <span className="text-danger">*</span>
                  </Label>
                  <InputField
                    name="locationName"
                    type="text"
                    placeholder={t('settings:Agents.CreateCollectionPlace.LocationName.placeholder')}
                  />
                </FormGroup>

                <FormGroup className="mb-5">
                  <Label>{t('settings:Agents.CreateCollectionPlace.LocationNameAddOn.label')}</Label>
                  <InputField
                    name="locationNameAddOn"
                    type="text"
                    placeholder={t('settings:Agents.CreateCollectionPlace.LocationNameAddOn.placeholder')}
                  />
                </FormGroup>

                <SenderForm />

                <h4 className="body font-weight-bold">
                  {t('settings:Agents.CreateCollectionPlace.pickupLocationTitle')}
                </h4>
                <FormGroup className="mb-5">
                  <Label>
                    {t('settings:Agents.CreateCollectionPlace.PickupLocation.PostalCode.label')}
                    <span className="text-danger">*</span>
                  </Label>
                  <InputField
                    name="locationPickupPostalCode"
                    type="text"
                    placeholder={t('validation:ZipCode.placeholder')}
                  />
                </FormGroup>

                <CodForm />
                <ContactForm />
              </Col>
            </Row>
          </Container>

          <div className="text-right mt-5">
            <Button
              color="primary"
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting && <Spinner size="sm" />}
              &nbsp;
              {t('settings:Agents.CreateCollectionPlace.submitButton')}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CreateCollectionPlaceForm;
