import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';

const dataTableRow = (props) => {
  let actions = null;

  const {
    customActions,
    selectedRow,
    rowClick,
    data: {
      id,
      actions: dataActions,
      conditionalData,
    },
    data,
    columns,
    showId,
    onRowSelect,
    history,
    className,
  } = props;

  if ((customActions && selectedRow === id) || (customActions && onRowSelect === null)) {
    actions = Object.entries(customActions).map(([key, value]) => {
      const elKey = id + key;

      if (value.showOnCondition && !value.showOnCondition(conditionalData)) {
        return null;
      }

      if (value.type === 'function') {
        const params = value.params.split(',');
        const fParams = [];

        params.map((item) => {
          const itemKey = item.replace(':', '');

          if (Object.prototype.hasOwnProperty.call(data, itemKey)) {
            return fParams.push(item.replace(item, data[itemKey]));
          }
          return null;
        });

        return (
          <Button
            color="none"
            className="btn-link-action"
            onClick={() => value.func.apply(null, fParams)}
            tooltip={value.text}
            key={elKey}
          >
            <FontAwesomeIcon icon={value.icon.class} className="mr-2" />
          </Button>
        );
      }

      if (value.type === 'link') {
        const ref = value.ref.split('/');
        let fRef = value.ref;

        ref.map((item) => {
          const itemKey = item.replace(':', '');

          if (Object.prototype.hasOwnProperty.call(data, itemKey)) {
            fRef = fRef.replace(item, data[itemKey]);
          }
          return null;
        });

        return (
          <Link to={fRef} tooltip={value.text} key={elKey}><FontAwesomeIcon
            icon={value.icon.class}
            className="mr-2"
          />
          </Link>
        );
      }

      return null;
    });
  } else if ((dataActions && selectedRow === id) || onRowSelect === null) {
    actions = dataActions;
  } else {
    actions = (
      <Button
        color="none"
        className="btn-link-action"
        onMouseEnter={() => (onRowSelect ? onRowSelect(id) : null)}
      >
        <FontAwesomeIcon
          icon={['fas', 'ellipsis-h']}
        />
      </Button>
    );
  }

  let onRowClick = null;

  if (rowClick) {
    if (rowClick.type === 'function') {
      const params = rowClick.value.params.split(',');
      const fParams = [];

      params.map((item) => {
        const key = item.replace(':', '');

        if (Object.prototype.hasOwnProperty.call(data, key)) {
          fParams.push(item.replace(item, data[key]));
        }
        return null;
      });

      onRowClick = () => rowClick.func.apply(null, fParams);
    } else if (rowClick.type === 'link') {
      const ref = rowClick.ref.split('/');
      let fRef = rowClick.ref;

      ref.map((item) => {
        const key = item.replace(':', '');

        if (Object.prototype.hasOwnProperty.call(data, key)) {
          fRef = fRef.replace(item, data[key]);
        }

        return null;
      });

      onRowClick = () => {
        history.push(fRef);
      };
    }
  }

  const items = Object.keys(data).map((key, index) => {
    if (
      (key === 'id' && !showId)
      || (columns.length > 0 && !columns.some((column) => column.index === key) && key !== 'checkbox')
      || key === 'conditionalData'
    ) {
      return null;
    }

    let width = columns[index] ? columns[index].width : null;
    let tdClassName = columns[index] ? columns[index].className : null;
    let rowClickDisabled = columns[index] ? !!columns[index].rowClickDisable : false;

    if (props.columns[index]) {
      width = props.columns[index].width || null;
      tdClassName = props.columns[index].className || null;
      rowClickDisabled = !!props.columns[index].rowClickDisable || false;
    }

    if (!rowClickDisabled && onRowClick) {
      tdClassName = tdClassName ? `${tdClassName} hover` : 'hover';
    }

    return (
      <td
        key={key}
        width={width}
        className={tdClassName}
        onClick={!rowClickDisabled ? onRowClick : null}
        onKeyPress={null}
      >
        {data[key]}
      </td>
    );
  });

  return (
    <tr
      onMouseEnter={() => (onRowSelect ? onRowSelect(id) : null)}
      onMouseLeave={() => (onRowSelect ? onRowSelect(null) : null)}
      className={selectedRow === id ? `selected ${className}` : `${className}`}
    >
      {items}
      {(customActions || data.actions) && <td key="actions" className="middle text-right actions">{actions}</td>}
    </tr>

  );
};

dataTableRow.propTypes = {
  showId: PropTypes.bool.isRequired,
  selectedRow: PropTypes.string,
  className: PropTypes.string,
  data: PropTypes.shape({ elements: PropTypes.any }),
  columns: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    index: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    className: PropTypes.string,
    rowClickDisable: PropTypes.bool,
    // If rowClick function defined, you can disable it for specific col
  })).isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  onRowSelect: PropTypes.func,
  rowClick: PropTypes.shape({
    type: PropTypes.oneOf(['link', 'function']).isRequired,
    ref: (propsData, propName) => {
      if (propsData.type === 'link' && propsData[propName] === undefined) {
        return new Error(
          'Please provide ref for type "link"',
        );
      }
      return null;
    },
    func: (propsData, propName) => {
      if (propsData.type === 'function' && propsData[propName] === undefined) {
        return new Error(
          'Please provide func for type "function"',
        );
      }
      return null;
    },
    params: (propsData, propName) => {
      if (propsData.type === 'function' && propsData[propName] === undefined) {
        return new Error(
          'Please provide params for type "function"',
        );
      }
      return null;
    },
  }),
  /**
   * @deprecated Since task #1677.
   * Use item["actions"] = <DataTableActionLink | DataTableActionFunc ... /> instead.
   */
  customActions: PropTypes.objectOf(PropTypes.shape({
    type: PropTypes.oneOf(['link', 'function']).isRequired,
    ref: (propsData, propName) => {
      if (propsData.type === 'link' && propsData[propName] === undefined) {
        return new Error(
          'Please provide ref for type "link"',
        );
      }
      return null;
    },
    func: (propsData, propName) => {
      if (propsData.type === 'function' && propsData[propName] === undefined) {
        return new Error(
          'Please provide func for type "function"',
        );
      }
      return null;
    },
    params: (propsData, propName) => {
      if (propsData.type === 'function' && propsData[propName] === undefined) {
        return new Error(
          'Please provide params for type "function"',
        );
      }
      return null;
    },
    icon: PropTypes.shape({
      class: PropTypes.array.isRequired,
      size: PropTypes.string,
    }),
    text: PropTypes.string.isRequired,
  })),
};

dataTableRow.defaultProps = {
  className: '',
  selectedRow: null,
  onRowSelect: null,
  customActions: null,
  rowClick: null,
  data: null,
};

export default withRouter(dataTableRow);
