import gql from 'graphql-tag';

export default class AgentQueryFactory {
  static agents(fields, fragments) {
    return gql`
            query agents {
                agents {
                    ${fields}
                }
            }
            ${fragments}
        `;
  }

  static agentById(fields, fragments) {
    return gql`
            query agentById($id: ID!) {
                agentById(id: $id) {
                    ${fields}          
                }
            }
            ${fragments}          
        `;
  }

  static allAgents(fields, fragments) {
    return gql`
            query allAgents {
                allAgents {
                    ${fields}
                }
            }
            ${fragments}
        `;
  }
}
