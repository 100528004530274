import React from 'react';
import type { Scope } from '../../IAuthorize';

type Props = {
  companyName: string;
  scopes: Scope[];
}

const ScopeList: React.FC<Props> = ({
  companyName,
  scopes,
}) => {
  const scopeDescriptionList = scopes.map((scope) => (
    <li key={scope.id}>
      {scope.description}
    </li>
  ));

  return (
    <>
      <p>
        Aplikace společnosti
        {' '}
        {companyName}
        {' '}
        vyžaduje přístup k některým Vašim
        údajům:
      </p>
      <ul>
        {scopeDescriptionList}
      </ul>
    </>
  );
};

export default ScopeList;
