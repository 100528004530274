import React from 'react';
import { generateReactKey } from 'Shared/utilities';
import { useTranslation } from 'react-i18next';
import TimelineIconAccordion from '../_TimelineIconAccordion/TimelineIconAccordion';

const StateWithCategory = ({ events }) => {
  const { t } = useTranslation(['status']);

  return (
    <TimelineIconAccordion
      theme="dark"
      icon={{
        type: 'fw',
        icon: ['far', 'circle'],
      }}
      className="trackAndTrace"
      title={(
        <>
          <h4>
            {t(`status:${events[0].category.code}`, events[0].category.title)}
          </h4>
          <div className="subtitle">
            {events[0].category.on}
          </div>
        </>
      )}
    >
      {events.map((event) => (
        <div
          className="event"
          key={generateReactKey()}
        >
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: event.title }} />
          <div className="subtitle">
            <span>{event.on}</span>
            {(event.author) && (
              <>
                <span>&nbsp;|&nbsp;</span>
                <span>
                  Uživatel:
                  {' '}
                  {event.author.name}
                </span>
              </>
            )}
          </div>
        </div>
      ))}
    </TimelineIconAccordion>
  );
};

export default StateWithCategory;
