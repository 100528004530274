import moment from 'moment';

import configurator from './Configurator';

const { cookieDomain } = configurator.config;
const domain = cookieDomain ? `;domain=${cookieDomain}` : '';

export default class AuthenticationHelper {
  static setCookies(
    accessToken: string,
    refreshToken: string,
    accessTokenExpiration: string,
    refreshTokenExpiration: string,
    admin = false,
  ): void {
    document.cookie = `accessToken=${accessToken};expires=${new Date(accessTokenExpiration).toUTCString()};path=/${domain}`;
    document.cookie = `refreshToken=${refreshToken};expires=${new Date(refreshTokenExpiration).toUTCString()};path=/${domain}`;
    document.cookie = `accessTokenExpiration=${accessTokenExpiration};expires=${new Date(accessTokenExpiration).toUTCString()};path=/${domain}`;

    if (admin === true || AuthenticationHelper.isAdmin === true) {
      document.cookie = `admin=${true};expires=${moment().add(1, 'months').utc().toString()};path=/${domain}`;
    }
  }

  static setAccessToken(accessToken: string, accessTokenExpiration: string): void {
    document.cookie = `accessToken=${accessToken};expires=${new Date(accessTokenExpiration).toUTCString()};path=/${domain}`;
  }

  static setFirebaseToken(token: string, expiration: Date): void {
    document.cookie = `firebaseToken=${token};expires=${expiration.toUTCString()};path=/${domain}`;
  }

  static clearCookies(): void {
    document.cookie = `accessToken=;expires=${moment().subtract(1, 'days')};path=/;${domain}`;
    document.cookie = `refreshToken=;expires=${moment().subtract(1, 'days')};path=/;${domain}`;
    document.cookie = `accessTokenExpiration=;expires=${moment().subtract(1, 'days')};path=/${domain}`;
    document.cookie = `firebaseToken=;expires=${moment().subtract(1, 'days')};path=/;${domain}`;
    document.cookie = `admin=;expires=${moment().subtract(1, 'days')};path=/${domain}`;
  }

  static get firebaseToken(): string | null {
    const cookie = document.cookie.replace(/(?:(?:^|.*;\s*)firebaseToken\s*=\s*([^;]*).*$)|^.*$/, '$1');
    return (cookie !== '') ? cookie : null;
  }

  static get accessToken(): string | null {
    const cookie = document.cookie.replace(/(?:(?:^|.*;\s*)accessToken\s*=\s*([^;]*).*$)|^.*$/, '$1');
    return (cookie !== '') ? cookie : null;
  }

  static get refreshToken(): string | null {
    const cookie = document.cookie.replace(/(?:(?:^|.*;\s*)refreshToken\s*=\s*([^;]*).*$)|^.*$/, '$1');
    return (cookie !== '') ? cookie : null;
  }

  static get accessTokenExpiration(): string | null {
    const cookie = document.cookie.replace(/(?:(?:^|.*;\s*)accessTokenExpiration\s*=\s*([^;]*).*$)|^.*$/, '$1');
    return (cookie !== '') ? cookie : null;
  }

  static get isAdmin(): boolean | null {
    const cookie = document.cookie.replace(/(?:(?:^|.*;\s*)admin\s*=\s*([^;]*).*$)|^.*$/, '$1');
    return (cookie !== '') ? !!cookie : null; // TODO: fix null code smell
  }

  static hasRole(role: string, user: Record<string, any>): boolean {
    // TODO: These null/array checks won't be necessary once we add proper types
    return user.roles && Array.isArray(user.roles) && user.roles.includes(role);
  }
}
