import * as actionTypes from 'StoreActions/actionTypes';

const totalCountChanged = (totalCount) => ({
  type: actionTypes.CONTACT_TOTAL_COUNT_CHANGED,
  payload: totalCount,
});

const pageChanged = (page) => ({
  type: actionTypes.CONTACTS_PAGE_CHANGED,
  payload: page,
});

const numberOfRowsChanged = (rowCount) => ({
  type: actionTypes.NUMBER_OF_ROWS_CHANGED,
  payload: rowCount,
});

const searchKeyChanged = (searchKey) => ({
  type: actionTypes.SEARCH_KEY_CHANGED,
  payload: searchKey,
});

const orderByChanged = (orderBy) => ({
  type: actionTypes.ORDERBY_CHANGED,
  payload: orderBy,
});

const orderByDirectionChanged = (orderByDirection) => ({
  type: actionTypes.ORDERBY_DIRECTION_CHANGED,
  payload: orderByDirection,
});

export const changePage = (page) => (dispatch) => {
  dispatch(pageChanged(page));
};

export const setNumberOfRows = (page) => (dispatch) => {
  dispatch(numberOfRowsChanged(page));
};

export const setSearchKey = (searchKey) => (dispatch) => {
  dispatch(searchKeyChanged(searchKey));
};

export const setOrderBy = (orderBy) => (dispatch) => {
  dispatch(orderByChanged(orderBy));
};

export const setOrderByDirection = (orderBy) => (dispatch) => {
  dispatch(orderByDirectionChanged(orderBy));
};

export const setTotalCount = (totalCount) => (dispatch) => {
  dispatch(totalCountChanged(totalCount));
};
