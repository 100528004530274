import React, { useState } from 'react';
import moment from 'moment';
import {
  Button, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row,
} from 'reactstrap';

import { ModalProps } from '~/hooks/useModal';
import { customManualMutation } from '#/core/models/Components/CustomMutation';
import DeliveryQueryFactory from '#/core/models/delivery/Queries/Factories/DeliveryQueryFactory';
import SVGIcon from '#/Components/Presentational/SVGIcon/SVGIcon';
import { stringifyNull } from '#/shared/utilities';
import DatePicker from '~/components/DatePicker/DatePicker';

type Props = ModalProps & {
  deliveryId: string;
  onSuccess: () => void;
};

const SnoozeModal: React.FC<Props> = ({
  deliveryId,
  onSuccess,
  isOpen,
  onToggle,
}) => {
  const defaultDate = moment()
    .add(1, 'days')
    .hours(7)
    .minutes(0)
    .seconds(0)
    .toDate();

  const [date, setDate] = useState<Date>(defaultDate);

  const [note, setNote] = useState<string | null>(null);

  const handleSubmit = async () => {
    customManualMutation({
      mutation: DeliveryQueryFactory.turnOffDeliveryImportant('id', ''),
      variables: {
        deliveryId,
        attributes: {
          to: date !== null ? moment(date).format() : date,
          note,
        },
      },
    })
      .then(() => {
        onToggle();

        onSuccess();
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => onToggle()}
      centered
      modalTransition={{ timeout: 150 }}
      className="modal-lg"
    >
      <ModalHeader toggle={() => onToggle()}>
        <div style={{
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        >
          Odebrat zásilku z Důležité
          {' '}
          <span style={{ paddingLeft: '10px' }}>
            <SVGIcon
              icon="warning"
              className="float-right"
            />
          </span>
        </div>
      </ModalHeader>

      <ModalBody className="pt-4">
        <Row className="mb-3">
          <Col md={12}>
            Zásilka bude navrácena do kategorie Důležité dne:
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <div className="datepicker-wrapper">
              <DatePicker
                open
                selected={new Date(date)}
                timeIntervals={15}
                showTimeSelect
                onChange={(calendarDate: Date) => setDate(calendarDate)}
              />
            </div>
          </Col>

          <Col
            md={6}
            className="horizontally-center-items"
          >
            Případná změna stavu ze strany přepravce
            nebude mít na opětovné označení zásilky
            příznakem &quot;Důležité&quot; žádný vliv. Pakliže dojde
            v mezidobí k ukončení doručování, zásilka se již
            do Důležité nevrátí.
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <Input
              name="note"
              value={stringifyNull(note)}
              placeholder="Přidat poznámku"
              onChange={({ target }) => { setNote(target.value); }}
            />
          </Col>
        </Row>
      </ModalBody>

      <ModalFooter className="border-0 mb-3">
        <Button
          onClick={handleSubmit}
          color="primary"
        >
          Odebrat z důležité
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default SnoozeModal;
