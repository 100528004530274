export default class CSVDropHelper {
  static handleDrop(
    acceptedFiles,
    history,
    setCSVFileUpload,
    toast,
    t,
    setModalOpen = () => null,
  ) {
    if (acceptedFiles && acceptedFiles[0]) {
      if (acceptedFiles[0].name.toLowerCase()
        .endsWith('.csv')) {
        setCSVFileUpload(acceptedFiles[0]);
        history.push('/deliveries/csv-import');
        setModalOpen(false);
        return null;
      }
      toast.error(t('deliveries:Banner.wrongFileFormat', { file: acceptedFiles[0].name }));
      setModalOpen(false);
      return null;
    }
    toast.error(t('deliveries:Banner.fileCheckError'));
    setModalOpen(false);
    return null;
  }
}
