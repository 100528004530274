import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';

import ActionMenuItem from '../../Presentational/ActionMenuItem/ActionMenuItem';

class CancelDeliveryAction extends React.Component {
  handleClick() {
    const { disabled, onClick } = this.props;

    if (!disabled) {
      onClick();
    }
  }

  render() {
    const { disabled, checkedDeliveries, t } = this.props;

    return (
      <ActionMenuItem
        icon="trash-alt"
        disabled={disabled}
        count={checkedDeliveries.length}
        onClick={() => this.handleClick()}
      >
        {t('deliveries:ActionBar.ActionMenu.cancelDelivery', { count: checkedDeliveries.length })}
      </ActionMenuItem>
    );
  }
}

export default withTranslation(['deliveries'])(CancelDeliveryAction);

CancelDeliveryAction.propTypes = {
  disabled: PropTypes.bool.isRequired,
  checkedDeliveries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onClick: PropTypes.func.isRequired,
};
