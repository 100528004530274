import {
  Badge,
  DropdownItem,
  DropdownMenu,
  Spinner,
} from 'reactstrap';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const DeliveryCloseDropdownMenu = ({ openDeliveriesList, onDeliveriesClose }) => {
  const { t } = useTranslation('deliveries');
  const { deliveriesCount } = openDeliveriesList;

  const renderDropdownItems = () => {
    if (Object.keys(openDeliveriesList).length === 0) return <DropdownItem />;

    const closingDeliveryInfo = Array.concat(openDeliveriesList.deliveriesClosingInformationByAgent);

    if (deliveriesCount === 0) {
      return (
        <DropdownItem disabled>
          {t('Banner.noDeliveriesToClose')}
        </DropdownItem>
      );
    }

    const dropdownElements = closingDeliveryInfo.map((item) => (
      <DropdownItem
        disabled={closingDeliveryInfo.deliveriesCount === 0}
        onClick={() => onDeliveriesClose(item.agent.id)}
        key={item.agent.id}
        className="d-flex justify-content-between align-items-center"
      >
        {item.agent.name}
        <Badge color="primary" pill className="ml-5">{item.deliveriesCount}</Badge>
      </DropdownItem>
    ));

    dropdownElements.push(<DropdownItem divider key="divider" />);
    dropdownElements.push(
      <DropdownItem
        onClick={() => onDeliveriesClose(null)}
        key="all"
        className="d-flex justify-content-between align-items-center"
      >
        {t('CloseDeliveriesAction.allOpenDeliveries')}
        <Badge color="primary" pill className="ml-5">{deliveriesCount}</Badge>
      </DropdownItem>,
    );

    return (
      <>
        {dropdownElements}
      </>
    );
  };

  return (
    <DropdownMenu>
      {Object.keys(openDeliveriesList).length === 0 && (
        <DropdownItem className="text-center">
          <Spinner size="sm" />
        </DropdownItem>
      )}
      {renderDropdownItems()}
    </DropdownMenu>
  );
};

DeliveryCloseDropdownMenu.propTypes = {
  openDeliveriesList: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
  ]).isRequired,
  onDeliveriesClose: PropTypes.func.isRequired,
};

export default DeliveryCloseDropdownMenu;
