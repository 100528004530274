import React from 'react';
import { withTranslation } from 'react-i18next';
import { Container } from 'reactstrap';

import SettingsBase from 'Containers/Settings/_base/SettingsBase';
import SettingsLayout from 'Components/Settings/SettingsLayout';
import { updateObject } from 'Shared/utilities';
import NotificationSettingsTable from './NotificationSettingsTable';

class SettingsChannelsDetailNotifications extends SettingsBase {
  constructor(props) {
    super(props, 'comm');
  }

  componentDidMount() {
    this.displayNotificationPage();
  }

  displayNotificationPage() {
    const state = updateObject(this.state, { isPageDisplayed: true });
    this.setState(state);
  }

  render() {
    const { t } = this.props;

    return (
      <SettingsLayout
        goBack="/settings"
        leftBlock={this.state.leftBlock}
        rightBlock={this.state.rightBlock}
        checkedItems={this.state.checkedItems}
        isOpened={this.state.isPageDisplayed}
      >
        <Container className="notifications">
          <div className="pb-3">
            <h1 className="pb-3">{t('settings:Notifications.title')}</h1>
            <p className="text-justify">
              {t('settings:Notifications.description')}
            </p>
          </div>
          <NotificationSettingsTable />
        </Container>
      </SettingsLayout>
    );
  }
}

export default withTranslation(['settings'])(SettingsChannelsDetailNotifications);
