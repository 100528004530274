import React, { useState } from 'react';
import { Card, CardBody, CardHeader, Spinner } from 'reactstrap';
import { ObservableQuery } from 'apollo-client';
import produce from 'immer';
import { useTranslation } from 'react-i18next';

import useAuthQuery from '~/hooks/useAuthQuery';
import TimelineFilters from './TimelineFilters/TimelineFilters';
import TimelineBody from './TimelineBody/TimelineBody';
import { Filter } from './types';
import deliveryTimelineByDeliveryQuery from './deliveryTimelineByDelivery.gql';

export interface Props {
  deliveryId: number,
  setRefetchTimelineEvents: (fn: ObservableQuery['refetch']) => void,
}

const DeliveryTimeline: React.FC<Props> = ({ deliveryId, setRefetchTimelineEvents }) => {
  const { t } = useTranslation('deliveries');

  const [filters, setFilters] = useState<Filter[]>(
    [{
      label: t('DeliveryDetail.Timeline.CheckboxLabels.TTStates'),
      name: 'trackAndTrace',
      value: true,
    },
    {
      label: t('DeliveryDetail.Timeline.CheckboxLabels.communication'),
      name: 'communication',
      value: true,
    },
    {
      label: t('DeliveryDetail.Timeline.CheckboxLabels.notes'),
      name: 'note',
      value: true,
    }],
  );

  function handleCheckboxChange(e: any) {
    const { name, checked } = e.target;

    const nextFilters = produce(filters, (draft) => {
      const filter = draft.find((x) => x.name === name);

      if (!filter) { return; }

      filter.value = checked;
    });

    setFilters(nextFilters);
  }

  const { loading, data, error, refetch } = useAuthQuery<any>(
    deliveryTimelineByDeliveryQuery,
    { deliveryId },
  );

  if (loading) {
    return (<Spinner />);
  }

  if (error) {
    return null;
  }

  const { events } = data.deliveryTimelineByDelivery;

  setRefetchTimelineEvents(refetch);

  return (
    <Card className="mb-5 timeline">
      <CardHeader className="with-buttons">
        <h4>{t('DeliveryDetail.Timeline.title')}</h4>

        <div className="right">
          <TimelineFilters
            filters={filters}
            onChange={(e: any) => handleCheckboxChange(e)}
          />
        </div>
      </CardHeader>

      <CardBody>
        <TimelineBody
          deliveryId={deliveryId}
          events={events}
          filters={filters}
          refetch={refetch}
        />
      </CardBody>
    </Card>
  );
};

export default DeliveryTimeline;
