import React from 'react';
import { ButtonGroup, Button } from 'reactstrap';
import { subDays, differenceInCalendarDays, endOfYesterday } from 'date-fns';
import { useTranslation } from 'react-i18next';

import DatePickerButton, { DateRange } from './DatePickerButton/DatePickerButton';

type Props = {
  onChange: (startDate: Date, endDate: Date) => void;
  dateRange: DateRange;
};

const DateControls: React.FC<Props> = ({
  dateRange,
  onChange,
}) => {
  const { t } = useTranslation('dashboard');

  const yesterday = endOfYesterday(); // should we get the base date from the server?

  const setLastRange = (x: number) => {
    onChange(subDays(yesterday, x), yesterday);
  };

  const activateBtn = (x: number): string | undefined => (
    Math.abs(differenceInCalendarDays(dateRange.start, yesterday)) === x ? 'active' : undefined
  );

  return (
    <div className="d-flex align-items-baseline">
      <span className="mr-2 text-grey-light">
        {t('DateControls.lastChosenDaysLabel')}
      </span>
      <ButtonGroup
        className="mr-3"
        size="md"
      >
        <Button
          color="light-alt"
          className={activateBtn(7)}
          onClick={() => { setLastRange(7); }}
        >
          7
          {' '}
          {t('DateControls.daysCountLabel')}
        </Button>

        <Button
          color="light-alt"
          className={activateBtn(14)}
          onClick={() => { setLastRange(14); }}
        >
          14
          {' '}
          {t('DateControls.daysCountLabel')}
        </Button>

        <Button
          color="light-alt"
          className={activateBtn(30)}
          onClick={() => { setLastRange(30); }}
        >
          30
          {' '}
          {t('DateControls.daysCountLabel')}
        </Button>
      </ButtonGroup>

      <DatePickerButton
        dateRange={dateRange}
        onApply={onChange}
      />
    </div>
  );
};

export default DateControls;
