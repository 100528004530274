import React from 'react';

import DelayedWithoutCategory from './DelayedWithoutCategory';
import DelayedWithCategory from './DelayedWithCategory';

const DelayedAccordion = ({ events }) => (
  <>
    {events[0].category ? <DelayedWithCategory events={events} /> : <DelayedWithoutCategory events={events} />}
  </>
);

export default DelayedAccordion;
