import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withApollo } from 'react-apollo';
import { Button } from 'reactstrap';
import produce from 'immer';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import UserReducer from 'Types/redux/Reducers/UserReducer';

import MainBody from 'Components/Presentational/Layout/MainBody/MainBody';
import DataTable from 'Containers/DataTable/DataTable';
import Pagination from 'Components/Presentational/Pagination/Pagination';
import DeliveryTableAgent from 'Components/Deliveries/DeliveryTableAgent';
import ProtocolsModal from 'Components/Presentational/ProtocolsModal/ProtocolsModal';
import { getDeliveryInflectionText } from 'Shared/utilities';
import FilesFragments from 'Models/Files/Query/Fragments/FilesFragments';
import Base64Helper from 'Tools/Base64Helper';
import PaginatorHelper from 'Tools/PaginatorHelper';
import { CustomQuery } from 'Models/Components/CustomQuery';
import ProtocolQueryFactory from 'Models/delivery/Protocols/ProtocolQueryFactory';
import ApolloQueryTypes from 'Types/apollo/ApolloQueryTypes';
import EmptyState from '~/components/EmptyState/EmptyState';
import AuthenticationHelper from '~/services/Auth';
import { format } from '~/services/date';
import { getCarrierLogoUrl } from '~/services/utils';

export const DELIVERY_COLLECTION_PROTOCOLS = ProtocolQueryFactory.deliveryCollectionProtocols(`
  totalCount
  items {
    id
    deliveriesCount
    agent {
      originalName
      logoSlug
    }
    collectionPlace { id name address { street city country zip } }
    created
  }
`);

const GET_PDF = ProtocolQueryFactory.deliveryCollectionProtocolPdfById(
  '...FileBase64',
  FilesFragments.fileBase64,
);

class Protocols extends React.Component {
  constructor(props) {
    super(props);

    const { t } = this.props;

    this.pdfLinkRef = React.createRef();
    this.numberOfRows = 25;

    this.state = {
      checked: [],
      showProtocolsModal: false,
      filter: {
        limit: this.numberOfRows,
        offset: 0,
        orderBy: 'created',
        orderByDirection: 'DESC',
      },
      pdf: {
        link: '#',
        name: '',
      },
    };

    this.totalPages = null;
    this.table = {
      columns: [
        {
          title: '',
          index: 'deliveryCount',
          key: '1',
          className: 'middle',
        },
        {
          title: '',
          index: 'agent',
          key: '2',
        },
        {
          title: '',
          index: 'address',
          key: '3',
        },
        {
          title: '',
          index: 'datetime',
          key: '4',
          className: 'middle',
        },
      ],
      actions: {
        custom: {
          detail: {
            type: 'link',
            ref: '/protocols/:id',
            icon: { class: ['far', 'eye'] },
            text: t('protocols:ListItemTooltip.protocolDetail'),
          },
          export: {
            type: 'function',
            func: this.export,
            params: ':id',
            icon: { class: ['fas', 'download'] },
            text: t('protocols:ListItemTooltip.downloadProtocol'),
          },
        },
        rowClick: {
          type: 'link',
          ref: '/protocols/:id',
        },
      },
      config: {
        hideActionButtons: true,
        striped: false,
        borderless: false,
        className: 'basic protocols',
      },

      isLoaderVisible: false,
    };
  }

  handleProtocolsSubmit = (refetchProtocols) => {
    this.toggleProtocols();
    refetchProtocols();
  };

  onProtocolsCancel = () => {
    this.toggleProtocols();
  };

  toggleProtocols = () => {
    let { showProtocolsModal } = this.state;
    showProtocolsModal = !showProtocolsModal;

    this.setState({ showProtocolsModal });
  };

  export = async (id) => {
    const { client } = this.props;
    const res = await client.query({
      query: GET_PDF,
      variables: { id },
      context: { headers: { AccessToken: AuthenticationHelper.accessToken } },
    });

    const protocolPdf = res.data.deliveryCollectionProtocolPdfById;
    const link = Base64Helper.createLinkForEncryptedFile(protocolPdf.rawSource, 'application/pdf');
    const state = produce(this.state, (draft) => {
      draft.pdf.link = link;
      draft.pdf.name = protocolPdf.name;
    });
    this.setState(state);

    return this.pdfLinkRef.current.click();
  };

  formatTableData = (data, t) => data.map((protocol) => {
    const currentItem = {};
    currentItem.deliveryCount = (
      <div className="deliveries-count">
        {`${protocol.deliveriesCount} ${getDeliveryInflectionText(protocol.deliveriesCount, t)}`}
      </div>
    );
    currentItem.agent = (
      <DeliveryTableAgent
        img={getCarrierLogoUrl(protocol.agent.logoSlug)}
        imgAlt={protocol.agent.originalName}
      />
    );
    currentItem.address = (
      <div className="collection-place">
        <span className="d-block">{protocol.collectionPlace.name}</span>
        {t('protocols:collectionPlaceLabel')}
      </div>
    );
    currentItem.datetime = (
      <div className="created">
        <span className="d-block">
          {format(new Date(protocol.created), 'PP | p')}
        </span>
        {t('protocols:protocolCreatedDateLabel')}
      </div>
    );
    currentItem.id = protocol.id;
    return currentItem;
  });

  handlePaginatorClick(page, refetch) {
    const state = produce(this.state, (draft) => {
      const { filter: { limit } } = this.state;
      draft.filter.offset = PaginatorHelper.computeOffset(page, limit);
    });

    this.setState(state, () => refetch);
  }

  render() {
    const { t, user } = this.props;
    const { collectionPlaces } = user.data.company;
    const { filter, pdf, showProtocolsModal } = this.state;

    const extraButtons = (
      <Button color="primary" onClick={() => this.toggleProtocols()}>
        <FontAwesomeIcon icon={['fas', 'plus']} fixedWidth />
        {' '}
        {t('protocols:newProtocolButton')}
      </Button>
    );

    return (
      <MainBody
        wrapperHeight="150px"
        title={t('protocols:title')}
        extraButtons={collectionPlaces.length > 0 ? extraButtons : null}
      >
        <CustomQuery
          query={DELIVERY_COLLECTION_PROTOCOLS}
          variables={filter}
        >
          {({ data, error, loading, refetch: refetchProtocols }) => {
            if (error || data === undefined || data.deliveryCollectionProtocols === undefined) {
              return null;
            }

            if (!loading && !this.totalPages && Object.keys(data).length > 0) {
              this.totalPages = PaginatorHelper.computeTotalPages(
                data.deliveryCollectionProtocols.totalCount,
                this.numberOfRows,
              );
            }

            let content = (
              <div>
                <DataTable
                  data={!loading ? this.formatTableData(data.deliveryCollectionProtocols.items, t) : []}
                  columns={this.table.columns}
                  actions={this.table.actions}
                  config={this.table.config}
                  isLoaderVisible={loading}
                />
                <Pagination
                  onPageChanged={(page) => this.handlePaginatorClick(page, refetchProtocols)}
                  totalPages={this.totalPages || 1}
                />
                <a
                  href={pdf.link}
                  download={pdf.name}
                  ref={this.pdfLinkRef}
                  style={{ display: 'none' }}
                >
                  {t('protocols:NewProtocolModal.PDFLink')}
                </a>
              </div>
            );

            if (!loading && data.deliveryCollectionProtocols.items.length === 0) {
              content = (
                <EmptyState
                  imgSrc="/assets/img/empty-states/in-progress.png"
                  imgDescription={t('protocols:EmptyState.imageDescription')}
                  title={t('protocols:EmptyState.title')}
                  description={t('protocols:EmptyState.description')}
                >
                  <Link to="/deliveries">
                    <Button color="primary">
                      {t('protocols:EmptyState.goToDeliveries')}
                    </Button>
                  </Link>
                </EmptyState>
              );
            }

            return (
              <>
                {content}

                <ProtocolsModal
                  onCancel={this.onProtocolsCancel}
                  onPrompt={() => this.handleProtocolsSubmit(refetchProtocols)}
                  toggle={this.toggleProtocols}
                  isOpen={showProtocolsModal}
                />
              </>
            );
          }}
        </CustomQuery>
      </MainBody>
    );
  }
}

Protocols.propTypes = {
  client: ApolloQueryTypes.client().isRequired,
  user: UserReducer.state().isRequired,
};

function mapStateToProps(state) {
  return { user: state.userReducer };
}

export default connect(mapStateToProps)(withApollo(withTranslation(['protocols'])(Protocols)));
