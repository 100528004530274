import React from 'react';

import { MappedRow } from '../types';
import ErrorTableCell from './ErrorTableCell';

type Props = {
  index: number;
  row: MappedRow,
}

const ErrorTableRow: React.FC<Props> = ({
  row,
  index,
}) => {
  const rowNumber = index + 1;

  return (
    <tr>
      {row.rowLevelError ? (
        <ErrorTableCell
          cell={{
            row: index,
            error: row.rowLevelError,
            id: `error-table-row-number-${index}`,
            column: null,
            value: `${rowNumber}`,
          }}
        />
      ) : (
        <td>
          {rowNumber}
        </td>
      )}

      {row.cells.map((cell) => (
        <ErrorTableCell
          key={cell.id}
          cell={cell}
        />
      ))}
    </tr>
  );
};

export default ErrorTableRow;
