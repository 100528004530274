import React from 'react';
import { Spinner } from 'reactstrap';

import useAuthQuery from '~/hooks/useAuthQuery';
import TimelineAccordionSection from './TimelineAccordionSection/TimelineAccordionSection';
import NoteSection from './NoteSection/NoteSection';
import { Filter } from '../types';

import deliveryByIdQuery from './deliveryById.gql';

type Props = {
  deliveryId: number;
  events: any[];
  filters: Filter[];
  refetch: () => any;
};

const TimelineBody: React.FC<Props> = ({
  deliveryId,
  events,
  filters,
  refetch,
}) => {
  const { loading, data, error, refetch: noteQueryRefetch } = useAuthQuery<any>(
    deliveryByIdQuery,
    { id: deliveryId },
  );

  if (loading) {
    return (<Spinner />);
  }

  if (error) {
    return null;
  }

  const { notes } = data.deliveryById;

  return (
    <>
      <NoteSection
        deliveryId={deliveryId}
        refetchNotes={noteQueryRefetch}
        refetchTimelineEvents={refetch}
      />

      <TimelineAccordionSection
        notes={notes}
        deliveryId={deliveryId}
        refetchTimelineEvents={refetch}
        filters={filters}
        events={events}
      />
    </>
  );
};

export default TimelineBody;
