import PropTypes from 'prop-types';

const deliveryFilter = PropTypes.shape({
  orderBy: PropTypes.string,
  orderByDirection: PropTypes.string,
  filter: PropTypes.string,
  flags: PropTypes.shape({ important: PropTypes.bool }),
});

const deliveryUpload = PropTypes.shape({ csvFile: PropTypes.oneOf([PropTypes.null, PropTypes.file]) });

export default {
  deliveryFilter,
  deliveryUpload,
};
