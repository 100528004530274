import React from 'react';
import PropTypes from 'prop-types';

import { Table } from 'reactstrap';
import DataTableRow from 'Components/Presentational/DataTable/DataTableRow';
import { TableLoader } from 'Components/components';

const dataTable = (props) => {
  const {
    data,
    columns,
    actions: {
      custom: actionsCustom,
      rowClick: actionsRowClick,
    },
    config: {
      showId: configShowId,
      hideActionButtons: configHideActionButtons,
      striped: configStriped,
      borderless: configBorderLess,
      className: configClassName,
      showHeader,
    },
    onRowSelect,
    selectedRow,
    isLoaderVisible,
  } = props;

  const rows = data.map((item) => (
    <DataTableRow
      key={item.id}
      data={item}
      columns={columns}
      customActions={actionsCustom}
      rowClick={actionsRowClick}
      showId={configShowId || false}
      hideActionButtons={configHideActionButtons}
      onRowSelect={configHideActionButtons ? onRowSelect : null}
      selectedRow={selectedRow}
      className={item.className}
    />
  ));

  const columnsList = columns.map((column) => (
    <th key={column.index} id={column.id} width={column.width}>{column.title}</th>
  ));

  return (
    <div>
      <TableLoader isVisible={isLoaderVisible} />
      <Table
        striped={configStriped}
        borderless={configBorderLess}
        className={configClassName}
      >
        {showHeader && (
          <thead>
            <tr>
              {columnsList}
            </tr>
          </thead>
        )}
        <tbody>
          {rows}
        </tbody>
      </Table>
    </div>
  );
};

dataTable.propTypes = {
  config: PropTypes.shape({
    showId: PropTypes.bool,
    hideActionButtons: PropTypes.bool,
    borderless: PropTypes.bool,
    striped: PropTypes.bool,
    className: PropTypes.string,
  }),
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    index: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    className: PropTypes.string,
    rowClickDisable: PropTypes.bool,
  })).isRequired,
  actions: PropTypes.shape({
    /**
     * @deprecated Since task #1677.
     * Use item["actions"] = <DataTableActionLink | DataTableActionFunc ... /> instead.
     */
    custom: PropTypes.objectOf(PropTypes.shape({
      type: PropTypes.oneOf(['link', 'function']).isRequired,
      ref: (props, propName) => {
        const { type } = props;
        const { hasOwnProperty } = Object.prototype;

        if (type === 'link' && !hasOwnProperty.call(props, propName)) {
          return new Error(
            'Please provide ref for type "link"',
          );
        }

        return null;
      },
      func: (props, propName) => {
        const { type } = props;
        const { hasOwnProperty } = Object.prototype;

        if (type === 'function' && !hasOwnProperty.call(props, propName)) {
          return new Error(
            'Please provide func for type "function"',
          );
        }

        return null;
      },
      params: (props, propName) => {
        const { type } = props;
        const { hasOwnProperty } = Object.prototype;

        if (type === 'function' && !hasOwnProperty.call(props, propName)) {
          return new Error(
            'Please provide params for type "function"',
          );
        }

        return null;
      },
      icon: PropTypes.shape({
        class: PropTypes.array.isRequired,
        size: PropTypes.string,
      }),
      text: PropTypes.string.isRequired,
    })),
    rowClick: PropTypes.shape({
      type: PropTypes.oneOf(['link', 'function']).isRequired,
      ref: (props, propName) => {
        const { type } = props;
        const { hasOwnProperty } = Object.prototype;

        if (type === 'link' && !hasOwnProperty.call(props, propName)) {
          return new Error(
            'Please provide ref for type "link"',
          );
        }

        return null;
      },
      func: (props, propName) => {
        const { type } = props;
        const { hasOwnProperty } = Object.prototype;

        if (type === 'function' && !hasOwnProperty.call(props, propName)) {
          return new Error(
            'Please provide func for type "function"',
          );
        }

        return null;
      },
      params: (props, propName) => {
        const { type } = props;
        const { hasOwnProperty } = Object.prototype;

        if (type === 'function' && !hasOwnProperty.call(props, propName)) {
          return new Error(
            'Please provide params for type "function"',
          );
        }

        return null;
      },
    }),
  }),
  isLoaderVisible: PropTypes.bool.isRequired,
  selectedRow: PropTypes.string,
  onRowSelect: PropTypes.func.isRequired,
};

dataTable.defaultProps = {
  config: {
    showHeader: true,
    showId: false,
    hideActionButtons: false,
    borderless: false,
    striped: true,
    className: null,
  },
  actions: {
    custom: null,
    rowClick: null,
  },
  selectedRow: null,
};

export default dataTable;
