import { UncontrolledTooltip } from 'reactstrap';
import React from 'react';

const TooltipWithSingleUser = ({ user, tooltipTarget }) => (
  <UncontrolledTooltip placement="bottom" target={tooltipTarget}>
    Zásilku si prohlíží
    {' '}
    {user.userName}
    {' '}
    {user.userSurname}
  </UncontrolledTooltip>
);

export default TooltipWithSingleUser;
