import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { withTranslation } from 'react-i18next';
import { Col, Button, Container, Row, Form, FormGroup, Input, Label, FormFeedback } from 'reactstrap';

import SettingsBase from 'Containers/Settings/_base/SettingsBase';
import SettingsLayout from 'Components/Settings/SettingsLayout';
import Loader from 'Containers/Loader/Loader';
import CompanyDataSource from 'Models/company/CompanyDataSource';

class Company extends SettingsBase {
  constructor(props) {
    super(props);
    this.company = new CompanyDataSource();
  }

  async componentDidMount() {
    await this.sendQueryAndStoreData();
  }

  async sendQueryAndStoreData() {
    const data = await this.company.selectById();
    const formattedCompanyData = {
      name: data.data.company.name,
      managingDirector: data.data.company.managingDirector,
      identificationNumber: data.data.company.identificationNumber,
      vatNumber: data.data.company.vatNumber,
      address: data.data.company.address,
    };

    this.setState({
      isOpened: true,
      isPartLoading: false,
      company: formattedCompanyData,
    });
  }

  form = ({
    handleSubmit,
    handleChange,
    handleBlur,
    isSubmitting,
    values,
    touched,
    errors,
  }) => {
    const { t } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        <h1 className="pb-5">{t('settings:CompanyInfo.title')}</h1>
        <Row>
          <Col md="4">
            <h4 className="text-nowrap">{t('settings:CompanyInfo.Subtitle.about')}</h4>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label htmlFor="companyName" style={{ display: 'block' }}>
                {t('settings:CompanyInfo.CompanyName.label')}
              </Label>
              <Input
                name="companyName"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.companyName}
                invalid={errors.companyName !== undefined}
                valid={touched.companyName && !errors.companyName}
              />
              {touched.companyName && errors.companyName && <FormFeedback>{errors.companyName}</FormFeedback>}
            </FormGroup>
            <FormGroup>
              <Label htmlFor="companyManagerName" style={{ display: 'block' }}>
                {t('settings:CompanyInfo.ManagerName.label')}
              </Label>
              <Input
                name="companyManagerName"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.companyManagerName}
                invalid={errors.companyManagerName !== undefined}
                valid={touched.companyManagerName && !errors.companyManagerName}
              />
              {touched.companyManagerName && errors.companyManagerName && <FormFeedback>{errors.companyManagerName}</FormFeedback>}
            </FormGroup>
            <FormGroup>
              <Label htmlFor="companyManagerSurname" style={{ display: 'block' }}>
                {t('settings:CompanyInfo.ManagerSurname.label')}
              </Label>
              <Input
                name="companyManagerSurname"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.companyManagerSurname}
                invalid={errors.companyManagerSurname !== undefined}
                valid={touched.companyManagerSurname && !errors.companyManagerSurname}
              />
              {touched.companyManagerSurname && errors.companyManagerSurname && <FormFeedback>{errors.companyManagerSurname}</FormFeedback>}
            </FormGroup>
            <FormGroup>
              <Label htmlFor="companyEmail" style={{ display: 'block' }}>
                {t('settings:CompanyInfo.InvoiceEmail.label')}
              </Label>
              <Input
                name="companyEmail"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.companyEmail}
                invalid={errors.companyEmail !== undefined}
                valid={touched.companyEmail && !errors.companyEmail}
              />
              {touched.companyEmail && errors.companyEmail && <FormFeedback>{errors.companyEmail}</FormFeedback>}
            </FormGroup>
            <FormGroup>
              <Label htmlFor="companyPhone" style={{ display: 'block' }}>
                {t('validation:Phone.label')}
              </Label>
              <Input
                name="companyPhone"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.companyPhone}
                invalid={errors.companyPhone !== undefined}
                valid={touched.companyPhone && !errors.companyPhone}
              />
              {touched.companyPhone && errors.companyPhone && <FormFeedback>{errors.companyPhone}</FormFeedback>}
            </FormGroup>
          </Col>
        </Row>
        <Row className="pt-5">
          <Col md="4">
            <h4>{t('settings:CompanyInfo.Subtitle.invoicing')}</h4>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label htmlFor="billingIdNumber" style={{ display: 'block' }}>
                {t('settings:CompanyInfo.IdNumber.label')}
              </Label>
              <Input
                name="billingIdNumber"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.billingIdNumber}
                invalid={errors.billingIdNumber !== undefined}
                valid={touched.billingIdNumber && !errors.billingIdNumber}
              />
              {touched.billingIdNumber && errors.billingIdNumber && <FormFeedback>{errors.billingIdNumber}</FormFeedback>}
            </FormGroup>
            <FormGroup>
              <Label htmlFor="billingVat" style={{ display: 'block' }}>
                {t('settings:CompanyInfo.VatNumber.label')}
              </Label>
              <Input
                name="billingVat"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.billingVat}
                invalid={errors.billingVat !== undefined}
                valid={touched.billingVat && !errors.billingVat}
              />
              {touched.billingVat && errors.billingVat && <FormFeedback>{errors.billingVat}</FormFeedback>}
            </FormGroup>
            <FormGroup className="pt-2">
              <Label htmlFor="billingStreet" style={{ display: 'block' }}>
                {t('validation:Street.label')}
              </Label>
              <Input
                name="billingStreet"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.billingStreet}
                invalid={errors.billingStreet !== undefined}
                valid={touched.billingStreet && !errors.billingStreet}
              />
              {touched.billingStreet && errors.billingStreet && <FormFeedback>{errors.billingStreet}</FormFeedback>}
            </FormGroup>
            <div className="form-row">
              <FormGroup className="col-md-8">
                <Label htmlFor="billingCity" style={{ display: 'block' }}>
                  {t('validation:City.label')}
                </Label>
                <Input
                  name="billingCity"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.billingCity}
                  invalid={errors.billingCity !== undefined}
                  valid={touched.billingCity && !errors.billingCity}
                />
                {touched.billingCity && errors.billingCity && <FormFeedback>{errors.billingCity}</FormFeedback>}
              </FormGroup>
              <FormGroup className="col-md-4">
                <Label htmlFor="billingZipCode" style={{ display: 'block' }}>
                  {t('validation:ZipCode.label')}
                </Label>
                <Input
                  name="billingZipCode"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.billingZipCode}
                  invalid={errors.billingZipCode !== undefined}
                  valid={touched.billingZipCode && !errors.billingZipCode}
                />
                {touched.billingZipCode && errors.billingZipCode && <FormFeedback>{errors.billingZipCode}</FormFeedback>}
              </FormGroup>
            </div>
          </Col>
        </Row>
        <Row className="pt-5">
          <Col md="12" className="text-center">
            <Button type="submit" color="primary" disabled={isSubmitting}>
              {t('settings:CompanyInfo.submitButton')}
            </Button>
            <Link to="/settings">
              <Button color="link" className="ml-3">
                {t('settings:CompanyInfo.cancelButton')}
              </Button>
            </Link>
          </Col>
        </Row>
      </Form>
    );
  };

  render() {
    const { t } = this.props;
    let content = <Loader />;

    if (this.state.company !== undefined) {
      content = (
        <Container>
          <Formik
            initialValues={{
              companyName: this.state.company.name,
              companyManagerName: this.state.company.managingDirector.name,
              companyManagerSurname: this.state.company.managingDirector.surname,
              companyEmail: this.state.company.managingDirector.email,
              companyPhone: this.state.company.managingDirector.phone,
              billingIdNumber: this.state.company.identificationNumber,
              billingVat: this.state.company.vatNumber,
              billingStreet: this.state.company.address.street,
              billingCity: this.state.company.address.city,
              billingZipCode: this.state.company.address.zip,
              paymentCardHolder: this.state.company.managingDirector.name,
              paymentCardLastNumbers: this.state.company.managingDirector.name,
            }}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              const company = {
                name: values.companyName,
                identificationNumber: values.billingIdNumber,
                vatNumber: values.billingVat,
                managingDirector: {
                  name: values.companyManagerName,
                  surname: values.companyManagerSurname,
                  email: values.companyEmail,
                  phone: values.companyPhone,
                },
                address: {
                  street: values.billingStreet,
                  city: values.billingCity,
                  zip: values.billingZipCode,
                  country: 'CZ',
                },
              };

              try {
                await this.company.updateCompany(company);
                toast.success(t('settings:CompanyInfo.Banner.success'));
                setSubmitting(false);
              } catch (e) {
                setSubmitting(false);
              }
            }}
            validationSchema={Yup.object().shape({
              companyName: Yup.string()
                .required(t('settings:CompanyInfo.CompanyName.required'))
                .max(127, t('settings:CompanyInfo.CompanyName.maxLength', { count: 127 })),
              companyManagerName: Yup.string()
                .required(t('settings:CompanyInfo.ManagerName.required'))
                .max(127, t('settings:CompanyInfo.ManagerName.maxLength', { count: 127 })),
              companyManagerSurname: Yup.string()
                .required(t('settings:CompanyInfo.ManagerSurname.required'))
                .max(127, t('settings:CompanyInfo.ManagerSurname.maxLength', { count: 127 })),
              companyEmail: Yup.string()
                .required(t('settings:CompanyInfo.InvoiceEmail.required'))
                .email(t('validation:Email.pattern'))
                .max(255, t('validation:Email.maxLength', { count: 255 })),
              companyPhone: Yup.string()
                .required(t('validation:Phone.required'))
                .matches(/^(?:\+\d{2})?\d{10}(?:,(?:\+\d{2})?\d{10})*$/, { message: t('validation:Phone.pattern') }),
              billingIdNumber: Yup.string()
                .required(t('settings:CompanyInfo.IdNumber.required'))
                .matches(/^\d{8}$/, t('settings:CompanyInfo.IdNumber.pattern')),
              billingVat: Yup.string()
                .matches(/^[A-Z]{2}\d{8,10}$/, t('settings:CompanyInfo.VatNumber.pattern'))
                .nullable(),
              billingStreet: Yup.string()
                .required(t('validation:Street.required'))
                .max(127, t('validation:Street.maxLength', { count: 127 })),
              billingCity: Yup.string()
                .required(t('validation:City.required'))
                .max(127, t('validation:City.maxLength', { count: 127 })),
              billingZipCode: Yup.string()
                .required(t('validation:ZipCode.required'))
                .matches(/^\d{3} ?\d{2}$/, t('validation:ZipCode.pattern')),
            })}
            component={this.form}
          />
        </Container>
      );
    }

    return (
      <SettingsLayout
        goBack="/settings"
        leftBlock={this.state.leftBlock}
        rightBlock={this.state.rightBlock}
        checkedItems={this.state.checkedItems}
        isOpened={this.state.isOpened}
        isPartLoading={this.state.isPartLoading}
      >
        {content}
      </SettingsLayout>
    );
  }
}

export default withTranslation(['settings', 'validation'])(Company);
