import gql from 'graphql-tag';

export default class BatchQueryFactory {
  static deliveryBatches(fields) {
    return gql`
                query deliveryBatches(
                    $limit: Int!
                    $offset: Int
                    $agentId: ID
                    $collectionPlaceId: ID
                    $createdById: ID
                    $created: Date
                    $orderBy: EnumDeliveryBatchOrderByFields
                    $orderByDirection: EnumOrderByDirection) {
                    deliveryBatches(
                        limit: $limit
                        offset: $offset
                        agentId: $agentId
                        collectionPlaceId: $collectionPlaceId
                        createdById: $createdById
                        created: $created
                        orderBy: $orderBy
                        orderByDirection: $orderByDirection) {
                            ${fields}
                        }
                    }`;
  }

  static deliveryBatchCsvExportById(fields, fragments = '') {
    return gql`
            query deliveryBatchCsvExportById($id: ID!) {
                deliveryBatchCsvExportById(id: $id) {
                    ${fields}
                }
            }
            ${fragments}`;
  }

  static deliveryBatchById(fields) {
    return gql`
            query deliveryBatchById($id: ID!) {
                deliveryBatchById(id: $id) {
                   ${fields}
                }
            }
        `;
  }
}
