import { filterObjectEmptyProperties } from 'Containers/Settings/Branding/helpers/filterObjectEmptyProperties';
import { ImageResource } from 'Containers/Settings/Branding/typesAndDefaults';

export type TrackAndTraceValues = {
  id?: string;
  title: string,
  primaryColor: string,
  linkColor: string,
  headerColor: string,
  headerBackgroundColor: string,
  footerColor: string,
  footerBackgroundColor: string,
  buttonColor: string,
  buttonBackgroundColor: string,
  tagColor: string,
  tagBackgroundColor: string,
  selectedTagColor: string,
  selectedTagBackgroundColor: string,
  progressBarColor: string,
  enabledRating: boolean,

  desktopBackground?: null | File | string;
  mobileBackground?: null | File | string;
  // the same naming as in the backend which is accidentally the opposite of the frontend naming convection
  // so in this cas desktopBackgroundImage is holder for Image object data
  desktopBackgroundImage?: ImageResource | null;
  mobileBackgroundImage?: ImageResource | null

  logo?: ImageResource | null;
  favicon?: ImageResource | null;
  logoImg?: null | File | string;
  faviconImg?: null | File | string;

  activateAfterSave?: boolean;
};

export const initialTTValues: TrackAndTraceValues = {
  title: '',
  buttonBackgroundColor: '',
  buttonColor: '',
  enabledRating: true,
  footerBackgroundColor: '',
  footerColor: '',
  headerBackgroundColor: '',
  headerColor: '',
  linkColor: '',
  primaryColor: '',
  progressBarColor: '',
  selectedTagBackgroundColor: '',
  selectedTagColor: '',
  tagBackgroundColor: '',
  tagColor: '',
};

export type MutationTrackAndTraceConfig = {
  id?: string,
  config: TrackAndTraceConfigMutationInput;
};

export type TrackAndTraceConfigMutationInput = {
  title: string;
  linkColor: string;
  primaryColor: string;
  enabledRating: boolean;
  footerBackgroundColor: string;
  headerBackgroundColor: string;

  tagColor?: string;
  selectedTagColor?: string;
  tagBackgroundColor?: string;
  selectedTagBackgroundColor?: string;
  headerColor?: string;
  footerColor?: string;
  buttonColor?: string;
  buttonBackgroundColor?: string;
  progressBarColor?: string;

  logoId?: string;
  faviconId?: string;
  mobileBackgroundImageId?: string;
  desktopBackgroundImageId?: string;
};

export type QueryEshopTrackAndTraceConfig = {
  eshop: {
    trackAndTraceConfigs: TTConfig[];
    activeTrackAndTraceConfig: TTConfig | null;
  }
};

export const convertTrackAndTraceConfigToInput = (trackAndTraceValues: TrackAndTraceValues): TrackAndTraceConfigMutationInput => ({
  title: trackAndTraceValues.title,
  linkColor: trackAndTraceValues.linkColor,
  primaryColor: trackAndTraceValues.primaryColor,
  enabledRating: trackAndTraceValues.enabledRating,
  footerBackgroundColor: trackAndTraceValues.footerBackgroundColor,
  headerBackgroundColor: trackAndTraceValues.headerBackgroundColor,

  ...filterObjectEmptyProperties({
    tagColor: trackAndTraceValues.tagColor,
    selectedTagColor: trackAndTraceValues.selectedTagColor,
    tagBackgroundColor: trackAndTraceValues.tagBackgroundColor,
    selectedTagBackgroundColor: trackAndTraceValues.selectedTagBackgroundColor,

    headerColor: trackAndTraceValues.headerColor,
    footerColor: trackAndTraceValues.footerColor,
    buttonColor: trackAndTraceValues.buttonColor,
    buttonBackgroundColor: trackAndTraceValues.buttonBackgroundColor,
    progressBarColor: trackAndTraceValues.progressBarColor,
  }),
});

export type CreateTrackAndTraceConfigResponse = {
  createTrackAndTraceConfig: TrackAndTraceValues;
};

export type UpdateTrackAndTraceConfigResponse = {
  updateTrackAndTraceConfig: TrackAndTraceValues;
};

export type TTConfig = {
  id: string;
  title: string;
  lastUpdatedAt: string;
  primaryColor: string;
  linkColor: string;
  headerColor: string;
  headerBackgroundColor: string;
  footerColor: string;
  footerBackgroundColor: string;
  buttonColor: string;
  buttonBackgroundColor: string;
  progressBarColor: string;
  logo?: ImageResource | null;
  desktopBackgroundImage?: ImageResource | null;
};

export type MutationTTActivate = {
  activateTrackAndTraceConfig: {
    id: string;
  }
};

export type MutationTTDeactivate = {
  deactivateTrackAndTraceConfig: {
    id: string;
  }
};

export type MutationTTDelete = {
  deleteTrackAndTraceConfig: {
    success: string;
    message: string;
  }
};
