import React from 'react';
import PropTypes from 'prop-types';

import basePaths from 'Routes/paths/base.path';
import LocalDeliveryTypes from '../../LocalDeliveryTypes/LocalDeliveryTypes';
import EditDeliveryUI from '../../Presentational/EditDelivery/EditDelivery';

export default class EditDelivery extends React.Component {
  editDelivery = async () => {
    const { push, deliveryId } = this.props;
    push(`${basePaths.deliveries}/edit/${deliveryId}`);
  };

  render() {
    const { canUpdate } = this.props;

    return (
      <EditDeliveryUI canUpdate={canUpdate} onEditClick={() => this.editDelivery()} />
    );
  }
}

EditDelivery.propTypes = {
  canUpdate: PropTypes.bool.isRequired,
  push: LocalDeliveryTypes.every().push.isRequired,
  deliveryId: LocalDeliveryTypes.every().deliveryId.isRequired,
};
