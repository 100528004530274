import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import { generateReactKey } from '../../../shared/utilities';
import routesList from '../../../core/routes/web.route';

function App() {
  const content = (
    <main>
      <Switch>
        {routesList.map((routeProps) => <Route {...routeProps} key={generateReactKey()} state={routeProps.state} />)}
      </Switch>
    </main>
  );

  return (
    <Suspense fallback={<></>}>
      {content}
    </Suspense>
  );
}

export default App;
