import produce from 'immer';
import React from 'react';
import SettingsLayout from 'Components/Settings/SettingsLayout';
import basePaths from 'Routes/paths/base.path';
import settingsNav from 'Routes/nav/settings.nav';
import { updateObject, hasProperty } from 'Shared/utilities';
import CompanyDataSource from 'Models/company/CompanyDataSource';
import EshopDataSource from 'Models/eshop/EshopDataSource';

class SettingsBase extends React.Component {
  constructor(props, eshopPart = '', useLoadingState = true) {
    super(props);

    this.t = props.t;
    this.navigation = settingsNav(this.t);
    this.userLoadingState = useLoadingState;
    this.eshopPart = eshopPart;
    this.company = new CompanyDataSource();
    this.eshop = new EshopDataSource();

    const data = {
      company: '',
      eshop: '',
      eshopLogo: null,
      checkedItems: [
        'shop_comm_emails',
        'shop_branding_marketing_campaigns',
        'shop_branding_track_and_trace',
        'shop_branding_mailing',
      ],
    };

    const leftBlock = this.prepareLeftBlockData(eshopPart, data);

    this.state = {
      children: null,
      checkedItems: [],
      isOpened: false,
      modalIsOpen: false,
      isPartLoading: true,
      leftBlock,
      rightBlock: {
        header: {
          title: data.eshop,
          subTitle: `${this.t('settings:Navigation.Eshop.title')}`,
          eshopLogo: data.eshopLogo,
          button: {
            text: `${this.t('settings:Navigation.newEshopButton')}`,
            path: `${basePaths.settings}/channels/new`,
            class: {
              color: 'secondary',
              size: 'xs',
              outline: false,
            },
          },
          percentDone: 20,
        },
        customHeader: null,
        main: {
          left: [
            {
              name: `${this.t('settings:Navigation.Eshop.Subtitle.general')}`,
              items: this.navigation.generalRight,
            },
          ],
          right: [
            {
              name: `${this.t('settings:Navigation.Eshop.Subtitle.communication')}`,
              items: this.navigation.communicationRight,
            },
          ],
        },
      },
      // TODO: Remove inputs? Deleted from settings/eshop – any other usages?
      // eslint-disable-next-line react/no-unused-state
      inputs: {
        logo: {
          el: null,
          file: null,
          fileName: null,
          filePreview: null,
        },
      },
      // eslint-disable-next-line react/no-unused-state
      table: {
        columns: [],
        actions: {},
        config: {},
        isLoaderVisible: false,
      },
      // eslint-disable-next-line react/no-unused-state
      data: [],
    };

    this.state.checkedItems = data.checkedItems;
  }

  // eslint-disable-next-line camelcase
  async UNSAFE_componentWillMount() {
    const companyPromise = this.company.selectById(null, 'name, subscription { branding }');
    const eshopNamePromise = this.eshop.eshop('name, logo { url }');

    const result = await Promise.all([companyPromise, eshopNamePromise]);

    const companyName = result.find((item) => hasProperty(item.data, 'company')).data.company.name;
    const hasActiveBranding = result.find((item) => hasProperty(item.data, 'company')).data.company?.subscription?.branding;
    const eshopName = result.find((item) => hasProperty(item.data, 'eshop')).data.eshop.name;
    const eshopLogo = result.find((item) => hasProperty(item.data, 'eshop')).data.eshop?.logo?.url;

    const data = {
      company: companyName || `${this.t('settings:Navigation.notFilled')}`,
      eshop: eshopName || `${this.t('settings:Navigation.notFilled')}`,
      eshopLogo: eshopLogo || null,
      checkedItems: [
        'shop_comm_emails',
      ],
    };

    const { rightBlock: oldRightBlock } = this.state;
    const leftBlock = this.prepareLeftBlockData(this.eshopPart, data, hasActiveBranding);
    const rightBlock = produce(oldRightBlock, (draft) => {
      if (hasActiveBranding) {
        draft.main.right.push({
          name: `${this.t('settings:Navigation.Eshop.Subtitle.branding')}`,
          items: this.navigation.brandingRight,
        });
      }
      draft.header.title = eshopName || `${this.t('settings:Navigation.notFilled')}`;
      draft.header.eshopLogo = eshopLogo || null;
    });

    this.setState({
      leftBlock,
      rightBlock,
    });
  }

  prepareLeftBlockData(eshopPart, data, hasActiveBranding) {
    const baseLeftBlock = {
      header: {
        title: data.company,
        subTitle: `${this.t('settings:Navigation.Company.title')}`,
        button: {
          text: `${this.t('settings:Navigation.newCompanyButton')}`,
          path: `${basePaths.settings}/company/new`,
          class: {
            color: 'secondary',
            size: 'xs',
            outline: false,
          },
        },
      },
      main: [
        {
          name: `${this.t('settings:Navigation.Company.subtitle')}`,
          items: this.navigation.generalLeft,
        },
      ],
    };

    switch (eshopPart) {
      case 'general':
        baseLeftBlock.header = updateObject(baseLeftBlock.header, {
          title: data.eshop,
          subTitle: 'Eshop',
        });
        baseLeftBlock.main = [
          {
            name: `${this.t('settings:Navigation.Eshop.Subtitle.general')}`,
            items: this.navigation.generalRight,
          },
          {
            name: `${this.t('settings:Navigation.Eshop.Subtitle.communication')}`,
            items: this.navigation.communicationRight,
          },
        ];

        if (hasActiveBranding) {
          baseLeftBlock.main.push({
            name: `${this.t('settings:Navigation.Eshop.Subtitle.branding')}`,
            items: this.navigation.brandingRight,
          });
        }
        break;
      case 'comm':
      case 'branding':
        baseLeftBlock.header = updateObject(baseLeftBlock.header, {
          title: data.eshop,
          subTitle: 'Eshop',
        });
        baseLeftBlock.main = [
          {
            name: `${this.t('settings:Navigation.Eshop.Subtitle.general')}`,
            items: this.navigation.generalRight,
          },
          {
            name: `${this.t('settings:Navigation.Eshop.Subtitle.communication')}`,
            items: this.navigation.communicationRight,
          },
        ];

        if (hasActiveBranding) {
          baseLeftBlock.main.push({
            name: `${this.t('settings:Navigation.Eshop.Subtitle.branding')}`,
            items: this.navigation.brandingRight,
          });
        }
        break;

      default:
        break;
    }

    return baseLeftBlock;
  }

  render() {
    const { leftBlock, rightBlock, checkedItems, isOpened, isPartLoading, children, modalIsOpen } = this.state;

    return (
      <SettingsLayout
        leftBlock={leftBlock}
        rightBlock={rightBlock}
        checkedItems={checkedItems}
        isOpened={isOpened}
        isPartLoading={this.userLoadingState && isPartLoading}
        modalIsOpen={modalIsOpen}
      >
        {children}
      </SettingsLayout>
    );
  }
}

export default SettingsBase;
