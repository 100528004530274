import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { pick } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { generateReactKey } from '#/shared/utilities';
import TimelineAccordion from './TimelineAccordion/TimelineAccordion';
import NoteModal from './NoteModal/NoteModal';
import { Filter } from '../../types';

const visibleTypeMap: any = {
  trackAndTrace: ['action', 'state', 'delayed'],
  communication: ['email'],
  note: ['note'],
};

function mapFiltersToEventTypes(filters: Filter[]): string[] {
  return filters
    .filter((filter: any) => filter.value === true)
    .reduce((acc: any, filter: any) => [...acc, ...visibleTypeMap[filter.name]], []);
}

type Props = {
  events: any;
  filters: Filter[];
  refetchTimelineEvents: any;
  deliveryId: any;
  notes: any;
}

const TimelineAccordionSection: React.FC<Props> = ({
  events,
  filters,
  refetchTimelineEvents,
  deliveryId,
  notes,
}) => {
  const { t } = useTranslation(['deliveries']);
  const [isShowingAllAccordions, setShowAllAccordions] = useState(false);
  const [editNoteId, setEditNoteId] = useState(null);
  const [groupedEvents, setGroupedEvents] = useState<any>({});
  const maxVisibleAccordions = 10;
  const visibleEventTypes = mapFiltersToEventTypes(filters);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    setGroupedEvents(groupEventsByCategory(events));
  }, [isShowingAllAccordions, events]);

  function toggleShowAllAccordions() {
    setShowAllAccordions(!isShowingAllAccordions);
  }

  function getCategoryFromEvent(event: any) {
    return event.category ? event.category.code : event.category;
  }

  function groupEventsByCategory(timelineEvents: any[]) {
    let prevCategory: any = null;
    let currentGroupPropName: any = null;
    const groups: any = {};

    timelineEvents.forEach((event: any, index) => {
      const currentCategory = getCategoryFromEvent(event);

      if (currentCategory !== prevCategory
        || currentGroupPropName === null
        || currentCategory === null
      ) {
        prevCategory = currentCategory;
        currentGroupPropName = [`${index}_${currentCategory}`];
        groups[currentGroupPropName] = [];
        groups[currentGroupPropName].push(event);
      } else {
        groups[currentGroupPropName].push(event);
      }
    });

    return groups;
  }

  function createAccordionsFromEvents() {
    const groupsSlicedForAccordionLimit = !isShowingAllAccordions ? pick(
      groupedEvents,
      Object.keys(groupedEvents).slice(0, maxVisibleAccordions),
    ) : groupedEvents;

    return Object.keys(groupsSlicedForAccordionLimit)
      .filter((categoryName) => visibleEventTypes.includes(
        groupedEvents[categoryName][0].type,
      ))
      .map((categoryName) => (
        <TimelineAccordion
          notes={notes}
          onEditNoteClick={setEditNoteId}
          key={generateReactKey()}
          events={groupedEvents[categoryName]}
        />
      ));
  }

  function renderAccordions() {
    const accordionComponents = createAccordionsFromEvents();
    const groupedEventsCount = Object.keys(groupedEvents).length;

    const showBgClass = isShowingAllAccordions ? 'hideBg' : 'showBg';

    return (
      <div>
        <div className="accordions">
          {accordionComponents}
        </div>
        {groupedEventsCount > maxVisibleAccordions && (
          <div className={['more-button', showBgClass].join(' ')}>
            <div className="backgroundGradient">
              <Button
                color="secondary"
                onClick={toggleShowAllAccordions}
              >
                <span>
                  <FontAwesomeIcon icon={['fas', (isShowingAllAccordions ? 'arrow-up' : 'arrow-down')]} fixedWidth />
                  {isShowingAllAccordions
                    ? t('deliveries:DeliveryDetail.Timeline.showLess')
                    : t('deliveries:DeliveryDetail.Timeline.showMore', { count: groupedEventsCount - maxVisibleAccordions })}
                </span>
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }

  function getNoteById(id: string | null) {
    return events
      .filter((e: any) => e.type === 'note')
      .find((e: any) => e.relatedObjectId === id);
  }

  return (
    <>
      {renderAccordions()}
      <NoteModal
        id={editNoteId}
        defaultValue={editNoteId && getNoteById(editNoteId).title}
        refetchTimelineEvents={refetchTimelineEvents}
        setEditNoteId={setEditNoteId}
      />
    </>
  );
};

export default TimelineAccordionSection;
