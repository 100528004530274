import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const CSVDropModal = ({ isOpen, centered, onDrop }) => {
  const { t } = useTranslation('deliveries');

  return (
    <>
      <Modal isOpen={isOpen} centered={centered} size="lg" className="modal-drop">
        <ModalBody className="text-center">
          <img src="/assets/img/icons/csv_upload_file.svg" className="img-responsive" alt="" />
          <h4>{t('CsvDropModal.title')}</h4>
          <p>{t('CsvDropModal.description')}</p>
        </ModalBody>
      </Modal>
      <Dropzone onDrop={onDrop}>
        {({ getRootProps }) => (
          <div
            className="dropZone"
            {...getRootProps()}
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              zIndex: 1000000,
              display: isOpen ? 'block' : 'none',
            }}
          />
        )}
      </Dropzone>
    </>
  );
};

CSVDropModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  centered: PropTypes.bool.isRequired,
  onDrop: PropTypes.func.isRequired,
};

export default CSVDropModal;
