import React from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';

const withValidation = (Component) => {
  const WithValidation = (props) => (
    <Field {...props}>
      {({ field, form }) => {
        const { errors, touched } = form;
        const {
          name,
          onChange,
          type,
          options,
          placeholder,
        } = props;
        const { onChange: defaultOnChange } = field;

        return (
          <>
            <Component
              {...field}
              {...props}
              placeholder={placeholder}
              options={options}
              type={type}
              invalid={touched[name] && errors[name] !== undefined}
              valid={touched[name] && errors[name] === undefined}
              onChange={onChange || defaultOnChange}
            />
            { touched[name] && errors[name] && (
              <div className="invalid-feedback" style={{ display: 'block' }}>
                {errors[name]}
              </div>
            )}
          </>
        );
      }}
    </Field>
  );

  WithValidation.defaultProps = {
    type: 'text',
    onChange: null,
    options: null,
    placeholder: null,
  };

  WithValidation.propTypes = {
    ...Component.PropTypes,
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.shape({})),
  };

  return WithValidation;
};

export const InputWithValidation = withValidation(Input);

export default withValidation;
