import gql from 'graphql-tag';

export default class DeliveryFragments {
  static closedDeliveries() {
    return gql`
            fragment closedDeliveries on DeliveriesClosingState {
                success
                messages
                errorDeliveries {
                    id
                }
                failedDeliveries {
                  deliveryId
                  message
                }
            }
        `;
  }

  static openedDeliveries() {
    return gql`
            fragment openedDeliveries on DeliveriesClosingInformation {
                deliveriesCount,
                deliveriesClosingInformationByAgent {
                    deliveriesCount,
                    agent {
                        id
                        name
                    }
                }
            }
        `;
  }

  static deliveryPreview() {
    return gql`
            fragment DeliveryPreview on DeliveryConnection {
                totalCount
                items {
                    id
                    created
                    stateKey
                    canCancel
                    canClose
                    canUpdate
                    canGenerateTicket
                    delayed
                    isMonitored
                    reasonOfDelayCode
                    type {
                        name
                        agentType
                        agent {
                            id
                            logoSlug
                            originalName
                            logo {
                                name
                                url
                            }
                        }
                    }
                    important
                    created
                    deliveryNumber
                    cashOnDelivery
                    cashOnDeliveryCurrency
                    numberOfNotes
                    recipient {
                        contact {
                            name
                            surname
                        }
                    }
                    rating
                }
            }`;
  }

  static createdDelivery() {
    return gql`
            fragment CreatedDelivery on DeliveryCreatingInformation {
                success
                errors
                delivery {
                    id
                }
            }
        `;
  }
}
