import produce from 'immer';

import * as actionTypes from 'StoreActions/actionTypes';

const initialState = {
  paginator: { page: 1 },
  search: { searchKey: '' },
  table: {
    orderBy: 'name',
    numberOfRows: 25,
    orderByDirection: 'DESC',
    totalCount: 0,
  },
};

const storeCurrentPage = (state, action) => {
  state.paginator.page = action.payload;
};

const storeNumberOfRows = (state, action) => {
  state.table.numberOfRows = action.payload;
};

const setSearchKey = (state, action) => {
  state.search.searchKey = action.payload;
};

const setOrderBy = (state, action) => {
  state.table.orderBy = action.payload;
};

const setOrderByDirection = (state, action) => {
  state.table.orderByDirection = action.payload;
};

const setTotalCount = (state, action) => {
  state.table.totalCount = action.payload;
};

const reducer = (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case actionTypes.CONTACTS_PAGE_CHANGED:
      return storeCurrentPage(draft, action);
    case actionTypes.NUMBER_OF_ROWS_CHANGED:
      return storeNumberOfRows(draft, action);
    case actionTypes.SEARCH_KEY_CHANGED:
      return setSearchKey(draft, action);
    case actionTypes.ORDERBY_CHANGED:
      return setOrderBy(draft, action);
    case actionTypes.ORDERBY_DIRECTION_CHANGED:
      return setOrderByDirection(draft, action);
    case actionTypes.CONTACT_TOTAL_COUNT_CHANGED:
      return setTotalCount(draft, action);
    default:
      return draft;
  }
});

export default reducer;
