import React from 'react';
import { DropdownItem } from 'reactstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

const DuplicateDelivery = ({ onDuplicateClick }) => {
  const { t } = useTranslation('deliveries');

  return (
    <DropdownItem
      onClick={onDuplicateClick}
    >
      <FontAwesomeIcon icon={['fas', 'clone']} fixedWidth />
      {t('DeliveryDetail.Menu.duplicateDelivery')}
    </DropdownItem>
  );
};

DuplicateDelivery.defaultProps = { onDuplicateClick: () => null };
DuplicateDelivery.propTypes = { onDuplicateClick: PropTypes.func };

export default DuplicateDelivery;
