import React from 'react';
import { Card, CardHeader, CardBody, Progress } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { formatCurrency, rx } from '~/services/utils';
import { Cashflow, Currency } from '~/types/generated/graphql';

type Props = {
  currency: Currency,
  cashflow: Cashflow[],
  css: any,
};

const Cashflow: React.FC<Props> = ({
  currency,
  cashflow = [],
  css,
}) => {
  const { t } = useTranslation('dashboard');

  const { total, prepaid, cod } = cashflow.find((x: Cashflow) => x?.currency === currency) || {
    total: null,
    prepaid: null,
    cod: null,
  };

  return (
    <Card className={css.card}>
      <CardHeader>
        {t('CashflowCard.title')}
        {' '}
        /
        {' '}
        <span className="text-grey">
          {currency}
        </span>
      </CardHeader>

      <CardBody className="pt-0">
        <div className={css.number}>{formatCurrency(total, { currency, style: 'decimal' })}</div>

        <div className={css.progress}>
          <Progress
            value={rx(prepaid, total)}
            color="info"
          />
        </div>

        <div className="d-flex">
          <div className={css.cell}>
            <div className="text-grey font-weight-bold">{formatCurrency(prepaid, { currency, style: 'decimal' })}</div>
            <div>
              {t('CashflowCard.paid')}
            </div>
          </div>

          <div className={css.cell}>
            <div className="text-grey font-weight-bold">{formatCurrency(cod, { currency, style: 'decimal' })}</div>
            <div>
              {t('CashflowCard.cashOnDelivery')}
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default Cashflow;
