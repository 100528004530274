import { TFunction } from 'i18next';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CountryManagerContext } from '../../../CountryManager/CountryManager';
import CustomSelect, { Props as CustomSelectProps } from '../CustomSelect/CustomSelect';

const RECENT_COUNTRIES = ['CZ', 'SK', 'PL', 'DE', 'AT'];

type Options = CustomSelectProps['options'];

// TODO: import OptionType
export type Option = {
  label: string;
  value: string;
}

interface Country {
  id: string;
  nameCs: string;
  nameEn: string;
  code: string;
  code3: string;
  currency: string;
  currencyName: string;
  inEu: boolean;
  phonePrefix: string;
  postalCodePattern: string;
  postalCodeExample: string;
}

function formatCountries(
  countries: Country[],
  selectAll: boolean,
  t: TFunction,
  language = 'cs',
): Options {
  // const { defaultLabel } = this.props;
  const optionsRecent: Option[] = [];
  const optionsOthers: Option[] = [];

  countries.forEach((item) => {
    const label = language === 'cs' ? item.nameCs : item.nameEn;

    const option = {
      value: item.code,
      label: label ?? item.nameCs,
    };

    if (RECENT_COUNTRIES.includes(item.code)) {
      optionsRecent.push(option);
    } else {
      optionsOthers.push(option);
    }
  });

  if (selectAll) {
    optionsRecent.unshift({
      value: '',
      label: `${t('validation:CountrySelect.placeholder')}`,
    });
  }

  const options: Options = [
    {
      label: `${t('validation:CountrySelect.optionsRecentLabel')}`,
      options: optionsRecent as any, // TODO
    },
    {
      label: `${t('validation:CountrySelect.optionsOthersLabel')}`,
      options: optionsOthers as any, // TODO
    },
  ];

  return options;
}

export type Props = CustomSelectProps & {
  selectAll?: boolean;
  countryCodesWhitelist?: string[];
  countryCodesBlacklist?: string[];
};

const CountrySelect: React.FC<Props> = ({
  selectAll = false,
  placeholder,
  countryCodesWhitelist,
  countryCodesBlacklist,
  ...props
}) => {
  const { t, i18n } = useTranslation('validation');
  const countryManager: any = useContext(CountryManagerContext);

  /**
   * List of all available countries, can be filtered using the countryCodesWhitelist.
   * countryCodesWhitelist = ['CZ', 'SK', ...] will provide options for Czechia, Slovakia, ...
   * countryCodesBlacklist = ['CZ', 'SK', ...] will provide options except for Czechia, Slovakia, ...
   */
  const countries = useMemo(() => {
    if (countryCodesWhitelist?.length) {
      return countryManager.countries.filter((country: Country) => countryCodesWhitelist.includes(country.code));
    }

    if (countryCodesBlacklist?.length) {
      return countryManager.countries.filter((country: Country) => !countryCodesBlacklist.includes(country.code));
    }

    return countryManager.countries;
  }, [countryCodesWhitelist, countryCodesBlacklist, countryManager.countries]);

  return (
    <CustomSelect
      {...props}
      options={formatCountries(countries, selectAll, t, i18n.language)}
      // classNamePrefix="select"
      placeholder={placeholder || t('CountrySelect.placeholder')}
    />
  );
};

export default CountrySelect;
