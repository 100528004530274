import gql from 'graphql-tag';

import BaseDataSource from '../BaseDataSource';
import AuthenticationHelper from '~/services/Auth';

export default class DeliveryDataSource extends BaseDataSource {
  constructor() {
    super();

    this.defaultAgentFields = `
      id
      name
      fullName
      logo {
        url
      }
      extraServices(companyOnly: true) {
        code
        name
        description
        selectedByDefault
        arguments {
          type
          name
          exampleValue
        }
      }
      collectionPlaces {
        id
        name
        contact { email phone }
      }
      deliveryTypes(onlyActive: true) {
        id
        name
        pickupPlaceType {
          id
          name
        }
      }
    `;

    this.defaultCancelDeliveryFields = `
      id
      status
      type {
        agentType,
        agent {
          id
          logo {
            name
            url
          }
        }
      }
      created
      deliveryNumber
      cashOnDelivery
      cashOnDeliveryCurrency
      recipient {
        contact {
          name
          surname
        }
      }
    `;
  }

  // TODO: move to base class or a generic utility
  async query(x, variables = {}) {
    const [dataKey, query] = Object.entries(x)[0];

    return this.client.query({
      query,
      variables,
      context: { headers: { AccessToken: AuthenticationHelper.accessToken } },
    })
      .then((result) => ({ data: result.data[dataKey] }));
  }

  async agents(fields = this.defaultAgentFields) {
    const query = gql`
      query agents {
        agents {
          ${fields}
        }
      }
    `;

    return this.client.query({
      query,
      context: { headers: { AccessToken: AuthenticationHelper.accessToken } },
    });
  }

  deliveryCsvImportSettings() {
    const query = gql`
      {
        deliveryCsvImportSettings {
          id
          name
          documentSeparator
          hasHeader
          isMonitored
          firstnameColumnNumber
          surnameColumnNumber
          contactPersonColumnNumber
          emailColumnNumber
          phoneColumnNumber
          cityColumnNumber
          streetColumnNumber
          streetNumberColumnNumber
          postalCodeColumnNumber
          countryColumnNumber
          valueColumnNumber
          valueCurrencyColumnNumber
          weightColumnNumber
          lengthColumnNumber
          widthColumnNumber
          heightColumnNumber
          ticketNoteColumnNumber
          codColumnNumber
          codCurrencyColumnNumber
          packagesCountColumnNumber
          variableSymbolColumnNumber
          deliveryTypeColumnNumber
          agentColumnNumber
          extraServicesColumnNumber
          collectionPlaceColumnNumber
          externalIdColumnNumber
          pickUpPlaceIdColumnNumber
          referenceIdColumnNumber
          trackingNumberColumnNumber
          defaultCountry
          defaultValueCurrency
          defaultCodCurrency
          defaultExtraServices
          defaultAgent
          defaultDeliveryType
          defaultCollectionPlace {
            id
            name
            identificator
            active
          }
        }
      }
    `;

    return this.client.query({
      query,
      fetchPolicy: 'no-cache',
      context: { headers: { AccessToken: AuthenticationHelper.accessToken } },
    });
  }

  createDeliveryCsvImport(deliveryCsvImport) {
    const mutation = gql`
      mutation createDeliveryCsvImport($deliveryCsvImport: InputDeliveryCsvImportCreate!) {
        createDeliveryCsvImport(deliveryCsvImport: $deliveryCsvImport) {
          success
          errors {
            fileErrors
            dataErrors {
              row
              column
              messages
            }
          }
          createdDeliveriesNumber
        }
      }
    `;

    const variables = { deliveryCsvImport };

    return this.client.mutate({
      mutation,
      variables,
      context: { headers: { AccessToken: AuthenticationHelper.accessToken } },
    });
  }

  extraServiceTypes() {
    const query = gql`
      query {
        extraServiceTypes(onlyExplicit: true) {
          id
          name
          code
        }
      }
    `;

    return this.client.query({
      query,
      context: { headers: { AccessToken: AuthenticationHelper.accessToken } },
    });
  }

  getDeliveriesTickets(deliveriesIds, mediumType, startPosition, rotate) {
    const query = gql`
      query getDeliveriesTickets( $deliveriesIds: [ID]!
        $mediumType: EnumDeliveryTicketMediumType!
        $startPosition: EnumDeliveryTicketStartPosition!
        $rotate: Boolean!) {
        getDeliveriesTickets ( deliveriesIds: $deliveriesIds
          mediumType: $mediumType
          startPosition: $startPosition
          rotate: $rotate) {
          name
          rawSource
        }
      }
    `;

    const variables = {
      deliveriesIds,
      mediumType,
      startPosition,
      rotate,
    };

    return this.client.query({
      query,
      variables,
      context: { headers: { AccessToken: AuthenticationHelper.accessToken } },
    });
  }

  deliveriesClosingInformation(fields) {
    const query = gql`
      query {
        deliveriesClosingInformation {
          ${fields}
        }
      }
    `;

    return this.client.query({
      query,
      fetchPolicy: 'no-cache',
      context: { headers: { AccessToken: AuthenticationHelper.accessToken } },
    });
  }

  closeDeliveries(agentId, fields) {
    const mutation = gql`
      mutation closeDeliveries($agentId: ID) {
        closeDeliveries(agentId: $agentId) {
          ${fields}
        }
      }
    `;

    const variables = { agentId };

    return this.client.mutate({
      mutation,
      variables,
      context: { headers: { AccessToken: AuthenticationHelper.accessToken } },
    });
  }

  cancelDeliveries(ids, fields = this.defaultCancelDeliveryFields) {
    const mutation = gql`
      mutation cancelDeliveries($deliveriesIds: [ID]!) {
        cancelDeliveries(deliveriesIds: $deliveriesIds) {
          ${fields}
        }
      }
    `;

    const variables = { deliveriesIds: ids };

    return this.client.mutate({
      mutation,
      variables,
      context: { headers: { AccessToken: AuthenticationHelper.accessToken } },
    });
  }

  createDelivery(delivery, fields) {
    const mutation = gql`
      mutation createDelivery($delivery: InputDeliveryCreate!) {
        createDelivery(delivery: $delivery) {
          ${fields}
        }
      }
    `;

    const variables = { delivery };

    return this.client.mutate({
      mutation,
      variables,
      context: { headers: { AccessToken: AuthenticationHelper.accessToken } },
    });
  }

  updateDelivery(delivery, fields) {
    const mutation = gql`
      mutation updateDelivery($delivery: InputDeliveryUpdate!) {
        updateDelivery(delivery: $delivery) {
          ${fields}
        }
      }
    `;

    const variables = { delivery };

    return this.client.mutate({
      mutation,
      variables,
      context: { headers: { AccessToken: AuthenticationHelper.accessToken } },
    });
  }
}
