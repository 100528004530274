import React from 'react';
import { Button, Spinner, ButtonProps } from 'reactstrap';

import css from './SubmitButton.module.scss';

type Props = ButtonProps & {
  isLoading?: boolean;
};

const SubmitButton: React.FC<Props> = ({
  disabled,
  isLoading,
  onClick,
  children,
  ...props
}) => (
  <Button
    {...props}
    onClick={onClick}
    disabled={disabled || isLoading}
  >
    {isLoading && <Spinner size="sm" className={css.spinner} />}
    {' '}
    {children}
  </Button>
);

export default SubmitButton;
