import { useReducer } from 'react';
import { produce } from 'immer';
import { generate } from 'shortid';

interface Action {
  type: string;
  index?: number;
}

function reducer(keys: string[], action: Action): string[] {
  switch (action.type) {
  case 'push':
    return produce(keys, (draft) => {
      draft.push(generate());
    });

  case 'remove':
    return typeof action.index !== 'undefined'
      ? produce(keys, (draft) => {
        draft.splice(action.index!, 1); // TODO: handle optional index properly
      })
      : keys;

  default:
    throw new Error();
  }
}

type pushKeyFn = () => void;
type removeKeyFn = (index: number) => void;

export function useUniqueKeys(initialKeysCount = 1): [string[], pushKeyFn, removeKeyFn] {
  const initialKeys = [];

  for (let i = 1; i <= initialKeysCount; i += 1) {
    initialKeys.push(generate());
  }

  const [keys, dispatch] = useReducer(reducer, initialKeys);

  const pushKey: pushKeyFn = () => {
    dispatch({ type: 'push' });
  };

  const removeKey: removeKeyFn = (index) => {
    dispatch({ type: 'remove', index });
  };

  return [keys, pushKey, removeKey];
}

export default useUniqueKeys;
