import React from 'react';
import {
  Card,
  CardBody,
  Spinner,
} from 'reactstrap';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import configurator from '~/services/Configurator';
import useAuthQuery from '~/hooks/useAuthQuery';

import eshopTrackAndTraceQuery from './eshopTrackAndTrace.gql';
import css from './TrackInfo.module.scss';

interface EshopTrackAndTraceData {
  eshop: {
    slug: string;
  }
}

interface PartialDelivery {
  id: string;
  deliveryNumber: Nullable<string>;
  agentTrackingUrl: Nullable<string>;
  accessSignature: string;
  stateCategory: string;
  type: {
    name: string;
    agent: {
      id: string;
      name: string;
      originalName: string;
      logo: {
        name?: string;
        url: string;
      };
    };
  };
  currentAgent: {
    id: string;
    logoSlug?: Nullable<string>;
    originalName: string;
    abbr: string;
    logo: {
      name?: string;
      url: string;
    };
  }
}

function formatTrackAndTraceUrl(
  hasBrandingFeature: boolean,
  eshopTrackAndTrace: EshopTrackAndTraceData['eshop'] | undefined,
  delivery: PartialDelivery,
): Nullable<string> {
  const { id, accessSignature, stateCategory } = delivery;

  if (!hasBrandingFeature || !eshopTrackAndTrace?.slug || parseInt(stateCategory, 10) < 2) {
    return null;
  }

  const { config } = configurator;

  if (config.ttUrl) {
    const { slug } = eshopTrackAndTrace;
    return slug ? `${config.ttUrl}/status/${slug}/${id}?sig=${accessSignature}` : '';
  }

  return '';
}

type Props = {
  delivery: PartialDelivery;
  user: any;
}

const TrackInfo: React.FC<Props> = ({ delivery, user }) => {
  const { t } = useTranslation('deliveries');
  const hasBrandingFeature = user?.hasActiveBranding ?? false;

  // TODO: fetch eshop settings up in the component chain and pull from a global store
  const { loading, data: eshopData } = useAuthQuery<EshopTrackAndTraceData>(eshopTrackAndTraceQuery, undefined, {
    skip: !hasBrandingFeature,
  });

  if (loading) {
    return (<Spinner size="lg" />);
  }

  const ttUrl = formatTrackAndTraceUrl(hasBrandingFeature, eshopData?.eshop, delivery);

  const disableClick = (ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    ev.preventDefault();
  };

  return (
    <Card className="mnh-100">
      <CardBody>
        <div
          className="d-flex flex-row justify-content-between align-items-start mb-3"
        >
          <p className="text-dark font-weight-bold mb-0">
            {delivery.currentAgent.originalName}

            {delivery.currentAgent.id === delivery.type.agent.id && (
              <span className="text-secondary small d-block">
                {delivery.type.name}
              </span>
            )}
          </p>

          <img
            alt={delivery.currentAgent.originalName ?? 'Agent logo'}
            src={delivery.currentAgent.logo.url}
            height={35}
          />
        </div>

        <div className={`${css.buttons} w-100 d-flex mt-3`}>
          <div className="w-100">
            {hasBrandingFeature && (
              <a href={ttUrl ?? '#'}
                target="_blank"
                className={`btn btn-secondary d-block ${!ttUrl && 'disabled'}`}
                rel="noopener noreferrer"
                onClick={!ttUrl ? disableClick : undefined}
              >
                {t('DeliveryDetail.TrackInfo.TTButton')}
              </a>
            )}

            <a
              href={delivery.agentTrackingUrl ?? ''}
              target="_blank"
              className={`btn btn-secondary d-block ${!delivery.agentTrackingUrl && 'disabled'}`}
              rel="noopener noreferrer"
              onClick={!delivery.agentTrackingUrl ? disableClick : undefined}
            >
              {hasBrandingFeature ? t('DeliveryDetail.TrackInfo.TTAgentButton') : t('DeliveryDetail.TrackInfo.TTButton')}
            </a>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default connect((state: any) => ({ user: state.userReducer.data }))(TrackInfo);
