import * as Yup from 'yup';

/**
 * @param {TFunction} t
 */
const createValidationSchema = (createCollectionPlace = true, t) => Yup.object().shape({
  collectionPlaceName: Yup.string()
    .required(t('settings:CollectionPlaces.Info.PlaceName.required')),
  companyNameForTickets: Yup.string()
    .required(t('settings:CollectionPlaces.Info.CompanyName.required')),
  identificator: Yup.string()
    .required(t('settings:CollectionPlaces.Info.Identificator.required'))
    .matches(/^[a-zA-Z0-9-]+$/, t('settings:CollectionPlaces.Info.Identificator.pattern')),
  contactName: Yup.string()
    .required(t('validation:Name.required')),
  contactSurname: Yup.string()
    .required(t('validation:Surname.required')),
  contactEmail: Yup.string()
    .required(t('validation:Email.required'))
    .email(t('validation:Email.pattern')),
  contactPhone: Yup.string()
    .required(t('validation:Phone.required'))
    .matches(/^(?:\+\d{2})?\d{10}(?:,(?:\+\d{2})?\d{10})*$/, { message: t('validation:Phone.pattern') }),
  street: createCollectionPlace ? Yup.string()
    .required(t('validation:Street.required')) : null,
  city: createCollectionPlace ? Yup.string()
    .required(t('validation:City.required')) : null,
  country: createCollectionPlace ? Yup.string()
    .required(t('validation:CountrySelect.required')) : null,
  zip: createCollectionPlace ? Yup.string()
    .required(t('validation:ZipCode.required'))
    .matches(/^\d{5}(?:[-\s]\d{4})?$/, { message: t('validation:ZipCode.pattern') }) : null,
});

export default createValidationSchema;
