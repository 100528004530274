import React from 'react';
import { useTranslation } from 'react-i18next';

import { generateReactKey } from 'Shared/utilities';
import TimelineIconAccordion from '../_TimelineIconAccordion/TimelineIconAccordion';

const DelayedAccordion = ({ events }) => {
  const { t } = useTranslation(['status']);

  return (
    <TimelineIconAccordion
      theme="dark"
      icon={{
        type: 'fw',
        icon: ['far', 'circle'],
      }}
      className="trackAndTrace"
      title={(
        <h4>
          {t(`status:${events[0].category.code}`, events[0].category.title)}
        </h4>
      )}
    >
      <h4>
        {events[0].category.on}
      </h4>
      {events.map((event) => (
        <div key={generateReactKey()} className="event">
          <h5>{event.title}</h5>
          <div className="subtitle">
            <span>{event.on}</span>
          </div>
        </div>
      ))}
    </TimelineIconAccordion>
  );
};

export default DelayedAccordion;
