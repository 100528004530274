import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Col,
  Container,
  Row,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import DataTable from 'Containers/DataTable/DataTable';
import withAllAgents from '../../HoC/withAllAgents/withAllAgents';
import withUnusedAgents from '../../HoC/withAgentsCredentials/withUnusedAgents';
import withCredentials from '../../HoC/withCredentials/withCredentials';

const AgentsUI = ({ table, data, unusedAgents }) => {
  const { t } = useTranslation('settings');

  return (
    <Container className="agents">
      <div className="pb-5">
        <Row className="align-items-center right-col-spacing">
          <Col md={7}>
            <h1>{t('settings:Agents.title')}</h1>
          </Col>
          <Col md={5} className="text-right">
            <Link to="/settings/agents/new">
              <Button
                color="secondary"
                size="sm"
                className="align-middle"
                disabled={unusedAgents.length === 0}
              >
                <FontAwesomeIcon icon={['fas', 'plus']} fixedWidth />
                {t('settings:Agents.newAgentButton')}
              </Button>
            </Link>
          </Col>
        </Row>
        <DataTable
          data={data}
          columns={table.columns}
          actions={table.actions}
          config={table.config}
          isLoaderVisible={false}
        />
      </div>
    </Container>
  );
};

AgentsUI.propTypes = {
  table: PropTypes.shape({}).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  unusedAgents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default withCredentials(withAllAgents(withUnusedAgents(AgentsUI)));
