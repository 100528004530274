import React from 'react';
import * as PropTypes from 'prop-types';

import { generateReactKey } from 'Shared/utilities';
import TechnologyNumberAccordionUI
  from '../../Presentational/TechnologyNumberAccordionUI/TechnologyNumberAccordionUI';
import TechnologyNumberModalUI
  from '../../Presentational/TechnologyNumberModalUI/TechnologyNumberModalUI';

export default class TechnologyNumberAccordion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalVisible: false,
      hasAddedTechnologyNumber: false,
    };
  }

    handleAddTechnologyNumber = (e) => {
      e.stopPropagation();

      this.toggleModal();
    };

    toggleModal = () => {
      const { isModalVisible } = this.state;

      this.setState({ isModalVisible: !isModalVisible });
    };

    handleSubmit = async (values, formik) => {
      const { technologyNumber, technologyType } = values;
      const { mergeAgentFormValues, technologyNumbers } = this.props;
      const { resetForm } = formik;

      const newTechnologyNumbers = technologyNumbers
        // eslint-disable-next-line no-return-assign,no-param-reassign
        .map((number) => {
          const deactivatedNumber = number;

          deactivatedNumber.active = false;
          return deactivatedNumber;
        });
      newTechnologyNumbers.unshift({
        id: generateReactKey(),
        number: technologyNumber,
        type: technologyType,
        active: true,
        date: new Date(),
      });

      mergeAgentFormValues({ technologyNumbers: newTechnologyNumbers });
      this.toggleModal();
      resetForm();
      this.setState({ hasAddedTechnologyNumber: true });
    };

    render() {
      const { isModalVisible, hasAddedTechnologyNumber } = this.state;
      const { technologyNumbers, active } = this.props;

      const content = (
        <>
          <hr className="my-4" />
          <TechnologyNumberAccordionUI
            technologyNumbers={technologyNumbers}
            onAddTechnologyNumber={this.handleAddTechnologyNumber}
            disableAddBtn={hasAddedTechnologyNumber}
          />
          <TechnologyNumberModalUI
            toggleModal={this.toggleModal}
            onSubmit={this.handleSubmit}
            isModalVisible={isModalVisible}
          />
        </>
      );

      return active ? content : null;
    }
}

TechnologyNumberAccordion.propTypes = {
  active: PropTypes.bool.isRequired,
  mergeAgentFormValues: PropTypes.func.isRequired,
  technologyNumbers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      number: PropTypes.string,
      type: PropTypes.string,
      active: PropTypes.bool,
      __typename: PropTypes.string,
    }),
  ).isRequired,
};
