import EMAIL_ACTIVATE from 'Containers/Settings/Branding/Mailing/queries/activateEmail.gql';
import { FormikHelpers } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { filterObjectEmptyProperties } from '../helpers/filterObjectEmptyProperties';
import FoxLoader from '~/components/FoxLoader/FoxLoader';
import { uploadImage } from '~/helpers/imageHelper';
import {
  CreateEshopEmailConfigResponse, EmailConfigValues,
  emailConfigValuesDefaults, MutationEmailActivate, MutationEshopEmailConfigInput, MutationEshopEmailConfigResponse,
} from './typesAndDefaults';
import { toast } from '#/shared/utilities';
import useAuthMutation from '~/hooks/useAuthMutation';
import MailingForm from './MailingForm/MailingForm';

import CREATE_EMAIL_CONFIG from './queries/createEmail.gql';
import UPDATE_EMAIL_CONFIG from './queries/updateEmail.gql';

const NewMailing: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation(['settings']);
  const [formIsSubmitting, setFormIsSubmitting] = React.useState(false);

  const [createEmailConfigMutation] = useAuthMutation<CreateEshopEmailConfigResponse, MutationEshopEmailConfigInput>(CREATE_EMAIL_CONFIG);
  const [updateEmailConfigMutation] = useAuthMutation<MutationEshopEmailConfigResponse, MutationEshopEmailConfigInput>(UPDATE_EMAIL_CONFIG);
  const [activateEmailConfigMutation] = useAuthMutation<MutationEmailActivate, { id: string }>(EMAIL_ACTIVATE);

  const submitNewMailingConfig = async (
    emailConfigFormValues: EmailConfigValues,
    formikHelpers: FormikHelpers<EmailConfigValues>,
    onSubmitCallback: () => void,
  ) => {
    setFormIsSubmitting(true);
    try {
      const emailConfigCreationResponse = await createEmailConfigMutation({
        variables: {
          config: {
            title: emailConfigFormValues.title,
            sender: emailConfigFormValues.sender,
            senderEmail: emailConfigFormValues.senderEmail,
            primaryColor: emailConfigFormValues.primaryColor,
            linkColor: emailConfigFormValues.linkColor,
            headerColor: emailConfigFormValues.headerColor,
            headerBackgroundColor: emailConfigFormValues.headerBackgroundColor,
            ...filterObjectEmptyProperties({
              buttonColor: emailConfigFormValues.buttonColor,
              buttonBackgroundColor: emailConfigFormValues.buttonBackgroundColor,
            }),
          },
        },
      });

      if (emailConfigCreationResponse.data?.createEmailConfig?.id) {
        const [logoUploadData] = await Promise.all([uploadImage(emailConfigFormValues.logoImg)]);

        await updateEmailConfigMutation({ variables: {
          config: {
            title: emailConfigFormValues.title,
            sender: emailConfigFormValues.sender,
            senderEmail: emailConfigFormValues.senderEmail,
            primaryColor: emailConfigFormValues.primaryColor,
            linkColor: emailConfigFormValues.linkColor,
            headerColor: emailConfigFormValues.headerColor,
            headerBackgroundColor: emailConfigFormValues.headerBackgroundColor,
            buttonColor: emailConfigFormValues.buttonColor,
            buttonBackgroundColor: emailConfigFormValues.buttonBackgroundColor,
            ...filterObjectEmptyProperties({ logoId: logoUploadData?.data.imageId }),
          },
          id: emailConfigCreationResponse.data?.createEmailConfig?.id,
        } });

        if (emailConfigFormValues.activateAfterSave) {
          formikHelpers.setFieldValue('activateAfterSave', false);
          await activateEmailConfigMutation({ variables: { id: emailConfigCreationResponse.data?.createEmailConfig?.id } });
          toast.success(t('settings:Branding.Mailing.Message.templateCreatedAndActivated'));
        } else {
          toast.success(t('settings:Branding.Mailing.Message.templateCreated'));
        }
      }

      formikHelpers.setSubmitting(false);
      setFormIsSubmitting(false);
      history.push('/settings/branding/mailing');
    } catch (e) {
      // Handled in global error interceptor
      formikHelpers.setSubmitting(false);
      setFormIsSubmitting(false);
    }

    onSubmitCallback();
  };

  return (
    <>
      <h1 className="mb-5">{t('settings:Branding.Mailing.newMailingTitle')}</h1>
      <MailingForm
        onSubmit={(values, formikHelpers, onSubmitCallback) => submitNewMailingConfig(values, formikHelpers, onSubmitCallback)}
        initialValues={emailConfigValuesDefaults}
      />
      { formIsSubmitting && <FoxLoader loaderText={t('settings:Branding.Mailing.mailingFormInsertingText')} /> }
    </>
  );
};

export default NewMailing;
