import React from 'react';
import { withTranslation } from 'react-i18next';
import { Col, Button, Row, Container } from 'reactstrap';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SettingsBase from 'Containers/Settings/_base/SettingsBase';
import SettingsLayout from 'Components/Settings/SettingsLayout';
import DataTable from 'Components/Presentational/DataTable/DataTable';
import { updateObject } from 'Shared/utilities';
import Modal from 'Containers/ConfirmModal/ConfirmModal';
import PackageDataSource from '../../../../core/models/packages/PackageDataSource';

class Packages extends SettingsBase {
  static formatDataForRender(packages) {
    return packages.map((item) => {
      const currentItem = {};
      currentItem.id = item.id;
      currentItem.name = item.name;
      currentItem.length = item.length;
      currentItem.width = item.width;
      currentItem.height = item.height;
      return currentItem;
    });
  }

  constructor(props) {
    super(props, 'general');
    this.model = new PackageDataSource();
    this.actionDelete = this.actionDelete.bind(this);
    this.showDeleteModal = this.showDeleteModal.bind(this);
    this.hideDeleteModal = this.hideDeleteModal.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
  }

  async componentDidMount() {
    await this.fetchData();
  }

  async actionDelete() {
    const { t } = this.props;

    await this.model.deletePackageTemplate(this.idToDelete);
    await this.fetchData(false);
    this.hideDeleteModal();
    toast.success(t('settings:Packages.Banner.deletePackageSuccess'));
  }

  hideDeleteModal() {
    this.setState({ modalIsOpen: false });
    this.idToDelete = null;
  }

  showDeleteModal(id) {
    this.setState({ modalIsOpen: true });
    this.idToDelete = id;
  }

  toggleDeleteModal() {
    const { modal } = this.state;
    this.setState({ modalIsOpen: !modal });
  }

  async fetchData(cache = true) {
    const { t } = this.props;
    let packages = await this.model.packageTemplates(cache);
    packages = packages.data.packageTemplates;

    const state = updateObject(this.state, {
      isOpened: true,
      isPartLoading: false,
      modalIsOpen: false,
      data: Packages.formatDataForRender(packages),
      table: {
        columns: [
          {
            title: 'Název',
            index: 'name',
            key: '1',
          },
          {
            title: 'Délka',
            index: 'length',
            key: '2',
          },
          {
            title: 'Šířka',
            index: 'width',
            key: '3',
          },
          {
            title: 'Výška',
            index: 'height',
            key: '4',
          },
          {
            title: 'Akce',
            index: 'actions',
            key: '5',
          },
        ],
        actions: {
          custom: {
            edit: {
              type: 'link',
              ref: '/settings/packages/:id/edit',
              icon: { class: ['far', 'edit'] },
              text: `${t('settings:Packages.editPackageTooltip')}`,
            },
            delete: {
              type: 'function',
              func: this.showDeleteModal,
              params: ':id',
              icon: { class: ['fas', 'times'] },
              text: `${t('settings:Packages.removePackageTooltip')}`,
            },
          },
          base: {
            onSelect: this.onSelect,
            onSelectAll: this.onSelectAll,
            onSortBy: this.onSortBy,
            onSearch: this.onSearch,
          },
        },
        config: {
          striped: false,
          borderless: false,
          className: 'basic packages',
        },
        isLoaderVisible: false,
      },
    });

    this.setState(state);
  }

  render() {
    const { t } = this.props;
    let content = null;

    if (this.state.table !== undefined) {
      content = (
        <Container>
          <Row className="align-items-center right-col-spacing">
            <Col md={7}>
              <h1>{t('settings:Packages.title')}</h1>
            </Col>
            <Col md={5} className="text-right">
              <Link to="/settings/packages/new">
                <Button
                  color="primary"
                  size="sm"
                  className="align-middle"
                >
                  <FontAwesomeIcon icon={['fas', 'plus']} fixedWidth />
                  {t('settings:Packages.addPackageSizeButton')}
                </Button>
              </Link>
            </Col>
          </Row>
          <DataTable
            data={this.state.data}
            columns={this.state.table.columns}
            actions={this.state.table.actions}
            config={this.state.table.config}
            isLoaderVisible={this.state.table.isLoaderVisible}
          />
        </Container>
      );
    }

    return (
      <SettingsLayout
        goBack="/settings"
        leftBlock={this.state.leftBlock}
        rightBlock={this.state.rightBlock}
        checkedItems={this.state.checkedItems}
        isOpened={this.state.isOpened}
        isPartLoading={this.state.isPartLoading}
      >
        {content}
        <Modal
          onCancel={this.hideDeleteModal}
          onPrompt={this.actionDelete}
          toggle={this.toggleDeleteModal}
          isOpen={this.state.modalIsOpen || false}
        />
      </SettingsLayout>
    );
  }
}

export default withTranslation(['settings'])(Packages);
