import { TFunction } from 'i18next';
import { object, string } from 'yup';

export const schema = (t: TFunction) => {
  return object().shape({
    locationName: string()
      .required(t('settings:Agents.CreateCollectionPlace.LocationName.required'))
      .max(128, t('settings:Agents.CreateCollectionPlace.LocationName.maxLength', { count: 128 })),
    locationNameAddOn: string()
      .max(128, t('settings:Agents.CreateCollectionPlace.LocationNameAddOn.maxLength', { count: 128 })),
    senderAddressCompanyName: string()
      .required(t('validation:CompanyName.required'))
      .max(50, t('validation:CompanyName.maxLength', { count: 50 })),
    senderAddressStreet: string()
      .required(t('settings:Agents.CreateCollectionPlace.Sender.Street.required'))
      .max(40, t('settings:Agents.CreateCollectionPlace.Sender.Street.maxLength', { count: 40 })),
    senderAddressHouseNumber: string()
      .matches(/^\d+$/, {
        excludeEmptyString: true,
        message: t('settings:Agents.CreateCollectionPlace.Sender.HouseNumber.pattern'),
      })
      .required(t('settings:Agents.CreateCollectionPlace.Sender.HouseNumber.required'))
      .max(6, t('settings:Agents.CreateCollectionPlace.Sender.HouseNumber.maxLength', { count: 6 })),
    senderAddressSequenceNumber: string()
      .max(6, t('settings:Agents.CreateCollectionPlace.Sender.SequenceNumber.maxLength', { count: 6 })),
    senderAddressPartCity: string()
      .max(40, t('settings:Agents.CreateCollectionPlace.Sender.District.maxLength', { count: 40 })),
    senderAddressCity: string()
      .required(t('validation:City.required'))
      .max(40, t('validation:City.maxLength', { count: 40 })),
    senderAddressZipCode: string()
      .required(t('validation:ZipCode.required')),
    senderAddressCountryId: string()
      .required(t('validation:CountrySelect.required')),
    locationPickupPostalCode: string()
      .required(t('validation:ZipCode.required')),
    codAddressCompanyName: string()
      .required(t('validation:CompanyName.required'))
      .max(50, t('validation:CompanyName.maxLength', { count: 50 })),
    codAddressStreet: string()
      .required(t('settings:Agents.CreateCollectionPlace.Sender.Street.required'))
      .max(40, t('settings:Agents.CreateCollectionPlace.Sender.Street.maxLength', { count: 40 })),
    codAddressHouseNumber: string()
      .matches(/^\d+$/, {
        excludeEmptyString: true,
        message: t('settings:Agents.CreateCollectionPlace.Sender.HouseNumber.pattern'),
      })
      .required(t('settings:Agents.CreateCollectionPlace.Sender.HouseNumber.required'))
      .max(6, t('settings:Agents.CreateCollectionPlace.Sender.HouseNumber.maxLength', { count: 6 })),
    codAddressSequenceNumber: string()
      .max(6, t('settings:Agents.CreateCollectionPlace.Sender.SequenceNumber.maxLength', { count: 6 })),
    codAddressPartCity: string()
      .max(40, t('settings:Agents.CreateCollectionPlace.Sender.District.maxLength', { count: 40 })),
    codAddressCity: string()
      .required(t('validation:City.required'))
      .max(40, t('validation:City.maxLength', { count: 40 })),
    codAddressZipCode: string()
      .required(t('validation:ZipCode.required')),
    codAddressCountryId: string()
      .required(t('validation:CountrySelect.required')),
    codBankPrefixAccount: string()
      .matches(/^\d+$/, {
        excludeEmptyString: true,
        message: t('settings:Agents.CreateCollectionPlace.BankAccountPrefix.pattern'),
      })
      .max(6, t('settings:Agents.CreateCollectionPlace.BankAccountPrefix.maxLength', { count: 6 })),
    codBankAccount: string()
      .required(t('settings:Agents.CreateCollectionPlace.BankAccountNumber.required'))
      .matches(/^\d+$/, {
        excludeEmptyString: true,
        message: t('settings:Agents.CreateCollectionPlace.BankAccountNumber.pattern'),
      })
      .max(10, t('settings:Agents.CreateCollectionPlace.BankAccountNumber.maxLength', { count: 10 })),
    codBankCode: string()
      .matches(/^\d{4}$/, {
        excludeEmptyString: true,
        message: t('settings:Agents.CreateCollectionPlace.BankCode.pattern'),
      })
      .required(t('settings:Agents.CreateCollectionPlace.BankCode.required')),
    senderContactsMobile: string(),
    senderContactsPhone: string(),
    senderContactsEmail: string(),
  });
};
