import React, { useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Accordion from 'Components/Presentational/Accordion/Accordion';

const TechnologyNumberRow = ({ technologyNumber }) => (
  <Row key={technologyNumber.id}>
    <Col md="4">{technologyNumber.number}</Col>
    <Col md="4">{technologyNumber.type}</Col>
  </Row>
);

const AccordionWrapper = ({ bodyVisibleTitle, bodyHiddenTitle, children }) => (
  <Accordion bodyVisibleTitle={bodyVisibleTitle} bodyHiddenTitle={bodyHiddenTitle}>
    {children}
  </Accordion>
);

const Title = ({ onAddTechnologyNumber, visibleTechnologyNumber, disableAddBtn }) => {
  const { t } = useTranslation(['settings']);

  return (
    <div className="row-element">
      <h5 className="pl-3">{t('settings:Agents.TechNumber.title')}</h5>

      {visibleTechnologyNumber && (
        <>
          <span>{visibleTechnologyNumber.number}</span>
          <span>{visibleTechnologyNumber.type}</span>
        </>
      )}

      <Button
        onClick={onAddTechnologyNumber}
        className="btn-outline-secondary m-0"
        disabled={disableAddBtn}
      >
        {t('settings:Agents.TechNumber.addButton')}
      </Button>
    </div>
  );
};

const TechnologyNumberList = ({ technologyNumbers }) => {
  const [inactiveShown, setInactiveShown] = useState(false);
  const { t } = useTranslation(['settings']);

  return (
    <div>
      <h5>{t('settings:Agents.TechNumber.listTitle')}</h5>

      <strong className="mt-3">{t('settings:Agents.TechNumber.active')}</strong>

      {technologyNumbers
        .filter((number) => number.active === true)
        .map((technologyNumber) => (
          <TechnologyNumberRow
            key={technologyNumber.id}
            technologyNumber={technologyNumber}
          />
        ))}

      {inactiveShown && (
        <>
          <strong>{t('settings:Agents.TechNumber.inactive')}</strong>

          {technologyNumbers
            .filter((number) => number.active === false)
            .map((technologyNumber) => (
              <TechnologyNumberRow
                key={technologyNumber.id}
                technologyNumber={technologyNumber}
              />
            ))}
        </>
      )}

      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
      <span className="mb-5 btn btn-outline-secondary btn-sm" onClick={() => setInactiveShown(!inactiveShown)}>
        {!inactiveShown ? t('settings:Agents.TechNumber.showInactive') : t('settings:Agents.TechNumber.hideInactive')}
      </span>
    </div>
  );
};

const TechnologyNumberAccordionUI = ({
  onAddTechnologyNumber,
  technologyNumbers,
  toggleInactiveVisiblity,
  disableAddBtn,
}) => {
  const getVisibleTechnologyNumber = () => technologyNumbers.filter((number) => number.active === true)[0] || null;

  return (
    <AccordionWrapper
      bodyHiddenTitle={(
        <Title
          visibleTechnologyNumber={getVisibleTechnologyNumber()}
          onAddTechnologyNumber={onAddTechnologyNumber}
          disableAddBtn={disableAddBtn}
        />
      )}
      bodyVisibleTitle={(
        <Title onAddTechnologyNumber={onAddTechnologyNumber} disableAddBtn={disableAddBtn} />
      )}
    >
      {technologyNumbers ? (
        <TechnologyNumberList technologyNumbers={technologyNumbers} toggleInactiveVisiblity={toggleInactiveVisiblity} />
      ) : null}
    </AccordionWrapper>
  );
};

TechnologyNumberAccordionUI.defaultProps = { toggleInactiveVisiblity: null };

TechnologyNumberAccordionUI.propTypes = {
  disableAddBtn: PropTypes.bool.isRequired,
  onAddTechnologyNumber: PropTypes.func.isRequired,
  technologyNumbers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      number: PropTypes.string,
      type: PropTypes.string,
    }),
  ).isRequired,
  toggleInactiveVisiblity: PropTypes.bool,
};

TechnologyNumberRow.propTypes = {
  technologyNumber: PropTypes.shape({
    id: PropTypes.string,
    number: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
};

TechnologyNumberList.propTypes = {
  technologyNumbers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    number: PropTypes.string,
    type: PropTypes.string,
  })).isRequired,
};

AccordionWrapper.propTypes = {
  bodyVisibleTitle: PropTypes.element.isRequired,
  bodyHiddenTitle: PropTypes.element.isRequired,
  children: PropTypes.element.isRequired,
};

Title.propTypes = {
  disableAddBtn: PropTypes.bool.isRequired,
  onAddTechnologyNumber: PropTypes.func.isRequired,
  visibleTechnologyNumber: PropTypes.shape({
    id: PropTypes.string,
    number: PropTypes.string,
    type: PropTypes.string,
  }),
};

Title.defaultProps = { visibleTechnologyNumber: null };

export default TechnologyNumberAccordionUI;
