/* eslint-disable no-multi-spaces */
import AuthenticationHelper from '~/services/Auth';

export const ERR_NOT_LOGGED = 'ERR_NOT_LOGGED';
export const ERR_NO_PERMISSIONS = 'ERR_NO_PERMISSIONS';

export const graphQLHeaders = { headers: { AccessToken: AuthenticationHelper.accessToken } };

export const validationTextTemplates = {
  required: 'Vyplňte prosím',
  requiredSelect: 'Vyberte prosím',
  invalid: 'Neplatný formát',
  invalidNumberType: 'Očekáváno číslo',
  invalidStringType: 'Očekáván text',
};

/** Agents which require CollectionPlace postCode */
export const AGENTS_WITH_COLLECTION_PLACE_POST_CODE = [
  '23', // Ceska Posta (new integration)
];

/** Agents which require CollectionPlace externalPlaceId */
export const AGENTS_WITH_EXTERNAL_PLACE_ID = [
  '2',  // WEDO (InTime)
  '13', // WEDO (Ulozenka)
  '20', // DPD
  '21', // Geis
  '23', // Ceska Posta (new integration)
];

/** Agents which don't support package pickup (from store to carrier) */
export const AGENTS_WITHOUT_PICKUP = [
  '1',  // Ceska Posta (original)
  '2',  // WEDO (InTime)
  '9',  // Messenger
  '12', // TNT
  '13', // WEDO (Ulozenka)
  '14', // Zasilkovna
  '15', // Posta bez Hranic
  '17', // Balikonos - WEDO (InTime)
  '18', // Balikonos - Messenger
  '19', // Balikonos - TNT
  '22', // Slovenska Posta
  '23', // Ceska Posta (new integration)
  '24', // JAPO
];

/** Deprecated agents are excluded from the "Create agent" form options */
export const DEPRECATED_AGENTS = [
  '1', // Ceska Posta (original)
];
