import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';
import { getDeliveryInflectionText } from 'Shared/utilities';
import { withApollo } from 'react-apollo';
import produce from 'immer';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import DataTable from 'Containers/DataTable/DataTable';
import MainBody from 'Components/Presentational/Layout/MainBody/MainBody';
import DeliveryTableAgent from 'Components/Deliveries/DeliveryTableAgent';
import DeliveryTableStatus from 'Components/Presentational/DataTable/DeliveryTableStatus';
import DeliveryTableRecipient from 'Components/Deliveries/DeliveryTableRecipient';
import DeliveryTableCashOnDelivery from 'Components/Deliveries/DeliveryTableCashOnDelivery';
import { CustomQuery } from 'Models/Components/CustomQuery';
import FilesFragments from 'Models/Files/Query/Fragments/FilesFragments';
import Base64Helper from 'Tools/Base64Helper';
import ProtocolQueryFactory from 'Models/delivery/Protocols/ProtocolQueryFactory';
import ApolloQueryTypes from 'Types/apollo/ApolloQueryTypes';
import AuthenticationHelper from '~/services/Auth';
import { format } from '~/services/date';
import { getCarrierLogoUrl } from '~/services/utils';

const GET_PDF = ProtocolQueryFactory.deliveryCollectionProtocolPdfById(
  '...FileBase64',
  FilesFragments.fileBase64,
);

const DELIVERY_COLLECTION_PROTOCOL_BY_ID = ProtocolQueryFactory.deliveryCollectionProtocolById(`
    agent {
      originalName
      logoSlug
    }
    deliveriesCount
    created
    deliveries {
      id
      deliveryNumber
      type {
        name
      }
      recipient { contact { name surname } }
      cashOnDelivery
      cashOnDeliveryCurrency
      stateKey
    }
`);

class ProtocolDetail extends React.Component {
  formatTableData(data) {
    const { t } = this.props;

    if (!data.deliveries) {
      return [];
    }

    const { agent } = data;

    return data.deliveries.map((delivery) => {
      const currentItem = {};

      currentItem.status = (
        <DeliveryTableStatus
          number={delivery.deliveryNumber}
          status={t(`status:${delivery.stateKey}`)}
        />
      );
      currentItem.agent = (
        <DeliveryTableAgent
          deliveryType={delivery.type.name}
          img={getCarrierLogoUrl(agent.logoSlug)}
          imgAlt={agent.originalName}
        />
      );
      currentItem.recipient = (
        <DeliveryTableRecipient
          name={delivery.recipient.contact.name}
          surname={delivery.recipient.contact.surname}
        />
      );
      currentItem.cashOnDelivery = (
        <DeliveryTableCashOnDelivery
          value={delivery.cashOnDelivery}
          currency={delivery.cashOnDeliveryCurrency}
        />
      );
      currentItem.id = delivery.id;
      return currentItem;
    });
  }

  constructor(props) {
    super(props);

    const { t } = this.props;

    this.pdfLinkRef = React.createRef();
    this.state = {
      pdf: {
        url: '',
        name: '',
      },
    };

    this.table = {
      columns: [
        {
          title: '',
          index: 'status',
          key: '1',
          width: 200,
        },
        {
          title: '',
          index: 'agent',
          key: '2',
          width: 200,
        },
        {
          title: '',
          index: 'recipient',
          key: '3',
          width: 200,
        },
        {
          title: '',
          index: 'cashOnDelivery',
          key: '4',
          width: 200,
        },
      ],
      actions: {
        custom: {
          detail: {
            type: 'link',
            ref: '/deliveries/:id',
            icon: { class: ['far', 'eye'] },
            text: t('protocols:ProtocolDetail.ListItemTooltip.deliveryDetail'),
          },
        },
        rowClick: {
          type: 'link',
          ref: '/deliveries/:id',
        },
      },
      config: {
        hideActionButtons: true,
        striped: false,
        borderless: false,
        className: 'basic deliveries protocol-detail',
      },

    };
  }

  download = async () => {
    const { client, match } = this.props;
    const res = await client.query({
      query: GET_PDF,
      variables: { id: match.params.id },
      context: { headers: { AccessToken: AuthenticationHelper.accessToken } },
    });

    const protocolPdf = res.data.deliveryCollectionProtocolPdfById;
    const link = Base64Helper.createLinkForEncryptedFile(protocolPdf.rawSource, 'application/pdf');
    const state = produce(this.state, (draft) => {
      draft.pdf.url = link;
      draft.pdf.name = protocolPdf.name;
    });

    return this.setState(state, () => this.pdfLinkRef.current.click());
  };

  render() {
    const { pdf } = this.state;
    const { t, match } = this.props;

    const extraButtons = (
      <>
        <Button color="primary" onClick={this.download}>
          <FontAwesomeIcon icon={['fas', 'download']} fixedWidth />
          {t('protocols:ProtocolDetail.downloadProtocolButton')}
        </Button>
        <a href={pdf.url} download={pdf.name} ref={this.pdfLinkRef} style={{ display: 'none' }}>
          {t('protocols:ProtocolDetail.downloadProtocolLink')}
        </a>
      </>
    );

    const title = (
      <>
        <Link to="/protocols">
          <div className="page-back-btn-overlay">
            <FontAwesomeIcon icon={['fas', 'arrow-alt-circle-left']} className="text-muted page-back-btn" />
          </div>
        </Link>
        {t('protocols:ProtocolDetail.title')}
      </>
    );

    return (
      <CustomQuery query={DELIVERY_COLLECTION_PROTOCOL_BY_ID} variables={{ id: match.params.id }}>
        {({ data, loading }) => {
          let queryData = data || {};

          if (queryData && queryData.deliveryCollectionProtocolById) {
            queryData = queryData.deliveryCollectionProtocolById;
          }

          return (
            <MainBody wrapperHeight="150px" title={title} extraButtons={extraButtons}>
              <div className="info-header">
                {queryData.agent && (
                  `
                    ${queryData.agent.originalName},
                    ${queryData.deliveriesCount}
                    ${getDeliveryInflectionText(queryData.deliveriesCount, t)},
                    ${t('protocols:ProtocolDetail.protocolCreatedDateLabel')} ${format(new Date(queryData.created), 'PPp')}
                  `
                )}
              </div>
              <div>
                <DataTable
                  data={this.formatTableData(queryData)}
                  columns={this.table.columns}
                  actions={this.table.actions}
                  config={this.table.config}
                  isLoaderVisible={loading}
                />
              </div>
            </MainBody>
          );
        }}
      </CustomQuery>
    );
  }
}

ProtocolDetail.propTypes = {
  match: PropTypes.shape({ params: PropTypes.shape({ id: PropTypes.string }) }).isRequired,
  client: ApolloQueryTypes.client().isRequired,
};

export default withApollo(withTranslation(['protocols', 'status'])(ProtocolDetail));
