import moment from 'moment';

import * as actionTypes from '#/store/actions/actionTypes';
import auth from '#/core/models/authentication/AuthenticationDataSource';
import AuthenticationHelper from '~/services/Auth';

const fetchUserStart = () => ({ type: actionTypes.FETCH_USER_START });

const fetchUserFail = (error) => ({
  type: actionTypes.FETCH_USER_FAIL,
  error,
});

export const fetchUserSuccess = (userData) => ({
  type: actionTypes.FETCH_USER_SUCCESS,
  data: userData,
});

export const fetchUser = (cache = true) => async (dispatch) => {
  dispatch(fetchUserStart());

  if (moment(AuthenticationHelper.accessTokenExpiration).valueOf() > moment().valueOf()) {
    const [userResponse, eshopResponse] = await Promise.allSettled([auth.loggedUserInfo(undefined, cache), auth.eshopMeta()]);

    if (userResponse.status === 'rejected' || !userResponse?.value) {
      await dispatch(fetchUserFail({
        code: 200,
        msg: '',
        msgDesc: userResponse.reason,
      }));
    } else {
      await dispatch(fetchUserSuccess({
        user: userResponse.value.data.loggedUserInfo,
        eshop: eshopResponse?.value?.data?.eshop ?? null,
      }));
    }
  }
};

export const setUserMeta = (data) => ({
  type: actionTypes.SET_USER_META,
  data,
});
