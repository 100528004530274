import React from 'react';
import PropTypes from 'prop-types';

const tableLoader = (props) => {
  const style = { visibility: props.isVisible ? 'visible' : 'hidden' };

  return (
    <div className="table-loader" style={style} />
  );
};

tableLoader.propTypes = { isVisible: PropTypes.bool.isRequired };

export default tableLoader;
