import React from 'react';
import * as PropTypes from 'prop-types';

import { DEPRECATED_AGENTS } from '../../../../../../../core/constants/app.const';

const withUnusedAgents = (Component) => (props) => {
  // eslint-disable-next-line react/prop-types
  const { credentials, agents: allAgents } = props;

  const filterUnusedAgents = () => {
    const usedAgents = credentials.map((cred) => cred.agent.id);
    const unusedAgents = allAgents.filter((a) => !usedAgents.includes(a.id) && !DEPRECATED_AGENTS.includes(a.id));

    return unusedAgents;
  };

  return (
    <Component
      {...props}
      unusedAgents={filterUnusedAgents()}
    />
  );
};

withUnusedAgents.propTypes = {
  credentials: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      username: PropTypes.string,
      password: PropTypes.string,
      companyCode: PropTypes.string,
      techNumbers: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          number: PropTypes.number,
          type: PropTypes.string,
          active: PropTypes.bool,
          __typename: PropTypes.string,
        }),
      ),
      agent: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        fullName: PropTypes.string,
        logo: PropTypes.shape({
          url: PropTypes.string,
          __typename: PropTypes.string,
        }),
        deliveryTypes: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            __typename: PropTypes.string,
          }),
        ),
        __typename: PropTypes.string,
      }),
      __typename: PropTypes.string,
    }),
  ).isRequired,
  agents: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    fullName: PropTypes.string,
    logo: PropTypes.shape({
      url: PropTypes.string,
      __typename: PropTypes.string,
    }),
    deliveryTypes: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        __typename: PropTypes.string,
      }),
    ),
    __typename: PropTypes.string,
  }),
};

export default withUnusedAgents;
