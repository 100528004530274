import Icon from 'Components/Presentational/Icon/Icon';
import React from 'react';
import { useHistory } from 'react-router-dom';
import css from './SimpleTable.module.scss';

export type HeaderType = {
  title: string;
  colspan?: number;
  className?: string | string[];
  iconName?: string | string[];
  width?: number;
}

export type CellType = {
  key: string;
  value: string | number | boolean | React.ReactNode | JSX.Element;
  onClickUrl?: string;
  className?: string | string[];
}

export type RowType = {
  key: string;
  cells: CellType[];
}

export type Props = {
  headers: HeaderType[];
  rows?: RowType[];
  variant: 'boxed' | 'bordered';
};

const SimpleTable: React.FC<Props> = ({
  headers,
  rows,
  variant,
  children,
}) => {
  const history = useHistory();

  const handleOnRowClick = (url: string | undefined) => {
    if (!url) return;
    history.push(url);
  };

  return (
    <table className={`${css.simpleTable} ${variant === 'bordered' ? css.tableBordered : css.tableBoxed}`}>
      <thead>
        <tr>
          {headers.map((header: HeaderType) => (
            <th
              key={header.title}
              className={`title ${header?.className || ''}`.trim()}
              colSpan={header.colspan}
              style={{ width: header?.width }}
            >
              {header.iconName && (
                <>
                  <Icon icon={header.iconName} type="fw" />
                  &nbsp;
                </>
              )}
              {header.title}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        { rows ? (rows.map((row) => (
          <tr key={row.key}>
            {row.cells.map((cell) => (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
              <td
                key={cell.key}
                className={`${cell?.className || ''} ${cell.onClickUrl && css.clickableColumn}`.trim()}
                onClick={() => handleOnRowClick(cell.onClickUrl)}
              >
                {cell.value}
              </td>
            ))}
          </tr>
        ))) : children}
      </tbody>
    </table>
  );
};

export default SimpleTable;
