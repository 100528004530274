import React from 'react';
import { generate } from 'shortid';

import { nl2p } from '#/shared/utilities';

interface FailedDelivery {
  deliveryId: number;
  message: string;
}

type Props = {
  failedDeliveries: FailedDelivery[];
  messages: string[];
  success: boolean;
}

const CloseDeliveryToastBody: React.FC<Props> = ({
  failedDeliveries,
  messages,
  success,
}) => {
  if (success) {
    return (
      <>
        {messages.map((message) => (
          <div key={generate()}>
            {nl2p(message)}
          </div>
        ))}
      </>
    );
  }

  return (
    <>
      {failedDeliveries.map((delivery) => (
        <div key={generate()} className="mb-3">
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a className="mb-1 text-underline text-white font-weight-bold" href={`/deliveries/${delivery.deliveryId}`} target="_blank">
            {'Zásilka: '}
            {delivery.deliveryId}
          </a>

          {nl2p(delivery.message)}
        </div>
      ))}
    </>
  );
};

export default CloseDeliveryToastBody;
