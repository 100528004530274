/* eslint-disable class-methods-use-this */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
export default class Base64Helper {
  static getUrlFromBlob(blob) {
    return URL.createObjectURL(blob);
  }

  constructor(file) {
    this.file = file;
    this.base64string = '';
  }

  stripBrowserInfo(string) {
    return string.split(',')[1];
  }

  convertBase64ToBlob(base64string, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    const byteCharacters = atob(base64string);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }

  // eslint-disable-next-line no-return-assign
  _handleLoadEnd = (e) => this.base64string = this.stripBrowserInfo(e.target.result);

  _convertFileToBase64WithoutBrowserInfo() {
    const FileReaderStream = new Promise((resolve) => {
      let reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = resolve;
      reader = null;
    });

    return FileReaderStream.then((e) => this._handleLoadEnd(e));
  }

  async getBase64String() {
    // eslint-disable-next-line no-underscore-dangle
    await this._convertFileToBase64WithoutBrowserInfo();
    return this.base64string;
  }

  static createLinkForEncryptedFile(encryptedFile, contentType) {
    const base64 = new Base64Helper(null);
    encryptedFile = base64.stripBrowserInfo(encryptedFile);
    const blob = base64.convertBase64ToBlob(encryptedFile, contentType);
    return URL.createObjectURL(blob);
  }
}
