import ApolloClient, { ApolloQueryResult } from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { subMonths } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col, Row, Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import SettingsBase from '#/Components/Containers/Settings/_base/SettingsBase';
import SettingsLayout from '#/Components/Settings/SettingsLayout';
import Loader from '#/Components/Containers/Loader/Loader';
import DataTable from '#/Components/Containers/DataTable/DataTable';
import graphqlStorage from '#/core/services/GraphqlStorage';
import AuthenticationHelper from '~/services/Auth';
import { formatCurrency } from '~/services/utils';
import { Currency } from '~/types/generated/graphql';
import INVOICES from './invoiceCurrentUserList.gql';
import { format } from '~/services/date';

interface FileUrl {
  name: string;
  url: string;
}

interface Invoice {
  id: string;
  title: string;
  priceTotal: number;
  priceTotalVat: number;
  currency: string;
  issued: string;
  maturity: string;
  isPaid: boolean;
  invoiceFile: FileUrl;
}

interface InvoiceRow {
  id: string;
  title: string;
  issued: JSX.Element | string;
  maturity: JSX.Element | string;
  isPaid?: JSX.Element | string;
  price: string;
  download: JSX.Element | string;
}

interface InvoiceQueryArgs {
  periodFrom: string;
  periodTo: string;
}

interface InvoiceQueryData {
  invoiceCurrentUserList: Invoice[];
}

class Invoices extends SettingsBase {
  gqlClient: ApolloClient<NormalizedCacheObject>;

  constructor(props: any) {
    super(props);
    this.gqlClient = graphqlStorage.client;
  }

  async componentDidMount(): Promise<void> {
    try {
      const response = await this.fetchInvoices();

      if (response.data) {
        this.renderTable(Invoices.formatRows(response.data.invoiceCurrentUserList));
      }
    } catch (e) {
      // Handled by global error interceptor
    }
  }

  renderTable(data: InvoiceRow[]): void {
    const { t } = this.props;

    this.setState({
      isOpened: true,
      isPartLoading: false,
      data,
      table: {
        isLoaderVisible: false,
        columns: [
          {
            title: 'ID',
            index: 'title',
            key: '1',
            className: 'pr-4 font-weight-medium',
          },
          {
            title: `${t('settings:Invoices.invoiceDate')}`,
            index: 'issued',
            key: '2',
            className: 'text-muted',
          },
          {
            title: `${t('settings:Invoices.invoiceDueDate')}`,
            index: 'maturity',
            key: '3',
            className: 'text-muted',
          },
          {
            title: `${t('settings:Invoices.paid')}`,
            index: 'isPaid',
            key: '4',
          },
          {
            title: `${t('settings:Invoices.sum')}`,
            index: 'price',
            key: '5',
          },
          {
            title: '',
            index: 'download',
            key: '6',
            className: 'pl-4',
          },
        ],
        config: {
          showHeader: true,
        },
      },
    });
  }

  static formatRows(invoices: Invoice[]): InvoiceRow[] {
    return invoices.map((invoice) => {
      const {
        id,
        title,
        issued,
        maturity,
        isPaid,
        priceTotalVat,
        currency,
        invoiceFile,
      } = invoice;

      const statusIcon = isPaid ? <FontAwesomeIcon icon="check" className="d-block ml-3 text-success" fixedWidth /> : undefined;

      return {
        title,
        issued: (
          <span className="text-nowrap">
            {format(new Date(issued))}
          </span>
        ),
        maturity: (
          <span className="text-nowrap">
            {format(new Date(maturity))}
          </span>
        ),
        isPaid: statusIcon,
        price: formatCurrency(priceTotalVat, {
          currency: currency as Currency,
          currencyDisplay: 'symbol',
        }),
        download: (
          <a
            href={invoiceFile.url}
            target="_blank"
            rel="noreferrer"
            download={invoiceFile.name}
          >
            <FontAwesomeIcon icon={['fas', 'download']} />
          </a>
        ),
        id,
      };
    });
  }

  async fetchInvoices(): Promise<ApolloQueryResult<InvoiceQueryData>> {
    this.setLoading(true);

    const response = await this.gqlClient.query<InvoiceQueryData, InvoiceQueryArgs>({
      query: INVOICES,
      variables: {
        periodFrom: subMonths(new Date(), 12).toISOString(),
        periodTo: new Date().toISOString(),
      },
      context: {
        headers: {
          AccessToken: AuthenticationHelper.accessToken,
        },
      },
    });

    this.setLoading(false);

    return response;
  }

  setLoading(loading: boolean): void {
    this.setState({
      isLoaderVisible: loading,
    });
  }

  render() {
    const { t } = this.props;

    let content = (
      <Loader />
    );

    if (this.state.table) {
      content = (
        <Container>
          <Row className="align-items-center right-col-spacing">
            <Col md={7}>
              <h1>{t('settings:Invoices.title')}</h1>
            </Col>
          </Row>
          <DataTable
            data={this.state.data}
            columns={this.state.table.columns}
            actions={this.state.table.actions}
            config={this.state.table.config}
            isLoaderVisible={this.state.table.isLoaderVisible}
          />
          <p className="mb-0 mt-4">
            {t('settings:Invoices.addressChange')}
            {' '}
            <Link to="/settings/company">
              {t('settings:Invoices.addressChangeLink')}
            </Link>
          </p>
        </Container>
      );
    }

    return (
      <SettingsLayout
        goBack="/settings"
        leftBlock={this.state.leftBlock}
        rightBlock={this.state.rightBlock}
        checkedItems={this.state.checkedItems}
        isOpened={this.state.isOpened}
        isPartLoading={this.state.isPartLoading}
      >
        {content}
      </SettingsLayout>
    );
  }
}

export default withTranslation(['settings'])(Invoices);
