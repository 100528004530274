// :: React
import React from 'react';
import PropTypes from 'prop-types';
import FoxdeliIcon from 'Components/Presentational/FoxdeliIcon/FoxdeliIcon';
import SvgIcon from 'Components/Presentational/SVGIcon/SVGIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Icon = ({
  icon: iconName,
  className,
  type,
  size,
  style,
  fixedWidth,
}) => {
  let icon = null;

  if (type === 'foxdeli') {
    icon = (
      <FoxdeliIcon
        icon={iconName}
        size={size}
        className={className}
        style={style}
      />
    );
  } else if (type === 'svg') {
    icon = (
      <SvgIcon
        icon={iconName}
        className={className}
        style={style}
      />
    );
  } else if (type === 'fw') {
    icon = (
      <FontAwesomeIcon
        icon={iconName}
        className={className}
        style={style}
        fixedWidth={fixedWidth}
      />
    );
  }

  return (icon);
};

Icon.defaultProps = {
  className: '',
  style: null,
  size: 'default',
  fixedWidth: false,
};

Icon.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  type: PropTypes.oneOf(['foxdeli', 'svg', 'fw']).isRequired,
};

export default Icon;
