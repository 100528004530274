import React, { useState } from 'react';
import { Button, FormGroup, Label, Modal, ModalBody, Form, FormFeedback } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';

import CountrySelect from 'Containers/Deliveries/New/CountrySelect/CountrySelect';
import SelectField from 'Containers/Deliveries/New/SelectField/SelectField';
import Icon from '~/components/Icon/Icon';
import useAuthMutation from '~/hooks/useAuthMutation';
import { toast } from '#/shared/utilities';

import TEST_EMAIL from './sendTestEmail.gql';

import css from './TestEmailModal.module.scss';
import SubmitButton from '~/components/SubmitButton/SubmitButton';

type Props = {
  activeTemplateId: Nullable<string>;
}

interface TestEmailValues {
  id: string,
  emailType: string,
  country: string,
}

const TestEmailModal: React.FC<Props> = ({ activeTemplateId }) => {
  const { t } = useTranslation(['settings', 'validation']);
  const [modalOpen, setModalOpen] = useState(false);

  const user = useSelector((state: any) => state.userReducer.data);
  const userEmail = user?.email;

  const [sendTestEmail] = useAuthMutation(TEST_EMAIL);

  const initialValues: TestEmailValues = {
    id: '',
    emailType: '',
    country: '',
  };

  const toggleModal = () => {
    if (modalOpen) {
      setModalOpen(false);
    } else {
      setModalOpen(true);
    }
  };

  const handleFormSubmit = async (values: TestEmailValues) => {
    try {
      const response = await sendTestEmail({
        variables: {
          id: activeTemplateId,
          emailType: values.emailType,
          country: values.country,
        },
      });
      const msg = response.data.sendTestEmail;

      if (msg.success) {
        toast.success(t('settings:Branding.Mailing.TestEmailModal.Banner.success'));
        setModalOpen(false);
      } else {
        toast.error(t('settings:Branding.Mailing.TestEmailModal.Banner.error'));
      }
    } catch {
      toast.error(t('settings:Branding.Mailing.TestEmailModal.Banner.error'));
    }
  };

  const options = [
    {
      label: t('settings:Branding.Mailing.TestEmailModal.EmailType.Options.shipped'),
      value: 'shipped',
    },
    {
      label: t('settings:Branding.Mailing.TestEmailModal.EmailType.Options.deliveryToday'),
      value: 'deliveryToday',
    },
    {
      label: t('settings:Branding.Mailing.TestEmailModal.EmailType.Options.deliveredPickupPlace'),
      value: 'deliveredPickupPlace',
    },
    {
      label: t('settings:Branding.Mailing.TestEmailModal.EmailType.Options.deliveredCustomer'),
      value: 'deliveredCustomer',
    },
  ];

  return (
    <>
      <Button
        color="secondary"
        onClick={() => setModalOpen(true)}
      >
        {t('settings:Branding.Mailing.testButton')}
      </Button>

      <Modal
        isOpen={modalOpen}
        toggle={toggleModal}
        centered
        className="text-center"
      >
        <button
          type="button"
          onClick={() => setModalOpen(false)}
          className={css.closeButton}
        >
          <Icon name="close" />
        </button>

        <ModalBody>
          <h2 className={`${css.title} mt-2`}>
            {t('settings:Branding.Mailing.TestEmailModal.title')}
          </h2>
          <p className="mt-4">
            {t('settings:Branding.Mailing.TestEmailModal.description')}
            {' '}
            <span className={css.userEmail}>{userEmail}</span>
          </p>

          <Formik
            initialValues={initialValues}
            onSubmit={handleFormSubmit}
            validationSchema={Yup.object().shape({
              country: Yup.string()
                .required(t('validation:CountrySelect.required')),
              emailType: Yup.string()
                .required(t('settings:Branding.Mailing.TestEmailModal.EmailType.required')),
            })}
          >
            {({
              handleSubmit,
              isSubmitting,
              values,
              setFieldValue,
              handleBlur,
              touched,
              errors,
            }) => (
              <Form
                onSubmit={handleSubmit}
                noValidate
              >
                <FormGroup className="text-left">
                  <Label>
                    {t('settings:Branding.Mailing.TestEmailModal.countryPickerLabel')}
                    <span className="branding-required-field">*</span>
                  </Label>
                  <CountrySelect
                    name="country"
                    // `value` must be of type `string` due to `CustomSelect` implementation
                    // (see CustomSelect.tsx:81)
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    value={values.country}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    onChange={({ value }) => setFieldValue('country', value)}
                    onBlur={handleBlur}
                    placeholder={t('validation:CountrySelect.placeholder')}
                    defaultLabel={t('validation:CountrySelect.label')}
                  />
                  {touched.country && errors.country && (
                    <FormFeedback className="d-block">{errors.country}</FormFeedback>
                  )}
                </FormGroup>

                <FormGroup className={`${css.emailType} text-left`}>
                  <Label>
                    {t('settings:Branding.Mailing.TestEmailModal.EmailType.label')}
                    <span className="branding-required-field">*</span>
                  </Label>
                  <SelectField
                    name="emailType"
                    options={options}
                    placeholder={t('settings:Branding.Mailing.TestEmailModal.EmailType.placeholder')}
                  />
                </FormGroup>

                <SubmitButton
                  color="primary"
                  className="mt-4"
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  {t('settings:Branding.Mailing.TestEmailModal.submitButton')}
                </SubmitButton>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </>
  );
};

export default TestEmailModal;
