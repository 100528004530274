import React from 'react';
import PropTypes from 'prop-types';

const DataTableTextWithSubheading = ({ heading, icon, subheading, className }) => (
  <div className={className}>
    <strong className="d-block">
      {heading}
      <span>{icon && icon}</span>
    </strong>
    {subheading}
  </div>
);

DataTableTextWithSubheading.propTypes = {
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  className: PropTypes.string,
};

DataTableTextWithSubheading.defaultProps = {
  icon: false,
  subheading: '',
  className: '',
};

export default DataTableTextWithSubheading;
