import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { useNoteInput } from '../../../useNoteInput';

const NoteModal = ({ id, defaultValue, refetchTimelineEvents, setEditNoteId }) => {
  const { t } = useTranslation('deliveries');
  const noteInput = useNoteInput({ noteId: id, refetchTimelineEvents });

  function nullifyNoteId() {
    setEditNoteId(null);
  }

  function handleSubmit(e) {
    e.preventDefault();
    noteInput.onNoteUpdate();
    nullifyNoteId();
  }

  return (
    <Modal isOpen={id !== null} toggle={nullifyNoteId} centered>
      <ModalHeader toggle={nullifyNoteId}>
        {t('DeliveryDetail.Timeline.Notes.Modal.title')}
      </ModalHeader>
      <ModalBody>
        <Input
          type="textarea"
          onInput={noteInput.onNoteChange}
          defaultValue={defaultValue}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSubmit} disabled={noteInput.note === ''}>
          {t('DeliveryDetail.Timeline.Notes.Modal.submitButton')}
        </Button>
        <Button color="link" onClick={nullifyNoteId}>
          {t('DeliveryDetail.Timeline.Notes.Modal.cancelButton')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default NoteModal;
