import * as Yup from 'yup';

const filterInvisibleInputs = (inputVisibilitySchema, t) => {
  const rules = {
    name: Yup.string(t('settings:Agents.Login.required'))
      .typeError(t('settings:Agents.Login.required'))
      .required(t('settings:Agents.Login.required')),
    password: Yup.string(t('settings:Agents.Login.required'))
      .typeError(t('settings:Agents.Login.required'))
      .required(t('settings:Agents.Login.required')),
    companyCode: Yup.string(t('settings:Agents.Login.required'))
      .typeError(t('settings:Agents.Login.required'))
      .required(t('settings:Agents.Login.required')),
    consent: Yup.bool()
      .notOneOf([false], t('settings:Agents.PrivacyConsent.required'))
      .required(t('settings:Agents.PrivacyConsent.required')),
  };

  const activeRuleKeys = Object.entries(inputVisibilitySchema)
  // eslint-disable-next-line no-unused-vars
    .filter(([key, value]) => value === true)
    .map((input) => (input[0] === 'userName' ? ['name', input[1]] : input))
    .reduce((acc, input) => [...acc, input[0]], []);

  delete activeRuleKeys.userName;

  const activeRules = {};
  activeRuleKeys.forEach((key) => { activeRules[key] = rules[key]; });

  return activeRules;
};

// eslint-disable-next-line import/prefer-default-export
export function createValidationSchema(
  {
    userName,
    password,
    companyCode,
    consent,
  },
  t,
) {
  const filteredRules = filterInvisibleInputs(
    {
      userName,
      password,
      companyCode,
      consent,
    },
    t,
  );

  return Yup.object().shape(filteredRules);
}
