import gql from 'graphql-tag';
import BaseDataSource from '../BaseDataSource';
import AuthenticationHelper from '~/services/Auth';

export default class AccountDataSource extends BaseDataSource {
  async activateAccount(id, cache = true) {
    const mutation = gql`
      mutation activateAccount($id: ID!) {
        activateAccount(id: $id) {
          id
        }
      }
    `;

    const variables = { id };

    const options = {
      mutation,
      variables,
      context: { headers: { AccessToken: AuthenticationHelper.accessToken } },
    };

    if (!cache) {
      options.fetchPolicy = 'no-cache';
    }

    return this.client.mutate(options);
  }

  async deactivateAccount(id, cache = true) {
    const mutation = gql`
      mutation deactivateAccount($id: ID!) {
        deactivateAccount(id: $id) {
          id
        }
      }
    `;

    const variables = { id };

    const options = {
      mutation,
      variables,
      context: { headers: { AccessToken: AuthenticationHelper.accessToken } },
    };

    if (!cache) {
      options.fetchPolicy = 'no-cache';
    }

    return this.client.mutate(options);
  }

  async updateAccount(id, account) {
    const mutation = gql`
      mutation updateAccount($id: ID, $account: InputAccount!) {
        updateAccount(id: $id, account: $account) {
          id
          contact {
            name
            surname
            email
            phone
          }
        }
      }
    `;

    const variables = { id, account };

    return this.client.mutate({
      mutation,
      variables,
      context: { headers: { AccessToken: AuthenticationHelper.accessToken } },
    });
  }

  async accounts(cache = true) {
    const query = gql`
      query accounts {
        accounts {
          id
          active
          primaryAccess
          contact {
            name
            surname
            email
          }
        }
      }
    `;

    const options = {
      query,
      context: { headers: { AccessToken: AuthenticationHelper.accessToken } },
    };

    if (!cache) {
      options.fetchPolicy = 'network-only';
    }

    return this.client.query(options);
  }

  async accountById(id) {
    const query = gql`
      query accountById($id: ID!) {
        accountById(id: $id) {
          id
          name
          login
          primaryAccess
          protocolAccess
          deliveryAccessType
          active
          contact {
            name
            surname
            email
            phone
          }
        }
      }
    `;

    const variables = { id };

    return this.client.query({
      query,
      variables,
      context: { headers: { AccessToken: AuthenticationHelper.accessToken } },
    });
  }
}
