import { Button } from 'reactstrap';
import React from 'react';
import { useTranslation } from 'react-i18next';

function LockToggleButton(props) {
  const { t } = useTranslation('settings');
  const { onClick } = props;

  return (
    <Button
      onClick={onClick}
      style={{
        display: 'block',
        width: '100%',
        borderRadius: 0,
      }}
    >
      {t('settings:Agents.Login.editButton')}
    </Button>
  );
}

export default LockToggleButton;
