import gql from 'graphql-tag';

import AuthenticationHelper from '~/services/Auth';
import BaseDataSource from '../BaseDataSource';

export default class CollectionPlace extends BaseDataSource {
  async collectionPlaces() {
    const query = gql`
      query collectionPlaces {
        collectionPlaces {
          id
          name
          active
          address {
            street
            city
            zip
            country
          }
        }
      }
    `;

    return this.client.query({
      query,
      context: { headers: { AccessToken: AuthenticationHelper.accessToken } },
    });
  }

  async createCollectionPlace(
    name,
    companyNameForTickets,
    identificator,
    active,
    contact,
    address,
  ) {
    const mutation = gql`
      mutation createCollectionPlace($collectionPlace: InputCollectionPlaceCreate! ) {
        createCollectionPlace(collectionPlace: $collectionPlace) {
          id
        }
      }
    `;

    const variables = { collectionPlace: { name, companyNameForTickets, identificator, contact, address, active } };

    return this.client.mutate({
      mutation,
      variables,
      context: { headers: { AccessToken: AuthenticationHelper.accessToken } },
    });
  }

  async deactivateCollectionPlace(id) {
    const mutation = gql`
      mutation deactivateCollectionPlace($id: ID!) {
        deactivateCollectionPlace(id: $id) {
          id
        }
      }
    `;

    const variables = { id };

    return this.client.mutate({
      mutation,
      variables,
      context: { headers: { AccessToken: AuthenticationHelper.accessToken } },
    });
  }

  async updateCollectionPlace(id, name, companyNameForTickets, identificator, active, contact) {
    const mutation = gql`
      mutation updateCollectionPlace($id: ID!, $collectionPlace: InputCollectionPlaceUpdate!) {
        updateCollectionPlace(id: $id, collectionPlace: $collectionPlace) {
          id
        }
      }
    `;

    const variables = {
      id,
      collectionPlace: {
        name,
        companyNameForTickets,
        active,
        identificator,
        contact,
      },
    };

    return this.client.mutate({
      mutation,
      variables,
      context: { headers: { AccessToken: AuthenticationHelper.accessToken } },
    });
  }

  async collectionPlaceById(id) {
    const query = gql`
      query collectionPlaceById($id: ID!) {
        collectionPlaceById(id: $id) {
          id
          name
          companyNameForTickets
          active
          identificator
          contact {
            name
            surname
            phone
            email
          }
          address {
            street
            country
            city
            zip
          }
        }
      }
    `;

    const variables = { id };

    return this.client.query({
      query,
      variables,
      context: { headers: { AccessToken: AuthenticationHelper.accessToken } },
    });
  }
}
