import React from 'react';
import { Spinner } from 'reactstrap';

import CustomQuery from 'Models/Components/CustomQuery';
import AgentQueryFactory
  from 'Models/agent/Query/Factories/AgentQueryFactory';
import AgentFragments from 'Models/agent/Query/Fragments/AgentFragments';

const withAllAgents = (Component) => (props) => (
  <CustomQuery
    query={AgentQueryFactory.allAgents('...CredentialFormAgent', AgentFragments.credentialFormAgent())}
  >
    {({ data, error, loading }) => {
      if (loading) return <Spinner />;
      if (error) return null;

      return (
        <Component {...props} agents={data.allAgents} />
      );
    }}
  </CustomQuery>
);

export default withAllAgents;
