import React from 'react';
import { DropdownItem } from 'reactstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import LocalDeliveryTypes from '../../LocalDeliveryTypes/LocalDeliveryTypes';

/* eslint-disable jsx-a11y/click-events-have-key-events */
const CloseDelivery = ({ canClose, onCloseClick }) => {
  const { t } = useTranslation('deliveries');

  return (
    <DropdownItem disabled={!canClose} onClick={onCloseClick}>
      <FontAwesomeIcon icon={['fas', 'box']} fixedWidth />
      {t('DeliveryDetail.Menu.closeDelivery')}
    </DropdownItem>
  );
};

CloseDelivery.defaultProps = {
  canClose: true,
  onCloseClick: () => null,
};

CloseDelivery.propTypes = {
  // eslint-disable-next-line react/no-typos
  canClose: LocalDeliveryTypes.every().canClose,
  onCloseClick: PropTypes.func,
};

export default CloseDelivery;
