import React from 'react';
import produce from 'immer';
import { withTranslation } from 'react-i18next';

import SettingsBase from 'Containers/Settings/_base/SettingsBase';
import SettingsLayout from 'Components/Settings/SettingsLayout';
import EshopPage from './EshopPage';

class Eshop extends SettingsBase {
  constructor(props) {
    super(props, 'general');
  }

  refreshSidebarEshopName(name) {
    const newState = produce(this.state, (draft) => {
      draft.leftBlock.header.title = name;
      draft.rightBlock.header.title = name;
    });

    this.setState(newState);
  }

  render() {
    return (
      <SettingsLayout
        goBack="/settings"
        leftBlock={this.state.leftBlock}
        rightBlock={this.state.rightBlock}
        checkedItems={this.state.checkedItems}
        isPartLoading={false}
        isOpened
      >
        <EshopPage updateSidebar={(name) => this.refreshSidebarEshopName(name)} />
      </SettingsLayout>
    );
  }
}

export default withTranslation(['settings'])(Eshop);
