const brandingRouteNames = {
  trackAndTrace: 't&t',
  newTrackAndTrace: 'new-t&t',
  editTrackAndTrace: 'edit-t&t',
  mailing: 'mailing',
  newMailing: 'new-mailing',
  editMailing: 'edit-mailing',
  campaign: 'campaign',
  newCampaign: 'new-campaign',
  editCampaign: 'edit-campaign',
};

export default brandingRouteNames;
