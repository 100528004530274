import Papa from 'papaparse/papaparse';
import jschardet from 'jschardet';

class CSVParser {
  static removeEmptyColumnsFromEnd = (rows) => {
    const formattedRows = rows;
    const cellLength = formattedRows[0].length;

    for (let cell = cellLength - 1; cell >= 0; cell -= 1) {
      if (formattedRows.every((row) => row[cell] === '')) {
        formattedRows.map((row) => row.splice(cell, 1));
      } else {
        break;
      }
    }

    return formattedRows;
  };

  constructor(callbackOnDone, callbackOnError) {
    this.callbackOnDone = callbackOnDone;
    this.callbackOnError = callbackOnError;
    this.allowedSeparators = [',', ';'];
  }

  handle(file, separator = ',') {
    this.config = {
      separator,
      hasHeader: false,
      encoding: '',
    };

    this.file = file;

    if (window.FileReader) {
      this.getFileTextEncoding();
    }
  }

  getFileTextEncoding() {
    const reader = new FileReader();

    reader.readAsBinaryString(this.file);
    reader.onload = this.onFileTextEncodingFinish;
    reader.onerror = this.onFileTextEncodingError;
  }

  onFileTextEncodingFinish = (event) => {
    const fileContent = event.target.result;
    const charEncoding = jschardet.detect(fileContent);
    const { encoding } = charEncoding;

    if (encoding !== 'UTF-8') {
      charEncoding.encoding = 'windows-1250';
    }

    this.config.encoding = charEncoding.encoding;

    Papa.parse(this.file, {
      header: false,
      skipEmptyLines: 'greedy',
      encoding: this.config.encoding,
      transform(value) {
        if (typeof value === 'string') {
          return value.trim();
        }

        return value;
      },
      complete: (result) => {
        this.onProcessFileComplete(result);
      },
      errors: () => {
        this.onProcessFileError();
      },
    });
  };

  onFileTextEncodingError = (event) => {
    this.callbackOnError([event.target.error]);
  };

  onProcessFileComplete(result) {
    const errorCodeWhitelist = ['TooFewFields'];

    if (result.data.length === 0) {
      return this.callbackOnError([{ message: 'CSV soubor neobsahuje žádná data!' }]);
    }

    if (!this.allowedSeparators.includes(result.meta.delimiter)) {
      return this.callbackOnError([{ message: `CSV obsahuje neplatný oddělovací znak, povolené jsou ${this.allowedSeparators.join(' nebo ')}` }]);
    }

    if (result.errors.length > 0 && result.errors.every((error) => !errorCodeWhitelist.includes(error.code))) {
      return this.callbackOnError(result.errors);
    }

    const resultWithoutEmptyColumns = result;
    resultWithoutEmptyColumns.data = CSVParser.removeEmptyColumnsFromEnd(
      result.data,
    );

    return this.callbackOnDone(resultWithoutEmptyColumns);
  }

  onProcessFileError = (errors) => {
    this.callbackOnError(errors);
  };
}

export default CSVParser;
