import React, { useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';

import Accordion from 'Components/Presentational/Accordion/Accordion';
import { generateReactKey } from 'Shared/utilities';

const SequenceAccordionUI = ({ onAddSequenceClick, sequences, service, editSequence, formik }) => {
  const { t } = useTranslation('settings');
  const [inactiveShown, setInactiveShown] = useState(false);

  const title = (
    <div className="row-element">
      <h5 className="pl-3 flex-grow-1">{service.deliveryType.name}</h5>
      <span className="px-3">{service.deliveryType.abbr}</span>
      <Button
        onClick={(e) => onAddSequenceClick(e, formik)}
        className="btn-outline-secondary m-0"
      >
        {t('Agents.DeliveryServices.addSequenceButton')}
      </Button>
    </div>
  );

  const renderSequences = (sequencesToRender) => sequencesToRender.map((sequence) => (
    <React.Fragment key={sequence.key || generateReactKey()}>
      <Row className="mt-3">
        <Col md="6">
          <strong>
            {sequence.start}
            -
            {sequence.end}
          </strong>
        </Col>
        <Col md="4" className="text-center">
          {new Date(sequence.created).toLocaleDateString('cs-CZ')}
        </Col>
        <Col md="2">
          {sequence.manuallyAdded && (
            <FontAwesomeIcon
              onClick={() => editSequence(sequence.key, formik)}
              icon={['fas', 'pen']}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <Trans
            i18nKey="settings:Agents.DeliveryServices.remainingSequence"
            values={{
              countRemainingNumbers: `${sequence.countRemainingNumbers}`,
              countNumbers: `${sequence.countNumbers}`,
            }}
          />
        </Col>
        <Col md="4" className="text-center">
          {sequence.cod && 'Dobírková'}
        </Col>
        <Col md="2"> &nbsp; </Col>
      </Row>
    </React.Fragment>
  ));

  return (
    <Accordion title={title}>
      <div>
        <h5>{t('Agents.DeliveryServices.activeSequenceTitle')}</h5>

        {renderSequences(sequences.filter((s) => s.active === true))}

        {inactiveShown && (
          <div className="mt-5">
            <h5>
              {t('Agents.DeliveryServices.inactiveSequenceTitle')}
            </h5>
          </div>
        )}

        {inactiveShown && renderSequences(sequences.filter((s) => s.active === false))}
        <Row className="mt-3">
          <Col md="12">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <span className="mb-5 btn btn-outline-secondary btn-sm" onClick={() => setInactiveShown(!inactiveShown)}>
              {!inactiveShown
                ? `${t('Agents.DeliveryServices.showSequencesButton')}`
                : `${t('Agents.DeliveryServices.hideSequencesButton')}`}
            </span>
          </Col>
        </Row>
      </div>
    </Accordion>
  );
};

SequenceAccordionUI.propTypes = {
  onAddSequenceClick: PropTypes.func.isRequired,
  sequences: PropTypes.arrayOf(
    PropTypes.shape({
      sequenceStart: PropTypes.string,
      sequenceEnd: PropTypes.string,
      cashOnDelivery: PropTypes.bool,
    }),
  ).isRequired,
  service: PropTypes.shape({}).isRequired,
  editSequence: PropTypes.func.isRequired,
  formik: PropTypes.shape({}).isRequired,
};

export default SequenceAccordionUI;
