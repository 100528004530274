/* eslint-disable react/require-default-props */
import React from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Accordion from 'Components/Presentational/Accordion/Accordion';

const AccordionWrapper = ({ bodyVisibleTitle, bodyHiddenTitle, children }) => (
  <Accordion bodyVisibleTitle={bodyVisibleTitle} bodyHiddenTitle={bodyHiddenTitle}>
    {children}
  </Accordion>
);

const Title = ({
  onAddStartNumberClick,
  visibleStartNumber,
  service,
  disableAddBtn,
}) => {
  const { t } = useTranslation('settings');

  return (
    <div className="row-element">
      <h5 className="pl-3 flex-grow-1">{service.deliveryType.name}</h5>
      {visibleStartNumber && <div className="mr-5">{visibleStartNumber.number}</div>}

      <Button
        onClick={onAddStartNumberClick}
        outline
        color="secondary"
        className="m-0"
        disabled={disableAddBtn}
      >
        {t('settings:Agents.DeliveryServices.addStartNumberButton')}
      </Button>
    </div>
  );
};

const StartNumberList = ({ startNumber }) => {
  const { t } = useTranslation('settings');

  return (
    <div>
      <h5>{t('settings:Agents.DeliveryServices.startNumberTitle')}</h5>

      {startNumber && (
        <>
          {t('settings:Agents.DeliveryServices.startNumberLabel')}{': '}{startNumber.number}
        </>
      )}
    </div>
  );
};

const StartNumberAccordionUI = ({
  onAddStartNumberClick,
  inactiveStartNumbers,
  startNumber,
  service,
  disableAddBtn,
}) => (
  <AccordionWrapper
    bodyHiddenTitle={(
      <Title
        service={service}
        visibleStartNumber={startNumber}
        onAddStartNumberClick={onAddStartNumberClick}
        disableAddBtn={disableAddBtn}
      />
    )}
    bodyVisibleTitle={(
      <Title
        service={service}
        onAddStartNumberClick={onAddStartNumberClick}
        disableAddBtn={disableAddBtn}
      />
    )}
  >
    <StartNumberList startNumber={startNumber} inactiveStartNumbers={inactiveStartNumbers} />
  </AccordionWrapper>
);

StartNumberList.defaultProps = { startNumber: null };

StartNumberAccordionUI.defaultProps = { startNumber: null };

StartNumberAccordionUI.propTypes = {
  disableAddBtn: PropTypes.bool.isRequired,
  onAddStartNumberClick: PropTypes.func.isRequired,
  inactiveStartNumbers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  startNumber: PropTypes.shape({
    id: PropTypes.string,
    number: PropTypes.string,
    type: PropTypes.string,
  }),
  service: PropTypes.shape({}).isRequired,
};

StartNumberList.propTypes = {
  startNumber: PropTypes.shape({
    id: PropTypes.string,
    number: PropTypes.string,
    used: PropTypes.bool,
  }),
};

AccordionWrapper.propTypes = {
  bodyVisibleTitle: PropTypes.element.isRequired,
  bodyHiddenTitle: PropTypes.element.isRequired,
  children: PropTypes.element.isRequired,
};

Title.propTypes = {
  disableAddBtn: PropTypes.bool.isRequired,
  service: PropTypes.shape({}).isRequired,
  onAddStartNumberClick: PropTypes.func.isRequired,
  visibleStartNumber: PropTypes.shape({
    id: PropTypes.string,
    number: PropTypes.string,
    type: PropTypes.string,
  }),
};

Title.defaultProps = { visibleStartNumber: null };

export default StartNumberAccordionUI;
