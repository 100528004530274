import React from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import css from './ExtraServices.module.scss';

interface ExtraService {
  code: string;
  name: string;
}

type Props = {
  extraServices: ExtraService[];
  agentAbbr: string;
};

const ExtraServices: React.FC<Props> = ({
  extraServices,
  agentAbbr,
}) => {
  const { t } = useTranslation(['deliveries', 'services']);

  if (!extraServices?.length) {
    return null;
  }

  const extraServiceItems = extraServices.map((item) => (
    <li key={item.code}>
      <span className="text-dark">
        {t([`services:${agentAbbr}.${item.code}`, `services:${item.code}.name`], item.name)}
      </span>
    </li>
  ));

  return (
    <Card className={css.extraServices}>
      <CardBody>
        <CardTitle className="text-dark font-weight-bold">
          {t('deliveries:DeliveryDetail.extraServicesTitle')}
        </CardTitle>

        <ul className="mb-0">
          {extraServiceItems}
        </ul>
      </CardBody>
    </Card>
  );
};

export default ExtraServices;
