import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { stringifyNull } from 'Shared/utilities';

const DeliveryRecipient = ({ name, surname }) => {
  const { t } = useTranslation();

  return (
    <div className="recipient">
      <span className="d-block text-truncate" title={`${stringifyNull(name)} ${stringifyNull(surname)}`}>
        {`${stringifyNull(name)} ${stringifyNull(surname)}`}
      </span>
      {t('SummaryListItemLabels.recipient')}
    </div>
  );
};

DeliveryRecipient.propTypes = {
  name: PropTypes.string,
  surname: PropTypes.string,
};

DeliveryRecipient.defaultProps = {
  name: null,
  surname: null,
};

export default DeliveryRecipient;
