import React from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';

import ExtraServiceCheckboxRow from '../../Presentational/ExtraServiceCheckboxRow/ExtraServiceCheckboxRow';
import ToggleAllCheckboxesButton from '../../Presentational/ToggleAllCheckboxesButton/ToggleAllCheckboxesButton';

import css from './InteractiveExtraServiceList.module.scss';

type Props = {
  agentAbbr: string;
};

const InteractiveExtraServiceList: React.FC<Props> = ({ agentAbbr }) => {
  const { t } = useTranslation(['settings', 'status']);
  const { values, setFieldValue, handleChange } = useFormikContext<any>();

  const toggleAllCheckboxesChecked = () => {
    const { extraServices } = values;
    const allChecked = !extraServices.some((service: any) => service.checked === false);

    setFieldValue(
      'extraServices',
      extraServices.map((extraService: any) => ({
        ...extraService,
        checked: !allChecked,
      })),
    );
  };

  return (
    <>
      <div className="setting-header">
        <h4>{t('settings:Agents.ExtraServices.title')}</h4>
      </div>

      <ul className={`${css.listStyle} description`}>
        <li>{t('settings:Agents.ExtraServices.descriptionItem1')}</li>
        <li>{t('settings:Agents.ExtraServices.descriptionItem2')}</li>
      </ul>
      <Row>
        <Col md={4}>
          <ToggleAllCheckboxesButton
            id="extraServiceCheckToggle"
            toggleCheckboxCheck={toggleAllCheckboxesChecked}
            values={values.extraServices.map((extraService: any) => extraService.checked)}
          />
        </Col>
        {values.extraServices.map((val: any, index: number) => (
          <ExtraServiceCheckboxRow
            key={val.id}
            id={val.id}
            name={`extraServices.${index}.checked`}
            label={t([`services:${agentAbbr}.${val.code}`, `services:${val.code}.name`], val.name)}
            checked={values.extraServices[index].checked === true}
            onChange={handleChange}
          />
        ))}
      </Row>
    </>
  );
};

export default InteractiveExtraServiceList;
