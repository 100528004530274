import React from 'react';
import { useTranslation } from 'react-i18next';
import Select, { ValueType } from 'react-select';

import localazyMetadata, { LocalazyLanguage } from '~/types/generated/languages';

import css from './LanguageSwitcher.module.scss';

const { languages } = localazyMetadata;
const languageOptions = [...languages];

const formatOptionLabel = (option: LocalazyLanguage): React.ReactNode => {
  return (
    <div className="d-flex flex-nowrap align-items-center">
      {option.language !== 'cimode' && (
        <img
          alt={`Flag representing language: ${option.name}`}
          src={`/assets/icons/flags/${option.language}.svg`}
          className="select__language-image"
          height={24}
          width={24}
        />
      )}
      <span className="select__language-name">
        {option.localizedName}
      </span>
    </div>
  );
};

type Props = {
  className?: string;
  hideCaret?: boolean;
};

const LanguageSwitcher: React.FC<Props> = ({ className, hideCaret = false }) => {
  const { i18n, ready } = useTranslation();

  const getDefaultValue = () => {
    const language = languages.find((l) => l.language === i18n.language);
    return language || {
      language: 'cs',
      region: '',
      script: '',
      isRtl: false,
      name: 'Czech',
      localizedName: 'Čeština',
      // eslint-disable-next-line no-nested-ternary
      pluralType: (n) => { return (n === 1) ? 'one' : (n >= 2 && n <= 4) ? 'few' : 'other'; },
    };
  };

  const handleChange = (value: ValueType<LocalazyLanguage>) => {
    const selectedOption = Array.isArray(value) ? value[0] : value;

    if (selectedOption) {
      i18n.changeLanguage(selectedOption.language);
    }
  };

  if (!ready) {
    return null;
  }

  return (
    <Select<LocalazyLanguage>
      id="language-selector"
      className={`${css.selectContainer} ${className || undefined} ${hideCaret && css.hideCaret}`.trim()}
      classNamePrefix="select"
      options={languageOptions}
      isSearchable={false}
      defaultValue={getDefaultValue()}
      formatOptionLabel={formatOptionLabel}
      getOptionValue={(option: LocalazyLanguage) => option.name}
      onChange={handleChange}
      menuPlacement="top"
    />
  );
};

export default LanguageSwitcher;
