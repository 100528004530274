// :: Base paths
import basePaths from 'Routes/paths/base.path';

/**
 * @param {TFunction} t
 */
const nav = (t) => {
  const generalSettingsNavLeft = [
    {
      name: `${t('settings:Navigation.Company.info')}`,
      id: 'com_gen_information',
      icon: {
        type: 'foxdeli',
        icon: 'informace',
      },
      path: `${basePaths.settings}/company`,
    },
    {
      name: `${t('settings:Navigation.Company.users')}`,
      id: 'com_gen_users',
      icon: {
        type: 'foxdeli',
        icon: 'uzivatele',
      },
      path: `${basePaths.settings}/users`,
    },
    {
      name: `${t('settings:Navigation.Company.invoices')}`,
      id: 'com_gen_invoices',
      icon: {
        type: 'foxdeli',
        icon: 'faktury',
      },
      path: `${basePaths.settings}/invoices`,
    },
    {
      name: 'API',
      id: 'com_gen_api',
      icon: {
        type: 'foxdeli',
        icon: 'api',
      },
      path: `${basePaths.settings}/api`,
    },
  ];

  const generalSettingsNavRight = [
    {
      name: `${t('settings:Navigation.Eshop.info')}`,
      id: 'shop_gen_information',
      icon: {
        type: 'foxdeli',
        icon: 'informace',
      },
      path: `${basePaths.settings}/information`,
    },
    {
      name: `${t('settings:Navigation.Eshop.agents')}`,
      id: 'shop_gen_agents',
      icon: {
        type: 'foxdeli',
        icon: 'prepravci',
      },
      path: `${basePaths.settings}/agents`,
    },
    {
      name: `${t('settings:Navigation.Eshop.collectionPlaces')}`,
      id: 'shop_gen_pickup_places',
      icon: {
        type: 'foxdeli',
        icon: 'svozova_mista',
      },
      path: `${basePaths.settings}/collection-places`,
    },
    {
      name: `${t('settings:Navigation.Eshop.packages')}`,
      id: 'shop_gen_packages',
      icon: {
        type: 'foxdeli',
        icon: 'velikost_baliku',
      },
      path: `${basePaths.settings}/packages`,
    },
  ];

  const communicationSettingsNavRight = [
    {
      name: `${t('settings:Navigation.Eshop.notifications')}`,
      id: 'shop_comm_emails',
      icon: {
        type: 'foxdeli',
        icon: 'nastaveni_upozorneni',
      },
      path: `${basePaths.settings}/notifications`,
    },
  ];

  const brandingSettingsNavRight = [
    {
      name: `${t('settings:Navigation.Eshop.trackAndTrace')}`,
      id: 'shop_branding_track_and_trace',
      icon: {
        type: 'foxdeli',
        icon: 'sledovani_zasilky',
      },
      path: `${basePaths.settings}/branding/track-and-trace`,
    },
    {
      name: `${t('settings:Navigation.Eshop.mailing')}`,
      id: 'shop_branding_mailing',
      icon: {
        type: 'foxdeli',
        icon: 'emaily_sms',
      },
      path: `${basePaths.settings}/branding/mailing`,
    },
    {
      name: `${t('settings:Navigation.Eshop.marketingCampaigns')}`,
      id: 'shop_branding_marketing_campaigns',
      icon: {
        type: 'foxdeli',
        icon: 'marketingove_kampane',
      },
      path: `${basePaths.settings}/branding/marketing-campaigns`,
    },
  ];

  return {
    generalLeft: generalSettingsNavLeft,
    generalRight: generalSettingsNavRight,
    communicationRight: communicationSettingsNavRight,
    brandingRight: brandingSettingsNavRight,
  };
};

export default nav;
