import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Card, CardBody, CardHeader, Nav, NavItem, Row, Col, Container, Spinner } from 'reactstrap';

import Icon from 'Components/Presentational/Icon/Icon';
import UpsellState from '~/components/UpsellState/UpsellState';
import { formatBackendAssetUrl } from '../../shared/utilities';

const prepareItems = (items) => items.map((item, index) => {
  let i = 0;

  const subItems = item.items.map((subItem) => {
    i += 1;
    return (
      <NavItem key={i}>
        <NavLink to={subItem.path} activeStyle={{ fontWeight: '500' }} className="none" key={i}>
          <Icon
            type={subItem.icon.type}
            icon={subItem.icon.icon}
            className={subItem.icon.className}
            size={subItem.icon.size}
            style={subItem.icon.style}
          />
          &nbsp;
          {subItem.name}
        </NavLink>
      </NavItem>
    );
  });

  return (
    <div key={item.name}>
      <div className={`small-title${index === 0 ? ' first' : ''}`}>{item.name}</div>
      <Nav vertical>
        {subItems}
      </Nav>
    </div>
  );
});

const settingsRightBlock = (props) => {
  const {
    children,
    isOpened,
    customHeader,
    upsellState,
    upsellDescription,
    main: {
      left: mainLeft,
      right: mainRight,
    },
    header: {
      title,
      subTitle,
      eshopLogo,
    },
  } = props;

  let content = children;
  let header = '';

  if (isOpened) {
    header = (
      <div>
        {customHeader !== null
          ? (
            <CardHeader className="bg-white card-header-custom">
              <Container>
                {customHeader}
              </Container>
            </CardHeader>
          ) : ''}
      </div>
    );
  }

  if (!isOpened) {
    content = (
      <Row>
        <Col xs={6}>
          {prepareItems(mainLeft)}
        </Col>
        <Col xs={6}>
          {prepareItems(mainRight)}
        </Col>
      </Row>
    );

    header = (
      <CardHeader>
        { !eshopLogo ? (
          <>
            <div>
              <div className="small-title first">{subTitle}</div>
            </div>
            <div className="mt-1">
              <div className="">
                <h3 className="text-truncate">{title || <Spinner />}</h3>
              </div>
            </div>
          </>
        ) : (
          <>
            <img className="mt-1" style={{ marginBottom: '.1rem' }} width="100px" src={formatBackendAssetUrl(eshopLogo)} alt={subTitle} />
          </>
        )}
        <hr />
      </CardHeader>
    );
  }

  return (
    <>
      {upsellState && <UpsellState description={upsellDescription} />}

      <Card>
        {header}
        <CardBody>
          {content}
        </CardBody>
      </Card>
    </>
  );
};

settingsRightBlock.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
  isOpened: PropTypes.bool,
  header: PropTypes.shape({
    title: PropTypes.string,
    subTitle: PropTypes.string,
    button: PropTypes.shape({
      text: PropTypes.string.isRequired,
      class: PropTypes.shape({
        color: PropTypes.string,
        size: PropTypes.string,
        outline: PropTypes.bool,
      }).isRequired,
      icon: PropTypes.shape({
        class: PropTypes.array.isRequired,
        size: PropTypes.string,
      }),
      path: PropTypes.string.isRequired,
    }),
    percentDone: PropTypes.number,
  }).isRequired,
  customHeader: PropTypes.element,
  main: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        items: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired,
            id: PropTypes.string.isRequired,
            path: PropTypes.string.isRequired,
            icon: PropTypes.shape({
              icon: PropTypes.string.isRequired,
              size: PropTypes.string,
            }),
          }),
        ),
      }),
    ),
  ).isRequired,
  upsellState: PropTypes.bool,
  upsellDescription: PropTypes.string,
};

settingsRightBlock.defaultProps = {
  isOpened: false,
  customHeader: null,
  children: null,
  upsellState: false,
};

export default settingsRightBlock;
