import PropTypes from 'prop-types';

export default class UserReducer {
  static state() {
    return PropTypes.shape({
      email: PropTypes.oneOf([PropTypes.null, PropTypes.string]),
      id: PropTypes.oneOf([PropTypes.null, PropTypes.string]),
      login: PropTypes.oneOf([PropTypes.null, PropTypes.string]),
      name: PropTypes.oneOf([PropTypes.null, PropTypes.string]),
      permissions: PropTypes.oneOf([PropTypes.null, PropTypes.Array]),
      phone: PropTypes.oneOf([PropTypes.null, PropTypes.string]),
      surname: PropTypes.oneOf([PropTypes.null, PropTypes.string]),
    });
  }
}
