const basePaths = {
  oauth: '/oauth',
  deliveries: '/deliveries',
  addressBook: '/contacts',
  settings: '/settings',
  auth: '/auth',
  batches: '/batches',
  protocols: '/protocols',
};

export default basePaths;
