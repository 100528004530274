import React from 'react';
import { Query } from 'react-apollo';

import ApolloQueryTypes from 'Types/apollo/ApolloQueryTypes';
import { getGraphQLAuthenticationContext } from 'Shared/utilities';
import graphqlStorage from 'Services/GraphqlStorage';

export const CustomQuery = ({
  query,
  variables,
  context = true,
  onCompleted,
  onError,
  notifyOnNetworkStatusChange = false,
  skip,
  children,
}) => (
  <Query
    query={query}
    variables={variables}
    onCompleted={onCompleted}
    onError={onError}
    context={context && getGraphQLAuthenticationContext()}
    notifyOnNetworkStatusChange={notifyOnNetworkStatusChange}
    skip={skip}
  >
    {children}
  </Query>
);

export const customManualQuery = ({
  client = graphqlStorage.client,
  query,
  variables = {},
}) => client.query({
  query,
  variables,
  context: getGraphQLAuthenticationContext(),
});

CustomQuery.defaultProps = {
  variables: null,
  onError: null,
  onCompleted: null,
  context: true,
  skip: false,
};

CustomQuery.propTypes = { ...ApolloQueryTypes.query() };

export default CustomQuery;
