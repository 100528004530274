import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import './SimpleModal.scss';

export type ModalData = {
  title: string;
  description: string;
  cancelButton: string;
  submitButton: string;
  onActionItem: () => void;
  submitButtonColor: string;
}

type Props = {
  title: string;
  description?: string;
  cancelButton?: string;
  submitButton?: string;

  isOpen: boolean;
  toggle: () => void;
  onActionItem?: () => void;
  submitButtonColor?: string;

  size?: 'sm' | 'md' | 'lg' | 'xl' | 'fullscreen';
  children?: React.ReactNode | JSX.Element;
};

const SimpleModal: React.FC<Props> = ({
  title,
  description,
  cancelButton,
  submitButton,
  isOpen,
  toggle,
  onActionItem,
  submitButtonColor,
  size = 'md',
  children,
}) => {
  return (
    <Modal size={size} className="simple-modal" isOpen={isOpen} toggle={toggle} modalTransition={{ timeout: 150 }}>
      <ModalHeader toggle={toggle}>
        {title}
      </ModalHeader>

      <ModalBody className="pt-0">
        {children || description}
      </ModalBody>

      <ModalFooter>
        {submitButton && (
          <Button
            className="mr-2"
            color={submitButtonColor}
            data-test="modal-submit"
            onClick={() => {
              if (onActionItem) onActionItem();
              toggle();
            }}
          >
            {submitButton}
          </Button>
        )}

        {cancelButton && (
          <Button
            className="mr-2"
            color="primary"
            outline
            onClick={() => toggle()}
            data-test="modal-cancel"
          >
            {cancelButton}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default SimpleModal;
