import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, CardBody, Col, Form, FormGroup, Row, Spinner } from 'reactstrap';
import produce from 'immer';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import MainBody from 'Components/Presentational/Layout/MainBody/MainBody';
import Loader from 'Containers/Loader/Loader';
import { formatBackendAssetUrl, getReactSelectValue, scrollToElement, toast } from 'Shared/utilities';
import DeliveryDataSource from 'Models/delivery/DeliveryDataSource';
import CollectionPlaceDataSource from 'Models/collectionPlace/CollectionPlace';
import SubjectDataSource from 'Models/subject/SubjectDataSource';
import basePaths from 'Routes/paths/base.path';
import ContactsModal from 'Components/Presentational/ContactsModal/ContactsModal';
import DeliveryActionCreators from 'StoreActions/DeliveryActionCreators';
import { AGENTS_WITH_COLLECTION_PLACE_POST_CODE } from 'Constants/app.const';

import DeliveryContext from './DeliveryContext';
import AGENT_BY_ID from './agentById.gql';
import DELIVERY_BY_ID from './deliveryById.gql';
import DELIVERY_AGENT_BY_ID from './deliveryAgentById.gql';
import DELIVERY_TYPE_BY_ID from './deliveryTypeById.gql';

import promiseMe from '~/services/promiseMe';
import FormikScroll from '~/components/FormikScroll/FormikScroll';
import SelectField from './SelectField/SelectField';
import Sender from './Sender/Sender';
import Recipient from './Recipient/Recipient';
import Details from './Details/Details';
import ExtraServices from './ExtraServices/ExtraServices';
import formValidationSchema from './validationSchema';

import css from './DeliveriesNew.module.scss';

class DeliveriesNew extends Component {
  static convertSubjectTypeForApi(type) {
    let formattedSubjectType = '';

    switch (type) {
      case 'sender':
        formattedSubjectType = 'address';
        break;
      case 'recipient':
        formattedSubjectType = 'address';
        break;
      case 'collection_place_contact':
        formattedSubjectType = 'collectionPlace';
        break;
      case 'contact':
        formattedSubjectType = 'contact';
        break;
      case 'pickupPlace':
        formattedSubjectType = 'pickUpPlace';
        break;
      default:
        console.error(`incorrect subject type ${type} was passed`);
        break;
    }

    return formattedSubjectType;
  }

  constructor(props) {
    super(props);

    const { match } = props;

    this.delivery = new DeliveryDataSource();
    this.collectionPlace = new CollectionPlaceDataSource();
    this.subject = new SubjectDataSource();

    this.state = {
      isLoading: true,
      isFormBodyLoading: false,
      isFullFormRendered: false,
      subjectModal: {
        isVisible: false,
        activeSubjectType: null,
        sender: { isDisabled: false },
        recipient: { isDisabled: false },
      },
      sender: { pickupType: 'collectionPlace' },
      recipient: { pickupType: 'address' },
      $delivery: null,
      $agents: [],
      $currentAgent: null,
      $currentDeliveryType: null,
      data: {},
      stayAfterSubmit: false,
      requiresCollectionPlacePostCode: false,
    };

    this.isEditingDelivery = match.path.includes('edit');
    this.isDuplicatingDelivery = match.params.deliveryDuplicationId >= 0;
    this.isCreatingDelivery = !this.isEditingDelivery;
  }

  async componentDidMount() {
    await this.fetchData();

    this.validateForm();
  }

  handleSenderPickupTypeChange(value) {
    const state = produce(this.state, (draft) => {
      if (draft.subjectModal.sender.isDisabled && value === 'address') {
        draft.data.senderType = 'contact';
      } else {
        switch (value) {
          case 'address':
            draft.data.senderType = 'sender';
            break;
          case 'collectionPlace':
            draft.data.senderType = 'collection_place_contact';
            break;
          case 'pickUpPlace':
            draft.data.senderType = 'address';
            break;
          default:
            console.error(`incorrect type ${value}`);
        }
      }

      draft.sender.pickupType = value;
    });

    this.setState(state);
  }

  handleRecipientPickupTypeChange(value) {
    const state = produce(this.state, (draft) => {
      if (draft.subjectModal.recipient.isDisabled && value === 'address') {
        draft.data.recipientType = 'contact';
      } else {
        switch (value) {
          case 'address':
            draft.data.recipientType = 'recipient';
            break;
          case 'collectionPlace':
            draft.data.recipientType = 'collection_place_contact';
            break;
          case 'pickupPlace':
            draft.data.recipientType = 'pickupPlace';
            break;
          default:
            console.error(`incorrect type ${value}`);
        }
      }

      draft.recipient.pickupType = value;
    });

    this.setState(state);
  }

  getFormInitialValues() {
    const { match: { params: { deliveryDuplicationId } } } = this.props;
    const { $currentAgent } = this.state;

    if (deliveryDuplicationId && $currentAgent.id) {
      return this.formatDuplicationFormInitialValues();
    }

    return this.formatFormInitialValues();
  }

  handleSubmit = async (values, { resetForm }) => {
    const { t } = this.props;
    const {
      data: {
        senderType,
        recipientType,
      },
      sender: { pickupType: senderPickupType },
      recipient: { pickupType: recipientPickupType },
      subjectModal,
      data,
      requiresCollectionPlacePostCode,
    } = this.state;

    let recipientObj = null;

    if (data.recipientType === 'recipient') {
      recipientObj = {
        contact: {
          name: values.recipientAddressName,
          surname: values.recipientAddressSurname,
          email: values.recipientEmail,
          phone: values.recipientPhone,
          companyName: values.recipientCompanyName,
        },
        address: {
          city: values.recipientAddressCity,
          country: getReactSelectValue(values.recipientState),
          zip: values.recipientAddressZip,
          street: values.recipientAddressStreet,
        },
      };
    }

    const getRecipientContact = () => ({
      name: values.recipientAddressName,
      surname: values.recipientAddressSurname,
      email: values.recipientEmail,
      phone: values.recipientPhone,
      companyName: values.recipientCompanyName,
    });

    const packages = values.packages.map((item) => {
      const weight = item.weight || null;
      const length = item.length || null;
      const width = item.width || null;
      const height = item.height || null;
      const containerCode = item.containerCode || null;
      const containerItems = item.containerItems || null;

      return {
        weight,
        length,
        width,
        height,
        containerCode,
        containerItems,
      };
    });

    const result = {
      packages,
      value: Number(values.packageValue),
      valueCurrency: getReactSelectValue(values.valueCurrency),
      cashOnDelivery: values.packageCod ? Number(values.packageCod) : null,
      cashOnDeliveryCurrency: getReactSelectValue(values.cashOnDeliveryCurrency),
      variableSymbol: values.packageVariableSymbol,
      deliveryId: null,
      externalId: values.packageExternalId,
      ticketNote: values.packageLabelText,
      senderType: DeliveriesNew.convertSubjectTypeForApi(senderType),
      sender: data.senderType === 'sender' ? {
        contact: {
          name: values.senderAddressName,
          surname: values.senderAddressSurname,
          email: values.senderEmail,
          phone: values.senderPhone,
          companyName: values.senderCompanyName,
        },
        address: {
          city: values.senderAddressCity,
          country: getReactSelectValue(values.senderState),
          zip: values.senderAddressZip,
          street: values.senderAddressStreet,
        },
      } : null,
      senderAddressBook: data.senderType === 'contact' ? subjectModal.sender.id : null,
      senderCollectionPlace: senderPickupType === 'collectionPlace' ? getReactSelectValue(
        values.senderCollectionPlace,
      ) : null,
      recipientType: DeliveriesNew.convertSubjectTypeForApi(recipientType),
      recipient: recipientObj,
      recipientContact: (recipientPickupType === 'pickupPlace') ? getRecipientContact() : null,
      recipientAddressBook: data.recipientType === 'contact' ? subjectModal.recipient.id : null,
      recipientCollectionPlace: recipientPickupType === 'collectionPlace' ? getReactSelectValue(
        values.recipientCollectionPlace,
      ) : null,
      recipientPickupPlace: recipientPickupType === 'pickupPlace' ? values.recipientPickupPlace.id : null,
      deliveryType: getReactSelectValue(values.agentService),
      agent: getReactSelectValue(values.agent),
      extraServices: [],
    };

    if (requiresCollectionPlacePostCode && senderPickupType === 'collectionPlace') {
      result.deliveryMetaData = { postCode: values.senderCollectionPlacePostCode ?? null };
    }

    const {
      $currentAgent,
      recipient,
      stayAfterSubmit,
    } = this.state;

    const selectedCollectionPlace = $currentAgent
      .collectionPlaces
      .find((collectionPlace) => collectionPlace.id === values.recipientCollectionPlace);

    $currentAgent.extraServices.forEach((service) => {
      if (values[service.code] === true) {
        const serviceArguments = Array.concat(service.arguments);
        let formattedArguments = [];
        const email = recipient.pickupType === 'collectionPlace' ? selectedCollectionPlace.contact.email : values.recipientEmail;
        const phone = recipient.pickupType === 'collectionPlace' ? selectedCollectionPlace.contact.phone : values.recipientPhone;

        serviceArguments.forEach((argument) => {
          const formattedArgument = {};
          formattedArgument.name = argument.type;

          if (argument.type === 'email') {
            formattedArgument.value = email;
          } else if (argument.type === 'phone') {
            formattedArgument.value = phone;
          } else {
            formattedArgument.value = '';
          }

          formattedArguments.push(formattedArgument);
        });

        if (serviceArguments.length === 0) {
          formattedArguments = null;
        }

        result.extraServices.push({
          code: service.code,
          arguments: formattedArguments,
        });
      }
    });

    const { match } = this.props;
    const successMessages = [];
    const errorMessages = [];
    let res;

    if (this.isCreatingDelivery) {
      res = await this.delivery.createDelivery(result, 'success errors delivery { id }');
      res = res.data.createDelivery;
    } else if (this.isEditingDelivery) {
      result.deliveryId = undefined;
      result.id = match.params.deliveryDuplicationId;
      result.referenceId = values.referenceId;

      res = await this.delivery.updateDelivery(result, 'success errors delivery { id }');
      res = res.data.updateDelivery;
    }

    if (!res.success && res.errors.length > 0) {
      Object.keys(res.errors).forEach((key) => {
        errorMessages.push(res.errors[key]);
      });
    } else {
      successMessages.push(this.isEditingDelivery
        ? t('deliveries:NewDelivery.Banner.editDeliverySuccess')
        : t('deliveries:NewDelivery.Banner.newDeliverySuccess'));
    }

    if (
      (
        (values.senderSaveToAddressBook && result.sender && senderPickupType === 'address')
        || (values.recipientSaveToAddressBook && result.recipient && recipientPickupType === 'address')
      )
      && ((result.senderType === 'address') || (result.recipientType === 'address'))
    ) {
      let subject;

      if (values.senderSaveToAddressBook) {
        subject = result.sender;
      } else if (values.recipientSaveToAddressBook) {
        subject = result.recipient;
      }

      try {
        const { contact, address } = subject;
        await this.subject.createSubject(contact, address);
      } catch (e) {
        errorMessages.push(e.graphQLErrors[0].message);
      }
    }

    if (errorMessages.length === 0) {
      if (stayAfterSubmit) {
        const newInitialFormValues = {
          ...this.formatFormInitialValues(),
          agent: values.agent,
          agentService: values.agentService,
        };
        resetForm({ values: newInitialFormValues });
        scrollToElement('#root');
      } else {
        const {
          history,
          setFilterAndFlags,
          setPage,
        } = this.props;

        if (this.isCreatingDelivery) {
          history.push(basePaths.deliveries);
          setFilterAndFlags({ filter: 'inProgress', flags: null });
          setPage(1);
        } else if (this.isEditingDelivery) {
          history.goBack();
        }
      }
    }

    successMessages.forEach((successMsg) => {
      toast.success(successMsg);
    });

    errorMessages.forEach((errorMsg) => {
      toast.error(errorMsg);
    });
  };

  handleSubjectSubmit = (subject, setFieldValue) => {
    const { subjectModal: { activeSubjectType: subjectType } } = this.state;

    setFieldValue(`${subjectType}AddressName`, subject.contact.name);
    setFieldValue(`${subjectType}AddressSurname`, subject.contact.surname);
    setFieldValue(`${subjectType}Phone`, subject.contact.phone);
    setFieldValue(`${subjectType}Email`, subject.contact.email);
    setFieldValue(`${subjectType}CompanyName`, subject.contact.companyName);

    setFieldValue(`${subjectType}AddressCity`, subject.address.city);
    setFieldValue(`${subjectType}AddressZip`, subject.address.zip);
    setFieldValue(`${subjectType}State`, subject.address.country);
    setFieldValue(`${subjectType}AddressStreet`, subject.address.street);

    const state = produce(this.state, (draft) => {
      const draftState = draft;

      draftState.subjectModal.isVisible = false;
      draftState.subjectModal.activeSubjectType = null;
      draftState.subjectModal[subjectType].isDisabled = true;
      draftState.subjectModal[subjectType].id = subject.id;
      draftState.data[`${this.lastModalSubject}Type`] = 'contact';
    });

    this.setState(state);
  };

  Form = ({
    handleSubmit,
    isSubmitting,
    values,
    setTouched,
    setFieldValue,
    validateForm,
    validateField,
    isValid,
    submitForm,
    resetForm,
  }) => {
    const { t } = this.props;
    const { isFullFormRendered } = this.state;

    if (!this.validateForm) {
      this.setFieldValue = setFieldValue;
      this.validateForm = validateForm;
      this.validateField = validateField;
      this.setTouched = setTouched;
    }

    const getAgentOptions = () => {
      const { $agents } = this.state;

      return $agents?.map(({ id, name }) => (
        { value: id, label: name }
      ));
    };

    const getDeliveryTypeOptions = () => {
      const { $currentAgent } = this.state;

      return $currentAgent?.deliveryTypes?.map(({ id, name }) => (
        { value: id, label: name }
      ));
    };

    const handleBeforeSubmit = (event) => {
      if (!isValid) {
        this.setState({ stayAfterSubmit: false });
      }

      handleSubmit(event);
    };

    const handleAgentChange = async ({ value }) => {
      resetForm();

      this.setState({
        isFullFormRendered: false,
        $currentDeliveryType: null,
      });

      if (!value) {
        this.setState({ $currentAgent: null });
        return;
      }

      this.setState({ isFormBodyLoading: true });

      const { data, error } = await promiseMe(
        this.delivery.query({ agentById: AGENT_BY_ID }, { id: value }),
      );

      if (error || !data) {
        toast.error(t('deliveries:NewDelivery.Banner.dataLoadError'));
        this.setState({
          isFormBodyLoading: false,
          $currentAgent: null,
        });

        return;
      }

      this.setState({
        isFormBodyLoading: false,
        $currentAgent: data,
        requiresCollectionPlacePostCode: AGENTS_WITH_COLLECTION_PLACE_POST_CODE.includes(value),
      });
    };

    const handleDeliveryTypeChange = async ({ value }) => {
      this.setState({ isFullFormRendered: false });

      if (!value) {
        this.setState({ $currentDeliveryType: null });

        return;
      }

      const { $currentAgent } = this.state;

      this.setState({ isFormBodyLoading: true });

      const { data, error } = await promiseMe(
        this.delivery.query({ deliveryTypeById: DELIVERY_TYPE_BY_ID }, { id: value }),
      );

      if (error || !data) {
        toast.error(t('deliveries:NewDelivery.Banner.dataLoadError'));
        this.setState({
          isFormBodyLoading: false,
          selectedDeliveryType: null,
        });

        return;
      }

      const countryCodesFilter = data?.pickupPlaceType?.countryCodesFilter;

      // TODO: move out of here
      $currentAgent.extraServices.forEach((service) => {
        setFieldValue(service.code, service.selectedByDefault, false);
      });

      const state = produce(this.state, (draft) => {
        draft.isFormBodyLoading = false;
        draft.isFullFormRendered = true;
        draft.$currentDeliveryType = data;
        draft.sender.pickupType = $currentAgent.collectionPlaces?.length ? 'collectionPlace' : 'address';
        draft.recipient.pickupType = draft.$currentDeliveryType.pickupPlaceType ? 'pickupPlace' : 'address';

        if ($currentAgent.collectionPlaces.length > 0) {
          setFieldValue('senderCollectionPlace', $currentAgent.collectionPlaces[0].id);

          setFieldValue(
            'senderCollectionPlacePostCode',
            $currentAgent.collectionPlaces[0]?.collectionPlaceTypeByAgent?.metaData?.postCode ?? '',
          );

          setFieldValue('recipientCollectionPlace', $currentAgent.collectionPlaces[0].id);
          draft.data.senderType = 'collection_place_contact';
        } else {
          draft.data.senderType = 'sender';
        }

        if (countryCodesFilter?.length) {
          setFieldValue('recipientPickupPlaceCountry', countryCodesFilter[0]);
        } else {
          setFieldValue('recipientPickupPlaceCountry', '');
        }
      });

      this.setState(state, () => {
        // TODO: should not call a hander directly
        this.handleRecipientPickupTypeChange(state.recipient.pickupType);
      });
    };

    return (
      <Form className={css.newDelivery} onSubmit={handleBeforeSubmit}>
        <FormikScroll />
        <Card className="shadow border-0 mb-4">
          <CardBody>
            <Row>
              <Col md="4">
                <h4>{t('deliveries:NewDelivery.agentTitle')}</h4>
              </Col>
              <Col md="6">
                <FormGroup>
                  <SelectField
                    label={t('deliveries:NewDelivery.AgentSelect.label')}
                    name="agent"
                    placeholder={t('deliveries:NewDelivery.AgentSelect.placeholder')}
                    options={getAgentOptions()}
                    onChange={handleAgentChange}
                    disabled={
                      this.isEditingDelivery || this.isDuplicatingDelivery
                    }
                  />
                </FormGroup>

                <FormGroup>
                  <SelectField
                    label={t('deliveries:NewDelivery.AgentService.label')}
                    name="agentService"
                    placeholder={t('deliveries:NewDelivery.AgentService.placeholder')}
                    options={getDeliveryTypeOptions()}
                    onChange={handleDeliveryTypeChange}
                    disabled={!values.agent || this.isEditingDelivery || this.isDuplicatingDelivery}
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>

        {isFullFormRendered && this.renderFormBody({
          isSubmitting,
          values,
          setFieldValue,
          submitForm,
        })}
      </Form>
    );
  };

  toggleContacts = (subjectType) => {
    const { subjectModal: subjectModalState } = this.state;
    this.lastModalSubject = subjectType;

    if (subjectType && subjectModalState[subjectType].isDisabled) {
      const state = produce(this.state, (draft) => {
        const draftObj = draft;
        draftObj.subjectModal[subjectType].isDisabled = false;
        draftObj.data[`${subjectType}Type`] = subjectType;
      });

      return this.setState(state);
    }

    const state = produce(this.state, (draft) => {
      if (subjectType) {
        draft.subjectModal.activeSubjectType = subjectType;
      } else {
        draft.subjectModal.activeSubjectType = null;
      }

      draft.subjectModal.isVisible = !draft.subjectModal.isVisible;
    });

    return this.setState(state);
  };

  renderFormBody = ({
    isSubmitting,
    setFieldValue,
    values,
    submitForm,
  }) => {
    const {
      $currentAgent,
      $currentDeliveryType,
      sender: { pickupType: senderPickupType },
      subjectModal: {
        sender: { isDisabled: isSenderDisabled },
        recipient: { isDisabled: isRecipientDisabled },
        isVisible: isSubjectModalVisible,
      },
      recipient: { pickupType: recipientPickupType },
      requiresCollectionPlacePostCode,
    } = this.state;

    const getCollectionPlaceOptions = () => {
      if (!$currentAgent?.collectionPlaces?.length) {
        return [];
      }

      return $currentAgent.collectionPlaces.map((item) => (
        { value: item.id, label: item.name }
      ));
    };

    const getPickupPlaceOptions = () => {
      if (!$currentDeliveryType?.pickupPlaceType?.pickupPlaces) return [];

      return $currentDeliveryType.pickupPlaceType.pickupPlaces.map((item) => ({
        value: item.id,
        label: item.label,
      }));
    };

    const hasPickupPlaces = () => !!$currentDeliveryType?.pickupPlaceType;
    const hasCollectionPlaces = () => !!$currentAgent?.collectionPlaces?.length;
    const agentLogoUrl = formatBackendAssetUrl($currentAgent?.logo?.url);
    const { t } = this.props;

    return (
      <>
        <Sender
          pickupTypeButtons={{ collectionPlace: hasCollectionPlaces() }}
          onPickupTypeChange={
            (pickupType) => {
              this.handleSenderPickupTypeChange(pickupType);
            }
          }
          pickupType={senderPickupType}
          disabled={isSenderDisabled}
          toggleContacts={() => this.toggleContacts('sender')}
          collectionPlaceOptions={getCollectionPlaceOptions()}
          collectionPlaces={$currentAgent?.collectionPlaces ?? []}
          requiresCollectionPlacePostCode={requiresCollectionPlacePostCode}
        />

        <Recipient
          pickupTypeButtons={{
            address: !hasPickupPlaces(),
            pickupPlace: hasPickupPlaces(),
            collectionPlace: (
              hasPickupPlaces() || hasCollectionPlaces() // TODO: correct?
            ),
          }}
          onPickupTypeChange={
            (pickupType) => {
              this.handleRecipientPickupTypeChange(pickupType);
            }
          }
          pickupType={recipientPickupType}
          pickupPlaceTypeId={$currentDeliveryType.pickupPlaceType?.id}
          defaultPickupPlace={values.recipientPickupPlace}
          disabled={isRecipientDisabled}
          toggleContacts={() => this.toggleContacts('recipient')}
          collectionPlaces={getCollectionPlaceOptions()}
          pickupPlaces={getPickupPlaceOptions()}
          countryCodesFilter={$currentDeliveryType.pickupPlaceType?.countryCodesFilter}
        />

        <Details />

        <Card className="shadow border-0 mb-5">
          <CardBody>
            <Row>
              <Col md="4">
                <h4>{t('deliveries:NewDelivery.addonsTitle')}</h4>
                <img
                  alt="Agent logo"
                  src={agentLogoUrl}
                  style={{ maxWidth: '120px' }}
                />
              </Col>
              <Col md="8">
                <ExtraServices
                  services={$currentAgent.extraServices}
                  collectionPlaces={$currentAgent.collectionPlaces}
                  recipientCollectionPlace={values.recipientCollectionPlace}
                  recipientPickupType={recipientPickupType}
                  agentAbbr={$currentAgent.abbr}
                />
              </Col>
            </Row>

            <Row className="pt-5">
              <Col md="4" />
              <Col md="6">
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                >
                  {isSubmitting && <Spinner size="sm" />}
                  &nbsp;
                  {t('deliveries:NewDelivery.saveDeliveryButton')}
                </Button>
                <Button
                  type="button"
                  onClick={() => {
                    this.setState({ stayAfterSubmit: true }, () => {
                      submitForm();
                    });
                  }}
                  color="secondary"
                  className="ml-3"
                  disabled={isSubmitting}
                >
                  {t('deliveries:NewDelivery.saveAndAddDeliveryButton')}
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <ContactsModal
          onCancel={() => this.toggleContacts()}
          onPrompt={(subject) => this.handleSubjectSubmit(subject, setFieldValue)}
          toggle={() => this.toggleContacts()}
          isOpen={isSubjectModalVisible}
        />
      </>
    );
  };

  async fetchData() {
    const { t } = this.props;

    const { data: agentsData } = await promiseMe(
      this.delivery.agents('id name deliveryTypes(onlyActive: true) { id name pickupPlaceType { name } }')
        .catch(() => {
          toast.error(t('deliveries:NewDelivery.Banner.dataLoadError'));
        }),
    );

    const { match: { params: { deliveryDuplicationId } } } = this.props;
    const { recipient: recipientState, subjectModal, sender: senderState } = this.state;

    let $delivery = null;
    let $currentAgent = null;
    let $currentDeliveryType = null;
    let isFullFormRendered = false;
    let senderType = 'collection_place_contact';
    let recipientType = 'recipient';
    const recipient = recipientState;
    const sender = senderState;
    const id = deliveryDuplicationId;

    if (id) {
      const { data: deliveryAgentData } = await promiseMe(
        this.delivery.query({ deliveryById: DELIVERY_AGENT_BY_ID }, { id })
          .catch(() => {
            toast.error(t('deliveries:NewDelivery.Banner.dataLoadError'));
          }),
      );

      const { data: deliveryData } = await promiseMe(
        this.delivery.query(
          { deliveryById: DELIVERY_BY_ID },
          {
            id,
            agentId: deliveryAgentData?.type?.agent?.id,
          },
        ).catch(() => {
          toast.error(t('deliveries:NewDelivery.Banner.dataLoadError'));
        }),
      );

      $delivery = deliveryData;

      $currentAgent = deliveryData.type.agent;

      const { data: deliveryTypeData } = await promiseMe(
        this.delivery.query(
          { deliveryTypeById: DELIVERY_TYPE_BY_ID },
          { id: deliveryData.type.id },
        ).catch(() => {
          toast.error(t('deliveries:NewDelivery.Banner.dataLoadError'));
        }),
      );

      $currentDeliveryType = deliveryTypeData;
      isFullFormRendered = true;
      senderType = deliveryData.sender.type;
      recipientType = deliveryData.recipient.type;

      if (senderType === 'contact' || senderType === 'sender') {
        sender.pickupType = 'address';
      } else {
        sender.pickupType = 'collectionPlace';
      }

      if (recipientType === 'contact' || recipientType === 'recipient') {
        recipient.pickupType = 'address';
      } else {
        recipient.pickupType = 'collectionPlace';
      }

      if (deliveryData.recipient.pickUpPlace !== null) {
        recipient.pickupType = 'pickupPlace';
        recipientType = 'pickupPlace';
      }

      if (senderType === 'contact') {
        subjectModal.sender.id = deliveryData.sender.id;
        subjectModal.sender.isDisabled = true;
      }

      if (recipientType === 'contact') {
        subjectModal.recipient.id = deliveryData.recipient.id;
        subjectModal.recipient.isDisabled = true;
      }
    }

    this.setState({
      recipient,
      data: {
        senderType,
        recipientType,
        subjectModal,
      },
      $delivery,
      $agents: agentsData?.agents,
      $currentAgent,
      $currentDeliveryType,
      requiresCollectionPlacePostCode: AGENTS_WITH_COLLECTION_PLACE_POST_CODE.includes($currentAgent?.id),
      isLoading: false,
      isFullFormRendered,
    });
  }

  formatDuplicationFormInitialValues() {
    const {
      $delivery,
      $currentAgent,
      $currentAgent: { extraServices: dataExtraServices },
      recipientIsCompany: dataRecipientIsCompany,
      requiresCollectionPlacePostCode,
    } = this.state;

    const delivery = $delivery;
    const extraServices = ($currentAgent && dataExtraServices) || [];

    const values = {
      agent: delivery.type.agent.id,
      agentService: delivery.type.id,
      externalId: delivery.externalId,
      referenceId: delivery.referenceId,

      senderCompanyName: delivery.sender.contact.companyName,
      senderAddressName: delivery.sender.contact.name,
      senderAddressSurname: delivery.sender.contact.surname,
      senderAddressStreet: delivery.sender.address.street,
      senderAddressCity: delivery.sender.address.city,
      senderAddressZip: delivery.sender.address.zip,
      senderEmail: delivery.sender.contact.email,
      senderPhone: delivery.sender.contact.phone,
      senderState: delivery.sender.address.country,
      senderCollectionPlace: (delivery.sender.collectionPlace) ? delivery.sender.collectionPlace.id : '',
      senderCollectionPlacePostCode: delivery.metaData?.postCode ?? '',
      senderPickupPlace: (delivery.sender.pickUpPlace) ? delivery.sender.pickUpPlace.id : '',
      senderIsCompany: false,
      senderSaveToAddressBook: false,

      recipientState: delivery.recipient.address.country,
      recipientCompanyName: delivery.recipient.contact.companyName,
      recipientCollectionPlace: (delivery.recipient.collectionPlace) ? delivery.recipient.collectionPlace.id : '',
      recipientPickupPlace: (delivery.recipient.pickUpPlace) ? delivery.recipient.pickUpPlace : '',
      recipientAddressName: delivery.recipient.contact.name,
      recipientAddressSurname: delivery.recipient.contact.surname,
      recipientAddressStreet: delivery.recipient.address.street,
      recipientAddressCity: delivery.recipient.address.city,
      recipientAddressZip: delivery.recipient.address.zip,
      recipientEmail: delivery.recipient.contact.email,
      recipientPhone: delivery.recipient.contact.phone,
      recipientIsCompany: dataRecipientIsCompany,
      recipientSaveToAddressBook: false,

      valueCurrency: delivery.valueCurrency,
      cashOnDeliveryCurrency: delivery.cashOnDeliveryCurrency || 'CZK',
      cashOnDelivery: delivery.cashOnDelivery,
      packageValue: delivery.value,
      packageCod: delivery.cashOnDelivery,
      packageExternalId: delivery.externalId,
      packageVariableSymbol: delivery.variableSymbol,
      packageLabelText: delivery.ticketNote,
      packages: delivery.packages,
    };

    // Try to prefill `delivery.metaData.postCode` with postCode from sender collectionPlaceType`
    if (
      requiresCollectionPlacePostCode
      && (
        values.senderCollectionPlacePostCode === ''
        || values.senderCollectionPlacePostCode === undefined
        || values.senderCollectionPlacePostCode === null
      )
    ) {
      values.senderCollectionPlacePostCode = delivery.sender.collectionPlace?.collectionPlaceTypeByAgent?.metaData?.postCode ?? '';
    }

    extraServices.forEach((service) => {
      let isServiceInDuplicationDelivery = false;

      delivery.extraServices.forEach((duplicationService) => {
        if (duplicationService.code === service.code) {
          isServiceInDuplicationDelivery = true;
        }
      });

      values[service.code] = isServiceInDuplicationDelivery;
    });

    return values;
  }

  formatFormInitialValues() {
    const {
      $currentAgent,
      recipientIsCompany: dataRecipientIsCompany,
    } = this.state;

    let extraServices = [];

    if ($currentAgent) {
      const { extraServices: dataExtraServices } = $currentAgent;

      if (dataExtraServices) {
        extraServices = dataExtraServices;
      }
    }

    const values = {
      agent: '',
      agentService: '',
      senderAddressName: '',
      senderAddressSurname: '',
      senderAddressStreet: '',
      senderAddressCity: '',
      senderAddressZip: '',
      senderCompanyName: '',
      senderEmail: '',
      senderPhone: '',
      senderState: '',
      senderCollectionPlace: '',
      senderCollectionPlacePostCode: '',
      senderPickupPlace: '',

      recipientAddressName: '',
      recipientAddressSurname: '',
      recipientCompanyName: '',
      recipientAddressStreet: '',
      recipientAddressCity: '',
      recipientAddressZip: '',
      recipientEmail: '',
      recipientPhone: '',
      recipientState: '',
      recipientCollectionPlace: '',
      recipientPickupPlace: '',
      recipientPickupPlaceCountry: '',

      valueCurrency: 'CZK',
      cashOnDeliveryCurrency: 'CZK',
      packageValue: '',
      packageCod: '',
      packageExternalId: '',
      packageVariableSymbol: '',
      packageLabelText: '',
      packages: [{
        weight: '',
        width: '',
        length: '',
        height: '',
      }],
      senderIsCompany: false,
      senderSaveToAddressBook: false,
      recipientIsCompany: dataRecipientIsCompany,
      recipientSaveToAddressBook: false,
    };

    extraServices.forEach((service) => {
      values[service.code] = false;
    });

    return values;
  }

  render() {
    const {
      isLoading,
      isFormBodyLoading,
      $currentAgent,
      $currentDeliveryType,
      requiresCollectionPlacePostCode,
    } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const { t } = this.props;
    const title = (
      <>
        <Link to="/deliveries">
          <div className="page-back-btn-overlay">
            <FontAwesomeIcon
              icon={['fas', 'arrow-alt-circle-left']}
              className="text-muted page-back-btn"
            />
          </div>
        </Link>

        {this.isEditingDelivery ? t('deliveries:editDeliveryTitle') : t('deliveries:NewDelivery.title')}
      </>
    );

    const { sender: { pickupType: senderPickupType } } = this.state;
    const { recipient: { pickupType: recipientPickupType } } = this.state;

    return (
      <MainBody
        wrapperHeight="150px"
        title={title}
      >
        <DeliveryContext.Provider
          value={{
            agent: $currentAgent,
            deliveryType: $currentDeliveryType,
          }}
        >
          <Formik
            // enableReinitialize
            initialValues={this.getFormInitialValues()}
            validateOnChange={false}
            validationSchema={formValidationSchema(
              senderPickupType,
              recipientPickupType,
              requiresCollectionPlacePostCode,
              t,
            )}
            component={this.Form}
            onSubmit={this.handleSubmit}
          />
          {isFormBodyLoading && <Loader />}
        </DeliveryContext.Provider>
      </MainBody>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setFilterAndFlags: (filter) => dispatch(DeliveryActionCreators.setFilterAndFlags(filter)),
    setPage: (page) => dispatch(DeliveryActionCreators.setPage(page)),
  };
}

export default connect(null, mapDispatchToProps)(withTranslation(['deliveries'])(DeliveriesNew));
