import gql from 'graphql-tag';

export default class CountryFragments {
  static IndexedDB() {
    return gql`
            fragment CountryInfoForStorage on Country {
                id
                code
                nameCs
                nameEn
            }
        `;
  }
}
