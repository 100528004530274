import { TFunction } from 'i18next';
import basePaths from '#/core/routes/paths/base.path';

export type NavigationItemType = 'link' | 'dropdown';

// Avoiding "NavItem" to prevent collisions with bootstrap's "NavItem" component
export type NavigationItem = {
  name: string;
  type: NavigationItemType;
  tag?: string;
  icon?: {
    type: any;
    icon: string;
    className?: string;
    size?: any;
    style?: any;
  };
}

export type NavigationLink = NavigationItem & {
  type: 'link';
  path: string;
  exact?: boolean;
  dataTestId?: string;
};

type NavigationDivider = {
  type: 'divider';
}

export type NavigationDropdownItem = NavigationLink | NavigationDivider;

export type NavigationDropdown = NavigationItem & {
  type: 'dropdown';
  children: NavigationDropdownItem[];
};

export type Navigation = Array<NavigationLink | NavigationDropdown>;

export type SidebarNavigation = {
  top: Navigation;
  bottom: Navigation;
}

/**
 * TODO: Přidat položku permission: 'NĚCO' a při renderování navbaru kontrolovat jestli je
 * definovaná a ověřit práva přes Access.hasPermissions
 */

const nav = (t: TFunction, hasActiveBranding: boolean) => {
  const sidebarNav: SidebarNavigation = {
    top: [
      {
        name: `${t('common:SidebarNav.deliveries')}`,
        path: basePaths.deliveries,
        icon: {
          type: 'foxdeli',
          icon: 'zasilky',
        },
        type: 'link',
        dataTestId: 'deliveries-menu-link',
      },
      {
        name: `${t('common:SidebarNav.batches')}`,
        icon: {
          type: 'foxdeli',
          icon: 'davky',
        },
        path: `${basePaths.batches}`,
        type: 'link',
        dataTestId: 'batches-menu-link',
      },
      {
        name: `${t('common:SidebarNav.protocols')}`,
        icon: {
          type: 'foxdeli',
          icon: 'protokoly',
        },
        path: `${basePaths.protocols}`,
        type: 'link',
        dataTestId: 'protocols-menu-link',
      },
      {
        name: `${t('common:SidebarNav.addressBook')}`,
        icon: {
          type: 'foxdeli',
          icon: 'adresar',
        },
        path: basePaths.addressBook,
        type: 'link',
        dataTestId: 'address-book-menu-link',
      },
      {
        name: `${t('common:SidebarNav.dashboard')}`,
        icon: {
          type: 'foxdeli',
          icon: 'statistiky',
        },
        path: '/dashboard',
        type: 'link',
        dataTestId: 'dashboard-menu-link',
      },
    ],
    bottom: [
      {
        name: `${t('common:SidebarNav.settings')}`,
        path: basePaths.settings,
        type: 'link',
        icon: {
          type: 'foxdeli',
          icon: 'nastaveni',
        },
        dataTestId: 'settings-menu-link',
      },
    ],
  };

  const topbarNav: Navigation = [
    {
      // TODO: Name is statically defined in Topbar
      name: '',
      type: 'dropdown',
      children: [
        {
          name: `${t('common:DropdownNav.myProfile')}`,
          type: 'link',
          path: `${basePaths.settings}/my-profile`,
          icon: {
            type: 'foxdeli',
            icon: 'profil',
          },
          exact: true,
        },
        {
          name: `${t('common:DropdownNav.logout')}`,
          type: 'link',
          path: `${basePaths.auth}/logout`,
          icon: {
            type: 'foxdeli',
            icon: 'odhlasit_se',
          },
          exact: true,
        },
      ],
    },
  ];

  if (hasActiveBranding) {
    sidebarNav.top.push(
      {
        name: `${t('common:SidebarNav.QuickAccess.trackAndTrace')}`,
        path: `${basePaths.settings}/branding/track-and-trace`,
        type: 'link',
        icon: {
          type: 'foxdeli',
          icon: 'sledovani_zasilky',
        },
        // tag: `${t('common:SidebarNav.QuickAccess.newItem')}`,
        dataTestId: 'track-and-trace-menu-link',
      },
      {
        name: `${t('common:SidebarNav.QuickAccess.emails')}`,
        path: `${basePaths.settings}/branding/mailing`,
        type: 'link',
        icon: {
          type: 'foxdeli',
          icon: 'emaily_sms',
        },
        // tag: `${t('common:SidebarNav.QuickAccess.newItem')}`,
        dataTestId: 'emails-menu-link',
      },
      {
        name: `${t('common:SidebarNav.QuickAccess.marketingCampaigns')}`,
        path: `${basePaths.settings}/branding/marketing-campaigns`,
        type: 'link',
        icon: {
          type: 'foxdeli',
          icon: 'marketingove_kampane',
        },
        // tag: `${t('common:SidebarNav.QuickAccess.newItem')}`,
        dataTestId: 'marketing-campaigns-menu-link',
      },
    );
  }

  return {
    sidebar: sidebarNav,
    topbar: topbarNav,
  };
};

export default nav;
