import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import CloseDeliveryAction from '../CloseDeliveryAction/CloseDeliveryAction';
import TicketPrintAction from '../TicketPrintAction/TicketPrintAction';
import CancelDeliveryAction from '../CancelDeliveryAction/CancelDeliveryAction';

const ActionMenu = ({ checkedDeliveries, refetchDeliveries, cancelDelivery, hideActionBar }) => {
  const { t } = useTranslation(['deliveries']);

  const actions = useMemo(() => {
    const multipleAgentsSelected = checkedDeliveries.some((d) => d.type.agent.id !== checkedDeliveries[0].type.agent.id);

    return {
      closeDeliveryDisabled: checkedDeliveries.some((d) => d.canClose === false),
      cancelDeliveryDisabled: checkedDeliveries.some((d) => d.canCancel === false),
      printLabelDisabled: checkedDeliveries.some((d) => d.canGenerateTicket === false),
      printLabelDisabledReason: multipleAgentsSelected ? t('deliveries:Banner.notPrintableNotSameAgent') : null,
    };
  }, [checkedDeliveries, t]);

  return (
    <>
      <CloseDeliveryAction
        disabled={actions.closeDeliveryDisabled}
        checkedDeliveries={checkedDeliveries}
        onComplete={hideActionBar}
        refetchDeliveries={refetchDeliveries}
      />
      <TicketPrintAction
        disabled={actions.printLabelDisabled}
        disabledReason={actions.printLabelDisabledReason}
        checkedDeliveries={checkedDeliveries}
      />
      <CancelDeliveryAction
        disabled={actions.cancelDeliveryDisabled}
        checkedDeliveries={checkedDeliveries}
        onClick={cancelDelivery}
      />
    </>
  );
};

ActionMenu.propTypes = {
  hideActionBar: PropTypes.func.isRequired,
  cancelDelivery: PropTypes.func.isRequired,
  refetchDeliveries: PropTypes.func.isRequired,
  checkedDeliveries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default ActionMenu;
