const oneOrMoreCheckboxesChecked = (collectionPlaces) => collectionPlaces
  .filter((cp) => cp.checked === true)
  .length > 0;

const externalIdsFilled = (collectionPlaces) => collectionPlaces
  .filter((cp) => cp.checked === true)
  .filter((cp) => cp.externalPlaceId === null || cp.externalPlaceId === undefined || cp.externalPlaceId === '')
  .length === 0;

const postCodesFilled = (collectionPlaces) => collectionPlaces
  .filter((cp) => cp.checked === true)
  .filter((cp) => cp.postCode === null || cp.postCode === undefined || cp.postCode === '')
  .length === 0;

function createCollectionPlaceValidation(isValid, errorMsg) {
  const errorObj = { collectionPlaces: errorMsg };
  let result = {};

  if (!isValid) {
    result = errorObj;
  }

  return result;
}

function createValidationSchema(values, isExternalPlaceIdHidden, isPostCodeHidden, t) {
  const { collectionPlaces } = values;

  const checkboxErrors = createCollectionPlaceValidation(
    oneOrMoreCheckboxesChecked(collectionPlaces),
    t('settings:Agents.CollectionPlaces.required'),
  );

  const externalPlaceIdErrors = !isExternalPlaceIdHidden ? createCollectionPlaceValidation(
    externalIdsFilled(collectionPlaces),
    t('settings:Agents.CollectionPlaces.ExternalPlaceId.required'),
  ) : {};

  const postCodeErrors = !isPostCodeHidden ? createCollectionPlaceValidation(
    postCodesFilled(collectionPlaces),
    t('settings:Agents.CollectionPlaces.PostCode.required'),
  ) : {};

  return {
    ...externalPlaceIdErrors,
    ...postCodeErrors,
    ...checkboxErrors,
  };
}

export default createValidationSchema;
