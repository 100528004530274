import React from 'react';
import { Mutation } from 'react-apollo';

import ApolloQueryTypes from 'Types/apollo/ApolloQueryTypes';
import { getGraphQLAuthenticationContext } from 'Shared/utilities';
import graphqlStorage from 'Services/GraphqlStorage';

export const CustomMutation = ({ mutation, variables, context = true, onCompleted, onError, refetchQueries, children }) => (
  <Mutation
    mutation={mutation}
    variables={variables}
    onCompleted={onCompleted}
    refetchQueries={refetchQueries}
    onError={onError}
    context={context && getGraphQLAuthenticationContext()}
  >
    {children}
  </Mutation>
);

CustomMutation.defaultProps = {
  variables: null,
  onError: null,
  onCompleted: null,
  context: true,
};

CustomMutation.propTypes = { ...ApolloQueryTypes.mutation() };

export const customManualMutation = async ({ variables, mutation }) => {
  const { client } = graphqlStorage;

  return client.mutate({
    mutation,
    variables,
    context: getGraphQLAuthenticationContext(),
  });
};

export default CustomMutation;
