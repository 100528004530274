import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Highlighter } from 'react-bootstrap-typeahead';

const ContactsModalItem = ({ user, searchText }) => (
  <div className="row">
    <div className="col-2 d-flex align-items-center justify-content-center">
      <FontAwesomeIcon icon={['far', 'user-circle']} size="2x" />
    </div>
    <div className="col-10">
      <span>
        <Highlighter key="name" search={searchText}>{`${user.name} ${user.surname}`}</Highlighter>
      </span>
      <div className="text-muted">
        <small>
          <Highlighter key="address" search={searchText}>{`${user.street} ${user.city}`}</Highlighter>
        </small>
      </div>
    </div>
  </div>
);

ContactsModalItem.propTypes = {
  user: PropTypes.shape({
    avatar: PropTypes.string,
    name: PropTypes.string.isRequired,
    surname: PropTypes.string.isRequired,
    street: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
  }).isRequired,
};

export default ContactsModalItem;
