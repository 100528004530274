import React from 'react';
import { useTranslation } from 'react-i18next';

import ConfirmModal from 'Containers/ConfirmModal/ConfirmModal';
import StopDeliveryProcessForm from '../../Container/StopDeliveryProcessForm/StopDeliveryProcessForm';

const InteractionMenuModals = ({ confirmModalState, modalService, deliveryId, refetchDeliveryDetail }) => {
  const { t } = useTranslation('deliveries');

  return (
    <div>
      <ConfirmModal
        isOpen={Object.keys(confirmModalState.value.closeDelivery)[0] === 'shown'}
        onPrompt={() => modalService.send('CONFIRM_MODAL')}
        onCancel={() => modalService.send('CANCEL_MODAL')}
        title={t('CloseDeliveryModal.title')}
        body={t('CloseDeliveryModal.description')}
        buttons={{
          prompt: { text: t('CloseDeliveryModal.submitButton'), color: 'secondary' },
          cancel: { text: t('CloseDeliveryModal.cancelButton'), color: 'link' },
        }}
      />
      <ConfirmModal
        isOpen={Object.keys(confirmModalState.value.cancelDelivery)[0] === 'shown'}
        onPrompt={() => modalService.send('CONFIRM_MODAL')}
        onCancel={() => modalService.send('CANCEL_MODAL')}
        title={t('CancelDeliveryModal.Single.title')}
        body={t('CancelDeliveryModal.Single.description')}
        buttons={{
          prompt: { text: t('CancelDeliveryModal.Single.submitButton'), color: 'secondary' },
          cancel: { text: t('CancelDeliveryModal.Single.cancelButton'), color: 'link' },
        }}
      />
      <StopDeliveryProcessForm
        deliveryId={deliveryId}
        modalService={modalService}
        modalState={confirmModalState}
        refetchDeliveryDetail={refetchDeliveryDetail}
      />
    </div>
  );
};

export default InteractionMenuModals;
