import React from 'react';
import PropTypes from 'prop-types';

import CountryQueryFactory from 'Models/country/queries/Factories/CountryQueryFactory';
import CountryFragments from 'Models/country/queries/Fragments/CountryFragments';
import ApolloQueryTypes from 'Types/apollo/ApolloQueryTypes';
import AuthenticationHelper from '~/services/Auth';

export const CountryManagerContext = React.createContext();

export default class CountryManager extends React.Component {
  constructor(props) {
    super(props);
    this.indexedDBTableName = 'country';
  }

  async componentDidMount() {
    try {
      await this.fetchCountries();
    } catch (e) {
      console.error(e);
    }
  }

  getCountryNameByCode = (code, languageCode = 'cs') => {
    const { tables } = this.props;
    const { country: countries } = tables;

    if (!countries) return null;

    const country = countries.find((item) => item.code === code);
    const suffix = languageCode === 'cs' ? 'Cs' : 'En';

    return (country) ? country[`name${suffix}`] : code;
  };

  // TODO: Split, add fallback - use countries from response when IndexedDB fails
  async fetchCountries() {
    const { getTable, setTable, apolloClient } = this.props;
    const countries = await getTable(this.indexedDBTableName);

    if (countries.length === 0) {
      const response = await apolloClient.query({
        query: CountryQueryFactory.countries(
          '...CountryInfoForStorage',
          CountryFragments.IndexedDB(),
        ),
        context: { headers: { AccessToken: AuthenticationHelper.accessToken } },
      });
      await setTable(this.indexedDBTableName, response.data.countries);
    }
  }

  render() {
    const { children, tables, disabled } = this.props;
    const { country } = tables;

    if (disabled) {
      return (
        <>
          {children}
        </>
      );
    }

    return (
      <CountryManagerContext.Provider value={{
        countries: country || [],
        getCountryNameByCode: this.getCountryNameByCode,
      }}
      >
        {children}
      </CountryManagerContext.Provider>
    );
  }
}

CountryManager.propTypes = {
  children: PropTypes.element,
  getTable: PropTypes.func.isRequired,
  setTable: PropTypes.func.isRequired,
  tables: PropTypes.shape({}).isRequired,
  apolloClient: ApolloQueryTypes.client().isRequired,
  disabled: PropTypes.bool.isRequired,
};

CountryManager.defaultProps = { children: null };
