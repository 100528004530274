import React from 'react';
import produce from 'immer';
import { withTranslation } from 'react-i18next';

import SettingsLayout from 'Components/Settings/SettingsLayout';
import SettingsBase from 'Containers/Settings/_base/SettingsBase';
import AgentForm from '../Components/Container/AgentForm/AgentForm';
import withAllAgents from '../Components/HoC/withAllAgents/withAllAgents';
import withAllCollectionPlaces
  from '../Components/HoC/withAllCollectionPlaces/withAllCollectionPlaces';
import withCredentials from '../Components/HoC/withCredentials/withCredentials';
import withUnusedAgents from '../Components/HoC/withAgentsCredentials/withUnusedAgents';

const AgentFormWithData = withAllCollectionPlaces(
  withAllAgents(withCredentials(withUnusedAgents(AgentForm))),
);

class AgentsNew extends SettingsBase {
  constructor(props) {
    super(props, 'comm');
  }

  componentDidMount() {
    this.displayPage();
  }

  displayPage() {
    const state = produce(this.state, (draft) => {
      const draftState = draft;

      draftState.isPageDisplayed = true;
    });

    this.setState(state);
  }

  render() {
    const { t } = this.props;
    const { history } = this.props;

    return (
      <SettingsLayout
        goBack="/settings"
        leftBlock={this.state.leftBlock}
        rightBlock={this.state.rightBlock}
        checkedItems={this.state.checkedItems}
        isOpened={this.state.isPageDisplayed}
      >
        <div className="right-col-spacing">
          <h1 className="text-nowrap">{t('settings:Agents.newAgentTitle')}</h1>
        </div>
        <AgentFormWithData
          history={history}
          isCreating
        />
      </SettingsLayout>
    );
  }
}

export default withTranslation(['settings'])(AgentsNew);
