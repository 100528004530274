import PropTypes from 'prop-types';

export default class ApolloQueryTypes {
  static client() {
    return PropTypes.shape({
      query: PropTypes.func,
      context: PropTypes.shape({}),
      variables: PropTypes.shape({}),
    });
  }

  static query() {
    return {
      query: PropTypes.object.isRequired,
      variables: PropTypes.shape({}),
      onError: PropTypes.func,
      onCompleted: PropTypes.func,
      context: PropTypes.bool,
    };
  }

  static mutation() {
    return {
      mutation: PropTypes.object.isRequired,
      variables: PropTypes.shape({}),
      onError: PropTypes.func,
      onCompleted: PropTypes.func,
      context: PropTypes.bool,
    };
  }
}
