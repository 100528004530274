import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormGroup, Label } from 'reactstrap';

import InputField from '~/components/InputField/InputField';

const ContactForm: React.FC = () => {
  const { t } = useTranslation(['settings']);

  return (
    <>
      <h4 className="body font-weight-bold">
        {t('settings:Agents.CreateCollectionPlace.contactTitle')}
      </h4>
      <FormGroup>
        <Label>{t('settings:Agents.CreateCollectionPlace.Contact.Mobile.label')}</Label>
        <InputField
          name="senderContactsMobile"
          type="tel"
          placeholder={t('settings:Agents.CreateCollectionPlace.Contact.Mobile.placeholder')}
        />
      </FormGroup>
      <FormGroup>
        <Label>{t('settings:Agents.CreateCollectionPlace.Contact.Phone.label')}</Label>
        <InputField
          name="senderContactsPhone"
          type="tel"
          placeholder={t('settings:Agents.CreateCollectionPlace.Contact.Phone.placeholder')}
        />
      </FormGroup>
      <FormGroup>
        <Label>{t('validation:Email.label')}</Label>
        <InputField
          name="senderContactsEmail"
          type="email"
          placeholder={t('validation:Email.placeholder')}
        />
      </FormGroup>
    </>
  );
};

export default ContactForm;
