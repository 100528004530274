export const transformDecimals = (value: string | number = ''): string => {
  if (typeof value === 'number') {
    return Number.isNaN(value) ? '' : value.toFixed(2);
  }

  const regex = /^(\d+)((?:,\d{3})*)?([.,]\d+)?$/;

  const [
    match,, // integer
    thousands,, // fraction,
  ] = regex.exec(value) || [];

  if (!match) {
    return value;
  }

  // if (integer && !thousands && !fraction) {
  //     return parseFloat(value).toFixed(2);
  // }

  const val = thousands ? value.replace(/,/g, '') : value.replace(/,(\d+)/g, '.$1');
  const num = parseFloat(val);

  // check for unexpected exceptions with NaN
  return (Number.isNaN(num)) ? value : num.toFixed(2);
};

export default transformDecimals;
