import React, { CSSProperties } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardFooter } from 'reactstrap';
import { Doughnut } from 'react-chartjs-2';
import { Popover } from 'react-bootstrap';
import { generate } from 'shortid';
import { useTranslation } from 'react-i18next';

import { formatNumber, rx } from '~/services/utils';
import { DeliveryCount } from '~/types/generated/graphql';
import ProgressList, { ProgressItem } from '~/App/[Dashboard]/ProgressList/ProgressList';
import Overlay from '~/App/[Dashboard]/Overlay/Overlay';

const doughnutOptions = {
  cutoutPercentage: 80,
  maintainAspectRatio: false,
  circumference: 1.5 * Math.PI,
  rotation: 0.75 * Math.PI,
  responsive: false,
  tooltips: {
    enabled: false,
  },
};

const doughnutData: any = {
  datasets: [{
    data: [],
    borderWidth: 0,
  }],
};

type Props = {
  deliveryCount: DeliveryCount;
  important: number;
  css: any;
}

const ReturnsChart: React.FC<Props> = ({ deliveryCount, important, css }) => {
  const { t } = useTranslation('dashboard');

  const prepareProgressItems = (count: DeliveryCount): ProgressItem[] => {
    const prepaid = count.returnedPrepaid || 0;
    const cod = count.returnedCod || 0;
    const max = Math.max(prepaid, cod);

    return [
      {
        label: t('ReturnsCard.ReturnsChart.paid'),
        value: rx(prepaid, max),
        key: generate(),
        formattedValue: formatNumber(prepaid),
      },
      {
        label: t('ReturnsCard.ReturnsChart.cashOnDelivery'),
        value: rx(cod, max),
        key: generate(),
        formattedValue: formatNumber(cod),
      },
    ];
  };

  const renderPopover = (count: DeliveryCount): React.ReactElement => (
    <Popover
      id="returns-popover"
      className={`${css.returnsPopover} _ds hide-arrow`}
    >
      <Popover.Content>
        <ProgressList
          items={prepareProgressItems(count)}
          compact
          showProgress={false}
        />
      </Popover.Content>
    </Popover>
  );

  const DOUGHNUT_WIDTH = 162;
  const DOUGHNUT_HEIGHT = 138;
  const DOUGHNUT_MAX = 10;
  const { returnPercentage } = deliveryCount;
  const [dataset] = doughnutData.datasets;

  // Handle empty state
  if (returnPercentage === null) {
    dataset.backgroundColor = ['#D6DBDF'];
    dataset.data = [1];
  } else {
    const cappedReturnPercentage = Math.min(returnPercentage, DOUGHNUT_MAX);

    dataset.backgroundColor = [
      '#FF5900',
      '#FFEEE6',
    ];
    dataset.data = [
      cappedReturnPercentage,
      DOUGHNUT_MAX - cappedReturnPercentage,
    ];
  }

  return (
    <Card className={`${css.card} mb-4`}>
      <CardBody className="position-relative">
        {/* TODO: Set css props on card (TS complains about types) */}
        <div
          className="d-flex justify-content-center"
          style={{ '--doughnutWidth': `${DOUGHNUT_WIDTH}px` } as CSSProperties}
        >
          <div className={css.doughnutLabel}>
            <div>
              {t('ReturnsCard.title')}
            </div>
            <div className={css.number}>
              {formatNumber(returnPercentage !== null ? returnPercentage / 100 : null, { style: 'percent' })}
            </div>
          </div>

          <div className={`${css.doughnutRange} ${css.doughnutRangeMin}`}>0%</div>
          <div className={`${css.doughnutRange} ${css.doughnutRangeMax}`}>{`${DOUGHNUT_MAX}%`}</div>

          <Overlay overlay={renderPopover(deliveryCount)}>
            <div>
              <Doughnut
                data={doughnutData}
                width={DOUGHNUT_WIDTH}
                height={DOUGHNUT_HEIGHT}
                options={doughnutOptions}
                legend={{ display: false }}
              />
            </div>
          </Overlay>
        </div>
      </CardBody>

      <CardFooter>
        {t('ReturnsCard.deliveriesToSolve')}

        <div className={`${css.number} mb-0`}>{formatNumber(important)}</div>

        {!!important && (
          <Link
            to="/deliveries"
            className="btn btn-secondary btn-sm"
            role="button"
            // aria-pressed="true"
          >
            {t('ReturnsCard.resolveDeliveriesButton')}
          </Link>
        )}
      </CardFooter>
    </Card>
  );
};

export default ReturnsChart;
