import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const buttonColors = [
  'link', 'secondary', 'warning', 'danger', 'success',
];

const ConfirmModal = ({ isOpen, onCancel, title, icon, text, buttons, onPrompt }) => (
  <Modal
    isOpen={isOpen}
    toggle={() => onCancel()}
    modalTransition={{ timeout: 150 }}
    centered
  >
    <ModalHeader toggle={() => onCancel()}>{ title }</ModalHeader>
    <ModalBody className="pt-4">
      <Row>
        <Col md={4} className="mx-auto text-center">
          <FontAwesomeIcon icon={icon || ['fas', 'exclamation-triangle']} size="5x" />
        </Col>
        <Col md={8} className="mx-auto">
          {text}
        </Col>
      </Row>
    </ModalBody>
    <ModalFooter className="border-0">
      <Button color={buttons.prompt.color} onClick={() => onPrompt()}>
        {buttons.prompt.text}
      </Button>
      <Button color={buttons.cancel.color} onClick={() => onCancel()}>
        {buttons.cancel.text}
      </Button>
    </ModalFooter>
  </Modal>
);

ConfirmModal.defaultProps = { icon: ['fas', 'exclamation-triangle'] };

ConfirmModal.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.arrayOf(PropTypes.string),
  isOpen: PropTypes.bool.isRequired,
  onPrompt: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  buttons: PropTypes.objectOf(PropTypes.shape({
    prompt: PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.oneOf(buttonColors).isRequired,
    }),
    cancel: PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.oneOf(buttonColors).isRequired,
    }),
  })).isRequired,
};

export default ConfirmModal;
