import { object, string } from 'yup';
import { TFunction } from 'i18next';

export const schema = (t: TFunction) => {
  return object().shape({
    name: string()
      .required(t('validation:Name.required'))
      .max(63, t('validation:Name.maxLength', { count: 63 })),
    surname: string()
      .required(t('validation:Surname.required'))
      .max(63, t('validation:Surname.maxLength', { count: 63 })),
    email: string()
      .email(t('validation:Email.pattern'))
      .required(t('validation:Email.required')),
    phone: string()
      .required(t('validation:Phone.required')),
  });
};
