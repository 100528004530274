import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router';
import { ApolloConsumer } from 'react-apollo';
import { withTranslation } from 'react-i18next';

import DeliveryQueryFactory from 'Models/delivery/Queries/Factories/DeliveryQueryFactory';
import DeliveryFragments from 'Models/delivery/Queries/Fragments/DeliveryFragments';
import { customManualMutation } from 'Models/Components/CustomMutation';
import { toast } from 'Shared/utilities';
import CloseDeliveryToastBody from 'Components/Presentational/CloseDeliveryToastBody/CloseDeliveryToastBody';
import ActionMenuItem from '../../Presentational/ActionMenuItem/ActionMenuItem';

class CloseDeliveryAction extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: false };
  }

  setLoading(loading) {
    this.setState({ isLoading: loading });
  }

  async closeDeliveries(id, client, refetchDeliveries) {
    const { onComplete } = this.props;
    this.setLoading(true);

    const closeDeliveryResponse = await customManualMutation({
      client,
      mutation: DeliveryQueryFactory.closeDeliveriesFromList('...closedDeliveries', DeliveryFragments.closedDeliveries()),
      variables: { deliveriesIds: id },
    });
    const { closeDeliveriesFromList: response } = closeDeliveryResponse.data;

    this.showDeliveryCloseToasts(response);
    this.setLoading(false);

    if (response.success) {
      onComplete();
      refetchDeliveries();
    }
  }

  async handleClick(client) {
    const { disabled, refetchDeliveries, checkedDeliveries } = this.props;
    const { isLoading } = this.state;

    if (disabled || isLoading) return;

    await this.closeDeliveries(checkedDeliveries.map((item) => item.id), client, refetchDeliveries);
  }

  showDeliveryCloseToasts(response) {
    const toastFunction = response.success ? 'success' : 'info';

    toast[toastFunction](
      <CloseDeliveryToastBody
        failedDeliveries={response.failedDeliveries ?? []}
        messages={response.messages}
        success={response.success}
      />,
      response.success ? undefined : { closeOnClick: false, draggable: false },
    );
  }

  render() {
    const { disabled, checkedDeliveries, t } = this.props;
    const { isLoading } = this.state;

    return (
      <ApolloConsumer>
        {(client) => (
          <ActionMenuItem
            icon="box"
            disabled={disabled || isLoading}
            count={checkedDeliveries.length}
            loading={isLoading}
            onClick={() => this.handleClick(client)}
          >
            {t('deliveries:ActionBar.ActionMenu.closeDelivery', { count: checkedDeliveries.length })}
          </ActionMenuItem>
        )}
      </ApolloConsumer>
    );
  }
}

export default withTranslation(['deliveries'])(withRouter(CloseDeliveryAction));

CloseDeliveryAction.propTypes = {
  history: PropTypes.shape({}).isRequired,
  disabled: PropTypes.bool.isRequired,
  checkedDeliveries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onComplete: PropTypes.func.isRequired,
  refetchDeliveries: PropTypes.func.isRequired,
};
