import { useRef, useEffect } from 'react';

/**
 * Supplement missing hook for executing useEffect() only on subsequent renders
 */
const useDidUpdateEffect: typeof useEffect = (fn, inputs) => {
  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    fn();
  }, inputs);
};

export default useDidUpdateEffect;
