import React, { useState, useEffect, useCallback, useContext, useMemo } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import PackageDataSource from '#/core/models/packages/PackageDataSource';
import useUniqueKeys from '~/hooks/useUniqueKeys';
import useModal from '~/hooks/useModal';
import { toast } from '#/shared/utilities';

import DeliveryContext from '../../DeliveryContext';
import PackageRow from './PackageRow/PackageRow';
import PackageSizes from './PackageSizes/PackageSizes';
import PackageTemplateModal from './PackageTemplateModal/PackageTemplateModal';
import { PackageValues } from '../../types';
import { PackageTemplate, PackageTemplateDraft } from './types';
import css from './Packages.module.scss';

function mapContainerOptions(codes: [] = []) {
  return codes.map(({ name, code }) => ({ label: name, value: code }));
}

const Packages: React.FC = () => {
  const { t } = useTranslation('deliveries');
  const {
    deliveryType: { isCargoType: isCargo },
    agent: { containerCodes },
  } = useContext(DeliveryContext);
  const { values: { packages = [] } } = useFormikContext<PackageValues>();
  const [keys, pushKey, removeKey] = useUniqueKeys(packages?.length);
  const [isModalOpen, toggleModal] = useModal();
  const [templateDraft, setTemplateDraft] = useState<PackageTemplateDraft>({
    length: 0,
    width: 0,
    height: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [templates, setTemplates] = useState<PackageTemplate[]>([]);

  const packageDataSource = useMemo(() => new PackageDataSource(), []);

  const fetchTemplates = useCallback(async () => {
    setIsLoading(true);

    try {
      // eslint-disable-next-line max-len
      const { data: { packageTemplates: packageTemplatesData } } = await packageDataSource.packageTemplates();

      setTemplates(packageTemplatesData);
    } catch (err) {
      console.error(err);

      setTemplates([]);

      // TODO: should rather display a subtle error message next to template list
      toast.error(t('NewDelivery.Banner.packageTemplateLoadError'));
    }

    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (isCargo) {
      return;
    }

    fetchTemplates();
  }, [isCargo, fetchTemplates]);

  const containerOptions = useMemo(() => (
    isCargo ? mapContainerOptions(containerCodes) : undefined
  ), [containerCodes, isCargo]);

  const handleSaveTemplateClick = (
    { length, width, height }: PackageTemplateDraft,
  ) => {
    setTemplateDraft({ width, length, height });

    toggleModal();
  };

  return (
    <>
      <FieldArray name="packages">
        {({ push, replace, remove }): React.ReactNode => (
          <>
            {
              packages.map((item, index, array) => (
                <PackageRow
                  // TODO: is the useUniqueKeys jazz useful at all? could just use index as the key
                  key={keys[index]}
                  index={index}
                  metrics={item}
                  onSaveTemplateClick={!isCargo ? handleSaveTemplateClick : undefined}
                  onDelete={(i: number): void => {
                    remove(i);
                    removeKey(index);
                  }}
                  hideDeleteButton={array.length === 1}
                  containerOptions={containerOptions}
                />
              ))
            }

            <Row
              className="mt-3"
              form
            >
              <div className={`${css.actions} text-center text-muted`}>
                {t('NewDelivery.Packages.addPackageLabel')}
              </div>

              <Col
                md={12}
                className={css.packageTemplate}
              >
                <PackageSizes
                  isLoading={isLoading}
                  templates={templates}
                  onAddFromTemplate={(template): void => {
                    const lastPackage = packages[packages.length - 1];

                    if (!lastPackage.length && !lastPackage.width && !lastPackage.height) {
                      const lastIndex = packages.length - 1;
                      const weight = lastPackage.weight || template.weight || '';
                      const { length, width, height } = template;
                      replace(lastIndex, { weight, length, width, height });
                    } else {
                      const { weight, length, width, height } = template;
                      push({ weight, length, width, height });
                      pushKey();
                    }
                  }}
                  onAddBlank={(): void => {
                    push({ weight: '', length: '', width: '', height: '' });
                    pushKey();
                  }}
                />
              </Col>
            </Row>
          </>
        )}
      </FieldArray>

      <PackageTemplateModal
        isOpen={isModalOpen}
        onToggle={toggleModal}
        template={templateDraft}
        onSuccess={() => {
          toast.success(t('NewDelivery.Banner.packageTemplateSaveSuccess'));

          setTemplateDraft({
            length: 0,
            width: 0,
            height: 0,
          });

          fetchTemplates();
        }}
      />
    </>
  );
};

export default Packages;
