import AuthenticationQueryFactory from 'Models/authentication/Query/Factories/AuthenticationQueryFactory';
import AuthenticationFragments from 'Models/authentication/Query/Fragments/AuthenticationFragments';

const GET_LOGGED_USER_PERMISSSIONS = AuthenticationQueryFactory.loggedUserInfo(
  '...LoggedUserPermissions',
  AuthenticationFragments.loggedUserPermissions(),
);

const GET_OAUTH_CLIENT = AuthenticationQueryFactory.oAuthClientByIdentifier(
  '...OAuthAuthorizationFormInfo',
  AuthenticationFragments.oAuthAuthorizationFormInfo(),
);

const GET_OAUTH_SCOPES = AuthenticationQueryFactory.oAuthScopes(
  '...OAuthScopes',
  AuthenticationFragments.oAuthScopes(),
);

const AUTHORIZE_CLIENT = AuthenticationQueryFactory.authorizeOAuthClient(
  '...OAuthAuthorizeRedirectInfo',
  AuthenticationFragments.oAuthAuthorizeRedirectInfo(),
);

export {
  GET_OAUTH_CLIENT,
  GET_LOGGED_USER_PERMISSSIONS,
  GET_OAUTH_SCOPES,
  AUTHORIZE_CLIENT,
};
