import gql from 'graphql-tag';

import BaseDataSource from '../BaseDataSource';
import AuthenticationHelper from '~/services/Auth';

export default class CompanyDataSource extends BaseDataSource {
  constructor(props) {
    super(props);
    this.defaultSelectByIdFields = `
      name
      managingDirector {
        name
        surname
        email
        phone
      }
      identificationNumber
      vatNumber
      address {
        country
        street
        city
        zip
      }
    `;
  }

  async selectById(id, fields = this.defaultSelectByIdFields) {
    const query = gql`
      query company($id: ID) {
        company(id: $id) {
          ${fields}
        }
      }
    `;
    const variables = { id };

    return this.client.query({
      query,
      variables,
      context: { headers: { AccessToken: AuthenticationHelper.accessToken } },
    });
  }

  async updateCompany(company) {
    const mutation = gql`
      mutation updateCompany($company: InputCompany!) {
        updateCompany(company: $company) {
          name
          managingDirector {
            name
            surname
            email
            phone
          }
          identificationNumber
          vatNumber
          address {
            country
            street
            city
            zip
          }
        }
      }
    `;
    const variables = { company };

    return this.client.mutate({
      mutation,
      variables,
      context: { headers: { AccessToken: AuthenticationHelper.accessToken } },
    });
  }
}
