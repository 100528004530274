import { addDays, differenceInCalendarDays } from 'date-fns';

import { Currency } from '~/types/generated/graphql';
import configurator from '~/services/Configurator';

export function rx(a?: Nullable<number>, b?: Nullable<number>): number {
  if (typeof a === 'number' && typeof b === 'number') {
    return Math.round((a / b) * 100);
  }

  return 0;
}

/**
 * Safely divides two numbers. Avoids division by zero.
 */
export function divide(a?: Nullable<number>, b?: Nullable<number>): number {
  if (typeof a === 'number' && typeof b === 'number' && b !== 0) {
    return a / b;
  }

  return 0;
}

/**
 * Formats a number as a currency string.
 * Use `style: 'currency'` to include the currency code in output.
 * TODO: Add option to specify locale
 * TODO: Use global type for currency type
 */
export const formatCurrency = (
  x?: Nullable<number>,
  options?: {
    style?: 'currency' | 'decimal',
    currency?: Currency,
    currencyDisplay?: 'symbol' | 'narrowSymbol' | 'code' | 'name',
    fractionDigits?: number,
  },
): string => {
  /**
   * Values higher than the notationLimit (inclusive) will be formatted
   * using compact notation (e.g. "10,05 mld.")
   */
  const notationLimit = 10000000000;

  // Default configuration, can be overriden by "options"
  const { style, currency, currencyDisplay, fractionDigits, notation } = {
    style: 'currency',
    currency: 'CZK',
    currencyDisplay: 'code',
    // Use 0 fraction digits for values between 1k and 10 billion
    fractionDigits: (x && x >= 1000 && x < notationLimit) ? 0 : 2,
    // Use compact notation ("10,05 mld.") for values over 10 billion
    notation: (x && x >= notationLimit) ? 'compact' : 'standard',
    ...options,
  };

  // TODO: typeof x !== 'number' ???
  if (x === null || typeof x === 'undefined' || Number.isNaN(x)) {
    return 'N/A';
  }

  return new Intl.NumberFormat('cs-CZ', {
    style,
    currency,
    currencyDisplay,
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits,
    notation,
  }).format(x);
};

/**
 * Formats a number according to the locale and formatting options
 * * TODO: Add option to specify locale
 */
export const formatNumber = (
  x?: Nullable<number>,
  options?: {
    style?: 'decimal' | 'percent' | 'unit',
    maximumFractionDigits?: number,
    unit?: string,
  },
): string => {
  const { style, maximumFractionDigits, unit } = {
    style: 'decimal',
    maximumFractionDigits: options?.style === 'percent' ? 1 : 2,
    ...options,
  };

  if (x === null || typeof x === 'undefined' || Number.isNaN(x)) {
    return 'N/A';
  }

  return new Intl.NumberFormat('cs-CZ', {
    style,
    maximumFractionDigits,
    unit,
  }).format(x);
};

/**
 * Calculates the number of days between start and end dates (both included).
 * Returns 0 when end date precedes start date.
 */
export const remainingDays = (start: Date, end: Date): number => {
  // Adding 1 day since differenceInCalendarDays doesn't take start date into account
  // When end date is today, the result will be 1 remaining day
  const days = differenceInCalendarDays(addDays(end, 1), start);

  return days < 0 ? 0 : days;
};

/**
 * Assets are sourced from S3 -> foxdeli-public-assets
 * @param logoSlug - Carrier logo slug defined on the Agent entity
 */
export const getCarrierLogoUrl = (logoSlug: string): string => {
  return `${configurator.config.carrierAssetsUrl}/carriers/logo/horizontal/svg/${logoSlug}.svg`;
};

export const formatPhoneNumber = (phoneNumber?: Nullable<string>): string => {
  if (phoneNumber === undefined || phoneNumber === null) {
    return '';
  }

  return phoneNumber.replace(/\s/g, '');
};

/**
 * Merges multiple CSS class names into a single string.
 * Ignores null, undefined and false values so you can use conditional
 * class names such as:
 * - (condition && 'className')
 * - (condition ? 'className1' : className2)
 *
 * @param cssClasses
 * @return string
 */
export const cssMerge = (...cssClasses: (string | false | null | undefined)[]): string => (
  cssClasses
    .filter((cssClass) => typeof cssClass === 'string')
    .join(' ')
);
