import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Badge, Button } from 'reactstrap';
import { useSelector } from 'react-redux';

import DeliveryFilterModal from 'Containers/Deliveries/Components/DeliveryFilter/DeliveryFilterModal/DeliveryFilterModal';
import useModal from '~/hooks/useModal';

import css from './DeliveryFilter.module.scss';

const DeliveryFilter: React.FC = () => {
  const filter = useSelector((state: any) => state.deliveryReducer.filter);
  const [isModalOpen, toggleModal] = useModal();

  return (
    <>
      <Button
        className={css.button}
        onClick={() => { toggleModal(); }}
      >
        {filter.recipientCountry && (
          <span className={css.tag}>
            1
          </span>
        )}
        <FontAwesomeIcon
          className={css.icon}
          icon={['fas', 'filter']}
        />
      </Button>

      <DeliveryFilterModal
        isOpen={isModalOpen}
        onToggle={toggleModal}
      />
    </>
  );
};

export default DeliveryFilter;
