import React, { useState } from 'react';
import { Card, CardBody, CardHeader, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import DurationTab from '~/App/[Dashboard]/DashboardBody/Duration/DurationTab/DurationTab';
import { DeliveryDuration } from '~/types/generated/graphql';

import css from '~/App/[Dashboard]/DashboardBody/DashboardBody.module.scss';

type Props = {
  deliveryDuration: DeliveryDuration;
  pickupDuration: DeliveryDuration;
}

const Duration: React.FC<Props> = ({
  deliveryDuration,
  pickupDuration,
}) => {
  const { t } = useTranslation('dashboard');

  const [activeTab, setActiveTab] = useState('1');

  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <Card className={css.card}>
      <CardHeader className="p-0">
        <Nav tabs>
          <NavItem>
            <NavLink
              tag="button"
              className={`${activeTab === '1' && 'active'} btn`}
              onClick={() => { toggle('1'); }}
            >
              {t('DurationCard.Delivery.tabTitle')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              tag="button"
              className={`${activeTab === '2' && 'active'} btn`}
              onClick={() => { toggle('2'); }}
            >
              {t('DurationCard.PickUp.tabTitle')}
            </NavLink>
          </NavItem>
        </Nav>
      </CardHeader>
      <CardBody className="position-relative">
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <DurationTab
              subtitle={t('DurationCard.Delivery.averageDeliveryTimeLabel')}
              deliveryDuration={deliveryDuration}
            />
          </TabPane>
          <TabPane tabId="2">
            <DurationTab
              subtitle={t('DurationCard.PickUp.averagePickUpTimeLabel')}
              deliveryDuration={pickupDuration}
            />
          </TabPane>
        </TabContent>
      </CardBody>
    </Card>
  );
};

export default Duration;
