import TT_ACTIVATE from 'Containers/Settings/Branding/TrackAndTrace/queries/activateTrackAndTrace.gql';
import { FormikHelpers } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { filterObjectEmptyProperties } from '../helpers/filterObjectEmptyProperties';
import FoxLoader from '~/components/FoxLoader/FoxLoader';
import { uploadImage } from '~/helpers/imageHelper';
import {
  convertTrackAndTraceConfigToInput,
  CreateTrackAndTraceConfigResponse, initialTTValues, MutationTrackAndTraceConfig, MutationTTActivate,
  TrackAndTraceValues, UpdateTrackAndTraceConfigResponse,
} from './typesAndDefaults';
import { toast } from '#/shared/utilities';
import useAuthMutation from '~/hooks/useAuthMutation';
import TrackAndTraceForm from './TrackAndTraceForm/TrackAndTraceForm';

import CREATE_TRACK_AND_TRACE_CONFIG from './queries/createTrackAndTrace.gql';
import UPDATE_TRACK_AND_TRACE_CONFIG from './queries/updateTrackAndTrace.gql';

const NewTrackAndTrace: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation(['settings']);
  const [formIsSubmitting, setFormIsSubmitting] = React.useState(false);

  const [createTrackAndTraceConfigMutation] = useAuthMutation<CreateTrackAndTraceConfigResponse, MutationTrackAndTraceConfig>(CREATE_TRACK_AND_TRACE_CONFIG);
  const [updateTrackAndTraceConfigMutation] = useAuthMutation<UpdateTrackAndTraceConfigResponse, MutationTrackAndTraceConfig>(UPDATE_TRACK_AND_TRACE_CONFIG);
  const [activateTrackAndTraceConfigMutation] = useAuthMutation<MutationTTActivate, { id: string }>(TT_ACTIVATE);

  const submitNewTT = async (trackAndTraceValues: TrackAndTraceValues, formikHelpers: FormikHelpers<TrackAndTraceValues>, onSubmitCallback: () => void) => {
    setFormIsSubmitting(true);
    try {
      const trackAndTraceConfigCreationResponse = await createTrackAndTraceConfigMutation({ variables: {
        config: convertTrackAndTraceConfigToInput(trackAndTraceValues),
      } });

      const trackAndTraceCreationData = trackAndTraceConfigCreationResponse?.data?.createTrackAndTraceConfig;
      if (trackAndTraceCreationData?.id) {
        const [logoUploadData, faviconUploadData, desktopBgUploadData, mobileBgUploadData] = await Promise.all([
          uploadImage(trackAndTraceValues.logoImg),
          uploadImage(trackAndTraceValues.faviconImg),
          uploadImage(trackAndTraceValues.desktopBackground),
          uploadImage(trackAndTraceValues.mobileBackground),
        ]);

        await updateTrackAndTraceConfigMutation({
          variables: {
            id: trackAndTraceCreationData.id,
            config: {
              ...convertTrackAndTraceConfigToInput(trackAndTraceValues),
              ...filterObjectEmptyProperties({
                logoId: logoUploadData?.data.imageId,
                faviconId: faviconUploadData?.data.imageId,
                desktopBackgroundImageId: desktopBgUploadData?.data.imageId,
                mobileBackgroundImageId: mobileBgUploadData?.data.imageId,
              }),
            },
          },
        });

        if (trackAndTraceValues.activateAfterSave) {
          formikHelpers.setFieldValue('activateAfterSave', false);
          await activateTrackAndTraceConfigMutation({ variables: { id: trackAndTraceCreationData.id } });
          toast.success(t('settings:Branding.TrackAndTrace.Message.templateCreatedAndActivated'));
        } else {
          toast.success(t('settings:Branding.TrackAndTrace.Message.templateCreated'));
        }
      }

      formikHelpers.setSubmitting(false);
      setFormIsSubmitting(false);
      history.push('/settings/branding/track-and-trace');
    } catch (e) {
      // Handled in global interceptor
      formikHelpers.setSubmitting(false);
      setFormIsSubmitting(false);
    }

    onSubmitCallback();
  };

  return (
    <>
      <h1 className="mb-5">{t('settings:Branding.TrackAndTrace.newTrackAndTraceTitle')}</h1>
      <TrackAndTraceForm
        onSubmit={(values, formikHelpers, onSubmitCallback) => submitNewTT(values, formikHelpers, onSubmitCallback)}
        initialValues={initialTTValues}
      />
      { formIsSubmitting && <FoxLoader loaderText={t('settings:Branding.TrackAndTrace.trackAndTraceFormInsertingText')} /> }
    </>
  );
};

export default NewTrackAndTrace;
