/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';

import LoginSection from '../LoginSection/LoginSection';
import CollectionPlaces from '../../Container/CollectionPlaces/CollectionPlaces';
import PrivacyConsent from '../PrivacyConsent/PrivacyConsent';
import TechnologyNumberAccordion from '../../Container/TechnologyNumberAccordion/TechnologyNumberAccordion';
import DeliveryTypeSection from '../../Container/DeliveryTypeSection/DeliveryTypeSection';
import InteractiveExtraServiceList from '../../Container/InteractiveExtraServiceList/InteractiveExtraServiceList';

const AgentFormBody = ({
  mergeAgentFormValues,
  formik,
  formData,
  schema,
  initialInactiveServices,
  refetchCollectionPlaces,
  collectionPlaces,
  showPartialCredentialsForm,
  lockLoginSection,
  currentAgent,
  credentialCollectionPlaces,
  toggleLoginSubmit,
  editedCredential,
  isCreating,
}) => {
  const { values, handleChange } = formik;
  const { activeServices, technologyNumbers, numberSequences } = formData;

  return (
    <>
      <LoginSection
        usernameLabel={schema.userNameLabel}
        passwordLabel={schema.passwordLabel}
        companyCodeLabel={schema.companyCodeLabel}
        toggleLoginSubmit={toggleLoginSubmit}
        isPasswordHidden={!schema.password}
        isUsernameHidden={!schema.userName}
        isCompanyCodeHidden={!schema.companyCode}
        lockLoginSection={lockLoginSection}
      />

      {!showPartialCredentialsForm && (
        <>
          <hr className="mt-5" />
          <CollectionPlaces
            collectionPlaces={collectionPlaces}
            refetchCollectionPlaces={refetchCollectionPlaces}
            credentialCollectionPlaces={credentialCollectionPlaces || []}
            mergeAgentFormValues={mergeAgentFormValues}
            active={schema.supportForCollectionPlaces}
            isExternalPlaceIdHidden={!schema.externalPlaceId}
            isPostCodeHidden={!schema.collectionPlacePostCode}
            isCollectionPlaceUpdateSupported={editedCredential?.isCollectionPlaceUpdateSupported ?? false}
            supportsPickup={schema.supportsPickup}
            agentId={currentAgent.id}
          />

          <TechnologyNumberAccordion
            technologyNumbers={technologyNumbers}
            mergeAgentFormValues={mergeAgentFormValues}
            active={schema.techNumber}
          />

          <hr className="mt-4" />
          <DeliveryTypeSection
            initialInactiveServices={initialInactiveServices}
            activeServices={activeServices}
            mergeAgentFormValues={mergeAgentFormValues}
            activeAgentId={values.agent.value}
            numberSequences={numberSequences}
            isCreating={isCreating}
          />

          {currentAgent.extraServices.length > 0 && (
            <>
              <hr className="mt-5" />
              <InteractiveExtraServiceList agentAbbr={currentAgent.abbr} />
            </>
          )}

          <hr className="mt-4" />
          <PrivacyConsent onChange={handleChange} value={values.consent} />
        </>
      )}
    </>
  );
};

AgentFormBody.defaultProps = { currentCredential: null };

AgentFormBody.propTypes = {
  credentialCollectionPlaces: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  initialInactiveServices: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  currentAgent: PropTypes.shape({}).isRequired,
  collectionPlaces: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  mergeAgentFormValues: PropTypes.func.isRequired,
  currentCredential: PropTypes.shape({}),
  formik: PropTypes.shape({}).isRequired,
  formData: PropTypes.shape({}).isRequired,
  schema: PropTypes.shape({
    userName: PropTypes.bool,
    password: PropTypes.bool,
    companyCode: PropTypes.bool,
    techNumber: PropTypes.bool,
    startNumber: PropTypes.bool,
    numberSequence: PropTypes.bool,
  }).isRequired,
  refetchCollectionPlaces: PropTypes.func.isRequired,
  showPartialCredentialsForm: PropTypes.bool.isRequired,
  lockLoginSection: PropTypes.bool.isRequired,
};

export default AgentFormBody;
