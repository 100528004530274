import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormFeedback, FormGroup, Label, Row, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FieldArray, FormikErrors, useFormikContext } from 'formik';

import CountrySelect from 'Containers/Deliveries/New/CountrySelect/CountrySelect';
import getFullPathUrlOrDefault from 'Containers/Settings/Branding/helpers/getFullPathUrlOrDefault';
import { ACCEPTED_FILE_TYPES } from 'Containers/Settings/Branding/typesAndDefaults';
import InputField from '~/components/InputField/InputField';
import { BannerItem, bannerItemInitValues, MarketingCampaignFormValues } from '../typesAndDefaults';
import SimpleFileInput from '~/components/SimpleFileInput/SimpleFileInput';
import SimpleSwitch from '~/components/SimpleSwitch/SimpleSwitch';

import './BannerItems.scss';

const BannerItems: React.FC = () => {
  const { t } = useTranslation(['settings', 'validation']);
  const { setFieldValue, values, errors } = useFormikContext<MarketingCampaignFormValues>();

  const assignedCountries: { [key: string]: string; } = useMemo(() => {
    const blackListCountries: { [key: string]: string; } = {};
    values.eshopCampaignBanners.forEach((bannerItem) => {
      if (bannerItem.country) {
        blackListCountries[`${bannerItem.id}`] = bannerItem.country;
      }
    });
    return blackListCountries;
  }, [values.eshopCampaignBanners]);

  const getCountryBlackList = (bannerItemId: string): string[] => {
    const blackListCountriesObject = { ...assignedCountries };
    delete blackListCountriesObject[bannerItemId];
    return Object.values(blackListCountriesObject);
  };

  const bannerErrors = errors.eshopCampaignBanners as Array<FormikErrors<BannerItem>>;

  return (
    <div className="mb-4 font-weight-medium banner-items">
      <p className="banner-items-description">
        {t('settings:Branding.MarketingCampaigns.Form.Banners.bannerSectionDescription')}
      </p>

      <FieldArray name="eshopCampaignBanners">
        {({ push, remove }): React.ReactNode => (
          <>
            { (values.eshopCampaignBanners || []).map((banner, index) => (
              <div className="mt-4 card banner-item" key={banner.id}>
                <div className="card-body">
                  <Row className="align-items-center">
                    <Col md={8}>
                      <h3 className="mt-2">{t('settings:Branding.MarketingCampaigns.Form.Banners.countryTitle')}</h3>
                    </Col>
                    <Col md={4}>
                      <FontAwesomeIcon className="banner-item-delete clearfix" onClick={() => remove(index)} icon={['fas', 'times']} />
                    </Col>
                  </Row>
                  <FormGroup>
                    <Label>
                      {t('settings:Branding.MarketingCampaigns.Form.Banners.countryPickerLabel')}
                      <span className="branding-required-field">*</span>
                    </Label>
                    <CountrySelect
                      name={`eshopCampaignBanners[${index}].country`}
                      data-test={`eshopCampaignBanners[${index}].country`}
                      // `value` must be of type `string` due to `CustomSelect` implementation
                      // (see CustomSelect.tsx:81)
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      value={banner.country}
                      countryCodesBlacklist={getCountryBlackList(`${banner.id}`)}
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      onChange={({ value }) => setFieldValue(`eshopCampaignBanners[${index}].country`, value)}
                      placeholder={t('validation:CountrySelect.placeholder')}
                      defaultLabel={t('validation:CountrySelect.label')}
                    />

                    { bannerErrors?.[index]?.country
                      && <FormFeedback className="d-block">{bannerErrors[index].country}</FormFeedback> }
                  </FormGroup>
                  <FormGroup>
                    <Label>
                      {t('settings:Branding.MarketingCampaigns.Form.Banners.linkWebLabel')}
                      <span className="branding-required-field">*</span>
                    </Label>
                    <InputField
                      name={`eshopCampaignBanners[${index}].linkWeb`}
                      type="text"
                      placeholder={t('settings:Branding.MarketingCampaigns.Form.Banners.linkWebPlaceholder')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>
                      {t('settings:Branding.MarketingCampaigns.Form.Banners.linkEmailLabel')}
                      <span className="branding-required-field">*</span>
                    </Label>
                    <InputField
                      name={`eshopCampaignBanners[${index}].linkEmail`}
                      type="text"
                      placeholder={t('settings:Branding.MarketingCampaigns.Form.Banners.linkEmailPlaceholder')}
                    />
                  </FormGroup>

                  <Row className="justify-content-center clearfix">
                    <Col xs={7}>
                      <h3>{t('settings:Branding.MarketingCampaigns.Form.Banners.trackAndTraceTitle')}</h3>
                    </Col>
                    <Col xs={5}>
                      <SimpleSwitch
                        name={`eshopCampaignBanners[${index}].isTrackAndTraceActive`}
                        badgeStatus={values.eshopCampaignBanners[index].isTrackAndTraceActive}
                      />
                    </Col>
                  </Row>
                  <Row className="clearfix">
                    <Col xs={6} className="float-left">
                      <SimpleFileInput
                        label={t('settings:Branding.MarketingCampaigns.Form.Banners.trackAndTraceDesktopBannerLabel')}
                        name={`eshopCampaignBanners[${index}].trackAndTraceDesktopBannerImage`}
                        accept={ACCEPTED_FILE_TYPES.Web}
                        initialPreviewUrl={getFullPathUrlOrDefault(banner.trackAndTraceDesktopBanner?.url)}
                        disabled={!banner.isTrackAndTraceActive}
                      />
                    </Col>
                    <Col xs={6} className="float-right">
                      <SimpleFileInput
                        label={t('settings:Branding.MarketingCampaigns.Form.Banners.trackAndTraceMobileBannerLabel')}
                        name={`eshopCampaignBanners[${index}].trackAndTraceMobileBannerImage`}
                        accept={ACCEPTED_FILE_TYPES.Web}
                        initialPreviewUrl={getFullPathUrlOrDefault(banner.trackAndTraceMobileBanner?.url)}
                        disabled={!banner.isTrackAndTraceActive}
                      />
                    </Col>
                    <p className="branding-technical-info">
                      {t('settings:Branding.MarketingCampaigns.Form.trackAndTraceBannerTechnicalInfo', {
                        width: 800,
                        height: 200,
                        mobileWidth: 400,
                        mobileHeight: 200,
                      })}
                    </p>
                  </Row>

                  <Row className="justify-content-center clearfix">
                    <Col xs={7}>
                      <h3>{t('settings:Branding.MarketingCampaigns.Form.Banners.emailCommunicationTitle')}</h3>
                    </Col>
                    <Col xs={5}>
                      <SimpleSwitch
                        name={`eshopCampaignBanners[${index}].isEmailCommunicationActive`}
                        badgeStatus={values.eshopCampaignBanners[index].isEmailCommunicationActive}
                      />
                    </Col>
                  </Row>
                  <Row className="justify-content-center clearfix">
                    <Col xs={12}>
                      <SimpleFileInput
                        label={t('settings:Branding.MarketingCampaigns.Form.Banners.emailCommunicationBannerLabel')}
                        name={`eshopCampaignBanners[${index}].emailCommunicationBannerImage`}
                        accept={ACCEPTED_FILE_TYPES.Email}
                        initialPreviewUrl={getFullPathUrlOrDefault(banner.emailCommunicationBanner?.url)}
                        disabled={!banner.isEmailCommunicationActive}
                      />
                    </Col>
                    <p className="branding-technical-info">
                      {t('settings:Branding.MarketingCampaigns.Form.mailingBannerTechnicalInfo', { width: 600, height: 200 })}
                    </p>
                  </Row>
                </div>
              </div>
            )) }

            <Button
              type="button"
              color="flat-blue"
              className="mt-4"
              data-test="add-banner-button"
              onClick={() => push(bannerItemInitValues())}
            >
              <FontAwesomeIcon icon={['fas', 'plus']} />
              {t('settings:Branding.MarketingCampaigns.Form.Banners.addBannerButton')}
            </Button>
          </>
        )}
      </FieldArray>
    </div>
  );
};

export default BannerItems;
