import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Label,
  FormGroup,
  Input,
  Row,
  Form,
  Col,
  FormFeedback,
} from 'reactstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import gql from 'graphql-tag';
import { produce } from 'immer';
import { ApolloConsumer } from 'react-apollo';

import { hasSelectDefaultValue } from 'Shared/formValidationRules/formValidationRules';
import { CustomQuery } from 'Models/Components/CustomQuery';
import { toast } from 'Shared/utilities';
import { customManualMutation } from 'Models/Components/CustomMutation';

const GET_AGENTS_AND_COLLECTION_PLACES = gql`
  query deliveryCollectionProtocolCreatingInformation  {
    deliveryCollectionProtocolCreatingInformation {
      agent {
        id
        name
      }
      collectionPlaces {
        collectionPlace {
          id
          name
        }
      }
    }
  }
`;

const CREATE_PROTOCOL = gql`
  mutation createDeliveryCollectionProtocol($collectionPlaceId: ID!, $agentId: ID!) {
    createDeliveryCollectionProtocol(collectionPlaceId: $collectionPlaceId, agentId: $agentId) {
      id
      deliveriesCount
      agent {
        name
        logo { url name }
      }
      collectionPlace { id name address { street city country zip } }
      created
    }
  }
`;

class ProtocolsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = { selectedAgentIndex: null };
    Yup.addMethod(Yup.string, 'hasSelectDefaultValue', hasSelectDefaultValue);
    this.data = null;
  }

  form = ({
    handleSubmit,
    handleChange,
    handleBlur,
    isSubmitting,
    values,
    touched,
    errors,
    setFieldValue,
  }) => {
    const { t } = this.props;
    const { selectedAgentIndex } = this.state;

    return (
      <Form onSubmit={handleSubmit}>
        <div className="form-row pt-2">
          <FormGroup className="col-md-6">
            <Label htmlFor="surname" style={{ display: 'block' }}>
              {t('protocols:NewProtocolModal.Agent.label')}
            </Label>
            <Input
              name="agent"
              type="select"
              onChange={this.handleAgentChange(setFieldValue)}
              onBlur={handleBlur}
              invalid={errors.agent !== undefined}
              valid={touched.agent && !errors.agent}
              value={values.agent}
            >
              <option value="SELECT">
                {t('protocols:NewProtocolModal.Agent.selectPlaceholder')}
              </option>
              {this.renderAgents()}
            </Input>
            {touched.agent && errors.agent && <FormFeedback>{errors.agent}</FormFeedback>}
          </FormGroup>
          <FormGroup className="col-md-6">
            <Label htmlFor="name" style={{ display: 'block' }}>
              {t('protocols:NewProtocolModal.CollectionPlace.label')}
            </Label>
            <Input
              name="collectionPlace"
              type="select"
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={errors.collectionPlace !== undefined}
              valid={touched.collectionPlace && !errors.collectionPlace}
              disabled={typeof selectedAgentIndex !== 'number' || selectedAgentIndex === -1}
              value={values.collectionPlace}
            >
              <option value="SELECT">
                {t('protocols:NewProtocolModal.CollectionPlace.selectPlaceholder')}
              </option>
              {this.renderCollectionPlaces()}
            </Input>
            {touched.place && errors.place && <FormFeedback>{errors.place}</FormFeedback>}
          </FormGroup>
        </div>
        <Row className="pt-5">
          <Col className="d-flex justify-content-center">
            <Button color="secondary" type="submit" disabled={isSubmitting}>
              {t('protocols:NewProtocolModal.submitButton')}
            </Button>
          </Col>
        </Row>
      </Form>
    );
  };

  handleAgentChange = (setFieldValue) => (e) => {
    const { name, value } = e.target;

    setFieldValue(name, value);

    const state = produce(this.state, (draft) => {
      draft.selectedAgentIndex = this.data.findIndex((item) => item.agent.id === value);
    });
    this.setState(state);
  };

  handleProtocolCreate = (t) => {
    toast.success(t('protocols:Banner.protocolCreateSuccess'));
  };

  emitCancel = () => {
    const { onCancel } = this.props;

    this.setState({ selectedAgentIndex: null });
    onCancel();
  };

  renderCollectionPlaces() {
    const { selectedAgentIndex } = this.state;

    return this.data[selectedAgentIndex] && this.data[selectedAgentIndex].collectionPlaces.map((item) => (
      <option value={item.collectionPlace.id} key={item.collectionPlace.id}>
        {item.collectionPlace.name}
      </option>
    ));
  }

  renderAgents() {
    return this.data.map((item) => (
      <option value={item.agent.id} key={item.agent.id}>
        {item.agent.name}
      </option>
    ));
  }

  render() {
    const { t, isOpen } = this.props;

    return (
      <CustomQuery query={GET_AGENTS_AND_COLLECTION_PLACES}>
        {({ data, error, loading }) => {
          if (loading) return null;
          if (error) return null;

          this.data = data.deliveryCollectionProtocolCreatingInformation;

          return (
            <ApolloConsumer>
              {(client) => (
                <Modal
                  isOpen={isOpen}
                  toggle={this.emitCancel}
                  centered
                  modalTransition={{ timeout: 150 }}
                  onOpened={this.resetData}
                >
                  <ModalHeader toggle={this.emitCancel}>
                    {t('protocols:NewProtocolModal.title')}
                  </ModalHeader>
                  <ModalBody className="pt-4">
                    <Formik
                      initialValues={{
                        agent: '',
                        collectionPlace: '',
                      }}
                      onSubmit={async (values) => {
                        const { onPrompt } = this.props;

                        await customManualMutation({
                          client,
                          mutation: CREATE_PROTOCOL,
                          variables: {
                            agentId: Number(values.agent),
                            collectionPlaceId: Number(
                              values.collectionPlace,
                            ),
                          },
                        });

                        onPrompt();
                        this.handleProtocolCreate(t);
                      }}
                      validationSchema={Yup.object()
                        .shape({
                          agent: Yup.string()
                            .hasSelectDefaultValue(t, t('protocols:NewProtocolModal.Agent.required'))
                            .required(t('protocols:NewProtocolModal.Agent.required')),
                          collectionPlace: Yup.string()
                            .hasSelectDefaultValue(t, t('protocols:NewProtocolModal.CollectionPlace.required'))
                            .required(t('protocols:NewProtocolModal.CollectionPlace.required')),
                        })}
                      component={this.form}
                    />
                  </ModalBody>
                </Modal>
              )}
            </ApolloConsumer>
          );
        }}
      </CustomQuery>
    );
  }
}

ProtocolsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onPrompt: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default withTranslation(['protocols'])(ProtocolsModal);
