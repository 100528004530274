import React from 'react';

import NoteWithCategory from './NoteWithCategory';
import NoteWithoutCategory from './NoteWithoutCategory';

const NoteAccordion = (props) => (
  <>
    {/* eslint-disable-next-line react/destructuring-assignment */}
    {props.events[0].category ? <NoteWithCategory {...props} /> : <NoteWithoutCategory {...props} />}
  </>
);

export default NoteAccordion;
