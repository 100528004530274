import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  MutationMarketingCampaignActivate,
  MutationMarketingCampaignDeactivate,
  MutationMarketingCampaignDelete,
  QueryMarketingCampaignsListData,
} from 'Containers/Settings/Branding/MarketingCampaigns/typesAndDefaults';
import { DATE_FORMAT_BRANDING } from 'Containers/Settings/Branding/typesAndDefaults';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Button, Badge } from 'reactstrap';
import { Link } from 'react-router-dom';
import { toast } from '#/shared/utilities';
import EmptyState from '~/components/EmptyState/EmptyState';
import FoxLoader from '~/components/FoxLoader/FoxLoader';
import SimpleDropdown from '~/components/SimpleDropdown/SimpleDropdown';
import SimpleModal, { ModalData } from '~/components/SimpleModal/SimpleModal';
import SimpleTable from '~/components/SimpleTable/SimpleTable';
import useAuthMutation from '~/hooks/useAuthMutation';
import useAuthQuery from '~/hooks/useAuthQuery';
import { format } from '~/services/date';
import statusToBadgeMapper from '../helpers/statusToBadgeMapper';

import MARKETING_CAMPAIGN_DELETE from './queries/deleteMarketingCampaign.gql';
import MARKETING_CAMPAIGNS_LIST from './queries/fetchMarketingCampaigns.gql';
import MARKETING_CAMPAIGN_ACTIVATE from './queries/activateMarketingCampaign.gql';
import MARKETING_CAMPAIGN_DEACTIVATE from './queries/deactivateMarketingCampaign.gql';

const MarketingCampaigns: React.FC = () => {
  const { t } = useTranslation(['settings']);
  const [modalData, setModalData] = React.useState<ModalData>();
  const [modalIsOpen, setModalIsOpen] = React.useState(false);

  const {
    data: marketingCampaignsDataQuery,
    loading: marketingCampaignsLoadingQuery,
    refetch: marketingCampaignsRefetchQuery,
  } = useAuthQuery<QueryMarketingCampaignsListData>(MARKETING_CAMPAIGNS_LIST);

  const [deleteMarketingCampaignMutation] = useAuthMutation<MutationMarketingCampaignDelete, { id: string }>(MARKETING_CAMPAIGN_DELETE);
  const [activateMarketingCampaignMutation] = useAuthMutation<MutationMarketingCampaignActivate, { id: string }>(MARKETING_CAMPAIGN_ACTIVATE);
  const [deactivateMarketingCampaignMutation] = useAuthMutation<MutationMarketingCampaignDeactivate>(MARKETING_CAMPAIGN_DEACTIVATE);

  const marketingCampaigns = (marketingCampaignsDataQuery?.eshop.eshopCampaigns || []).sort((a, b) => parseInt(b.id, 10) - parseInt(a.id, 10));
  const activeMarketingCampaign = marketingCampaignsDataQuery?.eshop.activeEshopCampaign;

  const deleteMarketingCampaign = async (id: string) => {
    try {
      await deleteMarketingCampaignMutation({ variables: { id } });
      toast.success(t('settings:Branding.MarketingCampaigns.Message.templateDeleted'));
      await marketingCampaignsRefetchQuery();
    } catch (e) {
      // Handled in global error interceptor
    }
  };

  const activateMarketingCampaign = async (id: string) => {
    try {
      await activateMarketingCampaignMutation({ variables: { id } });
      toast.success(t('settings:Branding.MarketingCampaigns.Message.templateActivated'));
      await marketingCampaignsRefetchQuery();
    } catch (e) {
      // Handled in global error interceptor
    }
  };

  const deactivateMarketingCampaign = async () => {
    try {
      await deactivateMarketingCampaignMutation();
      toast.success(t('settings:Branding.MarketingCampaigns.Message.templateDeactivated'));
      await marketingCampaignsRefetchQuery();
    } catch (e) {
      // Handled in global error interceptor
    }
  };

  const openActivateModal = (itemForActivation: string) => {
    setModalData({
      title: t('settings:Branding.MarketingCampaigns.List.ActivateModal.title'),
      description: t('settings:Branding.MarketingCampaigns.List.ActivateModal.description'),
      cancelButton: t('settings:Branding.MarketingCampaigns.List.ActivateModal.cancelButton'),
      submitButton: t('settings:Branding.MarketingCampaigns.List.ActivateModal.submitButton'),
      onActionItem: () => activateMarketingCampaign(itemForActivation),
      submitButtonColor: 'primary',
    });

    setModalIsOpen(!modalIsOpen);
  };

  const openDeactivateModal = async () => {
    setModalData({
      title: t('settings:Branding.MarketingCampaigns.List.DeactivateModal.title'),
      description: t('settings:Branding.MarketingCampaigns.List.DeactivateModal.description'),
      cancelButton: t('settings:Branding.MarketingCampaigns.List.DeactivateModal.cancelButton'),
      submitButton: t('settings:Branding.MarketingCampaigns.List.DeactivateModal.submitButton'),
      onActionItem: () => deactivateMarketingCampaign(),
      submitButtonColor: 'danger',
    });

    setModalIsOpen(!modalIsOpen);
  };

  const openDeleteModal = async (itemForDeletion: string) => {
    setModalData({
      title: t('settings:Branding.MarketingCampaigns.List.DeleteModal.title'),
      description: t('settings:Branding.MarketingCampaigns.List.DeleteModal.description'),
      cancelButton: t('settings:Branding.MarketingCampaigns.List.DeleteModal.cancelButton'),
      submitButton: t('settings:Branding.MarketingCampaigns.List.DeleteModal.submitButton'),
      onActionItem: () => deleteMarketingCampaign(itemForDeletion),
      submitButtonColor: 'danger',
    });

    setModalIsOpen(!modalIsOpen);
  };

  const tableTitles = [
    { title: t('settings:Branding.MarketingCampaigns.List.Headers.campaignName'), iconName: 'tag' },
    { title: t('settings:Branding.MarketingCampaigns.List.Headers.lastUpdate'), iconName: ['far', 'clock'] },
    // { title: t('settings:Branding.MarketingCampaigns.List.Headers.views'), iconName: ['far', 'eye'], className: 'text-center' },
    // { title: t('settings:Branding.MarketingCampaigns.List.Headers.clicks'), iconName: 'mouse-pointer', className: 'text-center' },
    // { title: t('settings:Branding.MarketingCampaigns.List.Headers.leads'), iconName: 'chart-line', className: 'text-center' },
    { title: t('settings:Branding.MarketingCampaigns.List.Headers.status'), iconName: 'check', className: 'text-center' },
  ];

  const actionItems = (campaignId: string) => {
    return [
      activeMarketingCampaign?.id === campaignId ? {
        key: 'deactivate',
        title: t('settings:Branding.MarketingCampaigns.List.Actions.deactivate'),
        onClick: () => openDeactivateModal(),
      } : {
        key: 'activate',
        title: t('settings:Branding.MarketingCampaigns.List.Actions.activate'),
        onClick: () => openActivateModal(campaignId),
      },
      {
        key: 'edit',
        title: t('settings:Branding.MarketingCampaigns.List.Actions.edit'),
        url: `/settings/branding/marketing-campaigns/${campaignId}`,
      },
      {
        key: 'delete',
        title: t('settings:Branding.MarketingCampaigns.List.Actions.delete'),
        onClick: () => openDeleteModal(campaignId),
        className: 'text-danger',
      },
    ];
  };

  const tableRows = marketingCampaigns.map((marketingCampaign) => ({
    key: marketingCampaign.id,
    cells: [
      { key: `${marketingCampaign.id}1`, value: marketingCampaign.campaignName, onClickUrl: `/settings/branding/marketing-campaigns/${marketingCampaign.id}` },
      {
        key: `${marketingCampaign.id}2`,
        onClickUrl: `/settings/branding/marketing-campaigns/${marketingCampaign.id}`,
        value: format(new Date(marketingCampaign.updatedAt), DATE_FORMAT_BRANDING),
      },
      // { key: 'x', onClickUrl: `/settings/branding/marketing-campaigns/${marketingCampaign.id}`, value: marketingCampaign.views },
      // { key: 'x', onClickUrl: `/settings/branding/marketing-campaigns/${marketingCampaign.id}`, value: marketingCampaign.clicks },
      // { key: 'x', onClickUrl: `/settings/branding/marketing-campaigns/${marketingCampaign.id}`, value: marketingCampaign.leads },
      {
        key: `${marketingCampaign.id}3`,
        className: 'text-center',
        onClickUrl: `/settings/branding/marketing-campaigns/${marketingCampaign.id}`,
        value: (
          <Badge color={statusToBadgeMapper(activeMarketingCampaign?.id === marketingCampaign.id)}>
            { t(`settings:Branding.MarketingCampaigns.Statuses.${activeMarketingCampaign?.id === marketingCampaign.id ? 'active' : 'inactive'}`) }
          </Badge>
        ),
      },
      {
        key: `${marketingCampaign.id}4`,
        className: 'text-right',
        value: (
          <SimpleDropdown items={actionItems(marketingCampaign.id)} text={<FontAwesomeIcon icon={['fas', 'ellipsis-h']} />} />
        ),
      },
    ],
  }));

  const header = (
    <Row className="align-items-center right-col-spacing marketing-campaigns-title">
      <Col md={8}>
        <h1>{ t('settings:Branding.MarketingCampaigns.List.title') }</h1>
      </Col>
      <Col sm={4} className="text-right">
        <Link to="/settings/branding/marketing-campaigns/new" data-test="new-marketing-campaign-button">
          <Button color="primary" size="xs" className="align-middle">
            <FontAwesomeIcon icon={['fas', 'plus']} fixedWidth />
            {t('settings:Branding.MarketingCampaigns.newCampaignButton')}
          </Button>
        </Link>
      </Col>
    </Row>
  );

  let content = (
    <>
      {header}
      <EmptyState
        imgSrc="/assets/img/empty-states/in-progress.png"
        imgDescription={t('settings:Branding.MarketingCampaigns.List.EmptyState.imageDescription')}
        title={t('settings:Branding.MarketingCampaigns.List.EmptyState.title')}
        description={t('settings:Branding.MarketingCampaigns.List.EmptyState.description')}
      />
    </>
  );

  if (marketingCampaigns && marketingCampaigns.length > 0) {
    content = (
      <>
        {header}
        <SimpleTable variant="boxed" headers={tableTitles} rows={tableRows} />
        {modalData && (
          <SimpleModal
            isOpen={modalIsOpen}
            toggle={() => setModalIsOpen(!modalIsOpen)}
            onActionItem={modalData.onActionItem}
            title={modalData.title}
            description={modalData.description}
            cancelButton={modalData.cancelButton}
            submitButton={modalData.submitButton}
            submitButtonColor={modalData.submitButtonColor}
          />
        )}
      </>
    );
  }

  return (
    <>
      {marketingCampaignsLoadingQuery ? (<FoxLoader withOverlay={false} />) : (content)}
    </>
  );
};

export default MarketingCampaigns;
