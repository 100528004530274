import React from 'react';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { cssMerge } from '~/services/utils';
import css from './UpsellState.module.scss';
import configurator from '~/services/Configurator';

export type UpsellStateDescriptionType = 'dashboard' | 'trackAndTrace' | 'emails' | 'campaigns' | 'importantDeliveries';

export const UpsellStateDescription: {[key: string]: UpsellStateDescriptionType} = {
  dashboard: 'dashboard',
  trackAndTrace: 'trackAndTrace',
  emails: 'emails',
  campaigns: 'campaigns',
  importantDeliveries: 'importantDeliveries',
};

type Props = {
  description: UpsellStateDescriptionType;
  backdrop?: boolean;
  className?: string;
}

const UpsellState: React.FC<Props> = ({
  description,
  backdrop = true,
  className,
}) => {
  const { t } = useTranslation(['common']);

  return (
    <div className={cssMerge(
      backdrop && css.backdrop,
      'd-flex justify-content-center align-items-start',
    )}
    >
      <div className={cssMerge(
        css.upsellState,
        className,
        'text-center d-flex flex-column align-items-center justify-content-end',
      )}
      >
        <img
          src="/assets/img/upgrade.png"
          alt="Upgrade licence"
          width={190}
          height={176}
        />

        <h2 className={`${css.title} mb-0`}>
          {t('common:UpsellState.title')}
        </h2>

        <p className={`${css.description} mx-auto mt-4 mb-0`}>
          {t('common:UpsellState.description', { feature: t(`common:UpsellState.Feature.${description}`) })}
        </p>

        <Button
          color="primary"
          className="mt-4"
          href={`${configurator.config.iamUrl}/upgrade-licence`}
        >
          {t('common:UpsellState.button')}
        </Button>
      </div>
    </div>
  );
};

export default UpsellState;
