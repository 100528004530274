import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ApolloProvider } from 'react-apollo';
import { toast, ToastContainer } from 'react-toastify';
import { setAutoFreeze } from 'immer';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css';
import 'react-datepicker/dist/react-datepicker.css';

import App from 'Containers/App/App';
import store from 'Instances/store.instance';
import 'Instances/moment.instance';
import graphqlStorage from 'Services/GraphqlStorage';
import ServiceLocator from 'Containers/ServiceLocator/ServiceLocator';
import configurator from '~/services/Configurator';
import './app.scss';
import './2020/i18n';

library.add(
  fas,
  far,
);

if (configurator.env === 'production') {
  setAutoFreeze(false);
}

const Main = () => (
  <ApolloProvider client={graphqlStorage.client}>
    <Provider store={store}>
      <ServiceLocator>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <App />
        </BrowserRouter>
      </ServiceLocator>
      <ToastContainer
        position={toast.POSITION.TOP_CENTER}
      />
    </Provider>
  </ApolloProvider>
);

ReactDOM.render(<Main />, document.getElementById('root'));
