/**
 * https://developer.helpscout.com/beacon-2/web/javascript-api/
 */

declare global {
  interface Window {
    Beacon: any;
  }
}

type BeaconSuggestItem = string | { text: string; url: string };

type BeaconNavigate =
  | '/'
  | '/ask/'
  | '/ask/message/'
  | '/ask/chat/'
  | '/answers/'
  | '/previous-messages/'
  | `/docs/search?query=${string}`;

interface BeaconIdentifyUserData {
  name?: string;
  email?: string;
  company?: string;
  jobTitle?: string;
  avatar?: string;
  signature?: string;

  [key: string]: string | undefined;
}

interface BeaconEventData {
  type: string;
  url: string;
  title: string;
}

interface BeaconLogoutOptions {
  endActiveChat?: boolean;
}

interface BeaconShowMessageOptions {
  delay?: number;
  force: boolean;
}

interface BeaconFn {
  (e: 'info'): any;
  (e: 'destroy'): void;
  (e: 'open'): void;
  (e: 'close'): void;
  (e: 'toggle'): void;
  (e: 'reset'): void;
  (e: 'init', beaconId: string): void;
  (e: 'search', query: string): void;
  (e: 'navigate', to: BeaconNavigate): void;
  (e: 'prefill', formData: any): void;
  (e: 'config', options: any): void;
  (e: 'session-data', data: any): void;
  (e: 'suggest', suggests: BeaconSuggestItem[]): void;
  (e: 'identify', userData: BeaconIdentifyUserData): void;
  (e: 'logout', options?: BeaconLogoutOptions): void;
  (e: 'event', eventData: BeaconEventData): void;
  (e: 'on', event: string, callback: () => void): void;
  (e: 'once', event: string, callback: () => void): void;
  (e: 'off', event: string, callback?: () => void): void;
  (e: 'show-message', messageId: string, options: BeaconShowMessageOptions): void;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line consistent-return
export const beaconSafe: BeaconFn = (e, ...args) => {
  if (window?.Beacon) {
    return window.Beacon(e, ...args);
  }
};
