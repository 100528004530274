import React from 'react';
import { Label, Input, FormFeedback, InputProps } from 'reactstrap';
import { useCommonField } from 'foxdeli-shared';

type Props = InputProps & {
  name: string;
  label?: string;
  disabled?: boolean;
  valid?: boolean;
  onBlur?: (event?: React.FocusEvent<any>) => void;
  onChange?: (event?: React.ChangeEvent<any>) => void;
}

const InputField: React.FC<Props> = ({
  name,
  label,
  disabled = false,
  onBlur,
  onChange,
  ...props
}) => {
  const [field, meta, , flags] = useCommonField(name);

  const labelEl = label && (
    <Label htmlFor={field.name}>
      {label}
    </Label>
  );

  const handleBlur = onBlur
    ? (event: React.FocusEvent<any>): void => {
      onBlur(event);
      field.onBlur(event);
    }
    : field.onBlur;

  const handleChange = onChange
    ? (event: React.ChangeEvent<any>): void => {
      onChange(event);
      field.onChange(event);
    }
    : field.onChange;

  /** Input does not like when backend returns null */
  const sanitizeNull = (val: any): string => (val === null ? '' : val);

  return (
    <>
      {labelEl}

      <Input
        {...field}
        {...props}
        value={sanitizeNull(field.value)}
        id={field.name}
        data-test={`input-${field.name}`}
        disabled={disabled}
        invalid={flags.isInvalid}
        valid={flags.isValid}
        onBlur={handleBlur}
        onChange={handleChange}
      />

      {flags.hasFeedback && (
        <FormFeedback>
          {meta.error}
        </FormFeedback>
      )}
    </>
  );
};

export default InputField;
