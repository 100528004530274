import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import gql from 'graphql-tag';
import jwtDecode from 'jwt-decode';

import configurator from '~/services/Configurator';
import graphqlStorage from './GraphqlStorage';
import AuthenticationHelper from '../../2020/services/Auth';

export default class UserDeliveryInteractionTracking {
  constructor() {
    this.gqlClient = graphqlStorage.client;
    this.config = configurator.config.firebase;
    this.user = {
      userId: null,
      name: null,
      surname: null,
      companyId: null,
    };

    if (this.config.apiKey) {
      try {
        firebase.initializeApp(this.config);
      } catch (e) {
        console.error('Firebase initialization failed.');
        console.error(e);
      }
    }
  }

  async setUser(userId, userName, userSurname, companyId) {
    this.user = {
      userId,
      userName,
      userSurname,
      companyId,
    };

    try {
      const token = await this.fetchFirebaseToken();

      if (token) {
        await firebase.auth().signInWithCustomToken(token);
      }
    } catch (e) {
      console.error('Firebase authentication failed.');
      console.error(e);
    }
  }

  async fetchFirebaseToken() {
    if (!AuthenticationHelper.firebaseToken) {
      const response = await this.gqlClient.query({
        query: gql`
          query firebaseToken {
            firebaseToken
          }
        `,
        context: { headers: { AccessToken: AuthenticationHelper.accessToken } },
      });

      if (response.data && response.data.firebaseToken) {
        const decodedToken = jwtDecode(response.data.firebaseToken);
        let expiration = new Date();

        if (decodedToken.exp) {
          // `exp * 1000` since exp is in seconds and the Date constructor expects milliseconds
          expiration = new Date(decodedToken.exp * 1000);
        } else {
          /**
           * Subtracting 60 seconds to account for possible client-server delay.
           * 3600 seconds is set on backend (and is also the maximum token expiration)
           * See https://firebase.google.com/docs/auth/admin/create-custom-tokens#create_custom_tokens_using_a_third-party_jwt_library
           */
          expiration.setSeconds(expiration.getSeconds() + (3600 - 60));
        }

        AuthenticationHelper.setFirebaseToken(response.data.firebaseToken, expiration);
      }
    }

    return AuthenticationHelper.firebaseToken;
  }

  createTrackDeliveryEditRef = (deliveryId) => firebase.database().ref(
    `clients/${this.user.companyId}/deliveriesInUse/${deliveryId}/users/${this.user.userId}`,
  );

  trackUserDeliveryEdit = async (deliveryId) => {
    const userRef = this.createTrackDeliveryEditRef(deliveryId);

    await Promise.all([
      userRef.set(this.user),
      userRef.onDisconnect().set(null),
    ]);
  };

  addUserDeliveryEditListener = (cb, deliveryId) => firebase
    .database()
    .ref(`clients/${this.user.companyId}/deliveriesInUse/${deliveryId}/users`)
    .on('value', cb);

  untrackUserDeliveryEdit = async (deliveryId) => {
    const userRef = this.createTrackDeliveryEditRef(deliveryId);

    await Promise.all([userRef.set(null), userRef.onDisconnect().cancel()]);
  };
}
