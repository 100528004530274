import React from 'react';

import css from './EmptyState.module.scss';
import { cssMerge } from '~/services/utils';

type Props = {
  imgSrc?: string;
  imgDescription?: string;
  title: string;
  description: string;
  className?: string;
}

const EmptyState: React.FC<Props> = ({
  imgSrc,
  imgDescription = '',
  title,
  description,
  className,
  children,
}) => {
  return (
    <div className={cssMerge(
      css.emptyState,
      className,
      'text-center',
    )}
    >
      {imgSrc && (
        <img
          src={imgSrc}
          alt={imgDescription}
          width={400}
          height={280}
        />
      )}

      <h2 className={`${css.title} mt-5`}>
        {title}
      </h2>
      <p className={`${css.description} mx-auto mt-4 mb-0`}>
        {description}
      </p>

      {children && (
        <div className="mt-4">
          {children}
        </div>
      )}
    </div>
  );
};

export default EmptyState;
