import React from 'react';
import { Col, Row } from 'reactstrap';
import { generate } from 'shortid';
import { divide, formatNumber, rx } from '~/services/utils';
import ProgressList, { ProgressItem } from '~/App/[Dashboard]/ProgressList/ProgressList';
import { DeliveryDuration } from '~/types/generated/graphql';

import css from './DurationTab.module.scss';

export const prepareProgressItems = (deliveryDuration: DeliveryDuration): ProgressItem[] => {
  const durations = [
    deliveryDuration.d0,
    deliveryDuration.d1,
    deliveryDuration.d2,
    deliveryDuration.d3,
    deliveryDuration.d4,
    deliveryDuration.d5Plus,
  ].map((duration) => (duration === null ? 0 : duration));

  const total = durations.reduce((acc, val) => acc + val);
  const max = Math.max(...durations);

  return durations.map((item, index) => ({
    label: `D+${index}${index === durations.length - 1 ? '+' : ''}`,
    value: rx(item, max),
    key: generate(),
    formattedValue: formatNumber(divide(item, total), { style: 'percent', maximumFractionDigits: 1 }),
  }));
};

type Props = {
  subtitle: string;
  deliveryDuration: DeliveryDuration;
}

const DurationTab: React.FC<Props> = ({
  subtitle,
  deliveryDuration,
}) => {
  return (
    <Row>
      <Col sm="12">
        <div className={`${css.title} mb-0`}>
          {formatNumber(deliveryDuration.average, { maximumFractionDigits: 1 })}
          <span className={css.titleSuffix}>
            {' D'}
          </span>
        </div>
        <div className={`${css.subtitle} mb-3`}>{subtitle}</div>

        <ProgressList
          className={css.progressList}
          items={prepareProgressItems(deliveryDuration)}
        />
      </Col>
    </Row>
  );
};

export default DurationTab;
