import React, { useState, useEffect } from 'react';
import { Spinner } from 'reactstrap';

import { useTokenQuery } from 'Hooks/Apollo/useTokenQuery';
import { toast } from 'Shared/utilities';
import { URLQueryHandler } from 'Tools/URLQueryHandler';
import Base64Helper from 'Tools/Base64Helper';
import { useTokenMutation } from 'Hooks/Apollo/useTokenMutation';
import MainBody from 'Components/Presentational/Layout/MainBody/MainBody';
import AuthorizeForm from './Presentational/AuthorizeForm/AuthorizeForm';
import AuthorizeError from './Presentational/AuthorizeError/AuthorizeError';

import { AUTHORIZE_CLIENT, GET_LOGGED_USER_PERMISSSIONS, GET_OAUTH_CLIENT, GET_OAUTH_SCOPES } from './Queries';

const redirectLinkHelperRef = React.createRef();

export default function Authorize({ history }) {
  const b64 = new Base64Helper();
  const urlParams = URLQueryHandler.getParsedQueryString();
  const [scopes, setScopes] = useState(null);

  const [error, setError] = useState({
    isInError: false,
    error: null,
    errorDescription: null,
  });

  function redirectNonPrimaryAccount(isPrimaryAccount) {
    if (!isPrimaryAccount) {
      history.push('/');
      toast.error('Nemáte dostatečná oprávnění schvalovat API přístupy. Toto rozhodnutí může dělat pouze hlavní účet.');
    }
  }

  function getScopesByNames(allScopes, urlScopes) {
    return urlScopes.map((urlScope) => allScopes.find((scope) => scope.name === urlScope));
  }

  const { loading: LoggedUserQueryLoading, error: LoggedUserQueryError } = useTokenQuery(GET_LOGGED_USER_PERMISSSIONS, {
    onCompleted: (data) => {
      redirectNonPrimaryAccount(data.loggedUserInfo.account.primaryAccess);
    },
  });

  const {
    loading: OAuthClientQueryLoading,
    error: OAuthClientQueryError,
    data: OAuthClientQueryData,
  } = useTokenQuery(GET_OAUTH_CLIENT, { variables: { identifier: urlParams.client_id } });

  const { loading: OAuthScopeQueryLoading, error: OAuthScopeQueryError, data: OAuthScopeQueryData } = useTokenQuery(GET_OAUTH_SCOPES);
  const [dispatchAuthorizeMutation] = useTokenMutation(AUTHORIZE_CLIENT);

  useEffect(() => {
    if (!OAuthScopeQueryData || Object.keys(OAuthScopeQueryData).length === 0) return;

    const { oAuthScopes } = OAuthScopeQueryData;

    if (urlParams.scope) {
      setScopes(getScopesByNames(oAuthScopes, urlParams.scope.split(' ')));
    }
  }, [OAuthScopeQueryData]);

  function handleAuthSuccess(redirectUri, code) {
    const { current: redirectLink } = redirectLinkHelperRef;

    // TODO: window.location.replace?
    redirectLink.setAttribute(
      'href',
      `${redirectUri}?code=${code}&state=${urlParams.state}`,
    );
    redirectLink.click();
  }

  function handleAuthError(errorCode, redirectUri, errorType, errorMessage, state) {
    if (errorCode === 302 && redirectUri && errorType && errorMessage && state) {
      const { current: redirectLink } = redirectLinkHelperRef;

      // TODO: window.location.replace?
      redirectLink.setAttribute(
        'href',
        `${redirectUri}?error=${errorType}&errorDescription=${errorMessage}&state=${state}`,
      );
      redirectLink.click();
    }

    if (errorCode === 402) {
      setError({
        isInError: true,
        error: errorType,
        errorDescription: errorMessage,
      });
    }
  }

  function handleAuthorize(redirectUri) {
    dispatchAuthorizeMutation({
      variables: {
        request: {
          identifier: urlParams.client_id,
          responseType: urlParams.response_type,
          scope: urlParams.scope,
          state: urlParams.state,
          redirectUri,
        },
      },
    }).then((response) => {
      const { data } = response;
      const { authorizeOAuthClient } = data;
      const { success, code, errorType, errorMessage, errorCode } = authorizeOAuthClient;

      if (success) {
        handleAuthSuccess(redirectUri, code);
      } else {
        handleAuthError(
          errorCode,
          redirectUri,
          errorType,
          errorMessage,
          urlParams.state,
        );
      }
    }).catch(() => setError({
      isInError: true,
      error: null,
      errorDescription: null,
    }));
  }

  if (!urlParams.client_id) {
    return (
      <AuthorizeError
        error="invalid_client"
        errorDescription="The client id supplied is invalid"
      />
    );
  }

  if (OAuthClientQueryLoading || LoggedUserQueryLoading || OAuthScopeQueryLoading || !scopes) {
    return <Spinner />;
  }

  if (OAuthClientQueryError || LoggedUserQueryError || OAuthScopeQueryError) {
    return (
      <AuthorizeError
        error="Chyba při načítání dotazu."
        errorDescription="Nastala chyba při načítání dotazu."
      />
    );
  }

  const { oAuthClientByIdentifier } = OAuthClientQueryData;
  const { companyName, logo, redirectUri } = oAuthClientByIdentifier;
  const { isInError, error: errorType, errorDescription } = error;

  if (isInError) {
    return (
      <AuthorizeError
        error={errorType}
        errorDescription={errorDescription}
      />
    );
  }

  return (
    <MainBody wrapperHeight="150px" col={{ xs: 12, sm: 12, md: 8 }}>
      <AuthorizeForm
        companyLogoUrl={logo ? Base64Helper.getUrlFromBlob(b64.convertBase64ToBlob(logo)) : logo}
        companyName={companyName}
        redirectUri={redirectUri}
        scopes={scopes}
        authorizeOAuthClient={() => handleAuthorize(redirectUri)}
      />

      {/* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid */}
      <a target="_blank" rel="noopener noreferrer" ref={redirectLinkHelperRef} />
    </MainBody>
  );
}
