import React from 'react';
import { Button, CustomInput, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FieldArray } from 'formik';

import ToggleAllCheckboxesButton from '../ToggleAllCheckboxesButton/ToggleAllCheckboxesButton';

const DeliveryTypeModalUI = ({
  agent,
  isModalVisible,
  onSubmit,
  formik,
  toggleModal,
  toggleAllCheckboxesChecked,
  services,
}) => {
  const { t } = useTranslation('settings');
  const { handleChange, values } = formik;

  const createCheckboxComponents = () => values.deliveryTypes.map((item, index) => (
    <div key={item.id}>
      <CustomInput
        type="checkbox"
        name={`deliveryTypes.${index}.value`}
        checked={values.deliveryTypes[index].value === true}
        onChange={handleChange}
        id={`deliveryTypes.${index}`}
        label={`${item.name} (${item.abbr})`}
        className="checkbox-modal"
      />
    </div>
  ));

  return (
    <>
      <Button
        onClick={toggleModal}
        className=""
        type="button"
      >
        {services.length > 1 ? t('settings:Agents.DeliveryServices.Button.edit') : t('settings:Agents.DeliveryServices.Button.add')}
      </Button>

      <Modal isOpen={isModalVisible} toggle={toggleModal} centered>
        <ModalHeader>{agent.fullName}</ModalHeader>
        <ModalBody className="pt-0">
          <FieldArray name="deliveryTypes">
            <>
              <ToggleAllCheckboxesButton
                id="deliveryTypeCheckToggle"
                toggleCheckboxCheck={() => toggleAllCheckboxesChecked(formik)}
                values={values.deliveryTypes.map((dt) => dt.value)}
              />
              {createCheckboxComponents()}
            </>
          </FieldArray>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            type="button"
            onClick={() => onSubmit(values)}
          >
            {t('settings:Agents.DeliveryServices.EditModal.submitButton')}
          </Button>
          <Button
            color="link"
            type="button"
            onClick={toggleModal}
          >
            {t('settings:Agents.DeliveryServices.EditModal.cancelButton')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

DeliveryTypeModalUI.propTypes = {
  agent: PropTypes.shape({
    fullName: PropTypes.string,
    deliveryTypes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        abbr: PropTypes.string,
        __typename: PropTypes.string,
      }),
    ),
    __typename: PropTypes.string,
  }).isRequired,
  isModalVisible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  formik: PropTypes.shape({}).isRequired,
  services: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default DeliveryTypeModalUI;
