import React from 'react';
import { CustomInput } from 'reactstrap';

import { generateReactKey } from '#/shared/utilities';
import { Filter } from '../types';

type Props = {
  filters: Filter[];
  onChange: (e: any) => void;
}

const TimelineFilters: React.FC<Props> = ({ filters, onChange }) => {
  const filterList = filters.map((check) => (
    <CustomInput
      key={check.id || generateReactKey()}
      id={check.id || generateReactKey()}
      type="checkbox"
      checked={check.value}
      onChange={onChange}
      name={check.name}
      className={check.name}
      label={check.label}
    />
  ));

  return (
    <>
      {filterList}
    </>
  );
};

export default TimelineFilters;
