import React from 'react';

import { generateReactKey } from 'Shared/utilities';
import TimelineIconAccordion from '../_TimelineIconAccordion/TimelineIconAccordion';

const StateWithoutCategory = ({ events }) => (
  <>
    {events.map((event) => (
      <TimelineIconAccordion
        key={generateReactKey()}
        theme="dark"
        icon={{ type: 'fw', icon: ['far', 'circle'] }}
        className="trackAndTrace"
        title={(
          <>
            <strong style={{ display: 'block' }}>{event.title}</strong>
            <span>{event.on}</span>
          </>
        )}
      />
    ))}
  </>
);

export default StateWithoutCategory;
