import { useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormFeedback, FormGroup, Label, Row } from 'reactstrap';
import { ValueType } from 'react-select';

import { CollectionPlaceFormValues } from './CreateCollectionPlaceForm';
import InputField from '~/components/InputField/InputField';
import CountrySelect, { Option } from '#/Components/Containers/Deliveries/New/CountrySelect/CountrySelect';

const SenderForm: React.FC = () => {
  const { t } = useTranslation(['settings', 'validation']);

  const {
    values,
    setFieldValue,
    handleBlur,
    touched,
    errors,
  } = useFormikContext<CollectionPlaceFormValues>();

  const handleChange = (field: string, option: ValueType<Option>) => {
    const selectedCountry = Array.isArray(option) ? option[0] : option;

    setFieldValue(field, selectedCountry.value);
  };

  return (
    <>
      <h4 className="body font-weight-bold">
        {t('settings:Agents.CreateCollectionPlace.senderTitle')}
      </h4>
      <FormGroup>
        <Label>
          {t('validation:CompanyName.label')}
          <span className="text-danger">*</span>
        </Label>
        <InputField
          name="senderAddressCompanyName"
          type="text"
          placeholder={t('validation:CompanyName.placeholder')}
        />
      </FormGroup>
      <FormGroup>
        <Label>
          {t('settings:Agents.CreateCollectionPlace.Sender.Street.label')}
          <span className="text-danger">*</span>
        </Label>
        <InputField
          name="senderAddressStreet"
          type="text"
          placeholder={t('settings:Agents.CreateCollectionPlace.Sender.Street.placeholder')}
        />
      </FormGroup>
      <Row>
        <Col xs={12} sm={6}>
          <FormGroup>
            <Label>
              {t('settings:Agents.CreateCollectionPlace.Sender.HouseNumber.label')}
              <span className="text-danger">*</span>
            </Label>
            <InputField
              name="senderAddressHouseNumber"
              type="text"
              placeholder={t('settings:Agents.CreateCollectionPlace.Sender.HouseNumber.placeholder')}
            />
          </FormGroup>
        </Col>
        <Col xs={12} sm={6}>
          <FormGroup>
            <Label>{t('settings:Agents.CreateCollectionPlace.Sender.SequenceNumber.label')}</Label>
            <InputField
              name="senderAddressSequenceNumber"
              type="text"
              placeholder={t('settings:Agents.CreateCollectionPlace.Sender.SequenceNumber.placeholder')}
            />
          </FormGroup>
        </Col>
      </Row>
      <FormGroup>
        <Label>
          {t('validation:City.label')}
          <span className="text-danger">*</span>
        </Label>
        <InputField
          name="senderAddressCity"
          type="text"
          placeholder={t('validation:City.placeholder')}
        />
      </FormGroup>
      <Row>
        <Col xs={12} sm={8}>
          <FormGroup>
            <Label>{t('settings:Agents.CreateCollectionPlace.Sender.District.label')}</Label>
            <InputField
              name="senderAddressPartCity"
              type="text"
              placeholder={t('settings:Agents.CreateCollectionPlace.Sender.District.placeholder')}
            />
          </FormGroup>
        </Col>
        <Col xs={12} sm={4}>
          <FormGroup>
            <Label>
              {t('validation:ZipCode.label')}
              <span className="text-danger">*</span>
            </Label>
            <InputField
              name="senderAddressZipCode"
              type="text"
              placeholder={t('validation:ZipCode.placeholder')}
            />
          </FormGroup>
        </Col>
      </Row>
      <FormGroup className="mb-5">
        <Label>
          {t('validation:CountrySelect.label')}
          <span className="text-danger">*</span>
        </Label>
        <CountrySelect
          name="senderAddressCountryId"
          // `value` must be of type `string` due to `CustomSelect` implementation
          // (see CustomSelect.tsx:81)
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          value={values.senderAddressCountryId}
          onChange={(option) => handleChange('senderAddressCountryId', option)}
          onBlur={handleBlur}
          placeholder={t('validation:CountrySelect.placeholder')}
          defaultLabel={t('validation:CountrySelect.placeholder')}
        />
        {touched.senderAddressCountryId && errors.senderAddressCountryId && (
          <FormFeedback className="d-block">
            {errors.senderAddressCountryId}
          </FormFeedback>
        )}
      </FormGroup>
    </>
  );
};

export default SenderForm;
