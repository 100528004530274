import React from 'react';
import { useHistory } from 'react-router-dom';

import { cssMerge } from '~/services/utils';
import './SimpleDropdown.scss';

export type DropdownItem = {
  key: string;
  title: string | JSX.Element;
  url?: string;
  onClick?: () => void;
  className?: string;
}

export type Props = {
  text: string | JSX.Element;
  items: DropdownItem[];
  className?: string;
};

const SimpleDropdown: React.FC<Props> = ({
  text,
  items,
  className = '',
}) => {
  const history = useHistory();

  return (
    <div className={`simple-dropdown text-left ${className}`.trim()}>
      <div className="simple-dropdown-button">{text}</div>
      <div className="simple-dropdown-content">
        { items.map((dropdownItem: DropdownItem) => {
          const clickHandler = () => {
            if (dropdownItem.url) {
              history.push(dropdownItem.url);
            } else if (dropdownItem.onClick) {
              dropdownItem.onClick();
            }
          };

          return (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus
            <div
              className={cssMerge(
                'simple-dropdown-link',
                dropdownItem.className,
              )}
              onClick={clickHandler}
              key={dropdownItem.key}
              data-test={`dropdown-${dropdownItem.key}`}
              role="link"
            >
              {dropdownItem.title}
            </div>
          );
        }) }
      </div>
    </div>
  );
};

export default SimpleDropdown;
