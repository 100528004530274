import React from 'react';
import {
  CustomInput,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Input,
  Button,
  FormGroup,
  Form,
  Col,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import withValidation from 'Components/Presentational/FormExtensions/HoC/withValidation/withValidation';

const InputWithValidation = withValidation(Input);

const SequenceModalUI = ({ isModalVisible, toggleModal, formik, onSubmit, service, isEditingSequence }) => {
  const { t } = useTranslation('settings');
  const { values, handleChange, handleBlur, resetForm, errors, touched } = formik;

  return (
    <Modal isOpen={isModalVisible} toggle={toggleModal} centered>
      <Form>
        <ModalHeader>
          {isEditingSequence
            ? `${t('settings:Agents.DeliveryServices.SequenceModal.editSequenceTitle')}`
            : `${t('settings:Agents.DeliveryServices.SequenceModal.newSequenceTitle')}`}
          {' '}
          {service.deliveryType.agent.name}
        </ModalHeader>
        <ModalBody className="pt-0">
          {t('settings:Agents.DeliveryServices.SequenceModal.sequenceLabel')}
          <FormGroup row>
            <Col>
              <InputWithValidation
                type="text"
                name="start"
                value={values.start}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Col>
            <div>-</div>
            <Col>
              <InputWithValidation
                type="text"
                name="end"
                value={values.end}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col>
              <CustomInput
                type="checkbox"
                checked={values.cod}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.cod}
                name="cod"
                id="tooltip-cashOnDelivery"
                className=""
                label={t('settings:Agents.DeliveryServices.SequenceModal.cashDeliveryLabel')}
              />
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={Object.keys(errors).length > 0 || Object.keys(touched).length === 0}
            type="button"
            onClick={() => onSubmit(values, resetForm)}
          >
            {isEditingSequence
              ? `${t('settings:Agents.DeliveryServices.SequenceModal.editSequenceButton')}`
              : `${t('settings:Agents.DeliveryServices.SequenceModal.newSequenceButton')}`}
          </Button>
          <Button
            color="link"
            type="button"
            onClick={toggleModal}
          >
            {t('settings:Agents.DeliveryServices.SequenceModal.cancelButton')}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

SequenceModalUI.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  isEditingSequence: PropTypes.bool.isRequired,
  formik: PropTypes.shape({}).isRequired,
  service: PropTypes.shape({}).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default SequenceModalUI;
