import React from 'react';
import { Container } from 'reactstrap';
import { Formik } from 'formik';
import { withTranslation } from 'react-i18next';

import SettingsBase from 'Containers/Settings/_base/SettingsBase';
import SettingsLayout from 'Components/Settings/SettingsLayout';
import { updateObject, toast } from 'Shared/utilities';
import Loader from 'Containers/Loader/Loader';
import CollectionPlace from 'Models/collectionPlace/CollectionPlace';
import CollectionPlaceFormUI from '../Components/Presentational/CollectionPlaceFormUI/CollectionPlaceFormUI';
import createValidationSchema from '../Utils/Formik/FormValidationSchema';

class CollectionPlacesEdit extends SettingsBase {
  static formatQueryData(data) {
    return {
      id: data.id,
      name: data.name,
      companyNameForTickets: data.companyNameForTickets,
      active: data.active,
      identificator: data.identificator,
      contactName: data.contact.name,
      contactSurname: data.contact.surname,
      contactEmail: data.contact.email,
      contactPhone: data.contact.phone,
      street: data.address.street,
      zip: data.address.zip,
      city: data.address.city,
      country: data.address.country,
    };
  }

  constructor(props) {
    super(props, 'general');
    this.collectionPlace = new CollectionPlace();
  }

    handleSubmit = async (values, { setSubmitting }) => {
      setSubmitting(true);

      const contact = {
        name: values.contactName,
        surname: values.contactSurname,
        email: values.contactEmail,
        phone: values.contactPhone,
      };

      try {
        await this.collectionPlace.updateCollectionPlace(
          this.state.pickupPlaceId,
          values.collectionPlaceName,
          values.companyNameForTickets,
          values.identificator,
          values.active,
          contact,
        );
      } finally {
        setSubmitting(false);
      }

      this.props.history.push('/settings/collection-places');
      const { t } = this.props;
      toast.success(t('settings:CollectionPlaces.Banner.editPlaceSuccess'));
    };

    async componentDidMount() {
      await this.fetchData();
    }

    async fetchData() {
      const pickupPlaceId = this.props.match.params.id;
      let data = await this.collectionPlace.collectionPlaceById(pickupPlaceId);
      data = data.data.collectionPlaceById;
      data = CollectionPlacesEdit.formatQueryData(data);

      const state = updateObject(this.state, {
        isOpened: true,
        isPartLoading: false,
        pickupPlaceId,
        visiblePickupPlace: data,
      });

      this.setState(state);
    }

    render() {
      const { t } = this.props;
      let content = <Loader />;

      if (this.state.visiblePickupPlace !== undefined) {
        content = (
          <Container>
            <Formik
              initialValues={{
                collectionPlaceName: this.state.visiblePickupPlace.name,
                companyNameForTickets: this.state.visiblePickupPlace.companyNameForTickets,
                identificator: this.state.visiblePickupPlace.identificator,
                contactName: this.state.visiblePickupPlace.contactName,
                contactSurname: this.state.visiblePickupPlace.contactSurname,
                contactEmail: this.state.visiblePickupPlace.contactEmail,
                contactPhone: this.state.visiblePickupPlace.contactPhone,
                active: this.state.visiblePickupPlace.active,
              }}
              onSubmit={this.handleSubmit}
              validationSchema={createValidationSchema(false, t)}
            >
              {(formikProps) => (
                <CollectionPlaceFormUI
                  formikProps={formikProps}
                  title="Editovat svozové místo"
                  create={false}
                />
              )}
            </Formik>
          </Container>
        );
      }

      return (
        <SettingsLayout
          goBack="/settings"
          leftBlock={this.state.leftBlock}
          rightBlock={this.state.rightBlock}
          checkedItems={this.state.checkedItems}
          isOpened={this.state.isOpened}
          isPartLoading={this.state.isPartLoading}
        >
          {content}
        </SettingsLayout>
      );
    }
}

export default withTranslation(['settings'])(CollectionPlacesEdit);
