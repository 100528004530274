import { ImageResource } from 'Containers/Settings/Branding/typesAndDefaults';

export type EmailConfigValues = {
  id?: string;
  title: string;
  sender: string;
  senderEmail: string;
  primaryColor: string;
  linkColor: string;
  headerColor: string;
  headerBackgroundColor: string;
  buttonColor?: string;
  buttonBackgroundColor?: string;

  logo?: ImageResource | null;
  logoImg?: null | File | string;

  activateAfterSave?: boolean;
}

export type EmailConfigInputValues = {
  id?: string;
  title: string;
  sender: string;
  senderEmail: string;
  primaryColor: string;
  linkColor: string;
  headerColor: string;
  headerBackgroundColor: string;
  buttonColor?: Nullable<string>;
  buttonBackgroundColor?: Nullable<string>;

  logoId?: number | string;
}

export const emailConfigValuesDefaults: EmailConfigValues = {
  title: '',
  sender: '',
  senderEmail: '',
  primaryColor: '',
  linkColor: '',
  headerColor: '#000000',
  headerBackgroundColor: '',
  buttonColor: '',
  buttonBackgroundColor: '',
};

export type CreateEshopEmailConfigResponse = {
  createEmailConfig: EmailConfigValues;
};

export type MutationEshopEmailConfigInput = {
  id?: string,
  config: EmailConfigInputValues;
};

export type MutationEshopEmailConfigResponse = {
  updateEmailConfig: EmailConfigValues;
};

export type QueryEshopEmailConfig = {
  eshop: {
    emailConfigs: EmailConfig[];
    activeEmailConfig: EmailConfig;
  }
}

export type EmailConfig = {
  id: string;
  title: string;
  lastUpdatedAt: string;
  sender: string;
  senderEmail: string;
  headerColor: string;
  headerBackgroundColor: string;
  buttonColor?: Nullable<string>;
  buttonBackgroundColor?: Nullable<string>;
  linkColor: string;
  primaryColor: string;
  logo?: ImageResource | null;
}

export type MutationEmailActivate = {
  activateEmailConfig: {
    id: string;
  }
}

export type MutationEmailDeactivate = {
  deactivateEmailConfig: {
    id: string;
  }
}

export type MutationEmailDelete = {
  deleteEmailConfig: {
    success: string;
    message: string;
  }
}
