import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

const Accordion = ({
  theme,
  title,
  children,
  bodyVisibleTitle,
  bodyHiddenTitle,
  className,
}) => {
  const [visible, setVisibility] = useState(false);

  return (
    <div className={`fd-accordion ${theme} ${className}`}>
      <Row className="title d-flex justify-content-between" onClick={() => setVisibility(!visible)}>
        <div className="flex-grow-1">{visible ? bodyVisibleTitle || title : bodyHiddenTitle || title}</div>
        {children && (
          <FontAwesomeIcon
            icon={['fas', visible ? 'chevron-up' : 'chevron-down']}
            className="mr-1"
            fixedWidth
          />
        )}
      </Row>
      {visible && children && (
        <Row className="body">
          <Col md="12">
            {children}
          </Col>
        </Row>
      )}
    </div>
  );
};

Accordion.defaultProps = {
  theme: 'light',
  bodyVisibleTitle: null,
  bodyHiddenTitle: null,
  title: null,
  children: null,
  className: null,
};

Accordion.propTypes = {
  bodyVisibleTitle: PropTypes.element,
  bodyHiddenTitle: PropTypes.element,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  theme: PropTypes.oneOf(['dark', 'light']),
  className: PropTypes.string,
  children: PropTypes
    .oneOfType([
      PropTypes.string,
      PropTypes.element,
      PropTypes.arrayOf(PropTypes.element),
    ]),
};

export default Accordion;
