// eslint-disable-next-line max-len
/* eslint-disable jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,jsx-a11y/no-static-element-interactions */
import React from 'react';
import { ButtonDropdown, DropdownToggle } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ApolloConsumer } from 'react-apollo';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import DeliveryQueryFactory from 'Models/delivery/Queries/Factories/DeliveryQueryFactory';
import DeliveryFragments from 'Models/delivery/Queries/Fragments/DeliveryFragments';
import { customManualMutation } from 'Models/Components/CustomMutation';
import { customManualQuery } from 'Models/Components/CustomQuery';
import ReactRouterTypes from 'Types/ReactRouter/ReactRouterTypes';
import { toast } from 'Shared/utilities';
import CloseDeliveryToastBody from 'Components/Presentational/CloseDeliveryToastBody/CloseDeliveryToastBody';
import DeliveryCloseDropdownMenu from '../../Presentational/DeliveryCloseDropdownMenu/DeliveryCloseDropdownMenu';

class DeliveryCloseDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isDropdownOpen: false,
      openDeliveries: {},
    };
  }

  toggleCloseDeliveries = async () => {
    const { isDropdownOpen } = this.state;

    this.setState({ isDropdownOpen: !isDropdownOpen });
    await this.fetchClosingDeliveryInfo();
  };

  closeDeliveries = async (id) => {
    const { history, setPageLoader } = this.props;
    setPageLoader(true);

    let response = await customManualMutation({
      client: this.client,
      variables: { agentId: id },
      mutation: DeliveryQueryFactory.closeDeliveries(
        '...closedDeliveries',
        DeliveryFragments.closedDeliveries(),
      ),
    });

    setPageLoader(false);
    response = response.data.closeDeliveries;

    if (response.success) {
      history.push('/batches');
    }

    this.showDeliveryCloseToasts(response);
  };

  async fetchClosingDeliveryInfo() {
    let closingDeliveryInfoResponse = await customManualQuery({
      client: this.client,
      query: DeliveryQueryFactory.deliveriesClosingInformation(
        '...openedDeliveries',
        DeliveryFragments.openedDeliveries(),
      ),
    });

    closingDeliveryInfoResponse = closingDeliveryInfoResponse.data.deliveriesClosingInformation;
    this.setState({ openDeliveries: closingDeliveryInfoResponse });
  }

  showDeliveryCloseToasts(response) {
    const toastFunction = response.success ? 'success' : 'info';

    toast[toastFunction](
      <CloseDeliveryToastBody
        failedDeliveries={response.failedDeliveries ?? []}
        messages={response.messages}
        success={response.success}
      />,
      response.success ? undefined : { closeOnClick: false, draggable: false },
    );
  }

  render() {
    const { isDropdownOpen, openDeliveries } = this.state;
    const { t } = this.props;

    return (
      <ApolloConsumer>
        {(client) => {
          this.client = client;

          return (
            <ButtonDropdown
              isOpen={isDropdownOpen}
              toggle={this.toggleCloseDeliveries}
            >
              <DropdownToggle caret>
                <FontAwesomeIcon icon={['fas', 'box']} fixedWidth />
                {t('deliveries:CloseDeliveriesAction.button')}
              </DropdownToggle>
              <DeliveryCloseDropdownMenu
                openDeliveriesList={openDeliveries}
                onDeliveriesClose={this.closeDeliveries}
              />
            </ButtonDropdown>
          );
        }}
      </ApolloConsumer>
    );
  }
}

DeliveryCloseDropdown.propTypes = {
  history: ReactRouterTypes.history().isRequired,
  setPageLoader: PropTypes.func.isRequired,
};

export default withRouter(withTranslation(['deliveries'])(DeliveryCloseDropdown));
