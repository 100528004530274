import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const DeliveryCashOnDelivery = ({ value, currency }) => {
  const { t } = useTranslation();

  if (!value || !currency) {
    return null;
  }

  return (
    <div className="cod">
      <strong className="d-block">
        {value}
        {' '}
        {currency}
      </strong>
      {t('SummaryListItemLabels.cashOnDelivery')}
    </div>
  );
};

DeliveryCashOnDelivery.defaultProps = {
  value: '',
  currency: '',
};

DeliveryCashOnDelivery.propTypes = {
  value: PropTypes.number,
  currency: PropTypes.string,
};

export default DeliveryCashOnDelivery;
