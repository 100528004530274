import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ContactsModalItem from 'Components/Presentational/ContactsModal/ContactsModalItem';
import SubjectDataSource from 'Models/subject/SubjectDataSource';

class ContactsModal extends React.Component {
  constructor(props) {
    super(props);

    this.subject = new SubjectDataSource();

    this.state = {
      isLoading: false,
      selectedSubject: null,
      typeaheadData: { searchData: [], searchQueryResponse: [] },
    };
  }

  handleChange = (val) => {
    const searchFormattedSubject = val[0];
    const { typeaheadData: { searchQueryResponse } } = this.state;

    if (!searchFormattedSubject) return;

    const unformattedSubject = searchQueryResponse.find((subject) => subject.id === searchFormattedSubject.id);
    this.setState({ selectedSubject: unformattedSubject });
  };

  onPrompt = () => {
    const { onPrompt } = this.props;
    const { selectedSubject } = this.state;

    onPrompt(selectedSubject);
  };

  fetchData = async (searchKey) => {
    const result = { searchData: [], searchQueryResponse: [] };
    let subjectResponse;

    subjectResponse = await this.subject.getSubjects(
      searchKey,
      undefined,
      undefined,
      undefined,
      10,
      undefined,
      'totalCount items { id contact { name surname email phone companyName } address { street city country zip } }',
    );
    subjectResponse = subjectResponse.data.subjects;

    if (subjectResponse.totalCount === 0) {
      return result;
    }

    const subjects = {
      * [Symbol.iterator]() {
        const data = subjectResponse.items;
        for (let i = 0; i < data.length; i += 1) {
          yield { id: data[i].id, ...data[i].contact, ...data[i].address };
        }
      },
    };

    result.searchData = Array.from(subjects);
    result.searchQueryResponse = subjectResponse.items;
    return result;
  };

  handleSearch = async (searchKey) => {
    this.setState({ isLoading: true });

    const data = await this.fetchData(searchKey);

    this.setState({
      isLoading: false,
      typeaheadData: data,
    });
  };

  resetData = () => {
    this.setState({ selectedSubject: null });
  };

  render() {
    const { t } = this.props;

    const {
      isOpen,
      onCancel,
    } = this.props;

    const {
      isLoading,
      typeaheadData: { searchData },
      selectedSubject,
    } = this.state;

    return (
      <Modal
        isOpen={isOpen}
        toggle={() => onCancel()}
        centered
        modalTransition={{ timeout: 150 }}
        onOpened={this.resetData}
      >
        <ModalHeader toggle={() => onCancel()}>
          {t('deliveries:NewDelivery.Address.ContactModal.title')}
        </ModalHeader>
        <ModalBody className="pt-4">
          <AsyncTypeahead
            isLoading={isLoading}
            options={searchData}
            labelKey={(option) => `${option.name} ${option.surname}, ${option.street}, ${option.city}`}
            maxResults={20}
            minLength={3}
            onSearch={this.handleSearch}
            onChange={this.handleChange}
            filterBy={['name', 'surname', 'city', 'street']}
            placeholder={t('deliveries:NewDelivery.Address.ContactModal.Search.placeholder')}
            renderMenuItemChildren={(option, props) => (
              <ContactsModalItem
                key={option.id}
                user={option}
                searchText={props.text}
              />
            )}
            emptyLabel={(
              <span>
                <FontAwesomeIcon icon={['far', 'circle']} fixedWidth />
                &nbsp;
                {t('deliveries:NewDelivery.Address.ContactModal.Search.noResults')}
              </span>
            )}
            promptText={(
              <span>
                <FontAwesomeIcon icon={['fas', 'keyboard']} fixedWidth />
                &nbsp;
                {t('deliveries:NewDelivery.Address.ContactModal.Search.help')}
              </span>
            )}
            searchText={(
              <span>
                <FontAwesomeIcon icon={['fas', 'circle-notch']} spin fixedWidth />
                &nbsp;
                {t('deliveries:NewDelivery.Address.ContactModal.Search.searching')}
              </span>
            )}
            paginationText="Zobrazit další výsledky ..."
          />
        </ModalBody>
        <ModalFooter className="border-0 justify-content-center">
          <Button
            onClick={this.onPrompt}
            color="primary"
            disabled={!selectedSubject}
          >
            {t('deliveries:NewDelivery.Address.ContactModal.submitButton')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

ContactsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onPrompt: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default withTranslation(['deliveries'])(ContactsModal);
