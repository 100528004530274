import React from 'react';
import { OverlayTrigger, OverlayTriggerProps } from 'react-bootstrap';

const Overlay: React.FC<OverlayTriggerProps> = ({
  overlay,
  children,
}) => {
  return (
    <OverlayTrigger
      placement="bottom"
      trigger={['hover', 'focus']}
      popperConfig={{
        modifiers: [
          {
            name: 'flip',
            enabled: true,
            options: {
              fallbackPlacements: ['top'],
            },
          },
        ],
      }}
      overlay={overlay}
    >
      {children}
    </OverlayTrigger>
  );
};

export default Overlay;
