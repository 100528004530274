import PropTypes from 'prop-types';
import React from 'react';
// import { Link } from 'react-router-dom';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Highlighter } from 'react-bootstrap-typeahead';
import SVGIcon from 'Components/Presentational/SVGIcon/SVGIcon';
import { stringifyNull, normalizeString } from 'Shared/utilities';

const SearchBarItem = ({ item, searchText }) => {
  const formatContactNameForSearch = (contact) => {
    const searchedFullName = normalizeString(searchText)
      .split(' ');

    if (searchedFullName.length > 1 && (contact.name !== null && contact.surname !== null)) {
      const contactName = normalizeString(contact.name);
      const searchedNameStartsWithFirstName = searchedFullName[0] === contactName;

      if (searchedNameStartsWithFirstName) {
        return `${contact.name} ${contact.surname}`;
      }

      return `${searchedFullName[1]} ${searchedFullName[0]}`;
    }

    return searchText;
  };

  switch (item.type) {
    case 'contact':
      return (
        <div
          className="row"
          role="row"
          tabIndex={0}
        >
          <div className="col-1 d-flex align-items-center justify-content-center">
            <SVGIcon icon="user" />
          </div>
          <div className="col-4">
            <span>
              <Highlighter key="name" search={formatContactNameForSearch(item)}>{ `${stringifyNull(item.name)} ${stringifyNull(item.surname)}` }</Highlighter>
            </span>
            <div className="text-muted">
              <small>
                <Highlighter key="address" search={searchText}>{ `${stringifyNull(item.street)} ${stringifyNull(item.zip)} ${stringifyNull(item.city)}`}</Highlighter>
              </small>
            </div>
          </div>
          <div className="col-4" />
        </div>
      );
    case 'delivery':
      return (
        <div
          className="row"
          role="row"
          tabIndex={0}
        >
          <div className="col-1 d-flex align-items-center justify-content-center">
            <SVGIcon icon="package" />
          </div>
          <div className="col-4">
            <Highlighter key="number" search={searchText}>{` ${stringifyNull(item.deliveryNumber)} ${stringifyNull(item.variableSymbol)} `}</Highlighter>
          </div>
          <div className="col-3 d-flex align-items-center text-muted">
            <Highlighter key="agent" search={searchText}>{ item.agent }</Highlighter>
          </div>
          <div className="col-4 d-flex align-items-center text-muted">
            <Highlighter key="company" search={formatContactNameForSearch(item)}>
              {`${stringifyNull(item.name)} ${stringifyNull(item.surname)}`}
            </Highlighter>
          </div>
        </div>
      );
    case 'component':
      return (
        <>
          { item.component() }
        </>
      );
    default:
      return null;
  }
};

SearchBarItem.propTypes = {
  item: PropTypes.oneOfType([
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      avatar: PropTypes.string,
      name: PropTypes.string,
      surname: PropTypes.string,
      city: PropTypes.string,
      zip: PropTypes.string,
      street: PropTypes.string,
      deliveriesCount: PropTypes.number,
    }),
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      deliveryNumber: PropTypes.string,
      agent: PropTypes.string.isRequired,
      name: PropTypes.string,
      surname: PropTypes.string,
    }),
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      component: PropTypes.func.isRequired,
    }),
  ]).isRequired,
  searchText: PropTypes.string.isRequired,
};

export default SearchBarItem;
