import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import {
  Col,
  Button,
  Container,
  Row,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  FormFeedback,
} from 'reactstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';

import SettingsBase from 'Containers/Settings/_base/SettingsBase';
import SettingsLayout from 'Components/Settings/SettingsLayout';
import { updateObject, toast } from 'Shared/utilities';
import Loader from 'Containers/Loader/Loader';
import FormikScroll from '~/components/FormikScroll/FormikScroll';
import PackageDataSource from '../../../../../core/models/packages/PackageDataSource';

class PackagesEdit extends SettingsBase {
  constructor(props) {
    super(props, 'general');
    this.model = new PackageDataSource();
  }

  async componentDidMount() {
    await this.fetchData();
  }

  async fetchData() {
    const packageId = this.props.match.params.id;
    let currentPackage = await this.model.packageTemplateById(packageId);
    currentPackage = currentPackage.data.packageTemplateById;

    const state = updateObject(this.state, {
      isOpened: true,
      isPartLoading: false,
      packageId,
      visiblePackage: currentPackage || null,
    });

    this.setState(state);
  }

  Form = ({ handleSubmit, handleChange, handleBlur, isSubmitting, values, touched, errors }) => {
    const { t } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        <FormikScroll />
        <h1 className="pb-5">{t('settings:Packages.editPackageTitle')}</h1>
        <FormGroup>
          <Label htmlFor="name" style={{ display: 'block' }}>
            {t('settings:Packages.Form.Name.label')}
          </Label>
          <Input
            name="name"
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            invalid={errors.name !== undefined}
            valid={touched.name && !errors.name}
          />
          {touched.name && errors.name && <FormFeedback>{errors.name}</FormFeedback>}
        </FormGroup>
        <div className="form-row">
          <FormGroup className="col-md-4">
            <Label htmlFor="length" style={{ display: 'block' }}>
              {t('validation:PackageLength.label')}
            </Label>
            <InputGroup className="input-appended">
              <Input
                name="length"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.length}
                invalid={errors.length !== undefined}
                valid={touched.length && !errors.length}
              />
              <InputGroupAddon addonType="append">cm</InputGroupAddon>
              {touched.length && errors.length && <FormFeedback>{errors.length}</FormFeedback>}
            </InputGroup>
          </FormGroup>

          <FormGroup className="col-md-4">
            <Label htmlFor="width" style={{ display: 'block' }}>
              {t('validation:PackageWidth.label')}
            </Label>
            <InputGroup className="input-appended">
              <Input
                name="width"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.width}
                invalid={errors.width !== undefined}
                valid={touched.width && !errors.width}
              />
              <InputGroupAddon addonType="append">cm</InputGroupAddon>
              {touched.width && errors.width && <FormFeedback>{errors.width}</FormFeedback>}
            </InputGroup>
          </FormGroup>

          <FormGroup className="col-md-4">
            <Label htmlFor="height" style={{ display: 'block' }}>
              {t('validation:PackageHeight.label')}
            </Label>
            <InputGroup className="input-appended">
              <Input
                name="height"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.height}
                invalid={errors.height !== undefined}
                valid={touched.height && !errors.height}
              />
              <InputGroupAddon addonType="append">cm</InputGroupAddon>
              {touched.height && errors.height && <FormFeedback>{errors.height}</FormFeedback>}
            </InputGroup>
          </FormGroup>
        </div>
        <Row>
          <Col md="12" className="text-center pt-5 pb-3">
            <Button type="submit" color="primary" disabled={isSubmitting}>
              {t('settings:Packages.Form.submitButton')}
            </Button>
            <Link to="/settings/packages">
              <Button color="link" className="ml-3">
                {t('settings:Packages.Form.cancelButton')}
              </Button>
            </Link>
          </Col>
        </Row>
      </Form>
    );
  };

  render() {
    const { t } = this.props;
    let content = <Loader />;

    if (this.state.visiblePackage !== undefined) {
      content = (
        <Container>
          <Formik
            initialValues={{
              name: this.state.visiblePackage.name,
              length: this.state.visiblePackage.length,
              height: this.state.visiblePackage.height,
              width: this.state.visiblePackage.width,
            }}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);

              await this.model.updatePackageTemplate(
                this.state.packageId,
                values.name,
                values.length,
                values.width,
                values.height,
              );

              this.props.history.push('/settings/packages/');
              toast.success(t('settings:Packages.Banner.editPackageSuccess'));
              setSubmitting(false);
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string()
                .required(t('settings:Packages.Form.Name.required'))
                .min(4, t('settings:Packages.Form.Name.minLength', { count: 4 })),
              length: Yup.number()
                .required(t('validation:PackageLength.required'))
                .min(1, t('validation:PackageLength.minLength', { count: 1 })),
              width: Yup.number()
                .required(t('validation:PackageWidth.required'))
                .min(1, t('validation:PackageWidth.minLength', { count: 1 })),
              height: Yup.number()
                .required(t('validation:PackageHeight.required'))
                .min(1, t('validation:PackageHeight.minLength', { count: 1 })),
            })}
            component={this.Form}
          />
        </Container>
      );
    }

    return (
      <SettingsLayout
        goBack="/settings"
        leftBlock={this.state.leftBlock}
        rightBlock={this.state.rightBlock}
        checkedItems={this.state.checkedItems}
        isOpened={this.state.isOpened}
        isPartLoading={this.state.isPartLoading}
      >
        {content}
      </SettingsLayout>
    );
  }
}

export default withTranslation(['settings', 'validation'])(PackagesEdit);
