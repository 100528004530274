import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import useModal from '~/hooks/useModal';

type Props = {
  onDeleteToken: () => void;
  disabled: boolean;
};

const DeleteApiTokenButton: React.FC<Props> = ({
  onDeleteToken,
  disabled,
}) => {
  const { t } = useTranslation(['deliveries']);
  const [isOpen, toggleModal] = useModal();

  return (
    <>
      <Button
        color="danger"
        outline
        onClick={() => toggleModal(true)}
        disabled={disabled}
      >
        {t('settings:Api.deleteButton')}
      </Button>
      <Modal
        isOpen={isOpen}
        toggle={() => toggleModal()}
        modalTransition={{ timeout: 150 }}
      >
        <ModalHeader toggle={() => toggleModal()}>
          {t('settings:Api.DeleteModal.title')}
        </ModalHeader>

        <ModalBody className="pt-0">
          {t('settings:Api.RefreshModal.description')}
        </ModalBody>

        <ModalFooter>
          <Button
            className="mr-2"
            color="danger"
            outline
            onClick={() => {
              onDeleteToken();
              toggleModal(false);
            }}
          >
            {t('settings:Api.deleteButton')}
          </Button>
          <Button
            className="mr-2"
            color="primary"
            outline
            onClick={() => toggleModal(false)}
          >
            {t('settings:Api.DeleteModal.cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default DeleteApiTokenButton;
