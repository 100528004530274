import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { interpret, Machine } from 'xstate';

import TicketPrintModal from 'Containers/TicketPrintModal/TicketPrintModal';
import { toast } from 'Shared/utilities';
import ActionMenuItem from '../../Presentational/ActionMenuItem/ActionMenuItem';

class TicketPrintAction extends React.Component {
  constructor(props) {
    super(props);

    this.printModalMachine = Machine({
      id: 'modal',
      initial: 'hidden',
      states: {
        hidden: { on: { TOGGLE: 'visible' } },
        visible: { on: { TOGGLE: 'hidden' } },
      },
    });

    this.state = { printModal: this.printModalMachine.initialState };
    this.printModalService = interpret(this.printModalMachine)
      .onTransition((printModal) => this.setState({ printModal }));
  }

  componentDidMount() {
    this.printModalService.start();
  }

  componentWillUnmount() {
    this.printModalService.stop();
  }

  handleClick() {
    const { disabled, disabledReason } = this.props;

    if (disabled) return;
    if (disabledReason) {
      toast.info(disabledReason);
      return;
    }

    this.toggleTicketPrintModal();
  }

  toggleTicketPrintModal() {
    this.printModalService.send('TOGGLE');
  }

  render() {
    const { checkedDeliveries, disabled, t } = this.props;
    const { printModal } = this.state;

    return (
      <>
        <ActionMenuItem
          icon="print"
          disabled={disabled}
          count={checkedDeliveries.length}
          onClick={() => this.handleClick()}
        >
          {t('deliveries:ActionBar.ActionMenu.printLabel', { count: checkedDeliveries.length })}
        </ActionMenuItem>
        <TicketPrintModal
          deliveryIds={checkedDeliveries.map((delivery) => delivery.id)}
          isOpen={printModal.value === 'visible'}
          onToggle={() => this.toggleTicketPrintModal()}
        />
      </>
    );
  }
}

export default withTranslation(['deliveries'])(TicketPrintAction);

TicketPrintAction.propTypes = {
  disabled: PropTypes.bool.isRequired,
  disabledReason: PropTypes.string,
  checkedDeliveries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
