import { Col, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import React from 'react';
import FormikTypes from 'Types/formik/FormikTypes';
import { useTranslation } from 'react-i18next';

const ContactSection = ({ handleChange, handleBlur, values, touched, errors, formAction }) => {
  const { t } = useTranslation(['contacts', 'validation']);

  return (
    <>
      <Col md="2" className="name d-flex align-items-center justify-content-center">
        {formAction === 'create' ? t('contacts:NewContact.formTitle') : t('contacts:EditContact.formTitle')}
      </Col>
      <Col md="5" className="border-left">
        <h5 className="pb-3 mt-3">
          {t('contacts:ContactForm.infoTitle')}
        </h5>
        <div className="form-row">
          <FormGroup className="col-md-6">
            <Label htmlFor="name" style={{ display: 'block' }}>
              {t('validation:Name.label')}
            </Label>
            <Input
              name="name"
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              invalid={touched.name && errors.name !== undefined}
              valid={touched.name && !errors.name}
            />
            {touched.name && errors.name && <FormFeedback>{errors.name}</FormFeedback>}
          </FormGroup>
          <FormGroup className="col-md-6">
            <Label htmlFor="surname" style={{ display: 'block' }}>
              {t('validation:Surname.label')}
            </Label>
            <Input
              name="surname"
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.surname}
              invalid={touched.surname && errors.surname !== undefined}
              valid={touched.surname && !errors.surname}
            />
            {touched.surname && errors.surname && <FormFeedback>{errors.surname}</FormFeedback>}
          </FormGroup>
        </div>
        <div className="form-row">
          <FormGroup className="col-md-12">
            <Label htmlFor="companyName" style={{ display: 'block' }}>
              {t('validation:CompanyName.label')}
            </Label>
            <Input
              name="companyName"
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.companyName}
              invalid={touched.companyName && errors.companyName !== undefined}
              valid={touched.companyName && true}
            />
            {touched.companyName && errors.companyName && <FormFeedback>{errors.companyName}</FormFeedback>}
          </FormGroup>
        </div>
        <div className="form-row">
          <FormGroup className="col-md-6">
            <Label htmlFor="email" style={{ display: 'block' }}>
              {t('validation:Email.label')}
            </Label>
            <Input
              name="email"
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              invalid={touched.email && errors.email !== undefined}
              valid={touched.email && !errors.email}
            />
            {touched.email && errors.email && <FormFeedback>{errors.email}</FormFeedback>}
          </FormGroup>
          <FormGroup className="col-md-6">
            <Label htmlFor="phone" style={{ display: 'block' }}>
              {t('validation:Phone.label')}
            </Label>
            <Input
              name="phone"
              type="text"
              placeholder="+420"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.phone || '+420'}
              invalid={touched.phone && errors.phone !== undefined}
              valid={touched.phone && !errors.phone}
            />
            {touched.phone && errors.phone && <FormFeedback>{errors.phone}</FormFeedback>}
          </FormGroup>
        </div>
      </Col>
    </>
  );
};

ContactSection.propTypes = {
  ...FormikTypes.eventHandlers().isRequired,
  ...FormikTypes.objects().isRequired,
};

export default ContactSection;
