/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import withValidation from 'Components/Presentational/FormExtensions/HoC/withValidation/withValidation';
import { useLockedInputGroup } from 'Components/Hooks/Form/useLockedInputGroup';
import LockToggleButton from './Presentational/LockToggleButton';
import PasswordField from '~/components/PasswordField/PasswordField';

const InputWithValidation = withValidation(Input);

const LoginSection = ({
  usernameLabel,
  passwordLabel,
  companyCodeLabel,
  toggleLoginSubmit,
  isUsernameHidden,
  isPasswordHidden,
  isCompanyCodeHidden,
  lockLoginSection,
}) => {
  const { t } = useTranslation('settings');

  const { isLocked, toggleLock } = useLockedInputGroup(lockLoginSection);
  const inputLabel = {
    username: usernameLabel || 'Jméno',
    password: passwordLabel || 'Heslo',
    companyCode: companyCodeLabel || 'Kód firmy',
  };

  const onLockClick = () => {
    toggleLoginSubmit(isLocked);
    toggleLock();
  };

  return (
    <>
      {(!isUsernameHidden || !isPasswordHidden) && (
        <div className="form-group mt-4">
          <div className="form-row">
            <div className="col-md-4">
              <h4>{t('Agents.Login.title')}</h4>
            </div>
            <div className="col-md-6">
              {!isUsernameHidden && (
                <>
                  <label>{inputLabel.username}</label>
                  <InputWithValidation
                    name="name"
                    className="form-control"
                    type="text"
                    disabled={isLocked}
                  />
                </>
              )}
              {!isPasswordHidden && (
                <>
                  <label>{inputLabel.password}</label>
                  <PasswordField
                    name="password"
                    placeholder="***"
                    disabled={isLocked}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="form-group mt-3">
        {!isCompanyCodeHidden && (
          <div className="form-row">
            <div className="col-md-4">
              {/*
                            <div>
                                <a href="#">
                                    Jak získat
                                    {` ${inputLabel.companyCode}`}
                                </a>
                            </div>
                            */}
            </div>
            <div className="col-md-6">
              <label>{inputLabel.companyCode}</label>
              <InputWithValidation
                name="companyCode"
                className="form-control"
                type="text"
                disabled={isLocked}
              />
            </div>
          </div>
        )}
      </div>
      {isLocked && (
        <div className="form-group mt-3">
          <div className="col-md-6 offset-md-4" style={{ padding: 0 }}>
            <LockToggleButton onClick={onLockClick} />
          </div>
        </div>
      )}
    </>
  );
};

export default LoginSection;
