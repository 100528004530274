import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { withTranslation } from 'react-i18next';
import MainBody from 'Components/Presentational/Layout/MainBody/MainBody';
import { toast } from 'Shared/utilities';
import Subject from 'Models/subject/SubjectDataSource';
import ContactForm from '../Components/Presentational/ContactForm/ContactForm';

class ContactsNew extends React.Component {
  constructor(props) {
    super(props);

    this.subject = new Subject();
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values, { setSubmitting }) {
    setSubmitting(true);

    const { t } = this.props;

    const contact = {
      name: values.name,
      surname: values.surname,
      companyName: values.companyName,
      email: values.email,
      phone: values.phone,
    };

    const address = {
      country: values.country.value,
      street: values.street,
      zip: values.zip,
      city: values.city,
    };

    if (typeof (address.country) === 'object') {
      address.country = address.country.value;
    }

    this.subject.createSubject(contact, address)
      .then((res) => {
        setSubmitting(false);
        const { history: { push: historyPush } } = this.props;
        historyPush(`/contacts/${res.data.createSubject.id}`);
        toast.success(t('contacts:Banner.contactCreateSuccess'));
      })
      .catch((err) => {
        console.warn(err);
        toast.error(t('contacts:Banner.contactCreateError'));
        setSubmitting(false);
      });
  }

  render() {
    const { t } = this.props;

    return (
      <MainBody wrapperHeight="150px" title={t('contacts:NewContact.title')}>
        <Container>
          <Formik
            initialValues={{
              name: '',
              surname: '',
              companyName: '',
              country: '',
              email: '@',
              phone: '',
              street: '',
              zip: '',
              city: '',
            }}
            onSubmit={this.handleSubmit}
            validationSchema={
              Yup.object().shape({
                surname: Yup.string()
                  .required(t('validation:Surname.required')),
                email: Yup.string()
                  .required(t('validation:Email.required'))
                  .email(t('validation:Email.pattern')),
                phone: Yup.string()
                  .required(t('validation:Phone.required')),
                street: Yup.string()
                  .required(t('validation:Street.required')),
                zip: Yup.string()
                  .required(t('validation:ZipCode.required')),
                city: Yup.string()
                  .required(t('validation:City.required')),
                country: Yup.string()
                  .required(t('validation:CountrySelect.required')),
              })
            }
            component={(formikProps) => <ContactForm {...formikProps} formAction="create" />}
          />
        </Container>
      </MainBody>
    );
  }
}

ContactsNew.propTypes = { history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired };

export default withTranslation(['contacts', 'validation'])(ContactsNew);
