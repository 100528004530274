import React from 'react';
import { DropdownItem } from 'reactstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import SVGIcon from 'Components/Presentational/SVGIcon/SVGIcon';

const EditDelivery = ({ onEditClick, canUpdate }) => {
  const { t } = useTranslation('deliveries');

  return (
    <DropdownItem onClick={onEditClick} disabled={!canUpdate}>
      <SVGIcon icon="edit_black" style={{ marginRight: '6px' }} />
      {' '}
      {t('DeliveryDetail.Menu.editDelivery')}
    </DropdownItem>
  );
};

EditDelivery.defaultProps = { onEditClick: () => null };

EditDelivery.propTypes = {
  canUpdate: PropTypes.bool.isRequired,
  onEditClick: PropTypes.func,
};

export default EditDelivery;
