import gql from 'graphql-tag';

export default class CompanyQueryFactory {
  static company(fields, fragments) {
    return gql`
      query company($id: ID) {
        company(id: $id) {
          ${fields}
        }
      }
      ${fragments}
    `;
  }

  static createCompanyCredentials(fields, fragments) {
    return gql`
      mutation createCompanyCredentials($companyCredentials: InputCompanyCredentialsCreate!) {
        createCompanyCredentials(companyCredentials: $companyCredentials) {
          ${fields}
        }
      }
      ${fragments}
    `;
  }

  static updateCompanyCredentials(fields, fragments) {
    return gql`
      mutation updateCompanyCredentials($companyCredentials: InputCompanyCredentialsUpdate!) {
        updateCompanyCredentials(companyCredentials: $companyCredentials) {
          ${fields}
        }
      }
      ${fragments}
    `;
  }
}
