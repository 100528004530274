import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import produce from 'immer';
import { connect } from 'react-redux';
import { ButtonDropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getOr } from 'lodash/fp';
import { withTranslation } from 'react-i18next';

import { toast } from 'Shared/utilities';
import MainBody from 'Components/Presentational/Layout/MainBody/MainBody';
import ActionBar from 'Components/Deliveries/ActionBar/ActionBar';
import Modal from 'Containers/ConfirmModal/ConfirmModal';
import DeliveryDataSource from 'Models/delivery/DeliveryDataSource';
import * as UserActions from 'StoreActions/user.action';
import { CustomQuery } from 'Models/Components/CustomQuery';
import DeliveryQueryFactory from 'Models/delivery/Queries/Factories/DeliveryQueryFactory';
import DeliveryFragments from 'Models/delivery/Queries/Fragments/DeliveryFragments';
import urlQueryHandler from 'Tools/URLQueryHandler';
import DeliveryPreviewTable from './Components/DeliveryPreviewTable/DeliveryPreviewTable';
import DeliveryCloseDropdown from './Components/DeliveryCloseDropdown/Components/Container/DeliveryCloseDropdown/DeliveryCloseDropdown';
import ReducerPropTypes from '../../../store/reducers/ReducerPropTypes';
import DeliveryFilter from './Components/DeliveryFilter/DeliveryFilter';

import DELIVERIES_FROM_API from './Components/DeliveryPreviewTable/deliveriesFromApi.gql';
import css from './Deliveries.module.scss';

class Deliveries extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.cancelDeliveries = this.cancelDeliveries.bind(this);
    this.handleCancelDeliveryClick = this.handleCancelDeliveryClick.bind(this);
    this.toggleCancelModal = this.toggleCancelModal.bind(this);
    this.cancelDelivery = this.cancelDelivery.bind(this);

    this.deliverySource = new DeliveryDataSource();
    this.cancelDeliveryId = null;

    this.state = {
      cancelDeliveryModal: {
        isOpen: false,
        isCancelingMultipleDeliveries: false,
      },
      checkedDeliveries: [],
      deliveries: [],
      dropdownOpen: false,
      upload: null,
      isLoadingPage: false,
    };
  }

  async componentDidMount() {
    window.addEventListener('keyup', this.handleKeyPress);

    const { setUserMeta } = this.props;
    const deliveriesResponse = await this.deliverySource.query({ deliveries: DELIVERIES_FROM_API }, { limit: 1, source: 'api' });

    setUserMeta({ hasDeliveriesFromApi: deliveriesResponse?.data?.totalCount > 0 ?? false });
  }

  componentWillUnmount() {
    window.removeEventListener('keyup', this.handleKeyPress);
  }

  hideActionBar = () => {
    this.setState((state) => ({ ...state, checkedDeliveries: [] }));
  };

  getCheckedIds = () => {
    const { checkedDeliveries } = this.state;
    return checkedDeliveries.map((item) => item.id);
  };

  setPageLoader = (value) => {
    this.setState({ isLoadingPage: value });
  };

  handleKeyPress = (e) => {
    if (e.keyCode === 27) {
      this.hideActionBar();
    }
  };

  cancelDeliveries = async (refetchDeliveries, t) => {
    try {
      const { checkedDeliveries } = this.state;
      const checkedIds = checkedDeliveries.map((delivery) => delivery.id);

      await this.deliverySource.cancelDeliveries(checkedIds);
      toast.success(t('deliveries:Banner.multiplePackagesCancelSuccess'));
      this.toggleCancelModal();
      refetchDeliveries();
    } catch (err) {
      return this.toggleCancelModal();
    }

    return this.hideActionBar();
  };

  checkItem = (id, data) => {
    const { delivery } = data;

    const state = produce(this.state, (draft) => {
      const deliveryIndex = draft.checkedDeliveries
        .findIndex((checkedDelivery) => checkedDelivery.id === id);

      if (deliveryIndex > -1) {
        draft.checkedDeliveries.splice(deliveryIndex, 1);
      } else {
        draft.checkedDeliveries.push(delivery);
      }
    });

    this.setState(state);
  };

  handleCancelDeliveryClick = (id) => {
    this.cancelDeliveryId = id;
    this.toggleCancelModal();
  };

  toggleCancelModal(isCancelingMultipleDeliveries = false) {
    const { cancelDeliveryModal } = this.state;
    const state = produce(this.state, (draft) => {
      draft.cancelDeliveryModal.isCancelingMultipleDeliveries = isCancelingMultipleDeliveries;
      draft.cancelDeliveryModal.isOpen = !cancelDeliveryModal.isOpen;
    });

    this.setState(state);
  }

  toggle() {
    this.setState((state) => ({ dropdownOpen: !state.dropdownOpen }));
  }

  async cancelDelivery(refetchDeliveries, t) {
    this.toggleCancelModal();
    const id = this.cancelDeliveryId;

    try {
      await this.deliverySource.cancelDeliveries([id], 'id');
      toast.success(t('deliveries:Banner.singlePackageCancelSuccess'));
      refetchDeliveries();
    } catch (e) {
      toast.error(t('deliveries:Banner.notCancelable'));
    }

    this.cancelDeliveryId = null;
  }

  render() {
    const {
      cancelDeliveryModal: {
        isCancelingMultipleDeliveries: cancelDeliveryModalIsCancelingMultipleDeliveries,
        isOpen: cancelDeliveryModalIsOpen,
      },
      checkedDeliveries,
      dropdownOpen,
    } = this.state;

    const {
      t,
      filter,
      filter: {
        orderBy,
        filter: category,
        orderByDirection,
        recipientCountry,
        flags,
        page,
      },
      user,
    } = this.props;

    const shouldSeeDeliveries = user?.hasCollectionPlace && user?.hasSetCredentials;
    const subscription = user.company?.subscription;

    const deliveryCancelModalTitle = t('deliveries:CancelDeliveryModal.Single.title');
    const deliveryCancelModalBody = t('deliveries:CancelDeliveryModal.Single.description');
    const multiDeliveryCancelModalTitle = t('deliveries:CancelDeliveryModal.Multiple.title');
    const multiDeliveryCancelModalBody = t('deliveries:CancelDeliveryModal.Multiple.description', { count: checkedDeliveries.length });

    const extraButtons = (
      <div className={css.deliveriesActions}>
        <DeliveryFilter />
        <DeliveryCloseDropdown setPageLoader={this.setPageLoader} />

        <ButtonDropdown
          isOpen={dropdownOpen}
          toggle={this.toggle}
          className="newDeliveriesDropdown ml-3"
        >
          <DropdownToggle caret color="secondary">
            <FontAwesomeIcon icon={['fas', 'plus']} fixedWidth />
            {t('deliveries:NewDeliveryAction.button')}
          </DropdownToggle>
          <DropdownMenu>
            <Link
              to="/deliveries/new"
              className="dropdown-item"
              tabIndex="0"
              role="menuitem"
            >
              {t('deliveries:NewDeliveryAction.manualInsertion')}
            </Link>
            <Link
              to="/deliveries/csv-import"
              className="dropdown-item"
              tabIndex="0"
              role="menuitem"
            >
              {t('deliveries:NewDeliveryAction.csvImport')}
            </Link>
          </DropdownMenu>
        </ButtonDropdown>
      </div>
    );

    if (!shouldSeeDeliveries) {
      return (
        <MainBody
          wrapperHeight="150px"
          title={t('deliveries:title')}
        />
      );
    }

    return (
      <CustomQuery
        query={DeliveryQueryFactory.deliveries(
          '...DeliveryPreview',
          DeliveryFragments.deliveryPreview(),
        )}
        variables={{
          orderBy,
          orderByDirection,
          category,
          recipientCountry: recipientCountry || null,
          important: !subscription?.importantDeliveries ? null : flags.important || false,
          offset: (page - 1) * 25,
          limit: 25,
        }}
        skip={flags.important && !subscription?.importantDeliveries}
      >
        {({ loading, data, refetch }) => {
          const defaultDeliveries = { totalCount: 0, items: [] };
          const deliveries = getOr(defaultDeliveries, 'deliveries', data);

          return (
            <MainBody wrapperHeight="150px" title={t('deliveries:title')} extraButtons={extraButtons}>
              {!loading && urlQueryHandler.setQueryStringByObject({ ...filter, flags: undefined })}

              <DeliveryPreviewTable
                refetchDeliveries={refetch}
                deliveries={deliveries.items}
                checkedIds={this.getCheckedIds()}
                checkItem={this.checkItem}
                clearCheckboxes={this.hideActionBar}
                onCancelDeliveryClick={this.handleCancelDeliveryClick}
                totalCount={deliveries.totalCount}
                isLoading={loading}
              />

              <ActionBar
                checkedDeliveries={checkedDeliveries}
                refetchDeliveries={refetch}
                hideActionBar={this.hideActionBar}
                cancelDelivery={() => this.toggleCancelModal(true)}
              />

              <Modal
                title={(cancelDeliveryModalIsCancelingMultipleDeliveries) ? multiDeliveryCancelModalTitle : deliveryCancelModalTitle}
                body={(cancelDeliveryModalIsCancelingMultipleDeliveries) ? multiDeliveryCancelModalBody : deliveryCancelModalBody}
                buttons={{
                  prompt: {
                    text: (cancelDeliveryModalIsCancelingMultipleDeliveries)
                      ? t('deliveries:CancelDeliveryModal.Multiple.submitButton')
                      : t('deliveries:CancelDeliveryModal.Single.submitButton'),
                    color: 'secondary',
                  },
                  cancel: {
                    text: (cancelDeliveryModalIsCancelingMultipleDeliveries)
                      ? t('deliveries:CancelDeliveryModal.Multiple.cancelButton')
                      : t('deliveries:CancelDeliveryModal.Single.cancelButton'),
                    color: 'link',
                  },
                }}
                onCancel={this.toggleCancelModal}
                onPrompt={(cancelDeliveryModalIsCancelingMultipleDeliveries)
                  ? () => this.cancelDeliveries(refetch, t)
                  : () => this.cancelDelivery(refetch, t)}
                toggle={this.toggleCancelModal}
                isOpen={cancelDeliveryModalIsOpen}
              />
            </MainBody>
          );
        }}
      </CustomQuery>
    );
  }
}

Deliveries.propTypes = {
  filter: ReducerPropTypes.deliveryFilter.isRequired,
  history: PropTypes.shape({}).isRequired,
  upload: PropTypes.shape({}).isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.userReducer.data,
    upload: state.deliveryReducer.upload,
    filter: state.deliveryReducer.filter,
  };
}

function mapDispatchToProps(dispatch) {
  return { setUserMeta: (meta) => dispatch(UserActions.setUserMeta(meta)) };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['deliveries', 'common'])(Deliveries));
