import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import moment from 'moment';

import useMutation from '~/hooks/useAuthMutation';
import DatePicker from '~/components/DatePicker/DatePicker';
import SubmitButton from '~/components/SubmitButton/SubmitButton';

import css from './ExtendFrameButtonPopover.module.scss';
import UPDATE_MAX_STORING_DATE from './updateMaxStoringDate.gql';

function addDays(date: Date, days: number): Date {
  const newDate = new Date(date);

  newDate.setDate(newDate.getDate() + days);

  return newDate;
}

type Props = {
  delivery: any;
  endDate: Date;
  onSuccess: () => void;
}

const ExtendFrameButtonPopover: React.FC<Props> = ({
  delivery,
  onSuccess,
  endDate,
  children,
}) => {
  const { t } = useTranslation(['deliveries']);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [date, setDate] = useState<Date>(endDate);

  const toggle = () => {
    if (!popoverOpen) {
      setDate(endDate);
    }

    setPopoverOpen(!popoverOpen);
  };

  const [
    updateMaxStoringDate,
    { loading },
  ] = useMutation(UPDATE_MAX_STORING_DATE);

  const handleChange = (calendarDate: Date) => {
    setDate(calendarDate);
  };

  const handleSubmit = () => {
    const formattedDate = moment(date).format('Y-MM-DD');
    const id = parseInt(delivery.id, 10);
    const hasChanged = date.getTime() !== endDate.getTime();

    if (hasChanged) {
      updateMaxStoringDate({ variables: { id, date: formattedDate } })
        .then(() => {
          toggle();

          onSuccess();
        })
        .catch(() => null);
    } else {
      toggle();
    }
  };

  const today = new Date(); // TODO: should get serverside date

  const maxDate = addDays(today, 21);

  const POPOVER_ID = 'extendPickupEndDate';

  return (
    <>
      <button
        className={`${css.extendBtn} text-capitalize`}
        type="button"
        id={POPOVER_ID}
      >
        {children}
      </button>

      <Popover
        placement="top"
        isOpen={popoverOpen}
        target={POPOVER_ID}
        toggle={toggle}
        trigger="legacy"
      >
        <PopoverHeader className="my-0">
          {t('deliveries:DeliveryDetail.ExtendDeposit.title')}
        </PopoverHeader>

        <PopoverBody>
          <DatePicker
            onChange={handleChange}
            selected={date}
            minDate={today}
            maxDate={maxDate}
            inline
          />

          <div className="d-flex d-justify-content-center py-1">
            <SubmitButton
              color="primary"
              onClick={handleSubmit}
              isLoading={loading}
            >
              {t('deliveries:DeliveryDetail.ExtendDeposit.submitButton')}
            </SubmitButton>
          </div>
        </PopoverBody>
      </Popover>
    </>
  );
};

export default ExtendFrameButtonPopover;
