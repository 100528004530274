import React from 'react';
import { CustomInput } from 'reactstrap';
import withValidation
  from 'Components/Presentational/FormExtensions/HoC/withValidation/withValidation';
import { useTranslation } from 'react-i18next';

const InputWithValidation = withValidation(CustomInput);

const PrivacyConsent = () => {
  const { t } = useTranslation('settings');

  return (
    <div className="row-element my-4 d-flex justify-content-center">
      <div className="col-md-6 small">
        <InputWithValidation
          className="consent"
          type="checkbox"
          name="consent"
          id="consent"
          label={t('Agents.PrivacyConsent.label')}
        />
      </div>
    </div>
  );
};

export default PrivacyConsent;
