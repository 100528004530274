import { Formik } from 'formik';
import React from 'react';
import { withTranslation } from 'react-i18next';

import { toast } from 'Shared/utilities';
import ReactRouterTypes from 'Types/ReactRouter/ReactRouterTypes';
import CollectionPlace from 'Models/collectionPlace/CollectionPlace';
import CollectionPlaceFormUI from '../../../../Components/Presentational/CollectionPlaceFormUI/CollectionPlaceFormUI';
import createValidationSchema from '../../../../Utils/Formik/FormValidationSchema';

class CollectionPlaceCreateForm extends React.Component {
  constructor(props) {
    super(props);
    this.collection = new CollectionPlace();
  }

    handleSubmit = async (values, { setSubmitting }) => {
      setSubmitting(true);

      const { t } = this.props;
      const { history } = this.props;
      const contact = {
        name: values.contactName,
        surname: values.contactSurname,
        email: values.contactEmail,
        phone: values.contactPhone,
      };
      const address = {
        street: values.street,
        city: values.city,
        zip: values.zip,
        country: values.country,
      };

      if (typeof (address.country) === 'object') {
        address.country = address.country.value;
      }

      setSubmitting(false);

      await this.collection.createCollectionPlace(
        values.collectionPlaceName,
        values.companyNameForTickets,
        values.identificator,
        values.active,
        contact,
        address,
      );
      history.push('/settings/collection-places');
      toast.success(t('settings:CollectionPlaces.Banner.newPlaceSuccess'));
    };

    render() {
      const { t } = this.props;

      return (
        <Formik
          initialValues={{
            active: true,
            collectionPlaceName: '',
            companyNameForTickets: '',
            identificator: '',
            contactName: '',
            contactSurname: '',
            contactEmail: '',
            contactPhone: '+420',
            country: '',
            street: '',
            city: '',
            zip: '',
          }}
          onSubmit={this.handleSubmit}
          validationSchema={createValidationSchema(true, t)}
        >
          {(formikProps) => (
            <CollectionPlaceFormUI
              formikProps={formikProps}
              create
            />
          )}

        </Formik>
      );
    }
}

CollectionPlaceCreateForm.propTypes = { history: ReactRouterTypes.history().isRequired };

export default withTranslation(['settings'])(CollectionPlaceCreateForm);
