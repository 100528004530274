class FormStore {
  constructor() {
    this.store = { forms: { collectionPlaces: {} } };
  }

  transformCollectionPlacesForApi() {
    const collectionPlaces = this.store.forms.collectionPlaces?.values?.collectionPlaces;

    if (!collectionPlaces) {
      return [];
    }

    const checkedCollectionPlaces = collectionPlaces.filter((cp) => cp.checked === true);

    return checkedCollectionPlaces.map((cp) => ({
      regular: cp.regular === 'regular',
      externalPlaceId: cp.externalPlaceId,
      collectionPlaceId: cp.id,
      note: cp.note,
      ...(cp.postCode ? { metaData: { postCode: cp.postCode } } : {}),
    }));
  }

  getTransformedCollectionPlaces() {
    return this.transformCollectionPlacesForApi();
  }

  submitCollectionPlaces = async () => {
    const collectionPlaceForm = this.store.forms.collectionPlaces;

    if (collectionPlaceForm.submitForm) {
      await collectionPlaceForm.submitForm();
    }
  };

  get collectionPlaceForm() {
    return this.store.forms.collectionPlaces;
  }
}

export default new FormStore();
