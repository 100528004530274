import gql from 'graphql-tag';

export default class ProtocolQueryFactory {
  static deliveryCollectionProtocols(fields) {
    return gql`
            query deliveryCollectionProtocols (
                $limit: Int!
                $offset: Int
                $agentId: ID
                $collectionPlaceId: ID
                $createdById: ID
                $created: Date
                $orderBy: EnumDeliveryCollectionProtocolOrderByFields
                $orderByDirection: EnumOrderByDirection
            ) {
                deliveryCollectionProtocols (
                    limit: $limit
                    offset: $offset
                    agentId: $agentId
                    collectionPlaceId: $collectionPlaceId
                    createdById: $createdById
                    created: $created
                    orderBy: $orderBy
                    orderByDirection: $orderByDirection
                ) {
                    ${fields}
                }
            }`;
  }

  static deliveryCollectionProtocolPdfById(fields, fragments = '') {
    return gql`
            query deliveryCollectionProtocolPdfById($id: ID!) {
                deliveryCollectionProtocolPdfById(id: $id) {
                    ${fields}
                }
            }
            ${fragments}
        `;
  }

  static deliveryCollectionProtocolById(fields) {
    return gql`
            query deliveryCollectionProtocolById($id: ID!) {
                deliveryCollectionProtocolById(id: $id) {
                    ${fields}
                }
            }
        `;
  }
}
