import React from 'react';
import { Alert } from 'reactstrap';

import BasicLayout from 'Components/Presentational/Layout/Basic/Basic';

const error404 = () => (
  <div>
    <BasicLayout>
      <Alert color="light">
        <h4 className="alert-heading">Ztraceni!</h4>
        <p>
          Omlouváme se, ale vypadá to, že obsah, který hledáte není k nalezení.
        </p>
        <hr />
        <p className="mb-0">
          <small>Zkuste se prosím znovu později.</small>
        </p>
      </Alert>
    </BasicLayout>
  </div>
);

export default error404;
