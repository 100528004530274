import React from 'react';
import { Card, CardBody, Row, Col, FormGroup, Button, CustomInput } from 'reactstrap';
import { connect } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import InputField from '~/components/InputField/InputField';
import SelectField from '../SelectField/SelectField';
import CountrySelect from '../CountrySelect/CountrySelect';
import { SenderPickupType, SenderValues, PlaceOption } from '../types';

type Props = {
  pickupTypeButtons: {
    collectionPlace: boolean;
  };
  onPickupTypeChange(pickupType: SenderPickupType): void;
  pickupType: SenderPickupType;
  disabled: boolean;
  toggleContacts(): void;
  collectionPlaceOptions: PlaceOption[];
  collectionPlaces: any[];
  requiresCollectionPlacePostCode: boolean;
};

const Sender = connect<Props, SenderValues>(({
  pickupTypeButtons,
  onPickupTypeChange,
  pickupType,
  disabled,
  toggleContacts,
  collectionPlaceOptions,
  collectionPlaces,
  requiresCollectionPlacePostCode,
  formik,
}) => {
  const { t } = useTranslation(['deliveries', 'validation']);

  const {
    values,
    handleChange,
    setFieldValue,
  } = formik;

  return (
    <Card className="shadow border-0 mb-4">
      <CardBody>
        <Row>
          <Col md="4">
            <h4>{t('deliveries:NewDelivery.senderTitle')}</h4>
          </Col>

          <Col md="6">
            <FormGroup>
              <Button
                color="switcher"
                active={pickupType === 'address'}
                onClick={(): void => onPickupTypeChange('address')}
              >
                {t('deliveries:NewDelivery.Address.switchButton')}
              </Button>

              {
                pickupTypeButtons.collectionPlace && (
                  <Button
                    color="switcher"
                    active={pickupType === 'collectionPlace'}
                    onClick={(): void => onPickupTypeChange('collectionPlace')}
                  >
                    {t('deliveries:NewDelivery.CollectionPlace.switchButton')}
                  </Button>
                )
              }
            </FormGroup>

            {pickupType === 'collectionPlace' && (
              <>
                <FormGroup>
                  <SelectField
                    label={t('deliveries:NewDelivery.CollectionPlace.label')}
                    name="senderCollectionPlace"
                    placeholder={t('deliveries:NewDelivery.CollectionPlace.placeholder')}
                    options={collectionPlaceOptions}
                    onChange={(option) => {
                      if (!requiresCollectionPlacePostCode) {
                        return;
                      }

                      const collectionPlace = collectionPlaces.find((p) => {
                        return p.id === (option as PlaceOption).value;
                      });

                      if (collectionPlace) {
                        setFieldValue(
                          'senderCollectionPlacePostCode',
                          collectionPlace.collectionPlaceTypeByAgent?.metaData?.postCode ?? '',
                        );
                      }
                    }}
                  />
                </FormGroup>
                {requiresCollectionPlacePostCode && (
                  <FormGroup>
                    <InputField
                      label={t('deliveries:NewDelivery.CollectionPlace.PostCode.label')}
                      name="senderCollectionPlacePostCode"
                    />
                  </FormGroup>
                )}
              </>
            )}

            {pickupType === 'address' && (
              <div>
                <div className="form-row">
                  <FormGroup className="col-md-5">
                    <InputField
                      label={t('deliveries:NewDelivery.Address.SenderName.label')}
                      name="senderAddressName"
                      disabled={disabled}
                      placeholder={t('deliveries:NewDelivery.Address.SenderName.placeholder')}
                    />
                  </FormGroup>
                  <FormGroup className="col-md-5">
                    <InputField
                      label={t('validation:Surname.label')}
                      name="senderAddressSurname"
                      disabled={disabled}
                      placeholder={t('validation:Surname.placeholder')}
                    />
                  </FormGroup>

                  <div className="col-md-2 mt-4 pt-2">
                    <Button
                      onClick={(): void => toggleContacts()}
                    >
                      <FontAwesomeIcon
                        icon={
                          (disabled) ? ['fas', 'times'] : ['fas', 'address-book']
                        }
                        className="no-margin"
                      />
                    </Button>
                  </div>
                </div>

                <FormGroup>
                  <InputField
                    label={t('validation:CompanyName.label')}
                    name="senderCompanyName"
                    disabled={disabled}
                    placeholder={t('validation:CompanyName.label')}
                  />
                </FormGroup>
                <FormGroup>
                  <InputField
                    label={t('validation:Street.label')}
                    name="senderAddressStreet"
                    disabled={disabled}
                    placeholder={t('validation:Street.label')}
                  />
                </FormGroup>

                <div className="form-row">
                  <FormGroup className="col-md-8">
                    <InputField
                      label={t('validation:City.label')}
                      name="senderAddressCity"
                      disabled={disabled}
                      placeholder={t('validation:City.label')}
                    />
                  </FormGroup>

                  <FormGroup className="col-md-4">
                    <InputField
                      label={t('validation:ZipCode.label')}
                      name="senderAddressZip"
                      disabled={disabled}
                      placeholder={t('validation:ZipCode.label')}
                    />
                  </FormGroup>
                </div>

                <FormGroup>
                  <SelectField
                    component={CountrySelect}
                    label={t('validation:CountrySelect.label')}
                    name="senderState"
                    disabled={disabled}
                  />
                </FormGroup>

                <FormGroup className="pt-4">
                  <InputField
                    label={t('validation:Email.label')}
                    type="email"
                    name="senderEmail"
                    disabled={disabled}
                    placeholder={t('validation:Email.label')}
                  />
                </FormGroup>

                <FormGroup>
                  <InputField
                    label={t('validation:Phone.label')}
                    name="senderPhone"
                    disabled={disabled}
                    placeholder={t('validation:Phone.label')}
                  />
                </FormGroup>

                <FormGroup>
                  <CustomInput
                    type="checkbox"
                    id="senderSaveToAddressBook"
                    // value={values.senderSaveToAddressBook}
                    onChange={handleChange}
                    checked={values.senderSaveToAddressBook}
                    label={t('deliveries:NewDelivery.Address.checkboxLabel')}
                  />
                </FormGroup>
              </div>
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
});

export default Sender;
