import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';
import { Row, Col, FormGroup, Label, Button, FormFeedback } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { ValueType } from 'react-select';

import CountrySelect, { Option } from 'Containers/Deliveries/New/CountrySelect/CountrySelect';
import InputField from '~/components/InputField/InputField';
import { CollectionPlaceFormValues } from './CreateCollectionPlaceForm';

const CodForm: React.FC = () => {
  const { t } = useTranslation(['settings', 'validation']);

  const {
    values,
    setFieldValue,
    setValues,
    handleBlur,
    touched,
    errors,
  } = useFormikContext<CollectionPlaceFormValues>();

  const canAutofill = useMemo<boolean>(() => {
    if (
      values.senderAddressCompanyName !== ''
      || values.senderAddressStreet !== ''
      || values.senderAddressHouseNumber !== ''
      || values.senderAddressSequenceNumber !== ''
      || values.senderAddressPartCity !== ''
      || values.senderAddressCity !== ''
      || values.senderAddressZipCode !== ''
      || values.senderAddressCountryId !== ''
    ) {
      return true;
    }

    return false;
  }, [values]);

  const handleAutofill = () => {
    setValues({
      ...values,
      codAddressCity: values.senderAddressCity ?? '',
      codAddressCompanyName: values.senderAddressCompanyName ?? '',
      codAddressHouseNumber: values.senderAddressHouseNumber ?? '',
      codAddressCountryId: values.senderAddressCountryId,
      codAddressPartCity: values.senderAddressPartCity ?? '',
      codAddressSequenceNumber: values.senderAddressSequenceNumber ?? '',
      codAddressStreet: values.senderAddressStreet ?? '',
      codAddressZipCode: values.senderAddressZipCode ?? '',
    });
  };

  const handleChange = (field: string, option: ValueType<Option>) => {
    const selectedCountry = Array.isArray(option) ? option[0] : option;

    setFieldValue(field, selectedCountry.value);
  };

  return (
    <>
      <div className="d-flex flex-row justify-content-between">
        <h4 className="body font-weight-bold">
          {t('settings:Agents.CreateCollectionPlace.codTitle')}
        </h4>
        <Button
          type="button"
          onClick={handleAutofill}
          disabled={!canAutofill}
          title={t('settings:Agents.CreateCollectionPlace.autofillDescription')}
        >
          {t('settings:Agents.CreateCollectionPlace.autofillButton')}
        </Button>
      </div>
      <FormGroup>
        <Label>
          {t('validation:CompanyName.label')}
          <span className="text-danger">*</span>
        </Label>
        <InputField
          name="codAddressCompanyName"
          type="text"
          placeholder={t('validation:CompanyName.placeholder')}
        />
      </FormGroup>
      <FormGroup>
        <Label>
          {t('settings:Agents.CreateCollectionPlace.Sender.Street.label')}
          <span className="text-danger">*</span>
        </Label>
        <InputField
          name="codAddressStreet"
          type="text"
          placeholder={t('settings:Agents.CreateCollectionPlace.Sender.Street.placeholder')}
        />
      </FormGroup>
      <Row>
        <Col xs={12} sm={6}>
          <FormGroup>
            <Label>
              {t('settings:Agents.CreateCollectionPlace.Sender.HouseNumber.label')}
              <span className="text-danger">*</span>
            </Label>
            <InputField
              name="codAddressHouseNumber"
              type="text"
              placeholder={t('settings:Agents.CreateCollectionPlace.Sender.HouseNumber.placeholder')}
            />
          </FormGroup>
        </Col>
        <Col xs={12} sm={6}>
          <FormGroup>
            <Label>{t('settings:Agents.CreateCollectionPlace.Sender.SequenceNumber.label')}</Label>
            <InputField
              name="codAddressSequenceNumber"
              type="text"
              placeholder={t('settings:Agents.CreateCollectionPlace.Sender.SequenceNumber.placeholder')}
            />
          </FormGroup>
        </Col>
      </Row>
      <FormGroup>
        <Label>
          {t('validation:City.label')}
          <span className="text-danger">*</span>
        </Label>
        <InputField
          name="codAddressCity"
          type="text"
          placeholder={t('validation:City.placeholder')}
        />
      </FormGroup>
      <Row>
        <Col xs={12} sm={8}>
          <FormGroup>
            <Label>{t('settings:Agents.CreateCollectionPlace.Sender.District.label')}</Label>
            <InputField
              name="codAddressPartCity"
              type="text"
              placeholder={t('settings:Agents.CreateCollectionPlace.Sender.District.placeholder')}
            />
          </FormGroup>
        </Col>
        <Col xs={12} sm={4}>
          <FormGroup>
            <Label>
              {t('validation:ZipCode.label')}
              <span className="text-danger">*</span>
            </Label>
            <InputField
              name="codAddressZipCode"
              type="text"
              placeholder={t('validation:ZipCode.placeholder')}
            />
          </FormGroup>
        </Col>
      </Row>
      <FormGroup className="mb-5">
        <Label>
          {t('validation:CountrySelect.label')}
          <span className="text-danger">*</span>
        </Label>
        <CountrySelect
          name="codAddressCountryId"
          // `value` must be of type `string` due to `CustomSelect` implementation
          // (see CustomSelect.tsx:81)
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          value={values.codAddressCountryId}
          onChange={(option) => handleChange('codAddressCountryId', option)}
          onBlur={handleBlur}
          placeholder={t('validation:CountrySelect.placeholder')}
          defaultLabel={t('validation:CountrySelect.placeholder')}
        />
        {touched.codAddressCountryId && errors.codAddressCountryId && (
          <FormFeedback className="d-block">
            {errors.codAddressCountryId}
          </FormFeedback>
        )}
      </FormGroup>

      <h4 className="body font-weight-bold">
        {t('settings:Agents.CreateCollectionPlace.bankTitle')}
      </h4>
      <Row>
        <Col xs={12} sm={4}>
          <FormGroup>
            <Label>{t('settings:Agents.CreateCollectionPlace.BankAccountPrefix.label')}</Label>
            <InputField
              name="codBankPrefixAccount"
              type="text"
            />
          </FormGroup>
        </Col>
        <Col xs={12} sm={8}>
          <FormGroup>
            <Label>
              {t('settings:Agents.CreateCollectionPlace.BankAccountNumber.label')}
              <span className="text-danger">*</span>
            </Label>
            <InputField
              name="codBankAccount"
              type="text"
              placeholder={t('settings:Agents.CreateCollectionPlace.BankAccountNumber.placeholder')}
            />
          </FormGroup>
        </Col>
      </Row>
      <FormGroup className="mb-5">
        <Label>
          {t('settings:Agents.CreateCollectionPlace.BankCode.label')}
          <span className="text-danger">*</span>
        </Label>
        <InputField
          name="codBankCode"
          type="text"
          placeholder={t('settings:Agents.CreateCollectionPlace.BankCode.placeholder')}
        />
      </FormGroup>
    </>
  );
};

export default CodForm;
