import React from 'react';
import { Link } from 'react-router-dom';
import { withApollo } from 'react-apollo';
import produce from 'immer';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';

import MainBody from 'Components/Presentational/Layout/MainBody/MainBody';
import DataTable from 'Containers/DataTable/DataTable';
import Pagination from 'Components/Presentational/Pagination/Pagination';
import DeliveryTableAgent from 'Components/Deliveries/DeliveryTableAgent';
import TicketPrintModal from 'Containers/TicketPrintModal/TicketPrintModal';
import { getDeliveryInflectionText } from 'Shared/utilities';
import { graphQLHeaders } from 'Constants/app.const';
import Base64Helper from 'Tools/Base64Helper';
import PaginatorHelper from 'Tools/PaginatorHelper';
import { CustomQuery } from 'Models/Components/CustomQuery';
import BatchQueryFactory from 'Models/delivery/Batch/BatchQueryFactory';
import FilesFragments from 'Models/Files/Query/Fragments/FilesFragments';
import ApolloQueryTypes from 'Types/apollo/ApolloQueryTypes';
import EmptyState from '~/components/EmptyState/EmptyState';
import { format } from '~/services/date';
import { getCarrierLogoUrl } from '~/services/utils';

const DELIVERY_BATCHES = BatchQueryFactory.deliveryBatches(`
  items {
    id
    agent {
      originalName
      logoSlug
      maxTicketPositions
    }
    created
    deliveries { id }
    deliveriesNumber
    collectionPlace { name }
  }
  totalCount
`);

const DELIVERY_BATCH_CSV_BY_ID = BatchQueryFactory.deliveryBatchCsvExportById('...FileBase64', FilesFragments.fileBase64);

class Batches extends React.Component {
  static formatTableData(batches, t) {
    return batches.map((batch) => {
      const currentItem = {};

      currentItem.deliveriesNumber = (
        <div className="deliveries-count">
          {`${batch.deliveriesNumber}
                    ${getDeliveryInflectionText(batch.deliveriesNumber, t)}`}
        </div>
      );
      currentItem.agent = (
        <DeliveryTableAgent
          img={getCarrierLogoUrl(batch.agent.logoSlug)}
          imgAlt={batch.agent.originalName}
        />
      );

      currentItem.address = (
        <div className="collection-place">
          <span className="d-block">{batch.collectionPlace.name}</span>
          {t('batches:collectionPlaceLabel')}
        </div>
      );

      currentItem.created = (
        <div className="created">
          <span className="d-block">{format(new Date(batch.created), 'PP | p')}</span>
          {t('batches:batchCreatedDateLabel')}
        </div>
      );
      currentItem.id = batch.id;
      currentItem.agentLabelsPrintMatrix = batch.agent.maxTicketPositions;

      return currentItem;
    });
  }

  constructor(props) {
    super(props);

    const { t } = this.props;

    this.state = {
      deliveryIDs: [],
      checked: [],
      csv: {
        name: '',
        url: '',
      },
      showTicketPrintModal: false,
      filter: {
        limit: 25,
        offset: 0,
        orderBy: 'created',
        orderByDirection: 'DESC',
      },
    };

    this.table = {
      columns: [
        {
          title: '',
          index: 'deliveriesNumber',
          key: '1',
          className: 'middle',
        },
        {
          title: '',
          index: 'agent',
          key: '2',
        },
        {
          title: '',
          index: 'address',
          key: '3',
        },
        {
          title: '',
          index: 'created',
          key: '4',
          className: 'middle',
        },
      ],
      actions: {
        custom: {
          detail: {
            type: 'link',
            ref: '/batches/:id',
            icon: { class: ['far', 'eye'] },
            text: t('batches:ListItemTooltip.batchDetail'),
          },
          print: {
            type: 'function',
            func: this.toggleTicketPrint,
            params: ':id,:agentLabelsPrintMatrix',
            icon: { class: ['fas', 'print'] },
            text: t('batches:ListItemTooltip.printTicketLabel'),
          },
          export: {
            type: 'function',
            func: this.downloadCSV,
            params: ':id',
            icon: { class: ['fas', 'download'] },
            text: t('batches:ListItemTooltip.exportBatch'),
          },
        },
        rowClick: {
          type: 'link',
          ref: '/batches/:id',
        },
      },
      config: {
        hideActionButtons: true,
        striped: false,
        borderless: false,
        className: 'basic batches',
      },
    };

    this.csvDownloader = React.createRef();
    this.batchData = null;
    this.totalPages = null;
  }

  downloadCSV = async (id) => {
    const { client } = this.props;
    let csv = await client.query({
      query: DELIVERY_BATCH_CSV_BY_ID,
      context: graphQLHeaders,
      variables: { id },
    });
    csv = csv.data.deliveryBatchCsvExportById;

    const blobURL = Base64Helper.createLinkForEncryptedFile(csv.rawSource, 'application/csv');

    this.setState({
      csv: {
        url: blobURL,
        name: csv.name,
      },
    }, () => {
      this.csvDownloader.current.click();
    });
  };

  handlePaginatorClick = (page, refetch) => {
    const state = produce(this.state, (draft) => {
      const newState = draft;
      const { filter: { limit } } = this.state;

      newState.filter.offset = PaginatorHelper.computeOffset(page, limit);
    });
    this.setState(state, () => refetch);
  };

  toggleTicketPrint = (id) => {
    let deliveryIDs;
    let { showTicketPrintModal } = this.state;
    showTicketPrintModal = !showTicketPrintModal;

    if (id) {
      deliveryIDs = this.batchData
        .find((batch) => batch.id === id.toString());
      deliveryIDs = { ...deliveryIDs };
      deliveryIDs = deliveryIDs.deliveries.map((delivery) => delivery.id);
      return this.setState({
        deliveryIDs,
        showTicketPrintModal,
      });
    }

    return this.setState({ showTicketPrintModal });
  };

  render() {
    const { t } = this.props;

    const { filter, deliveryIDs, showTicketPrintModal, csv } = this.state;

    return (
      <MainBody title={t('batches:title')} wrapperHeight="150px">
        <CustomQuery
          query={DELIVERY_BATCHES}
          variables={filter}
        >
          {({ data, error, loading, refetch }) => {
            if (error) return null;
            if (typeof data === 'undefined') return null;
            if (Object.keys(data).length > 0 && !this.batchData) {
              this.batchData = data.deliveryBatches.items;
            }
            if (!loading && !this.totalPages) {
              this.totalPages = PaginatorHelper.computeTotalPages(
                data.deliveryBatches.totalCount,
                filter.limit,
              );
            }

            let content = (
              <div>
                <DataTable
                  data={!loading ? Batches.formatTableData(
                    data.deliveryBatches.items, t,
                  ) : []}
                  columns={this.table.columns}
                  actions={this.table.actions}
                  config={this.table.config}
                  isLoaderVisible={loading}
                />
                <Pagination
                  onPageChanged={(id) => this.handlePaginatorClick(id, refetch)}
                  totalPages={this.totalPages || 1}
                />
              </div>
            );
            if (!loading && data.deliveryBatches.items.length === 0) {
              content = (
                <EmptyState
                  imgSrc="/assets/img/empty-states/in-progress.png"
                  imgDescription={t('batches:EmptyState.imageDescription')}
                  title={t('batches:EmptyState.title')}
                  description={t('batches:EmptyState.description')}
                >
                  <Link to="/deliveries">
                    <Button color="primary">
                      {t('batches:EmptyState.goToDeliveries')}
                    </Button>
                  </Link>
                </EmptyState>
              );
            }
            return content;
          }}
        </CustomQuery>
        <TicketPrintModal
          deliveryIds={deliveryIDs}
          onToggle={this.toggleTicketPrint}
          isOpen={showTicketPrintModal}
        />
        <a
          id="csvDownloader"
          ref={this.csvDownloader}
          href={csv.url}
          download={csv.name}
          style={{ display: 'none' }}
        >
          {t('batches:downloadCSVLink')}
        </a>
      </MainBody>
    );
  }
}

Batches.propTypes = { client: ApolloQueryTypes.client().isRequired };

function mapStateToProps(state) {
  return { user: state.userReducer };
}

export default connect(mapStateToProps)(withApollo(withTranslation(['batches'])(Batches)));
