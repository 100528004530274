import React, { Fragment } from 'react';
import { Table, UncontrolledTooltip } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import Icon from 'Components/Presentational/Icon/Icon';
import MetricsRow from './MetricsRow/MetricsRow';
import { AgentStats, Cashflow, Currency } from '~/types/generated/graphql';

import css from './AgentMetrics.module.scss';

type Props = {
  agentStats: AgentStats[],
  cashflow: Cashflow[];
};

const AgentMetrics: React.FC<Props> = ({
  agentStats,
  cashflow,
}) => {
  const { t } = useTranslation(['dashboard', 'status']);

  const currencies: Currency[] = cashflow.map((x: Cashflow) => x.currency);

  if (!agentStats.length) {
    return (
      <div className="text-center">
        {t('dashboard:Agents.Metrics.noData')}
      </div>
    );
  }

  return (
    <Table
      responsive
      className={css.table}
    >
      <thead>
        <tr>
          <th style={{ width: '240px' }}>
            {t('dashboard:Agents.Metrics.agentName')}
          </th>
          <th style={{ width: '100px' }}>
            {t('dashboard:Agents.Metrics.rating')}
          </th>
          <th style={{ width: '130px' }}>
            {t('dashboard:Agents.Metrics.deliveries')}
          </th>
          <th>{t('status:StatusCategory.delivered')}</th>
          <th>{t('status:StatusCategory.en_route')}</th>
          <th>{t('status:StatusCategory.not_delivered')}</th>
          <th>{t('dashboard:Agents.Metrics.returns')}</th>
          <th>{t('dashboard:Agents.Metrics.deliveriesToSolve')}</th>

          {currencies.map((currency) => (
            <Fragment key={currency}>
              <th>
                {t('dashboard:Agents.Metrics.cashflow')}
                {' / '}
                <span className="font-weight-medium">
                  {currency}
                </span>
              </th>

              <th>
                {t('dashboard:Agents.Metrics.paid')}
              </th>

              <th>
                {t('dashboard:Agents.Metrics.cashOnDelivery')}
              </th>
            </Fragment>
          ))}

          {currencies.map((currency) => (
            <th key={currency}>
              {'AOV / '}

              <span className="font-weight-medium mr-1">
                {currency}
              </span>

              {/* Adding an extra span since Icon won't accept "id" as a prop */}
              <span id={`tooltip-aov-${currency}`}>
                <Icon
                  type="svg"
                  icon="info-circle"
                  className={css.icon}
                />
              </span>
              {/* Disabling fade since the animation runs at 3fps or not at all for some reason */}
              <UncontrolledTooltip
                placement="bottom"
                target={`tooltip-aov-${currency}`}
                fade={false}
                delay={0}
                className="_ds"
                innerClassName={css.tooltipContent}
              >
                <span className="text-nowrap">
                  {t('dashboard:Agents.Metrics.AOVtooltip')}
                </span>
              </UncontrolledTooltip>
            </th>
          ))}

          <th>
            &#8960;
            {' '}
            {t('dashboard:Agents.Metrics.deliveryTime')}
          </th>
          <th>
            &#8960;
            {' '}
            {t('dashboard:Agents.Metrics.pickUpTime')}
          </th>
        </tr>
      </thead>
      <tbody>
        {
          agentStats
            .map(({
              agent,
              deliveryCount,
              deliveryDuration,
              pickupDuration,
              important,
              cashflow: agentCashflow,
            }) => {
              return (
                <MetricsRow
                  key={agent.id}
                  agent={agent}
                  deliveryCount={deliveryCount}
                  deliveryDuration={deliveryDuration}
                  pickupDuration={pickupDuration}
                  important={important}
                  agentCashflow={agentCashflow}
                  currencies={currencies}
                />
              );
            })
        }
      </tbody>
    </Table>
  );
};

export default AgentMetrics;
