import React, { useMemo } from 'react';
import { Card, Table } from 'reactstrap';
import { generate as generateId } from 'shortid';

import ErrorTableRow from 'Containers/ImportCSV/Components/Container/CSVImportErrorTable/ErrorTable/ErrorTableRow';
import { generateReactKey } from '#/shared/utilities';
import { DataError, Column, Row, MappedRow } from '../types';

type Props = {
  additionalColumns: Column[],
  columns: Column[],
  rows: Row[],
  errors: DataError[],
}

function mapRows(
  rows: Row[],
  errors: DataError[],
  columns: Column[],
  additionalColumnCount: number,
): MappedRow[] {
  return rows.map((row, rowIndex) => {
    const cells = [...row, ...Array(additionalColumnCount).fill('')];

    const mappedCells = cells.map((cell, cellIndex) => {
      const cellId = `error-table-cell-${rowIndex}-${cellIndex}`;
      const cellColumn = columns[cellIndex]?.value ?? null;
      let cellError = null;

      if (cellColumn !== null) {
        cellError = errors.find((e) => e.row === rowIndex && e.column === cellColumn) ?? null;
      }

      return {
        id: cellId,
        row: rowIndex,
        column: cellColumn,
        value: cell,
        error: cellError,
      };
    });

    const rowLevelError = errors
      .filter((e) => {
        return e.row === rowIndex && (e.column === '' || e.column === null);
      })
      .reduce<Nullable<DataError>>((acc, e) => {
        const previousMessages = acc?.messages ?? [];

        return {
          row: e.row,
          column: e.column,
          messages: [
            ...previousMessages,
            ...e.messages,
          ],
        };
      }, null);

    return {
      id: generateId(),
      cells: mappedCells,
      rowLevelError,
    };
  });
}

const ErrorTable: React.FC<Props> = ({
  columns: propColumns,
  rows: propRows,
  errors = [],
  additionalColumns,
}) => {
  const additionalColumnCount = additionalColumns.length;

  const columns: Column[] = useMemo(() => {
    let unmappedColumns = [];
    const unmappedColumnsLength = propRows[0].length - propColumns.length;

    if (unmappedColumnsLength > 0) {
      unmappedColumns = Array(unmappedColumnsLength).fill({
        label: '',
        value: null,
      });
    }

    return [
      // Mapped columns
      ...propColumns,
      // Trailing unmapped columns
      ...unmappedColumns,
      /**
       * Additional columns are generated from validation errors for which a corresponding mapped
       * column doesn't exist.
       */
      ...additionalColumns,
    ];
  }, [propColumns, propRows, additionalColumns]);

  const mappedRows: MappedRow[] = useMemo(() => {
    return mapRows(propRows, errors, columns, additionalColumnCount);
  }, [propRows, errors, columns, additionalColumnCount]);

  const isAdditionalColumn = (columnValue: string) => {
    return additionalColumns.find((c) => c.value === columnValue) !== undefined;
  };

  function renderHeaders() {
    return columns.map((column: Column) => (
      <th
        key={generateReactKey()}
        className={isAdditionalColumn(column.value) ? 'bg-warning' : ''}
      >
        {column.label}
      </th>
    ));
  }

  return (
    <>
      <Card
        className="shadow mb-3"
        style={{
          overflow: 'auto',
        }}
      >
        <Table className="table-hover table-sm">
          <thead>
            <tr>
              <th>#</th>
              {useMemo(() => renderHeaders(), [propColumns])}
            </tr>
          </thead>

          <tbody>
            {mappedRows.map((row, rowIndex) => (
              <ErrorTableRow
                key={row.id}
                index={rowIndex}
                row={row}
              />
            ))}
          </tbody>
        </Table>
      </Card>
    </>
  );
};

export default ErrorTable;
