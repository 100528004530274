import PropTypes from 'prop-types';

export default class ReactRouterTypes {
  static history() {
    return PropTypes.shape({ push: PropTypes.func.isRequired });
  }

  static match() {
    return PropTypes.shape({ params: PropTypes.shape({}).isRequired });
  }
}
