// :: React
import React from 'react';
import PropTypes from 'prop-types';

const FoxdeliIcon = ({
  icon,
  className,
  style,
  size,
}) => (
  <i
    className={['foxdeli-icon', `size_${size}`, `icon-${icon}`, className].join(' ')}
    style={style}
  />
);

FoxdeliIcon.defaultProps = {
  className: '',
  style: null,
  size: 'default',
};

FoxdeliIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
  size: PropTypes.string,
  style: PropTypes.shape({}),
};

export default FoxdeliIcon;
