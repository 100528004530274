import { NavItem, Spinner } from 'reactstrap';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/free-solid-svg-icons';

import { cssMerge } from '~/services/utils';
import css from './ActionMenuItem.module.scss';

type Props = {
  onClick: () => void;
  icon: IconName;
  count: number;
  loading?: boolean;
  disabled?: boolean;
}

const ActionMenuItem: React.FC<Props> = ({
  onClick,
  icon,
  count,
  loading = false,
  disabled = false,
  children,
}) => {
  return (
    <NavItem className={cssMerge(css.item, disabled && css.disabled)} onClick={onClick}>
      {loading ? (
        <Spinner className="mb-2" />
      ) : (
        <div className="fa-2x fa-layers fa-fw mb-2">
          <FontAwesomeIcon icon={['fas', icon]} />
          {!disabled && (
            <span className={cssMerge(css.count, 'fa-layers-counter')}>
              {count}
            </span>
          )}
        </div>
      )}

      {children}
    </NavItem>
  );
};

export default ActionMenuItem;
