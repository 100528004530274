import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, FormGroup, Form, Label, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';

import getFullPathUrlOrDefault from 'Containers/Settings/Branding/helpers/getFullPathUrlOrDefault';
import { MarketingCampaignFormValues } from 'Containers/Settings/Branding/MarketingCampaigns/typesAndDefaults';
import { ACCEPTED_FILE_TYPES } from 'Containers/Settings/Branding/typesAndDefaults';
import InputField from '~/components/InputField/InputField';
import OnUserLeavePageDialog from '~/components/OnUserLeavePageDialog/OnUserLeavePageDialog';
import SubmitButton from '~/components/SubmitButton/SubmitButton';
import BannerItems from '../Banners/BannerItems';
import SimpleFileInput from '~/components/SimpleFileInput/SimpleFileInput';
import SimpleSwitch from '~/components/SimpleSwitch/SimpleSwitch';
import { validationSchema } from './validationSchema';
import InlineFormRow from '#/2020/components/InlineFormRow/InlineFormRow';
import FormikScroll from '~/components/FormikScroll/FormikScroll';
import Tooltip from '#/Components/Presentational/Tooltip/Tooltip';

type Props = {
  onSubmit: (values: MarketingCampaignFormValues, formikHelpers: FormikHelpers<MarketingCampaignFormValues>, onSubmitCallback: () => void) => Promise<void>,
  initialValues: MarketingCampaignFormValues,
}

const MarketingCampaignForm: React.FC<Props> = ({ onSubmit, initialValues }) => {
  const { t } = useTranslation(['settings']);
  const history = useHistory();
  const formRef = React.useRef<FormikProps<MarketingCampaignFormValues>>(null);
  const [formIsSaving, setFormIsSaving] = useState(false);

  const handleSubmit = async (values: MarketingCampaignFormValues, formikHelpers: FormikHelpers<MarketingCampaignFormValues>) => {
    setFormIsSaving(true);
    formikHelpers.setSubmitting(true);
    await onSubmit(values, formikHelpers, () => setFormIsSaving(false));
  };

  return (
    <Formik
      enableReinitialize
      innerRef={formRef}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema(t)}
    >
      {({ handleSubmit: formikHandleSubmit, isSubmitting, values, resetForm, submitForm, setFieldValue, dirty }) => (
        <Form onSubmit={formikHandleSubmit} className="marketing-campaign-form">
          <FormikScroll />
          <InlineFormRow
            sectionTitle={t('settings:Branding.MarketingCampaigns.Form.CampaignInfo.title')}
            description={t('settings:Branding.MarketingCampaigns.Form.CampaignInfo.description')}
          >
            <FormGroup>
              <Label>
                {t('settings:Branding.MarketingCampaigns.Form.CampaignInfo.nameLabel')}
                <span className="branding-required-field">*</span>
              </Label>
              <InputField
                name="campaignName"
                type="text"
                placeholder={t('settings:Branding.MarketingCampaigns.Form.CampaignInfo.namePlaceholder')}
              />
            </FormGroup>
          </InlineFormRow>

          <hr className="mb-5 mt-4" />
          <InlineFormRow
            sectionTitle={t('settings:Branding.MarketingCampaigns.Form.DefaultSettings.title')}
            description={t('settings:Branding.MarketingCampaigns.Form.DefaultSettings.description')}
          >
            <FormGroup>
              <Row className="justify-content-center clearfix mb-1">
                <Col xs={6}>
                  <h3>{t('settings:Branding.MarketingCampaigns.Form.DefaultSettings.trackAndTraceTitle')}</h3>
                </Col>
                <Col xs={6}>
                  <SimpleSwitch name="isTrackAndTraceActive" badgeStatus={values.isTrackAndTraceActive} />
                </Col>
              </Row>

              <FormGroup>
                <Label>
                  {t('settings:Branding.MarketingCampaigns.Form.CampaignInfo.linkWebLabel')}
                  <span className="branding-required-field">*</span>
                  <Tooltip
                    id="campaign-form-link-web-tooltip"
                    text={t('settings:Branding.MarketingCampaigns.Form.CampaignInfo.linkTooltip')}
                    linkIconPosition="after"
                    placement="right"
                  />
                </Label>
                <InputField
                  name="linkWeb"
                  type="text"
                  placeholder={t('settings:Branding.MarketingCampaigns.Form.CampaignInfo.linkWebPlaceholder')}
                />
              </FormGroup>

              <Row className="clearfix">
                <Col xs={6} className="float-left">
                  <SimpleFileInput
                    label={t('settings:Branding.MarketingCampaigns.Form.DefaultSettings.defaultDesktopBannerLabel')}
                    name="defaultDesktopBannerImage"
                    initialPreviewUrl={getFullPathUrlOrDefault(values.defaultDesktopBanner?.url)}
                    accept={ACCEPTED_FILE_TYPES.Web}
                    disabled={!values.isTrackAndTraceActive}
                  />
                </Col>
                <Col xs={6} className="float-right">
                  <SimpleFileInput
                    label={t('settings:Branding.MarketingCampaigns.Form.DefaultSettings.defaultMobileBannerLabel')}
                    name="defaultMobileBannerImage"
                    initialPreviewUrl={getFullPathUrlOrDefault(values.defaultMobileBanner?.url)}
                    accept={ACCEPTED_FILE_TYPES.Web}
                    disabled={!values.isTrackAndTraceActive}
                  />
                </Col>
                <p className="branding-technical-info">
                  {t('settings:Branding.MarketingCampaigns.Form.trackAndTraceBannerTechnicalInfo', {
                    width: 800,
                    height: 200,
                    mobileWidth: 400,
                    mobileHeight: 200,
                  })}
                </p>
              </Row>

              <Row className="justify-content-center clearfix mt-3 mb-1">
                <Col xs={6}>
                  <h3>{t('settings:Branding.MarketingCampaigns.Form.DefaultSettings.emailCommunicationTitle')}</h3>
                </Col>
                <Col xs={6}>
                  <SimpleSwitch name="isEmailCommunicationActive" badgeStatus={values.isEmailCommunicationActive} />
                </Col>
              </Row>

              <FormGroup>
                <Label>
                  {t('settings:Branding.MarketingCampaigns.Form.CampaignInfo.linkEmailLabel')}
                  <span className="branding-required-field">*</span>
                  <Tooltip
                    id="campaign-form-link-email-tooltip"
                    text={t('settings:Branding.MarketingCampaigns.Form.CampaignInfo.linkTooltip')}
                    linkIconPosition="after"
                    placement="right"
                  />
                </Label>
                <InputField
                  name="linkEmail"
                  type="text"
                  placeholder={t('settings:Branding.MarketingCampaigns.Form.CampaignInfo.linkEmailPlaceholder')}
                />
              </FormGroup>

              <Row className="justify-content-center clearfix">
                <Col xs={12}>
                  <SimpleFileInput
                    label={t('settings:Branding.MarketingCampaigns.Form.DefaultSettings.defaultEmailBannerLabel')}
                    name="defaultEmailBannerImage"
                    initialPreviewUrl={getFullPathUrlOrDefault(values.defaultEmailBanner?.url)}
                    accept={ACCEPTED_FILE_TYPES.Email}
                    disabled={!values.isEmailCommunicationActive}
                  />
                </Col>
                <p className="branding-technical-info">
                  {t('settings:Branding.MarketingCampaigns.Form.mailingBannerTechnicalInfo', { width: 600, height: 200 })}
                </p>
              </Row>
            </FormGroup>
          </InlineFormRow>

          <h2 className="mt-5 branding-subtitle">{t('settings:Branding.MarketingCampaigns.Form.bannerSectionTitle')}</h2>
          <hr className="mb-3" />

          <BannerItems />
          <hr className="mb-3" />

          <div className="branding-form-controls">
            <SubmitButton data-test="submit-marketing-campaign-form" color="primary" type="submit" isLoading={isSubmitting}>
              {t('settings:Branding.MarketingCampaigns.Form.submit')}
            </SubmitButton>
            <SubmitButton
              data-test="submit-and-activate-marketing-campaign-form"
              className="ml-2 mr-5"
              color="primary"
              outline
              onClick={async () => {
                setFieldValue('activateAfterSave', true);
                await submitForm();
              }}
              isLoading={isSubmitting}
            >
              {t('settings:Branding.TrackAndTrace.Form.submitAndActivate')}
            </SubmitButton>
            <Button
              data-test="close-marketing-campaign-form"
              className="align-middle ml-md-auto mr-2"
              color="danger"
              outline
              onClick={() => history.push('/settings/branding/marketing-campaigns')}
            >
              {t('settings:Branding.MarketingCampaigns.Form.cancel')}
            </Button>
            <Button color="dark" size="xs" disabled={!dirty} onClick={() => resetForm()}>
              <FontAwesomeIcon icon={['fas', 'trash']} fixedWidth />
              {t('settings:Branding.MarketingCampaigns.Form.discardChanges')}
            </Button>
          </div>
          { dirty && !formIsSaving && <OnUserLeavePageDialog /> }
        </Form>
      )}
    </Formik>
  );
};

export default MarketingCampaignForm;
