import React from 'react';

import Accordion from 'Components/Presentational/Accordion/Accordion';
import Icon from 'Components/Presentational/Icon/Icon';

const TimelineIconAccordion = (props) => {
  const { className, icon } = props;

  return (
    <div className="timeline-accordion-wrapper d-flex">
      <div className={['timeline-accordion-icon-wrapper', className].join(' ')}>
        <Icon
          type={icon.type}
          icon={icon.icon}
          className={icon.className}
          size={icon.size}
          style={icon.style}
        />
        <span className="dots" />
      </div>
      <div className="flex-grow-1">
        <Accordion {...props} />
      </div>
    </div>
  );
};

export default TimelineIconAccordion;
