import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import localazyMetadata from '~/types/generated/languages';
import configurator from '~/services/Configurator';

const supportedLanguages = localazyMetadata.languages.map((l) => l.language);

i18n
  // https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  .use(initReactI18next)
  // https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'cs',
    supportedLngs: supportedLanguages,
    ns: [
      'common',
      'validation',
      'settings',
    ],
    defaultNS: 'common',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'b'],
    },
    backend: {
      queryStringParams: {
        v: configurator.config.i18nVersion ?? '1',
      },
    },
  });

i18n.on('languageChanged', (language) => {
  if (typeof window !== 'undefined') {
    const htmlLanguage = document.documentElement.lang;

    if (htmlLanguage !== language) {
      document.documentElement.lang = language;
    }

    window.__localeId__ = language;
  }
});

export default i18n;
