export const filterObjectEmptyProperties = (object: { [key: string]: string | null | undefined }): { [key: string]: string | number | boolean } => {
  const filteredObject = {} as { [key: string]: string | number | boolean };

  Object.keys(object).forEach((key) => {
    const objectValue = object[key];

    if (objectValue !== null && objectValue !== undefined && objectValue !== '') {
      filteredObject[key] = objectValue;
    }
  });

  return filteredObject;
};
