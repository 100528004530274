import gql from 'graphql-tag';
import AgentFragments from '../../../agent/Query/Fragments/AgentFragments';

export default class CompanyQueryFragments {
  static credentialsPreview() {
    return gql`
            fragment CredentialsPreview on Company {
                credentials {
                    id
                    agent {
                        ...AgentPreview
                    }
                    services(onlyActive: true) {
                        deliveryType { name }
                    }
                    status
                }
            }
            ${AgentFragments.agentPreview()}
        `;
  }

  static credentialsForm() {
    return gql`
            fragment CredentialsForm on CompanyCredentials {
                type
                status
                isCollectionPlaceUpdateSupported
                services {
                    id
                    active
                    startNumber {
                        id
                        number
                        used
                    }
                    numberSequences {
                        id
                        start
                        end
                        cod
                        active
                        created
                        countNumbers
                        countRemainingNumbers
                    }
                    deliveryType {
                        agent { name }
                        id
                        name
                        abbr
                    }
                }
                collectionPlacesTypes {   
                    id
                    note
                    regular
                    externalPlaceId
                    collectionPlace {
                       id
                    }
                    metaData {
                        postCode
                    }
                }
                techNumbers {
                    id
                    number
                    type
                    active
                }
            }
        `;
  }

  static credentialEditForm() {
    return gql`
            fragment CredentialEditForm on Company {
                credentials {
                    id
                    username
                    password
                    companyCode
                    ...CredentialsForm
                    agent {
                        ...CredentialForm
                    }
                }
            }
            ${AgentFragments.credentialEditForm()}
            ${CompanyQueryFragments.credentialsForm()}
        `;
  }

  static credentialCreate() {
    return gql`
            fragment CredentialCreate on CompanyCredentials {
                id
                isCollectionPlaceUpdateSupported
            }
        `;
  }
}
