import React from 'react';
import PropTypes from 'prop-types';

import RefreshDeliveryUI from '../../Presentational/RefreshDelivery/RefreshDelivery';
import LocalDeliveryTypes from '../../LocalDeliveryTypes/LocalDeliveryTypes';

export default class RefreshDelivery extends React.Component {
  refetchDetail = async () => {
    const { refetchDeliveryDetail, mutationService } = this.props;

    mutationService.send('REFRESH_MUTATION_DISPATCHED');
    await refetchDeliveryDetail();
    mutationService.send('REFRESH_MUTATION_RESPONSE_RECEIVED');
  };

  render() {
    const { mutationState } = this.props;
    return <RefreshDeliveryUI onRefreshClick={this.refetchDetail} mutationState={mutationState} />;
  }
}

RefreshDelivery.propTypes = {
  refetchDeliveryDetail: LocalDeliveryTypes.every().refetchDeliveryDetail.isRequired,
  mutationState: PropTypes.string.isRequired,
  mutationService: LocalDeliveryTypes.every().mutationService.isRequired,
};
