const csvFieldsSchema = (t) => {
  const csvFields = {
    firstname: {
      label: t('deliveries:NewDeliveryCSV.SampleData.SelectItems.firstname'),
      required: false,
    },
    surname: {
      label: t('deliveries:NewDeliveryCSV.SampleData.SelectItems.surname'),
      required: true,
    },
    contactPerson: {
      label: t('deliveries:NewDeliveryCSV.SampleData.SelectItems.contactPerson'),
      required: false,
    },
    street: {
      label: t('deliveries:NewDeliveryCSV.SampleData.SelectItems.street'),
      required: true,
    },
    streetNumber: { label: t('deliveries:NewDeliveryCSV.SampleData.SelectItems.streetNumber') },
    city: {
      label: t('deliveries:NewDeliveryCSV.SampleData.SelectItems.city'),
      required: true,
    },
    postalCode: {
      label: t('deliveries:NewDeliveryCSV.SampleData.SelectItems.postalCode'),
      required: true,
    },
    country: {
      label: t('deliveries:NewDeliveryCSV.SampleData.SelectItems.country'),
      required: false,
    },
    email: {
      label: t('deliveries:NewDeliveryCSV.SampleData.SelectItems.email'),
      required: true,
    },
    phone: {
      label: t('deliveries:NewDeliveryCSV.SampleData.SelectItems.phone'),
      required: true,
    },
    value: {
      label: t('deliveries:NewDeliveryCSV.SampleData.SelectItems.deliveryValue'),
      required: true,
    },
    valueCurrency: {
      label: t('deliveries:NewDeliveryCSV.SampleData.SelectItems.currencyValue'),
      required: false,
    },
    packagesCount: {
      label: t('deliveries:NewDeliveryCSV.SampleData.SelectItems.packagesCount'),
      required: false,
    },
    weight: {
      label: t('deliveries:NewDeliveryCSV.SampleData.SelectItems.packageWeight'),
      required: false,
    },
    length: {
      label: t('deliveries:NewDeliveryCSV.SampleData.SelectItems.packageLength'),
      required: false,
    },
    width: {
      label: t('deliveries:NewDeliveryCSV.SampleData.SelectItems.packageWidth'),
      required: false,
    },
    height: {
      label: t('deliveries:NewDeliveryCSV.SampleData.SelectItems.packageHeight'),
      required: false,
    },
    cod: {
      label: t('deliveries:NewDeliveryCSV.SampleData.SelectItems.cashOnDelivery'),
      required: true,
    },
    codCurrency: {
      label: t('deliveries:NewDeliveryCSV.SampleData.SelectItems.cashOnDeliveryCurrency'),
      required: false,
    },
    variableSymbol: {
      label: t('deliveries:NewDeliveryCSV.SampleData.SelectItems.variableSymbol'),
      required: true,
    },
    deliveryType: {
      label: t('deliveries:NewDeliveryCSV.SampleData.SelectItems.deliveryType'),
      required: false,
    },
    agent: {
      label: t('deliveries:NewDeliveryCSV.SampleData.SelectItems.agent'),
      required: false,
    },
    extraServices: {
      label: t('deliveries:NewDeliveryCSV.SampleData.SelectItems.extraServices'),
      required: false,
    },
    collectionPlace: {
      label: t('deliveries:NewDeliveryCSV.SampleData.SelectItems.collectionPlace'),
      required: false,
    },
    externalId: {
      label: t('deliveries:NewDeliveryCSV.SampleData.SelectItems.externalId'),
      required: false,
    },
    pickUpPlaceId: {
      label: t('deliveries:NewDeliveryCSV.SampleData.SelectItems.pickUpPlaceId'),
      required: false,
    },
    ticketNote: {
      label: t('deliveries:NewDeliveryCSV.SampleData.SelectItems.ticketNote'),
      required: false,
    },
    trackingNumber: {
      label: t('deliveries:NewDeliveryCSV.SampleData.SelectItems.trackingNumber'),
      required: false,
    },
    referenceId: {
      label: t('deliveries:NewDeliveryCSV.SampleData.SelectItems.referenceId'),
      required: false,
    },
  };
  return csvFields;
};

export default csvFieldsSchema;
