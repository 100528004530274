import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import basePaths from 'Routes/paths/base.path';
import ReactRouterTypes from 'Types/ReactRouter/ReactRouterTypes';

const AgentFormFooter = ({ isSubmitDisabled, history, onSubmitClick }) => {
  const { t } = useTranslation('settings');

  return (
    <div className="form-group">
      <div className="text-center">
        <Button
          className="mx-2"
          color="primary"
          type="submit"
          onClick={onSubmitClick}
          disabled={isSubmitDisabled}
        >
          {t('Agents.submitButton')}
        </Button>

        <Button
          className="mx-2"
          color="secondary"
          type="button"
          onClick={() => {
            history.push(`${basePaths.settings}/agents`);
          }}
        >
          {t('Agents.returnButton')}
        </Button>
      </div>
    </div>
  );
};

AgentFormFooter.propTypes = {
  history: ReactRouterTypes.history().isRequired,
  isSubmitDisabled: PropTypes.bool.isRequired,
};

export default AgentFormFooter;
