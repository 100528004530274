import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import EmptyState from '~/components/EmptyState/EmptyState';

type Props = {
  important: boolean;
  category: string;
}

const DeliveryEmptyState: React.FC<Props> = ({ important, category }) => {
  const { t } = useTranslation(['status', 'deliveries']);

  const titles = useMemo<{ [key: string]: string} >(() => ({
    important: t('deliveries:EmptyState.Title.important'),
    inProgress: t('deliveries:EmptyState.Title.inProgress'),
    toSend: t('deliveries:EmptyState.Title.readyToSend'),
    enRoute: t('deliveries:EmptyState.Title.sent'),
    delivered: t('deliveries:EmptyState.Title.delivered'),
    returned: t('deliveries:EmptyState.Title.notDelivered'),
    canceled: t('deliveries:EmptyState.Title.canceled'),
  }), [t]);

  const categories = useMemo<{ [key: string]: string} >(() => ({
    important: t('status:StatusCategory.important'),
    inProgress: t('status:StatusCategory.in_progress'),
    toSend: t('status:StatusCategory.ready_to_send'),
    enRoute: t('status:StatusCategory.sent'),
    delivered: t('status:StatusCategory.delivered'),
    returned: t('status:StatusCategory.not_delivered'),
    canceled: t('status:StatusCategory.canceled'),
  }), [t]);

  const title = useMemo(() => titles[category], [category, titles]);
  const categoryTranslation = useMemo(() => categories[category], [category, categories]);

  if (important) {
    return (
      <EmptyState
        className="pt-5"
        imgSrc="/assets/img/empty-states/important.png"
        title={t('deliveries:EmptyState.Title.important')}
        description={t('deliveries:EmptyState.Description.important')}
      />
    );
  }

  return (
    <EmptyState
      className="mt-5 pt-5"
      title={title}
      description={categoryTranslation ? t('deliveries:EmptyState.Description.default', { category: categoryTranslation }) : ''}
    />
  );
};

export default DeliveryEmptyState;
