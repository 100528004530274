import getFullPathUrlOrDefault from 'Containers/Settings/Branding/helpers/getFullPathUrlOrDefault';
import statusToBadgeMapper from 'Containers/Settings/Branding/helpers/statusToBadgeMapper';
import {
  MutationEmailActivate,
  MutationEmailDeactivate,
  MutationEmailDelete,
  QueryEshopEmailConfig,
} from 'Containers/Settings/Branding/Mailing/typesAndDefaults';
import MARKETING_CAMPAIGNS_LIST from 'Containers/Settings/Branding/MarketingCampaigns/queries/fetchMarketingCampaigns.gql';
import { QueryMarketingCampaignsListData } from 'Containers/Settings/Branding/MarketingCampaigns/typesAndDefaults';
import { DATE_FORMAT_BRANDING } from 'Containers/Settings/Branding/typesAndDefaults';
import ESHOP from 'Containers/Settings/Eshop/eshop.gql';
import { EshopQuery } from 'Containers/Settings/Eshop/types';
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Row, Col, Button, Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import TestEmailModal from 'Components/Containers/Settings/Branding/Mailing/TestEmailModal/TestEmailModal';
import * as userActions from '#/store/actions/user.action';
import { toast } from '#/shared/utilities';
import CompanyInformationBanner from '~/components/CompanyInformationBanner/CompanyInformationBanner';
import FoxLoader from '~/components/FoxLoader/FoxLoader';
import MailingPreview, { MailingPreviewSettings } from '~/components/MailingPreview/MailingPreview';
import ModalWithPreview from '~/components/ModalWithPreview/ModalWithPreview';
import SimpleTable from '~/components/SimpleTable/SimpleTable';
import SimpleDropdown from '~/components/SimpleDropdown/SimpleDropdown';
import SimpleModal, { ModalData } from '~/components/SimpleModal/SimpleModal';
import StatusBanner from '~/components/StatusBanner/StatusBanner';
import EmptyState from '~/components/EmptyState/EmptyState';
import useAuthMutation from '~/hooks/useAuthMutation';
import useAuthQuery from '~/hooks/useAuthQuery';
import { format } from '~/services/date';

import EMAIL_CONFIG from './queries/configEmail.gql';
import EMAIL_ACTIVATE from './queries/activateEmail.gql';
import EMAIL_DEACTIVATE from './queries/deactivateEmail.gql';
import EMAIL_DELETE from './queries/deleteEmail.gql';

const Mailing: React.FC = () => {
  const { t } = useTranslation(['settings']);
  const dispatch = useDispatch();
  const [modalData, setModalData] = React.useState<ModalData>();
  const [modalIsOpen, setModalIsOpen] = React.useState(false);

  const { data: emailConfigDataQuery, loading: emailConfigLoadingQuery, refetch: emailConfigRefetchQuery } = useAuthQuery<QueryEshopEmailConfig>(EMAIL_CONFIG);
  const [activateEmailConfigMutation] = useAuthMutation<MutationEmailActivate, { id: string }>(EMAIL_ACTIVATE);
  const [deactivateEmailConfigMutation] = useAuthMutation<MutationEmailDeactivate>(EMAIL_DEACTIVATE);
  const [deleteEmailConfigMutation] = useAuthMutation<MutationEmailDelete, { id: string }>(EMAIL_DELETE);

  const { data: eshopQueryData } = useAuthQuery<{ eshop: EshopQuery }, never>(ESHOP);
  const { data: marketingCampaignsDataQuery } = useAuthQuery<QueryMarketingCampaignsListData>(MARKETING_CAMPAIGNS_LIST);
  const activeMarketingCampaignEmailBannerUrl = marketingCampaignsDataQuery?.eshop?.activeEshopCampaign?.defaultEmailBanner?.url;

  const emailConfigs = (emailConfigDataQuery?.eshop.emailConfigs || []).sort((a, b) => parseInt(b.id, 10) - parseInt(a.id, 10));
  const activeEmailConfig = emailConfigDataQuery?.eshop.activeEmailConfig;
  const mailingPreviewConfig: MailingPreviewSettings = {
    logo: getFullPathUrlOrDefault(activeEmailConfig?.logo?.url),
    sender: activeEmailConfig?.sender,
    linkColor: activeEmailConfig?.linkColor,
    senderEmail: activeEmailConfig?.senderEmail,
    primaryColor: activeEmailConfig?.primaryColor,
    headerColor: activeEmailConfig?.headerColor,
    headerBackgroundColor: activeEmailConfig?.headerBackgroundColor,
    buttonColor: activeEmailConfig?.buttonColor,
    buttonBackgroundColor: activeEmailConfig?.buttonBackgroundColor,
    eshopName: eshopQueryData?.eshop?.name,
    eshopUrl: eshopQueryData?.eshop?.webUrl,
    activeMarketingCampaignEmailBannerUrl: getFullPathUrlOrDefault(activeMarketingCampaignEmailBannerUrl),
  };

  const activateEmailConfig = async (id: string) => {
    try {
      await activateEmailConfigMutation({ variables: { id } });
      toast.success(t('settings:Branding.Mailing.Message.templateActivated'));
      dispatch(userActions.setUserMeta({ hasActiveMailing: true }));
      await emailConfigRefetchQuery();
    } catch (e) {
      // Handled in global error interceptor
    }
  };

  const deactivateEmailConfig = async () => {
    try {
      await deactivateEmailConfigMutation();
      toast.success(t('settings:Branding.Mailing.Message.templateDeactivated'));
      dispatch(userActions.setUserMeta({ hasActiveMailing: false }));
      await emailConfigRefetchQuery();
    } catch (e) {
      // Handled in global error interceptor
    }
  };

  const deleteEmailConfig = async (id: string) => {
    try {
      await deleteEmailConfigMutation({ variables: { id } });
      toast.success(t('settings:Branding.Mailing.Message.templateDeleted'));

      if (activeEmailConfig?.id === id) {
        dispatch(userActions.setUserMeta({ hasActiveMailing: false }));
      }

      await emailConfigRefetchQuery();
    } catch (e) {
      // Handled in global error interceptor
    }
  };

  const openActivateModal = (itemForActivationId: string) => {
    setModalData({
      title: t('settings:Branding.Mailing.Library.ActivateModal.title'),
      description: t('settings:Branding.Mailing.Library.ActivateModal.description'),
      cancelButton: t('settings:Branding.Mailing.Library.ActivateModal.cancelButton'),
      submitButton: t('settings:Branding.Mailing.Library.ActivateModal.submitButton'),
      onActionItem: () => activateEmailConfig(itemForActivationId),
      submitButtonColor: 'primary',
    });

    setModalIsOpen(!modalIsOpen);
  };

  const openDeactivateModal = () => {
    setModalData({
      title: t('settings:Branding.Mailing.Library.DeactivateModal.title'),
      description: t('settings:Branding.Mailing.Library.DeactivateModal.description'),
      cancelButton: t('settings:Branding.Mailing.Library.DeactivateModal.cancelButton'),
      submitButton: t('settings:Branding.Mailing.Library.DeactivateModal.submitButton'),
      onActionItem: () => deactivateEmailConfig(),
      submitButtonColor: 'danger',
    });

    setModalIsOpen(!modalIsOpen);
  };

  const openDeleteModal = (itemForDeletionId: string) => {
    setModalData({
      title: t('settings:Branding.Mailing.Library.DeleteModal.title'),
      description: t('settings:Branding.Mailing.Library.DeleteModal.description'),
      cancelButton: t('settings:Branding.Mailing.Library.DeleteModal.cancelButton'),
      submitButton: t('settings:Branding.Mailing.Library.DeleteModal.submitButton'),
      onActionItem: () => deleteEmailConfig(itemForDeletionId),
      submitButtonColor: 'danger',
    });

    setModalIsOpen(!modalIsOpen);
  };

  const tableTitlesLibrary = [
    { title: t('settings:Branding.Mailing.Library.Table.Titles.mailing') },
    { title: t('settings:Branding.Mailing.Library.Table.Titles.lastSaved') },
    { title: t('settings:Branding.Mailing.Library.Table.Titles.status'), className: 'text-center' },
  ];

  const actionItems = (emailId: string) => {
    return [
      activeEmailConfig?.id === emailId ? {
        key: 'deactivate',
        title: t('settings:Branding.Mailing.Library.Table.Actions.deactivate'),
        onClick: () => {
          openDeactivateModal();
        },
      } : {
        key: 'activate',
        title: t('settings:Branding.Mailing.Library.Table.Actions.activate'),
        onClick: () => openActivateModal(emailId),
      },
      {
        key: 'edit',
        title: t('settings:Branding.Mailing.Library.Table.Actions.edit'),
        url: `/settings/branding/mailing/${emailId}`,
      },
      {
        key: 'delete',
        title: t('settings:Branding.Mailing.Library.Table.Actions.delete'),
        onClick: () => openDeleteModal(emailId),
        className: 'text-danger',
      },
    ];
  };

  const tableRows = emailConfigs.map((emailConfig) => ({
    key: emailConfig.id,
    cells: [
      { key: `${emailConfig.id}1`, value: emailConfig.title, onClickUrl: `/settings/branding/mailing/${emailConfig.id}` },
      {
        key: `${emailConfig.id}2`,
        onClickUrl: `/settings/branding/mailing/${emailConfig.id}`,
        value: format(new Date(emailConfig.lastUpdatedAt), DATE_FORMAT_BRANDING),
      },
      {
        key: `${emailConfig.id}3`,
        className: 'text-center',
        onClickUrl: `/settings/branding/mailing/${emailConfig.id}`,
        value: (
          <Badge color={statusToBadgeMapper(activeEmailConfig?.id === emailConfig.id)}>
            { t(`settings:Branding.Mailing.Library.Table.Status.${activeEmailConfig?.id === emailConfig.id ? 'active' : 'inactive'}`) }
          </Badge>
        ),
      },
      {
        key: `${emailConfig.id}4`,
        className: 'text-right',
        value: (
          <SimpleDropdown items={actionItems(emailConfig.id)} text={<FontAwesomeIcon icon={['fas', 'ellipsis-h']} />} />
        ),
      },
    ],
  }));

  let content = (
    <>
      <StatusBanner
        status={activeEmailConfig !== null}
        title={{
          active: t('settings:Branding.Mailing.StatusBanner.titleActive'),
          inactive: t('settings:Branding.Mailing.StatusBanner.titleInactive'),
        }}
      />

      {activeEmailConfig && (
        <>
          <Row className="mt-5">
            <Col xs={5}>
              <h2 className="branding-subtitle">
                {t('settings:Branding.Mailing.ActiveEmail.title')}
              </h2>
            </Col>
            <Col xs={7} className="text-right">
              <Link to={`/settings/branding/mailing/${activeEmailConfig.id}`} className="btn btn-primary">
                {t('settings:Branding.Mailing.ActiveEmail.editButton')}
              </Link>
              <SimpleDropdown
                items={actionItems(activeEmailConfig.id)}
                text={(
                  <Button color="secondary" className="ml-3">
                    <FontAwesomeIcon icon={['fas', 'ellipsis-h']} className="mr-0" />
                  </Button>
                )}
              />
            </Col>
          </Row>

          <Row className="mt-4">
            <Col className="d-flex">
              <dl className="branding-description-list mb-0">
                <dt>{t('settings:Branding.Mailing.ActiveEmail.nameLabel')}</dt>
                <dd>{activeEmailConfig?.title}</dd>

                <dt>{t('settings:Branding.Mailing.ActiveEmail.lastSavedLabel')}</dt>
                <dd>{format(new Date(activeEmailConfig?.lastUpdatedAt), DATE_FORMAT_BRANDING)}</dd>
              </dl>
            </Col>
            <Col>
              <ModalWithPreview
                modalTitle={t('settings:Branding.Mailing.PreviewModal.title')}
                modalCancelButtonText={t('settings:Branding.Mailing.PreviewModal.closeButton')}
              >
                <MailingPreview previewSettings={mailingPreviewConfig} />
              </ModalWithPreview>
            </Col>
          </Row>
        </>
      )}

      <hr className="mb-4 mt-4" />
      <CompanyInformationBanner />
      <hr className="mb-4 mt-3" />

      <Row className="mt-5">
        <Col xs={6}>
          <h2 className="branding-subtitle mt-3">
            {t('settings:Branding.Mailing.Library.title')}
          </h2>
        </Col>
        <Col xs={6} className="text-right">
          <Link
            to="/settings/branding/mailing/new"
            className="btn btn-secondary"
            data-test="new-mailing-button"
          >
            <FontAwesomeIcon icon={['fas', 'plus']} fixedWidth />
            {t('settings:Branding.Mailing.Library.newEmailButton')}
          </Link>
        </Col>
      </Row>
      <hr className="mb-4 mt-3" />

      <Row className="mt-4">
        <Col>
          <SimpleTable variant="bordered" headers={tableTitlesLibrary} rows={tableRows} />
          {modalData && (
            <SimpleModal
              isOpen={modalIsOpen}
              toggle={() => setModalIsOpen(!modalIsOpen)}
              onActionItem={modalData.onActionItem}
              title={modalData.title}
              description={modalData.description}
              cancelButton={modalData.cancelButton}
              submitButton={modalData.submitButton}
              submitButtonColor={modalData.submitButtonColor}
            />
          )}
        </Col>
      </Row>
    </>
  );

  if (emailConfigs.length === 0) {
    content = (
      <EmptyState
        imgSrc="/assets/img/empty-states/connect.png"
        imgDescription={t('settings:Branding.Mailing.EmptyState.imgDescription')}
        title={t('settings:Branding.Mailing.EmptyState.title')}
        description={t('settings:Branding.Mailing.EmptyState.description')}
      />
    );
  }

  return (
    <>
      <Row className="mb-4">
        <Col>
          <h1>{t('settings:Branding.Mailing.title')}</h1>
        </Col>
        <Col className="d-flex justify-content-end align-items-start">
          {!emailConfigLoadingQuery && emailConfigs.length === 0 && (
            <Link
              to="/settings/branding/mailing/new"
              className="btn btn-primary"
              data-test="new-mailing-button"
            >
              <FontAwesomeIcon icon={['fas', 'plus']} fixedWidth />
              {t('settings:Branding.Mailing.Library.newEmailButton')}
            </Link>
          )}
          {!emailConfigLoadingQuery && activeEmailConfig && (
            <TestEmailModal activeTemplateId={activeEmailConfig.id} />
          )}
        </Col>
      </Row>
      {emailConfigLoadingQuery ? (<FoxLoader withOverlay={false} />) : (content)}
    </>
  );
};

export default Mailing;
