import React from 'react';
import { produce } from 'immer';

import { generateReactKey } from 'Shared/utilities';
import StateAccordion from './StateAccordion/StateAccordion';
import ActionAccordion from './ActionAccordion/ActionAccordion';
import NoteAccordion from './NoteAccordion/NoteAccordion';
import DelayedAccordion from './DelayedAccordion/DelayedAccordion';
import { format } from '~/services/date';

const TimelineAccordion = ({ events, onEditNoteClick, notes }) => {
  const accordionComponents = {
    state: (updatedEvents) => (
      <StateAccordion
        events={updatedEvents}
        key={generateReactKey()}
      />
    ),
    action: (updatedEvents) => (
      <ActionAccordion
        events={updatedEvents}
        key={generateReactKey()}
      />
    ),
    delayed: (updatedEvents) => (
      <DelayedAccordion
        events={updatedEvents}
        key={generateReactKey()}
      />
    ),
    note: (updatedEvents) => (
      <NoteAccordion
        key={generateReactKey()}
        notes={notes}
        events={updatedEvents}
        onEditClick={onEditNoteClick}
      />
    ),
  };

  function formatDate(date) {
    return format(new Date(date), 'PPp');
  }

  function formatDatesInEvent(event) {
    const eventWithFormattedDate = event;

    eventWithFormattedDate.on = formatDate(eventWithFormattedDate.on);

    if (eventWithFormattedDate.category) {
      eventWithFormattedDate.category.on = formatDate(eventWithFormattedDate.category.on);
    }

    return eventWithFormattedDate;
  }

  const formattedEvents = produce(events, (draft) => {
    draft.map(formatDatesInEvent);
  });

  if (!events || events.length === 0) return null;
  return accordionComponents[events[0].type](formattedEvents);
};

export default TimelineAccordion;
