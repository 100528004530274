import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const dataTableActionLink = ({ to, icon, tooltip }) => (
  <span>
    <Link to={to} tooltip={tooltip}><FontAwesomeIcon icon={icon.class} size={icon.size} className="mr-2" /></Link>
  </span>
);

dataTableActionLink.propTypes = {
  to: PropTypes.string.isRequired,
  icon: PropTypes.shape({
    class: PropTypes.array.isRequired,
    size: PropTypes.string,
  }).isRequired,
  tooltip: PropTypes.string,
};

dataTableActionLink.defaultProps = { tooltip: null };

export default dataTableActionLink;
