import React from 'react';
import {
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';

import { useTranslation } from 'react-i18next';

import CreateCollectionPlaceForm from './CreateCollectionPlaceForm/CreateCollectionPlaceForm';
import { ModalProps } from '~/hooks/useModal';

type Props = ModalProps & {
  refreshCollectionPlaces: () => Promise<void>;
};

const CreateAgentCollectionPlaceModal: React.FC<Props> = ({
  isOpen,
  onToggle,
  refreshCollectionPlaces,
}) => {
  const { t } = useTranslation(['settings']);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => onToggle()}
      centered
      modalTransition={{ timeout: 150 }}
      size="lg"
    >
      <ModalHeader toggle={() => onToggle()}>
        {t('settings:Agents.CreateCollectionPlace.title')}
      </ModalHeader>

      <ModalBody className="pt-0">
        <CreateCollectionPlaceForm
          refreshCollectionPlaces={refreshCollectionPlaces}
        />
      </ModalBody>
    </Modal>
  );
};

export default CreateAgentCollectionPlaceModal;
