import React, { useState } from 'react';
import { useQuery } from 'react-apollo';
import { Spinner } from 'reactstrap';
import { subDays, endOfYesterday, formatISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { APOLLO_ENDPOINT_CONTEXT } from '#/core/services/GraphqlStorage';
import AuthenticationHelper from '~/services/Auth';
import PageBody from '~/App/PageBody/PageBody';
import DateControls from './DateControls/DateControls';
import DashboardBody from './DashboardBody/DashboardBody';
import { DashboardStatistics, QueryDashboardStatisticsArgs } from '~/types/generated/graphql';
import UpsellState, { UpsellStateDescription } from '~/components/UpsellState/UpsellState';

import DASHBOARD_STATS from './dashboardStats.gql';
import AGENTS from './agents.gql';

export interface Agent {
  id: string;
}

type Props = {
  user: any;
};

const Dashboard: React.FC<Props> = () => {
  const { t } = useTranslation('dashboard');

  const user = useSelector((state: any) => state.userReducer.data);
  const subscription = user.company?.subscription;

  const yesterday = endOfYesterday(); // should we get the base date from the server?
  const [dateRange, setDateRange] = useState({ start: subDays(yesterday, 7), end: yesterday });

  const { data: agentData, loading: agentsLoading } = useQuery<{ agents: Agent[] }>(
    AGENTS,
    {
      context: {
        headers: {
          AccessToken: AuthenticationHelper.accessToken,
        },
      },
    },
  );

  const {
    loading,
    data,
    error,
  } = useQuery<{ dashboardStatistics: DashboardStatistics }, QueryDashboardStatisticsArgs>(
    DASHBOARD_STATS,
    {
      variables: {
        // Fallback to yesterday – dateFrom/dateTo cannot be null
        dateFrom: formatISO(
          dateRange.start === null ? yesterday : dateRange.start,
          { representation: 'date' },
        ),
        // Fallback to start date if end date is not specified
        dateTo: formatISO(
          dateRange.end === null ? dateRange.start : dateRange.end,
          { representation: 'date' },
        ),
      },
      context: {
        endpoint: APOLLO_ENDPOINT_CONTEXT,
        headers: {
          Authorization: AuthenticationHelper.accessToken,
        },
      },
    },
  );

  const handleDateRangeChange = (startDate: Date, endDate: Date) => {
    setDateRange({ start: startDate, end: endDate });
  };

  const resetDateRange = () => {
    handleDateRangeChange(subDays(yesterday, 7), yesterday);
  };

  return (
    <PageBody
      title={(
        <span className="d-flex flex-row flex-nowrap align-items-end">
          {t('dashboard:title')}
        </span>
      )}
      extraButtons={(
        subscription.dashboard && (
          <DateControls
            dateRange={dateRange}
            onChange={handleDateRangeChange}
          />
        )
      )}
      wrapperHeight="150px"
      error={error ? t('dashboard:Banner.dataLoadError') : undefined}
    >
      {
        loading || agentsLoading
          ? <div className="d-flex justify-content-center"><Spinner /></div>
          : (
            <div className="position-relative">
              {!subscription.dashboard ? (
                <UpsellState description={UpsellStateDescription.dashboard} backdrop={false} />
              ) : (
                <DashboardBody
                  data={data?.dashboardStatistics}
                  agents={agentData?.agents}
                  resetDateRange={resetDateRange}
                />
              )}
            </div>
          )
      }
    </PageBody>
  );
};

export default Dashboard;
