// :: React
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

// :: Components
import MainBody from 'Components/Presentational/Layout/MainBody/MainBody';
import { Button, Card, CardBody, Container } from 'reactstrap';
import Pagination from 'Components/Presentational/Pagination/Pagination';
import * as actions from 'StoreActions/contact.action';
import { connect } from 'react-redux';
import DataTable from 'Containers/DataTable/DataTable';
import produce from 'immer';
import Modal from 'Containers/ConfirmModal/ConfirmModal';
import { toast } from 'Shared/utilities';

// :: Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Subject from 'Models/subject/SubjectDataSource';
import PaginatorHelper from 'Tools/PaginatorHelper';

class Contacts extends React.Component {
  static formatQueryData(queryData) {
    return queryData.data.subjects.items.map((item) => {
      const currentItem = {};
      // eslint-disable-next-line max-len
      currentItem.contact = <div className="text-truncate font-weight-bold" title={`${item.contact.name} ${item.contact.surname}`}>{`${item.contact.name} ${item.contact.surname}`}</div>;
      currentItem.email = <div className="text-truncate" title={item.contact.email}><a href={`mailto:${item.contact.email}`}>{item.contact.email}</a></div>;
      currentItem.countryCode = item.address.country;
      currentItem.phone = item.contact.phone;
      currentItem.address = (
        <div className="text-truncate" title={`${item.address.street} ${item.address.city} ${item.address.zip}`}>
          {`${item.address.street} ${item.address.city} ${item.address.zip}`}
        </div>
      );
      currentItem.id = item.id;

      return currentItem;
    });
  }

  constructor(props) {
    super(props);

    const { t } = this.props;

    this.subject = new Subject();
    this.handlePaginatorClick = this.handlePaginatorClick.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.actionDelete = this.actionDelete.bind(this);
    this.hideDeleteModal = this.hideDeleteModal.bind(this);
    this.showDeleteModal = this.showDeleteModal.bind(this);

    this.idToDelete = null;

    this.state = {
      data: [],
      modal: false,
      table: {
        columns: [
          {
            title: 'Kontakt',
            index: 'contact',
            key: '1',
          },
          {
            title: 'Email',
            index: 'email',
            key: '2',
          },
          {
            title: 'Stát',
            index: 'countryCode',
            key: '3',
          },
          {
            title: 'Telefon',
            index: 'phone',
            key: '4',
          },
          {
            title: 'Adresa',
            index: 'address',
            key: '5',
          },
        ],
        actions: {
          custom: {
            detail: {
              type: 'link',
              ref: '/contacts/:id',
              icon: { class: ['far', 'eye'] },
              text: t('contacts:ListItemTooltip.contactDetail'),
            },
            /* newConsignment: {
                            type: 'link',
                            ref: '/deliveries/new',
                            icon: 'fas fa-share',
                            text: 'Nová zásilka',
                        }, */
            edit: {
              type: 'link',
              ref: '/contacts/:id/edit',
              icon: { class: ['far', 'edit'] },
              text: t('contacts:ListItemTooltip.editContact'),
            },
            delete: {
              type: 'function',
              func: this.showDeleteModal,
              params: ':id,:email',
              icon: { class: ['fas', 'times'] },
              text: t('contacts:ListItemTooltip.deleteContact'),
            },
          },
          rowClick: {
            type: 'link',
            ref: '/contacts/:id',
          },
        },
        config: {
          hideActionButtons: true,
          striped: false,
          borderless: false,
          className: 'basic contacts fixed',
        },
        isLoaderVisible: false,
      },
    };
  }

  async componentDidMount() {
    await this.sendQueryAndHandleData();
  }

  get totalPages() {
    const {
      contact: {
        table: {
          totalCount: tableTotalCount,
          numberOfRows: tableNumberOfRows,
        },
      },
    } = this.props;

    return PaginatorHelper.computeTotalPages(tableTotalCount, tableNumberOfRows);
  }

  storeData(data) {
    this.setState({ data });
  }

  async sendQueryAndHandleData(store = true, cache = true) {
    const { setTotalCount } = this.props;

    const setLoaderVisibility = (visibility) => produce(this.state, (draft) => {
      draft.table.isLoaderVisible = visibility;
    });

    this.setState(setLoaderVisibility(true));
    const data = await this.sendQuery(cache);
    this.setState(setLoaderVisibility(false));
    setTotalCount(data.data.subjects.totalCount);
    const formattedData = Contacts.formatQueryData(data);

    if (store) {
      return this.storeData(formattedData);
    }
    return formattedData;
  }

  hideDeleteModal() {
    this.setState({ modal: false });
    this.idToDelete = null;
  }

  showDeleteModal(id) {
    this.setState({ modal: true });
    this.idToDelete = id;
  }

  toggleDeleteModal() {
    const { modal } = this.state;
    this.setState({ modal: !modal });
  }

  async actionDelete() {
    const { t } = this.props;
    await this.subject.deleteSubject(this.idToDelete);
    await this.sendQueryAndHandleData(true, false);
    this.hideDeleteModal();
    toast.success(t('contacts:Banner.contactDeleteSuccess'));
  }

  async handlePaginatorClick(page) {
    const { changePage } = this.props;

    await changePage(page);
    await this.sendQueryAndHandleData();
  }

  async handleSearch() {
    const { setSearchKey } = this.props;

    const searchKey = document.querySelector('#searchInput').value;
    await setSearchKey(searchKey);
    await this.sendQueryAndHandleData();
  }

  async handleSort(e) {
    const {
      setOrderBy,
      setOrderByDirection,
    } = this.props;

    const orderBy = e.target.getAttribute('data-orderby');
    const direction = e.target.getAttribute('data-direction');
    await setOrderBy(orderBy);
    await setOrderByDirection(direction);
    await this.sendQueryAndHandleData();
  }

  async sendQuery(cache) {
    const {
      contact: {
        paginator: { page },
        search: { searchKey },
        table: {
          numberOfRows,
          orderBy,
          orderByDirection,
        },
      },
    } = this.props;

    return this.subject.getSubjects(
      searchKey, orderBy, orderByDirection, (page - 1) * numberOfRows, numberOfRows, cache,
    );
  }

  render() {
    const { t } = this.props;

    const extraButtons = (
      <Link to="/contacts/new">
        <Button color="primary">
          <FontAwesomeIcon icon={['fas', 'plus']} fixedWidth />
                    &nbsp;
          {t('contacts:newContactButton')}
        </Button>
      </Link>
    );

    const {
      data,
      table: {
        columns: tableColumns,
        actions: tableActions,
        config: tableConfig,
        isLoaderVisible,
      },
      modal,
    } = this.state;

    let content = (
      <div className="contacts">
        {/* <ul>
                    <li>
                        Řadit dle jména:
                        <FontAwesomeIcon icon={["fas", "arrow-up"]}
                        data-direction="ASC" data-orderby="name"
                        onClick={this.handleSort}/>&nbsp;
                        <FontAwesomeIcon icon={["fas", "arrow-down"]}
                        data-direction="DESC" data-orderby="name"
                        onClick={this.handleSort}/>
                    </li>
                    <li>
                        Řadit dle příjmení:
                        <FontAwesomeIcon icon={["fas", "arrow-up"]}
                        data-direction="ASC" data-orderby="surname"
                        onClick={this.handleSort}/>&nbsp;
                        <FontAwesomeIcon icon={["fas", "arrow-down"]}
                        data-direction="DESC" data-orderby="surname"
                        onClick={this.handleSort}/>
                    </li>
                    <li>
                        Řadit dle emailu:
                        <FontAwesomeIcon icon={["fas", "arrow-up"]}
                        data-direction="ASC" data-orderby="email" onClick={this.handleSort}/>&nbsp;
                        <FontAwesomeIcon icon={["fas", "arrow-down"]}
                        data-direction="DESC" data-orderby="email" onClick={this.handleSort}/>
                    </li>
                    <li>
                        Řadit dle telefonu:
                        <FontAwesomeIcon icon={["fas", "arrow-up"]}
                        data-direction="ASC" data-orderby="phone" onClick={this.handleSort}/>&nbsp;
                        <FontAwesomeIcon icon={["fas", "arrow-down"]}
                        data-direction="DESC" data-orderby="phone" onClick={this.handleSort}/>
                    </li>
                </ul>
                  <InputGroup>
                    <Input id="searchInput" placeholder="Fráze na vyhledávání ..."/>
                    <Button onClick={this.handleSearch}>Vyhledat</Button>
                </InputGroup>
                */}
        <DataTable
          data={data}
          columns={tableColumns}
          actions={tableActions}
          config={tableConfig}
          isLoaderVisible={isLoaderVisible}
        />
        <Pagination
          onPageChanged={this.handlePaginatorClick}
          totalPages={this.totalPages || 5}
        />
      </div>
    );

    if (data === null) {
      content = (
        <Container className="text-center">
          <Card>
            <CardBody>
              <h4 className="pb-3">
                <FontAwesomeIcon icon={['fas', 'plus-circle']} size="3x" className="text-muted" />
                <div className="pt-4">
                  {t('contacts:NoContacts.title')}
                </div>
              </h4>
              <p className="pb-4">
                {t('contacts:NoContacts.description')}
              </p>
              <Link to="/contacts/new">
                <Button size="lg" outline color="secondary">
                  {t('contacts:NoContacts.createContactButton')}
                </Button>
              </Link>
            </CardBody>
          </Card>
        </Container>
      );
    }

    return (
      <MainBody wrapperHeight="150px" title={t('contacts:title')} extraButtons={extraButtons}>
        {content}
        <Modal
          onCancel={this.hideDeleteModal}
          onPrompt={this.actionDelete}
          toggle={this.toggleDeleteModal}
          isOpen={modal}
          body={t('contacts:DeleteContactModal.description')}
          buttons={{
            prompt: {
              text: t('contacts:DeleteContactModal.submitButton'),
              color: 'primary',
            },
            cancel: {
              text: t('contacts:DeleteContactModal.cancelButton'),
              color: 'secondary',
            },
          }}
          title={t('contacts:DeleteContactModal.title')}
        />
      </MainBody>
    );
  }
}

Contacts.propTypes = {
  setOrderBy: PropTypes.func.isRequired,
  setOrderByDirection: PropTypes.func.isRequired,
  changePage: PropTypes.func.isRequired,
  setSearchKey: PropTypes.func.isRequired,
  setTotalCount: PropTypes.func.isRequired,
  contact: PropTypes.shape({
    table: PropTypes.shape({
      numberOfRows: PropTypes.number,
      orderBy: PropTypes.string,
      orderByDirection: PropTypes.string,
    }).isRequired,
    search: PropTypes.shape({ searchKey: PropTypes.string }),
    paginator: PropTypes.shape({ page: PropTypes.number }),
  }).isRequired,
};

const mapStateToProps = (state) => ({ contact: state.contactReducer });

const mapDispatchToProps = (dispatch) => ({
  changePage: (page) => dispatch(actions.changePage(page)),
  setNumberOfRows: (rows) => dispatch(actions.setNumberOfRows(rows)),
  setSearchKey: (searchKey) => dispatch(actions.setSearchKey(searchKey)),
  setOrderBy: (orderBy) => dispatch(actions.setOrderBy(orderBy)),
  setOrderByDirection: (direction) => dispatch(actions.setOrderByDirection(direction)),
  setTotalCount: (totalCount) => dispatch(actions.setTotalCount(totalCount)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['contacts'])(Contacts));
