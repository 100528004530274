import React from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { useTranslation } from 'react-i18next';

interface Delivery {
  recipient: {
    pickUpPlace: {
      name: Nullable<string>;
      address: {
        country: Nullable<string>;
        street: Nullable<string>;
        city: Nullable<string>;
        zip: Nullable<string>;
      }
    }
  }
}

type Props = {
  delivery: Delivery;
}

const PickupPoint: React.FC<Props> = ({ delivery }) => {
  const { t } = useTranslation('deliveries');

  const { pickUpPlace } = delivery.recipient;

  return (
    <Card className="mb-4">
      <CardBody>
        <CardTitle className="text-dark font-weight-bold">
          {t('deliveries:DeliveryDetail.pickupPointTitle')}
        </CardTitle>

        <p className="mb-0">
          <span className="d-block text-dark">
            {pickUpPlace.name}
          </span>
          <span className="d-block">
            {pickUpPlace.address.street}
            ,
          </span>
          <span className="d-block">
            {pickUpPlace.address.zip}
            {' '}
            {pickUpPlace.address.city}
          </span>
          <span className="d-block">
            {pickUpPlace.address.country}
          </span>
        </p>
      </CardBody>
    </Card>
  );
};

export default PickupPoint;
