import gql from 'graphql-tag';

export default class EshopNotificationFragments {
  static deliveryStatusNotificationItem() {
    return gql`
      fragment DeliveryStatusNotificationItem on EshopNotificationCenterItem {
        name
        code
        path
        initialSettings {
          important
          email
          sms
        }
        userSettings {
          important
          email
          sms
        }
      }
    `;
  }

  static deliveryStatusNotifications() {
    return gql`
      fragment DeliveryStatusNotifications on EshopNotificationCenter {
        settings {
          ...DeliveryStatusNotificationItem
          items {
            ...DeliveryStatusNotificationItem
            items {
              ...DeliveryStatusNotificationItem
              items {
                ...DeliveryStatusNotificationItem
              }
            }
          }
        }
      }
      ${EshopNotificationFragments.deliveryStatusNotificationItem()}
    `;
  }

  static updateDeliveryStatusNotifications() {
    return gql`
      fragment UpdateDeliveryStatusNotifications on EshopNotificationCenter {
        id
      }
    `;
  }
}
