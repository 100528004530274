import React from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import type { Scope } from '../../IAuthorize';
import CompanyLogoCard from '../CompanyLogoCard/CompanyLogoCard';
import RedirectUriCard from '../RedirectUriCard/RedirectUriCard';
import ScopeList from '../ScopeList/ScopeList';

type Props = {
  scopes: Scope[],
  companyName: string,
  redirectUri: string,
  companyLogoUrl?: Nullable<string>,
  authorizeOAuthClient: () => any,
}

const AuthorizeForm: React.FC<Props> = ({
  scopes,
  redirectUri,
  companyName,
  companyLogoUrl,
  authorizeOAuthClient,
}) => {
  const denyAccessLink = redirectUri
    ? `${redirectUri}?error=access_denied&error_description=The+user+denied+access+to+your+application&state=`
    : null;

  return (
    <form>
      <div className="container">
        <div className="form-row">
          <div className="col-xl-2">
            {companyLogoUrl && (
              <CompanyLogoCard logoUrl={companyLogoUrl} />
            )}
          </div>
          <div className="col">
            <div className="card">
              <div className="card-body">
                <ScopeList
                  companyName={companyName}
                  scopes={scopes}
                />
                <ButtonGroup>
                  <Button
                    color="primary"
                    type="button"
                    onClick={authorizeOAuthClient}
                  >
                    Povolit
                  </Button>
                  <Button
                    color="secondary"
                    type="button"
                    href={denyAccessLink}
                    target="_blank"
                  >
                    Zamítnout
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="col-xl-2" />
          <div className="col">
            <RedirectUriCard redirectUri={redirectUri} />
          </div>
        </div>
      </div>
    </form>
  );
};

export default AuthorizeForm;
