import React from 'react';
import { NavLink } from 'react-router-dom';
import { Badge, NavItem } from 'reactstrap';
import { matchPath } from 'react-router';

import Icon from '#/Components/Presentational/Icon/Icon';

import './SidebarNavItem.scss';

type Props = {
  item: any;
};

const SidebarNavItem: React.FC<Props> = ({ item }) => {
  const classNames = ['nav-link'];

  if (item.children !== undefined) {
    item.children.forEach((child: any) => {
      const match = matchPath('/batches', { path: child.path });
      if (match && !classNames.includes('child-active')) {
        classNames.push('child-active');
      }
    });
  }

  return (
    <NavItem className="nav-item-sidebar">
      <NavLink to={item.path} className={classNames.join(' ')} exact={item.exact} data-test={item.dataTestId}>
        <Icon
          type={item.icon.type}
          icon={item.icon.icon}
          className={item.icon.className}
          size={item.icon.size}
          style={item.icon.style}
        />
        {' '}
        <div className="nav-item-sidebar-tooltip">
          {item.name}

          {item.tag && (
            <Badge pill className="nav-item-sidebar-badge ml-1">
              {item.tag}
            </Badge>
          )}
        </div>
      </NavLink>
    </NavItem>
  );
};

export default SidebarNavItem;
