import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Icon from 'Components/Presentational/Icon/Icon';
import DataTableTextWithSubheading from './DataTableTextWithSubheading';

const DeliveryStatus = ({ number, important, status, className }) => {
  const { t } = useTranslation(['deliveries']);

  return (
    <DataTableTextWithSubheading
      className={`status ${className}`}
      heading={number || t('deliveries:deliveryNumberUnassigned')}
      subheading={status}
      icon={important && (
        <Icon
          type="foxdeli"
          icon="warning_bigger"
          className="warning"
          size="1_2"
        />
      )}
    />
  );
};

DeliveryStatus.propTypes = {
  status: PropTypes.string.isRequired,
  number: PropTypes.string,
  important: PropTypes.bool,
  className: PropTypes.string,
};

DeliveryStatus.defaultProps = {
  number: '',
  important: false,
  className: '',
};

export default DeliveryStatus;
