import getImageIdFromRootObject from 'Containers/Settings/Branding/helpers/getImageIdFromRootObject';
import GET_ESHOP_BRANDING_DATA from 'Containers/Settings/Branding/queries/getEshopBrandingData.gql';
import TT_ACTIVATE from 'Containers/Settings/Branding/TrackAndTrace/queries/activateTrackAndTrace.gql';
import { EshopBrandingDataQuery } from 'Containers/Settings/Branding/typesAndDefaults';
import { FormikHelpers } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams as useRouterParams } from 'react-router';
import StatusBanner from '~/components/StatusBanner/StatusBanner';
import { filterObjectEmptyProperties } from '../helpers/filterObjectEmptyProperties';
import FoxLoader from '~/components/FoxLoader/FoxLoader';
import { uploadImage } from '~/helpers/imageHelper';
import { convertTrackAndTraceConfigToInput, initialTTValues, MutationTrackAndTraceConfig, MutationTTActivate, TrackAndTraceValues } from './typesAndDefaults';
import { toast } from '#/shared/utilities';
import useAuthMutation from '~/hooks/useAuthMutation';
import useAuthQuery from '~/hooks/useAuthQuery';
import FETCH_TT_CONFIG from './queries/fetchTrackAndTrace.gql';
import TrackAndTraceForm from './TrackAndTraceForm/TrackAndTraceForm';
import UPDATE_TRACK_AND_TRACE_CONFIG from './queries/updateTrackAndTrace.gql';

const EditTrackAndTrace: React.FC = () => {
  const { t } = useTranslation(['settings']);
  const { id: routeTrackAndTraceConfigId = '' } = useRouterParams<{ id: string }>();
  const [formIsSubmitting, setFormIsSubmitting] = React.useState(false);

  const {
    data: TTConfigDataQuery, loading: TTConfigLoadingQuery,
    refetch: TTConfigRefetchQuery,
  } = useAuthQuery<{eshopTrackAndTraceConfigById: TrackAndTraceValues}, { id: string }>(FETCH_TT_CONFIG, { id: routeTrackAndTraceConfigId });
  const { data: eshopQueryData, refetch: refetchEshopQueryData } = useAuthQuery<{ eshop: EshopBrandingDataQuery }, never>(GET_ESHOP_BRANDING_DATA);

  const [updateTrackAndTraceConfigMutation] = useAuthMutation<TrackAndTraceValues, MutationTrackAndTraceConfig>(UPDATE_TRACK_AND_TRACE_CONFIG);
  const [activateTrackAndTraceConfigMutation] = useAuthMutation<MutationTTActivate, { id: string }>(TT_ACTIVATE);

  const trackAndTraceFetchedConfig = TTConfigDataQuery?.eshopTrackAndTraceConfigById || initialTTValues;
  trackAndTraceFetchedConfig.logoImg = getImageIdFromRootObject(trackAndTraceFetchedConfig.logo);
  trackAndTraceFetchedConfig.faviconImg = getImageIdFromRootObject(trackAndTraceFetchedConfig.favicon);
  trackAndTraceFetchedConfig.desktopBackground = getImageIdFromRootObject(trackAndTraceFetchedConfig.desktopBackgroundImage);
  trackAndTraceFetchedConfig.mobileBackground = getImageIdFromRootObject(trackAndTraceFetchedConfig.mobileBackgroundImage);

  const submitTrackAndTraceConfig = async (
    trackAndTraceValues: TrackAndTraceValues,
    formikHelpers: FormikHelpers<TrackAndTraceValues>,
    onSubmitCallback: () => void,
  ) => {
    setFormIsSubmitting(true);

    try {
      const [logoUploadData, faviconUploadData, desktopBgUploadData, mobileBgUploadData] = await Promise.all([
        uploadImage(trackAndTraceValues.logoImg),
        uploadImage(trackAndTraceValues.faviconImg),
        uploadImage(trackAndTraceValues.desktopBackground),
        uploadImage(trackAndTraceValues.mobileBackground),
      ]);

      await updateTrackAndTraceConfigMutation({
        variables: {
          id: routeTrackAndTraceConfigId,
          config: {
            ...convertTrackAndTraceConfigToInput(trackAndTraceValues),
            ...filterObjectEmptyProperties({
              logoId: logoUploadData?.data.imageId || trackAndTraceValues.logo?.id,
              faviconId: faviconUploadData?.data.imageId || trackAndTraceValues.favicon?.id,
              desktopBackgroundImageId: desktopBgUploadData?.data.imageId || trackAndTraceValues.desktopBackgroundImage?.id,
              mobileBackgroundImageId: mobileBgUploadData?.data.imageId || trackAndTraceValues.mobileBackgroundImage?.id,
            }),
          },
        },
      });

      if (trackAndTraceValues.activateAfterSave) {
        formikHelpers.setFieldValue('activateAfterSave', false);
        await activateTrackAndTraceConfigMutation({ variables: { id: routeTrackAndTraceConfigId } });
        toast.success(t('settings:Branding.TrackAndTrace.Message.templateUpdatedAndActivated'));
      } else {
        toast.success(t('settings:Branding.TrackAndTrace.Message.templateUpdated'));
      }

      await TTConfigRefetchQuery();
      await refetchEshopQueryData();
      formikHelpers.setSubmitting(false);
      setFormIsSubmitting(false);
    } catch (e) {
      // Handled in global interceptor
      formikHelpers.setSubmitting(false);
      setFormIsSubmitting(false);
    }

    onSubmitCallback();
  };

  return (
    <>
      {eshopQueryData?.eshop?.activeTrackAndTraceConfig?.id === routeTrackAndTraceConfigId ? (
        <>
          <h1 className="mb-4">{ t('settings:Branding.TrackAndTrace.editTrackAndTraceTitle') }</h1>
          <StatusBanner className="mb-4" status title={{ active: t('settings:Branding.TrackAndTrace.StatusBanner.titleActiveEditDetail'), inactive: '' }} />
        </>
      ) : (
        <h1 className="mb-5">{t('settings:Branding.TrackAndTrace.editTrackAndTraceTitle')}</h1>
      )}
      {TTConfigLoadingQuery ? (<FoxLoader withOverlay={false} />) : (
        <TrackAndTraceForm
          onSubmit={(values, formikHelpers, onSubmitCallback) => submitTrackAndTraceConfig(values, formikHelpers, onSubmitCallback)}
          initialValues={trackAndTraceFetchedConfig}
        />
      )}
      { formIsSubmitting && <FoxLoader loaderText={t('settings:Branding.TrackAndTrace.trackAndTraceFormUpdatingText')} /> }
    </>
  );
};

export default EditTrackAndTrace;
