// :: React
import React from 'react';
import PropTypes from 'prop-types';

const Basic = (props) => {
  const { children } = props;

  return (
    <div id="layout">
      <div className="wrapper">
        <div id="content" className="logged--out">
          {children}
        </div>
      </div>
    </div>
  );
};

Basic.propTypes = { children: PropTypes.element };
Basic.defaultProps = { children: null };

export default Basic;
