import { Notifier } from '@airbrake/browser';

import configurator from '~/services/Configurator';

class Airbrake {
  public airbrake: Notifier | undefined;

  constructor() {
    this.airbrake = undefined;
  }

  init() {
    const { host, projectId, projectKey, environment } = configurator.config.airbrake || {};

    if (!projectId || !projectKey) {
      return;
    }

    this.airbrake = new Notifier({
      host,
      projectId,
      projectKey,
      environment: environment || configurator.env,
    });
  }

  get client() {
    return this.airbrake;
  }
}

export default new Airbrake();
