import React from 'react';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';

import PageBodyError from '~/App/PageBody/PageBodyError/PageBodyError';
import Topbar from '~/App/Topbar/Topbar';

import css from './PageBody.module.scss';

type Props = {
  title: React.ReactNode;
  extraButtons: React.ReactNode;
  wrapperHeight: string;
  user: any;
  className?: string;
  error?: string
}

const PageBody: React.FC<Props> = ({
  title,
  extraButtons,
  wrapperHeight,
  user,
  className = '',
  children,
  error,
}) => {
  return (
    <div className={className}>
      <div className="fixed-top d-flex flex-column justify-content-between">
        <Topbar
          className="_legacy"
          user={{
            name: user.data.name,
            surname: user.data.surname,
          }}
        />

        <div className={`${css.mainTitle} _ds container pb-3 px-3 d-flex justify-content-between align-items-end`}>
          <h2 className="headline mb-0">
            {title}
          </h2>

          <div className="buttons">
            {extraButtons}
          </div>
        </div>
      </div>

      <div
        className="_ds content-wrapper"
        style={{ marginTop: wrapperHeight }}
      >
        <Container>
          {error
            ? <PageBodyError message={error} />
            : children}
        </Container>
      </div>
    </div>
  );
};

function mapStateToProps(state: any) {
  return {
    user: state.userReducer,
  };
}

// TODO: fix `any` generics
export default connect<any, any, any>(mapStateToProps)(PageBody);
