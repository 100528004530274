// :: React
import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

// :: Components
import MainBody from 'Components/Presentational/Layout/MainBody/MainBody';
import { Col, Row, Card, CardBody, Button } from 'reactstrap';

import Modal from 'Containers/ConfirmModal/ConfirmModal';
import { toast } from 'Shared/utilities';

// :: Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SVGIcon from 'Components/Presentational/SVGIcon/SVGIcon';
import Loader from 'Containers/Loader/Loader';
import PropTypes from 'prop-types';
import SubjectDataSource from '../../../../core/models/subject/SubjectDataSource';
import { CountryManagerContext } from '../../CountryManager/CountryManager';

class ContactsDetail extends React.Component {
  constructor(props) {
    super(props);

    this.subject = new SubjectDataSource();
    this.formatAndStore = this.formatAndStore.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.actionDelete = this.actionDelete.bind(this);
    this.state = {
      modalIsOpen: false,
      subject: null,
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.sendQueryAndUpdateStore();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  sendQueryAndUpdateStore() {
    const { match: { params: { id } } } = this.props;

    this.subject
      .selectById(id)
      .then(this.formatAndStore)
      .catch(console.warn);
  }

  formatAndStore(response) {
    if (!this.mounted) return;

    const formattedData = {
      ...response.data.subjectById.contact,
      ...response.data.subjectById.address,
    };

    const { match: { params: { id } } } = this.props;

    this.setState({
      subjectId: id,
      subject: formattedData,
    });
  }

  toggleDeleteModal() {
    const { modalIsOpen } = this.state;

    this.setState({ modalIsOpen: !modalIsOpen });
  }

  async actionDelete() {
    const { t } = this.props;
    const { match: { params: { id: subjectId } } } = this.props;
    const { history } = this.props;

    await this.subject.deleteSubject(subjectId);
    history.push('/contacts');
    toast.success(t('contacts:Banner.contactDeleteSuccess'));
  }

  render() {
    const { t } = this.props;

    const { subjectId, subject, modalIsOpen } = this.state;

    let content = (
      <Loader />
    );

    const extraButtons = (
      <>
        <Link to={`/contacts/${subjectId}/edit`}>
          <Button color="primary" className="mr-3">
            {t('contacts:ContactDetail.editContactButton')}
          </Button>
        </Link>
        <Button
          outline
          color="primary"
          onClick={this.toggleDeleteModal}
        >
          {t('contacts:ContactDetail.deleteContactButton')}
        </Button>
      </>
    );

    let title = null;

    if (this.state && subject !== null) {
      const { subject: { street, zip, city, country, name, surname, email, phone } } = this.state;
      const { i18n } = this.props;

      title = (
        <>
          <Link to="/contacts">
            <div className="page-back-btn-overlay">
              <FontAwesomeIcon
                icon={['fas', 'arrow-alt-circle-left']}
                className="text-muted page-back-btn"
              />
            </div>
          </Link>
          {`${name} ${surname}`}
        </>
      );

      content = (
        <CountryManagerContext.Consumer>
          {(countryManager) => (
            <MainBody
              title={title}
              wrapperHeight="150px"
              extraButtons={extraButtons}
            >
              <Card>
                <CardBody>
                  <Row className="d-flex align-items-center">
                    <Col md="4">
                      <Row className="d-flex align-items-center">
                        <Col
                          md="4"
                          className="d-flex justify-content-end"
                        >
                          <SVGIcon icon="address" />
                        </Col>
                        <Col md="8">
                          <div>{street}</div>
                          <div>
                            {zip}
                            {' '}
                            {city}
                          </div>
                          <div>
                            {countryManager.getCountryNameByCode(country, i18n.language)}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col md="4">
                      <Row className="d-flex align-items-center">
                        <Col
                          md="4"
                          className="d-flex justify-content-end"
                        >
                          <SVGIcon icon="email" />
                        </Col>
                        <Col md="8">
                          <div><a href={`mailto:${email}`}>{email}</a></div>
                        </Col>
                      </Row>
                    </Col>
                    <Col md="4">
                      <Row className="d-flex align-items-center">
                        <Col
                          md="4"
                          className="d-flex justify-content-end"
                        >
                          <SVGIcon icon="phone" />
                        </Col>
                        <Col md="8">
                          <div>{phone}</div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </MainBody>
          )}
        </CountryManagerContext.Consumer>
      );
    }

    return (
      <div>
        {content}
        <Modal
          onCancel={this.toggleDeleteModal}
          onPrompt={this.actionDelete}
          toggle={this.toggleDeleteModal}
          isOpen={modalIsOpen}
          body={t('contacts:DeleteContactModal.description')}
          buttons={{
            prompt: {
              text: t('contacts:DeleteContactModal.submitButton'),
              color: 'primary',
            },
            cancel: {
              text: t('contacts:DeleteContactModal.cancelButton'),
              color: 'secondary',
            },
          }}
          title={t('contacts:DeleteContactModal.title')}
        />
      </div>
    );
  }
}

export default withTranslation('contacts')(ContactsDetail);

ContactsDetail.propTypes = {
  match: PropTypes.shape({ params: PropTypes.shape({ id: PropTypes.string }) }).isRequired,
  history: PropTypes.shape({ elements: PropTypes.any }).isRequired,
};
