import React from 'react';

import { customManualMutation } from 'Models/Components/CustomMutation';
import { toast } from 'Shared/utilities';
import DeliveryQueryFactory from 'Models/delivery/Queries/Factories/DeliveryQueryFactory';
import ApolloQueryTypes from 'Types/apollo/ApolloQueryTypes';
import LocalDeliveryTypes from '../../LocalDeliveryTypes/LocalDeliveryTypes';
import CancelDeliveryUI from '../../Presentational/CancelDelivery/CancelDelivery';

export default class CancelDelivery extends React.Component {
  async componentDidUpdate(prevProps) {
    const { modalState: prevModalState } = prevProps;
    const { modalState, modalService } = this.props;

    if (prevModalState.shown && modalState.shown) {
      if (prevModalState.shown === 'waitingForUserInput' && modalState.shown === 'confirmed') {
        await this.cancelDelivery();
      }

      modalService.send('CANCEL_DELIVERY_MODAL_HIDDEN');
    }
  }

  showModal = () => {
    const { modalService } = this.props;
    modalService.send('CANCEL_DELIVERY_MODAL_SHOWN');
  };

  cancelDelivery = async () => {
    const { deliveryId, refetchDeliveryDetail, mutationService, client } = this.props;

    mutationService.send('ACTION_MUTATION_DISPATCHED');

    try {
      await customManualMutation({
        client,
        mutation: DeliveryQueryFactory.cancelDeliveries('id', ''),
        variables: { deliveriesIds: [deliveryId] },
      });
      toast.success('Zásilka stornována.');
      mutationService.send('ACTION_MUTATION_RESPONSE_RECEIVED');
      refetchDeliveryDetail();
    } catch (e) {
      mutationService.send('ACTION_MUTATION_RESPONSE_RECEIVED');
    }
  };

  render() {
    const { canCancel } = this.props;

    return (
      <CancelDeliveryUI
        canCancel={canCancel}
        onCancelClick={this.showModal}
      />
    );
  }
}

CancelDelivery.propTypes = {
  client: ApolloQueryTypes.client().isRequired,
  deliveryId: LocalDeliveryTypes.every().deliveryId.isRequired,
  refetchDeliveryDetail: LocalDeliveryTypes.every().refetchDeliveryDetail.isRequired,
  mutationService: LocalDeliveryTypes.every().mutationService.isRequired,
  modalService: LocalDeliveryTypes.every().modalService.isRequired,
  modalState: LocalDeliveryTypes.every().modalState.isRequired,
  canCancel: LocalDeliveryTypes.every().canCancel.isRequired,
};
