import React from 'react';
import {
  Card,
  CardBody,
  Col,
  Row,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { CountryManagerContext } from '#/Components/Containers/CountryManager/CountryManager';
import { stringifyNull } from '#/shared/utilities';
import useAuthQuery from '~/hooks/useAuthQuery';

import collectionPlaceQuery from './collectionPlace.gql';

interface Delivery {
  sender: {
    collectionPlace: Nullable<{
      id: string;
      companyNameForTickets: string;
    }>;
    contact: {
      name: string;
      surname: string;
      phone: string;
      email: string;
      companyName: Nullable<string>;
    },
    address: {
      street: string;
      city: string;
      country: string;
      zip: string;
    },
  },
  recipient: {
    contact: {
      name: string;
      surname: string;
      phone: string;
      email: string;
      companyName: Nullable<string>;
    },
    address: {
      street: string;
      city: string;
      country: string;
      zip: string;
    },
  },
  type: {
    agent: {
      id: string;
    }
  };
}

type Props = {
  delivery: Delivery;
};

const TrackStatus: React.FC<Props> = ({ delivery }) => {
  const { i18n } = useTranslation();
  const {
    sender: {
      contact: {
        name: senderName,
        surname: senderSurname,
        phone: senderPhone,
        email: senderEmail,
      },
      address: {
        street: senderStreet,
        city: senderCity,
        country: senderCountry,
        zip: senderZip,
      },
      collectionPlace,
    },
    recipient: {
      contact: {
        name: recipientName,
        surname: recipientSurname,
        phone: recipientPhone,
        email: recipientEmail,
        companyName: recipientCompanyName,
      },
      address: {
        street: recipientStreet,
        city: recipientCity,
        country: recipientCountry,
        zip: recipientZip,
      },
    },
    type: { agent: { id: agentId } },
  } = delivery;

  const { data: collectionPlaceData } = useAuthQuery(
    collectionPlaceQuery,
    {
      id: collectionPlace?.id,
      agentId,
    },
    {
      errorPolicy: 'all',
      skip: !collectionPlace?.id,
    },
  );

  const collectionPlaceType = collectionPlaceData?.collectionPlaceById?.collectionPlaceTypeByAgent;

  const senderCompanyName = collectionPlaceType?.companyNameForTickets
    ?? collectionPlace?.companyNameForTickets
    ?? delivery.sender.contact?.companyName;

  return (
    <CountryManagerContext.Consumer>
      {(countryManager) => (
        <Row>
          <Col>
            <Card>
              <CardBody className="text-secondary">
                <h6 className="text-dark">
                  {senderCompanyName && (
                    <>
                      <b>{senderCompanyName}</b>
                      <br />
                    </>
                  )}
                  <b>{`${stringifyNull(senderName)} ${stringifyNull(senderSurname)}`}</b>
                </h6>
                {senderStreet}
                <br />
                {senderZip}
                {' '}
                {senderCity}
                ,
                <br />
                {countryManager.getCountryNameByCode(senderCountry, i18n.language)}
                <br />
                {senderPhone || <span className="text-muted">Neuvedeno</span>}
                <br />
                {senderEmail ? <a href={`mailto:${senderEmail}`}>{senderEmail}</a>
                  : <span className="text-muted">Neuvedeno</span>}
              </CardBody>
            </Card>
          </Col>
          <Col
            md={1}
            className="d-flex justify-content-center align-self-center"
          >
            <svg
              width="18px"
              height="13px"
              viewBox="0 0 18 13"
              version="1.1"
            >
              <g
                id="Page-1"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g
                  id="Sledování-zásilky"
                  transform="translate(-951.000000, -265.000000)"
                >
                  <g
                    id="Content"
                    transform="translate(310.000000, 80.000000)"
                  >
                    <g
                      id="Arrow"
                      transform="translate(650.000000, 191.500000) rotate(-180.000000) translate(-650.000000, -191.500000) translate(641.000000, 185.000000)"
                    >
                      <path
                        d="M1.62,6.36222222 L17.865,6.36222222"
                        id="Shape"
                        stroke="#949495"
                        strokeWidth="1.5"
                      />
                      <polygon
                        id="Shape"
                        fill="#949495"
                        fillRule="nonzero"
                        points="6.6 12.7244444 7.62 11.5888889 2.205 6.36222222 7.62 1.13555556 6.6 0 0 6.36222222"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </Col>
          <Col>
            <Card>
              <CardBody className="text-secondary">
                <h6 className="text-dark">
                  {recipientCompanyName && (
                    <>
                      <b>{recipientCompanyName}</b>
                      <br />
                    </>
                  )}
                  <b>{`${stringifyNull(recipientName)} ${stringifyNull(recipientSurname)}`}</b>
                </h6>
                {recipientStreet}
                <br />
                {recipientZip}
                {' '}
                {recipientCity}
                ,
                <br />
                {countryManager.getCountryNameByCode(recipientCountry, i18n.language)}
                <br />
                {recipientPhone || <span className="text-muted">Neuvedeno</span>}
                <br />
                {recipientEmail ? <a href={`mailto:${recipientEmail}`}>{recipientEmail}</a>
                  : <span className="text-muted">Neuvedeno</span>}
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </CountryManagerContext.Consumer>
  );
};

export default TrackStatus;
