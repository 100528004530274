import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import { useLocation } from 'react-router-dom';

import Topbar from '~/App/Topbar/Topbar';
import Alert from '~/components/Alert/Alert';
import EmailVerificationButton from '~/components/EmailVerificationButton/EmailVerificationButton';
import TrialAlert from '~/components/TrialAlert/TrialAlert';
import WelcomeModal from '~/components/WelcomeModal/WelcomeModal';
import GlobalBanners from '~/components/GlobalBanners/GlobalBanners';

import css from './MainBody.module.scss';

type Props = {
  title: React.ReactNode;
  extraButtons: React.ReactNode;
  bottomHeaderContent?: any;
  wrapperHeight: any;
  userState: any;
  className: any;
  legacy: boolean;
}

const MainBody: React.FC<Props> = ({
  title,
  extraButtons,
  bottomHeaderContent,
  wrapperHeight,
  userState,
  className = '',
  children,
  legacy = true,
}) => {
  const location = useLocation();
  const helperClassByLocationName = location.pathname.substring(1).replaceAll('/', '-');
  const cls = legacy ? '_legacy' : '_ds';

  const user = userState.data;
  const { company } = user;
  const subscription = company?.subscription;
  const subscriptionType = subscription?.subscriptionType;

  const shouldSeeWelcomeModal = useMemo(() => {
    return subscriptionType?.systemName === 'trial' && user.businessClass !== 'enterprise' && !user.hasSetCredentials;
  }, [subscriptionType, user.businessClass, user.hasSetCredentials]);

  const shouldSeeTrialAlert = useMemo(() => {
    return subscriptionType?.systemName === 'trial' && subscription?.validTo;
  }, [subscriptionType, subscription]);

  return (
    <div className={`${className} ${helperClassByLocationName}`.trim()}>
      <div className="fixed-top d-flex flex-column justify-content-between">
        <Topbar
          className="_legacy"
          user={{
            name: user.name,
            surname: user.surname,
          }}
        />

        <div className={`${css.mainTitle} ${cls} container pb-3 px-3 d-flex justify-content-between align-items-end`}>
          <h2 className="headline mb-0">{title}</h2>
          <div className="buttons">{extraButtons}</div>
        </div>

        {bottomHeaderContent}
      </div>

      <div className={`${cls} content-wrapper`} style={{ marginTop: wrapperHeight }}>
        <Container>
          {/* TODO: Move to GlobalAlerts */}
          {!company.emailConfirmed && (
            <Alert type="warning" className="mb-3">
              <p className="mb-0">
                Prosíme o potvrzení Vašeho fakturačního e-mailu
                <span className="font-weight-bold">
                  {' '}
                  {company?.email}
                </span>
              </p>
              <EmailVerificationButton>
                Zaslat autorizační link
              </EmailVerificationButton>
            </Alert>
          )}

          {shouldSeeTrialAlert && <TrialAlert validTo={subscription.validTo} />}
          {shouldSeeWelcomeModal && <WelcomeModal />}

          <GlobalBanners
            userId={user.id}
            eshopPlatform={user?.eshopPlatform}
            hasCollectionPlace={user?.hasCollectionPlace}
            hasSetCredentials={user?.hasSetCredentials}
            hasDeliveriesFromApi={user?.hasDeliveriesFromApi}
            hasActiveTrackAndTrace={user?.hasActiveTrackAndTrace}
            hasActiveMailing={user?.hasActiveMailing}
            hasBrandingFeature={subscription?.branding}
          />

          {children}
        </Container>
      </div>
    </div>
  );
};

function mapStateToProps(state: any) {
  return {
    userState: state.userReducer,
  };
}

// TODO: fix `any` generics
export default connect<any, any, any>(mapStateToProps)(MainBody);
