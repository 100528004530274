import React from 'react';
import { DropdownItem } from 'reactstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import LocalDeliveryTypes from '../../LocalDeliveryTypes/LocalDeliveryTypes';

const CancelDelivery = ({ onCancelClick, canCancel }) => {
  const { t } = useTranslation('deliveries');

  return (
    <DropdownItem disabled={!canCancel} onClick={onCancelClick}>
      <FontAwesomeIcon icon={['fas', 'times']} fixedWidth />
      {t('DeliveryDetail.Menu.cancelDelivery')}
    </DropdownItem>
  );
};

CancelDelivery.defaultProps = {
  onCancelClick: () => null,
  canCancel: true,
};

CancelDelivery.propTypes = {
  // eslint-disable-next-line react/no-typos
  canCancel: LocalDeliveryTypes.every().canCancel,
  onCancelClick: PropTypes.func,
};

export default CancelDelivery;
