import React from 'react';
import Select, { StylesConfig, Props as SelectProps } from 'react-select';
import AsyncSelect, { Props as AsyncSelectProps } from 'react-select/async';
import { useTranslation } from 'react-i18next';

const customSelectStyles: StylesConfig = {
  control: (style, {
    isFocused,
    isSelected,
  }) => ({
    ...style,
    backgroundColor: '#fff',
    boxShadow: (isFocused || isSelected) ? '#28a745' : undefined,
    borderColor: (isFocused || isSelected) ? 'rgb(153, 153, 153)' : '#ced4da',
    '&:hover, &:focus': {
      borderColor: 'rgb(153, 153, 153)',
      boxShadow: 'none',
    },
  }),
  groupHeading: (style) => ({
    ...style,
    color: '#000000',
  }),
  option: (style, {
    data,
    isDisabled,
    isFocused,
    isSelected,
  }) => {
    let bgColor;
    if (!isDisabled && isSelected) {
      bgColor = '#E71D73';
    } else if (!isDisabled && isFocused) {
      bgColor = '#f3f3f7';
    }
    return {
      ...style,
      backgroundColor: bgColor,
      color: (isSelected) ? '#fff' : data.color,
      '&:active': {
        backgroundColor: '#ebebef',
      },
    };
  },
  placeholder: () => ({
    margin: 0,
  }),
  menu: (style) => ({ ...style, zIndex: 2 }),
};

export type Props = SelectProps | AsyncSelectProps<{ label: string; value: string; }>;

// TODO: improve typings in all component
const CustomSelect: React.FC<Props> = ({
  value = '',
  options = [],
  loadOptions,
  noOptionsMessage,
  ...props
// eslint-disable-next-line arrow-body-style
}) => {
  const { t } = useTranslation(['common']);

  if (loadOptions) {
    return (
      <AsyncSelect
        {...props}
        loadOptions={loadOptions}
        noOptionsMessage={noOptionsMessage}
      />
    );
  }

  // TODO: type Options array properly
  const optionsFlat = (options as any)
    .reduce((x: any[], option: any) => (
      x.concat((option.options) ? option.options : option)
    ), []);

  const getValue = (opts: any): any => {
    // TODO: getValue should support `value` of type `{ label: string; value: string; }`
    const selectedOption = (opts as any).find((option: any) => option.value === value);

    return typeof selectedOption === 'undefined' ? null : selectedOption;
  };

  return (
    <Select
      {...props}
      options={options}
      styles={customSelectStyles}
      value={getValue(optionsFlat)}
      noOptionsMessage={noOptionsMessage ?? (() => t('common:Select.noOptions'))}
    />
  );
};

export default CustomSelect;
