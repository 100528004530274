// :: React
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// :: Components
import SettingsLeftBlock from 'Components/Settings/SettingsLeftBlock';
import SettingsRightBlock from 'Components/Settings/SettingsRightBlock';
import { Row, Col } from 'reactstrap';

import MainBody from 'Components/Presentational/Layout/MainBody/MainBody';

const settingsLayout = (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation('settings');
  const rightBlockClass = 'settings-right';

  const {
    isOpened, leftBlock, rightBlock,
    checkedItems, isPartLoading, goBack, children, upsellState, upsellDescription,
  } = props;

  return (
    <MainBody title={t('Navigation.title')} wrapperHeight="150px">
      <Row className="settings">
        <Col xs={12} sm={12} md={12} lg={3} className="col-xs-pb-20 settings-left">
          <SettingsLeftBlock
            isOpened={isOpened}
            header={leftBlock.header}
            main={leftBlock.main}
            checkedItems={checkedItems}
            goBack={goBack}
          />
        </Col>
        <Col xs={12} sm={12} md={12} lg={9} className={rightBlockClass} style={{ display: isPartLoading ? 'none' : 'block' }}>
          <SettingsRightBlock
            goBack={goBack}
            isOpened={isOpened}
            header={rightBlock.header}
            customHeader={rightBlock.customHeader}
            main={rightBlock.main}
            checkedItems={checkedItems}
            isPartLoading={isPartLoading}
            upsellState={upsellState}
            upsellDescription={upsellDescription}
          >
            {children}
          </SettingsRightBlock>
        </Col>
      </Row>
    </MainBody>
  );
};

settingsLayout.propTypes = {
  isOpened: PropTypes.bool,
  isPartLoading: PropTypes.bool,
  modalIsOpen: PropTypes.bool,
  goBack: PropTypes.string,
  checkedItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.shape({})]),
  leftBlock: PropTypes.shape({
    header: PropTypes.shape({
      title: PropTypes.string.isRequired,
      subTitle: PropTypes.string.isRequired,
      button: PropTypes.shape({
        text: PropTypes.string.isRequired,
        class: PropTypes.shape({
          color: PropTypes.string,
          size: PropTypes.string,
          outline: PropTypes.bool,
        }),
        icon: PropTypes.string,
        path: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    main: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        items: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired,
            id: PropTypes.string.isRequired,
            path: PropTypes.string.isRequired,
            icon: PropTypes.shape({
              class: PropTypes.array,
              size: PropTypes.string,
            }),
          }),
        ),
      }),
    ).isRequired,
  }),
  rightBlock: PropTypes.shape({
    header: PropTypes.shape({
      title: PropTypes.string,
      subTitle: PropTypes.string,
      button: PropTypes.shape({
        text: PropTypes.string.isRequired,
        class: PropTypes.shape({
          color: PropTypes.string,
          size: PropTypes.string,
          outline: PropTypes.bool,
        }),
        icon: PropTypes.shape({
          class: PropTypes.array,
          size: PropTypes.string,
        }),
        path: PropTypes.string.isRequired,
      }),
      percentDone: PropTypes.number,
    }),
    customHeader: PropTypes.element,
    main: PropTypes.objectOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          items: PropTypes.arrayOf(
            PropTypes.shape({
              name: PropTypes.string.isRequired,
              id: PropTypes.string.isRequired,
              path: PropTypes.string.isRequired,
              icon: PropTypes.shape({
                class: PropTypes.array,
                size: PropTypes.string,
              }),
            }),
          ),
        }),
      ),
    ),
  }),
  upsellState: PropTypes.bool,
  upsellDescription: PropTypes.string,
};

settingsLayout.defaultProps = {
  isOpened: false,
  isPartLoading: false,
  goBack: null,
  leftBlock: null,
  rightBlock: null,
  children: null,
  upsellState: false,
};

export default settingsLayout;
