import React from 'react';
import ReactDatePicker, { registerLocale, ReactDatePickerProps } from 'react-datepicker';
import { useTranslation } from 'react-i18next';

import { locales } from '~/services/date';

Object.keys(locales).forEach((locale) => {
  registerLocale(locale, locales[locale]);
});

type Props = ReactDatePickerProps;

const DatePicker: React.FC<Props> = ({
  showTimeSelect = false,
  ...props
}) => {
  const { t } = useTranslation(['common']);

  return (
    <ReactDatePicker
      {...props}
      dateFormat={showTimeSelect ? 'PPp' : 'PP'}
      showTimeSelect={showTimeSelect}
      timeFormat="p"
      locale={locales[window.__localeId__ ?? 'cs']}
      timeCaption={t('common:Datepicker.timeLabel')}
      disabledKeyboardNavigation // prevent ReactDatePicker weird double date selection
    />
  );
};

export default DatePicker;
