import { TFunction } from 'i18next';
import { array, object, string } from 'yup';

export const schema = (t: TFunction) => {
  return object().shape({
    localizationConfigs: array().of(object().shape({
      country: string()
        .required(t('validation:CountrySelect.required')),
      homepageUrl: string()
        .url(t('validation:Url.pattern'))
        .max(255, t('validation:Url.maxLength', { count: 255 })),
      contactUrl: string()
        .url(t('validation:Url.pattern'))
        .max(255, t('validation:Url.maxLength', { count: 255 })),
      termsAndConditionsUrl: string()
        .url(t('validation:Url.pattern'))
        .max(255, t('validation:Url.maxLength', { count: 255 })),
      privacyPolicyUrl: string()
        .url(t('validation:Url.pattern'))
        .max(255, t('validation:Url.maxLength', { count: 255 })),
      supportEmail: string()
        .email(t('validation:Email.pattern'))
        .max(255, t('validation:Email.maxLength', { count: 255 })),
      // TODO: Phone validation?
      supportPhone: string(),
    })),
  });
};
