import React, { CSSProperties } from 'react';
import { format } from 'date-fns';
import { Card, CardBody, CardFooter } from 'reactstrap';
import { Line } from 'react-chartjs-2';
import { ChartData, ChartDataSets } from 'chart.js';
import { useTranslation } from 'react-i18next';

import { DeliveryHistory } from '~/types/generated/graphql';

interface DeliveryChartDataSets extends ChartDataSets {
  data: number[];
}

interface DeliveryChartData extends ChartData {
  labels: string[];
  datasets: [DeliveryChartDataSets, DeliveryChartDataSets]
}

type Props = {
  deliveryHistory: DeliveryHistory[]
  css: any;
}

const DeliveriesChart: React.FC<Props> = ({ deliveryHistory, css }) => {
  const { t } = useTranslation('status');
  /**
   * Maps DeliveryHistory to a shape accepted by chart.js
   */
  const getTrendData = (data: DeliveryHistory[]): DeliveryChartData => {
    return data.reduce<DeliveryChartData>(
      (accumulator, current) => {
        const { labels } = accumulator;
        const [delivered, failed] = accumulator.datasets;

        // Populate labels & data fields
        labels.push(format(new Date(current.date), 'dd.MM.'));
        delivered.data.push(current.delivered);
        failed.data.push(current.failed);

        return {
          labels,
          datasets: [
            delivered,
            failed,
          ],
        };
      },
      {
        labels: [],
        datasets: [
          {
            label: t('StatusCategory.delivered'),
            borderColor: '#65C514',
            backgroundColor: 'transparent',
            data: [],
          },
          {
            label: t('StatusCategory.not_delivered'),
            borderColor: '#E21111',
            backgroundColor: 'transparent',
            data: [],
          },
        ],
      },
    );
  };

  const trendData = getTrendData(deliveryHistory);

  return (
    <Card className={`${css.card} pt-1`}>
      <CardBody>
        <Line
          type="line"
          data={trendData}
          options={{
            legend: {
              display: false,
            },
            tooltips: {
              enabled: true,
              bodyFontFamily: 'Tofino, sans-serif',
              backgroundColor: '#d6dbdf',
              bodyFontColor: '#6c757d',
              titleFontColor: '#6c757d',
              titleMarginBottom: 8,
              xPadding: 12,
              yPadding: 12,
              cornerRadius: 2,
              displayColors: false,
            },
            elements: {
              point: {
                radius: 2.5,
                hitRadius: 8,
              },
            },
            scales: {
              scaleLabel: {
                fontFamily: 'Tofino, sans-serif',
              },
              xAxes: [{
                gridLines: {
                  display: false,
                },
                ticks: {
                  min: 0,
                  fontFamily: 'Tofino, sans-serif',
                  maxRotation: 0,
                  padding: 24,
                  autoSkipPadding: 60,
                },
              }],
              yAxes: [{
                gridLines: {
                  display: true,
                  drawBorder: false,
                  color: 'rgba(52, 58, 64, 0.1)',
                  zeroLineColor: 'rgba(52, 58, 64, 0.1)',
                },
                ticks: {
                  min: 0,
                  maxTicksLimit: 8,
                  fontFamily: 'Tofino, sans-serif',
                  maxRotation: 0,
                  padding: 20,
                },
              }],
            },
          }}
        />
      </CardBody>
      <CardFooter>
        <ul className={`${css.legend} pl-0 mb-0 d-flex justify-content-center`}>
          {trendData.datasets.map((dataset) => (
            <li
              key={dataset.label}
              className={`${css.legendItem} px-3 d-flex align-items-center`}
              style={{ '--legendItemColor': dataset.borderColor } as CSSProperties}
            >
              {dataset.label}
            </li>
          ))}
        </ul>
      </CardFooter>
    </Card>
  );
};

export default DeliveriesChart;
