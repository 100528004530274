import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MARKETING_CAMPAIGNS_LIST from 'Containers/Settings/Branding/MarketingCampaigns/queries/fetchMarketingCampaigns.gql';
import { QueryMarketingCampaignsListData } from 'Containers/Settings/Branding/MarketingCampaigns/typesAndDefaults';
import ESHOP from 'Containers/Settings/Eshop/eshop.gql';
import { EshopQuery } from 'Containers/Settings/Eshop/types';
import { Formik, FormikHelpers } from 'formik';
import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, FormGroup, Form, Label } from 'reactstrap';
import { useHistory } from 'react-router-dom';

import ColorPicker from 'Components/Presentational/ColorPicker/ColorPicker';
import withValidation from 'Components/Presentational/FormExtensions/HoC/withValidation/withValidation';
import fileToPathReader from 'Containers/Settings/Branding/helpers/fileToPathReader';
import getFullPathUrlOrDefault from 'Containers/Settings/Branding/helpers/getFullPathUrlOrDefault';
import Tooltip from 'Components/Presentational/Tooltip/Tooltip';
import { ACCEPTED_FILE_TYPES } from 'Containers/Settings/Branding/typesAndDefaults';
import InlineFormRow from '~/components/InlineFormRow/InlineFormRow';
import InputField from '~/components/InputField/InputField';
import MailingPreview, { MailingPreviewSettings } from '~/components/MailingPreview/MailingPreview';
import ModalWithPreview from '~/components/ModalWithPreview/ModalWithPreview';
import OnUserLeavePageDialog from '~/components/OnUserLeavePageDialog/OnUserLeavePageDialog';
import SimpleFileInput from '~/components/SimpleFileInput/SimpleFileInput';
import SubmitButton from '~/components/SubmitButton/SubmitButton';
import FormikScroll from '~/components/FormikScroll/FormikScroll';
import useAuthQuery from '~/hooks/useAuthQuery';
import { validationSchema } from './validationSchema';

import { EmailConfigValues } from '../typesAndDefaults';

const FormikColorPicker = withValidation(ColorPicker);

type Props = {
  onSubmit: (values: EmailConfigValues, formikHelpers: FormikHelpers<EmailConfigValues>, onSubmitCallback: () => void) => Promise<void>,
  initialValues: EmailConfigValues,
}

const MailingForm: React.FC<Props> = ({ onSubmit, initialValues }) => {
  const { t } = useTranslation(['settings', 'validation']);
  const history = useHistory();
  const [logoPreview, setLogoPreview] = useState('');
  const { data: eshopQueryData } = useAuthQuery<{ eshop: EshopQuery }, never>(ESHOP);
  const { data: marketingCampaignsDataQuery } = useAuthQuery<QueryMarketingCampaignsListData>(MARKETING_CAMPAIGNS_LIST);
  const activeMarketingCampaignEmailBannerUrl = marketingCampaignsDataQuery?.eshop?.activeEshopCampaign?.defaultEmailBanner?.url;
  const [formIsSaving, setFormIsSaving] = useState(false);

  const handleSubmit = async (values: EmailConfigValues, formikHelpers: FormikHelpers<EmailConfigValues>) => {
    setFormIsSaving(true);
    formikHelpers.setSubmitting(true);
    await onSubmit(values, formikHelpers, () => setFormIsSaving(false));
  };

  const generatePreview = (file: string | File | null | undefined): void => {
    if (typeof file === 'string') {
      setLogoPreview(file);
      return;
    }

    if (file instanceof File) {
      fileToPathReader(file, (callbackFile) => {
        setLogoPreview(callbackFile);
      });
    }
  };

  const preparePreviewData = (emailConfigValues: EmailConfigValues): MailingPreviewSettings => ({
    logo: logoPreview || getFullPathUrlOrDefault(emailConfigValues?.logo?.url),
    sender: emailConfigValues?.sender,
    linkColor: emailConfigValues?.linkColor,
    senderEmail: emailConfigValues?.senderEmail,
    primaryColor: emailConfigValues?.primaryColor,
    headerColor: emailConfigValues?.headerColor,
    headerBackgroundColor: emailConfigValues?.headerBackgroundColor,
    buttonColor: emailConfigValues?.buttonColor,
    buttonBackgroundColor: emailConfigValues?.buttonBackgroundColor,

    eshopName: eshopQueryData?.eshop?.name,
    eshopUrl: eshopQueryData?.eshop?.webUrl,
    activeMarketingCampaignEmailBannerUrl: getFullPathUrlOrDefault(activeMarketingCampaignEmailBannerUrl),
  });

  const designDescriptionWithEmailPreview = (values: EmailConfigValues): JSX.Element => {
    return (
      <>
        {t('settings:Branding.Mailing.Form.Design.description')}
        <div className="pt-3">
          <ModalWithPreview
            modalTitle={t('settings:Branding.Mailing.PreviewModal.title')}
            modalCancelButtonText={t('settings:Branding.Mailing.PreviewModal.closeButton')}
          >
            <MailingPreview previewSettings={preparePreviewData(values)} />
          </ModalWithPreview>
        </div>
      </>
    );
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema(t)}
    >
      {({ handleSubmit: formikHandleSubmit, isSubmitting, values, resetForm, submitForm, setFieldValue, dirty }) => (
        <Form onSubmit={formikHandleSubmit} className="mailing-form">
          <FormikScroll />
          <InlineFormRow
            sectionTitle={t('settings:Branding.Mailing.Form.MailingName.title')}
            description={t('settings:Branding.Mailing.Form.MailingName.description')}
          >
            <FormGroup>
              <Label>
                {t('settings:Branding.Mailing.Form.MailingName.label')}
                <span className="branding-required-field">*</span>
              </Label>
              <InputField
                name="title"
                type="text"
                placeholder={t('settings:Branding.Mailing.Form.MailingName.placeholder')}
              />
            </FormGroup>
          </InlineFormRow>

          <hr className="mb-5 mt-4" />
          <InlineFormRow
            sectionTitle={t('settings:Branding.Mailing.Form.Sender.title')}
            description={t('settings:Branding.Mailing.Form.Sender.description')}
          >
            <FormGroup>
              <Label>
                {t('settings:Branding.Mailing.Form.Sender.senderLabel')}
                <span className="branding-required-field">*</span>
              </Label>
              <InputField
                name="sender"
                type="text"
                placeholder={t('settings:Branding.Mailing.Form.Sender.senderPlaceholder')}
              />
            </FormGroup>
            <FormGroup>
              <Label>
                {t('settings:Branding.Mailing.Form.Sender.emailLabel')}
                <span className="branding-required-field">*</span>
              </Label>
              <InputField
                name="senderEmail"
                type="text"
                placeholder={t('settings:Branding.Mailing.Form.Sender.emailPlaceholder')}
              />
            </FormGroup>
          </InlineFormRow>

          <hr className="mb-5 mt-4" />
          <InlineFormRow
            sectionTitle={t('settings:Branding.Mailing.Form.Logo.title')}
            description={t('settings:Branding.Mailing.Form.Logo.description', { width: 200, height: 60 })}
          >
            <FormGroup>
              <SimpleFileInput
                label={t('settings:Branding.Mailing.Form.Logo.logoLabel')}
                name="logoImg"
                accept={ACCEPTED_FILE_TYPES.Email}
                initialPreviewUrl={getFullPathUrlOrDefault(values.logo?.url)}
                onChangeEvent={({ target: { files } }: ChangeEvent<HTMLInputElement>) => generatePreview(files?.[0] || values.logo?.url)}
              />
            </FormGroup>
          </InlineFormRow>

          <hr className="mb-5 mt-4" />
          <InlineFormRow
            sectionTitle={t('settings:Branding.Mailing.Form.Design.title')}
            description={designDescriptionWithEmailPreview(values)}
          >
            <FormGroup>
              <h3>{t('settings:Branding.Mailing.Form.Design.brandColors')}</h3>
              <FormikColorPicker
                name="primaryColor"
                label={(
                  <>
                    {t('settings:Branding.Mailing.Form.Design.primaryColorLabel')}
                    <span className="branding-required-field">*</span>
                  </>
                )}
                color={values.primaryColor}
              />
              <FormikColorPicker
                name="linkColor"
                label={(
                  <>
                    {t('settings:Branding.Mailing.Form.Design.linkColorLabel')}
                    <span className="branding-required-field">*</span>
                  </>
                )}
                color={values.linkColor}
              />
              <FormikColorPicker
                name="headerBackgroundColor"
                label={(
                  <>
                    {t('settings:Branding.Mailing.Form.Design.headerBackgroundColorLabel')}
                    <span className="branding-required-field">*</span>
                  </>
                )}
                color={values.headerBackgroundColor}
              />
              <FormikColorPicker
                name="headerColor"
                label={(
                  <>
                    {t('settings:Branding.Mailing.Form.Design.headerColorLabel')}
                    <span className="branding-required-field">*</span>
                    <Tooltip
                      id="mailing-form-header-color-tooltip"
                      text={t('settings:Branding.Mailing.Form.Design.headerColorTooltip')}
                      linkIconPosition="after"
                      placement="right"
                    />
                  </>
                )}
                color={values.headerColor}
              />
              <FormikColorPicker
                name="buttonColor"
                label={t('settings:Branding.Mailing.Form.Design.buttonColorLabel')}
                color={values.buttonColor}
              />
              <FormikColorPicker
                name="buttonBackgroundColor"
                label={t('settings:Branding.Mailing.Form.Design.buttonBackgroundColorLabel')}
                color={values.buttonBackgroundColor}
              />
            </FormGroup>
          </InlineFormRow>

          <hr className="mb-3" />

          <div className="branding-form-controls">
            <SubmitButton data-test="submit-mailing-form" color="primary" type="submit" isLoading={isSubmitting}>
              {t('settings:Branding.Mailing.Form.submit')}
            </SubmitButton>
            <SubmitButton
              data-test="submit-and-activate-mailing-form"
              className="ml-2 mr-5"
              color="primary"
              outline
              onClick={async () => {
                setFieldValue('activateAfterSave', true);
                await submitForm();
              }}
              isLoading={isSubmitting}
            >
              {t('settings:Branding.Mailing.Form.submitAndActivate')}
            </SubmitButton>
            <Button
              data-test="close-mailing-form"
              className="align-middle ml-md-auto mr-2"
              color="danger"
              outline
              onClick={() => history.push('/settings/branding/mailing')}
            >
              {t('settings:Branding.Mailing.Form.cancel')}
            </Button>
            <Button color="dark" size="xs" disabled={!dirty} onClick={() => resetForm()}>
              <FontAwesomeIcon icon={['fas', 'trash']} fixedWidth />
              {t('settings:Branding.Mailing.Form.discardChanges')}
            </Button>
          </div>
          { dirty && !formIsSaving && <OnUserLeavePageDialog /> }
        </Form>
      )}
    </Formik>
  );
};

export default MailingForm;
