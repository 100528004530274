import React from 'react';
import { Card, CardBody, Row, Col, FormGroup, Button, CustomInput } from 'reactstrap';
import { connect } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import InputField from '~/components/InputField/InputField';
import SelectField from '../SelectField/SelectField';
import CountrySelect from '../CountrySelect/CountrySelect';
import { RecipientPickupType, RecipientValues, PlaceOption } from '../types';

import PickupPlaceSelect from './PickupPlaceSelect/PickupPlaceSelect';

type Props = {
  pickupTypeButtons: {
    address: boolean;
    pickupPlace: boolean;
    collectionPlace: boolean;
  };
  onPickupTypeChange(pickupType: RecipientPickupType): void;
  pickupType: RecipientPickupType;
  disabled: boolean;
  toggleContacts(): void;
  collectionPlaces: PlaceOption[];
  pickupPlaceTypeId: number;
  countryCodesFilter?: string[];
};

const Recipient = connect<Props, RecipientValues>(({
  pickupTypeButtons,
  onPickupTypeChange,
  pickupType,
  disabled,
  toggleContacts,
  collectionPlaces,
  pickupPlaceTypeId,
  countryCodesFilter,
  formik,
}) => {
  const { t } = useTranslation(['deliveries', 'validation']);
  const { values, handleChange } = formik;
  const supportsCountryFilter = countryCodesFilter !== undefined && countryCodesFilter.length > 0;

  return (
    <Card className="shadow border-0 mb-4">
      <CardBody>
        <Row>
          <Col md="4">
            <h4>{t('deliveries:NewDelivery.recipientTitle')}</h4>
          </Col>

          <Col md="6">
            <FormGroup>
              {
                pickupTypeButtons.address && (
                  <Button
                    color="switcher"
                    active={pickupType === 'address'}
                    onClick={(): void => { onPickupTypeChange('address'); }}
                  >
                    {t('deliveries:NewDelivery.Address.switchButton')}
                  </Button>
                )
              }
              {
                pickupTypeButtons.pickupPlace && (
                  <Button
                    color="switcher"
                    active={pickupType === 'pickupPlace'}
                    onClick={(): void => onPickupTypeChange('pickupPlace')}
                  >
                    {t('deliveries:NewDelivery.PickupPlace.switchButton')}
                  </Button>
                )
              }
              {
                pickupTypeButtons.collectionPlace && (
                  <Button
                    color="switcher"
                    active={pickupType === 'collectionPlace'}
                    onClick={(): void => onPickupTypeChange('collectionPlace')}
                  >
                    {t('deliveries:NewDelivery.CollectionPlace.switchButton')}
                  </Button>
                )
              }
            </FormGroup>

            {pickupType === 'collectionPlace' && (
              <FormGroup>
                <SelectField
                  label={t('deliveries:NewDelivery.CollectionPlace.label')}
                  name="recipientCollectionPlace"
                  placeholder={t('deliveries:NewDelivery.CollectionPlace.placeholder')}
                  options={collectionPlaces}
                />
              </FormGroup>
            )}

            {pickupType === 'pickupPlace' && (
              <div>
                {supportsCountryFilter && (
                  <FormGroup>
                    <SelectField
                      component={CountrySelect}
                      label={t('validation:CountrySelect.label')}
                      name="recipientPickupPlaceCountry"
                      countryCodesFilter={countryCodesFilter}
                    />
                  </FormGroup>
                )}

                <FormGroup>
                  <PickupPlaceSelect
                    name="recipientPickupPlace"
                    typeId={pickupPlaceTypeId}
                    countryCodeFilter={values.recipientPickupPlaceCountry}
                  />
                </FormGroup>

                <div className="form-row">
                  <FormGroup className="col-md-6">
                    <InputField
                      label={t('deliveries:NewDelivery.Address.RecipientName.label')}
                      name="recipientAddressName"
                      placeholder={t('deliveries:NewDelivery.Address.RecipientName.placeholder')}
                    />
                  </FormGroup>
                  <FormGroup className="col-md-6">
                    <InputField
                      label={t('validation:Surname.label')}
                      name="recipientAddressSurname"
                      placeholder={t('validation:Surname.placeholder')}
                    />
                  </FormGroup>
                </div>

                <FormGroup>
                  <InputField
                    label={t('validation:CompanyName.label')}
                    name="recipientCompanyName"
                    placeholder={t('validation:CompanyName.label')}
                  />
                </FormGroup>
                <FormGroup>
                  <InputField
                    label={t('validation:Email.label')}
                    type="email"
                    name="recipientEmail"
                    placeholder={t('validation:Email.label')}
                  />
                </FormGroup>
                <FormGroup>
                  <InputField
                    label={t('validation:Phone.label')}
                    name="recipientPhone"
                    placeholder={t('validation:Phone.label')}
                  />
                </FormGroup>
              </div>
            )}

            {pickupType === 'address' && (
              <div>
                <div className="form-row">
                  <FormGroup className="col-md-5">
                    <InputField
                      label={t('deliveries:NewDelivery.Address.RecipientName.label')}
                      name="recipientAddressName"
                      disabled={disabled}
                      placeholder={t('deliveries:NewDelivery.Address.RecipientName.placeholder')}
                    />
                  </FormGroup>
                  <FormGroup className="col-md-5">
                    <InputField
                      label={t('validation:Surname.label')}
                      name="recipientAddressSurname"
                      disabled={disabled}
                      placeholder={t('validation:Surname.placeholder')}
                    />
                  </FormGroup>

                  <div className="col-md-2 mt-4 pt-2">
                    <Button
                      onClick={(): void => toggleContacts()}
                    >
                      <FontAwesomeIcon
                        icon={
                          (disabled) ? ['fas', 'times'] : ['fas', 'address-book']
                        }
                        className="no-margin"
                      />
                    </Button>
                  </div>
                </div>

                <FormGroup>
                  <InputField
                    label={t('validation:CompanyName.label')}
                    name="recipientCompanyName"
                    disabled={disabled}
                    placeholder={t('validation:CompanyName.label')}
                  />
                </FormGroup>

                <FormGroup>
                  <InputField
                    label={t('validation:Street.label')}
                    name="recipientAddressStreet"
                    disabled={disabled}
                    placeholder={t('validation:Street.label')}
                  />
                </FormGroup>

                <div className="form-row">
                  <FormGroup className="col-md-8">
                    <InputField
                      label={t('validation:City.label')}
                      name="recipientAddressCity"
                      disabled={disabled}
                      placeholder={t('validation:City.label')}
                    />
                  </FormGroup>

                  <FormGroup className="col-md-4">
                    <InputField
                      label={t('validation:ZipCode.label')}
                      name="recipientAddressZip"
                      disabled={disabled}
                      placeholder={t('validation:ZipCode.label')}
                    />
                  </FormGroup>
                </div>

                <FormGroup>
                  <SelectField
                    component={CountrySelect}
                    label={t('validation:CountrySelect.label')}
                    name="recipientState"
                    disabled={disabled}
                  />
                </FormGroup>

                <FormGroup className="pt-4">
                  <InputField
                    label={t('validation:Email.label')}
                    name="recipientEmail"
                    disabled={disabled}
                    placeholder={t('validation:Email.label')}
                    type="email"
                  />
                </FormGroup>

                <FormGroup>
                  <InputField
                    label={t('validation:Phone.label')}
                    name="recipientPhone"
                    disabled={disabled}
                    placeholder={t('validation:Phone.label')}
                  />
                </FormGroup>

                <FormGroup>
                  <CustomInput
                    type="checkbox"
                    id="recipientSaveToAddressBook"
                    // value={values.recipientSaveToAddressBook}
                    onChange={handleChange}
                    checked={values.recipientSaveToAddressBook}
                    label={t('deliveries:NewDelivery.Address.checkboxLabel')}
                  />
                </FormGroup>
              </div>
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
});

export default Recipient;
