import PropTypes from 'prop-types';

export default class FormikTypes {
  static eventHandlers() {
    return {
      handleChange: PropTypes.func,
      handleBlur: PropTypes.func,
      handleSubmit: PropTypes.func,
    };
  }

  static utils() {
    return {
      setFieldValue: PropTypes.func,
      setFieldTouched: PropTypes.func,
      setSubmitting: PropTypes.func,
      setValues: PropTypes.func,
    };
  }

  static objects() {
    return {
      values: PropTypes.shape({}),
      errors: PropTypes.shape({}),
      touched: PropTypes.shape({}),
    };
  }

  static booleans() {
    return { isSubmitting: PropTypes.bool };
  }
}
