import React from 'react';
import produce from 'immer';
import { partition, flatten } from 'lodash';
import { Spinner } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import SettingsBase from 'Containers/Settings/_base/SettingsBase';
import SettingsLayout from 'Components/Settings/SettingsLayout';
import CollectionPlace from 'Models/collectionPlace/CollectionPlace';
import { toast } from 'Shared/utilities';
import Modal from 'Containers/ConfirmModal/ConfirmModal';
import { customManualMutation } from 'Models/Components/CustomMutation';
import CustomQuery from 'Models/Components/CustomQuery';
import { CountryManagerContext } from 'Containers/CountryManager/CountryManager';
import CollectionPlaceQueryFactory from 'Models/collectionPlace/Query/Factories/CollectionPlaceQueryFactory';
import CollectionPlaceFragments from 'Models/collectionPlace/Query/Fragments/CollectionPlaceFragments';
import CollectionPlacesPreviewUI from './Components/Presentational/CollectionPlacesPreviewUI/CollectionPlacesPreviewUI';

class CollectionPlaces extends SettingsBase {
  static sortCollectionPlaces(data) {
    const collectionPlacesSplitByActive = partition(data, (i) => i.active);

    collectionPlacesSplitByActive[0].sort((current, next) => Number(current.id) < Number(next.id));
    collectionPlacesSplitByActive[1].sort((current, next) => Number(current.id) < Number(next.id));

    return flatten(collectionPlacesSplitByActive);
  }

  constructor(props) {
    super(props, 'general');
    this.collectionPlace = new CollectionPlace();
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.actionDelete = this.actionDelete.bind(this);
    const { t } = this.props;

    this.table = {
      columns: [
        {
          title: 'Název',
          index: 'name',
          key: '1',
        },
        {
          title: 'Adresa',
          index: 'address',
          key: '2',
          width: 200,
        },
        {
          title: 'Akce',
          index: 'actions',
          key: '3',
        },
      ],
      actions: {
        custom: {
          edit: {
            type: 'link',
            ref: '/settings/collection-places/:id/edit',
            icon: { class: ['far', 'edit'] },
            text: `${t('settings:CollectionPlaces.Tooltip.editPlace')}`,
          },
          delete: {
            type: 'function',
            func: this.toggleDeleteModal,
            params: ':id',
            icon: { class: ['fas', 'times'] },
            text: `${t('settings:CollectionPlaces.Tooltip.deactivatePlace')}`,
          },
          activate: {
            type: 'function',
            func: this.activateCollectionPlace,
            params: ':id',
            icon: { class: ['fas', 'check'] },
            text: `${t('settings:CollectionPlaces.Tooltip.activatePlace')}`,
          },
        },
      },
      config: {
        striped: false,
        borderless: false,
        className: 'basic pickup-places',
      },
    };
  }

  async componentDidMount() {
    const state = produce(this.state, (draft) => {
      const draftState = draft;

      draftState.modal = false;
      draftState.isOpened = true;
      draftState.isPartLoading = false;
      draftState.isLoaderVisible = false;
    });

    this.setState(state);
  }

  async actionDelete() {
    const { t } = this.props;
    await this.collectionPlace.deactivateCollectionPlace(this.idToDelete);
    this.toggleDeleteModal();
    toast.success(t('settings:CollectionPlaces.Banner.placeDeactivate'));
    await this.refetchCollectionPlaces();
  }

  activateCollectionPlace = async (id) => {
    const { t } = this.props;

    await customManualMutation({
      mutation: CollectionPlaceQueryFactory.activateCollectionPlace(
        '...CollectionPlaceActivation',
        CollectionPlaceFragments.collectionPlaceActivation(),
      ),
      variables: { id },
    });
    toast.success(t('settings:CollectionPlaces.Banner.placeActivate'));
    await this.refetchCollectionPlaces();
  };

  formatQueryData(data) {
    const { i18n } = this.props;
    const sortedData = CollectionPlaces.sortCollectionPlaces(data);

    return sortedData.map((item) => {
      const formattedItem = {};
      formattedItem.id = item.id;
      formattedItem.name = item.name;
      formattedItem.address = (
        <div>
          <div>{item.address.street}</div>
          <div>
            {item.address.zip}
            &nbsp;
            {item.address.city}
            ,
            &nbsp;
            {this.getCountryNameByCode(item.address.country, i18n.language)}
          </div>
        </div>
      );
      formattedItem.className = item.active ? 'active' : 'inactive';

      return formattedItem;
    });
  }

  toggleDeleteModal(id) {
    const { modal: modalVisibility } = this.state;

    this.idToDelete = id;
    this.setState({ modal: !modalVisibility });
  }

  render() {
    const { t } = this.props;

    return (
      <CountryManagerContext.Consumer>
        {(countryManager) => {
          this.getCountryNameByCode = countryManager.getCountryNameByCode;

          return (
            <SettingsLayout
              goBack="/settings"
              leftBlock={this.state.leftBlock}
              rightBlock={this.state.rightBlock}
              checkedItems={this.state.checkedItems}
              isOpened={this.state.isOpened}
              isPartLoading={this.state.isPartLoading}
            >
              <CustomQuery
                query={CollectionPlaceQueryFactory.collectionPlaces('...CollectionPlacePreview', CollectionPlaceFragments.collectionPlacePreview())}
              >
                {({
                  data,
                  loading,
                  error,
                  refetch: refetchCollectionPlaces,
                }) => {
                  if (error) return <div>Při načítání svozových míst nastala chyba.</div>;
                  if (loading) return <Spinner />;

                  const { collectionPlaces } = data;

                  if (!this.refetchCollectionPlaces) {
                    this.refetchCollectionPlaces = refetchCollectionPlaces;
                  }

                  return (
                    <CollectionPlacesPreviewUI
                      dataTableStructure={this.table}
                      data={(!loading && !error) ? this.formatQueryData(collectionPlaces) : null}
                      loading={loading}
                    />
                  );
                }}
              </CustomQuery>
              <Modal
                onCancel={this.toggleDeleteModal}
                onPrompt={this.actionDelete}
                toggle={this.toggleDeleteModal}
                isOpen={this.state.modal || false}
                title={t('settings:CollectionPlaces.DeactivatePlaceModal.title')}
                body={t('settings:CollectionPlaces.DeactivatePlaceModal.title')}
                buttons={{
                  prompt: {
                    text: `${t('settings:CollectionPlaces.DeactivatePlaceModal.submitButton')}`,
                    color: 'secondary',
                  },
                  cancel: {
                    text: `${t('settings:CollectionPlaces.DeactivatePlaceModal.cancelButton')}`,
                    color: 'link',
                  },
                }}
              />
            </SettingsLayout>
          );
        }}
      </CountryManagerContext.Consumer>
    );
  }
}

export default withTranslation(['settings'])(CollectionPlaces);
