import React from 'react';
import { useTranslation } from 'react-i18next';

import { generateReactKey } from 'Shared/utilities';
import TimelineIconAccordion from '../_TimelineIconAccordion/TimelineIconAccordion';

const NoteWithoutCategory = ({ events, onEditClick, notes }) => {
  const { t } = useTranslation('deliveries');
  const findNote = (noteId) => notes.find((note) => note.id === noteId);

  return (
    <>
      {events.map((event) => (
        <TimelineIconAccordion
          theme="dark"
          className="note"
          icon={{ type: 'fw', icon: 'comment' }}
          key={generateReactKey()}
          title={(
            <>
              <h4>
                {t('DeliveryDetail.Timeline.Notes.noteLabel')}{': '}{event.title}
              </h4>
              <div className="subtitle">
                <span>{event.on}</span>
                <span>&nbsp;|&nbsp;</span>
                <span>{t('DeliveryDetail.Timeline.Notes.userLabel')}{': '}{event.author.name}
                </span>

                {(findNote(event.relatedObjectId) ? findNote(event.relatedObjectId).canUpdate : false) && (
                  <>
                    <span>&nbsp;|&nbsp;</span>
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                    <span className="link-button" onClick={() => onEditClick(event.relatedObjectId)}>
                      {t('DeliveryDetail.Timeline.Notes.editButton')}
                    </span>
                  </>
                )}
              </div>
            </>
          )}
        />
      ))}
    </>
  );
};

export default NoteWithoutCategory;
