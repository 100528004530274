import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormFeedback, FormGroup, Input, Label } from 'reactstrap';

import CountrySelect from 'Containers/CountrySelect/CountrySelect';
import FormikTypes from 'Types/formik/FormikTypes';

const AddressSection = ({ handleChange, handleBlur, values, touched, errors, setFieldValue }) => {
  const { t } = useTranslation(['contacts', 'validation']);

  return (
    <>
      <Col md="5" className="border-left">
        <h5 className="pb-3 mt-3">
          {t('contacts:ContactForm.addressTitle')}
        </h5>
        <div className="form-row">
          <FormGroup className="col-md-12">
            <Label htmlFor="country" style={{ display: 'block' }}>
              {t('validation:CountrySelect.label')}
            </Label>
            <CountrySelect
              name="country"
              onChange={setFieldValue}
              onBlur={handleBlur}
              value={values.country}
            />
            {touched.country && errors.country && (
              <FormFeedback className="d-block">{errors.country}</FormFeedback>
            )}
          </FormGroup>
        </div>
        <div className="form-row">
          <FormGroup className="col-md-12">
            <Label htmlFor="street" style={{ display: 'block' }}>
              {t('validation:Street.label')}
            </Label>
            <Input
              name="street"
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.street}
              invalid={touched.street && errors.street !== undefined}
              valid={touched.street && !errors.street}
            />
            {touched.street && errors.street && <FormFeedback>{errors.street}</FormFeedback>}
          </FormGroup>
        </div>
        <div className="form-row">
          <FormGroup className="col-md-6">
            <Label htmlFor="zip" style={{ display: 'block' }}>
              {t('validation:ZipCode.label')}
            </Label>
            <Input
              name="zip"
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.zip}
              invalid={touched.zip && errors.zip !== undefined}
              valid={touched.zip && !errors.zip}
            />
            {touched.zip && errors.zip && <FormFeedback>{errors.zip}</FormFeedback>}
          </FormGroup>
          <FormGroup className="col-md-6">
            <Label htmlFor="city" style={{ display: 'block' }}>
              {t('validation:City.label')}
            </Label>
            <Input
              name="city"
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.city}
              invalid={touched.city && errors.city !== undefined}
              valid={touched.city && !errors.city}
            />
            {touched.city && errors.city && <FormFeedback>{errors.city}</FormFeedback>}
          </FormGroup>
        </div>
      </Col>
    </>
  );
};

AddressSection.propTypes = {
  ...FormikTypes.eventHandlers().isRequired,
  ...FormikTypes.objects().isRequired,
};

export default AddressSection;
