// :: React
import React from 'react';
import PropTypes from 'prop-types';

const svgIcon = ({
  icon,
  className,
  alt,
  style,
}) => (
  <img
    alt={alt === null ? `Icon ${icon}` : alt}
    src={`/assets/img/icons/${icon}.svg`}
    className={className}
    style={style}
  />
);

svgIcon.defaultProps = {
  className: '',
  alt: null,
  style: null,
};

svgIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
  alt: PropTypes.string,
  style: PropTypes.shape({}),
};

export default svgIcon;
