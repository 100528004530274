import React from 'react';
import SimpleModal from '~/components/SimpleModal/SimpleModal';

import './ModalWithPreview.scss';

export type Props = {
  modalTitle: string;
  modalCancelButtonText: string;
};

const ModalWithPreview: React.FC<Props> = ({ modalTitle, modalCancelButtonText, children }) => {
  const [previewOpened, setPreviewOpened] = React.useState(false);

  return (
    <div
      className="modal-with-preview"
      onClick={() => setPreviewOpened(!previewOpened)}
      role="button"
      tabIndex={0}
      onKeyDown={({ key }) => {
        if (key === 'Enter') setPreviewOpened(!previewOpened);
      }}
    >
      <div className="content-preview">{children}</div>

      <SimpleModal
        isOpen={previewOpened}
        toggle={() => setPreviewOpened(!previewOpened)}
        title={modalTitle}
        cancelButton={modalCancelButtonText}
        size="fullscreen"
      >
        <div className="modal-with-preview-content">{children}</div>
      </SimpleModal>
    </div>
  );
};

export default ModalWithPreview;
