import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Col, Row, Container, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SettingsBase from 'Containers/Settings/_base/SettingsBase';
import SettingsLayout from 'Components/Settings/SettingsLayout';
import Loader from 'Containers/Loader/Loader';
import DataTable from 'Containers/DataTable/DataTable';
import AccountDataSource from '../../../../core/models/account/AccountDataSource';

class Users extends SettingsBase {
  constructor(props) {
    super(props);

    this.actionChangeStatus = this.actionChangeStatus.bind(this);
    this.model = new AccountDataSource();
    this.users = [];
  }

  async componentDidMount() {
    await this.sendQueryAndHandleData();
  }

  async sendQueryAndHandleData(cache = true) {
    const queryData = await this.sendQuery(cache);
    const formattedQueryData = Users.formatDataForRender(queryData);
    this.renderData(formattedQueryData);
  }

  renderData(formattedQueryData) {
    const { t } = this.props;

    this.setState({
      isOpened: true,
      data: formattedQueryData,
      isPartLoading: false,
      table: {
        columns: [
          {
            title: 'Jméno',
            index: 'fullName',
            key: '1',
          },
          {
            title: 'Email',
            index: 'email',
            key: '2',
          },
          {
            title: 'Aktivní',
            index: 'active',
            key: '4',
          },
          {
            title: 'Akce',
            index: 'actions',
            key: '5',
          },
        ],
        actions: {
          custom: {
            edit: {
              type: 'link',
              ref: '/settings/users/:id',
              icon: { class: ['far', 'edit'] },
              text: `${t('settings:Users.Tooltip.userEdit')}`,
            },
          },
          rowClick: {
            type: 'link',
            ref: '/settings/users/:id',
          },
        },
        config: {
          striped: false,
          borderless: false,
          className: 'basic users',
        },
        isLoaderVisible: false,
      },
    });
  }

  async actionChangeStatus(id) {
    const isActive = this.state.data.find((item) => item.id === id).active.props['data-active'];
    if (isActive) {
      await this.model.deactivateAccount(id);
      await this.sendQueryAndHandleData(false);
    } else {
      await this.model.activateAccount(id);
      await this.sendQueryAndHandleData(false);
    }
  }

  async sendQuery(cache) {
    this.setState({ isLoaderVisible: true });
    const accounts = await this.model.accounts(cache);
    this.setState({ isLoaderVisible: false });

    return accounts;
  }

  static formatDataForRender(queryData) {
    const queryDataList = queryData.data.accounts;

    return queryDataList.map((item) => {
      const currentItem = {};
      currentItem.id = item.id;
      currentItem.fullName = `${item.contact.name} ${item.contact.surname}`;
      currentItem.email = item.contact.email;
      currentItem.active = item.active ? <FontAwesomeIcon icon="check" fixedWidth /> : <FontAwesomeIcon icon="times" fixedWidth />;
      currentItem.conditionalData = { primaryAccess: item.primaryAccess };

      return currentItem;
    });
  }

  render() {
    const { t } = this.props;
    let content = <Loader />;

    if (this.state.table !== undefined) {
      content = (
        <Container>
          <Row className="align-items-center right-col-spacing">
            <Col md={7}>
              <h1>{t('settings:Users.title')}</h1>
            </Col>
            <Col md={5} className="text-right">
              <Link to="/settings/users/new">
                <Button
                  color="secondary"
                  size="sm"
                  className="align-middle"
                >
                  <FontAwesomeIcon icon={['fas', 'plus']} fixedWidth />
                  {t('settings:Users.newUserButton')}
                </Button>
              </Link>
            </Col>
          </Row>
          <DataTable
            data={this.state.data}
            columns={this.state.table.columns}
            actions={this.state.table.actions}
            config={this.state.table.config}
            isLoaderVisible={this.state.table.isLoaderVisible}
          />
        </Container>
      );
    }

    return (
      <SettingsLayout
        goBack="/settings"
        leftBlock={this.state.leftBlock}
        rightBlock={this.state.rightBlock}
        checkedItems={this.state.checkedItems}
        isOpened={this.state.isOpened}
        isPartLoading={this.state.isPartLoading}
      >
        {content}
      </SettingsLayout>
    );
  }
}

export default withTranslation(['settings'])(Users);
