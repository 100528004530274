import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Form, FormFeedback, FormGroup, Input, Label, Row, Spinner, FormText } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import CountrySelect from 'Containers/CountrySelect/CountrySelect';
import FormikTypes from 'Types/formik/FormikTypes';
import withValidation from 'Components/Presentational/FormExtensions/HoC/withValidation/withValidation';
import { stringToIdentifier } from 'Shared/utilities';
import FormikScroll from '~/components/FormikScroll/FormikScroll';

const InputWithValidation = withValidation(Input);

const handleCollectionPlaceNameChange = async (e, setFieldValue) => {
  const { name, value } = e.target;
  const identifier = stringToIdentifier(value);

  setFieldValue(name, value);
  setFieldValue('identificator', identifier);
};

const CollectionPlaceFormUI = ({ formikProps, create }) => {
  const {
    handleSubmit,
    handleBlur,
    handleChange,
    isSubmitting,
    values,
    touched,
    errors,
    setFieldValue,
  } = formikProps;

  const { t } = useTranslation('settings');

  return (
    <Form onSubmit={handleSubmit}>
      <FormikScroll />
      <h1 className="pb-3">
        {create ? `${t('CollectionPlaces.newPlaceTitle')}` : `${t('CollectionPlaces.editPlaceTitle')}`}
      </h1>
      <Row>
        <Col md="12" className="pb-3 text-justify">
          <p>
            {t('CollectionPlaces.description')}
          </p>
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <h4>{t('CollectionPlaces.State.title')}</h4>
        </Col>
        <Col md="6">
          <FormGroup>
            <span className="switch switch-sm">
              <input
                type="checkbox"
                className="switch"
                id="active"
                name="active"
                checked={values.active}
                onChange={handleChange}
              />
              <label htmlFor="active" style={{ paddingLeft: '10px' }}>
                {values.active ? t('CollectionPlaces.State.activeLabel') : t('CollectionPlaces.State.inactiveLabel')}
              </label>
            </span>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <h4>{t('CollectionPlaces.Info.title')}</h4>
        </Col>
        <Col md="6">
          <FormGroup>
            <Label htmlFor="collectionPlaceName" style={{ display: 'block' }}>
              {t('CollectionPlaces.Info.PlaceName.label')}
            </Label>
            <InputWithValidation
              name="collectionPlaceName"
              type="text"
              onChange={(e) => handleCollectionPlaceNameChange(e, setFieldValue)}
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="identificator" style={{ display: 'block' }}>
              {t('CollectionPlaces.Info.Identificator.label')}
            </Label>
            <InputWithValidation
              name="identificator"
              type="text"
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="companyNameForTickets" style={{ display: 'block' }}>
              {t('CollectionPlaces.Info.CompanyName.label')}
            </Label>
            <InputWithValidation
              name="companyNameForTickets"
              type="text"
            />
            <FormText color="muted">
              {t('CollectionPlaces.Info.CompanyName.note')}
            </FormText>
          </FormGroup>
        </Col>
      </Row>

      {create && (
        <Row className="pt-5">
          <Col md="4">
            <h4>{t('CollectionPlaces.addressTitle')}</h4>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label htmlFor="country" style={{ display: 'block' }}>
                {t('validation:CountrySelect.label')}
              </Label>
              <CountrySelect
                name="country"
                value={values.country}
                onChange={setFieldValue}
                onBlur={handleBlur}
                placeholder={t('validation:CountrySelect.placeholder')}
                defaultLabel={t('validation:CountrySelect.placeholder')}
              />
              {touched.country && errors.country && <FormFeedback className="d-block">{errors.country}</FormFeedback>}
            </FormGroup>
            <FormGroup>
              <Label htmlFor="street" style={{ display: 'block' }}>
                {t('validation:Street.label')}
              </Label>
              <InputWithValidation
                name="street"
                type="text"
              />
            </FormGroup>
            <div className="form-row">
              <FormGroup className="col-md-8">
                <Label htmlFor="city" style={{ display: 'block' }}>
                  {t('validation:City.label')}
                </Label>
                <InputWithValidation
                  name="city"
                  type="text"
                />
              </FormGroup>
              <FormGroup className="col-md-4">
                <Label htmlFor="zip" style={{ display: 'block' }}>
                  {t('validation:ZipCode.label')}
                </Label>
                <InputWithValidation
                  name="zip"
                  type="text"
                />
              </FormGroup>
            </div>
          </Col>
        </Row>
      )}

      <Row className="pt-5">
        <Col md="4">
          <h4>{t('CollectionPlaces.contactPersonTitle')}</h4>
        </Col>
        <Col md="6">
          <FormGroup>
            <Label htmlFor="contactName" style={{ display: 'block' }}>
              {t('validation:Name.label')}
            </Label>
            <InputWithValidation
              name="contactName"
              type="text"
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="contactSurname" style={{ display: 'block' }}>
              {t('validation:Surname.label')}
            </Label>
            <InputWithValidation
              name="contactSurname"
              type="text"
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="contactEmail" style={{ display: 'block' }}>
              {t('validation:Email.label')}
            </Label>
            <InputWithValidation
              name="contactEmail"
              type="text"
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="contactPhone" style={{ display: 'block' }}>
              {t('validation:Phone.label')}
            </Label>
            <InputWithValidation
              name="contactPhone"
              type="text"
            />
          </FormGroup>
        </Col>
      </Row>

      <Row className="pt-5">
        <Col md="12" className="text-center mb-3">
          <Button type="submit" color="primary" disabled={isSubmitting}>
            {isSubmitting && <Spinner size="sm" />}
            &nbsp;
            {t('CollectionPlaces.submitButton')}
          </Button>
          <Link to="/settings/collection-places">
            <Button color="link" className="ml-3">
              {t('CollectionPlaces.cancelButton')}
            </Button>
          </Link>
        </Col>
      </Row>
    </Form>
  );
};

CollectionPlaceFormUI.propTypes = {
  formikProps: PropTypes.shape({
    values: FormikTypes.objects().values.isRequired,
    errors: FormikTypes.objects().errors.isRequired,
    touched: FormikTypes.objects().touched.isRequired,
    handleBlur: FormikTypes.eventHandlers().handleBlur.isRequired,
    handleSubmit: FormikTypes.eventHandlers().handleSubmit.isRequired,
    setFieldValue: FormikTypes.utils().setFieldValue.isRequired,
    isSubmitting: FormikTypes.booleans().isSubmitting.isRequired,
  }).isRequired,
  create: PropTypes.bool.isRequired,
};

export default CollectionPlaceFormUI;
