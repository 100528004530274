import React from 'react';
import PropTypes from 'prop-types';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

import { generateReactKey } from 'Shared/utilities';

const LEFT_SYMBOL = 'LEFT';
const RIGHT_SYMBOL = 'RIGHT';
const SPACE_SYMBOL = 'SPACE';

class PaginationComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: props.currentPage,
      totalPages: props.totalPages,
      pageNeighbours: 3,
    };
  }

  static getRange(from, to) {
    return [...Array(to - from + 1)].map((_, i) => from + i);
  }

  componentDidMount() {
    this.fetchData(true);
  }

  componentDidUpdate() {
    this.fetchData();
  }

  getPages() {
    const { currentPage, totalPages, pageNeighbours } = this.state;
    const startPage = Math.max(2, currentPage - pageNeighbours);
    const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);
    const hasLeftSpill = startPage > 2;
    const hasRightSpill = (totalPages - endPage) > 1;
    let pages = PaginationComponent.getRange(startPage, endPage);

    if (hasLeftSpill && !hasRightSpill) {
      pages = [LEFT_SYMBOL, 1, SPACE_SYMBOL, ...pages, totalPages, RIGHT_SYMBOL];
    } else if (!hasLeftSpill && hasRightSpill) {
      pages = [LEFT_SYMBOL, 1, ...pages, SPACE_SYMBOL, totalPages, RIGHT_SYMBOL];
    } else if (totalPages > 5) {
      pages = [
        LEFT_SYMBOL,
        1,
        SPACE_SYMBOL,
        ...pages,
        SPACE_SYMBOL,
        totalPages,
        RIGHT_SYMBOL,
      ];
    } else {
      pages = [LEFT_SYMBOL, 1, ...pages, totalPages, RIGHT_SYMBOL];
    }

    return [...pages];
  }

  handleClick = (page) => (evt) => {
    evt.preventDefault();
    this.gotoPage(page);
  };

  handleMoveLeft = (evt) => {
    const { currentPage } = this.state;

    evt.preventDefault();
    this.gotoPage(currentPage - 1);
  };

  handleMoveRight = (evt) => {
    const { currentPage } = this.state;

    evt.preventDefault();
    this.gotoPage(currentPage + 1);
  };

  gotoPage(page) {
    const { onPageChanged } = this.props;
    const { totalPages, pageNeighbours } = this.state;

    onPageChanged(page);

    const currentPage = Math.max(0, Math.min(page, totalPages));

    const paginationData = {
      currentPage,
      totalPages,
      pageNeighbours,
    };

    this.setState(paginationData);
  }

  fetchData(mount = false) {
    const { totalPages } = this.state;
    const { totalPages: propTotalPages = null, currentPage } = this.props;

    if ((mount) || (totalPages && totalPages !== propTotalPages)) {
      const { pageNeighbours = 0 } = this.props;

      const paginationData = {
        currentPage,
        totalPages: propTotalPages,
        pageNeighbours,
      };

      this.setState(paginationData);
    }
  }

  render() {
    const { currentPage, totalPages } = this.state;

    if (!totalPages || totalPages === 1) return null;

    const pages = this.getPages();

    const content = pages.map((page, index) => {
      if (page === LEFT_SYMBOL) {
        return (
          <PaginationItem key={generateReactKey(index)} disabled={currentPage === 1}>
            <PaginationLink previous onClick={this.handleMoveLeft} />
          </PaginationItem>
        );
      }

      if (page === SPACE_SYMBOL) {
        return (
          <PaginationItem key={generateReactKey(index)} className="d-flex px-2 align-items-end font-weight-bold">
            &hellip;
          </PaginationItem>
        );
      }

      if (page === RIGHT_SYMBOL) {
        return (
          <PaginationItem key={generateReactKey(index)} disabled={currentPage === totalPages}>
            <PaginationLink next onClick={this.handleMoveRight} />
          </PaginationItem>
        );
      }

      return (
        <PaginationItem key={generateReactKey(index)} active={currentPage === page}>
          <PaginationLink onClick={this.handleClick(page)}>
            {page}
          </PaginationLink>
        </PaginationItem>
      );
    });

    return (
      <Pagination aria-label="Stránkování">
        {content}
      </Pagination>
    );
  }
}

PaginationComponent.propTypes = {
  totalPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number,
  pageNeighbours: PropTypes.number,
  onPageChanged: PropTypes.func.isRequired,
};

PaginationComponent.defaultProps = {
  pageNeighbours: 3,
  currentPage: 1,
};

export default PaginationComponent;
