import React from 'react';
import { Card, CardBody, Row, Col, FormGroup, Label, InputGroup, InputGroupAddon } from 'reactstrap';
import { connect } from 'formik';
import { useTranslation } from 'react-i18next';

import transformDecimals from '~/services/transformDecimals';
import CurrencySelect from '#/Components/Containers/CurrencySelect/CurrencySelect';
import Packages from './Packages/Packages';
import InputField from '~/components/InputField/InputField';
import { DeliveryDetailsValues } from '../types';

type Props = Record<string, unknown>;

const Details = connect<Props, DeliveryDetailsValues>(({ formik }) => {
  const { t } = useTranslation(['deliveries', 'validation']);

  const { values, setFieldValue } = formik;

  return (
    <Card className="shadow border-0 mb-4">
      <CardBody>
        <Row>
          <Col md="4">
            <h4>{t('deliveries:NewDelivery.Delivery.title')}</h4>
          </Col>

          <Col md="6">
            <div className="form-row">
              <FormGroup className="col-md-6 append-form-group">
                <Label
                  htmlFor="packageValue"
                  style={{ display: 'block' }}
                >
                  {t('deliveries:NewDelivery.Delivery.DeliveryValue.label')}
                </Label>

                <InputGroup className="input-appended">
                  <InputField
                    name="packageValue"
                    placeholder="0.00"
                    inputMode="numeric"
                    onBlur={(): void => {
                      setFieldValue('packageValue', transformDecimals(values.packageValue), false);
                    }}
                  />

                  <InputGroupAddon addonType="append">
                    <CurrencySelect
                      name="valueCurrency"
                      value={values.valueCurrency}
                      onChange={setFieldValue}
                    />
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>

              <FormGroup className="col-md-6 append-form-group">
                <Label
                  htmlFor="packageCod"
                  style={{ display: 'block' }}
                >
                  {t('deliveries:NewDelivery.Delivery.CashOnDelivery.label')}
                </Label>

                <InputGroup className="input-appended ">
                  <InputField
                    name="packageCod"
                    placeholder="0.00"
                    inputMode="numeric"
                    onBlur={(): void => {
                      setFieldValue('packageCod', transformDecimals(values.packageCod), false);
                    }}
                  />

                  <InputGroupAddon addonType="append">
                    <CurrencySelect
                      name="cashOnDeliveryCurrency"
                      value={values.cashOnDeliveryCurrency}
                      onChange={setFieldValue}
                    />
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </div>

            <FormGroup>
              <InputField
                label={t('deliveries:NewDelivery.Delivery.ExternalId.label')}
                name="packageExternalId"
              />
            </FormGroup>

            <FormGroup>
              <InputField
                label={t('deliveries:NewDelivery.Delivery.VariableSymbol.label')}
                name="packageVariableSymbol"
              />
            </FormGroup>

            <FormGroup>
              <InputField
                label={t('validation:TicketLabel.label')}
                name="packageLabelText"
              />
            </FormGroup>
          </Col>
        </Row>

        <Row className="pt-5">
          <Col md="4">
            <h4 className="text-nowrap">
              {t('deliveries:NewDelivery.Packages.title')}
            </h4>
          </Col>

          <Col md="6">
            <Packages />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
});

export default Details;
