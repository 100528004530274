import AuthenticationHelper from '~/services/Auth';
import configurator from '~/services/Configurator';

export const uploadImage = async (image: string | number | File | null | undefined, entityName?: string): Promise<any> => {
  const { restApiUrl } = configurator.config;

  if (image && typeof image !== 'string' && typeof image !== 'number' && image.type.match('image.*')) {
    const buffer = await image.arrayBuffer();
    const requestBody = new Uint8Array(buffer);

    return fetch(`${restApiUrl}/v4/images`, {
      method: 'PUT',
      body: requestBody,
      headers: new Headers({
        'Content-Type': 'application/octet-stream',
        Authorization: `graphql ${AuthenticationHelper.accessToken}`,
      }),
    }).then((response) => {
      if (entityName) {
        return Promise.resolve({ [entityName]: response.json() });
      }

      return response.json();
    });
  }

  return Promise.resolve();
};
