import { TFunction } from 'i18next';
import { object, string } from 'yup';

export const schema = (t: TFunction) => {
  return object().shape({
    name: string()
      .required(t('settings:Eshop.General.Name.required'))
      .max(128, t('settings:Eshop.General.Name.maxLength', { count: 128 })),
    webUrl: string()
      .url(t('validation:Url.pattern'))
      .required(t('settings:Eshop.General.WebUrl.required'))
      .max(255, t('validation:Url.maxLength', { count: 255 })),
    facebookUrl: string()
      .url(t('validation:Url.pattern'))
      .max(255, t('validation:Url.maxLength', { count: 255 })),
    instagramUrl: string()
      .url(t('validation:Url.pattern'))
      .max(255, t('validation:Url.maxLength', { count: 255 })),
    twitterUrl: string()
      .url(t('validation:Url.pattern'))
      .max(255, t('validation:Url.maxLength', { count: 255 })),
    linkedInUrl: string()
      .url(t('validation:Url.pattern'))
      .max(255, t('validation:Url.maxLength', { count: 255 })),
    youtubeUrl: string()
      .url(t('validation:Url.pattern'))
      .max(255, t('validation:Url.maxLength', { count: 255 })),
    homepageUrl: string()
      .url(t('validation:Url.pattern'))
      .max(255, t('validation:Url.maxLength', { count: 255 })),
    contactUrl: string()
      .url(t('validation:Url.pattern'))
      .max(255, t('validation:Url.maxLength', { count: 255 })),
    termsAndConditionsUrl: string()
      .url(t('validation:Url.pattern'))
      .max(255, t('validation:Url.maxLength', { count: 255 })),
    privacyPolicyUrl: string()
      .url(t('validation:Url.pattern'))
      .max(255, t('validation:Url.maxLength', { count: 255 })),
    supportEmail: string()
      .email(t('validation:Email.pattern'))
      .max(255, t('validation:Email.maxLength', { count: 255 })),
    // TODO: Phone validation?
    supportPhone: string(),
  });
};
