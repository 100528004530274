import { useState } from 'react';

function useLockedInputGroup(enabled = true) {
  const [isLocked, setLocked] = useState(enabled);

  const toggleLock = () => {
    if (enabled) {
      setLocked(!isLocked);
    }
  };

  return {
    toggleLock,
    isLocked,
  };
}

export {
  // eslint-disable-next-line import/prefer-default-export
  useLockedInputGroup,
};
