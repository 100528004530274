import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import Select from 'react-select';

import withValidation from 'Components/Presentational/FormExtensions/HoC/withValidation/withValidation';

const SelectWithValidation = withValidation(Select);

const AgentPicker = ({
  formik,
  onAgentChange,
  agents,
  disabled,
}) => {
  const { t } = useTranslation('settings');
  const { setFieldValue, values, resetForm } = formik;
  const renderAgents = () => agents.map((agent) => ({
    value: agent.id,
    label: agent.name,
  }));

  return (
    <FormGroup>
      <div className="form-row">
        <Col md="4">
          <h4>{t('Agents.AgentPicker.title')}</h4>
        </Col>
        <Col md="6">
          <Label>{t('Agents.AgentPicker.label')}</Label>
          <SelectWithValidation
            name="agent"
            classNamePrefix="select"
            onChange={(option, select) => {
              onAgentChange(option, select, resetForm, setFieldValue);
            }}
            value={values.agent}
            options={renderAgents()}
            noOptionsMessage={() => `${t('Agents.AgentPicker.noOptionsMessage')}`}
            isDisabled={disabled}
            placeholder={t('Agents.AgentPicker.selectPlaceholder')}
          />
        </Col>
      </div>
    </FormGroup>
  );
};

AgentPicker.propTypes = {
  disabled: PropTypes.bool.isRequired,
  formik: PropTypes.shape({}).isRequired,
  onAgentChange: PropTypes.func.isRequired,
  agents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default AgentPicker;
