import { TFunction } from 'i18next';
import { mixed, object, string, array } from 'yup';

export const validationSchema = (t: TFunction) => {
  return object().shape({
    campaignName: string()
      .required(t('settings:Branding.MarketingCampaigns.Form.Validation.CampaignName.required'))
      .max(255, t('settings:Branding.MarketingCampaigns.Form.Validation.CampaignName.maxLength', { count: 255 })),
    linkWeb: string()
      .url(t('validation:Url.pattern'))
      .max(255, t('validation:Url.maxLength', { count: 255 }))
      .when('isTrackAndTraceActive', {
        is: true,
        then: string().required(t('settings:Branding.MarketingCampaigns.Form.Validation.campaignLinkWeb')),
      }),
    linkEmail: string()
      .url(t('validation:Url.pattern'))
      .max(255, t('validation:Url.maxLength', { count: 255 }))
      .when('isEmailCommunicationActive', {
        is: true,
        then: string().required(t('settings:Branding.MarketingCampaigns.Form.Validation.campaignLinkEmail')),
      }),
    defaultEmailBannerImage: mixed().when('isEmailCommunicationActive', {
      is: true,
      then: mixed().required(t('validation:Default.Image.required')),
    }),
    defaultDesktopBannerImage: mixed().when('isTrackAndTraceActive', {
      is: true,
      then: mixed().required(t('validation:Default.Image.required')),
    }),
    defaultMobileBannerImage: mixed(),
    eshopCampaignBanners: array()
      .of(object().shape({
        country: string().required(t('validation:CountrySelect.required')),
        linkWeb: string()
          .required(t('settings:Branding.MarketingCampaigns.Form.Validation.campaignLinkWeb'))
          .url(t('validation:Url.pattern'))
          .max(255, t('validation:Url.maxLength', { count: 255 })),
        linkEmail: string()
          .required(t('settings:Branding.MarketingCampaigns.Form.Validation.campaignLinkEmail'))
          .url(t('validation:Url.pattern'))
          .max(255, t('validation:Url.maxLength', { count: 255 })),
        emailCommunicationBannerImage: mixed().when('isEmailCommunicationActive', {
          is: true,
          then: mixed().required(t('validation:Default.Image.required')),
        }),
        trackAndTraceDesktopBannerImage: mixed().when('isTrackAndTraceActive', {
          is: true,
          then: mixed().required(t('validation:Default.Image.required')),
        }),
        trackAndTraceMobileBannerImage: mixed().when('isTrackAndTraceActive', {
          is: true,
          then: mixed().required(t('validation:Default.Image.required')),
        }),
      })),
  });
};
