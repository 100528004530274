import React from 'react';
import { Container, Row, Col, Form, Button, CustomInput, FormGroup, Label, Spinner } from 'reactstrap';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useAuthMutation from '~/hooks/useAuthMutation';

import { formatPhoneNumber } from '~/services/utils';
import {
  AccountFragment,
  EnumDeliveryAccess,
  CreateAccountMutation,
  CreateAccountMutationVariables,
  UpdateAccountMutation,
  UpdateAccountMutationVariables,
  Option,
} from '../types';
import InputField from '~/components/InputField/InputField';
import SelectField from '../../../Deliveries/New/SelectField/SelectField';
import { schema } from './validationSchema';
import { toast } from '#/shared/utilities';

import CREATE_ACCOUNT from './createAccount.gql';
import UPDATE_ACCOUNT from './updateAccount.gql';

type FormValues = {
  name: string;
  surname: string;
  email: string;
  phone: string;
  protocolAccess: boolean;
  deliveryAccess: EnumDeliveryAccess;
}

type Props = {
  account?: AccountFragment;
  loading?: boolean;
  edit?: boolean;
};

const CreateUserForm: React.FC<Props> = ({
  account,
  loading,
  edit,
}) => {
  const { t } = useTranslation(['settings', 'validation']);
  const history = useHistory();

  const [createAccount, { loading: createLoading }] = useAuthMutation<CreateAccountMutation, CreateAccountMutationVariables>(CREATE_ACCOUNT);
  const [updateAccount, { loading: updateLoading }] = useAuthMutation<UpdateAccountMutation, UpdateAccountMutationVariables>(UPDATE_ACCOUNT);
  // Permissions can be changed either when editing a secondary (non-primary) user or creating a new user
  const canChangePermissions = edit === true ? !account?.primaryAccess : true;
  const deliveryAccessOptions: Option<EnumDeliveryAccess>[] = [
    {
      label: t('settings:Users.Form.DeliveryAccess.Options.all'),
      value: 'all',
    },
    {
      label: t('settings:Users.Form.DeliveryAccess.Options.own'),
      value: 'own',
    },
  ];

  const handleCreateAccount = async (variables: CreateAccountMutationVariables) => {
    await createAccount({
      variables,
    });
    toast.success(t('settings:Users.Banner.newUserSuccess'));
  };

  const handleEditAccount = async (variables: UpdateAccountMutationVariables) => {
    await updateAccount({
      variables,
    });
    const fullName = `${account?.contact?.name} ${account?.contact?.surname}`;
    toast.success(t('settings:Users.Banner.editUserSuccess', { userName: fullName }));
  };

  const handleSubmit = async (values: FormValues) => {
    if (createLoading || updateLoading) {
      return;
    }
    try {
      const mutationVariables: CreateAccountMutationVariables = {
        account: {
          firstname: values.name,
          surname: values.surname,
          email: values.email,
          phone: formatPhoneNumber(values.phone),
          protocolAccess: values.protocolAccess,
          deliveryAccessType: values.deliveryAccess,
        },
      };
      if (edit && account?.id) {
        const updateMutationVariables: UpdateAccountMutationVariables = {
          ...mutationVariables,
          id: account?.id,
        };
        await handleEditAccount(updateMutationVariables);
      } else {
        await handleCreateAccount(mutationVariables);
      }
      history.push('/settings/users');
    } catch (e) {
      // Handled in global error interceptor
    }
  };

  let content = (
    <Formik
      initialValues={{
        name: account?.contact?.name ?? '',
        surname: account?.contact?.surname ?? '',
        email: account?.contact?.email ?? '',
        phone: account?.contact?.phone ?? '',
        deliveryAccess: account?.deliveryAccessType ?? 'all',
        protocolAccess: account?.protocolAccess ?? false,
      }}
      onSubmit={handleSubmit}
      validationSchema={schema(t)}
    >
      {/* eslint-disable-next-line no-shadow */}
      {({ handleSubmit, isSubmitting }) => (
        <Form
          onSubmit={handleSubmit}
          noValidate
          className="text-left"
        >
          <Row>
            <Col xs={12} md={4}>
              <h4 className="mb-3 subheadline">
                {t('settings:Users.Form.Subtitle.userInfo')}
              </h4>
              <p className="font-weight-normal">
                {t('settings:Users.Form.Description.userInfo')}
              </p>
            </Col>

            <Col
              xs={12}
              md={{ size: 7, offset: 1 }}
              lg={5}
              className="pt-3 pt-md-0"
            >
              <FormGroup>
                <Label>{t('validation:Name.label')}</Label>
                <InputField
                  name="name"
                  type="text"
                  placeholder={t('validation:Name.placeholder')}
                />
              </FormGroup>
              <FormGroup>
                <Label>{t('validation:Surname.label')}</Label>
                <InputField
                  name="surname"
                  type="text"
                  placeholder={t('validation:Surname.placeholder')}
                />
              </FormGroup>
              <FormGroup>
                <Label>{t('validation:Email.label')}</Label>
                <InputField
                  name="email"
                  type="email"
                  placeholder={t('validation:Email.placeholder')}
                />
              </FormGroup>
              <FormGroup>
                <Label>{t('validation:Phone.label')}</Label>
                <InputField
                  name="phone"
                  type="text"
                  placeholder={t('validation:Phone.placeholder')}
                />
              </FormGroup>
            </Col>
          </Row>

          {canChangePermissions && (
            <Row className="mt-4">
              <Col xs={12} md={4}>
                <h4 className="mb-3 subheadline">
                  {t('settings:Users.Form.Subtitle.permissions')}
                </h4>
                <p className="font-weight-normal">
                  {t('settings:Users.Form.Description.permissions')}
                </p>
              </Col>

              <Col
                xs={12}
                md={{ size: 7, offset: 1 }}
                lg={5}
                className="pt-3 pt-md-0"
              >
                <FormGroup>
                  <div className="d-flex flex-column">
                    <SelectField
                      options={deliveryAccessOptions}
                      name="deliveryAccess"
                      label={t('settings:Users.Form.DeliveryAccess.label')}
                    />
                  </div>
                </FormGroup>

                <FormGroup>
                  <CustomInput
                    id="protocolAccess"
                    name="protocolAccess"
                    type="checkbox"
                    label={t('settings:Users.Form.ProtocolAccess.label')}
                  />
                </FormGroup>
              </Col>
            </Row>
          )}

          <Row className="mt-4">
            <Col className="text-center">
              <Button
                color="primary"
                type="submit"
                disabled={isSubmitting}
              >
                {edit
                  ? t('settings:Users.Form.updateUserButton')
                  : t('settings:Users.Form.createUserButton') }
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );

  if (loading) {
    content = (
      <div className="d-flex justify-content-center">
        <Spinner />
      </div>
    );
  }

  return (
    <Container className="px-0 pb-3">
      <Row>
        <Col className="right-col-spacing">
          <h1 className="h4 font-weight-semibold mb-6">
            {edit
              ? t('settings:Users.Form.Title.editUser')
              : t('settings:Users.Form.Title.newUser') }
          </h1>
        </Col>
      </Row>
      {content}
    </Container>
  );
};

export default CreateUserForm;
