import React from 'react';
import { Formik } from 'formik';
import fp from 'lodash/fp';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import { generateReactKey, toast } from 'Shared/utilities';
import { customManualMutation } from 'Models/Components/CustomMutation';
import CollectionPlaceQueryFactory
  from 'Models/collectionPlace/Query/Factories/CollectionPlaceQueryFactory';
import CollectionPlacesUI from '../../Presentational/CollectionPlacesUI/CollectionPlacesUI';
import withFormStore from '../../HoC/withFormStore/withFormStore';
import createValidationSchema from './validationSchema';

const CollectionPlacesWithGlobalStore = withFormStore(CollectionPlacesUI);
const EmptyFormWithGlobalStore = withFormStore(Input);

class CollectionPlaces extends React.Component {
  constructor(props) {
    super(props);

    this.collectionPlaces = this.getCollectionPlaces();
    this.state = { isRefreshLoading: false };
  }

  getCollectionPlaces() {
    const { collectionPlaces, credentialCollectionPlaces } = this.props;
    const getIdsFromCollectionPlaces = (collectionPlace) => collectionPlace.map((cp) => cp.id);
    const credentialCollectionPlaceIds = getIdsFromCollectionPlaces(credentialCollectionPlaces);
    const collectionPlaceIds = getIdsFromCollectionPlaces(collectionPlaces);

    const uncheckedCollectionPlaceIds = fp.difference(
      collectionPlaceIds,
      credentialCollectionPlaceIds,
    );

    const checkedCollectionPlaceIds = fp.difference(
      collectionPlaceIds,
      uncheckedCollectionPlaceIds,
    );

    const formatCpForInitialValues = (collectionPlace) => {
      const credentialCollectionPlace = credentialCollectionPlaces.find((cp) => cp.id === collectionPlace.id);
      const regular = get(credentialCollectionPlace, 'regular') === true ? 'regular' : 'automatic';
      const note = get(credentialCollectionPlace, 'note');
      const externalPlaceId = get(credentialCollectionPlace, 'externalPlaceId');
      const postCode = get(credentialCollectionPlace, 'postCode');

      return {
        id: collectionPlace.id,
        regularId: generateReactKey(),
        checked: checkedCollectionPlaceIds.includes(collectionPlace.id),
        regular,
        note: note || null,
        externalPlaceId,
        name: collectionPlace.name,
        postCode,
        ...collectionPlace,
      };
    };

    const formatCollectionPlacesForFormik = fp.compose(fp.map(formatCpForInitialValues));

    return formatCollectionPlacesForFormik(collectionPlaces.filter((cp) => cp.active === true));
  }

  initValidation = (values) => {
    const {
      active,
      isExternalPlaceIdHidden,
      isPostCodeHidden,
      t,
    } = this.props;
    let errors = {};

    if (active) {
      errors = createValidationSchema(values, isExternalPlaceIdHidden, isPostCodeHidden, t);
    }

    return errors;
  };

  async refreshCollectionPlaces() {
    const { isRefreshLoading } = this.state;
    const { agentId, t, refetchCollectionPlaces } = this.props;

    if (isRefreshLoading) {
      return;
    }

    this.setState({ isRefreshLoading: true });

    try {
      await customManualMutation({
        mutation: CollectionPlaceQueryFactory.updateCollectionPlacesFromAgent(),
        variables: { agentId },
      });

      refetchCollectionPlaces();
      toast.success(t('settings:Agents.CollectionPlaces.refreshSuccess'));
    } catch (e) {
      toast.error(t('settings:Agents.CollectionPlaces.refreshFail'));
    } finally {
      this.setState({ isRefreshLoading: false });
    }
  }

  render() {
    const {
      active,
      isExternalPlaceIdHidden,
      isPostCodeHidden,
      isCollectionPlaceUpdateSupported,
      supportsPickup,
      agentId,
    } = this.props;

    const { isRefreshLoading } = this.state;

    return (
      <Formik
        initialValues={{ collectionPlaces: this.collectionPlaces }}
        onSubmit={this.initValidation}
        validate={this.initValidation}
      >
        {(formikProps) => {
          let form = (
            <EmptyFormWithGlobalStore
              name="collectionPlaces"
              formik={formikProps}
              style={{ display: 'none' }}
            />
          );

          if (active) {
            form = (
              <CollectionPlacesWithGlobalStore
                name="collectionPlaces"
                formik={formikProps}
                onCheckboxChange={this.handleCheckboxChange}
                validate={this.validateCollectionPlaceCheckboxes}
                isExternalPlaceIdHidden={isExternalPlaceIdHidden}
                isPostCodeHidden={isPostCodeHidden}
                isCollectionPlaceUpdateSupported={isCollectionPlaceUpdateSupported}
                supportsPickup={supportsPickup}
                agentId={agentId}
                refreshCollectionPlaces={() => this.refreshCollectionPlaces()}
                isRefreshLoading={isRefreshLoading}
              />
            );
          }

          return form;
        }}
      </Formik>
    );
  }
}

export default withTranslation(['settings'])(CollectionPlaces);

CollectionPlaces.propTypes = {
  collectionPlaces: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  credentialCollectionPlaces: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isExternalPlaceIdHidden: PropTypes.bool.isRequired,
  isPostCodeHidden: PropTypes.bool.isRequired,
  isCollectionPlaceUpdateSupported: PropTypes.bool.isRequired,
  supportsPickup: PropTypes.bool.isRequired,
  agentId: PropTypes.string.isRequired,
  refetchCollectionPlaces: PropTypes.func.isRequired,
};
