/* eslint-disable max-len */
import stringPropertyIsSet from 'Containers/Settings/Branding/helpers/stringPropertyIsSet';
import { readableColor } from 'polished';
import React from 'react';

const regexForValidHex = /^#([0-9a-f]{3}){1,2}$/i;

export type DeliveryRatingPreviewSettings = {
  primaryColor?: string;
  tagColor?: string;
  tagBackgroundColor?: string;
  selectedTagColor?: string;
  selectedTagBackgroundColor?: string;
}

type Props = {
  className?: string;
  onClick?: () => void;
  previewSettings?: DeliveryRatingPreviewSettings;
};

export const resolveDeliveryRatingColors = (previewSettings: DeliveryRatingPreviewSettings | null): DeliveryRatingPreviewSettings => {
  const tagColor = previewSettings?.tagColor || previewSettings?.primaryColor || undefined;
  const selectedTagBackgroundColor = previewSettings?.selectedTagBackgroundColor || previewSettings?.primaryColor || undefined;
  const selectedTagColor = previewSettings?.selectedTagColor || (selectedTagBackgroundColor && selectedTagBackgroundColor.match(regexForValidHex) ? readableColor(selectedTagBackgroundColor) : undefined);

  let tagBackgroundColor = previewSettings?.tagBackgroundColor || undefined;
  if (stringPropertyIsSet(previewSettings?.primaryColor)) tagBackgroundColor = tagBackgroundColor || `${previewSettings?.primaryColor}10`;

  return { primaryColor: previewSettings?.primaryColor, tagColor, tagBackgroundColor, selectedTagColor, selectedTagBackgroundColor };
};

const DeliveryRatingPreview: React.FC<Props> = ({
  className,
  onClick,
  previewSettings,
}) => {
  const { tagColor, tagBackgroundColor, selectedTagColor, selectedTagBackgroundColor } = resolveDeliveryRatingColors(previewSettings || null);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" onClick={onClick} className={className} fillRule="evenodd" clipRule="evenodd" strokeLinecap="round" strokeLinejoin="round" viewBox="0 0 2088 1213">
      <path id="background" fill="#fff" fillOpacity=".95" d="M2087.5 25c0-13.798-11.202-25-25-25H25C11.202 0 0 11.202 0 25v1162.5c0 13.798 11.202 25 25 25h2037.5c13.798 0 25-11.202 25-25V25Z" />
      <g id="hovered-button-rectangle">
        <path fill={selectedTagBackgroundColor || '#000'} d="M1362.5 987.5c0-13.798-11.202-25-25-25H746.875c-13.798 0-25 11.202-25 25v100c0 13.798 11.202 25 25 25H1337.5c13.798 0 25-11.202 25-25v-100Z" />
        <text x="798.291" y="1054.55" fill={selectedTagColor || '#fff'} fontFamily="'Inter-SemiBold','Inter'" fontSize="46.875" fontWeight="600">Zaslat zpětnou vazbu</text>
      </g>
      <g id="delivery-rating-header">
        <foreignObject x="0" y="60" width="100%" height="80px">
          <div style={{ fontSize: '60px', fontWeight: 700, color: '#343A40', width: '100%', textAlign: 'center' }}>Jak proběhlo doručení objednávky?</div>
        </foreignObject>
        <g id="horrible">
          <text x="581.673" y="407.947" fontFamily="'Inter-Regular_SemiBold','Inter'" fontSize="39.697" fill="#343A40">Strašné</text>
          <path fill="#f3b711" fillRule="nonzero" d="M653.948 202.22c-31.467 0-57.068 25.598-57.068 57.065.003 31.463 25.601 57.065 57.068 57.065 31.463 0 57.061-25.602 57.061-57.065.004-31.467-25.598-57.065-57.061-57.065Z" />
          <path fill="#343a40" fillRule="nonzero" d="M639.062 251.656c-.004 4.109-3.338 7.444-7.447 7.444-4.105 0-7.443-3.335-7.443-7.444 0-4.108 3.338-7.443 7.443-7.443 4.109 0 7.443 3.338 7.443 7.443h.004Zm44.659 0c-.003 4.109-3.338 7.444-7.447 7.444-4.105 0-7.44-3.335-7.44-7.444-.003-4.108 3.335-7.443 7.44-7.443 4.109 0 7.447 3.338 7.447 7.443Z" />
          <path fill="none" stroke="#343a40" strokeWidth="4.96" d="m621.694 229.512 17.368 9.924m47.14-9.924-17.371 9.924m-31.01 37.216c3.182-1.584 9.885-2.56 16.974-2.477 6.51.076 12.382 1.028 15.28 2.477" />
        </g>
        <g id="bad">
          <text x="780.399" y="407.273" fontFamily="'Inter-Regular_SemiBold','Inter'" fontSize="39.697" fill="#343A40">Špatné</text>
          <path fill="#f3b711" fillRule="nonzero" d="M846.991 202.215c-31.467 0-57.068 25.598-57.068 57.064.003 31.464 25.601 57.065 57.068 57.065 31.463 0 57.061-25.601 57.061-57.065 0-31.466-25.598-57.064-57.065-57.064h.004Z" />
          <path fill="#343a40" fillRule="nonzero" d="M832.104 246.874c-.003 4.109-3.338 7.443-7.446 7.443-4.106 0-7.44-3.334-7.44-7.443-.004-4.109 3.334-7.443 7.44-7.443 4.108 0 7.446 3.334 7.446 7.443Zm44.659 0c0 4.109-3.337 7.443-7.443 7.443-4.108 0-7.443-3.334-7.443-7.443s3.335-7.443 7.443-7.443c4.106 0 7.443 3.334 7.443 7.443Z" />
          <path fill="none" stroke="#343a40" strokeWidth="4.96" d="M827.142 284.09c3.92-6.348 12.164-10.262 20.891-9.934 8.012.298 15.237 4.125 18.806 9.934" />
        </g>
        <g id="not-bad">
          <text x="966.512" y="407.549" fontFamily="'Inter-Regular_SemiBold','Inter'" fontSize="39.697" fill="#343A40">Nic moc</text>
          <path fill="#f3b711" fillRule="nonzero" d="M1044.03 202.63c-31.467 0-57.065 25.598-57.065 57.065 0 31.463 25.598 57.064 57.065 57.064 31.463 0 57.065-25.601 57.065-57.064 0-31.467-25.602-57.065-57.065-57.065Z" />
          <path fill="none" stroke="#343a40" strokeWidth="4.96" d="M1021.7 277.062h44.66" />
          <path fill="#343a40" fillRule="nonzero" d="M1029.15 247.289c0 4.109-3.338 7.444-7.444 7.444-4.108 0-7.443-3.335-7.443-7.444 0-4.108 3.335-7.443 7.443-7.443 4.106 0 7.444 3.335 7.444 7.443Zm44.659 0c0 4.109-3.338 7.444-7.443 7.444-4.109 0-7.443-3.335-7.443-7.444 0-4.108 3.334-7.443 7.443-7.443 4.105 0 7.443 3.335 7.443 7.443Z" />
        </g>
        <g id="good">
          <text x="1178.85" y="407.785" fontFamily="'Inter-Regular_SemiBold','Inter'" fontSize="39.697" fill="#343A40">Dobré</text>
          <path fill="#f3b711" fillRule="nonzero" d="M1233.04 316.523c31.516 0 57.064-25.552 57.064-57.065 0-31.519-25.548-57.064-57.064-57.064-31.516 0-57.065 25.545-57.065 57.064 0 31.513 25.549 57.065 57.065 57.065Z" />
          <path fill="none" stroke="#343a40" strokeMiterlimit="4" strokeWidth="4.96" d="M1257.39 281.613c-7.195 4.059-15.502 6.378-24.355 6.378-8.849 0-17.155-2.319-24.35-6.378" />
          <path fill="#343a40" fillRule="nonzero" d="M1218.15 247.053c0 4.109-3.338 7.443-7.443 7.443-4.109 0-7.443-3.334-7.443-7.443s3.334-7.443 7.443-7.443c4.105 0 7.443 3.334 7.443 7.443Zm44.66 0c0 4.109-3.338 7.443-7.444 7.443-4.108 0-7.443-3.334-7.443-7.443s3.335-7.443 7.443-7.443c4.106 0 7.444 3.334 7.444 7.443Z" />
        </g>
        <g id="great">
          <text x="1347.06" y="407.707" fontFamily="'Inter-Regular_SemiBold','Inter'" fontSize="39.697" fill="#343A40">Skvělé</text>
          <path fill="#f3b711" fillRule="nonzero" d="M1409.04 316.59c31.517 0 57.065-25.548 57.065-57.065 0-31.519-25.548-57.064-57.065-57.064-31.519 0-57.064 25.545-57.064 57.064 0 31.517 25.545 57.065 57.064 57.065Z" />
          <path fill="#fff" fillRule="nonzero" d="M1445.25 273.519c.03 3.821-.705 7.608-2.16 11.141-30.987 7.516-63.092.844-68.203-.324-1.386-3.437-2.088-7.112-2.061-10.817h.274s36.72 8.908 71.677.175l.473-.175Z" />
          <path fill="#ea5a47" fillRule="nonzero" d="M1443.09 284.657c-4.44 10.596-15.754 17.94-33.965 17.94-18.386 0-29.849-7.519-34.238-18.261 5.111 1.165 37.216 7.84 68.203.321Z" />
          <path fill="#343a40" fillRule="nonzero" d="M1390.6 260.075c4.109 0 7.443-6.005 7.443-13.408 0-7.407-3.334-13.408-7.443-13.408s-7.443 6.001-7.443 13.408c0 7.403 3.334 13.408 7.443 13.408Zm36.879.009c4.105 0 7.443-6.004 7.443-13.407 0-7.407-3.338-13.408-7.443-13.408-4.112 0-7.443 6.001-7.443 13.408 0 7.403 3.331 13.407 7.443 13.407Z" />
          <path fill="none" stroke="#343a40" strokeMiterlimit="4" strokeWidth="4.96" d="M1445.25 273.519c.03 3.821-.705 7.608-2.16 11.141-30.987 7.516-63.092.844-68.203-.324-1.386-3.437-2.088-7.112-2.061-10.817h.274s36.72 8.908 71.677.175l.473-.175Z" />
          <path fill="none" stroke="#343a40" strokeMiterlimit="4" strokeWidth="4.96" d="M1443.09 284.657c-4.44 10.596-15.754 17.94-33.965 17.94-18.386 0-29.849-7.519-34.238-18.261 5.111 1.165 37.216 7.84 68.203.321Z" />
        </g>
      </g>
      <g id="button-add-comment">
        <g id="add-comment-icon" fill="none" fillRule="nonzero" stroke="#343a40" strokeWidth="6.25">
          <path id="Vector_6" d="M1115.62 769.272v36.456" />
          <path id="Vector_7" d="M1097.4 787.5h36.456" />
        </g>
        <text x="1159.46" y="804.547" fill="#17171e" fontFamily="'Inter-SemiBold','Inter'" fontSize="46.875" fontWeight="600">Přidat vlastní komentář</text>
        <path id="button-add-comment-background" fill="none" stroke="#d6dbdf" strokeLinecap="butt" strokeLinejoin="miter" strokeWidth="3.13" d="M1757.81 737.5c-.001-12.936-10.502-23.438-23.438-23.438h-700c-12.936 0-23.437 10.502-23.437 23.438v100c0 12.936 10.501 23.438 23.437 23.438h700c12.936 0 23.438-10.502 23.438-23.438l-.001-100Z" />
      </g>
      <g id="button-rounded-3">
        <path fill={tagBackgroundColor} d="M959.375 787.5c0-41.394-33.606-75-75-75h-475c-41.394 0-75 33.606-75 75s33.606 75 75 75h475c41.394 0 75-33.606 75-75Z" />
        <text x="410.691" y="804.547" fill={tagColor || '#fff'} fontFamily="'Inter-Medium','Inter'" fontSize="46.875" fontWeight="500">Informování o zásilce</text>
      </g>
      <g id="button-rounded-1">
        <path fill={tagBackgroundColor} d="M1931.25 587.5c0-41.394-33.606-75-75-75h-484.375c-41.394 0-75 33.606-75 75s33.606 75 75 75h484.375c41.394 0 75-33.606 75-75Z" />
        <text x="1372.25" y="604.547" fill={tagColor || '#fff'} fontFamily="'Inter-Medium','Inter'" fontSize="46.875" fontWeight="500">Komunikace obchodu</text>
      </g>
      <g id="button-rounded-2">
        <path fill={tagBackgroundColor} d="M1246.88 587.5c0-41.394-33.606-75-75-75H831.255c-41.394 0-75 33.606-75 75s33.606 75 75 75h340.625c41.394 0 75-33.606 75-75Z" />
        <text x="832.372" y="604.547" fill={tagColor || '#fff'} fontFamily="'Inter-Medium','Inter'" fontSize="46.875" fontWeight="500">Chování kurýra</text>
      </g>
      <g id="hovered-button-rounded-1">
        <path fill={selectedTagBackgroundColor || '#000'} d="M706.25 587.5c0-41.394-33.606-75-75-75H228.125c-41.394 0-75 33.606-75 75s33.606 75 75 75H631.25c41.394 0 75-33.606 75-75Z" />
        <text x="228.272" y="604.547" fill={selectedTagColor || '#fff'} fontFamily="'Inter-Medium','Inter'" fontSize="46.875" fontWeight="500">Rychlost doručení</text>
      </g>
    </svg>
  );
};

export default DeliveryRatingPreview;
