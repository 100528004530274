import gql from 'graphql-tag';

export default class CurrencyQueryFactory {
  static currencies() {
    return gql`
            query countries {
                __type(name: "EnumCurrency") {
                    name
                    enumValues {
                        name
                    }
                }
            }`;
  }
}
