import gql from 'graphql-tag';

export default class CollectionPlaceQueryFactory {
  static collectionPlaceById(fields, fragments) {
    return gql`
            mutation collectionPlaceById($id: ID!) {
                collectionPlaceById(id: $id) {
                    ${fields}
                }
            }
            ${fragments}`;
  }

  static deactivateCollectionPlace(fields, fragments) {
    return gql`
            mutation deactivateCollectionPlace($id: ID!) {
                deactivateCollectionPlace(id: $id) {
                    ${fields}
                }
            }
            ${fragments}`;
  }

  static activateCollectionPlace(fields, fragments) {
    return gql`
            mutation activateCollectionPlace($id: ID!) {
                activateCollectionPlace(id: $id) {
                    ${fields}
                }
            }
            ${fragments}`;
  }

  static collectionPlaces(fields, fragments) {
    return gql`
            query collectionPlaces {
                collectionPlaces {
                    ${fields}
                }
            }
            ${fragments}`;
  }

  static updateCollectionPlacesFromAgent() {
    return gql`
        mutation updateCollectionPlacesFromAgent($agentId: ID!) {
            updateCollectionPlacesFromAgent(agentId: $agentId) {
                id
                name
                active
            }
        }
    `;
  }
}
