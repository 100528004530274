import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import {
  Col,
  Button,
  Container,
  Row,
  Form,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { updateObject, toast } from 'Shared/utilities';
import * as actions from 'StoreActions/user.action';
import Loader from 'Containers/Loader/Loader';
import SettingsBase from 'Containers/Settings/MyProfile/_base/SettingsBase';
import SettingsLayout from 'Components/Settings/SettingsLayout';
import { phoneNumber } from 'Shared/formValidationRules/formValidationRules';
import FormikScroll from '~/components/FormikScroll/FormikScroll';
import auth from '../../../../core/models/authentication/AuthenticationDataSource';

import css from './MyProfile.module.scss';

Yup.addMethod(Yup.string, 'phoneNumber', phoneNumber);

class Company extends SettingsBase {
  constructor(props) {
    super(props);

    this.setPasswordHidden = this.setPasswordHidden.bind(this);
    this.setPasswordVisible = this.setPasswordVisible.bind(this);
    this.submitForms = this.submitForms.bind(this);
    this.name = React.createRef();
    this.surname = React.createRef();
    this.email = React.createRef();
    this.phone = React.createRef();
    this.currentPassword = React.createRef();
    this.newPassword = React.createRef();
  }

  componentDidMount() {
    super.componentDidMount();
    this.fetchData();
  }

  fetchData() {
    const user = this.props.user.data;
    const state = updateObject(this.state, {
      isOpened: true,
      isPasswordVisible: false,
      userProfile: {
        id: user.id,
        name: user.name,
        surname: user.surname,
        email: user.email,
        phone: user.phone,
        protocolAccess: user.protocolAccess,
        deliveryAccessType: user.deliveryAccessType,
        currentPassword: '',
        newPassword: '',
      },
    });

    this.setState(state);
  }

  setPasswordHidden = () => {
    const state = updateObject(this.state, { isPasswordVisible: false });
    this.setState(state);
  }

  setPasswordVisible = () => {
    const state = updateObject(this.state, { isPasswordVisible: true });
    this.setState(state);
  }

  contactForm = ({
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
  }) => {
    const { t } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        <FormikScroll />
        <h1 className="pb-5">
          {t('settings:Profile.title')}
        </h1>
        <Row>
          <Col md="4">
            <h4>
              {t('settings:Profile.personalInformation')}
            </h4>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label htmlFor="name" style={{ display: 'block' }}>
                {t('validation:Name.label')}
              </Label>
              <Input
                name="name"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                invalid={errors.name !== undefined}
                valid={touched.name && !errors.name}
                ref={this.name}
              />
              {touched.name && errors.name && <FormFeedback>{errors.name}</FormFeedback>}
            </FormGroup>
            <FormGroup>
              <Label htmlFor="surname" style={{ display: 'block' }}>
                {t('validation:Surname.label')}
              </Label>
              <Input
                name="surname"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.surname}
                invalid={errors.surname !== undefined}
                valid={touched.surname && !errors.surname}
                ref={this.surname}
              />
              {touched.surname && errors.surname && <FormFeedback>{errors.surname}</FormFeedback>}
            </FormGroup>
            <FormGroup>
              <Label htmlFor="email" style={{ display: 'block' }}>
                {t('validation:Email.label')}
              </Label>
              <Input
                name="email"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                invalid={errors.email !== undefined}
                valid={touched.email && !errors.email}
                ref={this.email}
              />
              {touched.email && errors.email && (
                <FormFeedback>
                  {errors.email}
                </FormFeedback>
              )}
            </FormGroup>
            <FormGroup>
              <Label htmlFor="phone" style={{ display: 'block' }}>
                {t('validation:Phone.label')}
              </Label>
              <Input
                name="phone"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phone}
                invalid={errors.phone !== undefined}
                valid={touched.phone && !errors.phone}
                ref={this.phone}
              />
              {touched.phone && errors.phone && (
                <FormFeedback>
                  {errors.phone}
                </FormFeedback>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row className="pt-5" style={{ display: 'none' }}>
          <Col md="4" />
          <Col md="6">
            <Button id="contactFormSubmit" type="submit" color="primary">
              {t('settings:Profile.submitButton')}
            </Button>
          </Col>
        </Row>
      </Form>
    );
  };

  passwordForm = ({ handleSubmit, handleChange, handleBlur, values, touched, errors }) => {
    const { t } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        <Row className="pt-5">
          <Col md="4">
            <h4>
              {t('settings:Profile.changePassword')}
            </h4>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label htmlFor="currentPassword" style={{ display: 'block' }}>
                {t('settings:Profile.currentPasswordLabel')}
              </Label>
              <Input
                name="currentPassword"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.currentPassword}
                invalid={errors.currentPassword !== undefined}
                valid={touched.currentPassword && !errors.currentPassword}
                ref={this.currentPassword}
              />
              {touched.currentPassword && errors.currentPassword
              && <FormFeedback>{errors.currentPassword}</FormFeedback>}
            </FormGroup>
            <FormGroup>
              <Label htmlFor="newPassword" style={{ display: 'block' }}>
                {t('settings:Profile.newPasswordLabel')}
              </Label>
              <InputGroup className="input-appended ">
                <Input
                  name="newPassword"
                  type={this.state.isPasswordVisible ? 'text' : 'password'}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.newPassword}
                  invalid={errors.newPassword !== undefined}
                  valid={touched.newPassword && !errors.newPassword}
                  ref={this.newPassword}
                />
                <InputGroupAddon
                  addonType="append"
                >
                  <Button className={`btn ${css.btnInput}`} type="button">
                    {(this.state.isPasswordVisible)
                      ? <FontAwesomeIcon icon={['fas', 'eye']} onClick={this.setPasswordHidden} />
                      : <FontAwesomeIcon icon={['fas', 'eye-slash']} onClick={this.setPasswordVisible} />}
                  </Button>
                </InputGroupAddon>
                {touched.newPassword && errors.newPassword
                && <FormFeedback>{errors.newPassword}</FormFeedback>}
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
        <Row className="pt-5" style={{ display: 'none' }}>
          <Col md="4" />
          <Col md="6">
            <Button id="passwordFormSubmit" type="submit" color="primary">
              {t('settings:Profile.submitButton')}
            </Button>
          </Col>
        </Row>
      </Form>
    );
  };

  submitForms() {
    const getRefValue = (ref) => ref.current.props.value;

    if (
      (getRefValue(this.name) && getRefValue(this.name) !== this.state.userProfile.name)
      || (getRefValue(this.surname) && getRefValue(this.surname) !== this.state.userProfile.surname)
      || (getRefValue(this.phone) && getRefValue(this.phone) !== this.state.userProfile.phone)
      || (getRefValue(this.email) && getRefValue(this.email) !== this.state.userProfile.email)
    ) {
      document.querySelector('#contactFormSubmit').click();
    }

    if (getRefValue(this.currentPassword) && getRefValue(this.newPassword)) {
      document.querySelector('#passwordFormSubmit').click();
    }
  }

  render() {
    const { t, fetchUser } = this.props;
    const { userProfile } = this.state;
    let content = <Loader />;

    if (userProfile) {
      content = (
        <Container>
          <Formik
            initialValues={{
              name: userProfile.name,
              surname: userProfile.surname,
              email: userProfile.email,
              phone: userProfile.phone,
            }}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);

              const account = {
                firstname: values.name,
                surname: values.surname,
                phone: values.phone,
                email: values.email,
                protocolAccess: userProfile.protocolAccess,
                deliveryAccessType: userProfile.deliveryAccessType,
              };

              try {
                await auth.updateAccount(userProfile.id, account);
                toast.success(t('settings:Profile.Banner.success'));
              } catch (err) {
                toast.error(t('settings:Profile.Banner.error'));
              } finally {
                setSubmitting(false);
              }

              await fetchUser(false);
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string()
                .required(t('validation:Name.required')),
              surname: Yup.string()
                .required(t('validation:Surname.required')),
              email: Yup.string()
                .required(t('validation:Email.required'))
                .email(t('validation:Email.pattern')),
              phone: Yup.string()
                .required(t('validation:Phone.required'))
                .phoneNumber(t),
            })}
            component={this.contactForm}
          />
          <Formik
            initialValues={{
              currentPassword: '',
              newPassword: '',
            }}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              const password = {
                oldPassword: values.currentPassword,
                newPassword: values.newPassword,
              };

              try {
                const response = await auth.updatePassword(password);
                const { data } = response;
                toast.success(data.updatePassword);
                setSubmitting(false);
              } catch (e) {
                setSubmitting(false);
              }
            }}
            validationSchema={Yup.object().shape({
              currentPassword: Yup.string()
                .required(t('validation:Password.required')),
              newPassword: Yup.string()
                .required(t('validation:Password.required')),
            })}
            component={this.passwordForm}
          />

          <Row className="pt-5">
            <Col md="12" className="text-center">
              <Button type="button" color="primary" onClick={this.submitForms}>
                {t('settings:Profile.submitButton')}
              </Button>
              <Link to="/settings">
                <Button color="link" className="ml-3">
                  {t('settings:Profile.cancelButton')}
                </Button>
              </Link>
            </Col>
          </Row>
        </Container>
      );
    }

    return (
      <SettingsLayout
        leftBlock={this.state.leftBlock}
        rightBlock={this.state.rightBlock}
        checkedItems={this.state.checkedItems}
        isOpened={this.state.isOpened}
      >
        {content}
      </SettingsLayout>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return { fetchUser: (cache) => dispatch(actions.fetchUser(cache)) };
}

function mapStateToProps(state) {
  return { user: state.userReducer };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['settings', 'validation'])(Company));
