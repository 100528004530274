import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { produce } from 'immer';

import { ServiceLocatorContext } from 'Containers/ServiceLocator/ServiceLocator';
import { generateReactKey } from 'Shared/utilities';
import TooltipWithAllEditingUsers from './TooltipWithAllEditingUsers/TooltipWithAllUsers';
import TooltipWithSingleUser from './TooltipWithSingleUser/TooltipWithSingleUser';

const DeliveryUserEditViewer = ({ deliveryId, limit, className = '' }) => {
  const [users, setUsers] = useState(null);
  const { userDeliveryTracking } = useContext(ServiceLocatorContext);
  const { addUserDeliveryEditListener } = userDeliveryTracking;

  useEffect(() => {
    addUserDeliveryEditListener((data) => setUsers(data.val()), deliveryId);
  }, []);

  function renderUsers() {
    if (users === null) return null;

    const isShowingAllNamesInTooltip = limit === 1;
    const userArray = Object.keys(users).map((key) => users[key]);

    const limitedUserArray = produce(userArray, (draft) => {
      if (limit) draft.splice(limit, userArray.length);

      return draft;
    });

    const userComponents = limitedUserArray.map((user) => {
      const tooltipTarget = `preview_${generateReactKey()}`;

      return (
        <div key={user.userId} className={['initialsIconWrapper', className].join(' ')}>
          <span
            className="initialsIcon"
            id={tooltipTarget}
            title={(`${user.userName} ${user.userSurname} si prohlíží tuto zásilku`)}
          >
            {user.userName && user.userSurname && (user.userName.charAt(0).toUpperCase() + user.userSurname.charAt(0).toUpperCase())}
          </span>
          {isShowingAllNamesInTooltip ? (
            <TooltipWithAllEditingUsers
              editingUsers={userArray}
              tooltipTarget={tooltipTarget}
            />
          ) : (
            <TooltipWithSingleUser user={user} tooltipTarget={tooltipTarget} />
          )}
        </div>
      );
    });

    return (
      <>
        {userComponents}
      </>
    );
  }

  return (
    <>
      {renderUsers()}
    </>
  );
};

DeliveryUserEditViewer.defaultProps = { limit: null };
DeliveryUserEditViewer.propTypes = { limit: PropTypes.number };

export default DeliveryUserEditViewer;
