import React from 'react';
import produce from 'immer';
import { ApolloConsumer } from 'react-apollo';
import { Button, CustomInput, Spinner } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import Icon from 'Components/Presentational/Icon/Icon';
import CustomQuery from 'Models/Components/CustomQuery';
import EshopNotificationQueryFactory from 'Models/eshopNotifications/Query/Factories/EshopNotificationQueryFactory';
import EshopNotificationFragments from 'Models/eshopNotifications/Query/Fragments/EshopNotificationFragments';
import SVGIcon from 'Components/Presentational/SVGIcon/SVGIcon';
import Tooltip from 'Components/Presentational/Tooltip/Tooltip';
import { customManualMutation } from 'Models/Components/CustomMutation';
import { toast, generateReactKey } from 'Shared/utilities';

class NotificationSettingsTable extends React.Component {
  static getSettingsFromBackendData(data) {
    const { settings: eshopDeliveryNotificationSettings } = data.eshopNotificationCenter;
    return eshopDeliveryNotificationSettings;
  }

  static findNodeByPath(path, settings) {
    const splitPath = path.split('.');
    let index;
    let settingsIndex;
    let node;

    switch (splitPath.length) {
      case 1:
        node = settings.find((item) => item.code === splitPath[0]);
        break;
      case 2:
        index = settings.findIndex((item) => item.code === splitPath[0]);
        node = settings[index].items.find((item) => item.code === splitPath[1]);
        break;
      case 3:
        settingsIndex = settings.findIndex((item) => item.code === splitPath[0]);
        index = settings[settingsIndex].items.findIndex((item) => item.code === splitPath[1]);
        node = settings[settingsIndex].items[index].items.find((item) => item.code === splitPath[2]);
        break;
      default:
        break;
    }

    return node;
  }

  static getCheckedItems() {
    const items = [];
    const rows = document.querySelectorAll('#notificationSettingsTable tr[data-path]');

    rows.forEach((row) => {
      const path = row.getAttribute('data-path');
      const checkboxes = document.querySelectorAll(`#notificationSettingsTable tr[data-path='${path}'] input[checked]`);
      const checkedMethods = [];

      checkboxes.forEach((checkbox) => {
        checkedMethods.push(checkbox.getAttribute('data-method'));
      });

      items.push({
        path,
        settings: {
          sms: checkedMethods.includes('sms'),
          email: checkedMethods.includes('email'),
          important: checkedMethods.includes('important'),
        },
      });
    });

    return items;
  }

  constructor(props) {
    super(props);

    this.state = {
      isUsingInitialSettings: false,
      settings: [],
    };

    this.settingsObjectName = 'userSettings';
  }

  storeBackendDataToComponentState = (data) => {
    const { settings } = this.state;

    if (settings.length > 0) return;

    const updatedState = produce(this.state, (draft) => {
      draft.settings = NotificationSettingsTable.getSettingsFromBackendData(data);
    });

    this.setState(updatedState);
  };

  handleCheckboxChange = (e) => {
    const path = e.target.getAttribute('data-path');
    const methodKey = e.target.getAttribute('data-method');

    const updatedValue = produce(this.state, (draft) => {
      const node = NotificationSettingsTable.findNodeByPath(path, draft.settings);
      const value = node[this.settingsObjectName][methodKey];

      if (draft.isUsingInitialSettings) {
        draft.isUsingInitialSettings = false;
      }

      node[this.settingsObjectName][methodKey] = !value;
    });

    this.setState(updatedValue);
  };

  setInitialSettings = (eshopDeliveryNotificationSettings) => {
    const { isUsingInitialSettings } = this.state;

    if (!isUsingInitialSettings) {
      this.settingsObjectName = 'initialSettings';
      this.setState({
        isUsingInitialSettings: true,
        settings: eshopDeliveryNotificationSettings,
      });
    }
  };

  handleSubmit = (client) => {
    customManualMutation({
      client,
      variables: { settings: { items: NotificationSettingsTable.getCheckedItems() } },
      mutation: EshopNotificationQueryFactory.updateEshopNotificationCenter(
        '...UpdateDeliveryStatusNotifications',
        EshopNotificationFragments.updateDeliveryStatusNotifications(),
      ),
    })
      .then(() => toast.success('Nastavení úspěšně uloženo.'))
      .catch();
  };

  isCheckboxChecked(path, notificationMethodKey) {
    const { settings } = this.state;
    const node = NotificationSettingsTable.findNodeByPath(path, settings);

    return node[this.settingsObjectName] ? node[this.settingsObjectName][notificationMethodKey] : false;
  }

  renderNotificationTree(eshopDeliveryNotifications) {
    const { t } = this.props;
    const tree = [];

    const generateTableData = (notificationMethod, code, path) => {
      const nodePath = path.split('.');
      const renderDash = nodePath.includes('not_delivered') && nodePath.length > 1 && notificationMethod.key === 'important';

      const checkbox = (
        <CustomInput
          type="checkbox"
          name={code}
          checked={this.isCheckboxChecked(path, notificationMethod.key)}
          id={generateReactKey()}
          onChange={this.handleCheckboxChange}
          label=""
          className="d-flex align-self-center justify-content-center datatable-checkbox"
          data-path={path}
          data-method={notificationMethod.key}
          value={code}
          disabled={(notificationMethod.key === 'sms') && true}
        />
      );

      const dash = <div className="text-center">-</div>;

      return renderDash ? dash : checkbox;
    };
    const generateTableRow = (node, renderTableData, categoryClass) => {
      let notificationMethods = node[this.settingsObjectName];
      let categoryClassNumber = categoryClass;

      if (!notificationMethods) {
        notificationMethods = {};
      }

      if (renderTableData) {
        categoryClassNumber = 3;
      }

      return (
        <tr data-path={`${node.path}`} key={generateReactKey()} className={`category-${categoryClassNumber}`}>
          <td className="title">{t(`status:${node.code}`)}</td>
          <td>
            {renderTableData && generateTableData({ key: 'important', value: notificationMethods.important }, node.code, node.path)}
          </td>
          <td>
            {renderTableData && generateTableData({ key: 'email', value: notificationMethods.email }, node.code, node.path)}
          </td>
          <td>
            {renderTableData && generateTableData({ key: 'sms', value: notificationMethods.sms }, node.code, node.path)}
          </td>
        </tr>
      );
    };

    const shouldRenderTableData = (node) => node.userSettings !== null;

    for (let i = 0; i < eshopDeliveryNotifications.length; i += 1) {
      const firstLevelNode = eshopDeliveryNotifications[i];
      const firstCategorySettingItems = firstLevelNode.items || [];

      tree.push(generateTableRow(firstLevelNode, shouldRenderTableData(firstLevelNode), 1));

      for (let x = 0; x < firstCategorySettingItems.length; x += 1) {
        const secondLevelNode = firstCategorySettingItems[x];
        const secondCategoryItems = secondLevelNode.items || [];

        tree.push(generateTableRow(secondLevelNode, shouldRenderTableData(secondLevelNode), 2));

        for (let y = 0; y < secondCategoryItems.length; y += 1) {
          const thirdLevelNode = secondCategoryItems[y];
          tree.push(generateTableRow(thirdLevelNode, shouldRenderTableData(thirdLevelNode), 3));
        }
      }
    }

    return tree;
  }

  render() {
    const { settings } = this.state;

    return (
      <CustomQuery
        query={EshopNotificationQueryFactory.eshopNotificationCenter(
          '...DeliveryStatusNotifications',
          EshopNotificationFragments.deliveryStatusNotifications(),
        )}
        onCompleted={this.storeBackendDataToComponentState}
      >
        {({ data, loading, error }) => {
          if (loading || settings.length === 0) return <Spinner size="lg" className="text-center" />;
          if (error) return null;

          const { settings: eshopDeliveryNotificationSettings } = data.eshopNotificationCenter;
          const { t } = this.props;

          return (
            <ApolloConsumer>
              {(client) => (
                <>
                  <table id="notificationSettingsTable" className="table table-borderless">
                    <thead>
                      <tr>
                        <th className="title">
                          {t('settings:Notifications.TableTitles.packagesStates')}
                        </th>
                        <th>
                          <Icon type="foxdeli" icon="warning_bigger" />
                          {t('settings:Notifications.TableTitles.important')}
                          <Tooltip
                            id="tooltip-important"
                            linkText={<SVGIcon icon="notifications-info" className="" />}
                            text={t('settings:Notifications.TableTitles.importantTooltip')}
                            linkIcon={[]}
                            linkIconHide
                            linkIconPosition="after"
                            placement="bottom"
                          />
                        </th>
                        <th>
                          <Icon type="foxdeli" icon="emaily_sms" />
                          {t('settings:Notifications.TableTitles.email')}
                          <Tooltip
                            id="tooltip-email"
                            linkText={<SVGIcon icon="notifications-info" className="" />}
                            text={t('settings:Notifications.TableTitles.emailTooltip')}
                            linkIconHide
                            linkIcon={[]}
                            linkIconPosition="after"
                            placement="bottom"
                          />
                        </th>
                        <th>
                          <SVGIcon icon="notifications-phone" className="" />
                          {t('settings:Notifications.TableTitles.sms')}
                          <Tooltip
                            id="tooltip-sms"
                            linkText={<SVGIcon icon="notifications-info" className="" />}
                            text={t('settings:Notifications.TableTitles.smsTooltip')}
                            linkIconHide
                            linkIcon={[]}
                            linkIconPosition="after"
                            placement="bottom"
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.renderNotificationTree(settings)}
                    </tbody>
                  </table>
                  <div className="text-center pt-5 pb-3">
                    <Button type="submit" color="primary px-5" onClick={() => this.handleSubmit(client)}>
                      {t('settings:Notifications.submitButton')}
                    </Button>
                    <Button
                      type="button"
                      color="secondary"
                      className="ml-3"
                      onClick={() => this.setInitialSettings(eshopDeliveryNotificationSettings)}
                    >
                      {t('settings:Notifications.initialSettingsButton')}
                    </Button>
                  </div>
                </>
              )}
            </ApolloConsumer>
          );
        }}
      </CustomQuery>
    );
  }
}

export default withTranslation(['settings', 'status'])(NotificationSettingsTable);
