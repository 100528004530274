import PropTypes from 'prop-types';

// eslint-disable-next-line import/prefer-default-export
export const countries = {
  id: PropTypes.string,
  nameCs: PropTypes.string,
  nameEn: PropTypes.string,
  code: PropTypes.string,
  code3: PropTypes.string,
  currency: PropTypes.string,
  currencyName: PropTypes.string,
  inEu: PropTypes.bool,
  phonePrefix: PropTypes.string,
  postalCodePattern: PropTypes.string,
  postalCodeExample: PropTypes.string,
};
