import configurator from '../../../src/2020/services/Configurator';

export default class GoogleAnalytics {
  static async init() {
    if (!configurator.config.googleAnalyticsId) return;

    window.dataLayer = window.dataLayer || [];
    // eslint-disable-next-line no-undef,prefer-rest-params
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());

    gtag('config', configurator.config.googleAnalyticsId);
  }
}
