import React from 'react';
import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Input,
  ButtonGroup, FormFeedback,
  Row, Col,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { stringifyNull } from '#/shared/utilities';
import DatePicker from '~/components/DatePicker/DatePicker';

type Props = {
  handleSubmit: any;
  isVisible: any;
  hideContent: any;
  onChange: any;
  values: any;
  handleChange: (event: any) => void;
  submitted: any;
};

const StopDeliveryProcessFormUI: React.FC<Props> = ({
  handleSubmit,
  isVisible,
  hideContent,
  onChange,
  values,
  handleChange,
  submitted,
}) => {
  const { t } = useTranslation('deliveries');

  const stateClickEventDispatchFactory = (stateName: string) => () => onChange({
    target: {
      name: 'state',
      value: stateName,
    },
  });

  return (
    <Modal isOpen={isVisible} centered toggle={hideContent} className="modal-lg">
      <ModalHeader toggle={hideContent}>
        {t('DeliveryDetail.StopDeliveryModal.title')}
      </ModalHeader>
      <ModalBody>
        <p>
          {t('DeliveryDetail.StopDeliveryModal.description')}
        </p>

        <form onSubmit={handleSubmit}>
          <Row className="pb-3">
            <Col md="12">
              <ButtonGroup className="full-width">
                <Button
                  type="button"
                  name="delivered"
                  onClick={stateClickEventDispatchFactory('delivered')}
                  active={values.state === 'delivered'}
                >
                  {t('DeliveryDetail.StopDeliveryModal.DeliveryState.delivered')}
                </Button>
                <Button
                  type="button"
                  name="not_delivered"
                  onClick={stateClickEventDispatchFactory('not_delivered')}
                  active={values.state === 'not_delivered'}
                >
                  {t('DeliveryDetail.StopDeliveryModal.DeliveryState.notDelivered')}
                </Button>
                <Button
                  type="button"
                  name="returned"
                  onClick={stateClickEventDispatchFactory('returned')}
                  active={values.state === 'returned'}
                >
                  {t('DeliveryDetail.StopDeliveryModal.DeliveryState.returned')}
                </Button>
                {/* <Button
                                    type="button"
                                    name="damaged"
                                    onClick={stateClickEventDispatchFactory('damaged')}
                                    active={values.state === 'damaged'}
                                >
                                    Poškozeno
                                </Button> */}
                <Button
                  type="button"
                  name="lost"
                  onClick={stateClickEventDispatchFactory('lost')}
                  active={values.state === 'lost'}
                >
                  {t('DeliveryDetail.StopDeliveryModal.DeliveryState.lost')}
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Row className="datepicker-wrapper">
            <Col md="12">
              <DatePicker
                open
                selected={stringifyNull(values.date)}
                timeIntervals={15}
                showTimeSelect
                onChange={(date) => handleChange({ target: { name: 'date', value: date } })}
              />
            </Col>
          </Row>
          <Input
            type="text"
            name="note"
            onChange={onChange}
            value={values.note}
            placeholder={t('DeliveryDetail.StopDeliveryModal.notePlaceholder')}
          />
          <Row className="pt-3">
            <Col md="12">
              <Label style={{ paddingLeft: '20px' }}>
                <Input type="checkbox" name="confirm" invalid onChange={onChange} value={values.confirm} />
                <small>
                  {t('DeliveryDetail.StopDeliveryModal.Agreement.checkboxLabel')}
                </small>
                {(submitted && !values.confirm) && (
                  <FormFeedback>
                    {t('DeliveryDetail.StopDeliveryModal.Agreement.required')}
                  </FormFeedback>
                )}
              </Label>
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              <Button type="submit" color="primary">
                {t('DeliveryDetail.StopDeliveryModal.submitButton')}
              </Button>
            </Col>
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default StopDeliveryProcessFormUI;
