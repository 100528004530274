import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import SVGIcon from 'Components/Presentational/SVGIcon/SVGIcon';

const DataTableActionSVG = ({ to, icon, tooltip }) => (
  <span>
    <Link to={to} tooltip={tooltip}>
      <SVGIcon className="mr-2" icon={icon} alt="" />
    </Link>
  </span>
);

DataTableActionSVG.propTypes = {
  to: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
};

DataTableActionSVG.defaultProps = { tooltip: null };

export default DataTableActionSVG;
