import React from 'react';
import { RouteComponentProps } from 'react-router';
import AuthenticationDataSource from '#/core/models/authentication/AuthenticationDataSource';
import configurator from '~/services/Configurator';

class Login extends React.Component<RouteComponentProps> {
  private auth: typeof AuthenticationDataSource;

  constructor(props: RouteComponentProps) {
    super(props);

    this.auth = AuthenticationDataSource;
  }

  componentDidMount() {
    const { location, history } = this.props;

    if (location.search === '') {
      history.push('/');
      return;
    }

    const urlParams = new URLSearchParams(location.search);
    const tempAccessToken = urlParams.get('accessToken');

    if (tempAccessToken && tempAccessToken !== '') {
      window.location.replace(`${configurator.config.iamUrl}/remote-auth?accessToken=${tempAccessToken}`);
    } else {
      history.push('/');
    }
  }

  render() {
    return null;
  }
}

export default Login;
