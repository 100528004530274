import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import PropTypes from 'prop-types';
import { stringifyNull, generateReactKey } from 'Shared/utilities';
import { useTranslation } from 'react-i18next';
import SearchBarItem from './SearchBarItem';

const typeaheadKey = generateReactKey();

const SearchBar = ({
  options,
  isLoading,
  searchKey,
  onSearch,
  onChange,
}) => {
  const { t } = useTranslation();

  const formatContact = () => {
    const fullName = searchKey.split(' ');

    if (fullName.length > 1) {
      return `${stringifyNull(fullName[0])} ${stringifyNull(fullName[1])} `;
    }

    return `${searchKey} `;
  };

  return (
    <AsyncTypeahead
      id={typeaheadKey}
      options={options}
      isLoading={isLoading}
      onSearch={onSearch}
      onChange={onChange}
      labelKey={(option) => {
        switch (option.type) {
          case 'contact':
            return `${formatContact()} `
                            + `${stringifyNull(option.street)} `
                            + `${stringifyNull(option.zip)} `
                            + `${stringifyNull(option.city)}`;
          case 'delivery':
            return `${stringifyNull(option.deliveryNumber)}, `
                                + `${stringifyNull(option.agent)}, `
                                + `${formatContact()}`;
          case 'component':
            return `${searchKey}`;
          default:
            return '';
        }
      }}
      maxResults={11}
      minLength={3}
      placeholder={t('Search.placeholder')}
      useCache={false}
      renderMenuItemChildren={(optionProps, option) => (
        <SearchBarItem
          key={optionProps.id}
          item={optionProps}
          searchText={option.text}
        />
      )}
      emptyLabel={(
        <span>
          <FontAwesomeIcon icon={['far', 'circle']} fixedWidth />
          {' '}
          {t('Search.searching')}
        </span>
      )}
      promptText={(
        <span>
          <FontAwesomeIcon icon={['fas', 'keyboard']} fixedWidth />
          {' '}
          {t('Search.help')}
        </span>
      )}
      searchText={(
        <span>
          <FontAwesomeIcon icon={['fas', 'circle-notch']} spin fixedWidth />
          {' '}
          {t('Search.searching')}
        </span>
      )}
      className="search-lg search-bar"
    />
  );
};

SearchBar.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isLoading: PropTypes.bool.isRequired,
  searchKey: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SearchBar;
