import React, { useMemo } from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import Icon from '~/components/Icon/Icon';

import css from './Rating.module.scss';

const RATING_OPTIONS = [1, 2, 3, 4, 5];

type Tag = {
  id: string;
  key: string;
  sort: number;
};

type Props = {
  rating: number;
  note: Nullable<string>;
  tags: Nullable<Tag[]>;
};

const Rating: React.FC<Props> = ({
  rating,
  note,
  tags,
}) => {
  const { t } = useTranslation('deliveries');

  const { ratingLevelClass, subtitle } = useMemo(() => {
    if (rating === 4) {
      return {
        ratingLevelClass: css.level2,
        subtitle: t('deliveries:DeliveryDetail.Rating.Subtitle.whatToImprove'),
      };
    }

    if (rating === 5) {
      return {
        ratingLevelClass: css.level3,
        subtitle: t('deliveries:DeliveryDetail.Rating.Subtitle.whatWentWell'),
      };
    }

    return {
      ratingLevelClass: css.level1,
      subtitle: t('deliveries:DeliveryDetail.Rating.Subtitle.whatWasWrong'),
    };
  }, [rating, t]);

  return (
    <Card className="mb-4">
      <CardBody>
        <CardTitle
          className="text-dark font-weight-bold d-flex justify-content-between align-items-center mb-0"
        >
          {t('deliveries:DeliveryDetail.Rating.title')}

          <ul className={`mb-0 d-inline-flex ${css.ratingBar}`}>
            {RATING_OPTIONS.map((option) => {
              return (
                <li
                  key={option}
                  className={option <= rating ? css.on : css.off}
                >
                  <Icon
                    name="star"
                    className={css.star}
                  />
                </li>
              );
            })}
          </ul>
        </CardTitle>

        {tags && tags.length > 0 && (
          <div className="mt-3">
            <p className="mb-0 text-secondary">
              {subtitle}
              :
            </p>

            <ul className="mb-0 pl-0 d-inline-flex flex-wrap">
              {tags.map((tag) => {
                return (
                  <li
                    key={tag.id}
                    className={`${css.tag} ${ratingLevelClass}`}
                  >
                    {t(`deliveries:DeliveryDetail.RatingTag.${tag.key}`)}
                  </li>
                );
              })}
            </ul>
          </div>
        )}

        {note && note.length > 0 && (
          <div className="mt-3">
            <p className="mb-0 text-secondary">
              {t('deliveries:DeliveryDetail.Rating.commentTitle')}
              :
            </p>
            <p
              className={`${css.comment} ${ratingLevelClass} mb-0 mt-2`}
            >
              {note}
            </p>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default Rating;
