import React from 'react';
import { useField } from 'formik';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';

import { customManualQuery } from '#/core/models/Components/CustomQuery';
import SelectField from '../../SelectField/SelectField';

import PICKUP_PLACE_SEARCH from './pickupPlaceSearch.gql';

interface PickupPlaceSearchData {
  data: {
    pickupPlaceSearch: {
      id: string;
      location: string;
      name: string;
      label: string;
    }[],
  },
}

export interface PickupPlace {
  id: string;
  name: Nullable<string>;
  location: string;
  label: string;
}

interface DropdownItem {
  label: string;
  value: PickupPlace;
}

type Props = {
  typeId: number;
  name: string;
  countryCodeFilter?: string;
};

const PickupPlaceSelect: React.FC<Props> = ({
  typeId,
  name,
  countryCodeFilter,
}) => {
  const { t } = useTranslation('deliveries');
  const [field] = useField(name);

  const search = async (keyword: string): Promise<Nullable<DropdownItem[]>> => {
    if (keyword?.length < 3) {
      return null;
    }

    const filter = countryCodeFilter === '' || countryCodeFilter === undefined ? null : countryCodeFilter;

    const { data } = await customManualQuery({
      query: PICKUP_PLACE_SEARCH,
      variables: {
        typeId,
        keyword,
        countryCodeFilter: filter,
      },
    }) as PickupPlaceSearchData;

    return data?.pickupPlaceSearch?.map((x) => {
      return {
        label: x.label,
        value: x,
      };
    });
  };

  // https://github.com/JedWatson/react-select/issues/614#issuecomment-244006496
  const debouncedSearch = debounce((keyword, callback) => {
    search(keyword)
      .then((res) => callback(res))
      .catch(() => callback([]));
  }, 500);

  const printEmptyMessage = ({ inputValue = '' }): string => {
    return inputValue?.length >= 3
      ? t('NewDelivery.PickupPlace.noResults')
      : t('NewDelivery.PickupPlace.minLength', { count: 3 });
  };

  const defaultValue = field.value?.id
    ? {
      value: field.value,
      label: field.value.label,
    }
    : undefined;

  return (
    <SelectField
      label={t('NewDelivery.PickupPlace.switchButton')}
      placeholder={t('NewDelivery.PickupPlace.placeholder')}
      noOptionsMessage={printEmptyMessage}
      name={name}
      defaultValue={defaultValue}
      openMenuOnClick={false}
      loadOptions={debouncedSearch}
      isClearable
    />
  );
};

export default PickupPlaceSelect;
