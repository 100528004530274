import React from 'react';
import {
  Button,
  Card,
  CardBody,
  Col,
  CustomInput,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  Row,
  Spinner,
} from 'reactstrap';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

import Tooltip from 'Components/Presentational/Tooltip/Tooltip';
import CSVDragAndDrop from 'Containers/CSVDragAndDrop/CSVDragAndDrop';
import DataTable from 'Containers/DataTable/DataTable';
import DefaultValues from '../DefaultValues/DefaultValues';
import RemoveSavedSettings from '../../Container/RemoveSavedSettings';

const fileInputRef = React.createRef();

const ImportCSVForm = ({
  handleSubmit,
  handleChange,
  handleBlur,
  isSubmitting,
  values,
  touched,
  errors,
  setFieldValue,
  setFormikHelpers,
  inputs,
  table,
  history,
  deliveryCount,
  agents,
  activeAgentIndex,
  handleFileChanges,
  renderSettingsOptions,
  handleSettingsChange,
  handleHeaderChange,
  requiredCSVFields,
  csvInputIsInUse,
  renderCollectionPlaceOptions,
  extraServiceList,
  onAgentChange,
  onExtraServiceChange,
  csvImportSettings,
  activeSettingsIndex,
  handleSettingsDelete,
}) => {
  setFormikHelpers({ setFieldValue });

  const { t } = useTranslation('deliveries');

  const {
    csv: {
      file: inputsCsvFile,
      fileName: inputsCsvFileName,
    },
    extraServices,
  } = inputs;
  const {
    data: tableData,
    columns: tableColumns,
    actions: tableActions,
    config: tableConfig,
  } = table;

  return (
    <Form onSubmit={handleSubmit}>
      <Card className="shadow border-0 mb-4">
        <CardBody>
          <Row>
            <Col md="4">
              <h4 className="text-nowrap">
                {t('NewDeliveryCSV.SourceFile.title')}
              </h4>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label htmlFor="sourceFile">
                  {t('NewDeliveryCSV.SourceFile.label')}
                </Label>
                <div className="custom-input">
                  <Label
                    for="sourceFile"
                    className="btn btn-block btn-outline-secondary text-center"
                    onClick={() => fileInputRef.current.click()}
                  >
                    {inputsCsvFileName || t('NewDeliveryCSV.SourceFile.uploadFileButton')}
                  </Label>
                  <input
                    name="sourceFile"
                    type="file"
                    ref={fileInputRef}
                    onChange={(event) => handleFileChanges(event)}
                    onBlur={handleBlur}
                    accept=".csv"
                    style={{ display: 'none' }}
                  />
                </div>
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card className="shadow border-0 mb-5" style={{ display: !inputsCsvFile && 'none' }}>
        <CardBody>
          <Row>
            <Col md="4">
              <h4>{t('NewDeliveryCSV.UploadSettings.title')}</h4>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label htmlFor="savedSettings">
                  {t('NewDeliveryCSV.UploadSettings.label')}
                </Label>
                <Select
                  name="savedSettings"
                  value={values.savedSettings}
                  options={renderSettingsOptions()}
                  classNamePrefix="select"
                  onChange={(options, select) => {
                    handleSettingsChange(
                      setFieldValue,
                      options,
                      select,
                    );
                  }}
                  placeholder={t('NewDeliveryCSV.UploadSettings.placeholder')}
                  styles={{ menu: (provided) => ({ ...provided, zIndex: 2 }) }} // https://foxdeli.atlassian.net/browse/FOX-1067
                />
                {touched.savedSettings && errors.savedSettings && (
                  <FormFeedback>
                    {errors.savedSettings}
                  </FormFeedback>
                )}
              </FormGroup>

              {(activeSettingsIndex !== null) && (
                <div className="d-flex justify-content-end">
                  <RemoveSavedSettings
                    className="mb-1"
                    settingsId={csvImportSettings[activeSettingsIndex].id}
                    onRemove={() => handleSettingsDelete(
                      csvImportSettings[activeSettingsIndex].id,
                    )}
                  />
                </div>
              )}

              <FormGroup className="mb-0">
                <CustomInput
                  type="checkbox"
                  id="fileFirstLineHeader"
                  value
                  onChange={handleChange}
                  onClick={() => handleHeaderChange()}
                  checked={values.fileFirstLineHeader}
                  label={t('NewDeliveryCSV.UploadSettings.checkboxLabel')}
                />
              </FormGroup>

              <FormGroup className="d-flex flex-row align-items-end">
                <CustomInput
                  type="checkbox"
                  id="isMonitored"
                  onChange={handleChange}
                  checked={values.isMonitored}
                  label={t('NewDeliveryCSV.UploadSettings.MonitoredDeliveries.label')}
                  className="mr-2"
                />
                <Tooltip
                  id="tooltip-email"
                  text={t('NewDeliveryCSV.UploadSettings.MonitoredDeliveries.description')}
                  linkIcon="info-circle"
                  linkIconPosition="before"
                  placement="bottom"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="pt-5">
            <Col md="8">
              <h4>{t('NewDeliveryCSV.SampleData.title')}</h4>
              <span className="text-muted">
                {t('NewDeliveryCSV.SampleData.description')}
              </span>
            </Col>
            <Col md="4" />
          </Row>
          <Row className="pt-3">
            <Col xs={12} sm={12} md={12} lg={9}>
              {tableData && (
                <DataTable
                  data={tableData}
                  columns={tableColumns}
                  actions={tableActions}
                  config={tableConfig}
                  isLoaderVisible={false}
                />
              )}
            </Col>
            <Col xs={12} sm={12} md={12} lg={3}>
              {tableData && (
                <Card className="bg-light border-0 csv-import">
                  <CardBody>
                    <h4 className="mb-2">
                      {t('NewDeliveryCSV.SampleData.mandatoryItemsTitle')}
                    </h4>
                    <hr />
                    <Nav vertical className="align-middle">
                      {
                        Object.entries(requiredCSVFields).map(
                          ([key, item]) => {
                            if (
                              item.required
                              // Monitored deliveries require `trackingNumber` column
                              || (key === 'trackingNumber' && values.isMonitored === true)
                            ) {
                              return (
                                <NavItem
                                  key={key}
                                  value={key}
                                  className={csvInputIsInUse(key) ? 'text-crossed ' : ''}
                                >
                                  {item.label}
                                </NavItem>
                              );
                            }

                            return null;
                          },
                        )
                      }
                    </Nav>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
          <DefaultValues
            agents={agents}
            activeAgentIndex={activeAgentIndex}
            errors={errors}
            values={values}
            touched={touched}
            setFieldValue={setFieldValue}
            handleBlur={handleBlur}
            extraServices={extraServices}
            extraServiceList={extraServiceList}
            onAgentChange={onAgentChange}
            onExtraServiceChange={onExtraServiceChange}
          />
          <Row className="pt-5">
            <Col md="4">
              <h4>{t('NewDeliveryCSV.CollectionPlace.title')}</h4>
              <span className="text-muted">
                {t('NewDeliveryCSV.CollectionPlace.description')}
              </span>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label htmlFor="collectionPlace">
                  {t('NewDeliveryCSV.CollectionPlace.label')}
                </Label>
                <Select
                  name="collectionPlace"
                  value={values.collectionPlace}
                  options={renderCollectionPlaceOptions()}
                  classNamePrefix="select"
                  onChange={
                    (option, select) => setFieldValue(
                      select.name, option,
                    )
                  }
                  placeholder={t('NewDeliveryCSV.CollectionPlace.placeholder')}
                  styles={{ menu: (provided) => ({ ...provided, zIndex: 2 }) }} // https://foxdeli.atlassian.net/browse/FOX-1044
                />
                {touched.collectionPlace && errors.collectionPlace && (
                  <div
                    style={{ display: 'block' }}
                    className="invalid-feedback"
                  >
                    {errors.collectionPlace.value || errors.collectionPlace}
                  </div>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row className="pt-5">
            <Col md="4">
              <h4>{t('NewDeliveryCSV.SaveSettings.title')}</h4>
            </Col>
            <Col md="6">
              <FormGroup>
                <CustomInput
                  type="checkbox"
                  id="settingsSave"
                  value
                  onChange={handleChange}
                  checked={values.settingsSave}
                  label={t('NewDeliveryCSV.SaveSettings.checkboxLabel')}
                />
              </FormGroup>
              <FormGroup style={!values.settingsSave ? { display: 'none' } : null}>
                <Label htmlFor="settingsName" style={{ display: 'block' }}>
                  {t('NewDeliveryCSV.SaveSettings.settingsNameLabel')}
                </Label>
                <Input
                  name="settingsName"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.settingsName}
                  invalid={errors.settingsName !== undefined}
                  valid={touched.settingsName && !errors.settingsName}
                />
                {touched.settingsName && errors.settingsName
                                && <FormFeedback>{errors.settingsName}</FormFeedback>}
              </FormGroup>
            </Col>
          </Row>
          <Row className="pt-5">
            <Col md="4" />
            <Col md="6">
              <Button type="submit" color="primary" disabled={isSubmitting}>
                {isSubmitting && <Spinner size="sm" />}
                {t('NewDeliveryCSV.importDeliveriesButton', { deliveryCount: deliveryCount || 0 })}
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <CSVDragAndDrop
        history={history}
        setCSVFileUpload={(file) => handleFileChanges(null, file)}
      />
    </Form>
  );
};

export default ImportCSVForm;
