import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import authReducer from 'StoreReducers/auth.reducer';
import userReducer from 'StoreReducers/user.reducer';
import contactReducer from 'StoreReducers/contact.reducer';
import DeliveryReducer from 'StoreReducers/DeliveryReducer';
import * as actionTypes from 'StoreActions/actionTypes';

const reducers = combineReducers({
  authReducer,
  deliveryReducer: new DeliveryReducer().actionHandler,
  userReducer,
  contactReducer,
});

const rootReducer = (state, action) => {
  let rootState = state;

  if (action.type === actionTypes.USER_LOGGED_OUT) {
    rootState = undefined;
  }

  return reducers(rootState, action);
};

// TODO: Disable devtools!
const store = createStore(rootReducer, composeWithDevTools(
  applyMiddleware(thunkMiddleware),
));

export default store;
