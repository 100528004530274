import React from 'react';
import { Spinner } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { PackageTemplate } from '../types';

import css from './PackageSizes.module.scss';

type Props = {
  isLoading: boolean,
  templates: PackageTemplate[];
  onAddFromTemplate(item: any): void;
  onAddBlank(): void;
}

const PackageSizes: React.FC<Props> = ({
  isLoading,
  templates,
  onAddFromTemplate,
  onAddBlank,
}) => {
  const { t } = useTranslation('deliveries');

  const templateList = templates.map((item) => (
    <button
      type="button"
      className={css.btnPackageTemplate}
      key={item.id}
      onClick={(): void => { onAddFromTemplate(item); }}
    >
      <span
        className="d-block text-uppercase text-truncate"
        title={item.name}
      >
        {item.name}
      </span>

      <small>
        {item.length}
        x
        {item.width}
        x
        {item.height}
        cm
      </small>
    </button>
  ));

  return (
    <div className="d-flex flex-wrap">
      {isLoading ? (
        <div className="text-center">
          <Spinner size="sm" />
        </div>
      ) : (
        <>
          {templateList}

          <button
            type="button"
            className={css.btnPackageTemplate}
            onClick={(): void => { onAddBlank(); }}
          >
            <span className="d-block text-uppercase">
              {t('NewDelivery.Packages.anotherPackageLabel')}
            </span>
          </button>
        </>
      )}
    </div>
  );
};

export default PackageSizes;
