import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MARKETING_CAMPAIGNS_LIST from 'Containers/Settings/Branding/MarketingCampaigns/queries/fetchMarketingCampaigns.gql';
import { QueryMarketingCampaignsListData } from 'Containers/Settings/Branding/MarketingCampaigns/typesAndDefaults';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Badge, Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';

import statusToBadgeMapper from 'Containers/Settings/Branding/helpers/statusToBadgeMapper';
import { DATE_FORMAT_BRANDING } from 'Containers/Settings/Branding/typesAndDefaults';
import ESHOP from 'Containers/Settings/Eshop/eshop.gql';
import { EshopQuery, UpdateEshopMutation, UpdateEshopMutationVariables } from 'Containers/Settings/Eshop/types';
import UPDATE_ESHOP from 'Containers/Settings/Eshop/updateEshop.gql';
import getFullPathUrlOrDefault from 'Containers/Settings/Branding/helpers/getFullPathUrlOrDefault';
import CompanyInformationBanner from '~/components/CompanyInformationBanner/CompanyInformationBanner';
import FoxLoader from '~/components/FoxLoader/FoxLoader';
import ModalWithPreview from '~/components/ModalWithPreview/ModalWithPreview';
import { MutationTTActivate, MutationTTDeactivate, MutationTTDelete, QueryEshopTrackAndTraceConfig } from './typesAndDefaults';
import { toast } from '#/shared/utilities';
import * as userActions from '#/store/actions/user.action';
import EmptyState from '~/components/EmptyState/EmptyState';
import SimpleDropdown from '~/components/SimpleDropdown/SimpleDropdown';
import SimpleModal, { ModalData } from '~/components/SimpleModal/SimpleModal';
import SimpleTable from '~/components/SimpleTable/SimpleTable';
import StatusBanner from '~/components/StatusBanner/StatusBanner';
import TrackAndTracePreview, { TrackAndTracePreviewSettings } from '~/components/TrackAndTracePreview/TrackAndTracePreview';
import useAuthMutation from '~/hooks/useAuthMutation';
import useAuthQuery from '~/hooks/useAuthQuery';
import { format } from '~/services/date';

import TT_ACTIVATE from './queries/activateTrackAndTrace.gql';
import TT_CONFIG from './queries/configTrackAndTrace.gql';
import TT_DEACTIVATE from './queries/deactivateTrackAndTrace.gql';
import TT_DELETE from './queries/deleteTrackAndTrace.gql';

const TrackAndTrace: React.FC = () => {
  const { t } = useTranslation(['settings']);
  const dispatch = useDispatch();
  const [modalData, setModalData] = React.useState<ModalData>();
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [GTMId, setGTMId] = React.useState('');

  const { data: TTConfigDataQuery, loading: TTConfigLoadingQuery, refetch: TTConfigRefetchQuery } = useAuthQuery<QueryEshopTrackAndTraceConfig>(TT_CONFIG);
  const [ttActivateMutation] = useAuthMutation<MutationTTActivate, { id: string }>(TT_ACTIVATE);
  const [ttDeactivateMutation] = useAuthMutation<MutationTTDeactivate>(TT_DEACTIVATE);
  const [ttDeleteMutation] = useAuthMutation<MutationTTDelete, { id: string }>(TT_DELETE);

  const [updateEshop] = useAuthMutation<UpdateEshopMutation, UpdateEshopMutationVariables>(UPDATE_ESHOP);
  const { data: eshopQueryData, refetch: eshopQueryRefetch } = useAuthQuery<{ eshop: EshopQuery }, never>(ESHOP);
  const eshopData = eshopQueryData?.eshop;

  const { data: marketingCampaignsDataQuery } = useAuthQuery<QueryMarketingCampaignsListData>(MARKETING_CAMPAIGNS_LIST);
  const activeMarketingCampaignBannerUrl = marketingCampaignsDataQuery?.eshop?.activeEshopCampaign?.defaultDesktopBanner?.url;

  useEffect(() => {
    setGTMId(eshopData?.gtmId || '');
  }, [eshopData?.gtmId]);

  const ttConfigs = (TTConfigDataQuery?.eshop.trackAndTraceConfigs || []).sort((a, b) => parseInt(b.id, 10) - parseInt(a.id, 10));
  const activeTTConfig = TTConfigDataQuery?.eshop.activeTrackAndTraceConfig;
  const ttPreviewConfig: TrackAndTracePreviewSettings = {
    ...activeTTConfig,
    logo: getFullPathUrlOrDefault(activeTTConfig?.logo?.url),
    background: getFullPathUrlOrDefault(activeTTConfig?.desktopBackgroundImage?.url),
    eshopName: eshopData?.name,
    activeMarketingCampaignBannerUrl: getFullPathUrlOrDefault(activeMarketingCampaignBannerUrl),
  };

  const submitGTM = async () => {
    if (!GTMId) {
      toast.info(t('settings:Branding.TrackAndTrace.Message.setGTMIdWarning'));
      return;
    }

    try {
      await updateEshop({ variables: { eshop: { gtmId: GTMId } } });
      toast.success(t('settings:Branding.TrackAndTrace.Message.setGTMIdSuccess'));
      await eshopQueryRefetch();
    } catch (e) {
    // Handled in global error interceptor
    }
  };

  const activateTTConfig = async (id: string) => {
    try {
      await ttActivateMutation({ variables: { id } });
      toast.success(t('settings:Branding.TrackAndTrace.Message.templateActivated'));
      dispatch(userActions.setUserMeta({ hasActiveTrackAndTrace: true }));
      await TTConfigRefetchQuery();
    } catch (e) {
      // Handled in global error interceptor
    }
  };

  const deactivateTTConfig = async () => {
    try {
      await ttDeactivateMutation();
      toast.success(t('settings:Branding.TrackAndTrace.Message.templateDeactivated'));
      dispatch(userActions.setUserMeta({ hasActiveTrackAndTrace: false }));
      await TTConfigRefetchQuery();
    } catch (e) {
      // Handled in global error interceptor
    }
  };

  const deleteTTConfig = async (id: string) => {
    try {
      await ttDeleteMutation({ variables: { id } });
      toast.success(t('settings:Branding.TrackAndTrace.Message.templateDeleted'));

      if (activeTTConfig?.id === id) {
        dispatch(userActions.setUserMeta({ hasActiveTrackAndTrace: false }));
      }

      await TTConfigRefetchQuery();
    } catch (e) {
      // Handled in global error interceptor
    }
  };

  const openActivateModal = (itemForActivation: string) => {
    setModalData({
      title: t('settings:Branding.TrackAndTrace.Library.ActivateTemplateModal.title'),
      description: t('settings:Branding.TrackAndTrace.Library.ActivateTemplateModal.description'),
      cancelButton: t('settings:Branding.TrackAndTrace.Library.ActivateTemplateModal.cancelButton'),
      submitButton: t('settings:Branding.TrackAndTrace.Library.ActivateTemplateModal.submitButton'),
      onActionItem: () => activateTTConfig(itemForActivation),
      submitButtonColor: 'primary',
    });

    setModalIsOpen(!modalIsOpen);
  };

  const openDeactivateModal = async () => {
    setModalData({
      title: t('settings:Branding.TrackAndTrace.Library.DeactivateTemplateModal.title'),
      description: t('settings:Branding.TrackAndTrace.Library.DeactivateTemplateModal.description'),
      cancelButton: t('settings:Branding.TrackAndTrace.Library.DeactivateTemplateModal.cancelButton'),
      submitButton: t('settings:Branding.TrackAndTrace.Library.DeactivateTemplateModal.submitButton'),
      onActionItem: () => deactivateTTConfig(),
      submitButtonColor: 'danger',
    });

    setModalIsOpen(!modalIsOpen);
  };

  const openDeleteModal = async (itemForDeletion: string) => {
    setModalData({
      title: t('settings:Branding.TrackAndTrace.Library.DeleteTemplateModal.title'),
      description: t('settings:Branding.TrackAndTrace.Library.DeleteTemplateModal.description'),
      cancelButton: t('settings:Branding.TrackAndTrace.Library.DeleteTemplateModal.cancelButton'),
      submitButton: t('settings:Branding.TrackAndTrace.Library.DeleteTemplateModal.submitButton'),
      onActionItem: () => deleteTTConfig(itemForDeletion),
      submitButtonColor: 'danger',
    });

    setModalIsOpen(!modalIsOpen);
  };

  const tableTitles = [
    { title: t('settings:Branding.TrackAndTrace.Library.Table.Titles.trackingPage') },
    { title: t('settings:Branding.TrackAndTrace.Library.Table.Titles.lastSaved') },
    { title: t('settings:Branding.TrackAndTrace.Library.Table.Titles.status'), className: 'text-center' },
  ];

  const actionItems = (ttId: string) => {
    return [
      activeTTConfig?.id === ttId ? {
        key: 'deactivate',
        title: t('settings:Branding.Mailing.Library.Table.Actions.deactivate'),
        onClick: () => openDeactivateModal(),
      } : {
        key: 'activate',
        title: t('settings:Branding.Mailing.Library.Table.Actions.activate'),
        onClick: () => openActivateModal(ttId),
      },
      {
        key: 'edit',
        title: t('settings:Branding.TrackAndTrace.Library.Table.Actions.edit'),
        url: `/settings/branding/track-and-trace/${ttId}`,
      },
      {
        key: 'delete',
        title: t('settings:Branding.TrackAndTrace.Library.Table.Actions.delete'),
        onClick: () => openDeleteModal(ttId),
        className: 'text-danger',
      },
    ];
  };

  const tableRows = ttConfigs.map((tt) => ({
    key: tt.id,
    cells: [
      { key: `${tt.id}1`, value: tt.title, onClickUrl: `/settings/branding/track-and-trace/${tt.id}` },
      {
        key: `${tt.id}2`,
        onClickUrl: `/settings/branding/track-and-trace/${tt.id}`,
        value: format(new Date(tt.lastUpdatedAt), DATE_FORMAT_BRANDING),
      },
      {
        key: `${tt.id}3`,
        className: 'text-center',
        onClickUrl: `/settings/branding/track-and-trace/${tt.id}`,
        value: (
          <Badge color={statusToBadgeMapper(activeTTConfig?.id === tt.id)}>
            { t(`settings:Branding.TrackAndTrace.Library.Table.Status.${activeTTConfig?.id === tt.id ? 'active' : 'inactive'}`) }
          </Badge>
        ),
      },
      {
        key: `${tt.id}4`,
        className: 'text-right',
        value: (
          <SimpleDropdown items={actionItems(tt.id)} text={<FontAwesomeIcon icon={['fas', 'ellipsis-h']} />} />
        ),
      },
    ],
  }));

  let content = (
    <>
      <StatusBanner
        status={activeTTConfig !== null}
        title={{
          active: t('settings:Branding.TrackAndTrace.StatusBanner.titleActive'),
          inactive: t('settings:Branding.TrackAndTrace.StatusBanner.titleInactive'),
        }}
      />

      {activeTTConfig && (
        <>
          <Row className="mt-5">
            <Col xs={5}>
              <h2 className="branding-subtitle">
                {t('settings:Branding.TrackAndTrace.ActivePage.title')}
              </h2>
            </Col>
            <Col xs={7} className="text-right">
              <Link to={`/settings/branding/track-and-trace/${activeTTConfig.id}`} className="btn btn-primary">
                {t('settings:Branding.TrackAndTrace.ActivePage.editButton')}
              </Link>
              <SimpleDropdown
                items={actionItems(activeTTConfig.id)}
                text={(
                  <Button color="secondary" className="ml-3">
                    <FontAwesomeIcon icon={['fas', 'ellipsis-h']} className="mr-0" />
                  </Button>
                )}
              />
            </Col>
          </Row>

          <Row className="mt-4">
            <Col className="d-flex">
              <dl className="branding-description-list mb-0">
                <dt>{t('settings:Branding.TrackAndTrace.ActivePage.nameLabel')}</dt>
                <dd>{activeTTConfig.title}</dd>

                <dt>{t('settings:Branding.TrackAndTrace.ActivePage.lastSavedLabel')}</dt>
                <dd>{format(new Date(activeTTConfig.lastUpdatedAt), DATE_FORMAT_BRANDING)}</dd>
              </dl>
            </Col>
            <Col>
              <ModalWithPreview
                modalTitle={t('settings:Branding.TrackAndTrace.PreviewModal.title')}
                modalCancelButtonText={t('settings:Branding.TrackAndTrace.PreviewModal.closeButton')}
              >
                <TrackAndTracePreview previewSettings={ttPreviewConfig} />
              </ModalWithPreview>
            </Col>
          </Row>
        </>
      )}

      <h2 className="branding-subtitle mt-5">
        {t('settings:Branding.TrackAndTrace.GTM.title')}
      </h2>
      <hr className="mb-4 mt-3" />
      <Row className="mt-1">
        <Col xs={5}>
          <p className="mt-0 mb-0">
            {t('settings:Branding.TrackAndTrace.GTM.description')}
          </p>
          <a
            href="https://support.google.com/tagmanager/answer/6103696?hl=en"
            target="_blank"
            rel="noreferrer"
            className="mt-3 d-block"
          >
            {t('settings:Branding.TrackAndTrace.GTM.intercomLink')}
          </a>
        </Col>
        <Col xs={7}>
          <FormGroup>
            <Label className="mt-0">
              {t('settings:Branding.TrackAndTrace.GTM.label')}
            </Label>
            <Input
              onChange={(e) => setGTMId(e.target.value)}
              value={GTMId}
              name="gtm"
              type="text"
              placeholder={t('settings:Branding.TrackAndTrace.GTM.placeholder')}
            />
          </FormGroup>
          <Button color="primary" onClick={() => submitGTM()}>
            {t('settings:Branding.TrackAndTrace.GTM.submitButton')}
          </Button>
        </Col>
      </Row>
      <hr className="mb-4 mt-4" />
      <CompanyInformationBanner />
      <hr className="mb-4 mt-3" />

      <Row className="mt-5">
        <Col xs={5}>
          <h2 className="branding-subtitle mt-3">
            {t('settings:Branding.TrackAndTrace.Library.title')}
          </h2>
        </Col>
        <Col xs={7} className="text-right">
          <Link
            to="/settings/branding/track-and-trace/new"
            className="btn btn-secondary"
            data-test="new-track-and-trace-button"
          >
            <FontAwesomeIcon icon={['fas', 'plus']} fixedWidth />
            {t('settings:Branding.TrackAndTrace.Library.newPageButton')}
          </Link>
        </Col>
      </Row>
      <hr className="mb-4 mt-3" />

      <Row className="mt-4">
        <Col>
          <SimpleTable variant="bordered" headers={tableTitles} rows={tableRows} />
          {modalData && (
            <SimpleModal
              isOpen={modalIsOpen}
              toggle={() => setModalIsOpen(!modalIsOpen)}
              onActionItem={modalData.onActionItem}
              title={modalData.title}
              description={modalData.description}
              cancelButton={modalData.cancelButton}
              submitButton={modalData.submitButton}
              submitButtonColor={modalData.submitButtonColor}
            />
          )}
        </Col>
      </Row>
    </>
  );

  if (ttConfigs.length === 0) {
    content = (
      <EmptyState
        imgSrc="/assets/img/empty-states/in-progress.png"
        imgDescription={t('settings:Branding.TrackAndTrace.EmptyState.imgDescription')}
        title={t('settings:Branding.TrackAndTrace.EmptyState.title')}
        description={t('settings:Branding.TrackAndTrace.EmptyState.description')}
      />
    );
  }

  return (
    <>
      <Row className="mb-4">
        <Col>
          <h1>{t('settings:Branding.TrackAndTrace.title')}</h1>
        </Col>
        <Col className="d-flex justify-content-end align-items-start">
          {ttConfigs.length === 0 && !TTConfigLoadingQuery && (
            <Link
              to="/settings/branding/track-and-trace/new"
              className="btn btn-primary"
              data-test="new-track-and-trace-button"
            >
              <FontAwesomeIcon icon={['fas', 'plus']} fixedWidth />
              {t('settings:Branding.TrackAndTrace.Library.newPageButton')}
            </Link>
          )}
        </Col>
      </Row>
      {TTConfigLoadingQuery ? (<FoxLoader withOverlay={false} />) : (content)}
    </>
  );
};

export default TrackAndTrace;
