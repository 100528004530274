import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Navbar,
  Nav,
  NavItem,
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import Icon from 'Components/Presentational/Icon/Icon';
import SearchBar from 'Containers/SearchBar/SearchBar';
import { generateReactKey } from '#/shared/utilities';
import nav, { Navigation, NavigationDropdownItem } from '#/core/routes/nav/web.nav';

type Props = {
  user: {
    name: string;
    surname: string;
  };
  className?: string;
  hasActiveBranding: boolean;
}

const Topbar: React.FC<Props> = ({ user, className, hasActiveBranding }) => {
  const { t } = useTranslation();
  const navigation = nav(t, hasActiveBranding);

  const prepareDropdownItems = (items: NavigationDropdownItem[]) => {
    return items.map((child) => {
      if (child.type === 'divider') {
        return <DropdownItem divider key={generateReactKey()} />;
      }

      const { name, path, exact, icon } = child;

      return (
        <NavLink
          to={path}
          key={generateReactKey()}
          exact={exact}
          className="dropdown-item"
        >
          {icon && (
            <Icon
              type={icon.type}
              icon={icon.icon}
              className={icon.className}
              size={icon.size}
              style={icon.style}
            />
          )}
          {name}
        </NavLink>
      );
    });
  };

  const prepareItems = (items: Navigation) => {
    return items.map((item) => {
      if (item.type === 'link') {
        const { path, exact, icon } = item;

        return (
          <NavItem key={generateReactKey()}>
            <NavLink to={path} className="nav-link" exact={exact}>
              {icon && (
                <Icon
                  type={icon.type}
                  icon={icon.icon}
                  className={icon.className}
                  size={icon.size}
                  style={icon.style}
                />
              )}
              {item.name}
            </NavLink>
          </NavItem>
        );
      }

      const children = prepareDropdownItems(item.children);
      const { name, surname } = user;

      return (
        <UncontrolledDropdown nav inNavbar key={generateReactKey()}>
          <DropdownToggle nav caret className="user-menu">
            {/* TODO: Dropdown content should be dynamic */}
            <span className="initialsIcon">
              {`${name.charAt(0)}${surname.charAt(0)}`}
            </span>

            {`${name} ${surname}`}
          </DropdownToggle>
          <DropdownMenu right>
            {children}
          </DropdownMenu>
        </UncontrolledDropdown>
      );
    });
  };

  const items = prepareItems(navigation.topbar);

  return (
    <Navbar className={className} light expand="md" id="topbar-search">
      <Container>
        <Nav className="mr-auto" navbar>
          <SearchBar />
        </Nav>
        <Nav navbar>
          {items}
        </Nav>
      </Container>
    </Navbar>
  );
};

const mapStateToProps = (state: any) => ({ hasActiveBranding: state.userReducer.data.hasActiveBranding });
export default connect(mapStateToProps)(Topbar);
