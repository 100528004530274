import React from 'react';
import { withTranslation } from 'react-i18next';

import SettingsBase from 'Containers/Settings/_base/SettingsBase';
import SettingsLayout from 'Components/Settings/SettingsLayout';
import EditUserForm from './EditUserForm';

class EditUser extends SettingsBase {
  render() {
    return (
      <SettingsLayout
        goBack="/settings"
        leftBlock={this.state.leftBlock}
        rightBlock={this.state.rightBlock}
        checkedItems={this.state.checkedItems}
        isOpened
        isPartLoading={false}
      >
        <EditUserForm />
      </SettingsLayout>
    );
  }
}

export default withTranslation(['settings'])(EditUser);
