import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';

const TooltipWithAllEditingUsers = ({ editingUsers, tooltipTarget }) => (
  <UncontrolledTooltip placement="right" target={tooltipTarget}>
    Zásilku si prohlíží
    {' '}
    {editingUsers
      .map((user) => `${user.userName} ${user.userSurname}`)
      .join(', ')}
  </UncontrolledTooltip>
);

export default TooltipWithAllEditingUsers;
