import { GenericFieldHTMLAttributes, useFormikContext } from 'formik';
import { useCommonField } from 'foxdeli-shared';
import React, { ChangeEvent, useState } from 'react';
import { Form, FormControlProps, FormGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import fileToPathReader from 'Containers/Settings/Branding/helpers/fileToPathReader';
import './SimpleFileInput.scss';

type Props = Omit<FormControlProps, 'type'> & Omit<GenericFieldHTMLAttributes, 'type'> & {
  accept?: string;
  name: string;
  label?: string | JSX.Element;
  disabled?: boolean;
  imageDeleteEnabled?: boolean;
  initialPreviewUrl?: Nullable<string>;
  initialPlaceholderValue?: Nullable<string | number>;
  error?: string;
  onChangeEvent?: (event: ChangeEvent<HTMLInputElement>) => void;
};

const SimpleFileInput: React.FC<Props> = ({
  name,
  label,
  disabled = false,
  initialPreviewUrl,
  imageDeleteEnabled = false,
  error,
  onChangeEvent,
  ...props
}) => {
  let previewContent: JSX.Element;
  const { t } = useTranslation(['common']);
  const [preview, setPreview] = useState<Nullable<string>>(initialPreviewUrl || null);
  const [dragActive, setDragActive] = React.useState(false);
  const [eraseButtonVisible, setEraseButtonVisible] = React.useState(false);

  const inputRef = React.useRef<HTMLInputElement>();
  const [field, meta, helpers, flags] = useCommonField(name);
  const { setFieldValue } = useFormikContext();

  React.useEffect(() => {
    if (!field.value) {
      setPreview(null);
      setEraseButtonVisible(false);
    }

    if (initialPreviewUrl) {
      setPreview(initialPreviewUrl);
    }
  }, [field.value, initialPreviewUrl, name]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { files } = event.currentTarget;
    field.onChange(event);

    if (files && files[0]) {
      setFieldValue(name, files[0]);
      fileToPathReader(files[0], (callbackFile) => { setPreview(callbackFile); });
      setEraseButtonVisible(true);
    } else {
      setPreview(null);
      setFieldValue(name, null);
      setEraseButtonVisible(false);
    }

    if (onChangeEvent) onChangeEvent(event);
  };

  const handleDrag = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (event.type === 'dragenter' || event.type === 'dragover') {
      setDragActive(true);
    } else if (event.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (disabled) return;

    field.onChange(event);

    setDragActive(false);
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      const { files } = event.dataTransfer;
      setFieldValue(name, files[0]);
      fileToPathReader(files[0], (callbackFile) => { setPreview(callbackFile); });
      setEraseButtonVisible(true);
    } else {
      setEraseButtonVisible(false);
    }
  };

  const eraseImage = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    inputRef?.current?.value = null;
    setPreview(null);
    setEraseButtonVisible(false);
    setFieldValue(name, null);
    helpers.setTouched(true);
  };

  if (preview) {
    previewContent = <img className="simple-file-input-preview" src={preview} alt={t('common:SimpleFileInput.imagePreviewAlt')} />;
  } else {
    previewContent = <img className="simple-file-input-placeholder" src="/assets/img/upload.png" alt={t('common:SimpleFileInput.imagePlaceholderAlt')} />;
  }

  return (
    <FormGroup
      className={`simple-file-input ${flags.hasFeedback ? 'has-feedback' : ''} ${disabled ? 'simple-file-input--disabled' : ''}`.trim()}
      title={t('common:SimpleFileInput.title')}
    >
      <Form.Label className={dragActive ? 'simple-file-input-label drag-active' : 'simple-file-input-label'}>
        {label}
        {eraseButtonVisible && imageDeleteEnabled && (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
          <span onClick={eraseImage} className="simple-file-input-erase">x</span>
        )}
      </Form.Label>

      <div
        role="button"
        tabIndex={0}
        className="simple-file-input-wrapper"
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
        onClick={() => {
          if (disabled) return;

          inputRef.current?.click();
          helpers.setTouched(true);
        }}
        onBlur={() => inputRef.current?.blur()}
        onKeyDown={({ key }) => {
          if (key === 'Enter') inputRef.current?.click();
        }}
      >
        <Form.Control
          {...props}
          ref={inputRef}
          name={field.name}
          multiple={field.multiple}
          type="file"
          data-test={`file-input-${field.name}`}
          onChange={handleChange}
          isInvalid={flags.isInvalid}
          isValid={flags.isValid}
          disabled={disabled}
        />

        {previewContent}
      </div>

      {flags.hasFeedback && <Form.Control.Feedback type="invalid">{meta.error || error}</Form.Control.Feedback>}
    </FormGroup>
  );
};

export default SimpleFileInput;
