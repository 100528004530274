import React from 'react';
import {
  Button,
  CustomInput,
  Input,
  Spinner,
} from 'reactstrap';
import { FieldArray } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { stringifyNull } from 'Shared/utilities';
import SVGIcon from 'Components/Presentational/SVGIcon/SVGIcon';
import Tooltip from 'Components/Presentational/Tooltip/Tooltip';
import withValidation
  from 'Components/Presentational/FormExtensions/HoC/withValidation/withValidation';
import basePaths from 'Routes/paths/base.path';
import useModal from '~/hooks/useModal';
import CreateAgentCollectionPlaceModal
  from '../../Container/CreateAgentCollectionPlaceModal/CreateAgentCollectionPlaceModal';

const FormikInput = withValidation(CustomInput);

const CollectionPlacesUI = ({
  formik,
  isExternalPlaceIdHidden,
  isPostCodeHidden,
  isCollectionPlaceUpdateSupported,
  isRefreshLoading,
  supportsPickup,
  refreshCollectionPlaces,
}) => {
  const { t } = useTranslation(['settings']);
  const [isModalOpen, toggleModal] = useModal();

  const { handleChange, values, errors } = formik;

  const renderCollectionPlaces = () => values.collectionPlaces.map((cp, index) => (
    <div
      className={supportsPickup ? 'mb-5' : 'mb-3'}
      // eslint-disable-next-line react/no-array-index-key
      key={cp.name.toLowerCase().replace(' ', '') + index}
    >
      <div className="form-check">
        <FormikInput
          type="checkbox"
          name={`collectionPlaces.${index}.checked`}
          checked={values.collectionPlaces[index].checked === true}
          id={cp.id}
          label={cp.name}
          className="form-check-input strong"
          value
        />

        <div className="small">
          {cp.address.street}
          ,
          {' '}
          {cp.address.zip}
          {' '}
          {cp.address.city}
        </div>
      </div>

      <div className="details">
        {supportsPickup && (
          <>
            <div className="additional-check">
              <FormikInput
                type="radio"
                name={`collectionPlaces.${index}.regular`}
                value="regular"
                disabled={values.collectionPlaces[index].checked === false}
                id={`${values.collectionPlaces[index].regularId}-regular`}
                label={(
                  <>
                    {t('settings:Agents.CollectionPlaces.regularPickup')}
                    <Tooltip
                      id={`tooltip-collectionPlace-${index}-regular`}
                      linkText={(
                        <SVGIcon
                          icon="help"
                          className=""
                          style={{
                            maxHeight: '24px',
                            maxWidth: '24px',
                          }}
                        />
                      )}
                      text={t('settings:Agents.CollectionPlaces.regularPickupTooltip')}
                      linkIconHide
                      linkIcon={[]}
                      linkIconPosition="after"
                      placement="right"
                    />
                  </>
                )}
                className="form-check-input full-width"
              />
            </div>
            <div className="additional-check">
              <FormikInput
                type="radio"
                name={`collectionPlaces.${index}.regular`}
                value="automatic"
                disabled={values.collectionPlaces[index].checked === false}
                id={`${values.collectionPlaces[index].regularId}-automatic`}
                label={(
                  <>
                    {t('settings:Agents.CollectionPlaces.automaticPickup')}
                    <Tooltip
                      id={`tooltip-collectionPlace-${index}-automatic`}
                      linkText={(
                        <SVGIcon
                          icon="help"
                          className=""
                          style={{
                            maxHeight: '24px',
                            maxWidth: '24px',
                          }}
                        />
                      )}
                      text={t('settings:Agents.CollectionPlaces.automaticPickupTooltip')}
                      linkIconHide
                      linkIcon={[]}
                      linkIconPosition="after"
                      placement="right"
                    />
                  </>
                )}
                className="form-check-input full-width"
              />
            </div>
          </>
        )}

        {!isExternalPlaceIdHidden && (
          <>
            <label className="mt-2" htmlFor={`collectionPlaces.${index}.externalPlaceId`}>
              {t('settings:Agents.CollectionPlaces.ExternalPlaceId.label')}
            </label>
            <Input
              name={`collectionPlaces.${index}.externalPlaceId`}
              onChange={handleChange}
              value={stringifyNull(cp.externalPlaceId)}
            />
          </>
        )}

        {!isPostCodeHidden && (
          <>
            <label className="mt-3" htmlFor={`collectionPlaces.${index}.postCode`}>
              {t('settings:Agents.CollectionPlaces.PostCode.label')}
            </label>
            <Input
              name={`collectionPlaces.${index}.postCode`}
              onChange={handleChange}
              value={stringifyNull(cp.postCode)}
            />
          </>
        )}
      </div>
    </div>
  ));

  return (
    <div className="form-group">
      <div className="form-row">
        <div className="col-md-4">
          <h4>{t('settings:Agents.CollectionPlaces.title')}</h4>
        </div>

        <div className="col">
          {isCollectionPlaceUpdateSupported && (
            <>
              <div className="text-right mb-3">
                <Button
                  onClick={() => toggleModal(true)}
                  className="mr-3"
                >
                  {t('settings:Agents.CollectionPlaces.createAgentCollectionPlace')}
                </Button>

                <Button
                  onClick={() => refreshCollectionPlaces()}
                  disabled={isRefreshLoading}
                >
                  {isRefreshLoading && <Spinner size="sm" />}
                  &nbsp;
                  {t('settings:Agents.CollectionPlaces.refreshButton')}
                </Button>
              </div>

              <CreateAgentCollectionPlaceModal
                isOpen={isModalOpen}
                onToggle={toggleModal}
                refreshCollectionPlaces={refreshCollectionPlaces}
              />
            </>
          )}

          {values.collectionPlaces.length ? (
            <>
              <FieldArray
                name="collectionPlaces"
              >
                {(formikHelpers) => (
                  <>
                    {renderCollectionPlaces(formikHelpers)}
                  </>
                )}
              </FieldArray>
              {Object.keys(errors).length > 0 && (
                <div
                  style={{ display: 'block' }}
                  className="invalid-feedback"
                >
                  {errors.collectionPlaces}
                </div>
              )}
            </>
          ) : (
            <>
              <p className="mt-2 pt-1">
                {t('settings:Agents.CollectionPlaces.noCollectionPlaces')}
              </p>
              <Link
                to={`${basePaths.settings}/collection-places/new`}
                className="btn btn-primary"
              >
                {t('settings:Agents.CollectionPlaces.createCollectionPlace')}
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

CollectionPlacesUI.propTypes = {
  formik: PropTypes.shape({}).isRequired,
  isExternalPlaceIdHidden: PropTypes.bool.isRequired,
  isPostCodeHidden: PropTypes.bool.isRequired,
  isCollectionPlaceUpdateSupported: PropTypes.bool.isRequired,
  supportsPickup: PropTypes.bool.isRequired,
  isRefreshLoading: PropTypes.bool.isRequired,
  refreshCollectionPlaces: PropTypes.func.isRequired,
};

export default CollectionPlacesUI;
