import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Fade } from 'reactstrap';

import Icon from '#/Components/Presentational/Icon/Icon';
import css from './Guide.module.scss';

const Guide = ({ instructions, agentName }) => {
  const { t } = useTranslation('settings');

  if (!instructions) {
    return null;
  }

  return (
    <Fade>
      <div className={`${css.guide} mb-3`}>
        <h3 className="h6 text-transform-none">
          <Icon icon="informace" type="foxdeli" size="1" />

          {t('Agents.AgentPicker.connectAgentHelp')}
          {' '}
          {agentName}
          {'?'}
        </h3>

        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: instructions }} />
      </div>
    </Fade>
  );
};

Guide.defaultProps = { instructions: PropTypes.string };

Guide.propTypes = {
  agentName: PropTypes.string.isRequired,
  instructions: PropTypes.string,
};

export default Guide;
