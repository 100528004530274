import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardHeader, CardBody, Row, Col, Progress, Button } from 'reactstrap';
import { useTranslation, Trans } from 'react-i18next';

import { formatNumber, rx } from '~/services/utils';
import Cashflow from './Cashflow/Cashflow';
import ReturnsChart from './ReturnsChart/ReturnsChart';
import Duration from './Duration/Duration';
import AgentMetrics from './AgentMetrics/AgentMetrics';
import DeliveriesChart from '~/App/[Dashboard]/DashboardBody/DeliveriesChart/DeliveriesChart';
import { Agent } from '~/App/[Dashboard]/Dashboard';
import { DashboardStatistics } from '~/types/generated/graphql';

import css from './DashboardBody.module.scss';

type Props = {
  data?: DashboardStatistics;
  agents?: Agent[];
  resetDateRange: () => void;
}

const DashboardBody: React.FC<Props> = ({ data, agents, resetDateRange }) => {
  const { t } = useTranslation(['dashboard']);

  // Empty or null `deliveryCount.total` signifies no available data for the given date range
  // Empty `agents.length` is handled separately, takes precedence over empty `deliveryCount.total`
  if (!data || (!data?.deliveryCount.total && agents?.length)) {
    return (
      <div className="text-center">
        <img
          alt={t('dashboard:NoData.imageTitle')}
          src="/assets/img/dashboard/empty-state.png"
          width={400}
          height={280}
          className="mt-5"
        />
        <h1 className="h5 text-body mb-3">
          <Trans i18nKey="dashboard:NoData.title" />
        </h1>
        <p className="mb-4">
          <Trans i18nKey="dashboard:NoData.description" />
        </p>
        <Button
          color="outline-secondary"
          type="button"
          onClick={() => resetDateRange()}
        >
          {t('dashboard:NoData.resetButton')}
        </Button>
      </div>
    );
  }

  return (
    <>
      <Row>
        <Col xs={9}>
          <Row>
            <Col>
              <Card className={css.card}>
                <CardHeader>
                  {t('dashboard:DeliveryCard.title')}
                </CardHeader>

                <CardBody className="pt-0">
                  <div className={css.number}>
                    {formatNumber(data.deliveryCount.total)}
                  </div>

                  <div className="d-flex">
                    <div className={css.cell}>
                      <div className={css.progress}>
                        <Progress
                          value={rx(data.deliveryCount.delivered, data.deliveryCount.total)}
                          color="success"
                        />
                      </div>
                      <div className="text-success font-weight-bold">
                        {formatNumber(data.deliveryCount.delivered)}
                      </div>
                      <div>
                        {t('dashboard:DeliveryCard.delivered')}
                      </div>
                    </div>

                    <div className={css.cell}>
                      <div className={css.progress}>
                        <Progress
                          value={rx(data.deliveryCount.failed, data.deliveryCount.total)}
                          color="danger"
                        />
                      </div>
                      <div className="text-danger font-weight-bold">
                        {formatNumber(data.deliveryCount.failed)}
                      </div>
                      <div>
                        {t('dashboard:DeliveryCard.notDelivered')}
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col>
              <Cashflow
                currency="CZK"
                cashflow={data.cashflow}
                css={css}
              />
            </Col>

            <Col>
              <Cashflow
                currency="EUR"
                cashflow={data.cashflow}
                css={css}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <h3 className="py-4 my-0">
                {t('dashboard:ChartsCard.title')}
              </h3>

              <DeliveriesChart
                deliveryHistory={data.deliveryHistory}
                css={css}
              />
            </Col>
          </Row>
        </Col>

        <Col xs={3}>
          <ReturnsChart
            deliveryCount={data.deliveryCount}
            important={data.important}
            css={css}
          />
          <Duration
            deliveryDuration={data.deliveryDuration}
            pickupDuration={data.pickupDuration}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <h3 className="pt-4 pb-3 mb-0 mt-3">
            {t('dashboard:Agents.title')}
          </h3>

          {agents?.length ? (
            <AgentMetrics
              agentStats={data.agentStats}
              cashflow={data.cashflow}
            />
          ) : (
            <div className="text-center">
              <img
                alt={t('dashboard:Agents.NoData.imageTitle')}
                src="/assets/img/dashboard/agents-empty-state.png"
                width={400}
                height={280}
              />
              <h1 className="h5 text-body mt-5 mb-3">
                {t('dashboard:Agents.NoData.title')}
              </h1>
              <p className="mb-4">
                <Trans i18nKey="dashboard:Agents.NoData.description" />
              </p>
              <Link to="/settings/agents">
                <Button
                  color="primary"
                  type="button"
                >
                  {t('dashboard:Agents.NoData.connectButton')}
                </Button>
              </Link>
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

export default DashboardBody;
