import React from 'react';
import PropTypes from 'prop-types';
import { CustomInput } from 'reactstrap';

const deliveryCheckbox = (props) => (
  <CustomInput
    type="checkbox"
    checked={props.checked}
    onChange={() => props.onCheck(props.id, (props.data) && props.data)}
    id={`checkbox-${props.id}`}
    label=""
    className="d-flex align-self-center justify-content-center datatable-checkbox"
    value
    disabled={props.disabled}
  />
);

deliveryCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  onCheck: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

deliveryCheckbox.defaultProps = { disabled: false };
export default deliveryCheckbox;
