import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';

export const FORMIK_SCROLL_TARGET_CLASSNAME = 'formik-scroll-target';

const scrollToFirstInvalidField = (selector: string) => {
  const element = document.querySelector(`${selector}, .${FORMIK_SCROLL_TARGET_CLASSNAME}.is-invalid`);

  if (!element) return;

  element.scrollIntoView({
    behavior: 'smooth',
    block: 'center',
  });
};

/**
 * Automatically scrolls to the first invalid form field in Formik. `.form-control.is-invalid` is the default selector (works with Bootstrap 4)
 * Searches the DOM for the first occurrence of `.form-control.is-invalid` or `.formik-scroll-target` (see `FORMIK_SCROLL_TARGET_CLASSNAME`)
 */
const FormikScroll: React.FC = () => {
  const { isSubmitting, isValidating, isValid } = useFormikContext();

  useEffect(() => {
    if (isSubmitting && !isValidating && !isValid) {
      scrollToFirstInvalidField('.form-control.is-invalid, .form-control-file.is-invalid');
    }
  }, [isSubmitting, isValidating, isValid]);

  return null;
};

export default FormikScroll;
