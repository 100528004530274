// :: React
import React from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom';

// :: Components
import Icon from 'Components/Presentational/Icon/Icon';
import { Card, CardBody, CardHeader, Nav, NavItem, Spinner } from 'reactstrap';

// :: Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const prepareItems = (items) => items.map((item) => {
  let i = 0;
  const subItems = item.items.map((subItem) => {
    i += 1;
    return (
      <NavItem key={i}>
        <NavLink to={subItem.path} activeStyle={{ fontWeight: '500' }} className="none" key={i}>
          <Icon
            type={subItem.icon.type}
            icon={subItem.icon.icon}
            className={subItem.icon.className}
            size={subItem.icon.size}
            style={subItem.icon.style}
          />
                    &nbsp;
          {subItem.name}
        </NavLink>
      </NavItem>
    );
  });

  return (
    <div key={item.name}>
      <div className="small-title first">{ item.name }</div>
      <Nav vertical className="mb-4">
        {subItems}
      </Nav>
    </div>
  );
});

const settingsLeftBlock = (props) => {
  const {
    main,
    goBack,
    header: {
      title,
      subTitle,
    },
  } = props;

  return (
    <Card className="settings-sticky-card">
      <CardHeader>
        {goBack && (
          <div className="float-right mt-2">
            <Link to={goBack}>
              <FontAwesomeIcon icon={['fas', 'arrow-alt-circle-left']} className="text-muted card-back-btn" />
            </Link>
          </div>
        )}
        <div className="headline flex-grow-1">
          <div className="small-title first">{subTitle}</div>
          <h3 className="text-truncate" title={title}>{title || <Spinner />}</h3>
        </div>
        <hr />
      </CardHeader>
      <CardBody className="settings-left-block-menu-wrapper">
        {prepareItems(main)}
      </CardBody>
    </Card>
  );
};

settingsLeftBlock.propTypes = {
  // isOpened: PropTypes.bool,
  header: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
    button: PropTypes.shape({
      text: PropTypes.string.isRequired,
      class: PropTypes.shape({
        color: PropTypes.string,
        size: PropTypes.string,
        outline: PropTypes.bool,
      }).isRequired,
      icon: PropTypes.shape({
        class: PropTypes.array.isRequired,
        size: PropTypes.string,
      }),
      path: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  main: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        icon: PropTypes.shape({
          icon: PropTypes.string.isRequired,
          size: PropTypes.string,
        }),
      }),
    ),
  })).isRequired,
};

/* settingsLeftBlock.defaultProps = {
    isOpened: false,
}; */

export default settingsLeftBlock;
