import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { FormGroup } from 'reactstrap';
import { produce } from 'immer';
import { withTranslation } from 'react-i18next';

import CustomQuery from 'Models/Components/CustomQuery';
import AgentQueryFactory from 'Models/agent/Query/Factories/AgentQueryFactory';
import AgentFragments from 'Models/agent/Query/Fragments/AgentFragments';
import Accordion from 'Components/Presentational/Accordion/Accordion';
import DeliveryTypeModalUI from '../../Presentational/DeliveryTypeModalUI/DeliveryTypeModalUI';
import StartNumberAccordion from '../StartNumberAccordion/StartNumberAccordion';
import SequenceAccordion from '../SequenceAccordion/SequenceAccordion';

class DeliveryTypeSection extends React.Component {
  static formatStoredActiveServices(agentById, activeServices) {
    const activeDeliveryTypeIds = activeServices.length > 0
      ? activeServices.map((service) => service.deliveryType.id)
      : [];

    return agentById.deliveryTypes.map((type) => ({
      id: type.id,
      abbr: type.abbr,
      name: type.name,
      value: activeDeliveryTypeIds.includes(type.id),
      agentName: type.agent.name,
    }));
  }

  constructor(props) {
    super(props);

    const { initialInactiveServices } = props;
    this.state = { isModalVisible: false };
    this.hiddenServices = [...initialInactiveServices];
  }

  handleSubmit = (values) => {
    const { mergeAgentFormValues, activeServices } = this.props;
    const submittedDeliveryTypes = values.deliveryTypes;
    const typesToRemove = submittedDeliveryTypes.filter((type) => type.value === false);
    const typesToAdd = submittedDeliveryTypes.filter((type) => type.value === true);
    const getHiddenServiceIndex = (deliveryType) => this.hiddenServices
      .findIndex((service) => service.deliveryType.id === deliveryType.id);

    const formatServiceForAgentFormStore = (deliveryType) => {
      const hiddenServiceIndex = getHiddenServiceIndex(deliveryType);
      const hiddenService = this.hiddenServices[hiddenServiceIndex];

      return {
        deliveryType: {
          id: hiddenService && hiddenService
            .deliveryType.id ? hiddenService.deliveryType.id : deliveryType.id,
          abbr: deliveryType.abbr,
          name: deliveryType.name,
          agent: { name: deliveryType.agentName },
        },
        numberSequences: hiddenService ? hiddenService.numberSequences : null,
        startNumber: hiddenService ? hiddenService.startNumber : null,
        active: true,
        id: hiddenService && hiddenService.id ? hiddenService.id : null,
      };
    };

    const updatedServices = produce(activeServices, (draft) => {
      const draftServices = draft;

      const getCurrentServiceIndex = (deliveryType) => draftServices
        .findIndex((service) => service.deliveryType.id === deliveryType.id);

      const removeUncheckedTypes = (deliveryType) => {
        const storedServiceIndex = getCurrentServiceIndex(deliveryType);

        if (storedServiceIndex !== -1) {
          this.hiddenServices.push(activeServices[storedServiceIndex]);
          draftServices.splice(storedServiceIndex, 1);
        }
      };

      const addCheckedTypes = (deliveryType) => {
        const storedServiceIndex = getCurrentServiceIndex(deliveryType);
        if (storedServiceIndex === -1) {
          const hiddenServiceIndex = getHiddenServiceIndex(deliveryType);

          draftServices.push(formatServiceForAgentFormStore(deliveryType));
          this.hiddenServices.splice(hiddenServiceIndex, 1);
        }
      };

      typesToRemove.map(removeUncheckedTypes);
      typesToAdd.map(addCheckedTypes);
    });

    mergeAgentFormValues({ activeServices: updatedServices });
    this.toggleModal();
  };

  toggleModal = () => {
    const { isModalVisible } = this.state;

    this.setState({ isModalVisible: !isModalVisible });
  };

  toggleAllCheckboxesChecked = (formik) => {
    const { setValues, values } = formik;
    const { deliveryTypes } = values;
    const isEverythingChecked = !deliveryTypes.some((dt) => dt.value === false);

    setValues({
      deliveryTypes: deliveryTypes.map((dt) => ({
        ...(dt),
        value: !isEverythingChecked,
      })),
    });
  };

  createInitialValues(agentById) {
    const { activeServices } = this.props;
    const initialValues = DeliveryTypeSection.formatStoredActiveServices(agentById, activeServices);

    return { deliveryTypes: initialValues };
  }

  render() {
    const { t } = this.props;
    const { activeAgentId, activeServices, mergeAgentFormValues } = this.props;
    const { isModalVisible } = this.state;

    return (
      <CustomQuery
        query={AgentQueryFactory.agentById('...DeliveryTypeModal', AgentFragments.deliveryTypeModal())}
        variables={{ id: activeAgentId }}
      >
        {({ data, error, loading }) => {
          if (loading || error) return null;

          const { agentById } = data;
          const schema = agentById.credentialSettingsSchema;

          return (
            <Formik
              initialValues={this.createInitialValues(agentById)}
            >
              {(formik) => (
                <>
                  <div className="setting-header">
                    <h4>{t('settings:Agents.DeliveryServices.title')}</h4>
                    <DeliveryTypeModalUI
                      onSubmit={this.handleSubmit}
                      toggleModal={this.toggleModal}
                      toggleAllCheckboxesChecked={
                        this.toggleAllCheckboxesChecked
                      }
                      isModalVisible={isModalVisible}
                      agent={agentById}
                      formik={formik}
                      services={activeServices}
                    />
                  </div>
                  {schema.startNumber && activeServices.map((service) => (
                    <FormGroup key={service.deliveryType.id} className="mt-3">
                      <StartNumberAccordion
                        services={activeServices}
                        service={service}
                        mergeAgentFormValues={mergeAgentFormValues}
                      />
                    </FormGroup>
                  ))}
                  {schema.numberSequence && activeServices.map((service) => (
                    <FormGroup key={service.deliveryType.id} className="mt-3">
                      <SequenceAccordion
                        service={service}
                        services={activeServices}
                        mergeAgentFormValues={mergeAgentFormValues}
                      />
                    </FormGroup>
                  ))}
                  {!schema.numberSequence
                    && !schema.startNumber
                    && activeServices.map((service) => (
                      <FormGroup key={service.deliveryType.id} className="mt-3">
                        <Accordion title={(
                          <div className="row-element">
                            <h5 className="pl-3">
                              {service.deliveryType.name}
                            </h5>
                            <span>{service.deliveryType.abbr}</span>
                          </div>
                        )}
                        />
                      </FormGroup>
                    ))}
                </>
              )}
            </Formik>
          );
        }}
      </CustomQuery>
    );
  }
}

DeliveryTypeSection.propTypes = {
  activeAgentId: PropTypes.string.isRequired,
  mergeAgentFormValues: PropTypes.func.isRequired,
  activeServices: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  initialInactiveServices: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default (withTranslation(['settings', 'validation'])(DeliveryTypeSection));
