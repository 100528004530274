export default class ObjectMapper {
  static transformStoredServicesForApi(services) {
    return services.map((service) => {
      const startNumber = service.startNumber ? { number: service.startNumber.number } : null;

      return ({
        deliveryTypeId: service.deliveryType.id,
        numberSequences: ObjectMapper.transformStoredSequencesForApi(service.numberSequences),
        startNumber,
      });
    });
  }

  static transformExistingServicesForApi(services, existingServices) {
    const existingNumberSequenceIds = existingServices
      .map((svc) => svc.numberSequences || [])
      .reduce((acc, val) => [...acc, ...val], [])
      .map((sq) => sq.id);

    const existingStartNumberIds = existingServices
      .filter((svc) => svc.startNumber !== null)
      .map((svc) => svc.startNumber.id);

    return services.map((service) => ({
      id: service.id,
      newNumberSequences: ObjectMapper.transformStoredSequencesForApi(service.numberSequences, existingNumberSequenceIds),
      newStartNumber: service.startNumber && !existingStartNumberIds.includes(service.startNumber.id)
        ? { number: service.startNumber.number }
        : null,
    }));
  }

  static transformStoredSequencesForApi(sequences, existingNumberSequenceIds = []) {
    let result = null;

    if (sequences !== null) {
      const activeSequences = sequences
        .filter((number) => number !== null && number.active === true)
        .filter((num) => !existingNumberSequenceIds.includes(num.id));

      if (activeSequences.length !== 0) {
        result = activeSequences.map((sequence) => ({
          start: sequence.start,
          end: sequence.end,
          cod: sequence.cod,
        }));
      }
    }

    return result;
  }

  static transformExtraServicesForApi(extraServices) {
    return extraServices
      .filter((es) => es.checked)
      .map((es) => ({
        extraServiceId: es.id,
        default: es.selectedByDefault ?? false,
      }));
  }
}
