import React from 'react';
import { FormGroup, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import InputField from '~/components/InputField/InputField';

type Props = {
  inputNamePrefix?: string;
};

/**
 * Simple container for common form fields of EshopForm & EshopLocalizationForm
 */
const EshopContactForm: React.FC<Props> = ({ inputNamePrefix = '' }) => {
  const { t } = useTranslation(['settings', 'validation']);

  return (
    <>
      <FormGroup>
        <Label>{t('settings:Eshop.Contact.HomepageUrl.label')}</Label>
        <InputField
          name={`${inputNamePrefix}homepageUrl`}
          type="url"
          placeholder={t('settings:Eshop.Contact.HomepageUrl.placeholder')}
        />
      </FormGroup>
      <FormGroup>
        <Label>{t('settings:Eshop.Contact.ContactUrl.label')}</Label>
        <InputField
          name={`${inputNamePrefix}contactUrl`}
          type="url"
          placeholder={t('settings:Eshop.Contact.ContactUrl.placeholder')}
        />
      </FormGroup>
      <FormGroup>
        <Label>{t('settings:Eshop.Contact.TermsAndConditionsUrl.label')}</Label>
        <InputField
          name={`${inputNamePrefix}termsAndConditionsUrl`}
          type="url"
          placeholder={t('settings:Eshop.Contact.TermsAndConditionsUrl.placeholder')}
        />
      </FormGroup>
      <FormGroup>
        <Label>{t('settings:Eshop.Contact.PrivacyPolicyUrl.label')}</Label>
        <InputField
          name={`${inputNamePrefix}privacyPolicyUrl`}
          type="url"
          placeholder={t('settings:Eshop.Contact.PrivacyPolicyUrl.placeholder')}
        />
      </FormGroup>
      <FormGroup>
        <Label>{t('settings:Eshop.Contact.SupportEmail.label')}</Label>
        <InputField
          name={`${inputNamePrefix}supportEmail`}
          type="email"
          placeholder={t('settings:Eshop.Contact.SupportEmail.placeholder')}
        />
      </FormGroup>
      <FormGroup>
        <Label>{t('settings:Eshop.Contact.SupportPhone.label')}</Label>
        <InputField
          name={`${inputNamePrefix}supportPhone`}
          type="tel"
          placeholder={t('settings:Eshop.Contact.SupportPhone.placeholder')}
        />
      </FormGroup>
    </>
  );
};

export default EshopContactForm;
