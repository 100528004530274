import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { generate } from 'shortid';
import { Trans } from 'react-i18next';

import { remainingDays } from '~/services/utils';

const TOOLTIP_ID = `tooltip-${generate()}`;

type Props = {
  maxStoringDate: string;
}

const DeliveryInfoTooltip: React.FC<Props> = ({
  maxStoringDate,
  children,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const days = remainingDays(new Date(), new Date(maxStoringDate));

  const toggleTooltip = () => { setTooltipOpen(!tooltipOpen); };

  return (
    <>
      <span
        className="d-flex flex-row flex-nowrap"
        id={TOOLTIP_ID}
      >
        {children}
        <FontAwesomeIcon icon="info-circle" className="ml-2" />
      </span>

      <Tooltip
        placement="bottom"
        isOpen={tooltipOpen}
        target={TOOLTIP_ID}
        toggle={toggleTooltip}
      >
        <Trans
          i18nKey="deliveries:DeliveryDetail.Info.maxStoringDateTooltip"
          values={{
            daysLeft: days,
          }}
        />
      </Tooltip>
    </>
  );
};

export default DeliveryInfoTooltip;
