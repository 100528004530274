import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import * as actions from '#/store/actions/user.action';
import configurator from '~/services/Configurator';
import Auth from '~/services/Auth';
import Loader from '#/Components/Containers/Loader/Loader';
import Sidebar from '~/App/Sidebar/Sidebar';

type Props = {
  user: any;
  fetchUser: () => any;
};

const Main: React.FC<Props> = ({ user, fetchUser, children }) => {
  const [sidebarVisible] = useState<boolean>(true);
  const [isReady, setIsReady] = useState<boolean>(false);
  const { company } = user.data;

  useEffect(() => {
    fetchUser().then(() => { setIsReady(true); });
    // TODO: missing catch
  }, []);

  if (!user.isLoading && isReady) {
    if (Auth.hasRole('onboarding', user.data) || company === null) {
      window.location.replace(`${configurator.config.iamUrl}/onboarding`);
      return null;
    }

    if (Auth.hasRole('onboardingSetup', user.data) && !Auth.isAdmin) {
      window.location.replace(`${configurator.config.iamUrl}/welcome`);
      return null;
    }

    if (Auth.hasRole('subscriptionExpired', user.data)) {
      window.location.replace(`${configurator.config.iamUrl}/upgrade-licence`);
      return null;
    }
  }

  if (company === null) {
    return (
      <div id="layout" className="d-flex flex-column min-vh-100">
        <div id="content" className="logged--in">
          <Loader />
        </div>
      </div>
    );
  }

  return (
    <div id="layout" className="d-flex flex-column min-vh-100">
      <Sidebar sidebarVisible={sidebarVisible} />

      <div id="content" className="logged--in">
        {(!user.isLoading && isReady) ? children : <Loader />}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  user: {
    data: state.userReducer.data,
    isLoading: state.userReducer.isLoading,
    error: state.userReducer.error,
  },
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchUser: () => dispatch(actions.fetchUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
