import { format as dateFnsFormat, Locale } from 'date-fns';
import { cs, enGB } from 'date-fns/locale';

export const locales: { [k: string]: Locale } = {
  cs,
  // TODO: What about enUS etc?
  en: enGB,
};

/**
 * Localized date formatting function. Wrapper around date-fns `format()`
 * See https://date-fns.org/v2.28.0/docs/I18n
 */
export const format = (date: Date, formatString = 'PP'): string => {
  return dateFnsFormat(date, formatString, {
    locale: locales[window.__localeId__ ?? 'cs'],
  });
};
