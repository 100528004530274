import React from 'react';
import { Spinner } from 'reactstrap';

import CompanyQueryFactory
  from 'Models/company/Query/Factories/CompanyQueryFactory';
import CompanyQueryFragments
  from 'Models/company/Query/Fragments/CompanyQueryFragments';
import CustomQuery from 'Models/Components/CustomQuery';

const withCredentials = (Component) => (props) => (
  <CustomQuery
    query={CompanyQueryFactory.company('...CredentialEditForm', CompanyQueryFragments.credentialEditForm())}
    notifyOnNetworkStatusChange
  >
    {({ data, error, loading, refetch }) => {
      if (loading) return <Spinner />;
      if (error) return null;

      return (
        <Component
          {...props}
          credentials={data.company.credentials}
          refetchCredentials={refetch}
        />
      );
    }}
  </CustomQuery>
);

export default withCredentials;
