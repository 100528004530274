import { useState } from 'react';

export type ToggleModal = (force?: boolean) => void;

export type ModalProps = {
  isOpen: boolean;
  onToggle: ToggleModal;
  onSuccess?: () => void;
  onError?: () => void;
};

export default function useModal(): [boolean, ToggleModal] {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const toggleModal: ToggleModal = (force?: boolean) => setIsModalOpen(typeof force === 'undefined' ? !isModalOpen : force);

  return [isModalOpen, toggleModal];
}
