import React from 'react';

import './FoxLoader.scss';

type Props = {
  loaderText?: string;
  withOverlay?: boolean;
}

const FoxLoader: React.FC<Props> = ({ loaderText, withOverlay = true }) => {
  return (
    <div className={`fox-loader-wrapper ${withOverlay ? 'fox-loader-wrapper--overlay' : ''}`.trim()}>
      <div className="fox-loader">
        <div className="fox-loader-circle">
          <img
            className="fox-loader-image"
            src="/assets/img/snb-logo-symbol.svg"
            alt="loading"
            height={39}
            width={30}
          />
        </div>
        <div className="fox-loader-line-mask">
          <div className="fox-loader-line" />
        </div>
        { loaderText && <div className="fox-loader-text">{loaderText}</div>}
      </div>
    </div>
  );
};

export default FoxLoader;
