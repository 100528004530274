export const getItem = (key: string): string | null => {
  if (!window.localStorage) {
    return null;
  }

  return window.localStorage.getItem(key);
};

export const setItem = (key: string, value: string): void => {
  if (!window.localStorage) {
    return;
  }

  try {
    window.localStorage.setItem(key, value);
  } catch (e) {
    /**
     * Should only throw if the storage is full or when using Mobile Safari in private mode
     * see https://developer.mozilla.org/en-US/docs/Web/API/Storage/setItem#exceptions
     * Letting this one fail silently since we don't have any fallback now.
     */
  }
};
