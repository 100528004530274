import React from 'react';

import { customManualMutation } from 'Models/Components/CustomMutation';
import DeliveryQueryFactory from 'Models/delivery/Queries/Factories/DeliveryQueryFactory';
import { toast } from 'Shared/utilities';
import StopDeliveryProcessFormUI from '../../Presentational/StopDeliveryProcessFormUI/StopDeliveryProcessFormUI';

export default class StopDeliveryProcessForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      date: new Date(),
      note: '',
      confirm: false,
      state: 'delivered',
      submitted: false,
    };
  }

  hideContent = () => {
    const { modalService } = this.props;
    modalService.send('STOP_DELIVERY_PROCESS_MODAL_HIDDEN');
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const { confirm, state, note, date } = this.state;
    const { deliveryId, refetchDeliveryDetail } = this.props;

    this.setState({ submitted: true });

    if (!confirm) return;

    await customManualMutation({
      mutation: DeliveryQueryFactory.createDeliveryStopDelivering('id', ''),
      variables: {
        deliveryId,
        attributes: {
          type: state,
          dateStopDelivering: date,
          note,
        },
      },
    });

    refetchDeliveryDetail();
    this.hideContent();
    toast.success('Doručování ukončeno');
  };

  handleChange = (e) => {
    const { name, value, checked } = e.target;

    switch (e.target.type) {
      case 'checkbox':
        this.setState({ [name]: checked });
        break;
      default:
        this.setState({ [name]: value });
        break;
    }
  };

  render() {
    const { submitted } = this.state;
    const { modalState } = this.props;

    return (
      <StopDeliveryProcessFormUI
        submitted={submitted}
        values={this.state}
        isVisible={modalState.matches({ stopDeliveryProcess: 'shown' })}
        onChange={this.handleChange}
        hideContent={this.hideContent}
        handleSubmit={this.handleSubmit}
        handleChange={this.handleChange}
      />
    );
  }
}
