import Dexie from 'dexie';
import moment from 'moment';

class DexieDriver {
  constructor() {
    this.dbName = 'Foxdeli';
    this.init();
  }

  async init() {
    try {
      this.connect();
      this.initSchema();
      await this.setTableExpiration();
    } catch (e) {
      console.error(e);
    }
  }

  connect() {
    this.db = new Dexie(this.dbName);
  }

  initSchema() {
    this.db.version(1).stores({ countries: 'id,nameCs,nameEn,code,code3,currency,currencyName,inEu,phonePrefix,postalCodePattern,postalCodeExample' });
    this.db.version(2).stores({
      countries: 'id,nameCs,nameEn,code,code3,currency,currencyName,inEu,phonePrefix,postalCodePattern,postalCodeExample',
      tableExpiration: 'id,date',
    });
    this.db.version(3).stores({
      country: 'id,nameCs,nameEn,code,code3,currency,currencyName,inEu,phonePrefix,postalCodePattern,postalCodeExample',
      currency: 'name',
      tableExpiration: 'id,date',
    });
  }

  async setTableExpiration() {
    const expirationMs = await this.db.tableExpiration.get({ id: 0 });

    if (!expirationMs) {
      await this.db.tableExpiration.add({
        id: 0,
        tableExpiration: moment().add(1, 'day').valueOf(),
      });
    }
  }

  async clearDb() {
    try {
      let expirationMs = await this.db.tableExpiration.get({ id: 0 });
      expirationMs = expirationMs.tableExpiration;
      const currentTimeMs = moment().valueOf();

      if (currentTimeMs >= expirationMs) {
        await Promise.all(this.db.tables.map((table) => this.db[table.name].clear()));
      }
    } catch (e) {
      console.error(e);
    }
  }

  getDb() {
    return this.db;
  }
}

export default new DexieDriver();
