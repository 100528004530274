import React from 'react';
import { Formik } from 'formik';
import { produce } from 'immer';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { generateReactKey } from 'Shared/utilities';
import SequenceAccordionUI from '../../Presentational/SequenceAccordionUI/SequenceAccordionUI';
import SequenceModalUI from '../../Presentational/SequenceModalUI/SequenceModalUI';

class SequenceAccordion extends React.Component {
  static getNumbersCount(sequenceStart, sequenceEnd) {
    return sequenceEnd - sequenceStart;
  }

  constructor(props) {
    super(props);

    this.state = { isModalVisible: false };
    this.defaultFormValues = {
      start: '',
      end: '',
      cod: false,
    };
    this.isEditingSequence = false;
    this.editSequenceKey = null;
  }

  handleAddSequenceClick = (e, formik) => {
    e.stopPropagation();

    this.isEditingSequence = false;
    formik.resetForm();
    formik.setValues({ ...this.defaultFormValues });
    this.toggleModal();
  };

  toggleModal = () => {
    const { isModalVisible } = this.state;
    this.setState({ isModalVisible: !isModalVisible });
  };

  getInitialValues = () => {
    const { service } = this.props;
    const { numberSequences } = service;
    let res = null;

    if (!numberSequences) {
      res = this.defaultFormValues;
    } else {
      res = numberSequences;
    }

    return res;
  };

  handleSubmit = (values) => {
    const { service, services, mergeAgentFormValues } = this.props;

    const servicesWithUpdatedSequences = produce(services, (draft) => {
      const draftServices = draft;
      const currentServiceIndex = draftServices.findIndex((s) => s.deliveryType.id === service.deliveryType.id);
      const currentService = draftServices[currentServiceIndex];
      const created = new Date();
      const countNumbers = SequenceAccordion.getNumbersCount(values.start, values.end);
      const countRemainingNumbers = countNumbers;

      if (!currentService.numberSequences) currentService.numberSequences = [];

      if (!this.isEditingSequence) {
        currentService.numberSequences.push({
          ...values,
          active: true,
          key: generateReactKey(),
          created,
          countNumbers,
          countRemainingNumbers,
          manuallyAdded: true,
        });
      } else {
        const sequenceIndex = currentService.numberSequences.findIndex((sequence) => sequence.key === this.editSequenceKey);

        currentService.numberSequences[sequenceIndex] = {
          ...values,
          active: true,
          key: this.editSequenceKey || generateReactKey(),
          created,
          countNumbers,
          countRemainingNumbers,
          manuallyAdded: true,
        };
      }

      return draftServices;
    });

    mergeAgentFormValues({ activeServices: servicesWithUpdatedSequences });
    this.toggleModal();
  };

  editSequence = (sequenceKey, formik) => {
    const { service } = this.props;
    const sequence = service.numberSequences.find((s) => s.key === sequenceKey);

    this.isEditingSequence = true;
    this.editSequenceKey = sequenceKey;

    formik.resetForm();
    formik.setValues({ ...sequence });
    formik.setTouched({ ...Object.keys(sequence) });
    this.toggleModal();
  };

  render() {
    const { t, service } = this.props;
    const { isModalVisible } = this.state;

    return (
      <Formik
        initialValues={this.getInitialValues}
        validationSchema={
          Yup.object().shape({
            start: Yup.number()
              .required(t('settings:Agents.DeliveryServices.SequenceModal.required'))
              .typeError(t('settings:Agents.DeliveryServices.SequenceModal.typeError')),
            end: Yup.number()
              .required(t('settings:Agents.DeliveryServices.SequenceModal.required'))
              .typeError(t('settings:Agents.DeliveryServices.SequenceModal.typeError')),
          })
        }
      >
        {(formik) => (
          <div>
            <SequenceAccordionUI
              onAddSequenceClick={this.handleAddSequenceClick}
              sequences={service.numberSequences || []}
              service={service}
              editSequence={this.editSequence}
              formik={formik}
            />
            <SequenceModalUI
              toggleModal={this.toggleModal}
              isModalVisible={isModalVisible}
              formik={formik}
              onSubmit={this.handleSubmit}
              service={service}
              isEditingSequence={this.isEditingSequence}
            />
          </div>
        )}
      </Formik>
    );
  }
}

SequenceAccordion.propTypes = {
  services: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  service: PropTypes.shape({}).isRequired,
  mergeAgentFormValues: PropTypes.func.isRequired,
};

export default withTranslation(['settings'])(SequenceAccordion);
