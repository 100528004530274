import React, { useState } from 'react';
import { GenericFieldHTMLAttributes } from 'formik';
import { Button, Form, FormControlProps } from 'react-bootstrap';
import { useCommonField } from 'foxdeli-shared';
import Icon from '~/components/Icon/Icon';

import css from './PasswordField.module.scss';

type Props = FormControlProps & GenericFieldHTMLAttributes & {
  name: string;
  label?: string | JSX.Element;
};

const PasswordField: React.FC<Props> = ({
  name,
  label,
  ...props
}) => {
  const [revealed, setRevealed] = useState<boolean>(false);
  const [field, meta, , flags] = useCommonField(name);

  return (
    <>
      {label && (
        <Form.Label>
          {label}
        </Form.Label>
      )}

      <div className={`form-control-container ${flags.hasFeedback ? 'has-feedback' : ''}`.trim()}>
        <div className={css.inputGroup}>
          <Form.Control
            {...field}
            {...props}
            type={revealed ? 'text' : 'password'}
            isInvalid={flags.isInvalid}
            isValid={flags.isValid}
          />
          <button
            className={css.inputButton}
            type="button"
            onClick={() => { setRevealed(((prevState) => !prevState)); }}
          >
            <Icon name={revealed ? 'eye-off' : 'eye'} />
          </button>
        </div>

        {flags.hasFeedback && (
          <Form.Control.Feedback type="invalid" style={{ display: 'block' }}>
            {meta.error}
          </Form.Control.Feedback>
        )}
      </div>
    </>
  );
};

export default PasswordField;
