import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import produce from 'immer';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { interpret, Machine } from 'xstate';
import { withTranslation } from 'react-i18next';

import SearchBarUI from 'Components/Presentational/SearchBar/SearchBar';
import GlobalDataSource from 'Models/global/GlobalDataSource';
import { generateReactKey } from 'Shared/utilities';
import configurator from '~/services/Configurator';

class SearchBar extends React.Component {
  constructor(props) {
    super(props);

    this.isDayLimitSwitchShown = true;

    this.searchTimeoutMachine = Machine({
      initial: 'idle',
      states: {
        idle: { on: { TIMEOUT_STARTED: 'countingDown' } },
        countingDown: { on: { TIMEOUT_ENDED: 'idle' } },
      },
    });

    this.state = {
      isDayLimitActive: true,
      typeahead: {
        isLoading: false,
        options: [],
      },
      searchTimeout: this.searchTimeoutMachine.initialState,
    };

    this.global = new GlobalDataSource();
    this.searchTimeoutService = interpret(this.searchTimeoutMachine).onTransition((searchTimeout) => this.setState({ searchTimeout }));
    this.currentSearchKey = '';
  }

  componentDidMount() {
    this.searchTimeoutService.start();
  }

  componentWillUnmount() {
    this.searchTimeoutService.stop();
  }

  fetchData = async (searchKey) => {
    let searchResponse = null;
    const { isDayLimitActive } = this.state;

    try {
      searchResponse = await this.global.search(
        searchKey,
        10,
        (isDayLimitActive) ? 90 : null,
        'subjects { id contact { name surname } address { street city zip } }'
        + 'deliveries { id deliveryNumber type { agent { name } } variableSymbol recipient { contact { name surname } } }',
      );
      searchResponse = searchResponse.data.search;
    } catch (err) {
      return { searchKey, data: [] };
    }

    const subjects = searchResponse.subjects.map((subject) => ({
      type: 'contact',
      id: subject.id,
      ...subject.contact,
      ...subject.address,
    }));

    const deliveries = searchResponse.deliveries.map((delivery) => ({
      type: 'delivery',
      id: delivery.id,
      deliveryNumber: delivery.deliveryNumber,
      agent: delivery.type.agent.name,
      variableSymbol: delivery.variableSymbol,
      ...delivery.recipient.contact,
    }));

    return {
      searchKey,
      data: Array.concat(subjects, deliveries),
    };
  };

  handleSearch = (searchKey) => {
    const prevSearchKey = this.currentSearchKey;
    this.currentSearchKey = searchKey;
    const { isDayLimitActive } = this.state;

    if (isDayLimitActive === false && prevSearchKey !== this.currentSearchKey) {
      this.isDayLimitSwitchShown = true;
      this.setState({ isDayLimitActive: true }, () => this.startSearchTimeout());
    } else {
      this.startSearchTimeout();
    }
  };

  setDataFromApi = async (searchKey) => {
    const { searchKey: queryKey, data } = await this.fetchData(searchKey);

    if (queryKey !== this.currentSearchKey) {
      return;
    }

    this.addCustomOptions(data);

    const stateAfterDataFetched = produce(this.state, (draft) => {
      draft.typeahead.isLoading = false;
      draft.typeahead.options = data;
    });

    this.setState(stateAfterDataFetched);
  };

  toggleDayLimit = () => {
    const { isDayLimitActive } = this.state;
    this.isDayLimitSwitchShown = false;

    const state = produce(this.state, (draft) => {
      draft.isDayLimitActive = !isDayLimitActive;
      draft.typeahead.options = [];
    });

    this.setState(state, async () => {
      document.querySelector('.navbar .rbt-input-main.form-control.rbt-input').click();
      await this.handleSearch(this.currentSearchKey);
    });
  };

  handleChange = (options) => {
    const { history } = this.props;
    const selectedItem = options[0];

    if (!selectedItem) {
      return;
    }

    switch (selectedItem.type) {
      case 'contact':
        history.push(`/contacts/${selectedItem.id}`);
        break;
      case 'delivery':
        history.push(`/deliveries/${selectedItem.id}`);
        break;
      case 'component':
        if (selectedItem.onChange) {
          selectedItem.onChange();
        }

        break;
      default:
        break;
    }
  };

  addNotFoundOption(results) {
    const { t } = this.props;

    if (results.length > 0) return;

    results.push({
      type: 'component',
      id: generateReactKey(2),
      component: () => (
        <span>
          <FontAwesomeIcon icon={['far', 'circle']} fixedWidth />
          {' '}
          {t('Search.nothingFound')}
        </span>
      ),
    });
  }

  addCustomOptions(results) {
    this.addNotFoundOption(results);

    if (this.isDayLimitSwitchShown) {
      this.addShowAllDeliveriesAndContactsLink(results);
    }
  }

  addShowAllDeliveriesAndContactsLink(results) {
    const { t } = this.props;

    results.push({
      type: 'component',
      id: generateReactKey(1),
      onChange: this.toggleDayLimit,
      keepDropdownOpen: true,
      component: () => (
        <div
          role="row"
          tabIndex={0}
          onKeyPress={(e) => e.key === 'Enter' && this.toggleDayLimit()}
        >
          <span>
            <FontAwesomeIcon icon={['fas', 'archive']} color="#E71D73" fixedWidth />
            {' '}
            <span>
              {t('Search.deliveriesHistory')}
            </span>
          </span>
        </div>
      ),
    });
  }

  startSearchTimeout() {
    const { searchTimeout } = this.state;
    const timeoutMS = configurator.config.searchDebounce;
    const startTimeout = () => {
      this.searchTimeoutId = setTimeout(() => {
        const timeoutEnd = Date.now();
        const timeoutStart = timeoutEnd - timeoutMS;

        if (timeoutStart > this.lastKeyPress) {
          this.setDataFromApi(this.currentSearchKey);
          this.searchTimeoutService.send('TIMEOUT_ENDED');
        } else {
          clearTimeout(this.searchTimeoutId);
          startTimeout();
          this.searchTimeoutService.send('TIMEOUT_STARTED');
        }
      }, timeoutMS);
    };
    const resetOptions = () => {
      const resetSearchState = produce(this.state, (draft) => {
        draft.typeahead.isLoading = true;
        draft.typeahead.options = [];
      });

      this.setState(resetSearchState);
    };

    this.lastKeyPress = Date.now();

    if (searchTimeout.value === 'idle') {
      const { typeahead } = this.state;
      const { options } = typeahead;

      if (options.length > 0) {
        resetOptions();
      }

      this.searchTimeoutService.send('TIMEOUT_STARTED');
      startTimeout();
    }
  }

  render() {
    const { typeahead } = this.state;

    return (
      <SearchBarUI
        {...typeahead}
        searchKey={this.currentSearchKey}
        onSearch={this.handleSearch}
        onChange={this.handleChange}
      />
    );
  }
}

SearchBar.propTypes = { history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired };

export default withRouter(withTranslation(['common'])(SearchBar));
