import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { toast } from '#/shared/utilities';
import DeliveryQueryFactory from '#/core/models/delivery/Queries/Factories/DeliveryQueryFactory';

import { useTokenMutation } from '#/Components/Hooks/Apollo/useTokenMutation';
import Modal from '#/Components/Containers/ConfirmModal/ConfirmModal';

const REMOVE_SAVED_SETTINGS = DeliveryQueryFactory.deleteDeliveryCsvImportSettings(
  'id',
  '',
);

type Props = {
  settingsId: number;
  onRemove: () => any;
  className?: string;
}

const RemoveSavedSettings: React.FC<Props> = ({
  className,
  settingsId,
  onRemove,
}) => {
  const { t } = useTranslation('deliveries');

  const [isModalVisible, setModalVisible] = useState(false);
  const [isLinkLoading, setLinkLoading] = useState(false);
  const [dispatchRemoveSettings] = useTokenMutation(REMOVE_SAVED_SETTINGS);

  function renderSuccessToast() {
    toast.success(t('NewDeliveryCSV.Banner.settingsRemovedSuccess'));
  }

  function removeSettings() {
    setLinkLoading(true);

    return dispatchRemoveSettings({ variables: { id: settingsId } })
      .then(renderSuccessToast)
      .then(() => setLinkLoading(false))
      .then(() => setModalVisible(false))
      .then(onRemove);
  }

  return (
    <>
      {/* eslint-disable-next-line max-len */}
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <a
        className={className}
        style={{
          cursor: (isLinkLoading) ? 'progress' : 'pointer',
          userSelect: 'none',
        }}
        onClick={() => !isLinkLoading && setModalVisible(true)}
      >
        {isLinkLoading && t('NewDeliveryCSV.UploadSettings.removingMessage')}
        {!isLinkLoading && t('NewDeliveryCSV.UploadSettings.removeSavedButton')}
      </a>
      <Modal
        onCancel={() => setModalVisible(false)}
        onPrompt={() => removeSettings()}
        toggle={() => setModalVisible(!isModalVisible)}
        isOpen={isModalVisible}
        title={t('NewDeliveryCSV.UploadSettings.removeSavedModal.title')}
        body={t('NewDeliveryCSV.UploadSettings.removeSavedModal.description')}
        buttons={{
          prompt: {
            text: t('NewDeliveryCSV.UploadSettings.removeSavedModal.submitButton'),
            color: 'secondary',
          },
          cancel: {
            text: t('NewDeliveryCSV.UploadSettings.removeSavedModal.cancelButton'),
            color: 'link',
          },
        }}
      />
    </>
  );
};

export default RemoveSavedSettings;
