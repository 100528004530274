import React from 'react';
import { Button, Row, CardBody } from 'reactstrap';

import { LabelPosition, HandlePositionChange } from '../types';

type Props = {
  labelPosition: LabelPosition,
  onChange: HandlePositionChange,
};

const ModalMatrix4: React.FC<Props> = ({
  labelPosition,
  onChange,
}) => (
  <CardBody>
    <Row className="mb-2">
      <Button size="lg" className={labelPosition === 'first' ? 'active' : undefined} onClick={() => onChange('first')}>1</Button>
      <Button size="lg" className={labelPosition === 'second' ? 'active ml-2' : 'ml-2'} onClick={() => onChange('second')}>2</Button>
    </Row>
    <Row>
      <Button size="lg" className={labelPosition === 'third' ? 'active' : undefined} onClick={() => onChange('third')}>3</Button>
      <Button size="lg" className={labelPosition === 'fourth' ? 'active ml-2' : 'ml-2'} onClick={() => onChange('fourth')}>4</Button>
    </Row>
  </CardBody>
);

export default ModalMatrix4;
