import React, { useState } from 'react';
import { generate as generateId } from 'shortid';

import { Tooltip } from 'reactstrap';
import { Cell } from '../types';

import css from './ErrorTable.module.scss';

type Props = {
  cell: Cell;
}

const ErrorTableCell: React.FC<Props> = ({ cell }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const hasError = cell.error !== null;

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  return (
    <>
      <td
        className={hasError ? css.cellHasError : ''}
        id={cell.id}
      >
        {cell.value}
      </td>

      {hasError && (
        <Tooltip
          placement="auto"
          isOpen={tooltipOpen}
          target={cell.id}
          toggle={toggleTooltip}
          autohide={false}
        >
          {cell.error?.messages.map((message) => (
            <p key={generateId()}>
              {message}
            </p>
          ))}
        </Tooltip>
      )}
    </>
  );
};

export default ErrorTableCell;
