import React from 'react';

const iconNames = [
  'arrow-down-left',
  'arrow-up-right',
  'bar-chart-2',
  'calendar',
  'chevron-down',
  'chevron-up',
  'close',
  'grid',
  'info',
  'list',
  'more-horizontal',
  'more-vertical',
  'star',
  'alert-triangle',
  'eye-off',
  'eye',
] as const;

export type IconName = typeof iconNames[number];

// TODO: Extend HTMLElement (svg) props?
type Props = {
  name: IconName;
  className?: string;
  id?: string;
  style?: any; // TODO: type
};

const Icon: React.FC<Props> = ({ name, className, style }) => {
  // let cls = `${css.icon} ${css[name]} icon`;
  let cls = `icon icon-${name}`;

  if (className) {
    cls += ` ${className}`;
  }

  return (
    <svg
      className={cls}
      style={style}
    >
      <use xlinkHref={`/assets/icons/symbol-defs.svg#icon-${name}`} />
    </svg>
  );
};

export default Icon;
