import { uploadBannerImages, uploadCampaignDefaultImages } from 'Containers/Settings/Branding/MarketingCampaigns/helpers';
import MARKETING_CAMPAIGN_ACTIVATE from 'Containers/Settings/Branding/MarketingCampaigns/queries/activateMarketingCampaign.gql';
import { FormikHelpers } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { filterObjectEmptyProperties } from '../helpers/filterObjectEmptyProperties';
import FoxLoader from '~/components/FoxLoader/FoxLoader';
import {
  BannerItem, CampaignBannerCreationMutation, CampaignBannerCreationResponse,
  CampaignBannerInputValues, CampaignBannerUpdateMutation, CampaignBannerUpdateResponse,
  MarketingCampaignConfigMutation, MarketingCampaignCreationResponse, MarketingCampaignFormValues,
  marketingCampaignInitialValues, MarketingCampaignUpdateResponse, MutationMarketingCampaignActivate,
} from './typesAndDefaults';
import { toast } from '#/shared/utilities';
import useAuthMutation from '~/hooks/useAuthMutation';
import MarketingCampaignForm from './MarketingCampaignForm/MarketingCampaignForm';

import CREATE_MARKETING_CAMPAIGN from './queries/createMarketingCampaign.gql';
import UPDATE_MARKETING_CAMPAIGN from './queries/updateMarketingCampaign.gql';
import CREATE_MARKETING_CAMPAIGN_BANNER from './queries/createMarketingCampaignBanner.gql';
import UPDATE_MARKETING_CAMPAIGN_BANNER from './queries/updateMarketingCampaignBanner.gql';

const NewMarketingCampaign: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation(['settings']);
  const [formIsSubmitting, setFormIsSubmitting] = React.useState(false);

  const [createMarketingCampaignMutation] = useAuthMutation<MarketingCampaignCreationResponse, MarketingCampaignConfigMutation>(CREATE_MARKETING_CAMPAIGN);
  const [updateMarketingCampaignMutation] = useAuthMutation<MarketingCampaignUpdateResponse, MarketingCampaignConfigMutation>(UPDATE_MARKETING_CAMPAIGN);
  const [activateMarketingCampaignMutation] = useAuthMutation<MutationMarketingCampaignActivate, { id: string }>(MARKETING_CAMPAIGN_ACTIVATE);
  const [createMarketingCampaignBanner] = useAuthMutation<CampaignBannerCreationResponse, CampaignBannerCreationMutation>(CREATE_MARKETING_CAMPAIGN_BANNER);
  const [updateMarketingCampaignBanner] = useAuthMutation<CampaignBannerUpdateResponse, CampaignBannerUpdateMutation>(UPDATE_MARKETING_CAMPAIGN_BANNER);

  const uploadBannerImagesAndUpdateBannerEntity = async (bannerData: CampaignBannerInputValues, campaignBanner: BannerItem, bannerId: string) => {
    const awaitToBannerImagesUpload = await uploadBannerImages(bannerData, campaignBanner);
    const [desktopImagePromiseUploadData, mobileImagePromiseUploadData, emailImagePromiseUploadData] = awaitToBannerImagesUpload;

    await updateMarketingCampaignBanner({
      variables: {
        config: {
          ...bannerData,
          ...filterObjectEmptyProperties({
            trackAndTraceDesktopBannerId: desktopImagePromiseUploadData?.data.imageId,
            trackAndTraceMobileBannerId: mobileImagePromiseUploadData?.data.imageId,
            emailCommunicationBannerId: emailImagePromiseUploadData?.data.imageId,
          }),
        },
        eshopCampaignBannerId: bannerId,
      },
    });
  };

  const createBannerAndHandleImages = async (campaignId: string, campaignBanner: BannerItem) => {
    const awaitForBannersCreation = [];

    const bannerData: CampaignBannerInputValues = {
      isActive: true,
      country: campaignBanner.country,
      linkWeb: campaignBanner.linkWeb,
      linkEmail: campaignBanner.linkEmail,
      isTrackAndTraceActive: campaignBanner.isTrackAndTraceActive,
      isEmailCommunicationActive: campaignBanner.isEmailCommunicationActive,
    };

    const uploadedBannerResponse = await createMarketingCampaignBanner({ variables: { config: bannerData, eshopCampaignId: campaignId } });
    const bannerUploadData = uploadedBannerResponse.data?.createEshopCampaignBanner;

    if (bannerUploadData?.id) {
      awaitForBannersCreation.push(uploadBannerImagesAndUpdateBannerEntity(bannerData, campaignBanner, bannerUploadData.id));
    } else {
      awaitForBannersCreation.push(Promise.resolve());
    }

    await Promise.all(awaitForBannersCreation);
  };

  const uploadCampaignBanners = async (campaignId: string, campaignBanners: BannerItem[]) => {
    const awaitForBannersCreation = [];

    for (let i = 0; i < campaignBanners.length; i += 1) {
      const campaignBanner = campaignBanners[i];
      awaitForBannersCreation.push(createBannerAndHandleImages(campaignId, campaignBanner));
    }

    await Promise.all(awaitForBannersCreation);
  };

  const uploadCampaignData = async (campaignId: string, marketingCampaignValues: MarketingCampaignFormValues) => {
    const awaitToDefaultImagesUpload = await uploadCampaignDefaultImages(campaignId, marketingCampaignValues);
    const [defaultDesktopImagePromiseUploadData, defaultMobileImagePromiseUploadData, defaultEmailImagePromiseUploadData] = awaitToDefaultImagesUpload;

    await updateMarketingCampaignMutation({
      variables: {
        config: {
          // update not working without campaignName
          campaignName: marketingCampaignValues.campaignName,
          ...filterObjectEmptyProperties({
            defaultDesktopBannerId: defaultDesktopImagePromiseUploadData?.data.imageId,
            defaultMobileBannerId: defaultMobileImagePromiseUploadData?.data.imageId,
            defaultEmailBannerId: defaultEmailImagePromiseUploadData?.data.imageId,
          }),
        },
        id: campaignId,
      },
    });
  };

  const submitNewMarketingCampaign = async (
    marketingCampaignValues: MarketingCampaignFormValues,
    formikHelpers: FormikHelpers<MarketingCampaignFormValues>,
    onSubmitCallback: () => void,
  ) => {
    setFormIsSubmitting(true);
    try {
      const campaignCreationResponse = await createMarketingCampaignMutation({
        variables: { config: {
          campaignName: marketingCampaignValues.campaignName,
          linkWeb: marketingCampaignValues.linkWeb,
          linkEmail: marketingCampaignValues.linkEmail,
          isTrackAndTraceActive: marketingCampaignValues.isTrackAndTraceActive,
          isEmailCommunicationActive: marketingCampaignValues.isEmailCommunicationActive,
        } },
      });

      const campaignId = campaignCreationResponse?.data?.createEshopCampaign?.id;
      if (campaignId) {
        await uploadCampaignData(campaignId, marketingCampaignValues);

        if (marketingCampaignValues.eshopCampaignBanners.length > 0) {
          await uploadCampaignBanners(campaignId, marketingCampaignValues.eshopCampaignBanners);
        }

        if (marketingCampaignValues.activateAfterSave) {
          formikHelpers.setFieldValue('activateAfterSave', false);
          await activateMarketingCampaignMutation({ variables: { id: campaignId } });
          toast.success(t('settings:Branding.MarketingCampaigns.Message.templateCreatedAndActivated'));
        } else {
          toast.success(t('settings:Branding.MarketingCampaigns.Message.templateCreated'));
        }
      }

      formikHelpers.setSubmitting(false);
      setFormIsSubmitting(false);
      history.push('/settings/branding/marketing-campaigns');
    } catch (e) {
      // Handled by global error interceptor
      formikHelpers.setSubmitting(false);
      setFormIsSubmitting(false);
    }

    onSubmitCallback();
  };

  return (
    <>
      <h1 className="mb-5">{t('settings:Branding.MarketingCampaigns.newCampaignTitle')}</h1>
      <MarketingCampaignForm
        onSubmit={(values, formikHelpers, onSubmitCallback) => submitNewMarketingCampaign(values, formikHelpers, onSubmitCallback)}
        initialValues={marketingCampaignInitialValues}
      />
      { formIsSubmitting && <FoxLoader loaderText={t('settings:Branding.MarketingCampaigns.campaignFormInsertingText')} /> }
    </>
  );
};

export default NewMarketingCampaign;
