import React from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import DeliveryInfoTooltip from './DeliveryInfoTooltip/DeliveryInfoTooltip';
import ExtendFrameButtonPopover from './ExtendFrameButtonPopover/ExtendFrameButtonPopover';
import InfoRow from './InfoRow/InfoRow';
import { toast } from '#/shared/utilities';
import { format } from '~/services/date';

const DATE_FORMAT = 'EEEEEE, PP';

type Props = {
  delivery: any;
  onChange: () => void;
};

const DeliveryInfo: React.FC<Props> = ({ delivery, onChange }) => {
  const { t } = useTranslation('deliveries');

  const {
    externalId,
    referenceId,
    value,
    valueCurrency,
    variableSymbol,
    packagesCount,
    packagesWeight,
    ticketNote,
    source,
  } = delivery;

  const handleMaxStoringDateChange = () => {
    toast.success(t('deliveries:DeliveryDetail.Banner.maxStoringDateChangeSuccess'));

    onChange();
  };

  const { maxStoringDate } = delivery;

  return (
    <Card className="mb-4">
      <CardBody>
        <CardTitle className="text-dark font-weight-bold">
          {t('deliveries:DeliveryDetail.Info.title')}
        </CardTitle>

        <dl className="row mb-0">
          {delivery.currentAgent.id !== delivery.type.agent.id && (
            <>
              <InfoRow
                label={t('deliveries:DeliveryDetail.Info.intermediary')}
                value={(
                  <img
                    title={delivery.type.agent.originalName}
                    alt={delivery.type.agent.originalName}
                    src={delivery.type.agent.logo.url}
                    height={30}
                  />
                )}
              />

              <InfoRow
                label={t('deliveries:DeliveryDetail.Info.deliveryType')}
                value={delivery.type.name}
              />
            </>
          )}

          <InfoRow
            label={t('deliveries:DeliveryDetail.Info.ticketNote')}
            value={ticketNote}
          />

          <InfoRow
            label={t('deliveries:DeliveryDetail.Info.deliveryValue')}
            value={`${value} ${valueCurrency}`}
          />

          {delivery.cashOnDelivery && (
            <InfoRow
              label={t('deliveries:DeliveryDetail.Info.cashOnDelivery')}
              value={delivery.cashOnDelivery && `${delivery.cashOnDelivery} ${delivery.cashOnDeliveryCurrency}`}
            />
          )}

          {variableSymbol && (
            <InfoRow
              label={t('deliveries:DeliveryDetail.Info.variableSymbol')}
              value={variableSymbol}
            />
          )}

          <InfoRow
            label={t('deliveries:DeliveryDetail.Info.externalId')}
            value={externalId}
          />

          {referenceId !== null && (
            <InfoRow
              label={t('deliveries:DeliveryDetail.Info.referenceId')}
              value={referenceId}
            />
          )}

          <InfoRow
            label={t('deliveries:DeliveryDetail.Info.packagesCount')}
            value={packagesCount}
          />

          <InfoRow
            label={t('deliveries:DeliveryDetail.Info.packagesWeight')}
            value={packagesWeight && `${packagesWeight} kg`}
          />

          <InfoRow
            label={t('deliveries:DeliveryDetail.Info.deliveryCreatedDate')}
            value={delivery.created && format(new Date(delivery.created), DATE_FORMAT)}
            valueClassName="text-capitalize"
          />

          {maxStoringDate && (
            <InfoRow
              label={t('deliveries:DeliveryDetail.Info.maxStoringDate')}
              valueClassName="text-capitalize"
            >
              <DeliveryInfoTooltip maxStoringDate={maxStoringDate}>
                {
                  delivery.type.storingChangeAvailable
                    ? (
                      <ExtendFrameButtonPopover
                        delivery={delivery}
                        endDate={new Date(maxStoringDate)}
                        onSuccess={handleMaxStoringDateChange}
                      >
                        {format(new Date(maxStoringDate), DATE_FORMAT)}
                      </ExtendFrameButtonPopover>
                    )
                    : format(new Date(maxStoringDate), DATE_FORMAT)
                }
              </DeliveryInfoTooltip>
            </InfoRow>
          )}

          <InfoRow
            label={t('deliveries:DeliveryDetail.Info.source')}
            value={source}
          />
        </dl>
      </CardBody>
    </Card>
  );
};

export default DeliveryInfo;
