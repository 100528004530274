import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';

const RefreshDelivery = ({ mutationState, onRefreshClick }) => {
  const iconProps = {
    waiting: { icon: ['fas', 'redo'] },
    inProgress: {
      icon: ['fas', 'redo'],
      spin: true,
    },
  };

  return (
    <Button
      disabled={mutationState === 'inProgress'}
      color="link"
      onClick={onRefreshClick}
    >
      <FontAwesomeIcon {...iconProps[mutationState]} className="no-margin" />
    </Button>
  );
};

export default RefreshDelivery;

RefreshDelivery.defaultProps = { onRefreshClick: () => null };

RefreshDelivery.propTypes = {
  mutationState: PropTypes.string.isRequired,
  onRefreshClick: PropTypes.func,
};
