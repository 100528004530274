import { Card, CardBody, Col, Row } from 'reactstrap';
import React from 'react';

type Props = {
  message: string;
}

const PageBodyError: React.FC<Props> = ({ message }) => (
  <Row>
    <Col>
      <Card>
        <CardBody>
          {message}
        </CardBody>
      </Card>
    </Col>
  </Row>
);

export default PageBodyError;
