import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import stringPropertyIsSet from 'Containers/Settings/Branding/helpers/stringPropertyIsSet';
import MARKETING_CAMPAIGNS_LIST from 'Containers/Settings/Branding/MarketingCampaigns/queries/fetchMarketingCampaigns.gql';
import { QueryMarketingCampaignsListData } from 'Containers/Settings/Branding/MarketingCampaigns/typesAndDefaults';
import { EshopBrandingDataQuery, ACCEPTED_FILE_TYPES } from 'Containers/Settings/Branding/typesAndDefaults';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { debounce } from 'lodash';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, FormGroup, Form, Label, Row, Col } from 'reactstrap';
import { useHistory } from 'react-router-dom';

import ColorPicker from 'Components/Presentational/ColorPicker/ColorPicker';
import withValidation from 'Components/Presentational/FormExtensions/HoC/withValidation/withValidation';
import fileToPathReader from 'Containers/Settings/Branding/helpers/fileToPathReader';
import getFullPathUrlOrDefault from 'Containers/Settings/Branding/helpers/getFullPathUrlOrDefault';
import { TrackAndTraceValues } from 'Containers/Settings/Branding/TrackAndTrace/typesAndDefaults';
import Tooltip from 'Components/Presentational/Tooltip/Tooltip';
import DeliveryRatingPreview, { resolveDeliveryRatingColors } from '~/components/DeliveryRatingPreview/DeliveryRatingPreview';
import InlineFormRow from '~/components/InlineFormRow/InlineFormRow';
import InputField from '~/components/InputField/InputField';
import ModalWithPreview from '~/components/ModalWithPreview/ModalWithPreview';
import OnUserLeavePageDialog from '~/components/OnUserLeavePageDialog/OnUserLeavePageDialog';
import SimpleFileInput from '~/components/SimpleFileInput/SimpleFileInput';
import SimpleSwitch from '~/components/SimpleSwitch/SimpleSwitch';
import SubmitButton from '~/components/SubmitButton/SubmitButton';
import TrackAndTracePreview, { TrackAndTracePreviewSettings } from '~/components/TrackAndTracePreview/TrackAndTracePreview';
import FormikScroll from '~/components/FormikScroll/FormikScroll';
import useAuthQuery from '~/hooks/useAuthQuery';
import { validationSchema } from './validationSchema';

import GET_ESHOP_BRANDING_DATA from '../../queries/getEshopBrandingData.gql';

const FormikColorPicker = withValidation(ColorPicker);

type Props = {
  onSubmit: (values: TrackAndTraceValues, formikHelpers: FormikHelpers<TrackAndTraceValues>, onSubmitCallback: () => void) => Promise<void>,
  initialValues: TrackAndTraceValues,
}

const TrackAndTraceForm: React.FC<Props> = ({ onSubmit, initialValues }) => {
  const { t } = useTranslation(['settings']);
  const history = useHistory();
  const [optionalColorsSettingsVisible, setOptionalColorsSettingsVisible] = useState(!!(initialValues && (
    initialValues.headerColor || initialValues.footerColor || initialValues.buttonColor
    || initialValues.buttonBackgroundColor || initialValues.progressBarColor
  )));
  const [logoPreview, setLogoPreview] = useState('');
  const [desktopBackgroundPreview, setDesktopBackgroundPreview] = useState('');
  const formRef = React.useRef<FormikProps<TrackAndTraceValues>>(null);
  const { data: eshopQueryData } = useAuthQuery<{ eshop: EshopBrandingDataQuery }, never>(GET_ESHOP_BRANDING_DATA);
  const { data: marketingCampaignsDataQuery } = useAuthQuery<QueryMarketingCampaignsListData>(MARKETING_CAMPAIGNS_LIST);
  const activeMarketingCampaignBannerUrl = marketingCampaignsDataQuery?.eshop?.activeEshopCampaign?.defaultDesktopBanner?.url;

  const [canRecalculateRatingColors, setCanRecalculateRatingColors] = useState(true);
  const [formIsSaving, setFormIsSaving] = useState(false);
  const [canRecalculateBaseTTColors, setCanRecalculateBaseTTColors] = useState(true);

  useEffect(() => {
    if (stringPropertyIsSet(initialValues.selectedTagBackgroundColor)) {
      setCanRecalculateRatingColors(false);
    }

    if (stringPropertyIsSet(initialValues.linkColor)) {
      setCanRecalculateBaseTTColors(false);
    }
  }, [initialValues.selectedTagBackgroundColor, initialValues.linkColor]);

  // eslint-disable-next-line react-hooks/exhaustive-deps,max-len
  const recalculateDeliveryRatingColors = useCallback(debounce((primaryColorOverride: string | undefined, setFieldValue?: any, recalculateRatingColors?: boolean) => {
    if (!recalculateRatingColors) return;

    const calculatedColors = resolveDeliveryRatingColors({
      selectedTagBackgroundColor: '',
      selectedTagColor: '',
      tagBackgroundColor: '',
      tagColor: '',
      primaryColor: primaryColorOverride,
    });

    setFieldValue('selectedTagBackgroundColor', calculatedColors.selectedTagBackgroundColor);
    setFieldValue('selectedTagColor', calculatedColors.selectedTagColor);
    setFieldValue('tagBackgroundColor', calculatedColors.tagBackgroundColor);
    setFieldValue('tagColor', calculatedColors.tagColor);
  }, 100), []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const recalculateBaseTTColors = useCallback(debounce((primaryColorOverride: string | undefined, setFieldValue?: any, recalculateBaseColors?: boolean) => {
    if (!recalculateBaseColors) return;

    setFieldValue('linkColor', primaryColorOverride);
    setFieldValue('headerBackgroundColor', '#ffffff');
    setFieldValue('footerBackgroundColor', '#ffffff');
  }, 50), []);

  const handleSubmit = async (values: TrackAndTraceValues, formikHelpers: FormikHelpers<TrackAndTraceValues>) => {
    setFormIsSaving(true);
    formikHelpers.setSubmitting(true);
    await onSubmit(values, formikHelpers, () => setFormIsSaving(false));
  };

  const preparePreviewData = (trackAndTraceValues: TrackAndTraceValues): TrackAndTracePreviewSettings => ({
    primaryColor: trackAndTraceValues.primaryColor,
    linkColor: trackAndTraceValues.linkColor,
    headerColor: trackAndTraceValues.headerColor,
    headerBackgroundColor: trackAndTraceValues.headerBackgroundColor,
    footerColor: trackAndTraceValues.footerColor,
    footerBackgroundColor: trackAndTraceValues.footerBackgroundColor,
    buttonColor: trackAndTraceValues.buttonColor,
    buttonBackgroundColor: trackAndTraceValues.buttonBackgroundColor,
    progressBarColor: trackAndTraceValues.progressBarColor,

    logo: logoPreview || getFullPathUrlOrDefault(trackAndTraceValues?.logo?.url),
    background: desktopBackgroundPreview || getFullPathUrlOrDefault(trackAndTraceValues?.desktopBackgroundImage?.url),
    eshopName: eshopQueryData?.eshop?.name,
    activeMarketingCampaignBannerUrl: getFullPathUrlOrDefault(activeMarketingCampaignBannerUrl),
  });

  const generatePreview = (file: File | string | undefined, name: string): void => {
    if (file === undefined) return;

    if (typeof file === 'string') {
      if (name === 'desktopBackground') {
        setDesktopBackgroundPreview(file);
      } else if (name === 'logo') {
        setLogoPreview(file);
      }
      return;
    }

    fileToPathReader(file, (callbackFile) => {
      if (name === 'desktopBackground') {
        setDesktopBackgroundPreview(callbackFile);
      } else if (name === 'logo') {
        setLogoPreview(callbackFile);
      }
    });
  };

  const designDescriptionWithTTPreview = (values: TrackAndTraceValues): JSX.Element => {
    return (
      <>
        {t('settings:Branding.TrackAndTrace.Form.Design.description')}
        <div className="pt-3 branding-sticky-preview">
          <ModalWithPreview
            modalTitle={t('settings:Branding.TrackAndTrace.PreviewModal.title')}
            modalCancelButtonText={t('settings:Branding.TrackAndTrace.PreviewModal.closeButton')}
          >
            <TrackAndTracePreview previewSettings={preparePreviewData(values)} />
          </ModalWithPreview>
        </div>
      </>
    );
  };

  const deliveryRatingDescriptionWithPreview = (values: TrackAndTraceValues): JSX.Element => {
    return (
      <>
        {t('settings:Branding.TrackAndTrace.Form.DeliveryRating.description')}
        <div className="pt-3">
          <ModalWithPreview
            modalTitle={t('settings:Branding.TrackAndTrace.PreviewModal.deliveryRatingTitle')}
            modalCancelButtonText={t('settings:Branding.TrackAndTrace.PreviewModal.closeButton')}
          >
            <DeliveryRatingPreview previewSettings={values} />
          </ModalWithPreview>
        </div>
      </>
    );
  };

  const getDeliveryRatingWithDefaultColors = (values: TrackAndTraceValues, setFieldValue: any): JSX.Element => (
    <>
      <FormikColorPicker
        name="selectedTagBackgroundColor"
        label={t('settings:Branding.TrackAndTrace.Form.Design.selectedTagBackgroundColorLabel')}
        color={values.selectedTagBackgroundColor || ''}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setCanRecalculateRatingColors(false);
          setFieldValue('selectedTagBackgroundColor', e.target.value);
        }}
      />
      <FormikColorPicker
        name="selectedTagColor"
        label={t('settings:Branding.TrackAndTrace.Form.Design.selectedTagColorLabel')}
        color={values.selectedTagColor || ''}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setCanRecalculateRatingColors(false);
          setFieldValue('selectedTagColor', e.target.value);
        }}
      />
      <FormikColorPicker
        name="tagBackgroundColor"
        label={t('settings:Branding.TrackAndTrace.Form.Design.tagBackgroundColorLabel')}
        color={values.tagBackgroundColor || ''}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setCanRecalculateRatingColors(false);
          setFieldValue('tagBackgroundColor', e.target.value);
        }}
      />
      <FormikColorPicker
        name="tagColor"
        label={t('settings:Branding.TrackAndTrace.Form.Design.tagColorLabel')}
        color={values.tagColor || ''}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setCanRecalculateRatingColors(false);
          setFieldValue('tagColor', e.target.value);
        }}
      />
      <Button
        color="flat-blue"
        type="button"
        className="mt-4"
        data-test="calculate-rating-colors-by-primary-color"
        onClick={() => {
          recalculateDeliveryRatingColors(values.primaryColor, setFieldValue, true);
        }}
      >
        {t('settings:Branding.TrackAndTrace.Form.Design.fillByPrimaryColor')}
      </Button>
    </>
  );

  return (
    <Formik
      enableReinitialize
      innerRef={formRef}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema(t)}
    >
      {({ handleSubmit: formikHandleSubmit, isSubmitting, values, resetForm, submitForm, setFieldValue, dirty }) => (
        <Form onSubmit={formikHandleSubmit} className="track-and-trace-form">
          <FormikScroll />
          <InlineFormRow
            sectionTitle={t('settings:Branding.TrackAndTrace.Form.TrackingPageName.title')}
            description={t('settings:Branding.TrackAndTrace.Form.TrackingPageName.description')}
          >
            <FormGroup>
              <Label>
                {t('settings:Branding.TrackAndTrace.Form.TrackingPageName.label')}
                <span className="branding-required-field">*</span>
              </Label>
              <InputField
                name="title"
                type="text"
                placeholder={t('settings:Branding.TrackAndTrace.Form.TrackingPageName.placeholder')}
              />
            </FormGroup>
          </InlineFormRow>

          <hr className="mb-5 mt-4" />
          <InlineFormRow
            sectionTitle={t('settings:Branding.TrackAndTrace.Form.Logo.title')}
            description={t('settings:Branding.TrackAndTrace.Form.Logo.description', { width: 120, height: 48 })}
          >
            <Row>
              <Col xs={6}>
                <FormGroup>
                  <SimpleFileInput
                    label={t('settings:Branding.TrackAndTrace.Form.Logo.logoLabel')}
                    name="logoImg"
                    accept={ACCEPTED_FILE_TYPES.Web}
                    initialPreviewUrl={getFullPathUrlOrDefault(values.logo?.url)}
                    onChangeEvent={({ target: { files } }: ChangeEvent<HTMLInputElement>) => generatePreview(files?.[0] || values.logo?.url, 'logo')}
                  />
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup>
                  <SimpleFileInput
                    label={(
                      <>
                        {t('settings:Branding.TrackAndTrace.Form.Logo.faviconLabel')}
                        <Tooltip
                          id="tt-form-favicon-tooltip"
                          text={t('settings:Branding.TrackAndTrace.Form.Logo.faviconTooltip')}
                          linkIconPosition="after"
                          placement="right"
                        />
                      </>
                    )}
                    name="faviconImg"
                    accept={ACCEPTED_FILE_TYPES.Web}
                    initialPreviewUrl={getFullPathUrlOrDefault(values.favicon?.url)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </InlineFormRow>

          <hr className="mb-5 mt-4" />
          <InlineFormRow
            sectionTitle={t('settings:Branding.TrackAndTrace.Form.Design.title')}
            description={designDescriptionWithTTPreview(values)}
            className="mb-5"
          >
            <FormGroup>
              <h3>{t('settings:Branding.TrackAndTrace.Form.Design.brandColors')}</h3>
              <FormikColorPicker
                name="primaryColor"
                label={(
                  <>
                    {t('settings:Branding.TrackAndTrace.Form.Design.primaryColorLabel')}
                    <span className="branding-required-field">*</span>
                  </>
                )}
                color={values.primaryColor || ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldValue('primaryColor', e.target.value);
                  recalculateDeliveryRatingColors(e.target.value, setFieldValue, canRecalculateRatingColors);
                  recalculateBaseTTColors(e.target.value, setFieldValue, canRecalculateBaseTTColors);
                }}
              />
              <FormikColorPicker
                name="linkColor"
                label={(
                  <>
                    {t('settings:Branding.TrackAndTrace.Form.Design.linkColorLabel')}
                    <span className="branding-required-field">*</span>
                  </>
                )}
                color={values.linkColor || ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setCanRecalculateBaseTTColors(false);
                  setFieldValue('linkColor', e.target.value);
                }}
              />
              <FormikColorPicker
                name="headerBackgroundColor"
                label={(
                  <>
                    {t('settings:Branding.TrackAndTrace.Form.Design.headerBackgroundColorLabel')}
                    <span className="branding-required-field">*</span>
                  </>
                )}
                color={values.headerBackgroundColor || ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setCanRecalculateBaseTTColors(false);
                  setFieldValue('headerBackgroundColor', e.target.value);
                }}
              />
              <FormikColorPicker
                name="footerBackgroundColor"
                label={(
                  <>
                    {t('settings:Branding.TrackAndTrace.Form.Design.footerBackgroundColorLabel')}
                    <span className="branding-required-field">*</span>
                  </>
                )}
                color={values.footerBackgroundColor || ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setCanRecalculateBaseTTColors(false);
                  setFieldValue('footerBackgroundColor', e.target.value);
                }}
              />

              {optionalColorsSettingsVisible && (
                <>
                  <FormikColorPicker
                    name="headerColor"
                    label={t('settings:Branding.TrackAndTrace.Form.Design.headerColorLabel')}
                    color={values.headerColor || ''}
                  />
                  <FormikColorPicker
                    name="footerColor"
                    label={t('settings:Branding.TrackAndTrace.Form.Design.footerColorLabel')}
                    color={values.footerColor || ''}
                  />
                  <FormikColorPicker
                    name="buttonColor"
                    label={t('settings:Branding.TrackAndTrace.Form.Design.buttonColorLabel')}
                    color={values.buttonColor || ''}
                  />
                  <FormikColorPicker
                    name="buttonBackgroundColor"
                    label={t('settings:Branding.TrackAndTrace.Form.Design.buttonBackgroundColorLabel')}
                    color={values.buttonBackgroundColor || ''}
                  />
                  <FormikColorPicker
                    name="progressBarColor"
                    label={t('settings:Branding.TrackAndTrace.Form.Design.progressBarColorLabel')}
                    color={values.progressBarColor || ''}
                  />
                </>
              )}

              <Button
                color="flat-blue"
                type="button"
                className="mt-4"
                data-test="toggle-optional-colors"
                onClick={() => setOptionalColorsSettingsVisible(!optionalColorsSettingsVisible)}
              >
                {optionalColorsSettingsVisible
                  ? <FontAwesomeIcon icon={['fas', 'minus']} />
                  : <FontAwesomeIcon icon={['fas', 'plus']} />}

                {optionalColorsSettingsVisible
                  ? t('settings:Branding.TrackAndTrace.Form.Design.hideOptionalColorsSettings')
                  : t('settings:Branding.TrackAndTrace.Form.Design.showOptionalColorsSettings')}
              </Button>
            </FormGroup>
          </InlineFormRow>

          <InlineFormRow
            sectionTitle={t('settings:Branding.TrackAndTrace.Form.Background.title')}
            description={t('settings:Branding.TrackAndTrace.Form.Background.description', { width: 1920, height: 1080, mobileWidth: 390, mobileHeight: 120 })}
          >
            <Row>
              <Col xs={6}>
                <SimpleFileInput
                  label={t('settings:Branding.TrackAndTrace.Form.Design.backgroundDesktopLabel')}
                  name="desktopBackground"
                  accept={ACCEPTED_FILE_TYPES.Web}
                  initialPreviewUrl={getFullPathUrlOrDefault(values.desktopBackgroundImage?.url)}
                  onChangeEvent={({ target: { files } }: ChangeEvent<HTMLInputElement>) => generatePreview(
                    files?.[0] || values.desktopBackgroundImage?.url,
                    'desktopBackground',
                  )}
                />
              </Col>
              <Col xs={6}>
                <SimpleFileInput
                  label={t('settings:Branding.TrackAndTrace.Form.Design.backgroundMobileLabel')}
                  name="mobileBackground"
                  accept={ACCEPTED_FILE_TYPES.Web}
                  initialPreviewUrl={getFullPathUrlOrDefault(values.mobileBackgroundImage?.url)}
                />
              </Col>
            </Row>
          </InlineFormRow>

          <hr className="mb-5 mt-4" />
          <InlineFormRow
            sectionTitle={t('settings:Branding.TrackAndTrace.Form.DeliveryRating.title')}
            description={deliveryRatingDescriptionWithPreview(values)}
          >
            <SimpleSwitch
              name="enabledRating"
              badgeStatus={values.enabledRating}
              onChange={(value: boolean) => {
                if (value) {
                  recalculateDeliveryRatingColors(values.primaryColor, setFieldValue, canRecalculateRatingColors);
                }
              }}
            />
            {values.enabledRating && getDeliveryRatingWithDefaultColors(values, setFieldValue)}
          </InlineFormRow>

          <hr className="mb-3" />

          <div className="branding-form-controls">
            <SubmitButton data-test="submit-track-and-trace-form" color="primary" type="submit" isLoading={isSubmitting}>
              {t('settings:Branding.TrackAndTrace.Form.submit')}
            </SubmitButton>
            <SubmitButton
              data-test="submit-and-activate-track-and-trace-form"
              className="ml-2 mr-5"
              color="primary"
              outline
              onClick={async () => {
                setFieldValue('activateAfterSave', true);
                await submitForm();
              }}
              isLoading={isSubmitting}
            >
              {t('settings:Branding.TrackAndTrace.Form.submitAndActivate')}
            </SubmitButton>
            <Button
              data-test="close-track-and-trace-form"
              className="align-middle ml-md-auto mr-2"
              color="danger"
              outline
              onClick={() => history.push('/settings/branding/track-and-trace')}
            >
              {t('settings:Branding.TrackAndTrace.Form.cancel')}
            </Button>
            <Button color="dark" size="xs" disabled={!dirty} onClick={() => resetForm()}>
              <FontAwesomeIcon icon={['fas', 'trash']} fixedWidth />
              {t('settings:Branding.TrackAndTrace.Form.discardChanges')}
            </Button>
          </div>
          { dirty && !formIsSaving && <OnUserLeavePageDialog /> }
        </Form>
      )}
    </Formik>
  );
};

export default TrackAndTraceForm;
