import React from 'react';
import PropTypes from 'prop-types';
import produce from 'immer';
import { withTranslation } from 'react-i18next';

import Select from 'Components/Presentational/Select/Select';
import { countries as countryTypes } from 'Models/subject/types';
import { CountryManagerContext } from 'Containers/CountryManager/CountryManager';

class CountrySelect extends React.Component {
  onChange = (option, select) => {
    const { onChange } = this.props;
    onChange(select.name, option);
  };

  formatCountries(countries, t, language = 'cs') {
    const { defaultLabel } = this.props;
    const recent = ['CZ', 'SK', 'PL', 'DE', 'AT'];
    const optionsRecent = [];
    const optionsOthers = [];
    const options = [
      {
        value: '',
        label: defaultLabel || t('validation:CountrySelect.placeholder'),
      },
      {
        label: t('validation:CountrySelect.optionsRecentLabel'),
        options: optionsRecent,
      },
      {
        label: t('validation:CountrySelect.optionsOthersLabel'),
        options: optionsOthers,
      },
    ];

    countries.forEach((item) => {
      const label = language === 'cs' ? item.nameCs : item.nameEn;
      const option = {
        value: item.code,
        label: label ?? item.nameCs,
      };

      if (recent.includes(item.code)) {
        optionsRecent.push(option);
      } else {
        optionsOthers.push(option);
      }
    });

    return options;
  }

  render() {
    const { t, i18n, placeholder } = this.props;
    const inputProps = produce(this.props, (draft) => {
      delete draft.countries;
    });

    return (
      <CountryManagerContext.Consumer>
        {(countryManager) => (
          <Select
            {...inputProps}
            onChange={this.onChange}
            options={this.formatCountries(countryManager.countries, t, i18n.language)}
            classNamePrefix="select"
            placeholder={placeholder}
          />
        )}
      </CountryManagerContext.Consumer>
    );
  }
}

CountrySelect.defaultProps = {
  countries: null,
  placeholder: null,
  defaultLabel: null,
};

/* eslint-disable react/require-default-props */
CountrySelect.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.shape(countryTypes)),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  invalid: PropTypes.bool,
  valid: PropTypes.bool,
  defaultValue: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ]),
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  defaultLabel: PropTypes.string,
};

export default withTranslation(['validation'])(CountrySelect);
