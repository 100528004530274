import gql from 'graphql-tag';

export default class AgentFragments {
  static credentialFormDeliveryTypeInfo() {
    return gql`
            fragment CredentialFormDeliveryTypeInfo on DeliveryType {
                id
                name
                abbr
                agent { name }
            }
        `;
  }

  static agentPreview() {
    return gql`
            fragment AgentPreview on Agent {
                id
                name
                fullName
                originalName
                logoSlug
                logo { url }
            }
        `;
  }

  static credentialEditForm() {
    return gql`
            fragment CredentialForm on Agent {
                id
                name
                deliveryTypes { name }
                extraServices(companyOnly: true) {
                    id
                    code
                    name
                    selectedByDefault
                    description
                    allowedForApi
                    allowedForApp
                    allowedForCsv
                }
            }
        `;
  }

  static credentialFormAgent() {
    return gql`
            fragment CredentialFormAgent on Agent {
                id
                name
                abbr
                deliveryTypes {
                    ...CredentialFormDeliveryTypeInfo
                }
                extraServices {
                    id
                    code
                    name
                    description
                    allowedForApi
                    allowedForApp
                    allowedForCsv
                    selectedByDefault
                }
               credentialSettingsSchema {
                    techNumber
                    userName
                    userNameLabel
                    password
                    passwordLabel
                    companyCode
                    companyCodeLabel
                    techNumber
                    supportForCollectionPlaces
                    startNumber
                    numberSequence
                    configurationInstructions
                }
            }
            ${AgentFragments.credentialFormDeliveryTypeInfo()}
        `;
  }

  static deliveryTypeModal() {
    return gql`
            fragment DeliveryTypeModal on Agent {
                fullName
                deliveryTypes {
                    ...CredentialFormDeliveryTypeInfo
                }
                credentialSettingsSchema {
                    startNumber
                    numberSequence
                }
            }
            ${AgentFragments.credentialFormDeliveryTypeInfo()}
        `;
  }
}
