import gql from 'graphql-tag';
import BaseDataSource from '../BaseDataSource';
import AuthenticationHelper from '~/services/Auth';

export default class EshopDataSource extends BaseDataSource {
  constructor() {
    super();
    this.defaultEshopFields = `
            name
            webUrl
            logo {
                name
                url
            }
            platform
        `;
  }

  async eshop(fields = this.defaultEshopFields) {
    const query = gql`
      query eshop {
        eshop {
          ${fields}
        }
      }
    `;

    return this.client.query({
      query,
      context: { headers: { AccessToken: AuthenticationHelper.accessToken } },
    });
  }

  async updateEshop(name, webUrl, logo) {
    const mutation = gql`
      mutation updateEshop($eshop: InputEshopUpdate!) {
        updateEshop(eshop: $eshop) {
          name
        }
      }
    `;

    const variables = { eshop: { name, webUrl, logo } };

    return this.client.mutate({
      mutation,
      variables,
      context: { headers: { AccessToken: AuthenticationHelper.accessToken } },
    });
  }
}
