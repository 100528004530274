import {
  Col,
  FormFeedback,
  FormGroup,
  Label,
  Row,
} from 'reactstrap';
import React from 'react';
import Select from 'react-select';
import { useTranslation, Trans } from 'react-i18next';

import CountrySelect from 'Containers/CountrySelect/CountrySelect';
import CurrencySelect from 'Containers/CurrencySelect/CurrencySelect';
import PropTypes from 'prop-types';

const DefaultValues = (props) => {
  const { t } = useTranslation('deliveries');

  const renderAgentOptions = () => {
    const { agents } = props;

    const options = agents.map((item) => (
      { value: item.id, label: item.name }
    ));

    options.push({
      value: '',
      label: t('NewDeliveryCSV.DefaultValues.defaultLabel'),
    });

    return options;
  };

  const formatExtraServicesForMultiselect = () => {
    const extraServices = [];
    const { extraServiceList } = props;

    extraServiceList.forEach((service) => {
      extraServices.push({
        value: service.code,
        label: service.name,
      });
    });

    return extraServices;
  };

  const renderDeliveryTypes = () => {
    const { agents, activeAgentIndex } = props;

    if (activeAgentIndex === null) return [];

    const deliveryTypeOptions = agents[activeAgentIndex].deliveryTypes.map((deliveryType) => ({
      value: deliveryType.abbr,
      label: deliveryType.name,
    }));

    deliveryTypeOptions.push({
      value: '',
      label: t('NewDeliveryCSV.DefaultValues.defaultLabel'),
    });

    return deliveryTypeOptions;
  };

  const {
    values,
    touched,
    errors,
    onAgentChange,
    onExtraServiceChange,
    extraServices,
    setFieldValue,
    handleBlur,
  } = props;

  return (
    <Row className="pt-5">
      <Col md="4">
        <h4>{t('NewDeliveryCSV.DefaultValues.title')}</h4>
        <span className="text-muted">
          <Trans i18nKey="deliveries:NewDeliveryCSV.DefaultValues.description" />
        </span>
      </Col>
      <Col md="6">
        <FormGroup>
          <Label htmlFor="carrier">
            {t('NewDeliveryCSV.DefaultValues.agentLabel')}
          </Label>
          <Select
            name="carrier"
            value={values.carrier}
            options={renderAgentOptions()}
            classNamePrefix="select"
            onChange={onAgentChange(setFieldValue)}
            placeholder={t('NewDeliveryCSV.DefaultValues.placeholder')}
          />
          {touched.carrier && errors.carrier
          && <FormFeedback>{errors.carrier}</FormFeedback>}
        </FormGroup>
        <FormGroup>
          <Label htmlFor="deliveryType">
            {t('NewDeliveryCSV.DefaultValues.deliveryTypeLabel')}
          </Label>
          <Select
            name="deliveryType"
            value={values.deliveryType}
            options={renderDeliveryTypes()}
            classNamePrefix="select"
            onChange={
              (option, select) => setFieldValue(
                select.name, option,
              )
            }
            noOptionsMessage={() => t('NewDeliveryCSV.DefaultValues.noOptionsMessage')}
            placeholder={t('NewDeliveryCSV.DefaultValues.placeholder')}
          />
          {touched.carrier && errors.carrier
          && <FormFeedback>{errors.carrier}</FormFeedback>}
        </FormGroup>
        <FormGroup>
          <Label htmlFor="additionalServices">
            {t('NewDeliveryCSV.DefaultValues.extraServicesLabel')}
          </Label>
          <Select
            isMulti
            name="additionalServices"
            value={extraServices}
            options={formatExtraServicesForMultiselect()}
            classNamePrefix="select"
            onChange={onExtraServiceChange}
            placeholder={t('NewDeliveryCSV.DefaultValues.placeholder')}
            noOptionsMessage={() => t('NewDeliveryCSV.DefaultValues.noOptionsMessage')}
          />
          {touched.additionalServices && errors.additionalServices
          && <FormFeedback>{errors.additionalServices}</FormFeedback>}
        </FormGroup>
        <FormGroup>
          <Label htmlFor="recipientState">
            {t('NewDeliveryCSV.DefaultValues.recipientCountryLabel')}
          </Label>
          <CountrySelect
            name="recipientState"
            onChange={setFieldValue}
            onBlur={handleBlur}
            value={values.recipientState}
            defaultLabel={t('NewDeliveryCSV.DefaultValues.defaultLabel')}
            placeholder={t('NewDeliveryCSV.DefaultValues.placeholder')}
          />
          {touched.recipientState && errors.recipientState
          && <FormFeedback className="d-block">{errors.recipientState}</FormFeedback>}
        </FormGroup>
        <div className="form-row">
          <FormGroup className="col-md-6">
            <Label htmlFor="shipmentCurrency">
              {t('NewDeliveryCSV.DefaultValues.deliveryCurrencyLabel')}
            </Label>
            <CurrencySelect
              name="shipmentCurrency"
              value={values.shipmentCurrency}
              classNamePrefix="select"
              onChange={setFieldValue}
              defaultLabel={t('NewDeliveryCSV.DefaultValues.defaultLabel')}
              placeholder={t('NewDeliveryCSV.DefaultValues.placeholder')}
            />
            {touched.shipmentCurrency && errors.shipmentCurrency
            && <FormFeedback>{errors.shipmentCurrency}</FormFeedback>}
          </FormGroup>
          <FormGroup className="col-md-6">
            <Label htmlFor="cashOnDeliveryCurrency">
              {t('NewDeliveryCSV.DefaultValues.cashOnDeliveryCurrencyLabel')}
            </Label>
            <CurrencySelect
              name="cashOnDeliveryCurrency"
              value={values.cashOnDeliveryCurrency}
              classNamePrefix="select"
              onChange={setFieldValue}
              defaultLabel={t('NewDeliveryCSV.DefaultValues.defaultLabel')}
              placeholder={t('NewDeliveryCSV.DefaultValues.placeholder')}
            />
            {touched.cashOnDeliveryCurrency
            && errors.cashOnDeliveryCurrency
            && (
              <FormFeedback>
                {errors.cashOnDeliveryCurrency}
              </FormFeedback>
            )}
          </FormGroup>
        </div>
      </Col>
    </Row>
  );
};

DefaultValues.defaultProps = {
  activeAgentIndex: null,
  extraServices: null,
};

DefaultValues.propTypes = {
  agents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  activeAgentIndex: PropTypes.number,
  errors: PropTypes.shape({}).isRequired,
  values: PropTypes.shape({}).isRequired,
  touched: PropTypes.shape({}).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  extraServices: PropTypes.arrayOf(PropTypes.shape({})),
  extraServiceList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onAgentChange: PropTypes.func.isRequired,
  onExtraServiceChange: PropTypes.func.isRequired,
};

export default DefaultValues;
