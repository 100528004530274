import { TFunction } from 'i18next';
import { mixed, object, string } from 'yup';

export const validationSchema = (t: TFunction) => {
  return object().shape({
    title: string()
      .required(t('validation:TemplateName.required'))
      .max(255, t('validation:TemplateName.maxLength', { count: 255 })),
    sender: string().required(t('settings:Branding.Mailing.Form.Sender.Validation.name')),
    senderEmail: string()
      .email(t('validation:Email.pattern'))
      .required(t('settings:Branding.Mailing.Form.Sender.Validation.email'))
      .max(255, t('validation:Email.maxLength', { count: 255 })),
    logoImg: mixed(),
    primaryColor: string().required(t('settings:Branding.Mailing.Form.Design.Validation.primaryColor')),
    linkColor: string().required(t('settings:Branding.Mailing.Form.Design.Validation.linkColor')),
    headerColor: string().required(t('settings:Branding.Mailing.Form.Design.Validation.headerColor')),
    headerBackgroundColor: string().required(t('settings:Branding.Mailing.Form.Design.Validation.headerBackgroundColor')),
    buttonColor: string().nullable(),
    buttonBackgroundColor: string().nullable(),
  });
};
