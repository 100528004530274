import React from 'react';

import { Label, FormFeedback } from 'reactstrap';
import { ValueType, ActionMeta } from 'react-select';
import { useCommonField } from 'foxdeli-shared';

import CustomSelect, { Props as CustomSelectProps } from '../CustomSelect/CustomSelect';

type Props = CustomSelectProps & {
  component?: any;
  name: string;
  label?: string;
  disabled?: boolean;
  options?: CustomSelectProps['options'];
  onBlur?: (event?: React.FocusEvent<any>) => void;
  onChange?: (CustomSelectProps['onChange']);
};

const SelectField: React.FC<Props> = ({
  component = CustomSelect,
  name,
  label,
  disabled = false,
  options = [],
  onBlur,
  onChange,
  ...props
}) => {
  const [field, meta, helpers, flags] = useCommonField(name);

  const labelEl = label && (
    <Label htmlFor={field.name}>
      {label}
    </Label>
  );

  const handleBlur = (event: React.FocusEvent<any>): void => {
    helpers.setTouched(true);

    if (onBlur) {
      onBlur(event);
    }

    // field.onBlur(event);
  };

  // TODO: proper types
  const handleChange = (option: ValueType<any>, action: ActionMeta<any>): void => {
    if (onChange) {
      onChange(option, action);

      helpers.setValue(option?.value);
    } else {
      helpers.setValue(option?.value);
    }

    // field.onChange();
  };

  // TODO: use props generics? https://stackoverflow.com/questions/53958028/how-to-use-generics-in-props-in-react-in-a-functional-component/59373728#59373728
  const Component = component as any;

  return (
    <>
      {labelEl}

      <Component
        {...props}
        name={name}
        id={field.name}
        isDisabled={disabled}
        options={options}
        value={field.value}
        onBlur={handleBlur}
        onChange={handleChange}
        classNamePrefix="select"
      />

      {flags.hasFeedback && (
        <FormFeedback className={flags.isInvalid ? 'd-block' : ''}>
          {meta.error}
        </FormFeedback>
      )}
    </>
  );
};

export default SelectField;
