import gql from 'graphql-tag';

export default class CountryQueryFactory {
  static countries(fields, fragments) {
    return gql`
            query countries {
                countries {
                    ${fields}
                }
            }
            ${fragments}`;
  }
}
