import React from 'react';
import { DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

const StopDeliveryProcess = ({ canStopDeliveryProcess, onClick }) => {
  const { t } = useTranslation('deliveries');

  return (
    <DropdownItem disabled={!canStopDeliveryProcess} onClick={onClick}>
      <FontAwesomeIcon icon={['fas', 'stop-circle']} fixedWidth />
      {t('DeliveryDetail.Menu.stopDeliveryProcess')}
    </DropdownItem>
  );
};

export default StopDeliveryProcess;
