import { CustomInput } from 'reactstrap';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function ToggleAllCheckboxesButton(props) {
  const { t } = useTranslation(['common']);
  const { toggleCheckboxCheck, values, id } = props;
  const hasCheckedAllCheckboxes = values.every((val) => val === true);

  return (
    <CustomInput
      id={id}
      type="checkbox"
      onChange={toggleCheckboxCheck}
      checked={hasCheckedAllCheckboxes}
      value={hasCheckedAllCheckboxes}
      label={t('common:selectAllButton')}
      className="checkbox-modal"
    />
  );
}
