import React, { useState, useEffect, useRef } from 'react';

import { ModalProps } from '~/hooks/useModal';
import DeliveryDataSource from '#/core/models/delivery/DeliveryDataSource';
import Base64Helper from '#/core/tools/Base64Helper';
import ModalUi from './ModalUi/ModalUi';

import deliveryQuery from './delivery.gql';

function downloadBlob(blobUrl: string, filename: string) {
  const a = document.createElement('a');
  a.href = blobUrl;
  a.download = filename;
  a.click();

  return a;
}

type Props = ModalProps & {
  deliveryIds: any[];
};

const TicketPrintModal: React.FC<Props> = ({
  isOpen,
  onToggle,
  deliveryIds,
}) => {
  const [maxTicketPositions, setMaxTicketPositions] = useState<number>(4);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const delivery = new DeliveryDataSource();
  const isFirstRender = useRef(true);

  const fetchTicketData = async () => {
    if (deliveryIds.length === 0) {
      return;
    }

    const agents: any[] = [];
    const agentInfoQueries: any[] = [];

    deliveryIds.forEach((id) => {
      const query = delivery.query({ deliveryById: deliveryQuery }, { id });
      agentInfoQueries.push(query);
    });

    const agentQueryResponse = await Promise.all(agentInfoQueries);

    agentQueryResponse.forEach((val) => agents.push(val.data.type.agent));

    const hasMultipleAgents = !(agents.every((agent) => agent.abbr === agents[0].abbr));

    if (hasMultipleAgents) {
      onToggle();
      return;
    }

    setMaxTicketPositions(agents[0].maxTicketPositions);
  };

  useEffect(() => {
    if (isFirstRender.current) {
      fetchTicketData();
      isFirstRender.current = false;

      return;
    }

    // TODO: check if we still need the isOpen mode
    // eslint-disable-next-line no-self-compare
    if (isOpen && JSON.stringify(deliveryIds) !== JSON.stringify(deliveryIds)) {
      fetchTicketData();
    }
  });

  const handleTicketPrintPrompt = async (labelType: any, labelPosition: any) => {
    let mediumType = null;
    let startPosition = 'first';
    let rotate = null;

    if (labelType) {
      mediumType = 'roll';
      rotate = labelPosition === 'second';
    } else {
      mediumType = 'sheet';
      startPosition = labelPosition;
      rotate = false;
    }

    try {
      setIsLoading(true);
      const response = await delivery.getDeliveriesTickets(
        deliveryIds,
        mediumType,
        startPosition,
        rotate,
      );
      const { rawSource: encryptedPDF, name: pdfName } = response.data.getDeliveriesTickets;
      const blobUrl = Base64Helper.createLinkForEncryptedFile(encryptedPDF, 'application/pdf');

      downloadBlob(blobUrl, pdfName);

      onToggle();
    } catch (e) {
      onToggle();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalUi
      isOpen={isOpen}
      isLoading={isLoading}
      matrixType={maxTicketPositions}
      onCancel={() => { onToggle(); }}
      onPrompt={handleTicketPrintPrompt}
    />
  );
};

export default TicketPrintModal;
