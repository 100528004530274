import type { OperationVariables } from '@apollo/react-common';
import type { MutationTuple, MutationHookOptions } from '@apollo/react-hooks';
import { DocumentNode } from 'graphql';
import { useMutation } from 'react-apollo';

import AuthenticationHelper from '~/services/Auth';

function useAuthMutation<TData = any, TVariables = OperationVariables>(
  mutation: DocumentNode,
  options?: MutationHookOptions<TData, TVariables>,
): MutationTuple<TData, TVariables> {
  const context = {
    headers: {
      AccessToken: AuthenticationHelper.accessToken,
    },
  };

  return useMutation<TData, TVariables>(mutation, {
    context,
    ...options,
  });
}

export default useAuthMutation;
