import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip as BTooltip, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Tooltip extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tooltipOpen: false,
      modalOpen: false,
    };
  }

  onMoreClick() {
    const { modalOpen } = this.state;

    this.setState({
      modalOpen: !modalOpen,
      tooltipOpen: false,
    });
  }

  onCancel() {
    this.onMoreClick();
  }

  toggle() {
    const { tooltipOpen } = this.state;
    this.setState({ tooltipOpen: !tooltipOpen });
  }

  render() {
    const {
      linkIconPosition,
      linkText,
      linkIconHide,
      id,
      linkIcon,
      placement,
      autoHide,
      textAlign,
      modalTitle,
      modalContent,
      modalContentFooter,
      modalSize,
      modalClassName,
      showMoreText,
      text,
    } = this.props;

    const { modalOpen, tooltipOpen } = this.state;

    return (
      <span className="tooltip-wrapper">
        <span className="tooltip-link link" id={`Tooltip-${id}`}>
          {linkIconPosition === 'before' && !linkIconHide && <FontAwesomeIcon icon={linkIcon} fixedWidth />}
          {linkText}
          {linkIconPosition === 'after' && !linkIconHide && <FontAwesomeIcon icon={linkIcon} fixedWidth className="ml-1" />}
        </span>

        <BTooltip
          data-html="true"
          placement={placement}
          isOpen={tooltipOpen}
          autohide={autoHide}
          target={`Tooltip-${id}`}
          toggle={() => this.toggle()}
        >
          <div className={`text-${textAlign}`}>{text}</div>

          {modalTitle && modalContent && (
            <span
              role="button"
              className="tooltip-more link"
              onClick={() => this.onMoreClick()}
              tabIndex={0}
              onKeyPress={(e) => e.key === 'Enter' && this.onMoreClick()}
            >
              {showMoreText}
            </span>
          )}
        </BTooltip>

        {modalTitle && modalContent && (
          <Modal
            isOpen={modalOpen}
            toggle={() => this.onCancel()}
            centered
            modalTransition={{ timeout: 150 }}
            size={modalSize}
            className={modalClassName}
          >
            <ModalHeader toggle={() => this.onCancel()}>{modalTitle}</ModalHeader>
            <ModalBody className="pt-4">
              <Row>{modalContent}</Row>
            </ModalBody>
            <ModalFooter className="border-0">
              {modalContentFooter}
            </ModalFooter>
          </Modal>
        )}
      </span>
    );
  }
}

Tooltip.defaultProps = {
  linkText: '',
  linkIcon: [''],
  modalTitle: '',
  modalContent: <></>,
  modalContentFooter: <></>,
  modalClassName: '',
  modalSize: 'sm',
  placement: 'bottom',
};

Tooltip.propTypes = {
  id: PropTypes.string.isRequired,
  linkText: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object]),
  linkIcon: PropTypes.arrayOf(PropTypes.string),
  linkIconPosition: PropTypes.oneOf(['before', 'after']),
  linkIconHide: PropTypes.bool,
  text: PropTypes.string.isRequired,
  textAlign: PropTypes.oneOf(['left', 'center', 'right']),
  showMoreText: PropTypes.string,
  modalTitle: PropTypes.string,
  modalContent: PropTypes.element,
  modalContentFooter: PropTypes.element,
  modalClassName: PropTypes.string,
  modalSize: PropTypes.oneOf(['sm', 'lg']),
  placement: PropTypes.oneOf([
    'auto',
    'auto-start',
    'auto-end',
    'top',
    'top-start',
    'top-end',
    'right',
    'right-start',
    'right-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'left',
    'left-start',
    'left-end',
  ]),
  autoHide: PropTypes.bool,
};

Tooltip.defaultProps = {
  showMoreText: 'Více informací',
  linkIcon: ['fas', 'info-circle'],
  linkIconPosition: 'after',
  linkIconHide: false,
  textAlign: 'left',
  autoHide: false,
};

export default Tooltip;
