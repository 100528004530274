const resolveSvgColor = (primaryColor: string | undefined, secondaryColor: string | undefined, defaultColor: string): string => {
  if (primaryColor && primaryColor.startsWith('#')) {
    return primaryColor;
  }

  if (!primaryColor && secondaryColor && secondaryColor.startsWith('#')) {
    return secondaryColor;
  }

  if (!primaryColor && !secondaryColor && defaultColor && defaultColor.startsWith('#')) {
    return defaultColor;
  }

  return defaultColor;
};

export default resolveSvgColor;
