import React, { useState } from 'react';
import {
  Label, Modal, ModalBody, ModalFooter, ModalHeader, Button, Row, FormGroup, Card, CardBody,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';

import SubmitButton from '~/components/SubmitButton/SubmitButton';

import type { LabelPosition, HandlePositionChange } from './types';
import ModalMatrix4 from './ModalMatrix4/ModalMatrix4';
import ModalMatrix8 from './ModalMatrix8/ModalMatrix8';

type Props = {
  isOpen: boolean;
  onPrompt: (a: boolean, b: string) => void;
  onCancel: () => void;
  matrixType: number;
  isLoading: boolean;
  count?: number;
};

const ModalUi: React.FC<Props> = ({
  isOpen,
  onPrompt,
  onCancel,
  isLoading,
  matrixType = 4,
  count = 1,
}) => {
  const { t } = useTranslation(['common']);

  const [labelTypeChecked, setLabelTypeChecked] = useState<boolean>(false);

  const [labelPosition, setLabelPosition] = useState<LabelPosition>('first');

  const handleChange = () => {
    setLabelTypeChecked(!labelTypeChecked);

    setLabelPosition('first');
  };

  const handlePositionChange: HandlePositionChange = (val) => {
    setLabelPosition(val);
  };

  const handleSubmit = () => {
    onPrompt(labelTypeChecked, labelPosition);
  };

  const resetData = () => {
    setLabelTypeChecked(false);

    setLabelPosition('first');
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => onCancel()}
      centered
      modalTransition={{ timeout: 150 }}
      onOpened={resetData}
    >
      <ModalHeader toggle={() => onCancel()}>
        {count > 1
          ? t('common:TicketLabelModal.multipleTicketsTitle')
          : t('common:TicketLabelModal.singleTicketTitle') }
        {count > 1 ? `(${count})` : null}
      </ModalHeader>
      <ModalBody className="pt-4">
        <Row className="justify-content-center">
          <FormGroup>
            <span className="switch switch-sm switch-choice">
              <Label htmlFor="labelType">
                {t('common:TicketLabelModal.paperA4LabelSwitch')}
                {' '}
                &nbsp;
              </Label>
              <input
                type="checkbox"
                className="switch"
                id="labelType"
                name="labelType"
                checked={labelTypeChecked}
                onChange={() => handleChange()}
              />
              <Label htmlFor="labelType">
                {t('common:TicketLabelModal.rollLabelSwitch')}
              </Label>
            </span>
          </FormGroup>
        </Row>
        <Row style={labelTypeChecked ? { display: 'none' } : undefined} className="justify-content-center">
          <Card className="shadow border-0">
            {
              (matrixType === 8)
                ? (
                  <ModalMatrix8
                    labelPosition={labelPosition}
                    onChange={handlePositionChange}
                  />
                )
                : (
                  <ModalMatrix4
                    labelPosition={labelPosition}
                    onChange={handlePositionChange}
                  />
                )
            }
          </Card>
        </Row>
        <Row style={!labelTypeChecked ? { display: 'none' } : undefined} className="justify-content-center">
          <Card className="shadow border-0">
            <CardBody>
              <Row>
                <Button size="lg" className={labelPosition === 'first' ? 'active' : undefined} onClick={() => handlePositionChange('first')}>A</Button>
                <Button size="lg" className={labelPosition === 'second' ? 'active ml-2' : 'ml-2'} onClick={() => handlePositionChange('second')}>
                  <div className="text-rotate-90">A</div>
                </Button>
              </Row>
            </CardBody>
          </Card>
        </Row>
      </ModalBody>

      <ModalFooter className="border-0 justify-content-center">
        <SubmitButton
          color="primary"
          isLoading={isLoading}
          onClick={() => { handleSubmit(); }}
        >
          {t('common:TicketLabelModal.submitButton')}
        </SubmitButton>
      </ModalFooter>
    </Modal>
  );
};

export default ModalUi;
