import React from 'react';
import { pipe, range } from 'lodash/fp';

import { useTranslation } from 'react-i18next';
import Icon from '#/Components/Presentational/Icon/Icon';
import ErrorTable from './ErrorTable/ErrorTable';
import { Column, Row, DataError } from './types';

interface SchemaItem {
  label: string;
  required: boolean;
}

interface CsvColumnSchema {
  [key: string]: SchemaItem,
}

type Props = {
  columns: Column[],
  rows: Row[],
  isFirstRowHeader: boolean,
  errors: DataError[],
  columnSchema: CsvColumnSchema,
}

const CSVImportErrorTable: React.FC<Props> = ({
  columns,
  rows,
  isFirstRowHeader,
  errors,
  columnSchema,
}) => {
  const { t } = useTranslation(['deliveries']);

  function getUnassignedColumnIndexes(cols: Column[]): number[] {
    const colIndexes = range(0, cols.length);

    return colIndexes
      .filter((idx) => cols[idx] === undefined);
  }

  function removeRowHeader(r: Row[]): Row[] {
    return isFirstRowHeader
      ? r.filter((_, idx) => idx !== 0)
      : r;
  }

  function removeCellsWithoutColumn(r: Row[]): Row[] {
    const unassignedColumnIndexes = getUnassignedColumnIndexes(columns);

    return r.map((row) => row
      .filter((_: any, idx: any) => !unassignedColumnIndexes.includes(idx)));
  }

  function addHeaderColumn(cols: Column[]): Column[] {
    return isFirstRowHeader
      ? cols.map((col, idx) => ({
        label: rows[0][idx] || col.label,
        value: col.value,
      }))
      : cols;
  }

  function removeUndefinedColumns(cols: Column[]): Column[] {
    return cols.filter((_, idx) => cols[idx] !== undefined);
  }

  function computeErrorColumnsFromValidator(cols: Column[]): Column[] {
    const columnIds: string[] = cols.map((col) => col.value);

    return errors
      .filter((error) => error.row === 0)
      .map((error) => ({
        ...error,
        column: error.column === 'state' ? 'country' : error.column,
      }))
      .filter((error) => !columnIds.includes(error.column))
      .map((error) => ({
        ...error,
        label: columnSchema[error.column]
          ? `${columnSchema[error.column].label} (${t('deliveries:NewDeliveryCSV.Report.defaultValue')})`
          : '',
      }))
      .map((error) => ({
        label: error.label,
        value: error.column,
      }));
  }

  function stringifyNullErrorMessages(csvErrors: DataError[]): DataError[] {
    return csvErrors
      .map((err) => ({
        ...err,
        messages: err.messages || [t('deliveries:NewDeliveryCSV.Report.invalidField')],
        column: err.column === 'state' ? 'country' : err.column,
      }));
  }

  const formattedColumns = pipe(
    addHeaderColumn,
    removeUndefinedColumns,
  )(columns);

  const formattedRows = pipe(
    removeCellsWithoutColumn,
    removeRowHeader,
  )(rows);

  const additionalColumns = computeErrorColumnsFromValidator(formattedColumns);

  return (
    <div id="error-table">
      <div className="alert alert-danger" role="alert">
        <h4 className="alert-heading">
          <Icon icon="exclamation-triangle" type="fw" className="mr-2" />
          {t('deliveries:NewDeliveryCSV.Report.title')}
        </h4>

        {t('deliveries:NewDeliveryCSV.Report.subtitle')}
      </div>

      <ErrorTable
        additionalColumns={additionalColumns}
        columns={formattedColumns}
        rows={formattedRows}
        errors={stringifyNullErrorMessages(errors)}
      />
    </div>
  );
};

export default CSVImportErrorTable;
