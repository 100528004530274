import React from 'react';
import {
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import withValidation from 'Components/Presentational/FormExtensions/HoC/withValidation/withValidation';

const InputWithValidation = withValidation(Input);

const TechnologyNumberModalUI = ({
  toggleModal,
  onSubmit,
  isModalVisible,
}) => {
  const { t } = useTranslation(['settings']);

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{ technologyNumber: '', technologyType: '' }}
      validationSchema={
        Yup.object().shape({
          technologyNumber: Yup.number()
            .typeError(t('settings:Agents.TechNumber.Number.typeError'))
            .required(t('settings:Agents.TechNumber.Number.required'))
            .max(99999, t('settings:Agents.TechNumber.Number.maxLength', { count: 5 })),
          technologyType: Yup.string()
            .required(t('settings:Agents.TechNumber.Type.required'))
            .length(1, t('settings:Agents.TechNumber.Type.maxLength', { count: 1 })),
        })
      }
    >
      {({ handleSubmit }) => (
        <Modal isOpen={isModalVisible} centered toggle={toggleModal}>
          <ModalHeader>
            {t('settings:Agents.TechNumber.addTitle')}
          </ModalHeader>
          <ModalBody className="pt-0">
            <FormGroup>
              <Label>
                {t('settings:Agents.TechNumber.Number.label')}
              </Label>
              <InputWithValidation name="technologyNumber" />
            </FormGroup>
            <FormGroup>
              <Label>
                {t('settings:Agents.TechNumber.Type.label')}
              </Label>
              <InputWithValidation
                name="technologyType"
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="primary" onClick={handleSubmit}>
              {t('settings:Agents.TechNumber.submitButton')}
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </Formik>
  );
};

TechnologyNumberModalUI.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isModalVisible: PropTypes.bool.isRequired,
};

export default TechnologyNumberModalUI;
