import * as React from 'react';
import ConfirmModalUI from 'Components/Presentational/ConfirmModal/ConfirmModal';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

const buttonColors = ['link', 'secondary', 'warning', 'danger', 'success'];

// eslint-disable-next-line react/prefer-stateless-function
class ConfirmModal extends React.Component {
  render() {
    const { title, buttons, body, onCancel, onPrompt, isOpen, icon, t } = this.props;

    return (
      <ConfirmModalUI
        title={title || t('settings:Packages.DeleteItemModal.title')}
        text={body || t('settings:Packages.DeleteItemModal.description')}
        buttons={buttons || {
          prompt: {
            text: t('settings:Packages.DeleteItemModal.submitButton'),
            color: 'secondary',
          },
          cancel: {
            text: t('settings:Packages.DeleteItemModal.cancelButton'),
            color: 'link',
          },
        }}
        onPrompt={onPrompt}
        onCancel={onCancel}
        isOpen={isOpen}
        icon={icon || ['fas', 'exclamation-triangle']}
      />
    );
  }
}

ConfirmModal.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  icon: PropTypes.arrayOf(PropTypes.string),
  isOpen: PropTypes.bool.isRequired,
  onPrompt: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  buttons: PropTypes.objectOf(PropTypes.shape({
    prompt: PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.oneOf(buttonColors).isRequired,
    }),
    cancel: PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.oneOf(buttonColors).isRequired,
    }),
  })),
};

export default withTranslation(['settings'])(ConfirmModal);
