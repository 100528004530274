import React from 'react';

import css from './Alert.module.scss';

export type BannerType = 'primary' | 'info' | 'success' | 'warning' | 'error';

type Props = {
  type: BannerType;
  className?: string;
}

// TODO: Move banner component to shared workspace (requires webpack to support css modules)
const Alert: React.FC<Props> = ({ type, className, children }) => {
  const getBannerClass = (bannerType: BannerType): string => {
    switch (bannerType) {
    case 'primary':
      return css.primary;
    case 'info':
      return css.info;
    case 'success':
      return css.success;
    case 'warning':
      return css.warning;
    case 'error':
      return css.error;
    default:
      return css.primary;
    }
  };

  return (
    <div className={`${css.banner} ${getBannerClass(type)} ${className} mb-4 text-center font-weight-medium`.trim()}>
      {children}
    </div>
  );
};

export default Alert;
