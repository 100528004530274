/* eslint-disable react/sort-comp */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as PropTypes from 'prop-types';
import React from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import DataTable from 'Containers/DataTable/DataTable';
import Pagination from 'Components/Presentational/Pagination/Pagination';
import DeliveryTableStatus from 'Components/Presentational/DataTable/DeliveryTableStatus';
import DeliveryTableCheckbox from 'Components/Presentational/DataTable/DataTableCheckbox';
import DeliveryTableAgent from 'Components/Deliveries/DeliveryTableAgent';
import DeliveryTableRecipient from 'Components/Deliveries/DeliveryTableRecipient';
import DeliveryTableRating from 'Components/Deliveries/DeliveryTableRating';
import DeliveryTableCashOnDelivery from 'Components/Deliveries/DeliveryTableCashOnDelivery';
import DeliveryActionCreators from 'StoreActions/DeliveryActionCreators';
import DataTableActionLink from 'Components/Presentational/DataTable/DataTableActions/DataTableActionLink';
import DataTableActionFunc from 'Components/Presentational/DataTable/DataTableActions/DataTableActionFunc';
import Delivery from 'Models/delivery/DeliveryDataSource';
import ReducerPropTypes from 'StoreReducers/ReducerPropTypes';
import { URLQueryHandler } from 'Tools/URLQueryHandler';
import PaginatorHelper from 'Tools/PaginatorHelper';
import EshopDataSource from 'Models/eshop/EshopDataSource';
import DataTableActionSVG from 'Components/Presentational/DataTable/DataTableActions/DataTableActionSVG';
import UpsellState, { UpsellStateDescription } from '~/components/UpsellState/UpsellState';
import DeliveryEmptyState from '../DeliveryEmptyState/DeliveryEmptyState';
import DeliveryUserEditViewer from '../../../DeliveryUserEditViewer/DeliveryUserEditViewer';
import { format } from '~/services/date';
import { getCarrierLogoUrl } from '~/services/utils';

class DeliveryPreviewTable extends React.Component {
  constructor(props) {
    super(props);

    this.handleSort = this.handleSort.bind(this);
    this.handlePaginatorClick = this.handlePaginatorClick.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);

    this.delivery = new Delivery();
    this.eshop = new EshopDataSource();

    this.numberOfRows = 25;

    this.state = {
      columns: [
        {
          title: '',
          index: 'userIcon',
          key: '0',
          rowClickDisable: true,
          style: { width: '1px', padding: 0 },
        },
        {
          title: '',
          index: 'checkBox',
          key: '1',
          rowClickDisable: true,
          className: 'checkbox-wrapper',
        },
        {
          title: '',
          index: 'created',
          key: '2',
        },
        {
          title: '',
          index: 'status',
          key: '3',
        },
        {
          title: '',
          index: 'agent',
          key: '4',
        },
        {
          title: '',
          index: 'recipient',
          key: '5',
        },
        {
          title: '',
          index: 'cashOnDelivery',
          key: '6',
        },
        {
          title: '',
          index: 'numberOfNotes',
          key: '7',
        },
        {
          title: '',
          index: 'rating',
          key: '8',
          className: 'align-middle',
        },
      ],
      actions: {
        rowClick: {
          type: 'link',
          ref: '/deliveries/:id',
        },
      },
      config: {
        hideActionButtons: true,
        striped: false,
        borderless: false,
        className: 'basic deliveries',
      },
    };
  }

  async componentDidMount() {
    const { setFilters, user, filter } = this.props;
    const parsedQueryStrings = URLQueryHandler.getParsedQueryString();
    const hasImportantDeliveries = user?.company?.subscription?.importantDeliveries;

    // Filter (delivery category) and the "important" flag are mutually exclusive
    let updatedFilter = parsedQueryStrings?.filter ?? filter?.filter;
    let flags = { important: !updatedFilter };

    // Users without the "importantDeliveries" feature should see the "inProgress" category by default
    if (!hasImportantDeliveries && !updatedFilter) {
      updatedFilter = 'inProgress';
      flags = { important: false };
    }

    await setFilters({
      ...parsedQueryStrings,
      filter: updatedFilter,
      flags,
    });
  }

  get totalPages() {
    const { totalCount } = this.props;
    return PaginatorHelper.computeTotalPages(totalCount, this.numberOfRows);
  }

  isChecked = (id) => {
    const { checkedIds } = this.props;
    return checkedIds.includes(id);
  };

  handleFilterChange(filter, flags) {
    const {
      clearCheckboxes,
      setFilterAndFlags,
      setPage,
    } = this.props;

    setFilterAndFlags({ filter, flags });
    setPage(1);
    clearCheckboxes();
  }

  formatTableData() {
    const {
      checkItem,
      deliveries,
      onCancelDeliveryClick,
      t,
    } = this.props;

    return deliveries.map((delivery) => {
      const currentTableRow = {};

      currentTableRow.userIcon = (
        <DeliveryUserEditViewer
          limit={1}
          className="table"
          deliveryId={delivery.id}
        />
      );

      currentTableRow.checkbox = (
        <DeliveryTableCheckbox
          id={delivery.id}
          data={{ delivery }}
          onCheck={checkItem}
          checked={this.isChecked(delivery.id)}
          disabled={delivery.isMonitored === true}
        />
      );

      currentTableRow.created = (
        <DeliveryTableStatus
          number={format(new Date(delivery.created))}
          important={false}
          status={t('common:SummaryListItemLabels.date')}
        />
      );

      currentTableRow.status = (
        <DeliveryTableStatus
          number={delivery.deliveryNumber}
          important={delivery.important}
          status={delivery.delayed ? t(`status:DelayCode.${delivery.reasonOfDelayCode}`) : t(`status:${delivery.stateKey}`)}
        />
      );

      currentTableRow.agent = (
        <DeliveryTableAgent
          deliveryType={delivery.type.name}
          img={getCarrierLogoUrl(delivery.type.agent.logoSlug)}
          imgAlt={delivery.type.agent.originalName}
        />
      );

      currentTableRow.recipient = (
        <DeliveryTableRecipient
          name={delivery.recipient.contact.name}
          surname={delivery.recipient.contact.surname}
        />
      );

      currentTableRow.cashOnDelivery = (
        <DeliveryTableCashOnDelivery
          value={delivery.cashOnDelivery || 0}
          currency={delivery.cashOnDeliveryCurrency || 'Kč'}
        />
      );

      currentTableRow.numberOfNotes = (
        (delivery.numberOfNotes
          ? (
            <div className="text-center">
              <span className="fa-layers fa-fw fa-2x mt-2">
                <FontAwesomeIcon icon="comment" color="#949495" />
                <span className="fa-layers-text fa-inverse">
                  {delivery.numberOfNotes}
                </span>
              </span>
            </div>
          ) : (<></>))
      );

      currentTableRow.rating = delivery.rating ? (
        <DeliveryTableRating value={delivery.rating} />
      ) : (<></>);

      currentTableRow.id = delivery.id;
      currentTableRow.className = (this.isChecked(delivery.id) ? 'selectedRow' : '');
      currentTableRow.actions = (
        <>
          <DataTableActionLink
            to={`/deliveries/${delivery.id}`}
            icon={{ class: ['far', 'eye'] }}
            tooltip={t('deliveries:ListItemTooltip.detailDelivery')}
          />
          <DataTableActionLink
            to={`/deliveries/new/${delivery.id}`}
            icon={{ class: ['far', 'clone'] }}
            tooltip={t('deliveries:ListItemTooltip.duplicateDelivery')}
          />
          {delivery.canUpdate === true && (
            <DataTableActionSVG
              to={`/deliveries/edit/${delivery.id}`}
              icon="edit"
              tooltip={t('deliveries:ListItemTooltip.editDelivery')}
            />
          )}
          {delivery.canCancel === true && (
            <DataTableActionFunc
              onClick={() => onCancelDeliveryClick(delivery.id)}
              icon={{ class: ['fas', 'times'] }}
              tooltip={t('deliveries:ListItemTooltip.cancelDelivery')}
            />
          )}
        </>
      );

      return currentTableRow;
    });
  }

  async handlePaginatorClick(page) {
    const { clearCheckboxes, setPage } = this.props;

    clearCheckboxes();
    await setPage(page);
    await this.sendQueryAndHandleData();
  }

  async handleSort(e) {
    const orderBy = e.target.getAttribute('data-orderby');
    const direction = e.target.getAttribute('data-direction');
    const { setOrderBy, setOrderByDirection } = this.props;

    setOrderBy(orderBy);
    setOrderByDirection(direction);
  }

  async sendQueryAndHandleData() {
    const { refetchDeliveries } = this.props;
    refetchDeliveries();
  }

  render() {
    const {
      columns: tableColumns,
      actions: tableActions,
      config: tableConfig,
    } = this.state;

    const {
      t,
      filter,
      filter: {
        filter: tableFilter,
        flags,
      },
      isLoading,
      deliveries,
      user,
    } = this.props;

    const subscription = user.company?.subscription;

    return (
      <div className="deliveries-list">
        <div className="btn-group-deliveries-container">
          <ButtonGroup className="btn-group-deliveries mb-0">
            <Button
              className={flags.important === true ? 'active' : null}
              onClick={() => this.handleFilterChange(null, ['important'])}
              outline
            >
              {t('status:StatusCategory.important')}
            </Button>
            <Button
              className={tableFilter === 'inProgress' ? 'active' : null}
              onClick={() => this.handleFilterChange('inProgress')}
              outline
            >
              {t('status:StatusCategory.in_progress')}
            </Button>
            <Button
              className={tableFilter === 'toSend' ? 'active' : null}
              onClick={() => this.handleFilterChange('toSend')}
              outline
            >
              {t('status:StatusCategory.ready_to_send')}
            </Button>
            <Button
              className={tableFilter === 'enRoute' ? 'active' : null}
              onClick={() => this.handleFilterChange('enRoute')}
              outline
            >
              {t('status:StatusCategory.sent')}
            </Button>
            <Button
              className={tableFilter === 'delivered' ? 'active' : null}
              onClick={() => this.handleFilterChange('delivered')}
              outline
            >
              {t('status:StatusCategory.delivered')}
            </Button>
            <Button
              className={tableFilter === 'returned' ? 'active' : null}
              onClick={() => this.handleFilterChange('returned')}
              outline
            >
              {t('status:StatusCategory.not_delivered')}
            </Button>
            <Button
              className={tableFilter === 'canceled' ? 'active' : null}
              onClick={() => this.handleFilterChange('canceled')}
              outline
            >
              {t('status:StatusCategory.canceled')}
            </Button>
          </ButtonGroup>
        </div>

        <div className="position-relative deliveries-list-content-wrapper">
          {flags.important && !subscription.importantDeliveries && (
            <UpsellState description={UpsellStateDescription.importantDeliveries} />
          )}
          {!isLoading && deliveries.length === 0 ? (
            <DeliveryEmptyState important={flags.important} category={tableFilter} />
          ) : (
            <DataTable
              data={this.formatTableData()}
              columns={tableColumns}
              actions={tableActions}
              config={tableConfig}
              isLoaderVisible={isLoading}
            />
          )}
        </div>

        <Pagination
          currentPage={Number(filter.page)}
          onPageChanged={this.handlePaginatorClick}
          totalPages={this.totalPages || 10}
        />
      </div>
    );
  }
}

DeliveryPreviewTable.defaultProps = { totalCount: 0 };

DeliveryPreviewTable.propTypes = {
  checkedIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  checkItem: PropTypes.func.isRequired,
  clearCheckboxes: PropTypes.func.isRequired,
  filter: ReducerPropTypes.deliveryFilter.isRequired,
  setFilterAndFlags: PropTypes.func.isRequired,
  setOrderBy: PropTypes.func.isRequired,
  setOrderByDirection: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
  onCancelDeliveryClick: PropTypes.func.isRequired,
  refetchDeliveries: PropTypes.func.isRequired,
  deliveries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  totalCount: PropTypes.number,
  isLoading: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    filter: state.deliveryReducer.filter,
    user: state.userReducer.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setCSVFileUpload: (csvFile) => dispatch(DeliveryActionCreators.setCSVFile(csvFile)),
    setOrderBy: (orderBy) => dispatch(DeliveryActionCreators.setOrderBy(orderBy)),
    setOrderByDirection: (orderByDirection) => dispatch(DeliveryActionCreators.setOrderByDirection(orderByDirection)),
    setFilterAndFlags: (filterAndFlags) => dispatch(DeliveryActionCreators.setFilterAndFlags(filterAndFlags)),
    setFilters: (filters) => dispatch(DeliveryActionCreators.setFilters(filters)),
    setPage: (page) => dispatch(DeliveryActionCreators.setPage(page)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['common', 'status', 'deliveries'])(DeliveryPreviewTable));
