import React from 'react';
import PropTypes from 'prop-types';
import ApolloQueryTypes from 'Types/apollo/ApolloQueryTypes';
import CurrencyQueryFactory from 'Models/currency/queries/Factories/CurrencyQueryFactory';
import AuthenticationHelper from '~/services/Auth';

export const CurrencyManagerContext = React.createContext();

export default class CurrencyManager extends React.Component {
  constructor(props) {
    super(props);
    this.indexedDBTableName = 'currency';
  }

  async componentDidMount() {
    const { disabled } = this.props;

    if (disabled) return;

    try {
      await this.fetchCurrencies();
    } catch (e) {
      console.error(e);
    }
  }

  getCurrencyArray = async () => {
    const { getTable } = this.props;
    const currencies = await getTable(this.indexedDBTableName);

    return currencies.map((currency) => currency.name);
  };

  // TODO: Split, add fallback - use currencies from response when IndexedDB fails
  async fetchCurrencies() {
    const { getTable, setTable, apolloClient } = this.props;
    const currencies = await getTable(this.indexedDBTableName);

    if (currencies.length === 0) {
      const response = await apolloClient.query({
        query: CurrencyQueryFactory.currencies(),
        context: { headers: { AccessToken: AuthenticationHelper.accessToken } },
      });

      // eslint-disable-next-line no-underscore-dangle
      await setTable(this.indexedDBTableName, response.data.__type.enumValues);
    }
  }

  render() {
    const { children, tables, disabled } = this.props;
    const { currency } = tables;

    if (disabled) {
      return (
        <>
          {children}
        </>
      );
    }

    return (
      <CurrencyManagerContext.Provider value={{
        currencies: currency || [],
        getCurrencyArray: this.getCurrencyArray,
      }}
      >
        {children}
      </CurrencyManagerContext.Provider>
    );
  }
}

CurrencyManager.propTypes = {
  children: PropTypes.element,
  getTable: PropTypes.func.isRequired,
  setTable: PropTypes.func.isRequired,
  tables: PropTypes.shape({}).isRequired,
  apolloClient: ApolloQueryTypes.client().isRequired,
  disabled: PropTypes.bool.isRequired,
};

CurrencyManager.defaultProps = { children: null };
