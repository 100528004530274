import React from 'react';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { toast } from '#/shared/utilities';
import useModal from '~/hooks/useModal';

import SnoozeModal from './SnoozeModal/SnoozeModal';

type Props = {
  deliveryId: string;
  hidden: boolean;
  onSuccess: () => void;
};

const SnoozeButton: React.FC<Props> = ({
  deliveryId,
  hidden,
  onSuccess,
}) => {
  const { t } = useTranslation('deliveries');

  const [isModalOpen, toggleModal] = useModal();

  const handleSuccess = () => {
    toast.success(t('DeliveryDetail.Banner.removeFromImportantSuccess'));

    onSuccess();
  };

  return (
    <>
      <Button
        color="secondary"
        onClick={() => { toggleModal(); }}
        hidden={hidden}
        style={{ padding: '0.5rem 1rem' }}
      >
        {t('DeliveryDetail.removeFromImportantButton')}
      </Button>

      <SnoozeModal
        deliveryId={deliveryId}
        onToggle={toggleModal}
        isOpen={isModalOpen}
        onSuccess={handleSuccess}
      />
    </>
  );
};

export default SnoozeButton;
