/* eslint-disable react/sort-comp,object-curly-newline */
import React from 'react';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { sortBy } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import produce, { setAutoFreeze } from 'immer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';

import Select from 'Components/Presentational/Select/Select';
import basePaths from 'Routes/paths/base.path';
import CSVParser from 'Shared/csvParser/csvParser';
import MainBody from 'Components/Presentational/Layout/MainBody/MainBody';
import Loader from 'Containers/Loader/Loader';
import DeliveryActionCreators from 'StoreActions/DeliveryActionCreators';
import Base64Helper from 'Tools/Base64Helper';
import CollectionPlace from 'Models/collectionPlace/CollectionPlace';
import DeliveryDataSource from 'Models/delivery/DeliveryDataSource';
import { toast, generateReactKey, nullifyString, nl2p } from 'Shared/utilities';
import configurator from '~/services/Configurator';
import ImportCSVForm from './Components/Presentational/ImportCSVForm/ImportCSVForm';
import csvFields from './csvFieldSchema';
import defaultSettings from './defaultSettingsSchema';
import CSVImportErrorTable from './Components/Container/CSVImportErrorTable/CSVImportErrorTable';

class ImportCSV extends React.Component {
  static convertSeparatorString(separator) {
    switch (separator) {
      case ',':
        return 'comma';
      case ';':
        return 'semicolon';
      case 'comma':
        return ',';
      case 'semicolon':
        return ';';
      default:
    }
    return '';
  }

  onFileReadError(error) {
    const { t } = this.props;

    toast.error(t('deliveries:NewDeliveryCSV.Banner.fileDamagedError'));
    error.forEach((value) => console.warn(value));
  }

  static sortSettingsSelectOptions(csvSettingsObject) {
    return csvSettingsObject.sort((cur, next) => next.id - cur.id);
  }

  static resetRequiredCSVFieldValues(requiredCSVFields) {
    const fields = requiredCSVFields;

    Object.keys(fields).forEach((fieldKey) => {
      fields[fieldKey].value = null;
    });

    return fields;
  }

  constructor(props) {
    super(props);

    const { t } = this.props;

    this.CSVParser = new CSVParser(this.onFileReadFinish, this.onFileReadError.bind(this));
    this.delivery = new DeliveryDataSource();
    this.collectionPlace = new CollectionPlace();
    this.onCSVInputChange = this.onCSVInputChange.bind(this);
    this.csvInputIsInUse = this.csvInputIsInUse.bind(this);
    this.parseCSV = this.parseCSV.bind(this);
    this.csvColumns = csvFields(t);
    this.queryStrings = Object.freeze({
      agents: `
        id
        name
        abbr
        extraServices {
          code
          name
        }
        collectionPlaces {
          id
          name
        }
        deliveryTypes {
          abbr
          name
        }
      `,
    });

    this.setFieldValue = null;
  }

  async componentDidMount() {
    const { upload } = this.props;

    if (configurator.env === 'development') {
      setAutoFreeze(false);
    }

    await this.fetchData();

    if (upload.csvFile) {
      this.handleFileChanges(null, upload.csvFile);
    }
  }

  componentWillUnmount() {
    if (configurator.env === 'development') {
      setAutoFreeze(true);
    }
  }

  getImportCSVFormComponent = (formikProps) => {
    const {
      inputs,
      table,
      deliveryCount,
      agents,
      activeAgentIndex,
      csvImportSettings,
      activeSettingsIndex,
    } = this.state;
    const { history } = this.props;

    return (
      <ImportCSVForm
        inputs={inputs}
        table={table}
        deliveryCount={deliveryCount}
        agents={agents}
        activeAgentIndex={activeAgentIndex}
        setFormikHelpers={this.setFormikFormHelpers}
        history={history}
        handleFileChanges={this.handleFileChanges}
        renderSettingsOptions={this.renderSettingsOptions}
        handleSettingsChange={this.handleSettingsChange}
        handleHeaderChange={this.handleHeaderChange}
        requiredCSVFields={this.csvColumns}
        csvInputIsInUse={this.csvInputIsInUse}
        renderCollectionPlaceOptions={this.renderCollectionPlaceOptions}
        extraServiceList={this.extraServiceList}
        onAgentChange={this.onAgentChange}
        onExtraServiceChange={this.onExtraServiceChange}
        csvImportSettings={csvImportSettings}
        activeSettingsIndex={activeSettingsIndex}
        handleSettingsDelete={this.handleSettingsDelete}
        {...formikProps}
      />
    );
  };

  setFormikFormHelpers = ({ setFieldValue }) => {
    if (!this.setFieldValue) this.setFieldValue = setFieldValue;
  };

  onFileReadFinish = (result) => {
    const { hasHeader } = this.state;
    const trimmedData = result.data.filter((row) => row.length > 1);
    const deliveryCount = hasHeader ? trimmedData.length - 1 : trimmedData.length;

    this.setState({ deliveryCount });
    const { activeCSVSeparator } = this.state;
    if (result.meta.delimiter !== activeCSVSeparator) {
      this.handleSeparatorChange(
        ImportCSV.convertSeparatorString(result.meta.delimiter),
        false,
      );
    }

    if (result.data[0]) {
      this.prepareCSVTableData(result);
    }
  };

  onCSVInputChange = (index) => (option) => {
    const state = { ...this.state };
    state.csvInputValues[index] = option;

    this.setState(state, () => {
      this.prepareCSVInputs();
    });
  };

  setDefaultAgentSelectValue = (state, activeSettingsIndex, agents, setFieldValue) => {
    const modifiedState = state;
    const selectValue = this.setDefaultValueSelectByAbbr(
      setFieldValue,
      'carrier',
      'defaultAgent',
      activeSettingsIndex,
      agents,
    );

    if (selectValue !== null) {
      modifiedState.activeAgentIndex = agents.findIndex((agent) => agent.abbr === selectValue.value);
    } else {
      modifiedState.activeAgentIndex = null;
    }

    return modifiedState.activeAgentIndex;
  };

  onExtraServiceChange = (selectedOption) => {
    const state = { ...this.state };
    state.inputs.extraServices = selectedOption;

    this.setState(state);
  };

  onAgentChange = (setFieldValue) => (option, select) => {
    const { agents } = this.state;
    const id = option.value;
    const newState = { ...this.state };
    let agentIndex = agents.findIndex((item) => item.id === id);

    if (agentIndex === -1) {
      agentIndex = null;
    }

    newState.activeAgentIndex = agentIndex;
    setFieldValue('deliveryType', null);
    setFieldValue(select.name, option);
    this.setState(newState);
  };

  getCurrentExtraservicesAndRequiredFields(activeSettingsIndex, csvImportSettings) {
    if (activeSettingsIndex === null) {
      return [[], ImportCSV.resetRequiredCSVFieldValues({ ...this.csvColumns })];
    }

    const extraServices = [];
    const currentSettings = csvImportSettings[activeSettingsIndex];
    const requiredCSVFields = this.setRequiredCSVFieldValues(
      { ...this.csvColumns },
      activeSettingsIndex,
      currentSettings,
    );

    if (csvImportSettings.length === 0) {
      return [[], requiredCSVFields];
    }

    currentSettings.defaultExtraServices.forEach((item) => {
      const serviceObj = this.extraServiceList.find((service) => service.code === item);
      extraServices.push({
        label: serviceObj.name,
        value: serviceObj.code,
      });
    });

    return [extraServices, requiredCSVFields];
  }

  setDefaultValues(state, activeSettingsIndex) {
    const { t } = this.props;
    const draftState = state;
    const { csvImportSettings, agents } = draftState;

    if (csvImportSettings.length === 0 || activeSettingsIndex === null) {
      return;
    }

    const currentSettings = csvImportSettings[activeSettingsIndex];
    draftState.inputs.extraServices = [];
    const defaultCollectionPlace = currentSettings.defaultCollectionPlace
      ? {
        label: currentSettings.defaultCollectionPlace.name,
        value: currentSettings.defaultCollectionPlace.id,
      } : {
        label: t('deliveries:NewDeliveryCSV.defaultLabel'),
        value: '',
      };

    const activeAgentIndex = this.setDefaultAgentSelectValue(
      draftState,
      activeSettingsIndex,
      agents,
      this.setFieldValue,
    );

    if (activeAgentIndex !== null) {
      this.setDefaultValueSelectByAbbr(
        this.setFieldValue,
        'deliveryType',
        'defaultDeliveryType',
        activeSettingsIndex,
        agents[activeAgentIndex].deliveryTypes,
      );
    } else {
      this.setFieldValue('deliveryType', null);
    }

    this.setFieldValue('recipientState', currentSettings.defaultCountry);
    this.setFieldValue('cashOnDeliveryCurrency', currentSettings.defaultCodCurrency);
    this.setFieldValue('shipmentCurrency', currentSettings.defaultValueCurrency);
    this.setFieldValue('collectionPlace', defaultCollectionPlace);
    this.setFieldValue('isMonitored', currentSettings.isMonitored ?? false);

    currentSettings.defaultExtraServices.forEach((item) => {
      const serviceObj = this.extraServiceList.find((service) => service.code === item);
      draftState.inputs.extraServices.push({
        label: serviceObj.name,
        value: serviceObj.code,
      });
    });
  }

  setDefaultValueSelectByAbbr(setFieldValue,
    selectName,
    defaultSettingProperty,
    settingsIndex,
    abbrSearchArray,
    abbrObjectKey = 'abbr') {
    const { csvImportSettings } = this.state;

    if (csvImportSettings.length === 0) {
      return null;
    }

    let selectValue = null;

    let defaultSettingValue = csvImportSettings[settingsIndex][defaultSettingProperty];
    defaultSettingValue = (defaultSettingValue === 'CP') ? 'ČP' : defaultSettingValue;

    if (defaultSettingValue !== null) {
      if (Array.isArray(defaultSettingValue)) {
        selectValue = [];

        if (abbrSearchArray.length === 0) {
          selectValue = null;

          return selectValue;
        }

        for (let i = 0; i < defaultSettingValue.length; i += 1) {
          const deliveryTypeObj = abbrSearchArray.find((item) => item[abbrObjectKey] === defaultSettingValue[i]);
          selectValue.push({
            label: deliveryTypeObj.name,
            value: deliveryTypeObj.abbr,
          });
        }
      } else {
        const deliveryTypeObj = abbrSearchArray.find((item) => item[abbrObjectKey] === defaultSettingValue);
        selectValue = {
          label: deliveryTypeObj.name,
          value: deliveryTypeObj.abbr,
        };
      }
    }

    setFieldValue(selectName, selectValue);

    return selectValue;
  }

  getCSVSettingsIndexById = (id) => {
    const { csvImportSettings } = this.state;
    const index = csvImportSettings.findIndex((settings) => Number(settings.id) === Number(id));

    return index !== -1 ? index : null;
  };

  handleSettingsChange = (setFieldValue, option, select) => {
    const { csvImportSettings, agents } = this.state;
    const hasUnselectedSettings = option.value === '';
    const activeSettingsIndex = !hasUnselectedSettings ? this.getCSVSettingsIndexById(option.value) : null;
    const [extraServices, requiredList] = this.getCurrentExtraservicesAndRequiredFields(
      activeSettingsIndex,
      csvImportSettings,
      agents,
    );

    setFieldValue(select.name, option);

    const newState = produce(this.state, (draft) => {
      draft.activeSettingsIndex = activeSettingsIndex;
      draft.hasHeader = (activeSettingsIndex !== null)
        ? draft.csvImportSettings[activeSettingsIndex].hasHeader
        : false;
      draft.inputs.extraServices = extraServices;
      draft.csvRequiredInputList = requiredList;
      draft.dataErrors = [];

      if (!hasUnselectedSettings) {
        this.setDefaultValues(draft, activeSettingsIndex);
      } else {
        this.resetDefaultValues();
      }

      draft.table.columns
        .filter((column) => column.index === 'key')
        .map((column) => {
          const columnWithClassName = column;

          columnWithClassName.className = draft.hasHeader ? 'text-truncate col-toggle col-visible' : 'text-truncate col-toggle col-invisible';

          return columnWithClassName;
        });
    });

    this.setState(newState, () => {
      const { hasHeader } = this.state;

      setFieldValue('fileFirstLineHeader', hasHeader);
      this.parseCSV();
    });
  };

  handleSettingsDelete = (id) => {
    const { t } = this.props;
    const { csvImportSettings } = this.state;
    const isDeletingLastSetting = csvImportSettings[1] === undefined;

    const updatedState = produce(this.state, (draft) => {
      const draftState = draft;

      draftState.activeSettingsIndex = !isDeletingLastSetting ? 0 : null;
      draftState.csvImportSettings = draftState.csvImportSettings.filter((settings) => settings.id !== id);
    });

    this.setState(updatedState);
    this.handleSettingsChange(
      this.setFieldValue,
      {
        value: !isDeletingLastSetting ? updatedState.csvImportSettings[0].id : '',
        label: !isDeletingLastSetting ? updatedState.csvImportSettings[0].name : t('deliveries:NewDeliveryCSV.defaultLabel'),
      },
      { name: 'savedSettings' },
    );
  };

  handleSeparatorChange(value, shouldParseCSV = true) {
    const newState = produce(this.state, (draft) => {
      draft.activeCSVSeparator = value;
    });

    this.setState(newState, () => shouldParseCSV && this.parseCSV);
  }

  handleHeaderChange = () => {
    const state = { ...this.state };
    state.hasHeader = !state.hasHeader;

    const columnKeyIndex = state.table.columns.findIndex((item) => item.index === 'key');
    state.table.columns[columnKeyIndex].className = state.hasHeader ? 'text-truncate col-toggle col-visible' : 'text-truncate col-toggle col-invisible';

    this.setState(state, this.parseCSV);
  };

  handleFileChanges = (event, csv) => {
    const { t } = this.props;
    const file = csv || event.target.files[0];
    const state = { ...this.state };

    state.inputs.csv.file = file;
    state.inputs.csv.fileName = file ? file.name : t('deliveries:NewDeliveryCSV.SourceFile.uploadFileButton');
    state.table.data = null;

    this.setState(state, this.changeSettingsSelectToDefaultValue);
  };

  parseCSV() {
    const {
      inputs: { csv: { file: csvFile } },
      activeCSVSeparator,
    } = this.state;
    if (!this.state || !csvFile) return;

    this.CSVParser.handle(
      csvFile,
      activeCSVSeparator,
    );
  }

  prepareCSVInputs() {
    const { t } = this.props;
    const state = { ...this.state };
    const { csvRequiredInputList } = this.state;

    const optionsRequired = [];
    const optionsOptional = [];
    const options = [
      {
        value: '',
        label: t('deliveries:NewDeliveryCSV.SampleData.SelectLabels.notUse'),
      },
      {
        label: t('deliveries:NewDeliveryCSV.SampleData.SelectLabels.mandatory'),
        options: optionsRequired,
      },
      {
        label: t('deliveries:NewDeliveryCSV.SampleData.SelectLabels.optional'),
        options: optionsOptional,
      },
    ];

    Object.entries(csvRequiredInputList).forEach(([key, item]) => {
      if (!this.csvInputIsInUse(key)) {
        const option = {
          value: key,
          label: item.label,
          color: item.required ? '#FF601B' : null,
        };

        if (item.required) {
          optionsRequired.push(option);
        } else {
          optionsOptional.push(option);
        }
      }
    });

    if (state && state.table.data) {
      state.table.data.map((item) => {
        const itemObj = item;
        const { csvInputValues } = this.state;

        itemObj.input = (
          <Select
            name={`csv-input-${item.id}`}
            value={csvInputValues[item.id] || ''}
            options={options}
            classNamePrefix="select"
            onChange={this.onCSVInputChange(item.id)}
            placeholder={t('deliveries:NewDeliveryCSV.SampleData.selectPlaceholder')}
          />
        );

        return itemObj;
      });

      this.setState(state);
    }
  }

  prepareCSVTableData(result) {
    const state = { ...this.state };
    state.csvInputValues = [];
    const data = [];

    Object.entries(result.data[0]).forEach(([key, value]) => {
      const index = Number(key);

      data.push({
        columnLetter: (
          <div className="font-weight-bold text-truncate">
            {String.fromCharCode(65 + index)}
          </div>
        ),
        key: <div>{value}</div>,
        value: (
          <div className="text-truncate" title={result.data[state.hasHeader ? 1 : 0][index]}>
            {result.data[state.hasHeader ? 1 : 0][index]}
          </div>),
        input: null,
        id: index,
      });
    });

    Object.keys(state.csvRequiredInputList).forEach((key) => {
      const currentObj = state.csvRequiredInputList[key];

      if (!currentObj.value) return;

      const columnIndex = currentObj.value - 1;

      state.csvInputValues[columnIndex] = {
        value: key.toString(),
        label: currentObj.label,
      };
    });

    state.table.values = result.data;
    state.table.data = data;
    this.setState(state, () => {
      this.prepareCSVInputs();
    });
  }

  async fetchData() {
    const { t } = this.props;

    let csvImportSettings = await this.delivery.deliveryCsvImportSettings();
    csvImportSettings = ImportCSV.sortSettingsSelectOptions(csvImportSettings.data.deliveryCsvImportSettings);

    if (csvImportSettings.length === 0) {
      csvImportSettings.push(defaultSettings);
    }

    const agents = await this.delivery.agents(this.queryStrings.agents);
    const { data: { agents: agentsData } } = agents;

    const extraServiceList = await this.delivery.extraServiceTypes();
    this.extraServiceList = extraServiceList.data.extraServiceTypes;

    const collectionPlaces = await this.collectionPlace.collectionPlaces();
    this.collectionPlaces = collectionPlaces.data.collectionPlaces.filter((cp) => cp.active === true);

    const { upload: { csvFile } } = this.props;
    const activeSettingsIndex = csvImportSettings !== null ? 0 : null;
    const [extraServices, requiredList] = this.getCurrentExtraservicesAndRequiredFields(
      activeSettingsIndex,
      csvImportSettings,
      agentsData,
    );

    const hasHeader = (activeSettingsIndex !== null) ? csvImportSettings[activeSettingsIndex].hasHeader : false;

    this.setState({
      activeSettingsIndex,
      activeAgentIndex: null,
      csvImportSettings,
      activeCSVSeparator: (activeSettingsIndex !== null) ? csvImportSettings[activeSettingsIndex].documentSeparator : null,
      inputs: {
        csv: {
          el: null,
          file: csvFile || null,
          fileName: csvFile ? csvFile.name : t('deliveries:NewDeliveryCSV.SourceFile.uploadFileButton'),
        },
        extraServices,
      },
      hasHeader,
      table: {
        data: null,
        columns: [
          {
            title: '',
            index: 'columnLetter',
            key: '0',
            width: '10%',
          },
          {
            title: '',
            index: 'key',
            key: '1',
            className: hasHeader ? 'text-truncate col-toggle col-visible' : 'text-truncate col-toggle col-invisible',
          },
          {
            title: '',
            index: 'value',
            key: '2',
            width: '30%',
          },
          {
            title: '',
            index: 'input',
            key: '3',
            width: '30%',
          },
        ],
        actions: {},
        config: {
          showHeader: false,
          striped: false,
          borderless: false,
          className: 'basic import-csv fixed',
        },
      },
      csvRequiredInputList: requiredList,
      csvInputValues: {},
      agents: agentsData,
      dataErrors: [],
    }, this.parseCSV);
  }

  setRequiredCSVFieldValues = (requiredCSVFields, activeSettingsIndex, currentSettings) => {
    if (currentSettings === null || currentSettings === undefined) {
      return [];
    }

    // CSV fields defined in csvFieldSchema.js
    const requiredCSVFieldsWithValues = { ...requiredCSVFields };
    const isCSVPresetSelected = activeSettingsIndex !== null;

    // CSV column mapping mapped from loaded settings
    const allCSVColumnNumbers = Object.keys(currentSettings)
      .filter((columnKey) => columnKey.endsWith('ColumnNumber'))
      .map((columnKey) => columnKey.replace('ColumnNumber', ''))
      .map((columnKey) => ({ [columnKey]: currentSettings[`${columnKey}ColumnNumber`] }));

    Object.keys(requiredCSVFieldsWithValues).forEach((requiredFieldKey) => {
      if (!isCSVPresetSelected) {
        requiredCSVFieldsWithValues[requiredFieldKey].value = null;
      } else {
        // eslint-disable-next-line arrow-body-style
        const existingValue = allCSVColumnNumbers.find((column) => {
          return Object.keys(column)[0] === requiredFieldKey;
        });

        /**
         * Loaded settings might not contain all values from csvFieldSchema.js,
         * some options could've been added after the settings were initially saved
         */
        if (existingValue) {
          requiredCSVFieldsWithValues[requiredFieldKey].value = existingValue[requiredFieldKey];
        } else {
          requiredCSVFieldsWithValues[requiredFieldKey].value = null;
        }
      }
    });

    return requiredCSVFieldsWithValues;
  };

  resetDefaultValues() {
    this.setFieldValue('recipientState', '');
    this.setFieldValue('cashOnDeliveryCurrency', '');
    this.setFieldValue('carrier', '');
    this.setFieldValue('deliveryType', '');
    this.setFieldValue('shipmentCurrency', '');
    this.setFieldValue('collectionPlace', '');
    this.setFieldValue('isMonitored', false);

    const resetExtraServices = produce(this.state, (draft) => {
      draft.inputs.csv.extraServices = [];
    });

    this.setState(resetExtraServices);
  }

  csvInputIsInUse(value) {
    const { csvInputValues } = this.state;

    return Object.values(csvInputValues).some((item) => (
      item.value ? item.value === value : false
    ));
  }

  changeSettingsSelectToDefaultValue() {
    const { t } = this.props;
    const { csvImportSettings } = this.state;
    let select;
    let option;

    if (csvImportSettings.length > 0) {
      select = { name: 'savedSettings' };
      option = {
        label: csvImportSettings[0].name,
        value: csvImportSettings[0].id,
      };
    } else {
      select = { name: 'savedSettings' };
      option = {
        label: t('deliveries:NewDeliveryCSV.defaultLabel'),
        value: '',
      };
    }

    this.handleSettingsChange(this.setFieldValue, option, select);
  }

  renderSettingsOptions = () => {
    const { t } = this.props;
    const { csvImportSettings } = this.state;

    const settingsOptions = csvImportSettings.map((item) => (
      { value: item.id, label: item.name }
    ));

    if (!settingsOptions.find((item) => item.value === '')) {
      settingsOptions.push({
        value: '',
        label: t('deliveries:NewDeliveryCSV.defaultLabel'),
      });
    }

    return settingsOptions;
  };

  renderCollectionPlaceOptions = () => {
    const { t } = this.props;
    const options = this.collectionPlaces.map((item) => (
      { value: item.id, label: item.name }
    ));

    options.push({
      value: '',
      label: t('deliveries:NewDeliveryCSV.defaultLabel'),
    });

    return options;
  };

  render() {
    const { t } = this.props;

    if (!this.state) {
      return <Loader />;
    }

    const {
      activeSettingsIndex,
      hasHeader,
      csvImportSettings,
      inputs: {
        csv: {
          file: inputsCsvFile,
          fileName: inputsCsvFileName,
        },
        extraServices,
      },
      table: { values: tableValues },
      csvInputValues,
      activeAgentIndex,
      agents,
      activeCSVSeparator,
      dataErrors,
    } = this.state;

    const title = (
      <>
        <Link to="/deliveries/">
          <div className="page-back-btn-overlay">
            <FontAwesomeIcon icon={['fas', 'arrow-alt-circle-left']} className="text-muted page-back-btn" />
          </div>
        </Link>
        {t('deliveries:NewDeliveryCSV.title')}
      </>
    );

    return (
      <MainBody wrapperHeight="150px" title={title}>
        {(tableValues && dataErrors.length > 0) && (
          <CSVImportErrorTable
            isFirstRowHeader={hasHeader}
            columns={csvInputValues}
            rows={tableValues}
            errors={dataErrors}
            columnSchema={this.csvColumns}
          />
        )}
        <Formik
          initialValues={(activeSettingsIndex !== null) ? {
            fileFirstLineHeader: hasHeader,
            collectionPlaceAsDefault: false,
            shipmentCurrency: csvImportSettings[activeSettingsIndex].defaultValueCurrency || '',
            cashOnDeliveryCurrency: csvImportSettings[activeSettingsIndex].defaultCodCurrency || '',
            deliveryType: csvImportSettings[activeSettingsIndex].deliveryType || '',
            settingsSave: false,
            carrier: csvImportSettings[activeSettingsIndex].defaultAgent || '',
            recipientState: csvImportSettings[activeSettingsIndex].defaultCountry || '',
            collectionPlace:
              (csvImportSettings[activeSettingsIndex].defaultCollectionPlace)
              && (csvImportSettings[activeSettingsIndex].defaultCollectionPlace.id || ''),
            savedSettings: csvImportSettings[activeSettingsIndex].id || '',
            settingsName: '',
            isMonitored: csvImportSettings[activeAgentIndex]?.isMonitored ?? false,
          } : {
            deliveryType: '',
            fileFirstLineHeader: hasHeader,
            collectionPlaceAsDefault: false,
            shipmentCurrency: '',
            cashOnDeliveryCurrency: '',
            settingsSave: false,
            carrier: '',
            recipientState: '',
            collectionPlace: '',
            savedSettings: '',
            settingsName: '',
            isMonitored: false,
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);

            const b64helper = new Base64Helper(inputsCsvFile);
            const csvColumns = { ...csvInputValues };
            const dataForMutation = { settings: csvColumns };
            const selectedAgent = (activeAgentIndex !== null)
              ? agents[activeAgentIndex].abbr : null;
            const selectNames = [
              'shipmentCurrency',
              'cashOnDeliveryCurrency',
              'carrier',
              'recipientState',
              'collectionPlace',
              'savedSettings',
              'deliveryType',
            ];
            const formValues = { ...values };
            const errorComponents = [];

            Object.keys(dataForMutation.settings).forEach((key) => {
              const csvColumnsObj = dataForMutation.settings;

              if (csvColumnsObj[key].value in this.csvColumns) {
                const formattedKeyForMutation = `${csvColumnsObj[key].value}ColumnNumber`;
                csvColumnsObj[formattedKeyForMutation] = parseInt(key, 10) + 1;
              }

              delete csvColumns[key];
            });

            const unselectedRequiredFields = [];

            Object.keys(this.csvColumns).forEach((key) => {
              const csvColumnsObj = dataForMutation.settings;

              if (!this.csvInputIsInUse(key)) {
                const formattedKeyForMutation = `${key}ColumnNumber`;
                csvColumnsObj[formattedKeyForMutation] = null;

                if (
                  this.csvColumns[key].required
                  // Monitored deliveries require `trackingNumber` column
                  || (key === 'trackingNumber' && formValues.isMonitored === true)
                ) {
                  unselectedRequiredFields.push(
                    this.csvColumns[key].label,
                  );
                }
              }
            });

            unselectedRequiredFields.forEach((label) => {
              errorComponents.push(
                <React.Fragment key={generateReactKey()}>
                  <div>
                    {t('deliveries:NewDeliveryCSV.unselectedRequiredFields', { labelName: label })}
                  </div>
                  <br />
                </React.Fragment>,
              );
            });

            if (unselectedRequiredFields.length > 0) {
              toast.error(
                <>
                  {[...errorComponents]}
                </>,
              );
              return setSubmitting(false);
            }

            selectNames.forEach((selectName) => {
              if (
                formValues[selectName]
                && Object.prototype.hasOwnProperty.call(formValues[selectName], 'value')
                && formValues[selectName].value !== ''
              ) {
                formValues[selectName] = formValues[selectName].value;
              } else if ((formValues[selectName] && formValues === '')
                || (formValues[selectName] && formValues[selectName].value === '')) {
                formValues[selectName] = null;
              }
            });

            dataForMutation.csvFile = {};
            dataForMutation.csvFile.name = inputsCsvFileName;
            dataForMutation.csvFile.rawSource = await b64helper.getBase64String();
            dataForMutation.saveConfigurationAsTemplate = formValues.settingsSave;
            dataForMutation.settings.documentSeparator = activeCSVSeparator;
            dataForMutation.settings.hasHeader = formValues.fileFirstLineHeader;
            dataForMutation.settings.name = formValues.settingsName;
            dataForMutation.settings.defaultAgent = (selectedAgent === 'ČP') ? 'CP' : selectedAgent;
            dataForMutation.settings.defaultExtraServices = [];
            dataForMutation.settings.isMonitored = formValues.isMonitored;

            if (extraServices) {
              extraServices.forEach((service) => {
                dataForMutation.settings.defaultExtraServices.push(service.value);
              });
            }

            dataForMutation.settings.defaultCountry = nullifyString(formValues.recipientState);
            dataForMutation.settings.defaultValueCurrency = nullifyString(formValues.shipmentCurrency);
            dataForMutation.settings.defaultCodCurrency = nullifyString(formValues.cashOnDeliveryCurrency);
            dataForMutation.settings.defaultCollectionPlace = formValues.collectionPlace;
            dataForMutation.settings.defaultDeliveryType = nullifyString(formValues.deliveryType);

            try {
              let response = await this.delivery.createDeliveryCsvImport(dataForMutation);
              response = response.data.createDeliveryCsvImport;

              const {
                history: { push: historyPush },
                setFilterAndFlags,
                setPage,
              } = this.props;

              if (response.success) {
                historyPush(basePaths.deliveries);
                setFilterAndFlags({ filter: 'inProgress', flags: null });
                setPage(1);
                if (formValues.settingsSave) {
                  toast.success(t('deliveries:NewDeliveryCSV.Banner.newSettingsSaveSuccess'));
                }
                toast.success(t('deliveries:NewDeliveryCSV.Banner.allDeliveriesSaveSuccess', { createdDeliveriesNumber: response.createdDeliveriesNumber }));
              }

              if (response.errors.fileErrors.length > 0) {
                response.errors.fileErrors.forEach((error) => {
                  errorComponents.push(nl2p(error));
                });
              }

              response.errors.dataErrors = sortBy(response.errors.dataErrors, 'row');

              if (response.errors.dataErrors.length > 0) {
                const updatedErrorState = produce(this.state, (draft) => {
                  draft.dataErrors = response.errors.dataErrors;
                });

                this.setState(updatedErrorState, () => {
                  setTimeout(() => {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }, 10);
                });
              }

              if (errorComponents.length > 0) {
                toast.error(
                  <>
                    {[...errorComponents]}
                  </>,
                );
              }
            } catch (err) {
              return console.warn(err.graphQLErrors ? err.graphQLErrors[0].message : err);
            } finally {
              setSubmitting(false);
            }

            return setSubmitting(false);
          }}
          validationSchema={Yup.object().shape({
            collectionPlace: Yup.object().shape({
              value: Yup.string().required(t('deliveries:NewDeliveryCSV.CollectionPlace.required')),
            }).typeError(t('deliveries:NewDeliveryCSV.CollectionPlace.required')),
          })}
          component={this.getImportCSVFormComponent}
        />
      </MainBody>
    );
  }
}

function mapStateToProps(state) {
  return { upload: state.deliveryReducer.upload };
}

function mapDispatchToProps(dispatch) {
  return {
    setCSVFileUpload: (csvFile) => dispatch(DeliveryActionCreators.setCSVFile(csvFile)),
    setFilterAndFlags: (filter) => dispatch(DeliveryActionCreators.setFilterAndFlags(filter)),
    setPage: (page) => dispatch(DeliveryActionCreators.setPage(page)),
  };
}

ImportCSV.propTypes = {
  history: PropTypes.shape({}).isRequired,
  upload: PropTypes.shape({ csvFile: PropTypes.any }).isRequired,
  setFilterAndFlags: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['deliveries'])(ImportCSV));
