import React from 'react';
import PropTypes from 'prop-types';

import LoaderUI from 'Components/Presentational/Loader/Loader';

export default class Loader extends React.Component {
  constructor(props) {
    super(props);
    this.state = { shouldDisplaySpinner: false };
    this.minLoaderTimeout = 200;
    this.mounted = true;
  }

  componentDidMount() {
    const { isVisible } = this.props;

    if (!isVisible && !this.spinnerTimeoutId) {
      this.spinnerTimeoutId = setTimeout(() => {
        if (!this.mounted) return;
        this.setState({ shouldDisplaySpinner: true });
      }, this.minLoaderTimeout);
    } else {
      clearTimeout(this.loaderTimeoutId);
      this.loaderTimeoutId = undefined;
      this.setState({ shouldDisplaySpinner: false });
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    const { shouldDisplaySpinner } = this.state;
    let display = 'none';

    if (shouldDisplaySpinner) {
      display = 'block';
    }

    return (
      <div style={{ display }}>
        <LoaderUI {...this.props} />
      </div>
    );
  }
}

Loader.defaultProps = {
  isVisible: false,
  fullscreen: true,
};

Loader.propTypes = {
  isVisible: PropTypes.bool,
  fullscreen: PropTypes.bool,
};
