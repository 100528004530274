import statusToBadgeMapper from 'Containers/Settings/Branding/helpers/statusToBadgeMapper';
import { useFormikContext } from 'formik';
import { useCommonField } from 'foxdeli-shared';
import React from 'react';
import './SimpleSwitch.scss';
import { useTranslation } from 'react-i18next';
import { Badge, FormGroup } from 'reactstrap';
import { Form } from 'react-bootstrap';

export type Props = {
  name: string,
  className?: string,
  badgeStatus?: boolean,
  onChange?: (value: boolean) => void,
};

const SimpleSwitch: React.FC<Props> = ({ name, className, badgeStatus = null, onChange, ...props }) => {
  const [field, , , flags] = useCommonField(name);
  const { t } = useTranslation(['common']);
  const { setFieldValue } = useFormikContext();

  return (
    <FormGroup
      data-test={`switch-${name}`}
      className="simple-switch-wrapper"
      onClick={() => {
        setFieldValue(name, !field.value);
        if (onChange) onChange(!field.value);
      }}
    >

      {badgeStatus !== null && (
        <Badge className="mr-2 mt-1" color={statusToBadgeMapper(badgeStatus)}>
          {t(`common:BadgeStatus.${
            badgeStatus ? 'active' : 'inactive'
          }`)}
        </Badge>
      )}

      <Form.Check
        className={`simple-switch ${field.value ? 'simple-switch-checked' : ''} ${className} ${flags.hasFeedback ? 'has-feedback' : ''}`.trim()}
      >
        <Form.Check.Input
          {...field}
          {...props}
          className="simple-switch-input"
          type="checkbox"
          name={name}
          isInvalid={flags.isInvalid}
          checked={field.value}
        />
        <span className="simple-switch-slider" />
      </Form.Check>
    </FormGroup>
  );
};

export default SimpleSwitch;
