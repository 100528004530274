import { ImageResource } from 'Containers/Settings/Branding/typesAndDefaults';
import { generate } from 'shortid';

export type QueryMarketingCampaignsListData = {
  eshop: {
    eshopCampaigns: MarketingCampaign[];
    activeEshopCampaign: MarketingCampaign;
  }
};

export type MutationMarketingCampaignActivate = {
  activateEshopCampaign: {
    id: string;
  }
};

export type MutationMarketingCampaignDeactivate = {
  deactivateEshopCampaign: {
    id: string;
  }
};

export type MutationMarketingCampaignDelete = {
  deleteEshopCampaign: {
    success: string;
    message: string;
    errors: string;
  }
};

export type MutationMarketingCampaignBannerDelete = {
  deleteEshopCampaignBanner: {
    success: string;
    message: string;
    errors: string;
  }
};

export type MarketingCampaignMutationInput = {
  linkWeb?: string;
  linkEmail?: string;
  campaignName?: string;
  isTrackAndTraceActive?: boolean;
  isEmailCommunicationActive?: boolean;
  isActive?: boolean;
  defaultDesktopBannerId?: number | string | null;
  defaultMobileBannerId?: number | string | null;
  defaultEmailBannerId?: number | string | null;
};

export type MarketingCampaignConfigMutation = {
  config: MarketingCampaignMutationInput;
  id?: string;
};

export type MarketingCampaign = {
  id: string;
  campaignName: string;
  defaultDesktopBanner?: ImageResource;
  defaultEmailBanner?: ImageResource;
  updatedAt: Date | string;
  clicks: number;
  leads: number;
  views: number;
};

export type MarketingCampaignFormValues = {
  id?: number;
  campaignName: string;
  linkWeb: string;
  linkEmail: string;
  eshopCampaignBanners: Array<BannerItem>;

  defaultDesktopBanner?: ImageResource | null;
  defaultMobileBanner?: ImageResource | null;
  defaultEmailBanner?: ImageResource | null;

  defaultDesktopBannerImage?: File | string | null;
  defaultMobileBannerImage?: File | string | null;
  defaultEmailBannerImage?: File | string | null;

  isTrackAndTraceActive: boolean;
  isEmailCommunicationActive: boolean;

  activateAfterSave?: boolean;
};

export type MarketingCampaignConfigQuery = {
  eshopCampaignById: MarketingCampaignFormValues;
};

export type MarketingCampaignCreationResponse = {
  createEshopCampaign: {
    id: string,
    campaignName: string,
  }
};

export type MarketingCampaignUpdateResponse = {
  updateEshopCampaign: MarketingCampaignFormValues;
};

export type BannerItem = {
  id?: string,
  country: string;
  isActive?: boolean;
  linkWeb?: string
  linkEmail?: string

  isTrackAndTraceActive: boolean;
  isEmailCommunicationActive: boolean;

  emailCommunicationBanner?: ImageResource | null;
  trackAndTraceMobileBanner?: ImageResource | null;
  trackAndTraceDesktopBanner?: ImageResource | null;

  emailCommunicationBannerImage?: File | string | null;
  trackAndTraceMobileBannerImage?: File | string | null;
  trackAndTraceDesktopBannerImage?: File | string | null;
};

export const bannerItemInitValues = (): BannerItem => {
  return {
    id: generate(),
    country: '',
    linkWeb: '',
    linkEmail: '',
    isTrackAndTraceActive: false,
    isEmailCommunicationActive: false,
  };
};

export const marketingCampaignInitialValues: MarketingCampaignFormValues = {
  campaignName: '',
  linkWeb: '',
  linkEmail: '',
  isTrackAndTraceActive: true,
  isEmailCommunicationActive: true,
  eshopCampaignBanners: [],

  defaultMobileBanner: null,
  defaultDesktopBanner: null,
  defaultEmailBanner: null,

  defaultDesktopBannerImage: null,
  defaultMobileBannerImage: null,
  defaultEmailBannerImage: null,
};

export type CampaignBannerInputValues = {
  country?: string;
  linkWeb?: string;
  linkEmail?: string;
  isActive?: boolean;
  isTrackAndTraceActive?: boolean;
  isEmailCommunicationActive?: boolean;
  emailCommunicationBannerId?: number | string;
  trackAndTraceDesktopBannerId?: number | string;
  trackAndTraceMobileBannerId?: number | string;
};

export type CampaignBannerCreationMutation = {
  config: CampaignBannerInputValues;
  eshopCampaignId: string;
};

export type CampaignBannerUpdateMutation = {
  config: CampaignBannerInputValues;
  eshopCampaignBannerId: string;
};

export type CampaignBannerResponseValues = {
  id?: string;
  country: string;
  linkWeb: string;
  linkEmail: string;
  isActive: boolean;
  isTrackAndTraceActive: boolean;
  isEmailCommunicationActive: boolean;

  emailCommunicationBanner: null | File | string;
  trackAndTraceDesktopBanner: null | File | string;
  trackAndTraceMobileBanner: null | File | string;
};

export type CampaignBannerCreationResponse = {
  createEshopCampaignBanner: CampaignBannerResponseValues;
};

export type CampaignBannerUpdateResponse = {
  updateEshopCampaignBanner: CampaignBannerResponseValues;
};
