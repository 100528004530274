import React from 'react';
import { toast } from 'Shared/utilities';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import ReactRouterTypes from 'Types/ReactRouter/ReactRouterTypes';
import CSVDropModal from 'Components/Presentational/CSVDropModal/CSVDropModal';
import CSVDropHelper from 'Tools/CSVDropHelper';

class CSVDragAndDrop extends React.Component {
  constructor(props) {
    super(props);

    this.dom = { getDropzone: () => document.querySelector('.dropZone') };
    this.state = { isCSVModalOpen: false };
  }

  componentDidMount() {
    document.addEventListener('dragover', this.handleDrag);
    this.dom.getDropzone().addEventListener('dragleave', this.handleDragLeave);
  }

  componentWillUnmount() {
    document.removeEventListener('dragover', this.handleDrag);
    this.dom.getDropzone().removeEventListener('dragleave', this.handleDragLeave);
  }

  setModalOpen = (open) => {
    this.setState({ isCSVModalOpen: open });
  };

  handleDrag = (e) => {
    e.preventDefault();
    this.setState({ isCSVModalOpen: true });
  };

  handleDragLeave = () => {
    this.setState({ isCSVModalOpen: false });
  };

  handleDrop = (files) => {
    const { history, setCSVFileUpload, t } = this.props;
    CSVDropHelper.handleDrop(files, history, setCSVFileUpload, toast, t, this.setModalOpen);
  };

  render() {
    const { isCSVModalOpen } = this.state;

    return (
      <CSVDropModal
        onDrop={this.handleDrop}
        isOpen={isCSVModalOpen}
        centered
      />
    );
  }
}

CSVDragAndDrop.propTypes = {
  history: ReactRouterTypes.history().isRequired,
  setCSVFileUpload: PropTypes.func.isRequired,
};

export default withTranslation(['deliveries'])(CSVDragAndDrop);
