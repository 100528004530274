import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { ApolloConsumer } from 'react-apollo';
import { interpret } from 'xstate';

import CloseDelivery from '../CloseDelivery/CloseDelivery';
import DuplicateDelivery from '../DuplicateDelivery/DuplicateDelivery';
import CancelDelivery from '../CancelDelivery/CancelDelivery';
import LocalDeliveryTypes from '../../LocalDeliveryTypes/LocalDeliveryTypes';
import RefreshDelivery from '../RefreshDelivery/RefreshDelivery';
import EditDelivery from '../EditDelivery/EditDelivery';
import InteractionMenuModals from '../../Presentational/InteractionMenuModals/InteractionMenuModals';
import StopDeliveryProcess from '../../Presentational/StopDeliveryProcess/StopDeliveryProcess';
import { confirmModalMachine, mutationMachine } from './Machines';

/* eslint-disable jsx-a11y/click-events-have-key-events */
export default class DeliveryInteractionMenu extends React.Component {
  constructor(props) {
    super(props);

    this.confirmModalMachine = confirmModalMachine();
    this.mutationMachine = mutationMachine();
    this.state = {
      dropdownOpen: false,
      mutation: this.mutationMachine.initialState,
      confirmModal: this.confirmModalMachine.initialState,
    };

    this.mutationService = interpret(this.mutationMachine).onTransition((mutation) => this.setState({ mutation }));
    this.modalService = interpret(this.confirmModalMachine).onTransition((confirmModal) => this.setState({ confirmModal }));

    this.disableActionToggleIcon = {
      waiting: false,
      inProgress: true,
    };

    this.actionToggleIconProps = {
      waiting: { icon: ['fas', 'ellipsis-h'] },
      inProgress: {
        icon: ['fas', 'hourglass'],
        spin: true,
      },
    };
  }

  componentDidMount() {
    this.mutationService.start();
    this.modalService.start();
  }

  componentWillUnmount() {
    this.mutationService.stop();
    this.modalService.stop();
  }

  toggle = () => {
    const { dropdownOpen } = this.state;
    this.setState({ dropdownOpen: !dropdownOpen });
  };

  render() {
    const { dropdownOpen, mutation, confirmModal } = this.state;
    const {
      canClose,
      canCancel,
      canUpdate,
      refetchDeliveryDetail,
      deliveryId,
      push,
      canStopDeliveryProcess,
      isMonitored,
    } = this.props;

    return (
      <div>
        <div className="btn-group mr-2 mt-1" role="group">
          <RefreshDelivery
            mutationService={this.mutationService}
            mutationState={mutation.value.refreshMutation}
            refetchDeliveryDetail={refetchDeliveryDetail}
          />
        </div>
        <div className="btn-group mt-1" role="group">
          <Dropdown
            isOpen={dropdownOpen}
            toggle={this.toggle}
            tabIndex={0}
            direction="left"
            role="button"
          >
            <DropdownToggle
              color="link"
              disabled={this.disableActionToggleIcon[mutation.value.actionDropdownMutation]}
            >
              <FontAwesomeIcon
                {...this.actionToggleIconProps[mutation.value.actionDropdownMutation]}
                className="no-margin"
              />
            </DropdownToggle>
            <ApolloConsumer>
              {(client) => (
                <DropdownMenu>
                  {!isMonitored && (
                    <CloseDelivery
                      client={client}
                      canClose={canClose}
                      modalService={this.modalService}
                      modalState={confirmModal.value.closeDelivery}
                      mutationService={this.mutationService}
                      deliveryId={deliveryId}
                      refetchDeliveryDetail={refetchDeliveryDetail}
                    />
                  )}
                  <DuplicateDelivery
                    push={push}
                    deliveryId={deliveryId}
                  />
                  <EditDelivery
                    push={push}
                    deliveryId={deliveryId}
                    canUpdate={canUpdate}
                  />
                  <CancelDelivery
                    client={client}
                    canCancel={canCancel}
                    modalService={this.modalService}
                    modalState={confirmModal.value.cancelDelivery}
                    mutationState={mutation}
                    mutationService={this.mutationService}
                    deliveryId={deliveryId}
                    refetchDeliveryDetail={refetchDeliveryDetail}
                  />
                  <StopDeliveryProcess
                    canStopDeliveryProcess={canStopDeliveryProcess}
                    onClick={() => this.modalService.send(
                      'STOP_DELIVERY_PROCESS_MODAL_SHOWN',
                    )}
                  />
                </DropdownMenu>
              )}
            </ApolloConsumer>
          </Dropdown>
          <InteractionMenuModals
            deliveryId={deliveryId}
            confirmModalState={confirmModal}
            modalService={this.modalService}
            refetchDeliveryDetail={refetchDeliveryDetail}
          />
        </div>
      </div>
    );
  }
}

DeliveryInteractionMenu.propTypes = { ...LocalDeliveryTypes.deliveryMenuInteraction() };
