import { Button, Form, Row, Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import FormikTypes from 'Types/formik/FormikTypes';
import FormikScroll from '~/components/FormikScroll/FormikScroll';
import AddressSection from '../AddressSection/AddressSection';
import ContactSection from '../ContactSection/ContactSection';

const ContactForm = (props) => {
  const { t } = useTranslation('contacts');
  const { handleSubmit, isSubmitting, buttonLabel } = props;

  return (
    <Form onSubmit={handleSubmit}>
      <FormikScroll />
      <Row className="contacts shadow-sm border border-light">
        <ContactSection {...props} />
        <AddressSection {...props} />
      </Row>
      <div className="text-center pt-4">
        <Button type="submit" color="primary" className="mr-3" disabled={isSubmitting}>
          {isSubmitting && <Spinner size="sm" />}
          &nbsp;
          {buttonLabel || t('EditContact.submitButton')}
        </Button>
        <Link to="/contacts">
          <Button color="link">
            {t('ContactForm.cancelButton')}
          </Button>
        </Link>
      </div>
    </Form>
  );
};

ContactForm.propTypes = {
  buttonLabel: PropTypes.string,
  ...FormikTypes.objects().isRequired,
  ...FormikTypes.booleans().isRequired,
  ...FormikTypes.eventHandlers().isRequired,
};

export default ContactForm;
