import * as actionTypes from 'StoreActions/actionTypes';
import { updateObject } from 'Shared/utilities';

const initialState = {
  data: {
    id: null,
    name: null,
    surname: null,
    login: null,
    email: null,
    phone: null,
    company: null,
    businessClass: null,
    primaryAccess: false,
    protocolAccess: false,
    deliveryAccessType: null,
    roles: [],

    // Computed properties
    hasCollectionPlace: true,
    hasSetCredentials: true,
    hasDeliveriesFromApi: true,
    hasActiveTrackAndTrace: true,
    hasActiveMailing: true,
    eshopPlatform: null,
    hasActiveBranding: false,
  },
  isLoading: false,
  error: {
    inError: false,
    data: {
      code: null,
      msg: null,
      msgDesc: null,
    },
  },
};

const fetchUserStart = (state) => updateObject(state, {
  isLoading: true,
  error: { inError: false },
});

const fetchUserFail = (state, action) => updateObject(state, {
  isLoading: false,
  error: {
    inError: true,
    data: action.error,
  },
});

const setUserMeta = (state, action) => {
  const { eshopPlatform, hasSetCredentials, hasDeliveriesFromApi, hasActiveTrackAndTrace, hasActiveMailing } = action.data;

  return {
    ...state,
    data: {
      ...state.data,
      eshopPlatform: eshopPlatform ?? state.data.eshopPlatform,
      hasSetCredentials: hasSetCredentials ?? state.data.hasSetCredentials,
      hasDeliveriesFromApi: hasDeliveriesFromApi ?? state.data.hasDeliveriesFromApi,
      hasActiveTrackAndTrace: hasActiveTrackAndTrace ?? state.data.hasActiveTrackAndTrace,
      hasActiveMailing: hasActiveMailing ?? state.data.hasActiveMailing,
    },
  };
};

const fetchUserSuccess = (state, action) => {
  const { user, eshop } = action.data;
  const { id, login, company, businessClass, primaryAccess, protocolAccess, deliveryAccessType } = user.account;
  const { name, surname, email, phone } = user.account.contact;
  const { collectionPlaces, credentials } = user.account.company;

  return {
    ...state,
    data: {
      ...state.data,
      id,
      name,
      surname,
      login,
      email,
      phone,
      company,
      businessClass,
      primaryAccess,
      protocolAccess,
      deliveryAccessType,
      roles: user.roles,
      eshopPlatform: eshop?.platform ?? null,
      hasActiveTrackAndTrace: eshop?.activeTrackAndTraceConfig !== null,
      hasActiveMailing: eshop?.activeEmailConfig !== null,
      hasCollectionPlace: collectionPlaces.length > 0,
      hasSetCredentials: credentials.length > 0 && credentials.some((c) => c.status !== 'incomplete'),
      hasActiveBranding: company?.subscription?.branding ?? false,
    },
    isLoading: false,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_START:
      return fetchUserStart(state, action);
    case actionTypes.FETCH_USER_FAIL:
      return fetchUserFail(state, action);
    case actionTypes.FETCH_USER_SUCCESS:
      return fetchUserSuccess(state, action);
    case actionTypes.USER_LOGGED_OUT:
      return initialState;
    case actionTypes.SET_USER_META:
      return setUserMeta(state, action);
    default:
      return state;
  }
};

export default reducer;
