import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import './CompanyInformationBanner.scss';

const CompanyInformationBanner: React.FC = () => {
  const { t } = useTranslation(['settings']);

  return (
    <div className="company-information-banner">
      <div className="company-information-banner-image">
        <img src="/assets/img/socials.png" alt="social icons" />
      </div>
      <div className="company-information-banner-text">
        <h3>{t('settings:Branding.CompanyInformationBanner.title')}</h3>
        <p>{t('settings:Branding.CompanyInformationBanner.description')}</p>
      </div>
      <div className="company-information-banner-link">
        <Link to="/settings/information" className="btn btn-primary">
          {t('settings:Branding.CompanyInformationBanner.setButtonText')}
        </Link>
      </div>
    </div>
  );
};

export default CompanyInformationBanner;
