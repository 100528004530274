export type ImageResource = {
  id: number,
  currentFolder?: string;
  fileName?: string;
  height?: number
  mimeType?: string;
  url: string;
  width?: number
};

export const DATE_FORMAT_BRANDING = 'PP, p';

export const ACCEPTED_FILE_TYPES = {
  Web: 'image/png, image/jpeg, image/svg+xml',
  Email: 'image/png, image/jpeg',
} as const;

export type EshopBrandingDataQuery = {
  name: string;
  webUrl: string;
  activeTrackAndTraceConfig: { id: string };
  activeEmailConfig: { id: string };
  activeEshopCampaign: { id: string };
};
