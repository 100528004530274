import getImageIdFromRootObject from 'Containers/Settings/Branding/helpers/getImageIdFromRootObject';
import EMAIL_ACTIVATE from 'Containers/Settings/Branding/Mailing/queries/activateEmail.gql';
import GET_ESHOP_BRANDING_DATA from 'Containers/Settings/Branding/queries/getEshopBrandingData.gql';
import { EshopBrandingDataQuery } from 'Containers/Settings/Branding/typesAndDefaults';
import { FormikHelpers } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams as useRouterParams } from 'react-router-dom';
import StatusBanner from '~/components/StatusBanner/StatusBanner';
import { filterObjectEmptyProperties } from '../helpers/filterObjectEmptyProperties';
import { sanitizeObjectNullishValues } from '../helpers/sanitizeObjectNullishValues';
import FoxLoader from '~/components/FoxLoader/FoxLoader';
import { uploadImage } from '~/helpers/imageHelper';
import {
  EmailConfigValues,
  emailConfigValuesDefaults,
  MutationEmailActivate,
  MutationEshopEmailConfigInput,
  MutationEshopEmailConfigResponse,
} from './typesAndDefaults';
import { toast } from '#/shared/utilities';
import useAuthMutation from '~/hooks/useAuthMutation';
import useAuthQuery from '~/hooks/useAuthQuery';
import MailingForm from './MailingForm/MailingForm';

import UPDATE_EMAIL_CONFIG from './queries/updateEmail.gql';
import FETCH_EMAIL_CONFIG from './queries/fetchEmail.gql';

const EditMailing: React.FC = () => {
  const { t } = useTranslation(['settings']);
  const { id: routeMailingConfigId = '' } = useRouterParams<{ id: string }>();
  const [formIsSubmitting, setFormIsSubmitting] = React.useState(false);
  const [updateEmailConfigMutation] = useAuthMutation<MutationEshopEmailConfigResponse, MutationEshopEmailConfigInput>(UPDATE_EMAIL_CONFIG);
  const [activateEmailConfigMutation] = useAuthMutation<MutationEmailActivate, { id: string }>(EMAIL_ACTIVATE);

  const {
    data: emailConfigDataQuery, loading: emailConfigLoadingQuery,
    refetch: refetchEmailConfigQuery,
  } = useAuthQuery<{ eshopEmailConfigById: EmailConfigValues }, { id: string }>(FETCH_EMAIL_CONFIG, { id: routeMailingConfigId });
  const { data: eshopQueryData, refetch: refetchEshopQueryData } = useAuthQuery<{ eshop: EshopBrandingDataQuery }, never>(GET_ESHOP_BRANDING_DATA);
  const emailConfigFetchedValues = sanitizeObjectNullishValues(emailConfigDataQuery?.eshopEmailConfigById) || emailConfigValuesDefaults;
  emailConfigFetchedValues.logoImg = getImageIdFromRootObject(emailConfigFetchedValues.logo);

  const submitEmailConfig = async (emailConfigFormValues: EmailConfigValues, formikHelpers: FormikHelpers<EmailConfigValues>, onSubmitCallback: () => void) => {
    setFormIsSubmitting(true);
    try {
      const [logoUploadData] = await Promise.all([uploadImage(emailConfigFormValues.logoImg)]);

      await updateEmailConfigMutation({
        variables: {
          id: routeMailingConfigId,
          config: {
            title: emailConfigFormValues.title,
            sender: emailConfigFormValues.sender,
            senderEmail: emailConfigFormValues.senderEmail,
            primaryColor: emailConfigFormValues.primaryColor,
            linkColor: emailConfigFormValues.linkColor,
            headerColor: emailConfigFormValues.headerColor,
            headerBackgroundColor: emailConfigFormValues.headerBackgroundColor,
            ...filterObjectEmptyProperties({
              buttonColor: emailConfigFormValues.buttonColor,
              buttonBackgroundColor: emailConfigFormValues.buttonBackgroundColor,
              logoId: logoUploadData?.data.imageId || emailConfigFormValues.logo?.id,
            }),
          },
        },
      });

      if (emailConfigFormValues.activateAfterSave) {
        formikHelpers.setFieldValue('activateAfterSave', false);
        await activateEmailConfigMutation({ variables: { id: routeMailingConfigId } });
        toast.success(t('settings:Branding.Mailing.Message.templateUpdatedAndActivated'));
      } else {
        toast.success(t('settings:Branding.Mailing.Message.templateUpdated'));
      }

      await refetchEmailConfigQuery();
      await refetchEshopQueryData();
      formikHelpers.setSubmitting(false);
      setFormIsSubmitting(false);
    } catch (e) {
      // Handled in global error interceptor
      formikHelpers.setSubmitting(false);
      setFormIsSubmitting(false);
    }

    onSubmitCallback();
  };

  return (
    <>
      {eshopQueryData?.eshop?.activeEmailConfig?.id === routeMailingConfigId ? (
        <>
          <h1 className="mb-4">{ t('settings:Branding.Mailing.editMailingTitle') }</h1>
          <StatusBanner
            className="mb-4"
            status
            title={{ active: t('settings:Branding.Mailing.StatusBanner.titleActiveEditDetail'), inactive: '' }}
          />
        </>
      ) : (
        <h1 className="mb-5">{t('settings:Branding.Mailing.editMailingTitle')}</h1>
      )}
      {emailConfigLoadingQuery ? (<FoxLoader withOverlay={false} />) : (
        <MailingForm
          onSubmit={(values, formikHelpers, onSubmitCallback) => submitEmailConfig(values, formikHelpers, onSubmitCallback)}
          initialValues={emailConfigFetchedValues}
        />
      )}
      { formIsSubmitting && <FoxLoader loaderText={t('settings:Branding.Mailing.mailingFormUpdatingText')} /> }
    </>
  );
};

export default EditMailing;
