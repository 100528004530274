/* eslint-disable class-methods-use-this */
import React from 'react';
import { Formik } from 'formik';
import * as PropTypes from 'prop-types';
import { produce, setAutoFreeze } from 'immer';
import { cloneDeep, toArray, intersectionWith, differenceWith, get, has } from 'lodash';
import { Form } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import basePaths from 'Routes/paths/base.path';
import ReactRouterTypes from 'Types/ReactRouter/ReactRouterTypes';
import { toast, nullifyString } from 'Shared/utilities';
import CompanyQueryFactory from 'Models/company/Query/Factories/CompanyQueryFactory';
import CompanyQueryFragments from 'Models/company/Query/Fragments/CompanyQueryFragments';
import CollectionPlaceQueryFactory from 'Models/collectionPlace/Query/Factories/CollectionPlaceQueryFactory';
import { customManualMutation } from 'Models/Components/CustomMutation';
import { AGENTS_WITH_COLLECTION_PLACE_POST_CODE, AGENTS_WITH_EXTERNAL_PLACE_ID, AGENTS_WITHOUT_PICKUP } from 'Constants/app.const';
import * as UserActions from 'StoreActions/user.action';
import formStore from '../../Stores/Form/FormStore';
import AgentFormBody from '../../Presentational/AgentFormBody/AgentFormBody';
import AgentPicker from '../../Presentational/AgentPicker/AgentPicker';
import AgentFormFooter from '../../Presentational/AgentFormFooter/AgentFormFooter';
import Guide from '../../Presentational/Guide/Guide';
import FormikScroll from '~/components/FormikScroll/FormikScroll';
import { createValidationSchema } from './validationSchema';
import ObjectMapper from './ObjectMapper';

class AgentForm extends React.Component {
  static getExistingObjectsByKey(existingObjArr, target, sourceComparePath, targetComparePath) {
    return intersectionWith(
      existingObjArr,
      target,
      (cur, next) => get(cur, sourceComparePath) === get(next, targetComparePath),
    );
  }

  static getNewObjectsByKey(existingObjArr, target, sourceComparePath, targetComparePath) {
    return differenceWith(
      target,
      existingObjArr,
      (cur, next) => get(cur, targetComparePath) === get(next, sourceComparePath),
    );
  }

  constructor(props) {
    super(props);

    const { selectedCredentialId, credentials } = props;
    const currentCredential = selectedCredentialId ? credentials.find((cred) => cred.id === selectedCredentialId) : null;
    let formData = {
      activeServices: [],
      technologyNumbers: [],
      numberSequences: [],
    };

    this.checkedCredentialExistence = false;
    this.isEditing = !!selectedCredentialId;
    this.initialInactiveServices = [];
    this.defaultFormInitialValues = {
      agent: '',
      name: '',
      password: '',
      technologyNumber: '',
      companyCode: '',
      consent: false,
      extraServices: [],
    };

    if (this.isEditing) {
      this.editedCredential = this.getCurrentCredential();
      this.existingServices = cloneDeep(currentCredential.services);
      this.existingCollectionPlaces = cloneDeep(currentCredential.collectionPlacesTypes);
      this.existingTechNumbers = cloneDeep(currentCredential.techNumbers);
      this.initialInactiveServices = currentCredential.services.filter((service) => service.active === false);
      this.defaultFormInitialValues.extraServices = this.getInitialExtraServices(this.getAvailableExtraServices(currentCredential.agent.extraServices));

      formData = {
        numberSequences: currentCredential.services.numberSequences || [],
        activeServices: currentCredential.services.filter((service) => service.active === true),
        technologyNumbers: toArray(currentCredential.techNumbers),
      };
    }

    this.state = {
      isAgentSelected: false,
      shouldSubmitLogin: !this.isEditing || currentCredential?.status === 'incomplete',
      currentAgentId: this.isEditing ? currentCredential?.agent.id : null,
      formData,
    };
  }

  componentDidMount() {
    if (this.isEditing) {
      const { credentials } = this.props;
      this.checkCurrentCredentialExistence(credentials);
    }
  }

  getCurrentCredential() {
    const { selectedCredentialId, credentials } = this.props;
    return credentials.find((credential) => credential.id === selectedCredentialId);
  }

  // eslint-disable-next-line react/destructuring-assignment
  getCurrentAgent(currentAgentId = this.state.currentAgentId) {
    const { agents } = this.props;
    return agents.find((agent) => agent.id === currentAgentId);
  }

  getAvailableExtraServices(agentExtraServices) {
    return agentExtraServices?.filter((es) => es.allowedForApp) ?? [];
  }

  getInitialExtraServices(availableExtraServices) {
    return availableExtraServices.map((extraService) => ({
      ...extraService,
      checked: true,
      selectedByDefault: extraService.selectedByDefault ?? false,
    }));
  }

  getInitialFormValues() {
    const currentAgent = this.getCurrentAgent();
    const hasConfigurationInstructions = currentAgent ? currentAgent.credentialSettingsSchema.configurationInstructions !== null : false;
    const availableExtraServices = this.getAvailableExtraServices(currentAgent?.extraServices);

    let initialValues = {
      ...this.defaultFormInitialValues,
      extraServices: this.getInitialExtraServices(availableExtraServices),
    };

    if (this.editedCredential) {
      const { agent: credentialsAgent, username, password, companyCode } = this.editedCredential;

      initialValues = {
        name: username,
        password,
        companyCode,
        consent: !hasConfigurationInstructions,
        agent: {
          value: credentialsAgent.id,
          label: credentialsAgent.name,
        },
        extraServices: availableExtraServices.map((extraService) => {
          const existingExtraService = credentialsAgent?.extraServices?.find((s) => s.id === extraService.id);

          return {
            ...extraService,
            checked: existingExtraService !== undefined,
            selectedByDefault: existingExtraService?.selectedByDefault ?? false,
          };
        }),
      };
    }

    return initialValues;
  }

  getActiveTechnologyNumber() {
    const { formData } = this.state;
    const { technologyNumbers } = formData;
    let result = null;

    if (technologyNumbers.length > 0) {
      const activeNumber = technologyNumbers.filter((number) => number.active === true)[0];
      const existingActiveNumber = this.existingTechNumbers.filter((number) => number.active === true)[0];
      const { type, number } = activeNumber;

      if (!existingActiveNumber || existingActiveNumber.number !== activeNumber.number) {
        result = {
          type,
          number,
        };
      }
    }

    return result;
  }

  getExistingServices() {
    const { formData } = this.state;
    const existingActiveServices = AgentForm.getExistingObjectsByKey(
      formData.activeServices,
      this.existingServices,
      'deliveryType.id',
      'deliveryType.id',
    );

    let updatedServices = produce({ existingServices: existingActiveServices }, (draft) => {
      const draftServices = draft;
      const hasStartNumbers = has(draftServices.existingServices[0], 'startNumber.number');
      const hasNumberSequences = has(draftServices.existingServices[0], 'numberSequences');
      const inactiveServiceIds = this.initialInactiveServices.map((s) => s.id);

      if (hasStartNumbers) {
        draftServices.existingServices = this.nullifyUnchangedServiceStartNumber(draftServices.existingServices);
      }

      if (hasNumberSequences) {
        draftServices.existingServices = this.nullifyUnchangedServiceSequences(draftServices.existingServices);
      }

      draftServices.existingServices = draftServices.existingServices
        .filter((service) => !inactiveServiceIds.includes(service.id));
    });

    updatedServices = updatedServices.existingServices;

    return ObjectMapper.transformExistingServicesForApi(updatedServices, this.existingServices);
  }

  getNewServices() {
    const { formData } = this.state;
    const { activeServices } = formData;
    const newServices = AgentForm.getNewObjectsByKey(
      this.existingServices.filter((svc) => svc.active === true),
      activeServices,
      'id',
      'id',
    );

    return ObjectMapper.transformStoredServicesForApi(newServices);
  }

  getExistingCollectionPlacesTypes() {
    const submittedCollectionPlaces = formStore.getTransformedCollectionPlaces();
    const formattedExistingCollectionPlaces = [];

    this.existingCollectionPlaces.forEach((res) => {
      formattedExistingCollectionPlaces.push({
        id: res.id,
        note: res.note,
        regular: res.regular,
        externalPlaceId: res.externalPlaceId,
        collectionPlace: res.collectionPlace,
      });
    });

    return AgentForm.getExistingObjectsByKey(
      formattedExistingCollectionPlaces,
      submittedCollectionPlaces,
      'collectionPlace.id',
      'collectionPlaceId',
    )
      .map((cp) => {
        const existingCp = cp;
        const submittedCollectionPlace = submittedCollectionPlaces
          .find((scp) => scp.collectionPlaceId === existingCp.collectionPlace.id);

        existingCp.regular = submittedCollectionPlace ? submittedCollectionPlace.regular : false;
        existingCp.externalPlaceId = submittedCollectionPlace
          ? nullifyString(submittedCollectionPlace.externalPlaceId)
          : null;

        if (submittedCollectionPlace?.metaData?.postCode) {
          existingCp.metaData = { postCode: submittedCollectionPlace.metaData.postCode };
        }

        delete existingCp.collectionPlace;

        return existingCp;
      });
  }

  getNewCollectionPlaces() {
    const collectionPlaces = formStore.getTransformedCollectionPlaces();

    return AgentForm.getNewObjectsByKey(
      this.existingCollectionPlaces,
      collectionPlaces,
      'collectionPlace.id',
      'collectionPlaceId',
    );
  }

  getCredentialCollectionPlaces() {
    const collectionPlaces = [];

    if (this.existingCollectionPlaces) {
      this.existingCollectionPlaces.forEach((cp) => {
        collectionPlaces.push({
          ...cp.collectionPlace,
          note: cp.note,
          regular: cp.regular,
          externalPlaceId: cp.externalPlaceId,
          postCode: cp.metaData?.postCode,
        });
      });
    }

    return collectionPlaces;
  }

  setDefaultAgentValues(currentAgent) {
    if (this.isEditing) return;

    setAutoFreeze(false);

    const updatedState = produce(this.state, (draft) => {
      draft.formData.activeServices = currentAgent.deliveryTypes.map((dt) => ({
        active: true,
        id: null,
        numberSequences: null,
        startNumber: null,
        deliveryType: dt,
      }));

      return draft;
    });

    setAutoFreeze(true);
    this.setState(updatedState);
  }

  handleAgentChange = (option, select, resetForm, setFieldValue) => {
    const currentAgent = this.getCurrentAgent(option.value);

    this.setState({ isAgentSelected: option.value !== '' });
    this.reinitForm(resetForm);

    setFieldValue(select.name, option);
    setFieldValue('extraServices', this.getInitialExtraServices(this.getAvailableExtraServices(currentAgent?.extraServices)));

    this.setState({ currentAgentId: option.value }, () => {
      this.setDefaultAgentValues(currentAgent);
    });
  };

  mergeFormValues = (newFormValues) => {
    const updatedFormValues = produce(this.state, (draft) => {
      draft.formData = {
        ...draft.formData,
        ...newFormValues,
      };
    });

    this.setState(updatedFormValues);
  };

  toggleLoginSubmit = (shouldSubmitLogin) => {
    this.setState((prevState) => ({
      ...prevState,
      shouldSubmitLogin,
    }));
  };

  checkCurrentCredentialExistence = () => {
    if (!this.checkedCredentialExistence) {
      const selectedCredential = this.editedCredential;

      if (selectedCredential === undefined) {
        toast.error('Vybraný přepravce neexistuje');
      } else {
        this.setState({ isAgentSelected: true });
      }

      this.checkedCredentialExistence = true;
    }
  };

  handleSubmit = async (values) => {
    const { user, setUserMeta } = this.props;
    const collectionPlaceFormHasErrors = formStore.collectionPlaceForm.errors && Object.keys(formStore.collectionPlaceForm.errors).length > 0;

    if (collectionPlaceFormHasErrors) return;

    if (!this.isEditing) {
      await this.handleCredentialCreate(values);
    } else {
      await this.handleCredentialUpdate(values);
    }

    if (!user?.data?.hasSetCredentials) {
      setUserMeta({ hasSetCredentials: true });
    }
  };

  createFormSchema(consent = true) {
    const currentAgent = this.getCurrentAgent();
    const externalPlaceId = AGENTS_WITH_EXTERNAL_PLACE_ID.includes(currentAgent.id);
    const collectionPlacePostCode = AGENTS_WITH_COLLECTION_PLACE_POST_CODE.includes(currentAgent.id);
    const supportsPickup = !AGENTS_WITHOUT_PICKUP.includes(currentAgent.id);
    let schema = {
      ...currentAgent.credentialSettingsSchema,
      externalPlaceId,
      collectionPlacePostCode,
      consent,
      supportsPickup,
    };

    if (this.isEditing) {
      const { type } = this.getCurrentCredential();

      schema = type === 'STANDARD' ? {
        ...schema,
        userName: false,
        password: false,
        companyCode: false,
      } : schema;
    }

    return schema;
  }

  nullifyUnchangedServiceStartNumber(services) {
    return services.map((service) => {
      const updatedService = service;
      const existingService = this.existingServices.find((exSvc) => exSvc.id === service.id);

      if (
        existingService
        && existingService.startNumber
        && updatedService.startNumber
        && existingService.startNumber.number === updatedService.startNumber.number
      ) {
        updatedService.startNumber = null;
      }

      return updatedService;
    });
  }

  nullifyUnchangedServiceSequences(services) {
    return services.map((service) => {
      const updatedService = service;
      const existingService = this.existingServices.find((existing) => existing.deliveryType.id === service.deliveryType.id);

      if (existingService === null || service.numberSequences === null) {
        return service;
      }

      let existingSequences = existingService.numberSequences || [];
      existingSequences = existingSequences.map((sequence) => sequence.id);

      updatedService.numberSequences = service.numberSequences.map((sequence) => {
        if (existingSequences.includes(sequence.id)) {
          return null;
        }

        return sequence;
      });

      return service;
    });
  }

  async handleCredentialCreate(values) {
    const { history, t } = this.props;
    const { formData } = this.state;
    const { activeServices, technologyNumbers } = formData;

    const getActiveTechnologyNumber = () => {
      let result = null;

      if (technologyNumbers.length > 0) {
        const activeNumber = technologyNumbers.filter((number) => number.active === true)[0];
        const { type, number } = activeNumber;

        result = { type, number };
      }

      return result;
    };

    const result = {
      agentId: values.agent.value,
      username: values.name,
      password: values.password,
      companyCode: values.companyCode,
      services: activeServices.length > 0 ? ObjectMapper.transformStoredServicesForApi(activeServices) : null,
      techNumber: getActiveTechnologyNumber(),
      extraServices: ObjectMapper.transformExtraServicesForApi(values.extraServices),
      collectionPlacesTypes: formStore.getTransformedCollectionPlaces(),
    };

    const response = await customManualMutation({
      mutation: CompanyQueryFactory.createCompanyCredentials('...CredentialCreate', CompanyQueryFragments.credentialCreate()),
      variables: { companyCredentials: result },
    });

    const { id, isCollectionPlaceUpdateSupported } = response.data.createCompanyCredentials;

    if (isCollectionPlaceUpdateSupported) {
      await customManualMutation({
        mutation: CollectionPlaceQueryFactory.updateCollectionPlacesFromAgent(),
        variables: { agentId: values.agent.value },
      });
    }

    history.push(`${basePaths.settings}/agents/${id}/edit`);
    toast.success(t('settings:Agents.agentCreatedMessage'));
  }

  reinitForm(resetForm) {
    resetForm();

    this.setState({
      formData: {
        activeServices: [],
        technologyNumbers: [],
        numberSequences: [],
      },
    });
  }

  async handleCredentialUpdate(values) {
    const { selectedCredentialId, refetchCredentials, t } = this.props;
    const { shouldSubmitLogin } = this.state;
    let existingServices = this.getExistingServices();

    existingServices = existingServices.map((service) => {
      const formattedService = service;

      if (formattedService.startNumber) {
        // eslint-disable-next-line no-underscore-dangle
        delete formattedService.startNumber.__typename;
      }

      return formattedService;
    });

    const newServices = this.getNewServices();
    const existingCollectionPlaces = this.getExistingCollectionPlacesTypes();
    const newCollectionPlaces = this.getNewCollectionPlaces();

    const result = {
      id: selectedCredentialId,
      username: shouldSubmitLogin ? values.name : null,
      password: shouldSubmitLogin ? values.password : null,
      companyCode: shouldSubmitLogin ? values.companyCode : null,
      newCollectionPlacesTypes: newCollectionPlaces,
      existingCollectionPlacesTypes: existingCollectionPlaces,
      newServices,
      existingServices,
      newTechNumber: this.getActiveTechnologyNumber(),
      extraServices: ObjectMapper.transformExtraServicesForApi(values.extraServices),
    };

    await customManualMutation({
      mutation: CompanyQueryFactory.updateCompanyCredentials('id', ''),
      variables: { companyCredentials: result },
    });

    refetchCredentials();
    toast.success(t('settings:Agents.agentUpdatedMessage'));
  }

  render() {
    const {
      agents,
      history,
      unusedAgents,
      collectionPlaces,
      refetchCollectionPlaces,
      isCreating,
      t,
    } = this.props;

    const { formData, isAgentSelected, currentAgentId } = this.state;
    const currentAgent = agents.find((agent) => agent.id === currentAgentId);

    // TODO: Extract magic string '23' to constants when branding gets merged
    const showPartialCredentialsForm = currentAgent?.id === '23' && (isCreating || this.editedCredential.status === 'incomplete');
    const lockLoginSection = showPartialCredentialsForm ? false : !isCreating && this.editedCredential.status !== 'incomplete';
    const schema = currentAgent ? this.createFormSchema(!showPartialCredentialsForm) : null;

    return (
      <Formik
        onSubmit={this.handleSubmit}
        validationSchema={currentAgent ? createValidationSchema(schema, t) : null}
        initialValues={this.getInitialFormValues()}
      >
        {(formikProps) => (
          <Form onSubmit={(e) => {
            if (formikProps.isSubmitting) return;

            e.stopPropagation();
            formikProps.handleSubmit(e);
            // TODO: call setSubmitting(false) once Formik is used properly
          }}
          >
            <FormikScroll />
            {isAgentSelected && schema.configurationInstructions && (
              <Guide agentName={currentAgent.name} instructions={schema.configurationInstructions} />
            )}

            <AgentPicker
              formik={formikProps}
              onAgentChange={this.handleAgentChange}
              agents={unusedAgents || []}
              disabled={this.isEditing}
            />

            {isAgentSelected && (
              <AgentFormBody
                initialInactiveServices={this.initialInactiveServices}
                credentialCollectionPlaces={this.getCredentialCollectionPlaces()}
                currentAgent={currentAgent}
                refetchCollectionPlaces={refetchCollectionPlaces}
                collectionPlaces={collectionPlaces}
                showPartialCredentialsForm={showPartialCredentialsForm}
                lockLoginSection={lockLoginSection}
                formik={formikProps}
                formData={formData}
                mergeAgentFormValues={this.mergeFormValues}
                toggleLoginSubmit={this.toggleLoginSubmit}
                schema={schema}
                editedCredential={this.editedCredential}
                isCreating={isCreating}
              />
            )}

            <AgentFormFooter
              onSubmitClick={formStore.submitCollectionPlaces}
              history={history}
              isSubmitDisabled={formikProps.isSubmitting || !isAgentSelected}
            />
          </Form>
        )}
      </Formik>
    );
  }
}

function mapStateToProps(state) {
  return { user: state.userReducer };
}

function mapDispatchToProps(dispatch) {
  return { setUserMeta: (meta) => dispatch(UserActions.setUserMeta(meta)) };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['settings'])(AgentForm));

AgentForm.defaultProps = {
  selectedCredentialId: null,
  initialValues: null,
  isCreating: false,
};

AgentForm.propTypes = {
  collectionPlaces: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  credentials: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      username: PropTypes.string,
      password: PropTypes.string,
      companyCode: PropTypes.string,
      techNumbers: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          number: PropTypes.string,
          type: PropTypes.string,
          active: PropTypes.bool,
        }),
      ),
      agent: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        fullName: PropTypes.string,
        logo: PropTypes.shape({ url: PropTypes.string }),
        deliveryTypes: PropTypes.arrayOf(
          PropTypes.shape({ name: PropTypes.string }),
        ),
      }),
    }),
  ).isRequired,
  unusedAgents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedCredentialId: PropTypes.string,
  initialValues: PropTypes.shape({}),
  history: ReactRouterTypes.history().isRequired,
  isCreating: PropTypes.bool,
  agents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      fullName: PropTypes.string,
      logo: PropTypes.shape({ url: PropTypes.string }),
      deliveryTypes: PropTypes.arrayOf(
        PropTypes.shape({ name: PropTypes.string }),
      ),
    }),
  ).isRequired,
};
