import React from 'react';
import qs from 'query-string';
import { Redirect } from 'react-router-dom';

export class URLQueryHandler {
  static getParsedQueryString() {
    return qs.parse(window.location.search);
  }

  constructor() {
    this.currentQueryString = null;
  }

  setQueryStringByObject(object) {
    const queryString = qs.stringify(object);

    if (queryString === this.currentQueryString
            && window.location.search !== '') {
      return null;
    }

    this.currentQueryString = qs.stringify(object);

    return (
      <Redirect to={{
        pathname: '/deliveries',
        search: queryString,
      }}
      />
    );
  }
}

export default new URLQueryHandler();
