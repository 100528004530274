import React from 'react';
import './InlineFormRow.scss';
import { cssMerge } from '~/services/utils';

type Props = {
 sectionTitle: string;
 description?: string | JSX.Element;
 rowItemTitle?: string;
 leftRowSizing?: string;
 rightRowSizing?: string;
 className?: string;
};

const InlineFormRow: React.FC<Props> = ({
  leftRowSizing = 'col-md-5 col-xs-6 col-xl-6',
  rightRowSizing = 'col-md-7 col-xs-6 col-xl-6',
  sectionTitle,
  description,
  rowItemTitle,
  className,
  children,
}) => {
  return (
    <div className={cssMerge(
      'row inline-form-row',
      className,
    )}
    >
      <div className={`${leftRowSizing} row-description`}>
        <h3 className="section-title">{sectionTitle}</h3>
        {description && (<div className="section-description">{description}</div>)}
      </div>
      <div className={`${rightRowSizing} row-items`}>
        {rowItemTitle && <div className="row-item-title">{rowItemTitle}</div>}
        <div className="row-item-content">
          {children}
        </div>
      </div>
    </div>
  );
};

export default InlineFormRow;
