import type { OperationVariables, QueryResult } from '@apollo/react-common';
import { DocumentNode } from 'graphql';
import { useQuery, QueryHookOptions } from 'react-apollo';

import AuthenticationHelper from '~/services/Auth';

function useAuthQuery<TData = any, TVariables = OperationVariables>(
  query: DocumentNode,
  variables?: TVariables,
  options?: QueryHookOptions<TData, TVariables>,
): QueryResult<TData, TVariables> {
  const context = {
    headers: {
      AccessToken: AuthenticationHelper.accessToken,
    },
  };

  return useQuery<TData, TVariables>(query, {
    ...options,
    context,
    variables,
  });
}

export default useAuthQuery;
