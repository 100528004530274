import React from 'react';
import { withTranslation } from 'react-i18next';
import SettingsLayout from 'Components/Settings/SettingsLayout';
import SettingsBase from 'Containers/Settings/_base/SettingsBase';
import { Container } from 'reactstrap';
import { connect } from 'react-redux';

import brandingComponentsDataByRoute, { BrandingComponentContent } from './helpers/brandingRouteComponentMapping';

import './BrandingGlobalStyles.scss';
import './MarketingCampaigns/MarketingCampaigns.scss';

class BrandingWrapper extends SettingsBase {
  private readonly routeName: string;
  private readonly componentByRoute: BrandingComponentContent;

  constructor(props: any) {
    super(props, 'branding');
    this.routeName = this.props?.routeData?.routeName;
    this.componentByRoute = brandingComponentsDataByRoute[this.routeName];
  }

  getComponentByRoute = () => {
    const Component: React.FC = this.componentByRoute.component;
    return <Component {...this.props} />;
  }

  render() {
    const { user } = this.props;
    const { company } = user.data;
    const subscription = company?.subscription;

    return (
      <SettingsLayout goBack={this.componentByRoute.goBackRoute}
        leftBlock={this.state.leftBlock}
        rightBlock={this.state.rightBlock}
        checkedItems={this.state.checkedItems}
        isPartLoading={false}
        upsellState={!subscription.branding}
        upsellDescription={this.componentByRoute.upsellDescription}
        isOpened
      >
        <Container className={`branding ${this.componentByRoute.className}`}>
          { this.getComponentByRoute() }
        </Container>
      </SettingsLayout>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    user: state.userReducer,
  };
}

export default connect<any, any, any>(mapStateToProps)(withTranslation('settings')(BrandingWrapper));
