import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../2020/components/Icon/Icon';

const deliveryTableRating = ({ value }) => (
  <div className="rating">
    <Icon name="star" />
    <span>{value}</span>
  </div>
);

deliveryTableRating.defaultProps = { value: null };
deliveryTableRating.propTypes = { value: PropTypes.number };

export default deliveryTableRating;
