import gql from 'graphql-tag';

export default class AuthenticationFragments {
  static get authenticationTokens() {
    return gql`
            fragment AuthenticationTokens on Identity {
                accessToken
                refreshToken
                accessTokenExpiration
                refreshTokenExpiration
            }
        `;
  }

  static loggedUserPermissions() {
    return gql`
            fragment LoggedUserPermissions on Identity {
                account {
                    primaryAccess
                }
            }
        `;
  }

  static oAuthAuthorizationFormInfo() {
    return gql`
            fragment OAuthAuthorizationFormInfo on OAuthClient {
                companyName
                redirectUri
                logo
            }
        `;
  }

  static oAuthScopes() {
    return gql`
            fragment OAuthScopes on OAuthScope {
                id
                name
                description
            }
        `;
  }

  static oAuthAuthorizeRedirectInfo() {
    return gql`
            fragment OAuthAuthorizeRedirectInfo on OAuthClientAuthorizationResponse {
                success
                code
                errorType
                errorMessage
                errorCode
            }
        `;
  }
}
