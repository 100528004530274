import ApolloClient, { ApolloQueryResult } from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import React from 'react';
import { Col, Row, Container, Button, Spinner } from 'reactstrap';
import { FetchResult } from 'apollo-link';
import { withTranslation } from 'react-i18next';

import RefreshApiTokenButton from 'Containers/Settings/Api/RefreshApiTokenButton';
import DeleteApiTokenButton from 'Containers/Settings/Api/DeleteApiTokenButton';
import SettingsBase from '#/Components/Containers/Settings/_base/SettingsBase';
import SettingsLayout from '#/Components/Settings/SettingsLayout';
import graphqlStorage from '#/core/services/GraphqlStorage';
import AuthenticationHelper from '~/services/Auth';
import BASIC_TOKEN from './basicToken.gql';
import BASIC_TOKEN_CREATE from './basicTokenCreate.gql';
import BASIC_TOKEN_DELETE from './basicTokenDelete.gql';

interface QueryBasicTokenData {
  basicToken: Nullable<string>
}

interface MutationTokenDeleteData {
  basicTokenDelete: boolean;
}

interface MutationTokenCreateData {
  basicTokenCreate: Nullable<string>;
}

class Api extends SettingsBase {
  gqlClient: ApolloClient<NormalizedCacheObject>;

  constructor(props: any) {
    super(props);
    this.gqlClient = graphqlStorage.client;
  }

  async componentDidMount(): Promise<void> {
    try {
      const response = await this.fetchToken();

      if (response.data) {
        this.setState({
          token: response.data.basicToken,
          isOpened: true,
          isPartLoading: false,
        });
      }
    } catch (e) {
      // Handled by global error interceptor
    }
  }

  setLoading(loading: boolean): void {
    this.setState({
      loading,
    });
  }

  async fetchToken(): Promise<ApolloQueryResult<QueryBasicTokenData>> {
    this.setLoading(true);

    const response = await this.gqlClient.query<QueryBasicTokenData>({
      query: BASIC_TOKEN,
      context: {
        headers: {
          AccessToken: AuthenticationHelper.accessToken,
        },
      },
    });

    this.setLoading(false);

    return response;
  }

  async createToken(): Promise<FetchResult<MutationTokenCreateData>> {
    this.setLoading(true);

    const response = await this.gqlClient.mutate<MutationTokenCreateData>({
      mutation: BASIC_TOKEN_CREATE,
      context: {
        headers: {
          AccessToken: AuthenticationHelper.accessToken,
        },
      },
    });

    if (response.data) {
      this.setState({
        token: response.data.basicTokenCreate,
      });
    }

    this.setLoading(false);

    return response;
  }

  async deleteToken(): Promise<FetchResult<MutationTokenDeleteData>> {
    this.setLoading(true);

    const response = await this.gqlClient.mutate<MutationTokenDeleteData>({
      mutation: BASIC_TOKEN_DELETE,
      context: {
        headers: {
          AccessToken: AuthenticationHelper.accessToken,
        },
      },
    });

    if (response.data?.basicTokenDelete) {
      this.setState({
        token: null,
      });
    }

    this.setLoading(false);

    return response;
  }

  render() {
    const { t } = this.props;

    // NOTE: Suppressed since SettingsBase is written in JS = can't change inferred types
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { token, loading } = this.state;
    let content = null;

    if (!token) {
      content = (
        <>
          {loading && (
            <div className="mb-3">
              <Spinner />
            </div>
          )}
          <Button
            className="mr-2"
            color="primary"
            outline
            type="button"
            onClick={() => this.createToken()}
          >
            {t('settings:Api.createButton')}
          </Button>
        </>
      );
    } else {
      content = (
        <>
          {loading ? (
            <div className="mb-3">
              <Spinner />
            </div>
          ) : (
            <p className="mb-4">
              {token}
            </p>
          )}

          <RefreshApiTokenButton
            onRefreshToken={() => this.createToken()}
            disabled={loading}

          />
          <DeleteApiTokenButton
            onDeleteToken={() => this.deleteToken()}
            disabled={loading}
          />
        </>
      );
    }

    return (
      <SettingsLayout
        goBack="/settings"
        leftBlock={this.state.leftBlock}
        rightBlock={this.state.rightBlock}
        checkedItems={this.state.checkedItems}
        isOpened={this.state.isOpened}
        isPartLoading={this.state.isPartLoading}
      >
        <Container>
          <Row className="align-items-center right-col-spacing pb-0">
            <Col>
              <h1
                className="mb-3"
              >
                {t('settings:Api.title')}
              </h1>

              {content}
            </Col>
          </Row>
        </Container>
      </SettingsLayout>
    );
  }
}

export default withTranslation('settings')(Api);
