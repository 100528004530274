import getImageIdFromRootObject from 'Containers/Settings/Branding/helpers/getImageIdFromRootObject';
import MARKETING_CAMPAIGN_ACTIVATE from 'Containers/Settings/Branding/MarketingCampaigns/queries/activateMarketingCampaign.gql';
import GET_ESHOP_BRANDING_DATA from 'Containers/Settings/Branding/queries/getEshopBrandingData.gql';
import { EshopBrandingDataQuery } from 'Containers/Settings/Branding/typesAndDefaults';
import { FormikHelpers } from 'formik';
import { ExecutionResult } from 'graphql';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams as useRouterParams } from 'react-router';
import FoxLoader from '~/components/FoxLoader/FoxLoader';
import StatusBanner from '~/components/StatusBanner/StatusBanner';
import { uploadBannerImages, uploadCampaignDefaultImages } from './helpers';
import { toast } from '#/shared/utilities';
import useAuthMutation from '~/hooks/useAuthMutation';
import {
  BannerItem, CampaignBannerCreationMutation, CampaignBannerCreationResponse,
  CampaignBannerInputValues, CampaignBannerUpdateMutation, CampaignBannerUpdateResponse,
  MarketingCampaignConfigMutation, MarketingCampaignConfigQuery, MarketingCampaignFormValues,
  MarketingCampaignUpdateResponse, marketingCampaignInitialValues, MutationMarketingCampaignBannerDelete, MutationMarketingCampaignActivate,
} from './typesAndDefaults';
import useAuthQuery from '~/hooks/useAuthQuery';
import MarketingCampaignForm from './MarketingCampaignForm/MarketingCampaignForm';

import FETCH_MARKETING_CAMPAIGN from './queries/fetchMarketingCampaignDetail.gql';
import UPDATE_MARKETING_CAMPAIGN from './queries/updateMarketingCampaign.gql';
import CREATE_MARKETING_CAMPAIGN_BANNER from './queries/createMarketingCampaignBanner.gql';
import DELETE_MARKETING_CAMPAIGN_BANNER from './queries/deleteMarketingCampaignBanner.gql';
import UPDATE_MARKETING_CAMPAIGN_BANNER from './queries/updateMarketingCampaignBanner.gql';

const EditMarketingCampaign: React.FC = () => {
  const { t } = useTranslation(['settings']);
  const [formIsSubmitting, setFormIsSubmitting] = React.useState(false);
  const { id: routeCampaignId = '' } = useRouterParams<{ id: string }>();

  const {
    data: marketingCampaignDataQuery,
    loading: marketingCampaignLoadingQuery,
    refetch: marketingCampaignRefetchQuery,
  } = useAuthQuery<MarketingCampaignConfigQuery, { id: string }>(FETCH_MARKETING_CAMPAIGN, { id: routeCampaignId });
  const { data: eshopQueryData, refetch: refetchEshopQueryData } = useAuthQuery<{ eshop: EshopBrandingDataQuery }, never>(GET_ESHOP_BRANDING_DATA);

  const [updateMarketingCampaignMutation] = useAuthMutation<MarketingCampaignUpdateResponse, MarketingCampaignConfigMutation>(UPDATE_MARKETING_CAMPAIGN);
  const [createMarketingCampaignBanner] = useAuthMutation<CampaignBannerCreationResponse, CampaignBannerCreationMutation>(CREATE_MARKETING_CAMPAIGN_BANNER);
  const [activateMarketingCampaignMutation] = useAuthMutation<MutationMarketingCampaignActivate, { id: string }>(MARKETING_CAMPAIGN_ACTIVATE);

  const [updateMarketingCampaignBanner] = useAuthMutation<CampaignBannerUpdateResponse, CampaignBannerUpdateMutation>(UPDATE_MARKETING_CAMPAIGN_BANNER);
  const [deleteMarketingCampaignBanner] = useAuthMutation<MutationMarketingCampaignBannerDelete, { id: string }>(DELETE_MARKETING_CAMPAIGN_BANNER);

  const initiallyLoadedCampaignData = Object.freeze({ ...marketingCampaignDataQuery?.eshopCampaignById });
  const initiallyLoadedBannersIds = (initiallyLoadedCampaignData?.eshopCampaignBanners || []).map((banner) => banner.id);
  const marketingCampaignFetchedValues = marketingCampaignDataQuery?.eshopCampaignById || marketingCampaignInitialValues;

  marketingCampaignFetchedValues.defaultDesktopBannerImage = getImageIdFromRootObject(marketingCampaignFetchedValues.defaultDesktopBanner);
  marketingCampaignFetchedValues.defaultMobileBannerImage = getImageIdFromRootObject(marketingCampaignFetchedValues.defaultMobileBanner);
  marketingCampaignFetchedValues.defaultEmailBannerImage = getImageIdFromRootObject(marketingCampaignFetchedValues.defaultEmailBanner);

  marketingCampaignFetchedValues.eshopCampaignBanners = marketingCampaignFetchedValues.eshopCampaignBanners.map((banner) => ({
    ...banner,
    emailCommunicationBannerImage: getImageIdFromRootObject(banner.emailCommunicationBanner),
    trackAndTraceDesktopBannerImage: getImageIdFromRootObject(banner.trackAndTraceDesktopBanner),
    trackAndTraceMobileBannerImage: getImageIdFromRootObject(banner.trackAndTraceMobileBanner),
  }));

  const uploadBannerImagesAndUpdateBannerEntity = async (bannerData: CampaignBannerInputValues, campaignBanner: BannerItem, bannerId: string) => {
    const awaitToBannerImagesUpload = await uploadBannerImages(bannerData, campaignBanner);
    const [desktopImagePromiseUploadData, mobileImagePromiseUploadData, emailImagePromiseUploadData] = awaitToBannerImagesUpload;

    await updateMarketingCampaignBanner({
      variables: {
        config: {
          ...bannerData,
          trackAndTraceDesktopBannerId: desktopImagePromiseUploadData?.data.imageId || campaignBanner.trackAndTraceDesktopBanner?.id,
          trackAndTraceMobileBannerId: mobileImagePromiseUploadData?.data.imageId || campaignBanner.trackAndTraceMobileBanner?.id,
          emailCommunicationBannerId: emailImagePromiseUploadData?.data.imageId || campaignBanner.emailCommunicationBanner?.id,
        },
        eshopCampaignBannerId: bannerId,
      },
    });
  };

  const createOrUpdateBannerAndHandleImages = async (campaignId: string, campaignBanner: BannerItem) => {
    let bannerId = campaignBanner.id;
    const awaitForBannersCreation = [];

    const bannerData: CampaignBannerInputValues = {
      isActive: true,
      country: campaignBanner.country,
      linkWeb: campaignBanner.linkWeb,
      linkEmail: campaignBanner.linkEmail,
      isTrackAndTraceActive: campaignBanner.isTrackAndTraceActive,
      isEmailCommunicationActive: campaignBanner.isEmailCommunicationActive,
    };

    if (!initiallyLoadedBannersIds.includes(campaignBanner.id)) {
      const uploadedBannerResponse = await createMarketingCampaignBanner({ variables: { config: bannerData, eshopCampaignId: campaignId } });
      bannerId = uploadedBannerResponse.data?.createEshopCampaignBanner?.id;
    }

    if (bannerId) {
      awaitForBannersCreation.push(uploadBannerImagesAndUpdateBannerEntity(bannerData, campaignBanner, bannerId));
    } else {
      awaitForBannersCreation.push(Promise.resolve());
    }

    await Promise.all(awaitForBannersCreation);
  };

  const handleCampaignBanners = async (campaignId: string, campaignBanners: BannerItem[]) => {
    const awaitForBannersCreation = [];

    for (let i = 0; i < campaignBanners.length; i += 1) {
      const campaignBanner = campaignBanners[i];
      awaitForBannersCreation.push(createOrUpdateBannerAndHandleImages(campaignId, campaignBanner));
    }

    await Promise.all(awaitForBannersCreation);
  };

  const handleBannersDeletion = async (marketingCampaignFormValues: MarketingCampaignFormValues) => {
    const awaitToDeleteBanners: Promise<ExecutionResult>[] = [];
    const campaignBanners = marketingCampaignFormValues.eshopCampaignBanners || [];
    const currentBannersIds = campaignBanners.map((banner) => banner.id);

    initiallyLoadedBannersIds.forEach((initialBannerId) => {
      if (!currentBannersIds.includes(initialBannerId) && initialBannerId) {
        awaitToDeleteBanners.push(deleteMarketingCampaignBanner({ variables: { id: initialBannerId } }));
      }
    });

    await Promise.all(awaitToDeleteBanners);
  };

  const submitMarketingCampaign = async (
    marketingCampaignFormValues: MarketingCampaignFormValues,
    formikHelpers: FormikHelpers<MarketingCampaignFormValues>,
    onSubmitCallback: () => void,
  ) => {
    setFormIsSubmitting(true);
    try {
      const awaitToDefaultImagesUpload = await uploadCampaignDefaultImages(routeCampaignId, marketingCampaignFormValues);
      const [defaultDesktopImagePromiseUploadData, defaultMobileImagePromiseUploadData, defaultEmailImagePromiseUploadData] = awaitToDefaultImagesUpload;

      await updateMarketingCampaignMutation({
        variables: {
          id: routeCampaignId,
          config: {
            linkWeb: marketingCampaignFormValues.linkWeb,
            linkEmail: marketingCampaignFormValues.linkEmail,
            campaignName: marketingCampaignFormValues.campaignName,
            isTrackAndTraceActive: marketingCampaignFormValues.isTrackAndTraceActive,
            isEmailCommunicationActive: marketingCampaignFormValues.isEmailCommunicationActive,

            // pair new images or set the existing id's
            defaultDesktopBannerId: defaultDesktopImagePromiseUploadData?.data.imageId || marketingCampaignFormValues.defaultDesktopBanner?.id,
            defaultMobileBannerId: defaultMobileImagePromiseUploadData?.data.imageId || marketingCampaignFormValues.defaultMobileBanner?.id,
            defaultEmailBannerId: defaultEmailImagePromiseUploadData?.data.imageId || marketingCampaignFormValues.defaultEmailBanner?.id,
          },
        },
      });

      if (marketingCampaignFormValues.eshopCampaignBanners.length > 0) {
        await handleCampaignBanners(routeCampaignId, marketingCampaignFormValues.eshopCampaignBanners);
      }

      if (marketingCampaignFormValues.activateAfterSave) {
        formikHelpers.setFieldValue('activateAfterSave', false);
        await activateMarketingCampaignMutation({ variables: { id: routeCampaignId } });
        toast.success(t('settings:Branding.MarketingCampaigns.Message.templateUpdatedAndActivated'));
      } else {
        toast.success(t('settings:Branding.MarketingCampaigns.Message.templateUpdated'));
      }

      await handleBannersDeletion(marketingCampaignFormValues);
      await marketingCampaignRefetchQuery();
      await refetchEshopQueryData();
      formikHelpers.setSubmitting(false);
      setFormIsSubmitting(false);
    } catch (e) {
      // Handled by global error interceptor
      formikHelpers.setSubmitting(false);
      setFormIsSubmitting(false);
    }

    onSubmitCallback();
  };

  return (
    <>
      {eshopQueryData?.eshop?.activeEshopCampaign?.id === routeCampaignId ? (
        <>
          <h1 className="mb-4">{ t('settings:Branding.MarketingCampaigns.editCampaignTitle') }</h1>
          <StatusBanner
            className="mb-4"
            status
            title={{ active: t('settings:Branding.MarketingCampaigns.StatusBanner.titleActiveEditDetail'), inactive: '' }}
          />
        </>
      ) : (
        <h1 className="mb-5">{t('settings:Branding.MarketingCampaigns.editCampaignTitle')}</h1>
      )}
      {marketingCampaignLoadingQuery ? (<FoxLoader withOverlay={false} />) : (
        <MarketingCampaignForm
          onSubmit={(values, formikHelpers, onSubmitCallback) => submitMarketingCampaign(values, formikHelpers, onSubmitCallback)}
          initialValues={marketingCampaignFetchedValues}
        />
      )}
      { formIsSubmitting && <FoxLoader loaderText={t('settings:Branding.MarketingCampaigns.campaignFormUpdatingText')} /> }
    </>
  );
};

export default EditMarketingCampaign;
