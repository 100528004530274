import React from 'react';
import { CustomInput, Col } from 'reactstrap';

const ExtraServiceCheckboxRow = ({
  label,
  name,
  checked,
  onChange,
  id,
}) => (
  <Col md={4}>
    <CustomInput
      name={name}
      checked={checked}
      onChange={onChange}
      type="checkbox"
      id={id}
      label={label}
    />
  </Col>
);

export default ExtraServiceCheckboxRow;
