export default class PaginatorHelper {
  static computeTotalPages(totalCount, numberOfRows) {
    let totalPages = Math.ceil(totalCount / numberOfRows);
    if (totalPages === 0) {
      totalPages = 1;
    }

    return totalPages;
  }

  static computeOffset(currentPage, numberOfRows) {
    return (currentPage - 1) * numberOfRows;
  }
}
