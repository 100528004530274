import React from 'react';
import moment from 'moment';
import AuthenticationDataSource from 'Models/authentication/AuthenticationDataSource';
import { clearTimeoutById } from 'Shared/utilities';
import PropTypes from 'prop-types';
import AuthenticationHelper from '~/services/Auth';

export default class TokenChecker extends React.Component {
  constructor(props) {
    super(props);
    this.accessToken = AuthenticationHelper.accessToken;
    this.refreshToken = AuthenticationHelper.refreshToken;
    this.auth = AuthenticationDataSource;
    this.accessTokenTimeoutId = null;
  }

  async componentDidMount() {
    const { setAuthenticated } = this.props;

    if (!this.refreshToken) {
      return setAuthenticated(false);
    }
    if (!this.accessToken) {
      await this.refreshAccessToken();
    } else {
      setAuthenticated(true);
    }

    return this.startAccessTokenExpirationTimeout();
  }

  componentWillUnmount() {
    clearTimeoutById(this.accessTokenTimeoutId);
  }

    startAccessTokenExpirationTimeout = () => {
      let accessTokenExpirationMs = moment(AuthenticationHelper.accessTokenExpiration)
        .valueOf() - moment()
        .valueOf();
      accessTokenExpirationMs = moment(accessTokenExpirationMs).subtract(30, 'seconds');
      this.accessTokenTimeoutId = setTimeout(async () => {
        const refreshed = await this.refreshAccessToken();
        if (refreshed) {
          this.startAccessTokenExpirationTimeout();
        }
      }, accessTokenExpirationMs);
    };

    refreshAccessToken = async () => {
      const { setAuthenticated } = this.props;

      try {
        let newAuthData = await this.auth.refreshAccessToken(this.refreshToken);
        newAuthData = newAuthData.data.refreshAccessToken;
        AuthenticationHelper.setCookies(
          newAuthData.accessToken,
          newAuthData.refreshToken,
          newAuthData.accessTokenExpiration,
          newAuthData.refreshTokenExpiration,
        );
        this.accessToken = newAuthData.accessToken;
        this.refreshToken = newAuthData.refreshToken;
        setAuthenticated(true);

        return true;
      } catch (err) {
        console.warn('accessToken refresh failed');
        console.warn(err);
        setAuthenticated(false);

        return false;
      }
    };

    render() {
      const { children } = this.props;

      return (
        <>
          {children}
        </>
      );
    }
}

TokenChecker.propTypes = {
  setAuthenticated: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
};
