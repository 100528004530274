import gql from 'graphql-tag';

export default class NotificationFactory {
  static eshopNotificationCenter(fields, fragments) {
    return gql`
      query eshopNotificationCenter {
        eshopNotificationCenter {
          ${fields}
        }
      }
      ${fragments}
    `;
  }

  static updateEshopNotificationCenter(fields, fragments) {
    return gql`
      mutation updateEshopNotificationCenter($settings: InputEshopNotificationCenterUpdate!) {
        updateEshopNotificationCenter(settings: $settings) {
          ${fields}
        }
      }
      ${fragments}
    `;
  }
}
