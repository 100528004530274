import gql from 'graphql-tag';

export default class CollectionPlaceFragments {
  static collectionPlacePreview() {
    return gql`
            fragment CollectionPlacePreview on CollectionPlace {
                id
                name
                active
                address {
                    street
                    city
                    zip
                    country
                }
            }`;
  }

  static collectionPlaceActivation() {
    return gql`
            fragment CollectionPlaceActivation on CollectionPlace {
                id
            }`;
  }

  static agentFormCollectionPlace() {
    return gql`
            fragment AgentFormCollectionPlace on CollectionPlace {
                id
                name
                active
                address {
                    street
                    city
                    zip
                    country
                }
            }`;
  }
}
