import React, { Fragment } from 'react';
import { Popover } from 'react-bootstrap'; // TODO: must not use react-bootstrap and reacstrap in one component
import { Progress } from 'reactstrap';

import { cssMerge, formatCurrency, formatNumber, rx } from '~/services/utils';
import ProgressList from '~/App/[Dashboard]/ProgressList/ProgressList';
import { prepareProgressItems } from '~/App/[Dashboard]/DashboardBody/Duration/DurationTab/DurationTab';
import Overlay from '~/App/[Dashboard]/Overlay/Overlay';
import {
  Agent,
  Cashflow,
  Currency,
  DeliveryCount,
  DeliveryDuration,
} from '~/types/generated/graphql';
import Icon from '~/components/Icon/Icon';

import css from './MetricsRow.module.scss';

function getCurrencyCashflow(agentCashflow: Cashflow[], currency: Currency) {
  return agentCashflow.find((item: Cashflow) => item.currency === currency);
}

interface AgentBranding {
  id: number;
  logo?: string;
}

const AGENT_BRANDING: AgentBranding[] = [
  { id: 1, logo: 'cp.svg' },
  { id: 2, logo: 'wedo.svg' },
  { id: 4, logo: 'ppl.svg' },
  { id: 8, logo: 'gls.svg' },
  { id: 9, logo: 'messenger.svg' },
  { id: 12, logo: 'tnt.svg' },
  { id: 13, logo: 'wedo-ulozenka.svg' },
  { id: 14, logo: 'zasilkovna.svg' },
  { id: 15, logo: 'pbh.svg' },
  { id: 16, logo: 'gls.svg' },
  { id: 17, logo: 'wedo.svg' },
  { id: 18, logo: 'messenger.svg' },
  { id: 19, logo: 'tnt.svg' },
  { id: 20, logo: 'dpd.svg' },
  { id: 21, logo: 'geis.svg' },
  { id: 23, logo: 'cp.svg' },
  { id: 25, logo: 'ppl.svg' },
];

type Props = {
  agent: Agent;
  deliveryCount: DeliveryCount;
  deliveryDuration: DeliveryDuration;
  pickupDuration: DeliveryDuration;
  important: number;
  agentCashflow: Cashflow[];
  currencies: Currency[];
}

const MetricsRow: React.FC<Props> = ({
  agent,
  deliveryCount,
  deliveryDuration,
  pickupDuration,
  important,
  agentCashflow,
  currencies,
}) => {
  const renderPopover = (duration: DeliveryDuration): React.ReactElement => (
    <Popover
      id="duration-popover"
      className={`${css.durationPopover} _ds hide-arrow`}
    >
      <Popover.Content>
        <ProgressList
          className={css.progressList}
          items={prepareProgressItems(duration)}
          compact
        />
      </Popover.Content>
    </Popover>
  );

  const { logo } = AGENT_BRANDING.find((x) => x.id === agent.id) || {};

  const cashflowCells = currencies.map((currency) => {
    const agentItem = getCurrencyCashflow(agentCashflow, currency);

    return (
      <Fragment key={currency}>
        <td className={`${css.dashedBorder} font-weight-medium`}>
          {formatCurrency(agentItem?.total, { currency, style: 'decimal' })}
        </td>

        <td className={`${css.dashedBorder}`}>
          <Progress
            value={rx(agentItem?.prepaid, agentItem?.total)}
            color="info"
          />
          <div className="pt-1 text-grey-light">
            {formatCurrency(agentItem?.prepaid, { currency, style: 'decimal' })}
          </div>
        </td>

        <td>
          <Progress
            value={rx(agentItem?.cod, agentItem?.total)}
            color="info"
          />

          <div className="pt-1 text-grey-light">
            {formatCurrency(agentItem?.cod, { currency, style: 'decimal' })}
          </div>
        </td>
      </Fragment>
    );
  });

  const aovCells = currencies.map((currency) => {
    const agentItem = getCurrencyCashflow(agentCashflow, currency);

    return (
      <td
        key={currency}
        className={`${css.dashedBorder} font-weight-medium`}
      >
        {formatCurrency(agentItem?.aov, { currency, style: 'decimal' })}
      </td>
    );
  });

  return (
    <tr key={agent.id} className={css.row}>
      <th>
        <div className="d-flex align-items-center">
          {logo && (
            <img
              alt={agent.name || ''}
              src={`/assets/img/agent-squircles/${logo}`}
              width="36"
              height="36"
            />
          )}

          {agent.name}
        </div>
      </th>

      <td className={cssMerge('font-weight-medium', css.rating, css.solidBorder, deliveryCount.rating === null && css.disabled)}>
        <Icon
          name="star"
          className="mr-1 mb-1"
        />

        <span>{formatNumber(deliveryCount.rating, { maximumFractionDigits: 1 })}</span>
      </td>

      <td className="font-weight-medium">
        {formatNumber(deliveryCount.total)}
      </td>

      <td className={css.dashedBorder}>
        <Progress
          value={rx(deliveryCount.delivered, deliveryCount.total)}
          color="success"
        />
        <div className="pt-1 text-grey-light">
          {formatNumber(deliveryCount.delivered)}
        </div>
      </td>

      <td>
        <Progress
          value={rx(deliveryCount.shipped, deliveryCount.total)}
          color="info"
        />
        <div className="pt-1 text-grey-light">
          {formatNumber(deliveryCount.shipped)}
        </div>
      </td>

      <td>
        <Progress
          value={rx(deliveryCount.failed, deliveryCount.total)}
          color="danger"
        />
        <div className="pt-1 text-grey-light">
          {formatNumber(deliveryCount.failed)}
        </div>
      </td>

      <td>
        <Progress
          value={rx(deliveryCount.returned, deliveryCount.total)}
          color="warning"
        />
        <div className="pt-1 text-grey-light">
          {formatNumber(deliveryCount.returnPercentage == null ? null : (deliveryCount.returnPercentage / 100), { style: 'percent' })}
        </div>
      </td>

      <td className={`${css.dashedBorder} font-weight-medium`}>
        {formatNumber(important)}
      </td>

      { cashflowCells }

      { aovCells }

      <td className={`${css.dashedBorder} font-weight-medium`}>
        <Overlay overlay={renderPopover(deliveryDuration)}>
          <span className={css.popoverTrigger}>
            {`${formatNumber(deliveryDuration.average, { maximumFractionDigits: 1 })} D`}
          </span>
        </Overlay>
      </td>

      <td className={`${css.dashedBorder} font-weight-medium`}>
        <Overlay overlay={renderPopover(pickupDuration)}>
          <span className={css.popoverTrigger}>
            {`${formatNumber(pickupDuration.average, { maximumFractionDigits: 1 })} D`}
          </span>
        </Overlay>
      </td>
    </tr>
  );
};

export default MetricsRow;
