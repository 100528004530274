import React from 'react';

type Props = {
  redirectUri: string
}

const RedirectUriCard: React.FC<Props> = ({ redirectUri }) => {
  return (
    <div className="card">
      <div className="card-body">
        <p>Po dokončení budete přesměrováni na následující adresu:</p>
        <code>{redirectUri}</code>
      </div>
    </div>
  );
};

export default RedirectUriCard;
