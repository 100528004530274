import React from 'react';
import {
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Form,
  FormFeedback,
} from 'reactstrap';
import { FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';

import { PackageTemplateFormValues } from '../../types';

type Props = FormikProps<PackageTemplateFormValues> & {
  errorMessage: string;
};

const ModalForm: React.FC<Props> = ({
  handleSubmit,
  handleChange,
  handleBlur,
  values,
  touched,
  errors,
  isSubmitting,
  errorMessage,
}) => {
  const { t } = useTranslation(['deliveries', 'validation']);

  return (
    <Form onSubmit={handleSubmit}>
      <ModalBody>
        {
          errorMessage && (
            <p className="text-danger">
              {errorMessage}
            </p>
          )
        }

        <FormGroup>
          <Label
            htmlFor="name"
            style={{ display: 'block' }}
          >
            {t('deliveries:NewDelivery.Packages.SamplePackageModal.PackageName.label')}
          </Label>

          <Input
            name="name"
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            invalid={errors.name !== undefined}
            valid={touched.name && !errors.name}
          />
          {touched.name && errors.name && <FormFeedback>{errors.name}</FormFeedback>}
        </FormGroup>

        <div className="form-row">
          <FormGroup className="col-md-4">
            <Label
              htmlFor="length"
              style={{ display: 'block' }}
            >
              {t('validation:PackageLength.label')}
            </Label>

            <InputGroup className="input-appended">
              <Input
                name="length"
                type="number"
                required
                min={0}
                step="any"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.length}
                invalid={errors.length !== undefined}
                valid={touched.length && !errors.length}
              />
              <InputGroupAddon addonType="append">cm</InputGroupAddon>
              {touched.length && errors.length && <FormFeedback>{errors.length}</FormFeedback>}
            </InputGroup>
          </FormGroup>

          <FormGroup className="col-md-4">
            <Label htmlFor="width" style={{ display: 'block' }}>
              {t('validation:PackageWidth.label')}
            </Label>

            <InputGroup className="input-appended">
              <Input
                name="width"
                type="number"
                required
                min={0}
                step="any"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.width}
                invalid={errors.width !== undefined}
                valid={touched.width && !errors.width}
              />
              <InputGroupAddon addonType="append">cm</InputGroupAddon>
              {touched.width && errors.width && <FormFeedback>{errors.width}</FormFeedback>}
            </InputGroup>
          </FormGroup>

          <FormGroup className="col-md-4">
            <Label htmlFor="height" style={{ display: 'block' }}>
              {t('validation:PackageHeight.label')}
            </Label>

            <InputGroup className="input-appended">
              <Input
                name="height"
                type="number"
                required
                min={0}
                step="any"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.height}
                invalid={errors.height !== undefined}
                valid={touched.height && !errors.height}
              />
              <InputGroupAddon addonType="append">cm</InputGroupAddon>
              {touched.height && errors.height && <FormFeedback>{errors.height}</FormFeedback>}
            </InputGroup>
          </FormGroup>
        </div>
      </ModalBody>

      <ModalFooter className="border-0 justify-content-center">
        <Button
          type="submit"
          color="primary"
          disabled={isSubmitting}
        >
          {t('deliveries:NewDelivery.Packages.SamplePackageModal.submitButton')}
        </Button>
      </ModalFooter>
    </Form>
  );
};

export default ModalForm;
