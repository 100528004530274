import React from 'react';
import { generateReactKey } from 'Shared/utilities';

import TimelineIconAccordion from '../_TimelineIconAccordion/TimelineIconAccordion';

const DelayedWithoutCategory = ({ events }) => (
  <>
    {events.map((event) => (
      <TimelineIconAccordion
        key={generateReactKey()}
        theme="dark"
        icon={{
          type: 'fw',
          icon: ['far', 'circle'],
        }}
        className="trackAndTrace"
        title={(
          <>
            <h4>{event.title}</h4>
            <div className="subtitle">{event.on}</div>
          </>
        )}
      />
    ))}
  </>
);

export default DelayedWithoutCategory;
