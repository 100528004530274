import React from 'react';
import { Redirect } from 'react-router-dom';

import { ErrorNotFound, LayoutBasic, LayoutMain } from '#/Components/components';
import configurator from '~/services/Configurator';
import * as containers from '../../Components/Containers/containers';
import { withLayout, getUserHomepage } from '../../shared/utilities';
import basePaths from '../routes/paths/base.path';
import protectedRoute from '../Auth/ProtectedRoute';
import brandingRouteNames from '../../../src/Components/Containers/Settings/Branding/helpers/brandingRouteNames';

const redirectTo = (to) => (props) => (
  <Redirect to={to} {...props} />
);

/*
 * ----------------------------------
 * :: > Base
 * ----------------------------------
 */
const baseRoutes = [
  {
    component: redirectTo(getUserHomepage()),
    exact: true,
    path: '/',
  },
  {
    component: protectedRoute(withLayout(containers.Dashboard, LayoutMain)),
    exact: true,
    path: '/dashboard',
  },
];

/*
 * ----------------------------------
 * :: > Auth/Register
 * ----------------------------------
 */
const authRoutes = [
  {
    render: () => window.location.replace(`${configurator.config.iamUrl}/login`),
    exact: true,
    path: `${basePaths.auth}/login`,
  },
  {
    component: withLayout(containers.AuthLogout, LayoutBasic),
    exact: true,
    path: `${basePaths.auth}/logout`,
  },
  {
    render: () => window.location.replace(`${configurator.config.iamUrl}/forgotten-password`),
    exact: true,
    path: `${basePaths.auth}/recover-password`,
  },
];

/*
 * ----------------------------------
 * :: > Deliveries
 * ----------------------------------
 */
const deliveriesRoutes = [
  {
    component: protectedRoute(withLayout(containers.Deliveries, LayoutMain)),
    exact: true,
    path: basePaths.deliveries,
  },
  {
    component: protectedRoute(withLayout(containers.DeliveriesNew, LayoutMain)),
    exact: true,
    path: `${basePaths.deliveries}/new`,
  },
  {
    component: protectedRoute(withLayout(containers.DeliveriesNew, LayoutMain)),
    path: `${basePaths.deliveries}/new/:deliveryDuplicationId`,
  },
  {
    component: protectedRoute(withLayout(containers.DeliveriesNew, LayoutMain)),
    path: `${basePaths.deliveries}/edit/:deliveryDuplicationId`,
  },
  {
    component: protectedRoute(withLayout(containers.ImportCSV, LayoutMain)),
    exact: true,
    path: `${basePaths.deliveries}/csv-import`,
  },
  {
    component: protectedRoute(withLayout(containers.Batches, LayoutMain)),
    exact: true,
    path: `${basePaths.batches}`,
  },
  {
    component: protectedRoute(withLayout(containers.Protocols, LayoutMain)),
    exact: true,
    path: `${basePaths.protocols}`,
  },
  {
    component: protectedRoute(withLayout(containers.DeliveryDetail, LayoutMain)),
    path: `${basePaths.deliveries}/:deliveryId`,
    exact: true,
  },
  {
    component: protectedRoute(withLayout(containers.BatchDetail, LayoutMain)),
    path: `${basePaths.batches}/:id`,
  },
  {
    component: protectedRoute(withLayout(containers.ProtocolDetail, LayoutMain)),
    path: `${basePaths.protocols}/:id`,
  },
];

/*
 * ----------------------------------
 * :: > Address book
 * ----------------------------------
 */
const addressBookRoutes = [
  {
    component: protectedRoute(withLayout(containers.Contacts, LayoutMain)),
    exact: true,
    path: basePaths.addressBook,
  },
  {
    component: protectedRoute(withLayout(containers.ContactsNew, LayoutMain)),
    path: `${basePaths.addressBook}/new`,
  },
  {
    component: protectedRoute(withLayout(containers.ContactsEdit, LayoutMain)),
    path: `${basePaths.addressBook}/:id/edit`,
  },
  {
    component: protectedRoute(withLayout(containers.ContactsDetail, LayoutMain)),
    path: `${basePaths.addressBook}/:id`,
  },
];

/*
 * ----------------------------------
 * :: > OAuth
 * ----------------------------------
 */
const oauthRoutes = [
  {
    component: protectedRoute(withLayout(containers.Authorize, LayoutMain)),
    exact: true,
    path: `${basePaths.oauth}/authorize`,
  },
];

/*
 * ----------------------------------
 * :: > Settings
 * ----------------------------------
 */
const settingsMyProfileRoutes = [
  {
    component: protectedRoute(withLayout(containers.SettingsMyProfile, LayoutMain)),
    exact: true,
    path: `${basePaths.settings}/my-profile`,
  },
];

const settingsCouriersRoutes = [
  {
    component: protectedRoute(withLayout(containers.SettingsAgents, LayoutMain)),
    path: `${basePaths.settings}/agents`,
    exact: true,
  },
  {
    component: protectedRoute(withLayout(containers.SettingsAgentsNew, LayoutMain)),
    path: `${basePaths.settings}/agents/new`,
  },
  {
    component: protectedRoute(withLayout(containers.SettingsAgentsEdit, LayoutMain)),
    path: `${basePaths.settings}/agents/:id/edit`,
  },
];

const settingsChannelsRoutes = [
  {
    component: protectedRoute(withLayout(containers.SettingsChannel, LayoutMain)),
    path: `${basePaths.settings}/information`,
  },
  {
    component: protectedRoute(withLayout(
      containers.SettingsChannelsDetailNotifications, LayoutMain,
    )),
    path: `${basePaths.settings}/notifications`,
  },
];

const settingsPickupPlacesRoutes = [
  {
    component: protectedRoute(withLayout(containers.SettingsCollectionPlaces, LayoutMain)),
    exact: true,
    path: `${basePaths.settings}/collection-places`,
  },
  {
    component: protectedRoute(withLayout(containers.SettingsCollectionPlacesNew, LayoutMain)),
    path: `${basePaths.settings}/collection-places/new`,
  },
  {
    component: protectedRoute(withLayout(containers.SettingsCollectionPlacesEdit, LayoutMain)),
    path: `${basePaths.settings}/collection-places/:id/edit`,
  },
];

const settingsUsersRoutes = [
  {
    component: protectedRoute(withLayout(containers.SettingsUsers, LayoutMain)),
    exact: true,
    path: `${basePaths.settings}/users`,
  },
  {
    component: protectedRoute(withLayout(containers.SettingsUsersNew, LayoutMain)),
    path: `${basePaths.settings}/users/new`,
    exact: true,
  },
  {
    component: protectedRoute(withLayout(containers.SettingsUsersEdit, LayoutMain)),
    path: `${basePaths.settings}/users/:id`,
    exact: true,
  },
];

const settingsCompanyRoutes = [
  {
    component: protectedRoute(withLayout(containers.SettingsCompany, LayoutMain)),
    exact: true,
    path: `${basePaths.settings}/company`,
  },
];

const settingsInvoicesRoutes = [
  {
    component: protectedRoute(withLayout(containers.SettingsInvoices, LayoutMain)),
    exact: true,
    path: `${basePaths.settings}/invoices`,
  },
];

const settingsApiRoutes = [
  {
    component: protectedRoute(withLayout(containers.SettingsApi, LayoutMain)),
    exact: true,
    path: `${basePaths.settings}/api`,
  },
];

const settingsPackagesRoutes = [
  {
    component: protectedRoute(withLayout(containers.SettingsPackages, LayoutMain)),
    exact: true,
    path: `${basePaths.settings}/packages`,
  },
  {
    component: protectedRoute(withLayout(containers.SettingsPackagesNew, LayoutMain)),
    path: `${basePaths.settings}/packages/new`,
  },
  {
    component: protectedRoute(withLayout(containers.SettingsPackagesEdit, LayoutMain)),
    path: `${basePaths.settings}/packages/:id/edit`,
  },

];

const settingsBrandingRoutes = [
  {
    component: protectedRoute(withLayout(containers.SettingsBrandingWrapper, LayoutMain, { routeName: brandingRouteNames.trackAndTrace })),
    exact: true,
    path: `${basePaths.settings}/branding/track-and-trace`,
  },
  {
    component: protectedRoute(withLayout(containers.SettingsBrandingWrapper, LayoutMain, { routeName: brandingRouteNames.newTrackAndTrace })),
    path: `${basePaths.settings}/branding/track-and-trace/new`,
  },
  {
    component: protectedRoute(withLayout(containers.SettingsBrandingWrapper, LayoutMain, { routeName: brandingRouteNames.editTrackAndTrace })),
    path: `${basePaths.settings}/branding/track-and-trace/:id`,
  },
  {
    component: protectedRoute(withLayout(containers.SettingsBrandingWrapper, LayoutMain, { routeName: brandingRouteNames.mailing })),
    exact: true,
    path: `${basePaths.settings}/branding/mailing`,
  },
  {
    component: protectedRoute(withLayout(containers.SettingsBrandingWrapper, LayoutMain, { routeName: brandingRouteNames.newMailing })),
    path: `${basePaths.settings}/branding/mailing/new`,
  },
  {
    component: protectedRoute(withLayout(containers.SettingsBrandingWrapper, LayoutMain, { routeName: brandingRouteNames.editMailing })),
    path: `${basePaths.settings}/branding/mailing/:id`,
  },
  {
    component: protectedRoute(withLayout(containers.SettingsBrandingWrapper, LayoutMain, { routeName: brandingRouteNames.campaign })),
    exact: true,
    path: `${basePaths.settings}/branding/marketing-campaigns`,
  },
  {
    component: protectedRoute(withLayout(containers.SettingsBrandingWrapper, LayoutMain, { routeName: brandingRouteNames.newCampaign })),
    path: `${basePaths.settings}/branding/marketing-campaigns/new`,
  },
  {
    component: protectedRoute(withLayout(containers.SettingsBrandingWrapper, LayoutMain, { routeName: brandingRouteNames.editCampaign })),
    path: `${basePaths.settings}/branding/marketing-campaigns/:id`,
  },
];

const settingsRoutes = [
  {
    component: protectedRoute(withLayout(containers.SettingsCommon, LayoutMain)),
    exact: true,
    path: basePaths.settings,
  },
  ...settingsMyProfileRoutes,
  ...settingsCouriersRoutes,
  ...settingsChannelsRoutes,
  ...settingsPickupPlacesRoutes,
  ...settingsPackagesRoutes,
  ...settingsUsersRoutes,
  ...settingsInvoicesRoutes,
  ...settingsApiRoutes,
  ...settingsBrandingRoutes,
];

export const routes = [
  ...baseRoutes,
  ...authRoutes,
  ...deliveriesRoutes,
  ...addressBookRoutes,
  ...settingsCompanyRoutes,
  ...settingsRoutes,
  ...oauthRoutes,
  { component: ErrorNotFound },
];

export default routes;
