import produce from 'immer';

import * as types from '../actions/actionTypes';
import { getItem, setItem } from '../../2020/services/localStorage';

export default class DeliveryReducer {
  constructor() {
    this.initialState = {
      filter: {
        page: 1,
        orderBy: 'id',
        orderByDirection: 'DESC',
        filter: null,
        recipientCountry: getItem('deliveryFilterCountry') || null,
        flags: { important: true },
      },
      upload: { csvFile: null },
    };
  }

  static setCSVFileUpload(state, payload) {
    return produce(state, (draft) => {
      draft.upload.csvFile = payload;
    });
  }

  static setOrderBy(state, payload) {
    return produce(state, (draft) => {
      draft.filter.orderBy = payload;
    });
  }

  static setOrderByDirection(state, payload) {
    return produce(state, (draft) => {
      draft.filter.orderByDirection = payload;
    });
  }

  static setFilterAndFlags(state, payload) {
    const { filter, flags } = payload;

    return produce(state, (draft) => {
      draft.filter.filter = filter;

      if (flags) {
        flags.forEach((flag) => {
          draft.filter.flags[flag] = true;
        });
      } else {
        draft.filter.flags = {};
      }
    });
  }

  static setPage(state, payload) {
    return produce(state, (draft) => {
      draft.filter.page = payload;
    });
  }

  static setFilters(state, payload) {
    return produce(state, (draft) => {
      draft.filter = { ...draft.filter, ...payload };
    });
  }

  static setRecipientCountry(state, payload) {
    // TODO: Move to side effect
    setItem('deliveryFilterCountry', payload);

    return produce(state, (draft) => {
      draft.filter.recipientCountry = payload;
    });
  }

  actionHandler = (state = this.initialState, action) => {
    const { type, payload } = action;

    switch (type) {
      case types.DELIVERY_UPLOAD_CSV_FILE_CHANGED:
        return DeliveryReducer.setCSVFileUpload(state, payload);
      case types.DELIVERY_FILTER_AND_FLAGS_CHANGED:
        return DeliveryReducer.setFilterAndFlags(state, payload);
      case types.DELIVERY_FILTERS_CHANGED:
        return DeliveryReducer.setFilters(state, payload);
      case types.DELIVERY_ORDER_BY_DIRECTION_CHANGED:
        return DeliveryReducer.setOrderByDirection(state, payload);
      case types.DELIVERY_ORDER_BY_CHANGED:
        return DeliveryReducer.setOrderBy(state, payload);
      case types.DELIVERY_PAGE_CHANGED:
        return DeliveryReducer.setPage(state, payload);
      case types.DELIVERY_RECIPIENT_COUNTRY_CHANGED:
        return DeliveryReducer.setRecipientCountry(state, payload);
      default:
        return state;
    }
  }
}
