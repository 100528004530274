import React, { useState } from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

import { ModalProps } from '~/hooks/useModal';
import PackageDataSource from '#/core/models/packages/PackageDataSource';

import ModalForm from './ModalForm/ModalForm';
import { PackageTemplateDraft, PackageTemplateFormValues } from '../types';

const validationSchema = (t: TFunction) => {
  return Yup.object().shape({
    name: Yup.string()
      .required(t('deliveries:NewDelivery.Packages.SamplePackageModal.PackageName.required'))
      .min(4, t('deliveries:NewDelivery.Packages.SamplePackageModal.PackageName.minLength', { count: 4 })),
    length: Yup.number()
      .required(t('validation:PackageLength.required')),
    width: Yup.number()
      .required(t('validation:PackageWidth.required')),
    height: Yup.number()
      .required(t('validation:PackageHeight.required')),
  });
};

type Props = ModalProps & {
  template: PackageTemplateDraft;
}

const PackageTemplateModal: React.FC<Props> = ({
  isOpen,
  onToggle,
  template,
  onSuccess,
}) => {
  const { t } = useTranslation(['deliveries', 'validation']);

  const [errorMessage, setErrorMessage] = useState<string>('');

  const packageDataSource = new PackageDataSource();

  const handleSubmit = async (
    values: PackageTemplateFormValues,
    { setSubmitting }: FormikHelpers<PackageTemplateFormValues>,
  ) => {
    setSubmitting(true);

    try {
      const { name, length, width, height } = values;
      await packageDataSource.createPackageTemplate(name, length, width, height);

      setErrorMessage('');

      setSubmitting(false);

      onToggle(false);

      if (onSuccess) { onSuccess(); }
    } catch (error) {
      console.error(error.graphQLErrors[0].message);

      setErrorMessage(t('deliveries:NewDelivery.Banner.packageTemplateSaveError'));

      setSubmitting(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => onToggle()}
      centered
      modalTransition={{ timeout: 150 }}
    >
      <ModalHeader toggle={() => onToggle()}>
        {t('deliveries:NewDelivery.Packages.SamplePackageModal.title')}
      </ModalHeader>

      <Formik
        initialValues={{
          name: '',
          length: template.length,
          height: template.height,
          width: template.width,
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema(t)}
        // component={ModalForm}
      >
        {(props) => (
          <ModalForm
            {...props}
            errorMessage={errorMessage}
          />
        )}
      </Formik>
    </Modal>
  );
};

export default PackageTemplateModal;
