import PropTypes from 'prop-types';

export default class LocalDeliveryTypes {
  static every() {
    return {
      canClose: PropTypes.bool,
      deliveryId: PropTypes.string,
      refetchDeliveryDetail: PropTypes.func,
      push: PropTypes.func,
      mutationService: PropTypes.shape({}),
      modalService: PropTypes.shape({}),
      modalState: PropTypes.oneOfType([PropTypes.shape({}).isRequired, PropTypes.string]),
      canCancel: PropTypes.bool,
    };
  }

  static deliveryMenuInteraction() {
    return {
      canClose: LocalDeliveryTypes.every().canClose.isRequired,
      deliveryId: LocalDeliveryTypes.every().deliveryId.isRequired,
      refetchDeliveryDetail: LocalDeliveryTypes.every().refetchDeliveryDetail.isRequired,
      push: LocalDeliveryTypes.every().push.isRequired,
      isMonitored: PropTypes.bool,
    };
  }
}
