import React from 'react';
import { Form, Button, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import Icon from 'Components/Presentational/Icon/Icon';
import { useNoteInput } from '../../useNoteInput';

type Props = {
  deliveryId: number;
  refetchTimelineEvents: () => any;
  refetchNotes: () => any;
};

const NoteSection: React.FC<Props> = ({ deliveryId, refetchTimelineEvents, refetchNotes }) => {
  const { t } = useTranslation('deliveries');

  const noteInput = useNoteInput({ deliveryId, refetchTimelineEvents, refetchNotes, noteId: null });

  function handleSubmit(e: any) {
    e.preventDefault();

    noteInput.onNoteCreate();
  }

  return (
    <Form className="add-note" onSubmit={handleSubmit}>
      <div className="input">
        <Icon
          type="fw"
          icon={['fas', 'comment']}
          fixedWidth
        />

        <Input
          value={noteInput.note}
          placeholder={t('DeliveryDetail.Timeline.Notes.placeholder')}
          type="text"
          onChange={noteInput.onNoteChange}
        />
      </div>

      <Button type="submit" color="primary" disabled={noteInput.note === ''}>
        {t('DeliveryDetail.Timeline.Notes.submitButton')}
      </Button>
    </Form>
  );
};

export default NoteSection;
