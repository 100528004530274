import { FormikErrors, useFormikContext } from 'formik';
import React, { useMemo } from 'react';
import { Card, CardBody, FormFeedback, FormGroup, Label } from 'reactstrap';
import { ValueType } from 'react-select';
import { useTranslation } from 'react-i18next';

import CountrySelect, { Option } from 'Containers/Deliveries/New/CountrySelect/CountrySelect';
import { EshopLocalizationFormValues } from 'Containers/Settings/Eshop/EshopLocalizationForm/EshopLocalizationForm';
import EshopContactForm from '../EshopForm/EshopContactForm';
import SubmitButton from '~/components/SubmitButton/SubmitButton';
import { FORMIK_SCROLL_TARGET_CLASSNAME } from '~/components/FormikScroll/FormikScroll';
import useAuthMutation from '~/hooks/useAuthMutation';
import { cssMerge } from '~/services/utils';
import { toast } from '#/shared/utilities';
import { LocalizationConfigValues } from '../EshopForm/EshopForm';

import DELETE_LOCALIZATION_CONFIG from '../deleteLocalizationConfig.gql';

type Props = {
  index: number;
  config: LocalizationConfigValues;
  onDelete: () => void;
};

const EshopLocalizationItem: React.FC<Props> = ({ index, config, onDelete }) => {
  const { t } = useTranslation(['settings', 'validation']);
  const { setFieldValue, handleBlur, errors: formikErrors, values: formikValues } = useFormikContext<EshopLocalizationFormValues>();
  const [deleteConfig, { loading: deleteConfigLoading }] = useAuthMutation(DELETE_LOCALIZATION_CONFIG);

  const inputNamePrefix = useMemo(() => `localizationConfigs[${index}]`, [index]);

  const localizationItemErrors = useMemo<FormikErrors<LocalizationConfigValues> | null>(() => {
    const errors = formikErrors?.localizationConfigs?.[index] ?? null;
    return typeof errors === 'string' ? null : errors;
  }, [formikErrors, index]);

  /**
   * Already assigned countries which should be excluded from CountrySelect via its countryBlacklist prop.
   */
  const assignedCountries = useMemo(() => {
    return formikValues.localizationConfigs
      .map((localizationConfig) => localizationConfig.country)
      // Exclude the currently selected country of this EshopLocalizationItem and filter possible empty values
      .filter((country) => country && country !== config.country) as string[];
  }, [formikValues, config.country]);

  const handleDeleteConfig = async (id?: string, callback?: () => void) => {
    try {
      // Delete existing config
      if (id) {
        await deleteConfig({
          variables: {
            id,
          },
        });
        toast.success(t('settings:Eshop.Message.localizationConfigDeleted'));
      }

      // Remove config from FieldArray
      if (callback) {
        callback();
      }
    } catch (error) {
      // Handled in global error interceptor
    }
  };

  return (
    <Card className="mb-4">
      <CardBody>
        <FormGroup className="mb-5">
          <Label>
            {t('validation:CountrySelect.label')}
            <span className="text-danger">*</span>
          </Label>
          <CountrySelect
            // `value` must be of type `string` due to `CustomSelect` implementation
            // (see CustomSelect.tsx:81)
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            value={config.country}
            name={`${inputNamePrefix}.country`}
            onBlur={handleBlur}
            placeholder={t('validation:CountrySelect.placeholder')}
            defaultLabel={t('validation:CountrySelect.placeholder')}
            countryCodesBlacklist={assignedCountries}
            className={cssMerge(
              FORMIK_SCROLL_TARGET_CLASSNAME,
              localizationItemErrors?.country ? 'is-invalid' : '',
            )}
            onChange={(option: ValueType<Option>) => {
              const selectedCountry = Array.isArray(option) ? option[0] : option;
              setFieldValue(`${inputNamePrefix}.country`, selectedCountry.value);
            }}
          />
          {localizationItemErrors?.country && (
            <FormFeedback className="d-block">
              {localizationItemErrors.country}
            </FormFeedback>
          )}
        </FormGroup>

        <EshopContactForm inputNamePrefix={`${inputNamePrefix}.`} />

        <div className="card-actions">
          <SubmitButton
            color="danger"
            outline
            onClick={() => handleDeleteConfig(config.id, onDelete)}
            className="align-middle"
            size="xs"
            isLoading={deleteConfigLoading}
          >
            {t('settings:Eshop.Localization.deleteVariantButton')}
          </SubmitButton>
        </div>
      </CardBody>
    </Card>
  );
};

export default EshopLocalizationItem;
