interface Result<T> {
  data: T | null;
}

interface WrappedResponse<T> extends Result<T> {
  error: string | null;
}

type PromiseInput<T> = Promise<Result<T>>;

// function isPromise(x: any): boolean {
//   // https://stackoverflow.com/questions/27746304/how-do-i-tell-if-an-object-is-a-promise/38339199
//   return Promise.resolve(x) === x || x instanceof Promise || !!x?.then;
// }

function promiseMe<T = any>(
  promise: PromiseInput<T>, // | { [key: string]: PromiseInput<T> }
): Promise<WrappedResponse<T>> {
  return promise
    .then(({ data }) => ({ data, error: null }))
    .catch((error: string) => ({ error, data: null }));

  // if (isPromise(x)) {
  //   return (x as PromiseInput<T>)
  //     .then(({ data }) => ({ data, error: null }))
  //     .catch((error: string) => ({ error, data: null }));
  // }

  // const [dataKey, promise] = Object.entries(x as { [key: string]: PromiseInput<T> })[0];

  // return promise
  //   .then(({ data }) => {
  //     if (data && data[dataKey]) {
  //       return { data: data[dataKey], error: null };
  //     }

  //     return Promise.reject(new Error(`data or data.${dataKey} property not found in result`));
  //   })
  //   .catch((error: string) => ({ error, data: null }));
}

export default promiseMe;
