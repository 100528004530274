import { useMutation } from 'react-apollo';
import { DocumentNode } from 'graphql';
import { MutationOptions } from 'apollo-client';

import { getGraphQLAuthenticationContext } from '#/shared/utilities';

function useTokenMutation(mutation: DocumentNode, options?: MutationOptions) {
  return useMutation(
    mutation, {
      ...options,
      context: getGraphQLAuthenticationContext(),
    },
  );
}

export {
  useTokenMutation,
};
