import gql from 'graphql-tag';

export default class DeliveryQueryFactory {
  static closeDeliveries(fields, fragments) {
    return gql`
            mutation closeDeliveries (
                $agentId: ID
            ) {
                closeDeliveries (
                    agentId: $agentId
                ) {
                    ${fields}
                }
            }
            ${fragments}`;
  }

  static closeDeliveriesFromList(fields, fragments) {
    return gql`
            mutation closeDeliveriesFromList (
                $deliveriesIds: [ID]!
            ) {
                closeDeliveriesFromList (
                    deliveriesIds: $deliveriesIds
                ) {
                    ${fields}
                }
            }
            ${fragments}`;
  }

  static cancelDeliveries(fields, fragments) {
    return gql`
            mutation cancelDeliveries (
                $deliveriesIds: [ID]!
            ) {
                cancelDeliveries (
                    deliveriesIds: $deliveriesIds
                ) {
                    ${fields}
                }
            }
            ${fragments}`;
  }

  static deliveriesClosingInformation(fields, fragments) {
    return gql`
            query deliveriesClosingInformation {
                deliveriesClosingInformation {
                    ${fields}
                }
            }
            ${fragments}`;
  }

  static deliveryById(fields, fragments) {
    return gql`
            query deliveryById (
                $id: ID!
            ) {
                deliveryById (
                    id: $id
                ) {
                    ${fields}
                }
            }
            ${fragments}`;
  }

  static createDelivery(fields, fragments) {
    return gql`
            mutation createDelivery (
                $delivery: InputDeliveryCreate!
            ) {
                createDelivery (
                    delivery: $delivery
                ) {
                    ${fields}
                }
            }
            ${fragments}`;
  }

  static turnOffDeliveryImportant(fields, fragments) {
    return gql`
            mutation turnOffDeliveryImportant (
                $deliveryId: ID!, $attributes: InputDeliveryImportantTurnOffAttributes!
            ) {
                turnOffDeliveryImportant (
                    deliveryId: $deliveryId, attributes: $attributes
                ) {
                    ${fields}
                }
            }
            ${fragments}`;
  }

  static deliveries(fields, fragments) {
    return gql`
             query deliveries($orderBy: EnumDeliveryOrderByFields,
                    $orderByDirection: EnumOrderByDirection,
                    $category: EnumDeliveryFilterByCategory,
                    $recipientCountry: EnumCountry,
                    $important: Boolean,
                    $offset: Int,
                    $limit: Int) {
                    deliveries(orderBy: $orderBy,
                        orderByDirection:
                        $orderByDirection,
                        category: $category,
                        recipientCountry: $recipientCountry,
                        important: $important,
                        offset: $offset,
                        limit: $limit) {
                        ${fields}
                    }
                }
                ${fragments}
        `;
  }

  static createDeliveryStopDelivering(fields, fragments) {
    return gql`
            mutation createDeliveryStopDelivering($deliveryId: ID!, $attributes: InputDeliveryStopDeliveringAttributes!) {
                createDeliveryStopDelivering(deliveryId: $deliveryId, attributes: $attributes) {
                    ${fields}
                }
            }
            ${fragments}
        `;
  }

  static createDeliveryNote(fields, fragments) {
    return gql`
            mutation createDeliveryNote($note: InputDeliveryNoteCreate!) {
                createDeliveryNote(note: $note) {
                    ${fields}
                }
            }
            ${fragments}
        `;
  }

  static updateDeliveryNote(fields, fragments) {
    return gql`
            mutation updateDeliveryNote($note: InputDeliveryNoteUpdate!) {
                updateDeliveryNote(note: $note) {
                    ${fields}
                }
            }
            ${fragments}
        `;
  }

  static deleteDeliveryCsvImportSettings(fields, fragments) {
    return gql`
            mutation deleteDeliveryCsvImportSettings($id: ID!) {
                deleteDeliveryCsvImportSettings(id: $id) {
                    ${fields}
                }
            }
            ${fragments}
        `;
  }
}
